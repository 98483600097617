import { FunctionComponent, useContext, useEffect, useState } from "react";

import { useCall, useEthers } from "@usedapp/core";
import StakeCard from "components/customCards/StakeCard";
import { Modal } from "components/lib";
import { BigNumber, Contract, utils } from "ethers";
import millify from "millify";

import GeoBlockModal from "components/GeoBlockModal";
import { ConsentContext } from "context/Consent";
import { GeoIpContext } from "context/GeoIp";
import { useChainId } from "hooks/useChainId";
import { useCoinPrice } from "hooks/useCoinPrice";
import { useRewardApr } from "hooks/useRewardApr";
import StakingAbi from "utils/Staking.json";
import StakingDualAbi from "utils/StakingDual.json";
import StakeUnstakeModal from "./modals/stakeUnstake";

export interface stakePoolProps {
  poolDetails: any;
  updatePoolData?: (data) => void
};

const StakePoolNonSteer: FunctionComponent<stakePoolProps> = (props) => {
  const poolDetails = props["poolDetails"];
  const { account } = useEthers();
  const [chainId] = useChainId();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const rewardApr = useRewardApr(poolDetails);

  // const rewardApr = '0';
  const [modalType, setModalType] = useState<"stake" | "unstake">();

  const stakingInterface = new utils.Interface(poolDetails?.["isDualFactory"] ? StakingDualAbi.abi: StakingAbi.abi);
  const stakingContract = new Contract(
    poolDetails.stakingPool,
    stakingInterface
  );
  const { geoBlocked } = useContext(GeoIpContext);
  const { setShowConsentModal, hasSigned } = useContext(ConsentContext);
  const [showGeoBlockModal, setShowGeoBlockModal] = useState(false);
  const [range,] = useState({
    upperTickPrice: `0 ${poolDetails?.["vaultTokens"]?.["token1"]?.["symbol"]}`,
    lowerTickPrice: `0 ${poolDetails?.["vaultTokens"]?.["token1"]?.["symbol"]}`,
  });

  // const position = useVaultPositions({
  //   id: poolDetails?.stakingToken,
  //   beaconName: poolDetails?.beaconName
  // });

  // const slot0 = useSlot0(poolDetails?.smartPoolData?.pool);
  // const { maxUpperBound: upperTick, maxLowerBound: lowerTick } = useMemo(() => {
  //   return !!position
  //     ? getMaxBounds(position)
  //     : {
  //         maxUpperBound: 1,
  //         maxLowerBound: -1,
  //       }
  //       //
  //     }, [position]);
  //   const Token0 = useMemo(
  //     () => {
  //       return poolDetails?.['vaultTokens']?.["smartPoolData"] ?  new Token(
  //         chainId,
  //         poolDetails?.["vaultTokens"]?.["token0"]?.["address"],
  //         Number(poolDetails?.["smartPoolData"]?.["token0Decimals"]),
  //         poolDetails?.["vaultTokens"]?.["token0"]?.["symbol"],
  //         poolDetails?.["vaultTokens"]?.["token0"]?.["name"],
  //       ): null
  //     },
  //     [poolDetails, chainId]
  //   );

  //   const Token1 = useMemo(
  //     () => {
  //       return poolDetails?.['vaultTokens']?.["smartPoolData"] ? new Token(
  //         chainId,
  //         poolDetails?.["vaultTokens"]?.["token1"]?.["address"],
  //         Number(poolDetails?.["smartPoolData"]?.["token1Decimals"]),
  //         poolDetails?.["vaultTokens"]?.["token1"]?.["symbol"],
  //         poolDetails?.["vaultTokens"]?.["token1"]?.["name"],
  //       ) : null 
  //     },
  //     [poolDetails, chainId]
  //   );
  

  // and we can create a token object for token1
  

  // useEffect(() => {
  //   if(position) {
  //     try {
  //       const upperTickPrice = tickToPrice(Token0, Token1, upperTick).toFixed(8);
  //       const lowerTickPrice = tickToPrice(Token0, Token1, lowerTick).toFixed(8);
  //       setRange({
  //         upperTickPrice: `${upperTickPrice} ${poolDetails?.["vaultTokens"]?.["token1"]?.["symbol"]}`,
  //         lowerTickPrice: `${lowerTickPrice} ${poolDetails?.["vaultTokens"]?.["token1"]?.["symbol"]}`
  //       });
  //     } catch(e) {
  //         console.log({e});
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [position, Token0, Token1, lowerTick, upperTick, poolDetails]);



  

  // const inRange = useMemo(() => {
  //   if (position && slot0) {
  //     const currentTick = slot0?.tick || 0;
  //     return isCurrentTickInRange(currentTick, {
  //       maxUpperBound: upperTick,
  //       maxLowerBound: lowerTick,
  //     })
  //   } else {
  //     return null
  //   }
  // }, [slot0, position, upperTick, lowerTick]);

  const [pool, setPool] = useState({
    id: poolDetails.stakingPool,
    start: new Date().toLocaleDateString("en-US"),
    protocol: poolDetails.protocol,
    userStakeBalance: "0",
    vault: poolDetails?.vaultTokens,
    beaconName: poolDetails?.beaconName,
    chainId: poolDetails?.chainId,
    rewardTokenA: poolDetails?.rewardTokenA.toLowerCase(),
    rewardTokenB: poolDetails?.rewardTokenB,
    stakingToken: poolDetails?.stakingToken.toLowerCase(),
    rewardsDuration: poolDetails?.rewardsDuration,
    // token decimals
    rewardTokenADecimals: poolDetails?.rewardTokenADetail?.decimals,
    rewardTokenBDecimals: poolDetails?.rewardTokenBDetail?.decimals,
    rewardTokenADetail: poolDetails?.rewardTokenADetail,
    rewardTokenBDetail: poolDetails?.rewardTokenBDetail,
    
    stakeTokenDecimals: poolDetails?.staking?.decimals,
    stakeToken: poolDetails?.staking?.symbol,
    earnTokenA: poolDetails?.rewardTokenADetail?.symbol,
    earnTokenB: poolDetails?.rewardTokenBDetail?.symbol,
    periodFinish: poolDetails?.periodFinish ? BigNumber.from(poolDetails?.periodFinish) : "",
    strategyId: poolDetails?.strategyId,
    feeTier: poolDetails?.feeTier,
    smartPoolData: poolDetails?.smartPoolData,
    isDualFactory: poolDetails?.isDualFactory,
    isSteerVault: poolDetails?.isSteerVault
  });


  const {data: usdPricesReward} = useCoinPrice(pool.rewardTokenA.toLowerCase(), pool.rewardTokenA.toLowerCase());
  const {data: usdPricesStake} = useCoinPrice(pool.stakingToken.toLowerCase(), pool.stakingToken.toLowerCase());

  // const userlpTokenDetails = useLpTokenInT0AndT1(pool["userStake"], pool["stakingToken"].toLowerCase());
  // const totalLpTokenDetails = useLpTokenInT0AndT1(pool["totalAmount"], pool["stakingToken"].toLowerCase());


  useEffect(() => {
    if (pool["isActive"] && pool["userStakeAmount"] && pool['userStake'] && pool['stakeTokenDecimals']) {
      props.updatePoolData(pool)
    } 

  }, [pool, props]);

  const totalBalance = useCall({
    contract: stakingContract,
    method: "totalSupply",
    args: [],
  }
  );

  const rewardRateA = useCall(
    {
      contract: stakingContract,
      method: poolDetails?.["isDualFactory"] ? "rewardRateA" : "rewardRate",
      args: [],
    }
  );

  const rewardRateB = useCall(
    poolDetails?.["isDualFactory"] ? {
      contract: stakingContract,
      method: "rewardRateB",
      args: [],
    } : false
  );
  const stakeBalance = useCall(
    account
      ? {
        contract: stakingContract,
        method: "balanceOf",
        args: [account],
      }
      : false
  );
  const totalRewardBalanceA = poolDetails.dailyEmissionRewardA * (parseInt(poolDetails.rewardsDuration)/86400);
  const totalRewardBalanceB = (poolDetails.dailyEmissionRewardA || 0) * (parseInt(poolDetails.rewardsDuration)/86400);

  const rewardA = useCall(
    account
      ? {
        contract: stakingContract,
        method: poolDetails?.["isDualFactory"] ? "earnedA" : "earned",
        args: [account],
      }
      : false
  );

  const rewardB = useCall(
    account && poolDetails?.["isDualFactory"]
      ? {
        contract: stakingContract,
        method:  "earnedB",
        args: [account],
      }
      : false
  );

  useEffect(() => {
    const poolData = {};
    if (totalBalance?.value && totalBalance.value.length > 0 && usdPricesStake && usdPricesStake[pool.stakingToken]) {
      if (!pool["totalAmount"] || (pool["totalAmount"] && totalBalance.value[0] && pool["totalAmount"] !== totalBalance.value[0].toString())) {
        poolData["totalAmount"] = totalBalance.value[0].toString();
        poolData['TST'] = parseFloat(
          utils.formatUnits(poolData["totalAmount"], pool["stakeTokenDecimals"])
        ) * usdPricesStake[pool.stakingToken];
      }
    }

    if (stakeBalance && stakeBalance?.value && stakeBalance.value.length > 0) {
      if (!pool["userStake"] || (pool["userStake"] && stakeBalance.value[0] && pool["userStake"] !== stakeBalance.value[0].toString())) {
        poolData["userStake"] = stakeBalance.value[0].toString();
      }
    }

    if (rewardA && rewardA?.value && rewardA.value.length > 0) {
      const rewardBalanceA = rewardA.value[0];
      if (!pool["rewardBalanceA"] || (pool["rewardBalanceA"] && rewardBalanceA && pool["rewardBalanceA"].toString() !== rewardBalanceA.toString())) {

        poolData['rewardBalanceA'] = rewardBalanceA;
        poolData["rewardBalanceBigNumA"] = rewardA.value[0] || BigNumber.from('0')
      }
    }

    if (rewardB && rewardB?.value && rewardB.value.length > 0) {
      const rewardBalanceB = rewardB.value[0];
      if (!pool["rewardBalanceB"] || (pool["rewardBalanceB"] && rewardBalanceB && pool["rewardBalanceB"].toString() !== rewardBalanceB.toString())) {

        poolData['rewardBalanceB'] = rewardBalanceB;
        poolData["rewardBalanceBigNumB"] = rewardB.value[0] || BigNumber.from('0')
      }
    }


    if (!pool["totalRewardBalanceA"] || (pool["totalRewardBalanceA"] && totalRewardBalanceA && pool["totalRewardBalanceA"].toString() !== totalRewardBalanceA.toString())) {
      pool["totalRewardBalanceA"] = totalRewardBalanceA;
    }

    if (!pool["totalRewardBalanceB"] || (pool["totalRewardBalanceB"] && totalRewardBalanceB && pool["totalRewardBalanceB"].toString() !== totalRewardBalanceB.toString())) {
      pool["totalRewardBalanceB"] = totalRewardBalanceB;
    }
    // create pool symbol
    if (pool["stakeToken"] && pool["earnTokenA"]) {
      if (!pool["symbol"]) {
        poolData["symbol"] = `${pool["stakeToken"]}-${pool["earnTokenA"]}`;
      }
    }

    // calculate start and end for the stake pool
    if (pool["rewardsDuration"] && pool["periodFinish"]) {
      if (!pool["end"]) {
        const endDate = new Date(parseInt(pool["periodFinish"].toString()) * 1000);
        poolData["end"] = endDate.toLocaleDateString("en-US");
        poolData["isActive"] = Date.now() < endDate.getTime() ? "Active" : "Expired";
        poolData['rewardsDuration'] = pool["rewardsDuration"].toString();

        // calculate number of days left for the pool
        const daysLeft = Math.floor(
          (endDate.getTime() - Date.now()) / (1000 * 3600 * 24)
        );
        poolData["daysLeft"] = daysLeft;

        let badgeText = '';

        // check if number of days is zero then create badge text as Today and then show time left
        if (daysLeft === 0) {
          const hoursLeft = Math.floor(
            (endDate.getTime() - Date.now()) / (1000 * 3600)
          );
          // calculate number of hours and minutes and seconds in HH:mm:ss format
          const hours = Math.floor(hoursLeft);
          const minutes = Math.floor(
            (endDate.getTime() - Date.now()) / (1000 * 60) - hours * 60
          );
          const seconds = Math.floor(
            (endDate.getTime() - Date.now()) / 1000 - hours * 60 * 60 - minutes * 60
          );
          badgeText = `Active for: ${hours}:${minutes}:${seconds} hours`;
        } else if (poolData["isActive"] === "Active") {
          badgeText = `Active for: ${daysLeft} ${daysLeft > 1 ? 'days' : 'day'}`;
        } else {
          badgeText = `Expired`;
        }

        poolData["badgeText"] = badgeText;
      }
    }

    if (pool["totalRewardBalanceA"] &&  !pool["totalRewardsA"] && pool["rewardsDuration"] && pool["rewardTokenADecimals"] && pool["totalAmount"]) {
        // poolData["totalRewardsA"] = millify(parseFloat(
        //   utils.formatUnits(pool["totalRewardBalanceA"], pool["rewardTokenADecimals"])
        // ),
        //   {
        //     precision: 2,
        //   }
        // );
        poolData["totalRewardsA"] = millify(parseFloat(
          pool["totalRewardBalanceA"]
        ),
          {
            precision: 2,
          }
        );
    }
    if (pool["totalRewardBalanceB"] &&  !pool["totalRewardsB"]  && pool["totalRewardBalanceB"] && pool["rewardsDuration"] && pool["rewardTokenBDecimals"] && pool["totalAmount"]) {
      
      poolData["totalRewardsB"] = millify(parseFloat(
        utils.formatUnits(pool["totalRewardBalanceB"], pool["rewardTokenBDecimals"])
      ),
        {
          precision: 2,
        }
      );
    }


    // user stack balance
    if (pool["userStake"] && pool["stakeTokenDecimals"]) {
      const userStakeBalance = millify(parseFloat(
        utils.formatUnits(pool["userStake"], pool["stakeTokenDecimals"])
      ), {
        precision: 2,
      });

      if (!pool["userStakeBalance"] || (pool["userStakeBalance"] && pool["userStakeBalance"] !== userStakeBalance)) {
        poolData["userStakeBalance"] = userStakeBalance;
      }


      if (!pool['userStakeAmount'] && pool["userStake"] && usdPricesStake && usdPricesStake[pool.stakingToken]) {

        poolData['userStakeAmount'] = (parseFloat(
          utils.formatUnits(pool["userStake"], pool["stakeTokenDecimals"])
        ) * usdPricesStake[pool.stakingToken] ).toFixed(2);
        poolData['userStakeAmountFullEth'] = parseFloat(
          utils.formatUnits(pool["userStake"], pool["stakeTokenDecimals"])
        );
      }
    }

    // reward balance
    if (pool["rewardBalanceA"] && pool["rewardTokenADecimals"]) {
      const userRewardBalanceA = millify(parseFloat(
        utils.formatUnits(pool["rewardBalanceA"], pool["rewardTokenADecimals"])
      ), {
        precision: 2,
      });
      if (!pool["userRewardBalanceA"] || (pool["userRewardBalanceA"] && pool["userRewardBalanceA"] !== userRewardBalanceA)) {
        poolData["userRewardBalanceA"] = userRewardBalanceA;
      }
    }

    // reward balance
    if (pool["rewardBalanceB"] && pool["rewardTokenBDecimals"]) {
      const userRewardBalanceB = millify(parseFloat(
        utils.formatUnits(pool["rewardBalanceB"], pool["rewardTokenBDecimals"])
      ), {
        precision: 2,
      });
      if (!pool["userRewardBalanceB"] || (pool["userRewardBalanceB"] && pool["userRewardBalanceB"] !== userRewardBalanceB)) {
        poolData["userRewardBalanceB"] = userRewardBalanceB;
      }
    }

    if(pool["rewardApr"] !== rewardApr) {
      pool["rewardApr"] = rewardApr;
    }

    if (pool["rewardRateA"] && pool["rewardsDuration"]) {
      if (!pool['rewardAmountA']) {
        pool['rewardAmountA'] = millify(parseFloat(
          utils.formatUnits(
            BigNumber.from(pool["rewardRateA"]).mul(pool["rewardsDuration"]),
            pool["rewardTokenADecimals"]
          )
        ), {
          precision: 2,
        });
      }
    }

    if (pool["rewardRateB"] && pool["rewardsDuration"]) {
      if (!pool['rewardAmountB']) {
        pool['rewardAmountB'] = millify(parseFloat(
          utils.formatUnits(
            BigNumber.from(pool["rewardRateB"]).mul(pool["rewardsDuration"]),
            pool["rewardTokenBDecimals"]
          )
        ), {
          precision: 2,
        });
      }
    }
    if (Object.keys(poolData).length > 0) {
      setPool({ ...pool, ...poolData });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardApr, rewardRateA, rewardRateB, rewardA, rewardB, stakeBalance, totalBalance, pool, totalRewardBalanceA, totalRewardBalanceB, usdPricesStake, usdPricesReward]);

  const showStakeCard = () => {
    // check account and chain id and pool
    if (chainId && pool['id']) {
      // check end and pool symbol
      if (pool['end'] && pool['symbol']) {
        if ((pool['rewardTokenADecimals'] || pool['rewardTokenBDecimals']) && pool['stakeTokenDecimals']) {
          return true;
        }
      }
    }

    return false;
  }

  const btnClick = (type) => {
    if (geoBlocked && type === 'stake') {
      setShowGeoBlockModal(true);
    } else if (!hasSigned && localStorage.getItem('consent-verified') !== 'true' && type === 'stake') {
      setShowConsentModal(true);
    } else {
      setIsModalOpen(true);
      setModalType(type);
    }
  }
  return (
    <div className="flex px-1 pb-4">
      {showStakeCard() && <StakeCard
        info={{
          ...pool,
          chainId,
          account,
          inRange: false,
          range,
          userlpTokenDetails: null,
          totalLpTokenDetails: null,
          btns: [
            () => btnClick("stake"),
            () => btnClick("unstake")
          ]
        }}
        key={pool.id}
        account={account}
      />}

      {isModalOpen && account && pool && pool["stakingToken"] && ( pool["rewardTokenA"] || pool["rewardTokenB"] ) && (
        <Modal
          isOpen={isModalOpen}
          size={"small"}
          content={
            <StakeUnstakeModal
              pool={pool}
              closeModal={() => setIsModalOpen(false)}
              type={modalType}
            />
          }
        />
      )}
      {showGeoBlockModal && (
        <Modal
          isOpen={showGeoBlockModal}
          size={"large"}
          content={<GeoBlockModal closeModal={() => setShowGeoBlockModal(false)} />}
        />
      )}
    </div>
  );
};

export default StakePoolNonSteer;

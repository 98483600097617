import { Token } from '@uniswap/sdk-core';

import { computePoolAddress, FeeAmount, nearestUsableTick, TICK_SPACINGS } from '@uniswap/v3-sdk';

import { Call, useCalls, useEthers } from '@usedapp/core';

import { useConcentratedLiquidityPool } from './useConcentratedLiquidityPool';

import { Contract } from 'ethers';
import { useMemo, useState } from 'react';
import { Protocol } from 'utils/types';
import { getV3FactoryContractConfig } from './getV3FactoryContract';
import { getV3TickLensContractConfig } from './useTickLensContract';
import { getInitCodeHAsh } from 'utils';

interface useTicksProps {
  token0: Token | undefined
  token1: Token | undefined
  chainId: number,
  feeAmount: FeeAmount | undefined
  numSurroundingTicks?: number | undefined
  enabled?: boolean | undefined,
  protocol: Protocol
}

const SOME_MAXIMUM_VALUE = 1250 * 5

const bitmapIndex = (tick: number, tickSpacing: number) => {
  return Math.floor(tick / tickSpacing / 256)
}

export function useTicks({
  token0,
  token1,
  chainId,
  feeAmount,
  numSurroundingTicks: _numSurroundingTicks,
  enabled,
  protocol
}: useTicksProps) {


  const numSurroundingTicks = _numSurroundingTicks ?? 1250
  const [currentNumSurroundingTicks, setCurrentNumSurroundingTicks] = useState(numSurroundingTicks);

  const {library} = useEthers();

  const { data: pool } = useConcentratedLiquidityPool({ token0, token1, chainId: chainId, feeAmount, enabled, protocol })

  const tickSpacing = feeAmount && TICK_SPACINGS[feeAmount]
  const activeTick =
    typeof pool?.tickCurrent === 'number' && tickSpacing ? nearestUsableTick(pool?.tickCurrent, tickSpacing) : undefined
  const poolAddress = useMemo(
    () => token0 && token1 && feeAmount && chainId ? computePoolAddress({
            factoryAddress: getV3FactoryContractConfig(chainId, protocol).address!,
            tokenA: token0.wrapped,
            tokenB: token1.wrapped,
            fee: feeAmount,
            initCodeHashManualOverride: getInitCodeHAsh(protocol, chainId)
          })
        : undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chainId, feeAmount, token0, token1]
  )

  const minIndex = useMemo(
    () =>
      tickSpacing !== undefined && activeTick !== undefined
        ? bitmapIndex(activeTick - currentNumSurroundingTicks * tickSpacing, tickSpacing)
        : undefined,
    [tickSpacing, activeTick, currentNumSurroundingTicks]
);

const maxIndex = useMemo(
    () =>
      tickSpacing !== undefined && activeTick !== undefined
        ? bitmapIndex(activeTick + currentNumSurroundingTicks * tickSpacing, tickSpacing)
        : undefined,
    [tickSpacing, activeTick, currentNumSurroundingTicks]
);
 
  const contractReads = useMemo(() => {
    const calls: Call[] = [];

      if (minIndex && maxIndex && poolAddress) {
        for (let i = minIndex; i <= maxIndex; i++) {
          const v3TickLensConfigs = getV3TickLensContractConfig(chainId, protocol);
          const contract = new Contract(v3TickLensConfigs.address, v3TickLensConfigs.abi, library);
          calls.push(
            {
              args: [poolAddress, i],
              method: 'getPopulatedTicksInWord',
              contract: contract
            }
          )
        }
      }
    return calls;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, maxIndex, minIndex, poolAddress])


  const reads = useCalls(contractReads);
  const isLoading = reads.some(result => result === undefined);

  return useMemo(() => {
    let output = [];

    if (!isLoading) {
      const data = reads;

      const reduced = data?.filter((tick) => tick && tick.value && tick.value.length > 0).map((tick) => tick.value).reduce((acc, word) => [...acc, ...word], []).reduce((acc, word) => [...acc, ...word], [])

      if (reduced.length === 0 && currentNumSurroundingTicks < SOME_MAXIMUM_VALUE) {
        setCurrentNumSurroundingTicks(currentNumSurroundingTicks * 2);  // double the distance
        return {
          isLoading: true,
          error: '',
          data: []
        }
      }

      if (reduced.length > 0) {
        const renamed = reduced.map((tick: any) => {

          if(!tick.liquidityNet){
            debugger;
          }

          return {
            tickIdx: tick.tick,
            liquidityNet: tick.liquidityNet.toString(),
          };
        });

        type TickData = {
          tickIdx: number;
          liquidityNet: string;
        };

        output = renamed.sort((a: TickData, b: TickData) => a.tickIdx - b.tickIdx);
      }
    }

    return {
      isLoading,
      error: '',
      data: output,
    };
}, [reads, currentNumSurroundingTicks, isLoading]);

}

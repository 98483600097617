import {
  Navbar,
  Modal,
  Icon,
  Label,
  Button,
  Footer,
  ProgressBar
} from "components/lib";
import "react-step-progress-bar/styles.css";

import { useEthers, useEtherBalance, useNotifications, useUpdateConfig, useConfig } from "@usedapp/core";
import { utils } from "ethers";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getNotificationStyle,
  getGasTokenSymbol,
  isTestnet,
  supportedChains,
  getSupportedDexes,
  getDexDetails,
} from "utils";
import ToastContainerComp from "./ToastContainer";
import { toast } from "react-toastify";
import ToastContent from "./ToastContent";
import ConnectWalletModal from "./ConnectWalletModal";
import { getExplorerLink } from "utils";
import TestnetModal from "./TestnetModal";
import ConsentModal from "./ConsentModal";
import { GeoIpContext } from "context/GeoIp";
import { ConsentContext } from "context/Consent";
import { DeprecatedBundlesContext } from "context/DeprecatedBundles";
import { StakingPoolsContext } from "context/StakingPools";
import { SushiRewardPoolsContext } from "context/SushiRewardPools";
import { useMerkl } from "hooks/useMerkl";
import { MerklContext } from "context/Merkl";

import { useGeoIP } from "hooks/useGeoIP";
import { useConsent } from "hooks/useConsent";
import { useDeprecatedBundles } from "hooks/useDeprecatedBundles";
import { useStakingPools } from "hooks/useStakingPools";
import { useChainId } from "hooks/useChainId";
import { useSushiRewardPools } from "hooks/useSushiRewardPools";
import { DexContext } from "context/Dex";

const progressMsg = {
  0: 'Welcome to Steer Dapp',
  15: 'Establishing secure connection with Steer Contracts...',
  30: 'Establishing secure connection with Steer Nodes ...',
  45: 'Establishing secure connection with Steer decentralized Data provider...',
  60: 'Connection established...',
  75: 'Fetching the data...',
  90: 'Loading the data...'
};

export const LabeledProgressBar = (progressMsgs, progressPercent) => {
  return (
    <div className={`mx-auto my-40`}
      style={{
        width: "30%"
      }}>
      <Label
        content={progressMsgs[progressPercent] || ''}
        size={"large"}
        casing={"capitalize"}
        color={"white-500"}
        align="center"
      />
      <div className="mt-4">
        <ProgressBar percent={progressPercent} color={`#52E5A5`} />
      </div>
    </div>
  )
}

// get notification details for Toast
const getNotificationData = ({ type, transactionName, transaction }) => {
  const explorerLink = getExplorerLink(transaction?.chainId);
  if (type === "transactionStarted") {
    return {
      title: `Transaction Started`,
      description: `${transactionName} started`,
      type: "info",
    };
  }
  if (type === "transactionSucceed") {
    return {
      title: `Transaction Succeed`,
      description: (
        <span>
          {" "}
          {transactionName} Succeed,{" "}
          <a
            href={`${explorerLink}/tx/${transaction?.hash}`}
            target="_blank"
            rel="noreferrer"
            className={`text-white-500 hover:cursor-pointer underline`}
          >
            View Transaction
          </a>
        </span>
      ),
      type: "success",
    };
  }

  if (type === "error" || type === "transactionFailed") {
    return {
      title: transactionName || "Failed Transaction",
      description: <span>{transaction?.msg || transaction?.hash}</span>,
      type: "error",
    };
  }
};

export const sendNotification = (notificationData) => {
  const { title, description, type } = getNotificationData(notificationData);
  toast(<ToastContent title={title} description={description} type={type} />, {
    style: getNotificationStyle(type),
  });
};

const Container: React.FC = ({ children }) => {
  const { account, switchNetwork, deactivate } = useEthers();
  const [chainId] = useChainId();
  const updateConfig = useUpdateConfig();
  const tconfig = useConfig();
  const history = useHistory();
  const { notifications } = useNotifications();
  const [openConnectModal, setOpenConnectModal] = useState(false);
  const [showNetworkSwitchModal, setShowNetworkSwitchModal] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(supportedChains.find((c) => c.id === chainId));
  const { merklRewardPools, loadingMerklPools } = useMerkl(chainId);
  const etherBalance = useEtherBalance(account, { chainId });
  const ethBalance = utils.formatEther(etherBalance || "0");
  const gasToken = getGasTokenSymbol(chainId);
  const [hasMintedOg, setHasMintedOg] = useState({
    account: null,
    state: false,
  });
  const { geoBlocked, setGeoBlocked } = useGeoIP();
  const { loadingDeprecatedBundles, deprecatedBundles } = useDeprecatedBundles(chainId);
  const { showConsentModal, setShowConsentModal, hasSigned, getSignature, verifyEIP712Signature } = useConsent();
  const [progressPercent, setProgressPercent] = useState(0);
  const { pools, loadingStakingPools } = useStakingPools(chainId);
  const { sushiRewardPools, loadingSushiRewardPools } = useSushiRewardPools(chainId);

  const supportedChain = supportedChains.find(d => d.id === chainId);
  const dexs = getSupportedDexes(supportedChain);
  const [selectedDex, setSelectedDex] = useState(dexs[0]);
  useEffect(() => {
    if (chainId) {
      const ifChainSupported = supportedChains.find(
        (chain) => chain.id === chainId
      );
      if (!ifChainSupported) {
        setShowNetworkSwitchModal(true);
      } else {
        setSelectedNetwork(ifChainSupported);
        const dexs = getSupportedDexes(supportedChain);
        setSelectedDex(dexs[0]);
        setShowNetworkSwitchModal(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  useEffect(() => {
    if (progressPercent <= 75) {
      let progressInterval = setInterval(() => {
        setProgressPercent(progressPercent + 15)
      }, 500);
      return () => {
        clearInterval(progressInterval);
      }
    }
  }, [progressPercent])

  useEffect(() => {
    if (hasMintedOg.state && hasMintedOg.account !== account) {
      setHasMintedOg({
        account: null,
        state: false,
      });
    }
  }, [account, hasMintedOg.account, hasMintedOg.state]);

  useEffect(() => {
    if (chainId && account && localStorage.getItem('consent-verified') !== 'true') {
      (async () => {
        await getSignature();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId]);


  const hasNotification = notifications.length > 0;

  useEffect(() => {
    for (const chain_notification of notifications) {
      //@ts-ignore
      if (chain_notification.type !== "walletConnected") {
        sendNotification(chain_notification);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNotification]);

  const handleWallet = () => {
    if (!account) {
      deactivate();
      setOpenConnectModal(true);
    }
  };

  const navbarContent = [
    {
      label: "Smart Pools",
      onClick: () => history.push("/"),
    },
    {
      label: "Stake Pools",
      onClick: () => history.push("/stake"),
    },
    {
      label: "Data Marketplace",
      onClick: () => history.push("/data-marketplace"),
    },
    {
      label: "Automations",
      onClick: () => history.push("/automation"),
    },
    {
      label: "Testnet Leaderboard",
      onClick: () => history.push("/testnet-leaderboard"),
    },
    {
      label: "Backtesting Toolkit",
      onClick: () =>
        window.open(
          "https://www.npmjs.com/package/@steerprotocol/steer-toolset"
        ),
    },
  ];

  const footerLinks = [
    {
      name: "Term & Service",
      onClick: () =>
        window.open(
          "https://steer.finance/wp-content/uploads/2023/04/Steer-Protocol-TCs-230308_cleanJL.docx-1.pdf "
        ),
    },
    {
      name: "Privacy Policy",
      onClick: () =>
        window.open(
          "https://steer.finance/wp-content/uploads/2023/03/Steer-Finance-Privacy-Policy-230307_cleanJL.docx.pdf"
        ),
    },
  ];


  const failedToNetworkSwitch = () => {
    return sendNotification({
      type: "error",
      transactionName: `Failed to switch.`,
      transaction: {
        msg: <div>Please Check On Wallet and Approve the switch request. Try Switching Network Directly from the wallet</div>,
      },
    });
  };


  const renderNetworkSwitchModal = () => (
    <Modal
      isOpen={showNetworkSwitchModal}
      size={"small"}
      content={
        <div className="flex flex-col items-center justify-center m-6">
          <Icon
            name={`faUnlink`}
            size={`4x`}
            style={{
              color: "#FFF",
            }}
          />
          <div className="mt-10 mb-3">
            <Label
              //@ts-ignore
              content={
                <div className="flex flex-col">
                  <span>
                    We currently support{" "}
                    {supportedChains.map((chain) => chain.name).join(", ")}.
                  </span>
                  <span className="mt-4">
                    Please switch to one of the supported networks.
                  </span>
                </div>
              }
              align={`center`}
              casing={"capitalize"}
              color={`white-500`}
            />
            <div
              className={"flex flex-wrap m-6 flex-start"}
            >
              {supportedChains.map((chain) => (
                <div className="m-2">
                  <Button
                    type={"tertiary"}
                    size={"small"}
                    content={chain.name || ""}
                    onClickHandler={async () => {
                      try {
                        await switchNetwork(chain.id);
                      } catch (e) {
                        failedToNetworkSwitch();
                        console.log("issue in network switch", e);
                      }
                    }}
                  />

                </div>
              ))}
            </div>
          </div>
        </div>
      }
    />
  );

  useEffect(() => {
    const run = async () => {
      await (new Promise((resolve) => setTimeout(resolve, 1000)));
      let data = window.location.pathname.slice(1);
      const search = window.location.search;
      if(search) {
        const dex = getDexDetails(search);
        if(dex) {
          data = dex.value;
        }
      }
      if (dexs.find(d => d.value === data)) {
        _setSelectedDex(data);
        if(chainId !== supportedChain.id) {
          await handleSelectNetwork(supportedChain.value); 
        }
      } else {
        for (let chain of supportedChains) {
          const dexsForChain = getSupportedDexes(chain);
          if (dexsForChain.find(d => d.value === data)) {
            await handleSelectNetwork(chain.value); 
            _setSelectedDex(data);
            break; 
          }
        }
      }
    }
    run();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId]);


  useEffect(() => {
    let data = window.location.pathname.slice(1);
    const search = window.location.search;
    if(search) {
      const dex = getDexDetails(search);
      if(dex) {
        data = dex.value;
      }
    }

    if(search?.toLowerCase()?.indexOf('stake') !== -1 && selectedDex.value === data) {
      history.push('/stake');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDex])


  const handleSelectNetwork = async (data) => {
    const chain = supportedChains.find((chain) => chain.value === data);
    if(chain) {
      if (account) {
        try {
          await switchNetwork(chain.id);
          updateConfig({ ...tconfig, readOnlyChainId: Number(chain.id) });
          setSelectedNetwork(chain);
        } catch (e) {
          failedToNetworkSwitch();
          console.log("issue in network switch", e);
        }
      } else {
        updateConfig({ ...tconfig, readOnlyChainId: Number(chain.id) });
      }
    }
  };

  const _setSelectedDex = (data) => {
    const dex = dexs.find(d => d.value === data);
    dex && setSelectedDex(dex);
  }

  const renderChildren = () => (
    <GeoIpContext.Provider
      value={{
        setGeoBlocked,
        geoBlocked,
      }}
    >
      <ConsentContext.Provider
        value={{
          setShowConsentModal,
          hasSigned
        }}
      >
        <DeprecatedBundlesContext.Provider
          value={{
            deprecatedBundles,
            loadingDeprecatedBundles
          }}>
          <StakingPoolsContext.Provider
            value={{
              stakingPools: pools,
              loadingStakingPools: loadingStakingPools
            }}>
            <SushiRewardPoolsContext.Provider
              value={{
                sushiRewardPools: sushiRewardPools,
                loadingSushiRewardPools: loadingSushiRewardPools
              }}
            >
              <MerklContext.Provider
                value={{
                  merklRewardPools,
                  loadingMerklPools
                }}
              >
                <DexContext.Provider
                  value={{
                    selectedDex: selectedDex.value,
                  }}
                >
                  {!loadingDeprecatedBundles && children}
                  {loadingDeprecatedBundles && LabeledProgressBar(progressMsg, progressPercent)}
                </DexContext.Provider>
              </MerklContext.Provider>
            </SushiRewardPoolsContext.Provider>
          </StakingPoolsContext.Provider>
        </DeprecatedBundlesContext.Provider>
      </ConsentContext.Provider>
    </GeoIpContext.Provider>
  )
  return (
    <div
      className="flex flex-col h-screen"
      style={{
        background:
          "url(https://cloud.derekbarrera.xyz/5tnOJtLaUi0QY9SUZflH.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100%",
        overflow: "auto",
      }}
    >
      <div className="">
        {useMemo(() => {
          return (
            <Navbar
              //@ts-ignore
              logo={true}
              type="dapp"
              content={
                isTestnet(chainId)
                  ? navbarContent
                  : navbarContent.filter((d) => d.label !== "Testnet Leaderboard")
              }
              logoClickHandler={() => history.push("/")}
              addressClick={() => history.push("/user-dashboard")}
              onDocsClick={() => {
                window.open("https://docs.steer.finance/");
              }}
              onDiscordClick={() => {
                window.open("https://discord.com/invite/9Ha6w26Mv8");
              }}
              onTwitterClick={() => {
                window.open("https://twitter.com/steerprotocol");
              }}
              selectedNetwork={selectedNetwork}
              networks={supportedChains}
              selectedDex={selectedDex}
              dexs={dexs}
              showDexFilter={true}
              onDexSelect={_setSelectedDex}
              onSelectHandler={async (data) => await handleSelectNetwork(data)}
              showConnectButton={!account}
              handleConnectWallet={handleWallet}
              walletAddress={account}
              onUDashClick={() => history.push("/user-dashboard")}
              balance={`${parseFloat(ethBalance).toFixed(3)} ${gasToken}`}
              wrapperClass="bg-black-900 bg-opacity-70 py-2"
            />
          )
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedNetwork, account, chainId, ethBalance, selectedDex])}

        {isTestnet(chainId) && account && (
          <div
            className={`flex justify-center items-center relative px-6 py-2 my-2 bg-black-900 bg-opacity-80 rounded-2xl w-10/12 text-white-500 lg:w-9/12 mx-auto `}
            style={{
              boxShadow: "black -1px 0px 30px -10px",
            }}
          >
            {/* <img
            className='absolute right-8 -top-2 -z-0'
            src={backgroundImg}
            /> */}
            The snapshot of the wallet address interacted with last testnet
            version is stored and the reward will be given based on all testnet
            version interaction. Happy Testing!
          </div>
        )}
      </div>
      {!showNetworkSwitchModal && isTestnet(chainId) && (!account || (account && hasMintedOg.state)) && renderChildren()}
      {!showNetworkSwitchModal && !isTestnet(chainId) && renderChildren()}
      {/* {children} */}
      <ToastContainerComp />
      {openConnectModal && (
        <ConnectWalletModal
          isOpen={openConnectModal}
          setIsOpen={setOpenConnectModal}
        />
      )}
      {showNetworkSwitchModal && renderNetworkSwitchModal()}
      {account && !hasMintedOg.state && isTestnet(chainId) && chainId !== undefined && (
        <TestnetModal account={account} setHasMintedOg={setHasMintedOg} />
      )}
      {account && showConsentModal && <ConsentModal verifyEIP712Signature={verifyEIP712Signature} showConsentModal={showConsentModal} closeModal={() => setShowConsentModal(false)} />}
      <Footer
        links={footerLinks}
        wrapperClass="bg-black-900 bg-opacity-70 py-2"
        onDiscordClick={() => {
          window.open("https://discord.com/invite/9Ha6w26Mv8");
        }}
        onTwitterClick={() => {
          window.open("https://twitter.com/steerprotocol");
        }}
      />
    </div>
  );
};
export default Container;
import { useCall } from '@usedapp/core';
import { Contract, utils } from "ethers";
import { useLpTokenInT0AndT1 } from 'queries/lp-token';
import { useEffect, useState } from 'react';
import ERC20 from "utils/Erc20.json";
import StakingAbi from "utils/Staking.json";
import StakingDualAbi from "utils/Staking.json";
import { useCoinPrice } from './useCoinPrice';


export const useRewardApr = (stakingPoolData): {
  apr: string;
  aprA: string;
  aprB: string;
} => {
  const stakingInterface = new utils.Interface(stakingPoolData?.["isDualFactory"] ? StakingDualAbi.abi : StakingAbi.abi);
  const erc20Interface = new utils.Interface(ERC20.abi);
  const [stakedToken, setStakedToken] = useState(null);

  const stakingData = stakingPoolData ? { ...stakingPoolData } : {};
  const { data: priceDataA } = useCoinPrice(
    stakingPoolData ? stakingData.stakingToken.toLowerCase() : null,
    stakingPoolData ? stakingData.rewardTokenA.toLowerCase() : null
  );

  const { data: priceDataB } = useCoinPrice(
    stakingPoolData ? stakingData.stakingToken.toLowerCase() : null,
    stakingPoolData ? stakingData?.rewardTokenB?.toLowerCase() : null
  );

  const llpTokenBreakup = useLpTokenInT0AndT1(stakedToken,
    stakingPoolData && stakingPoolData.isSteerVault ?
      stakingData.stakingToken.toLowerCase() : null
  )

  const getStakingContract = () => {
    return new Contract(
      stakingPoolData.stakingPool,
      stakingInterface
    );
  }

  const totalSupply = useCall(stakingPoolData ? {
    contract: getStakingContract(),
    method: "totalSupply",
    args: [],
  } : false
  );


  useEffect(() => {

    if (totalSupply && totalSupply?.value?.length > 0 && !stakedToken) {
      setStakedToken(totalSupply.value[0].toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSupply])

  const totalRewardBalanceA = useCall(stakingPoolData?.rewardTokenA
    ? {
      contract: new Contract(stakingPoolData.rewardTokenA, erc20Interface),
      method: "balanceOf",
      args: [
        stakingPoolData.stakingPool
      ],
    }
    : false)

  const totalRewardBalanceB = useCall(stakingPoolData?.rewardTokenB
    ? {
      contract: new Contract(stakingPoolData.rewardTokenB, erc20Interface),
      method: "balanceOf",
      args: [
        stakingPoolData.stakingPool
      ],
    }
    : false)

  const getData = (rewardToken, rewardTokenDecimals, totalStaked, priceData, totalRewardBalance, dailyEmission) => {
    const totalRewardBalanceVal = totalRewardBalance?.value?.[0];
    const rewardsPerDurationEth = parseFloat(
      utils.formatUnits(totalRewardBalanceVal, rewardTokenDecimals)
    );

    if (priceData[rewardToken]) {
      let totalRewardsUsd = rewardsPerDurationEth * priceData[rewardToken]
      // in case usd values are not available
      let totalStakedUsd = totalStaked;
      if (stakingPoolData.isSteerVault === true && priceData[rewardToken]) {
        totalStakedUsd = llpTokenBreakup.tvl;
      } else if (priceData[stakingPoolData.stakingToken.toLowerCase()]) {
        totalStakedUsd = priceData[stakingPoolData.stakingToken.toLowerCase()] * totalStaked;
      }

      
      if (totalStaked > 0) {

        const perDayEmissionUsd = dailyEmission * priceData[rewardToken];
        // const v1 = (totalRewardsUsd * (365 * 24 * 60 * 60)) / parseInt(stakingPoolData.rewardsDuration);        
        const v1 = (perDayEmissionUsd * 365)

        return ((v1 / totalStakedUsd) * 100).toFixed();
        // return (((rewardsPerDurationEth / totalStaked) / durationInYears) * 100).toFixed(2); 
      } else {
        return totalRewardsUsd.toFixed(2);
      }
    } else {

      if (totalStaked > 0) {
        // const v1 = (rewardsPerDurationEth * (365 * 24 * 60 * 60))/parseInt(stakingPoolData.rewardsDuration);
        const v1 = dailyEmission * 365;
        return ((v1 / totalStaked) * 100).toFixed();
        // return (((rewardsPerDurationEth / totalStaked) / durationInYears) * 100).toFixed(2); 
      } else {
        return rewardsPerDurationEth.toFixed(2);
      }

    }
  }

  const getApr = () => {

    if (
      totalSupply && totalSupply?.value?.length > 0 &&
      totalRewardBalanceA && totalRewardBalanceA?.value && totalRewardBalanceA.value.length > 0 &&
      priceDataA && Object.keys(priceDataA).length > 0 && (stakingPoolData.isSteerVault === false || llpTokenBreakup)
    ) {
      const totalSupplyVal = totalSupply.value[0];
      const totalStaked = parseFloat(
        utils.formatUnits(totalSupplyVal, stakingPoolData.staking.decimals)
      );

      const aprs = {
        apr: '0',
        aprA: '0',
        aprB: '0'
      }
      const rewardAPRA =  getData(stakingPoolData.rewardTokenA.toLowerCase(), stakingPoolData.rewardTokenADecimal, totalStaked, priceDataA, totalRewardBalanceA, stakingData.dailyEmissionRewardA);
      
      aprs["aprA"] = rewardAPRA;
      let rewardAPRB = "0"
      if(totalRewardBalanceB && totalRewardBalanceB?.value && totalRewardBalanceB.value.length > 0 && priceDataB && Object.keys(priceDataB).length > 0 ) {
        rewardAPRB = stakingData["isDualFactory"] ? getData(stakingPoolData.rewardTokenB.toLowerCase(), stakingPoolData.rewardTokenBDecimal, totalStaked, priceDataB, totalRewardBalanceB, stakingData.dailyEmissionRewardB) : "0.0"
        aprs["aprB"] = rewardAPRB;
      }
      
      aprs['apr'] = String(parseFloat(rewardAPRA) + parseFloat(rewardAPRB))
      return aprs;
    }

    return {apr: '0', aprA: '0', aprB: '0'};
  }

  return getApr();
}



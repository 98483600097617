import React, { useEffect, useState, useRef } from "react";

import {
	JsonForm,
	Modal,
	Icon,
} from "components/lib";

import { getIpfsBundleConfig, toTitleCase } from "utils";

const ViewConfig = ({
	data,
	isOpen,
	closeModal,
}: {
	data: any;
	isOpen: boolean;
	closeModal: () => void;
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [hasError, setHasError] = useState(null);
	let submitFormRef = useRef(null);
	const title = `View Data Connector for ${toTitleCase(
		data?.source
	)} Source & ${toTitleCase(data?.host)} Host Config`
	const hash = data.bundle;
	const [configJSON, setConfigJSON] = useState({});

	useEffect(() => {
		(async () => {
			const ipfsData = await getIpfsBundleConfig(hash);

			if (ipfsData.hasError) {
				setHasError(ipfsData.msg);
			} else {
				setConfigJSON(ipfsData.data);
			}
			setIsLoading(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const hasProperties = Object.keys(configJSON["properties"] || {}).length > 0;

	return (
		<Modal
			isOpen={isOpen}
			onClose={closeModal}
			size={"large"}
			content={

				<div className="flex flex-col p-4 mb-2" key={hash}>
					<div className="absolute top-8 right-7 text-white-100">
						{<Icon
							name={`faTimes`}
							className={`text-san-marino-50 hover:bg-white-900 focus:ring-white-900 bg-white-100 fill-white stroke-white`}
							size={`1x`}
							color={"bg-white-100"}
							onClickHandler={closeModal}
						/>}
					</div>
					<div className="flex mb-4 mr-4 text-base leading-6 capitalize text-white-500">
						{title}
					</div>
					<div
						className={`mb-4 border border-lynch-rgba shadow-xs`}
						style={{ width: "100%" }}
					/>
					<a className="flex justify-center mb-2 capitalize text-white-500 hover:underline" 
						rel="noreferrer" href={"https://docs.steer.finance/data-connectors/interface-api#configuration-form"} 
						target={'_blank'}>{"Learn about configuration paramters?"}</a>
					{hasError && (
						<div className="flex justify-center text-lg leading-6 text-red-500">
							{hasError}
						</div>
					)}
					{isLoading && (
						<div className="flex justify-center text-lg text-white-500">
							<Icon spin={true} name="faSpinner" />
						</div>
					)}

					{!isLoading && hasProperties && (
						<JsonForm
							schema={configJSON}
							ref={submitFormRef}
							//@ts-ignore
							setFormData={() => null}
							formData={{}}
							onSubmit={closeModal}
							type={'data-connector'}
						/>
					)}
					{!isLoading && !hasProperties && (
						<div className="flex justify-center text-lg leading-6 text-white-500">
							{"No Config Parameters"}
						</div>
					)}
				</div>
			}
		/>
	);
};

export default ViewConfig;

import React, { FunctionComponent } from "react";
import { Badge } from "../Badge";
import { Label } from "../Label";
import { LabelData } from "../LabelData";
import AppSVG from "./appSVG";
import JobSVG from "./JobSVG";
import DataConnectorSVG from "./dataConnectorSVG";
import { Button } from "../Button";
import { Icon } from "../Icon";
import RangeSplit from "./RangeSplit";


export interface AppCardProps {
  badgeData?: string;
  hideBadge?: boolean;
  icon: string;
  name: string;
  data: Array<Object>;
  id?: any;
  cardType: "app" | "dataConnector" | "job" | "stake";
  onClick?: () => void;
  stakeProtocol?: string;
  card ?: 'user' | 'app'; 
}
const primaryInfoKeys = {
  app: ["asset", "apr"],
  dataConnector: ["host", "output"],
  job: ["gasSpent", "status"],
  stake: ["asset", "reward"],
};

const mockPrimaryInfoKeys = {
  app: ["asset", "fee"]
};

const mockSecondaryInfoKeys = {
  app: {
    part1: ["tvl", 'volumeUSD'],
    part2: [],
  },
};

const userKeys = ["userDeposit", "reward", "vaultGas"];

const secondaryInfoKeys = {
  app: {
    part1: ["tvl", 'interval'],
    part2: [],
  },
  dataConnector: {
    part1: [],
    part2: ["creator"],
  },
  job: {
    part1: [],
    part2: ["creator"],
  },
  stake: {
    part1: ["totalStaked", "apr"],
    part2: ["stakedBalance", "stakedBalanceInUSD"],
    part3: ["liquidityInRange", "earnedRewards"]
  },
};


export const getBadgeUrl = (badge: string) => {
  if(badge) {

    if (badge.toLowerCase().indexOf('uniswap') !== -1) {
      return 'https://steer.finance/wp-content/uploads/2023/05/1200px-Uniswap_Logo.svg.png'
    } else if (badge.toLowerCase().indexOf('forge') !== -1) {
      return 'https://forge.trade/images/ForgeIcon.png';
    } else if (badge.toLowerCase().indexOf('maia') !== -1) {
      return 'https://publissteerimages.s3.amazonaws.com/maiadao.png';
    } else if (badge.toLowerCase().indexOf('sushi') !== -1) {
      return 'https://steer.finance/wp-content/uploads/2023/05/sushiswap-sushi-logo.png';
    } else if (badge.toLowerCase().indexOf('pancake') !== -1) {
      return 'https://cryptologos.cc/logos/pancakeswap-cake-logo.png';
    } else if (badge.toLowerCase().indexOf('angle') !== -1) {
      return 'https://raw.githubusercontent.com/AngleProtocol/angle-assets/main/2_protocol-icon-only/0-MAIN-icon/PNG/angle-mark-topview-gradient.png';
    } else if(badge.toLowerCase().indexOf('retro') !== -1) {
      return `https://649394451-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F9tlcSLJodEszQdc9cz4F%2Fuploads%2FAz3GxHqb3G2tRSRSa0QE%2FFrame%203.svg?alt=media&token=b2eb93f3-23b1-425d-8f6d-0d1c3ecc2fb0`
    }
    else if (badge.toLowerCase().indexOf('polygon') !== -1 || badge.toLowerCase().indexOf('mumbai') !== -1 ||  badge.toLowerCase().indexOf('zkevm') !== -1) {
      return 'https://assets.trustwalletapp.com/blockchains/polygon/assets/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/logo.png?raw=true';
    } else if (badge.toLowerCase().indexOf('arbitrum') !== -1) {
      return 'https://assets-cdn.trustwallet.com/blockchains/arbitrum/assets/0x912CE59144191C1204E64559FE8253a0e49E6548/logo.png?raw=true';
    } else if (badge.toLowerCase().indexOf('optimism') !== -1) {
      return 'https://assets.trustwalletapp.com/blockchains/optimism/assets/0x4200000000000000000000000000000000000042/logo.png?raw=true';
    } else if (badge.toLowerCase().indexOf('bsc') !== -1) {
      return 'https://cdn.sushi.com/image/upload/f_auto,c_limit,w_64,q_auto/native-currency/bnb.svg';
    } else if (badge.toLowerCase().indexOf('evmos') !== -1) {
      return 'https://docs.evmos.org/img/evmos.svg';
    } else if (badge.toLowerCase().indexOf('metis') !== -1) {
      return 'https://raw.githubusercontent.com/MaiaDAO/token-list/main/0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000.svg';
    } else if (badge.toLowerCase().indexOf('kinetix') !== -1) {
      return 'https://www.gitbook.com/cdn-cgi/image/width=40,dpr=2,height=40,fit=contain,format=auto/https%3A%2F%2F3367236650-files.gitbook.io%2F~%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FfCvG3GJjhzqh94soelRm%252Ficon%252FrxkFrW722xy3eYWie0uK%252FKFI_Token%25201.png%3Falt%3Dmedia%26token%3D91528fc6-c2f8-40ee-8637-a71a9dcbf202';
    } else if (badge.toLowerCase().indexOf('avax') !== -1) {
      return 'https://cdn.sushi.com/image/upload/f_auto,c_limit,w_64,q_auto/tokens/43114/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7.jpg';
    } else if (badge.toLowerCase().indexOf('celo') !== -1) {
      return 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/token/celo.jpg';
    }  else if (badge.toLowerCase().indexOf('thundercore') !== -1) {
      return 'https://www.gitbook.com/cdn-cgi/image/width=40,dpr=2,height=40,fit=contain,format=auto/https%3A%2F%2F1384322056-files.gitbook.io%2F~%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FHVry7OTN1UZzjjhTeYXg%252Ficon%252Ftc2CvK0iK8pBB1anEcAT%252F10990.png%3Falt%3Dmedia%26token%3Dd308595a-a25f-4dc2-bd7e-8237f6d9f8e1';
    } else if(badge.toLowerCase().indexOf('ether') !== -1) {
      return `https://cdn.sushi.com/image/upload/f_auto,c_limit,w_64,q_auto/native-currency/ethereum.svg`
    } else if(badge.toLowerCase().indexOf('base') !== -1) {
      return `https://raw.githubusercontent.com/base-org/brand-kit/001c0e9b40a67799ebe0418671ac4e02a0c683ce/logo/symbol/Base_Symbol_Blue.svg`
    } else if(badge.toLowerCase().indexOf('kava') !== -1) {
      return 'https://app.kava.io/assets/kavaIcon-7772487b.svg'
    }
  }
  return null;
}

const upperBadgeSectionWithVector = (type, badgeData, stakeProtocol, props) => {
  let badge = badgeData.split('-');
  badge = badge.length > 1 ? badge[1].trim() : badge[0];
  if (props.name && type === 'app') {
    badge = props.name.split(' ');
    badge = badge[0] + " " + (badge[1] ? badge[1] : "") + " " + (badge[2] ? badge[2] : "") + " " + (badge[3] ? badge[3] : "");
    badge = `${badge.slice(0, 27)}`
  }
  return (
    <>
      <div className={`absolute top-0 right-0`}>
        {/* {type === "app" && } */}
        {/* {type === "stake" && <StakeSVG />} */}
        <AppSVG />
      </div>
      <div className={`px-3 py-4`}>
        {!props.hideBadge && <div className="block ">
          <Badge
            textColor="black-500"
            borderColor="black-500"
            bgColor="white-500"
            content={badge}
            size={"xsmall"}
          />
          {/* {props["incentivized"] && <div className="mx-0 my-1 ">
            <Badge
              textColor="black-500"
              borderColor="black-500"
              bgColor="white-500"
              content={'Incentived App'}
              size={"xsmall"}
            />
          </div>} */}
        </div>}
        {props.hideBadge && <Button
          type={'tertiary'}
          size="xsmall"
          content={'Deploy Smart Pool'}
          casing={'capitalize'}
          onClickHandler={() => props.onClick()}
        />}

        {(type === "stake" || (type === "app")) &&
          <div className={`absolute right-3 top-2`}>
            <Icon
              tokenURL={getBadgeUrl(type === 'stake' ? stakeProtocol: badgeData)}
              type={"token"} tokenSize="large"
            />
          </div>
        }
         {/* {(type === "app") && props.data?.liquidity?.positionType &&
          <div className={`absolute right-3 top-4`}>
            <Badge
              textColor="block-500"
              borderColor="black-500"
              bgColor="white-500"
              content={`${props.data?.liquidity?.positionType}`}
              size={"xsmall"}
            />
          </div>
        } */}

      </div>
    </>
  );
};

const titleWithIcon = (icon, name) => (
  <div className="flex items-center">
    <img
      className={`${"relative w-12 h-12 rounded-full"}`}
      src={icon}
      alt={"app icon"}
    />
    <div className="px-2 overflow-hidden max-h-12">
      <Label content={name.slice(0, 40).replaceAll("-", " ")} size="xlarge" color="black-500" />
    </div>
  </div>
);

const titleSectionWithVector = (type, data) => (
  <>
    <div className="absolute top-0 right-0">
      {type === "dataConnector" && <DataConnectorSVG />}
      {type === "job" && <JobSVG />}
    </div>
    <div className="z-0 px-4 py-4">{titleWithIcon(data.icon, data.name)}</div>
  </>
);

const primaryInfoSection = (hideBadge, type, data, id = "") => {
  const firstData = hideBadge ? data?.[mockPrimaryInfoKeys[type][0]]: data?.[primaryInfoKeys[type][0]];
  const secondData = hideBadge ? data?.[mockPrimaryInfoKeys[type][1]]: data?.[primaryInfoKeys[type][1]];
  return (
    <div className={`flex justify-between`}>
    <LabelData
      label={firstData?.label}
      labelStyle={`capitalize text-sm text-gray-500 ${firstData?.type === 'asset' ? "font-bold" : ""}`}
      data={firstData?.val}
      dataStyle={" text-lg text-black-500 font-semibold mt-0"}
      alignment="vertical"
      //@ts-ignore
      tooltipContent={`${firstData?.type === 'asset' ? "" : ""}`}
      tooltipId={`${firstData?.type === 'asset' ? "" : id}`}
      dataType={firstData?.type}
    />
    {type !== "dataConnector" && (
      <LabelData
      //@ts-ignore
        tooltipContent={secondData?.label === 'Fee APR ✨' && <div className="m-1"> {secondData?.feeAPR}: Fee APR <br /> {secondData?.rewardAPR}: Reward APR</div>}
        tooltipId={secondData?.label === 'Fee APR ✨' && id}
        label={`${secondData?.label}`}
        labelStyle={`capitalize text-sm text-gray-500 text-right`}
        data={secondData?.val}
        dataStyle={`${secondData?.label === 'reward' ? 'text-sm': "text-md"} text-black-500 font-bold mt-0`}
        dataType={secondData?.type}
        alignment="vertical"
      />
    )}
    {type === "dataConnector" && (
      <div className="py-4">
        <Badge
          textColor="black-500"
          borderColor="black-500"
          bgColor="white-500"
          content={secondData?.val}
          size={"xsmall"}
        />
      </div>
    )}
  </div>
  )
}

// const stakeColoredSection = (data) => (
//   <div className={`flex flex-col`}>
//     {[data.stake, data.reward].map(d => (
//       <div className="flex items-center mb-0.5">
//         <Icon
//           token={d.val.toLowerCase()}
//           tokenURL={d.val}
//           type={"token"}
//           tokenSize="large"
//           color={"#242b57"}
//         />
//         <div className="px-1 mx-2">
//           <LabelData
//             label={d.label}
//             labelStyle={"capitalize text-sm   text-gray-500"}
//             data={d.val}
//             dataStyle={" text-md text-black-500 font-semibold mt-0"}
//             alignment="vertical"
//           />
//         </div>
//       </div>
//     ))}

//   </div>
// );

const coloredSection = (hideBadge, type, data, id = "") => (
  <div
    className={`relative flex flex-col  rounded-full ${type === "app" && " mt-2 mb-1"
      }`}
  >
    {/* {type === "app" && titleWithIcon(data.icon, data.name)} */}
    {/* {type === "stake" && stakeColoredSection(data)} */}
    {primaryInfoSection(hideBadge, type, data, id)}
  </div>
);

const lowerSection = (hideBadge, type, data, id, card, onClick) => {
  const { part1, part2, part3 } = hideBadge ? mockSecondaryInfoKeys[type] : secondaryInfoKeys[type];
  const isSteerVaultStake = data?.['asset']?.['val'].length > 1;
  return (
    <>
      {part1.length !== 0 && (
        <div className={`${type === 'stake' ? "mt-4": ""} flex justify-between ${type === 'app' && 
        data?.['tvl']?.val.length > 0 && data?.['tvl']?.val?.[1].length > 1
        ? "mb-0" : "mb-2"}  `}
        >
          {part1.map((d, i) => (
            <LabelData
              label={`${data?.[d]?.label} ${type === 'stake'  && d === 'apr' ? ' ✨' : ''}`}
              labelStyle={`${type === 'stake'  && d === 'apr' ? 'text-right' : ''} capitalize text-sm text-gray-500`}
              data={data?.[d]?.val}
              dataType={data?.[d]?.type}
              dataStyle={`${type === 'stake'  ? 'text-md' : 'text-sm'}  text-black-500 ${type === 'stake'  && d === 'apr' ? 'text-right font-bold' : ''}`}
              tooltipId={type === 'stake'  && d === 'apr' ? `stake ${data?.[d]?.tooltipId}`: null }
              tooltipContent={type === 'stake' && d === 'apr' ? data?.[d]?.tooltip: ""}
              alignment="vertical"
            />
          ))}
        </div>
      )}
      {/* {type === 'stake' && <div className="flex items-center text-sm font-bold my-2"> <span>User Data</span> <div className="bg-gray-500  mx-1" style={{width: "70%", height: '1.5px'}}/></div>} */}

      <div className={`flex justify-between ${type === 'job' && 'my-7'}  ${type === 'dataConnector' && 'my-4'} `}>
        {part2.map((d, i) => (
          <LabelData
            label={data?.[d]?.label}
            labelStyle={`capitalize ${i === 1 ? "text-right" : ""} text-gray-500 ${type === 'stake'? 'text-sm' : 'text-xs'}`}
            data={data?.[d]?.val}
            dataStyle={`text-black-500 ${i === 1 ? "text-right" : ""} ${type === 'stake'? 'text-md' : 'text-xs'}`}
            alignment="vertical"
            dataType={data?.[d]?.type || "text"}
          />
        ))}
        {data.btn && (
          <Button
            type="primary"
            size="xsmall"
            content={data.btn.label}
            onClickHandler={data.btn.val.onClick}
          />
        )}
      </div>
      {type === 'stake' &&  <div className={`flex justify-between my-2`}>
          {isSteerVaultStake && <LabelData
            label={data?.[part3[0]].label}
            labelStyle={`capitalize text-gray-500 text-sm`}
            data={<div className="flex justify-start"><Badge
            textColor="black-500"
            borderColor={`${data?.[part3[0]] ? "shamrock-500" : "yellow-400"}`}
            bgColor={`${data?.[part3[0]] ? "shamrock-500" : "yellow-400"}`}
            content={`${data?.[part3[0]] ? 'In Range': "Out of Range"}`}
            size={"xsmall"}
            tooltipId={id}
            tooltipContent={data?.[part3[0]]?.tooltip}
          /></div>}
            alignment="vertical"
          />}
          <LabelData
            label={data?.[part3[1]].label}
            labelStyle={`capitalize text-gray-500 text-sm ${isSteerVaultStake ? 'text-right' : "text-left"} `}
            data={data?.[part3[1]].val}
            dataStyle={`text-black-500 text-md  ${isSteerVaultStake ? 'text-right' : "text-left"}`}
            alignment="vertical"
            dataType={data?.[part3[1]].type || "text"}
          />
           {data.btns && !isSteerVaultStake && data.btns.slice(1, 2).map((d, i) => (
              <div className="mt-3">
                <Button
              type={'nprimary'}
              size="xsmall"
              content={d?.label}
              onClickHandler={d?.val.onClick}
              disabled={d?.val.disable}
              fullWidth={true}
            />
              </div>
          ))}
      </div>}
      
      {data.btns && data.btns.length > 0 && <div className="mt-3">
            <div className="my-2 grid grid-cols-2 gap-4">
          {data.btns && isSteerVaultStake && data.btns.slice(0, 2).map((d, i) => (
            <Button
              type={'nprimary'}
              size="xsmall"
              content={d?.label}
              onClickHandler={d?.val.onClick}
              disabled={d?.val.disable}
              fullWidth={true}
            />
          ))}
          
        </div>
        <div className="my-2 grid grid-cols-2 gap-4">
          {data.btns && data.btns.slice(2, data.btns.length).map((d, i) => (
            <Button
              type={'nsecondary'}
              size="xsmall"
              content={d?.label}
              onClickHandler={d?.val.onClick}
              disabled={d?.val.disable}
              fullWidth={true}

            />
          ))}
        </div>
      </div>
      }
      {type === 'app' && card === 'user' && <div className={`flex justify-between my-2`}>
        {userKeys.slice(0, 2).map((d, i) => (
          <LabelData
          label={data?.[userKeys[i]].label}
          labelStyle={`${i === 1 ? "text-right" : ""}  capitalize text-gray-500 text-xs `}
          data={data?.[userKeys[i]].val}
          dataStyle={`text-black-500 text-sm  ${i === 1 ? "text-right" : ""} `}
          alignment="vertical"
          dataType={data?.[userKeys[i]].type || "text"}
          tooltipId={data?.[userKeys[i]].tooltip ? `user ${i} ${id}` : null}
          tooltipContent={data?.[userKeys[i]].tooltip || null}
        />
        ))}
          </div>
      }
      {type === 'app' && card === 'user' && <div className={`flex justify-between my-2`}>
      <LabelData
            label={data?.[userKeys[2]].label}
            labelStyle={`capitalize text-gray-500 text-xs`}
            data={data?.[userKeys[2]].val}
            dataStyle={`text-black-500 text-sm`}
            alignment="vertical"
            dataType={data?.[userKeys[2]].type || "text"}
          />
      <LabelData
            label={'Liquidty Position'}
            labelStyle={`capitalize text-gray-500 text-xs`}
            data={<div className="flex justify-end"><Badge
            textColor="black-500"
            borderColor={`${data?.inRange ? "shamrock-500" : "yellow-400"}`}
            bgColor={`${data?.inRange ? "shamrock-500" : "yellow-400"}`}
            content={`${data?.inRange ? 'In Range': "Out of Range"}`}
            size={"xsmall"}
          /> </div>}
            alignment="vertical"
          />
      </div>}
      {card === 'user' && 
        <Button
        type={'nprimary'}
        size="xsmall"
        content={`View Pool`}
        onClickHandler={onClick}
        fullWidth={true}
        />
      }
      {type === 'app'  && card !== 'user' && String(data?.["liquidity"]?.maxTick).indexOf('Infinity') === -1 && <div className={`flex flex-col space-y-1 `}>
        <div className={`flex flex-row justify-between items-center`}>
         <div className="text-sm text-gray-500">Liquidity Placement</div>
          <div>
          {/* <Badge
          textColor="black-500"
          borderColor={`${data?.liquidity?.inRange ? "shamrock-500" : "yellow-400"}`}
          bgColor={`${data?.liquidity?.inRange ? "shamrock-500" : "yellow-400"}`}
          content={`${data?.liquidity?.inRange ? 'In Range': "Out of Range"}`}
          size={"xsmall"}
        /> */}
         <Badge
              textColor="block-500"
              borderColor="black-500"
              bgColor="white-500"
              content={`${data?.liquidity?.positionType}`}
              size={"xsmall"}
            />
          </div>
        </div>
        <RangeSplit
          currentTick={data?.liquidity?.currentTick}
          minTick={data?.liquidity?.minTick}
          maxTick={data?.liquidity?.maxTick}
          token0Symbol={data?.liquidity?.token0Symbol}
          token1Symbol={data?.liquidity?.token1Symbol}
        />
      </div>}
    </>
  );
};

const backgroundColor = {
  app: "transaprent",
  dataConnector: "rgba(180, 222, 255, 0.2)",
  job: "rgba(255, 203, 69, 0.1)",
  stake: "transaprent",
};

export const AppCard: FunctionComponent<AppCardProps> = (props) => {
  const { id, data, badgeData, icon, name, cardType, onClick, card } = props;
  const newData = { ...data, badgeData, icon, name };
  const bigCard = cardType === "app" || cardType === "stake";
  const isSteerVaultStake = data?.['asset']?.['val'].length > 1;

  return (
    <div
      data-testid="AppCard-component"
      onClick={() => onClick && card !== 'user' ? onClick() : ''}
      key={id}
      className={cardType === "app" ? "cursor-pointer" : "cursor-default"}
    >
      <div
        className={`relative overflow-hidden flex align-center 
          ${cardType === 'app' && data['isDeprecated'] ? 'bg-gray-200' : 'bg-white-500'}
         flex-grow`}
        style={{
          width: cardType === 'stake' || card === 'user' ? "300px": "280px",
          height: bigCard ? cardType === "stake" ? newData["btns"] && newData["btns"].length > 2 ? isSteerVaultStake ? "430px" : "380px" : "320px" : props.hideBadge ? "178px" : 
          String(newData?.["liquidity"]?.maxTick).indexOf('Infinity') !== -1 ? card === 'user' ? "370px" : "208px" :  card === 'user' ? "370px" : "258px" : "220px",
          boxShadow: "0px 0px 30px 5px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px",
        }}
      >
        {bigCard
          ? upperBadgeSectionWithVector(cardType, badgeData, props.stakeProtocol, props)
          : titleSectionWithVector(cardType, newData)}
        <div
          className="absolute"
          style={{
            background: backgroundColor[cardType],
            top: bigCard ? cardType === "stake" || card === 'user' ? "12%" : props.hideBadge? "25%" : "16%" : "38%",
            height: bigCard ? "118px" : "70px",
            width: "100%",
          }}
        >
          <div className={`py-2 px-4`}>
            {coloredSection(props.hideBadge, cardType, newData, id)}
            {lowerSection(props.hideBadge, cardType, newData, id, card, onClick)}
          </div>
        </div>
      </div>
    </div>
  );
};

AppCard.defaultProps = {
  cardType: "app",
};

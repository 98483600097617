"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SavingsRateFactory__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        inputs: [],
        name: "AlreadyInitialized",
        type: "error",
    },
    {
        inputs: [],
        name: "Initializing",
        type: "error",
    },
    {
        inputs: [],
        name: "NotAllowed",
        type: "error",
    },
    {
        inputs: [],
        name: "NotGovernorOrGuardian",
        type: "error",
    },
    {
        inputs: [],
        name: "NotInitializing",
        type: "error",
    },
    {
        inputs: [],
        name: "ZeroAddress",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "savingsRate",
                type: "address",
            },
        ],
        name: "ContractRemoved",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "implementation",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "toggleStatus",
                type: "bool",
            },
        ],
        name: "ImplementationToggled",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint8",
                name: "version",
                type: "uint8",
            },
        ],
        name: "Initialized",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "savingsRate",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "implementation",
                type: "address",
            },
        ],
        name: "NewSavingsRateContract",
        type: "event",
    },
    {
        inputs: [
            {
                internalType: "address payable",
                name: "savingsRate",
                type: "address",
            },
        ],
        name: "addSavingsRate",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "coreBorrow",
        outputs: [
            {
                internalType: "contract ICoreBorrow",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "implementation",
                type: "address",
            },
            {
                internalType: "address",
                name: "_token",
                type: "address",
            },
            {
                internalType: "address",
                name: "_surplusManager",
                type: "address",
            },
            {
                internalType: "string",
                name: "_name",
                type: "string",
            },
            {
                internalType: "string",
                name: "_symbol",
                type: "string",
            },
        ],
        name: "deploySavingsRate",
        outputs: [
            {
                internalType: "address",
                name: "deployed",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "getActiveSavingsRate",
        outputs: [
            {
                internalType: "address[]",
                name: "",
                type: "address[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        name: "getAddressFromSymbol",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract ICoreBorrow",
                name: "_coreBorrow",
                type: "address",
            },
            {
                internalType: "address",
                name: "_proxyAdmin",
                type: "address",
            },
        ],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "savingsRate",
                type: "address",
            },
        ],
        name: "isSavingsRate",
        outputs: [
            {
                internalType: "bool",
                name: "status",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "isSupportedImplementation",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "proxyAdmin",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "savingsRate",
                type: "address",
            },
        ],
        name: "revokeSavingsRate",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "saltImplementation",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "savingsRateContractList",
        outputs: [
            {
                internalType: "address",
                name: "contractAddress",
                type: "address",
            },
            {
                internalType: "bool",
                name: "status",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "savingsRateIndex",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_implementation",
                type: "address",
            },
        ],
        name: "toggleSupportForImplementation",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
class SavingsRateFactory__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.SavingsRateFactory__factory = SavingsRateFactory__factory;
SavingsRateFactory__factory.abi = _abi;

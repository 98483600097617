import React, { FunctionComponent, useState } from "react";

import { Button } from "./Button";
import {Icon } from './Icon';
import {Link } from './Link';
import { Checkbox } from "./Checkbox";
import { ActionBtnTypeObj } from "./Table";
import { Tooltip } from './Tooltip';
import { Dropdown, whiteStyle } from './Dropdown';
import millify from 'millify';
import { DragHandle } from "./DragHandle";
interface RowProps {
  row: {
    index: number | string;
    cells: Array<any>;
    getRowProps: () => any;
    originalRow: any;
  };
  rowExpandable: boolean;
  id: number | string;
  actionBtns?: Array<ActionBtnTypeObj>;
  colWidth?: number;
  metaDataContent?: Array<{
    label: string;
    value: string;
    type?: string;
    optionalText?: string;
  }>;
  dropdownActions?: {
    actions: Array<{
      label: string,
      value: any
    }> | null;
    onClickHandler: (e: any) => void;
  }
  isDragging?: boolean;
  dragAttributes?: any;
  dragListner?: any;
  backgroundType?: 'white' | 'base'
  selectedRows?: any;
  rowClickable?: boolean;
  setSelectedRows?: (data) => void;
}

export const renderIcon = (cell) => {
  return (
    <div className={`pl-8`}>
      <Icon name={cell.value === 'completed' ? 'faCheckCircle' : cell.value} size={"lg"}
        style={{
          color: '#52e5a5'
        }}
      />
    </div>
  );
};

export const renderButton = (btn, cell) => {
  let text = btn.text;
  if (text.length === 0) {
    text = btn.getText(cell);
  }
  const disableBtn = cell?.row?.original?.disableBtns;
  return (
    <Button
      style={`w-full inline-flex justify-center`}
      onClickHandler={() => btn.onClickHandler(cell?.row?.original)}
      content={text}
      type={btn.type || "tertiary"}
      size={"xsmall"}
      disabled={
        (cell?.column?.Header.toLowerCase() === "status" &&
          btn.text.toLowerCase() === "inmigration") || disableBtn === text || (disableBtn && disableBtn.indexOf(text) !== -1)
      }
      casing={"uppercase"}
      iconType={cell?.column?.Header.toLowerCase() === "status" ? "svg" : "normal"}
      svgName={
        cell?.column?.Header.toLowerCase() === "status"
          ? btn.text.toLowerCase()
          : null
      }
    />
  );
};

const renderCheckbox = (cell) => {
  return (
    <Checkbox
      textAlign={"right"}
      isChecked={cell.row?.original?.active?.checked}
      onClickHandler={(checked) =>
        cell.value.onClickHandler(cell.row?.original?.id, checked)
      }
    />
  );
};


const renderLink = (text, casing) => {
  return (
    <Link
      onClick={() => console.log("link")}
      content={text}
      target={"_self"}
      underline={true}
    />
  );
};

export const renderText = (text, casing, themeWhite = false) => {
  return <div className={`${casing} ${themeWhite ? whiteStyle.text: ""} text-sm`}>{text}</div>;
};

export const renderMultiLineText = (texts, casing, themeWhite = false) => {
  return <div className={`text-sm ${casing}`}>
    {texts.map((text) => {
            // 123 BTC -> [123, BTC];
            const splitData = text.split(" ");
            return (
              <div className={`whitespace-normal  ${themeWhite ? whiteStyle.text: ""}`}>
                {millify(splitData[0], {
                  precision: 3,
                })}{" "}
                {splitData[1]}
              </div>
            );
          })}
  </div>;
};

export const renderTextWithIcon = (text, themeWhite = false) => {
  return (
    <div className={`flex relative`}>
      <div className={` absolute`}>{ <Icon name={'faClock'} size={"lg"}
        color={themeWhite ? "#000": "#fff"}
      />}</div>
      {<div className={`ml-6 whitespace-normal ${themeWhite ? whiteStyle.text: ""}`}>{text}</div>}
    </div>
  );
};

export const renderCoinText = (text, casing, themeWhite = false) => {
  const tokens = text.split("-");
  
  return (
    <div className={`flex flex-row`}>
    {tokens[0] && (
      <Icon
        token={tokens[0]}
        tokenURL={tokens[0]}
        type={"token"}
        tokenSize="medium"
        color={"#242b57"}
      />
    )}
    {tokens[1] && (
      <Icon
        token={tokens[1]}
        tokenURL={tokens[1]}
        type={"token"}
        tokenSize="medium"
        color={"#242b57"}
      />
    )}
</div>
 
  );
};

export const renderStats = (text, casing?, themeWhite = false) => {
  const val = parseFloat(text);
  return (
    <span
      className={`flex items-center text-base font-medium leading-6 ${val > 0
        ? "text-shamrock-500"
        : val < 0
          ? "text-red-default"
          : themeWhite ? whiteStyle.text: "text-white-400"
        }`}
    >
      {val > 0 ? `+${val}%` : val === 0 ? `${val}%` : `-${val}%`}

    </span>
  );
};

export const renderCustom = (cell) => {
  const isStatus = cell.column.Header.toLowerCase() === "status";
  return (
    <>
      {cell.value?.type === "checkbox" && renderCheckbox(cell)}
      {cell.value?.type === "button" ||
        (isStatus &&
          renderButton(
            {
              text: cell.value || cell.value?.text,
              onClickHandler: cell.value?.onClickHandler,
              type: isStatus ? "rounded" : cell.value?.type,
            },
            cell
          ))}
    </>
  );
};

export const renderMetaData = (data, themeWhite = false) => {
  return <div className={`grid grid-cols-2 gap-2 mt-3`} key={`${data.label}`}>
    <div className={`text-sm ${themeWhite ? whiteStyle.text: "text-white-600"}`}>{data.label}</div>
    <div className={`text-sm ${themeWhite ? whiteStyle.text: "text-white-500"} font-medium 
        ${data.type === 'tooltip' || data.type === 'link' ? 'cursor-pointer' : ''}`}>
      {data.type === 'tooltip' && <Tooltip tooltipContent={data.optionalText} tooltipId={data.label} tooltipClickable={false} />}
      {data.type === 'tooltip' && <div data-tip data-for={data.label}>{data.value}</div>}
      {(data.type === 'text' || data.type === undefined) && data.value}

      {data.type === 'link' && <a rel="noreferrer" href={data.value.link} target="_blank" >{data.value.label}</a>}
    </div>
  </div>
};

export const renderDropdown = (cell, { actions, onClickHandler }, theme="base") => {
  return <div className="mx-2"><Dropdown
    options={actions}
    onSelectHandler={(val) => onClickHandler({ val, cell })}
    type={"normal"}
    hasIcon={false}
    hideSelection={true}
    //@ts-ignore
    backgroundType={theme}
  /></div>;
}

export const renderTooltip = (val: {
  optionalText: string;
  label: string;
  value: string;
}, themeWhite = false) => {
  return <div className={`text-base font-medium leading-6 ${themeWhite ? whiteStyle.text  :"text-white-500"} cursor-pointer`}>
    <Tooltip tooltipContent={val.optionalText} tooltipId={val.label} tooltipClickable={false} />
    <div data-tip data-for={val.label}>{val.value}</div>
  </div>
}

export const Row: FunctionComponent<RowProps> = (props) => {
  const [expanded, setExpanded] = useState(false);
  const { row, id, rowExpandable, actionBtns } = props;
  const themeWhite = props.backgroundType === "white" || props?.selectedRows?.["name"] === row?.["values"]?.name;
  const onExpandable = () => {
    setExpanded(!expanded);
  };
  return (
    <tr
      {...row.getRowProps()}
      className={`flex w-full relative block cursor-pointer my-2
        rounded-xl ${themeWhite ? whiteStyle.background : 'bg-indigo-primary hover:bg-east-bay-500'}`}
      key={`${id} - ${row.index}`}
      onClick={() => {
        if(props["rowClickable"]) {
          props["setSelectedRows"](row);
        } 
      }}
    >
      {!props.isDragging && <div
        className={`flex w-full 
        ${expanded
            ? `rounded-t-xl ${themeWhite ? whiteStyle.background :'bg-east-bay-500'}`
            : `rounded-xl ${themeWhite ? whiteStyle.background : 'bg-indigo-primary hover:bg-east-bay-500 hover:cursor-pointer'}`
          }
      `}
      >
        {row.cells.map((cell, index) => {
          const casing = cell.column.casing || "capitalize";
          const type = cell.column.type || "text";
          const id = cell.column.id;
          const data = cell.render ? cell.render("Cell") : cell.value;
          return (
            <td
              {...cell.getCellProps()}
              className={`px-6 py-2 text-base leading-5	
						flex items-center ${themeWhite ? whiteStyle.text :"text-white-500 "}
					${index === 0 ? "font-medium" : "font-normal"}`}
              style={{
                width: `${props.colWidth}%`,
                "whiteSpace": "normal !important"
              }}
              key={`${id} - ${index}`}
            >
              {(props.isDragging || ((props.dragAttributes && props.dragListner) && index === 0)) && <DragHandle {...props.dragAttributes} {...props.dragListner} isDragging={props.isDragging} />}
              {index === 0 && rowExpandable && (
                <div
                  className={`mr-4 whitespace-nowrap text-sm flex items-center`}
                >
                  <Icon
                    name={expanded ? "faChevronDown" : "faChevronRight"}
                    size={"sm"}
                    onClickHandler={onExpandable}
                    style={{
                      color: expanded ? "#52E5A5" : themeWhite ? whiteStyle.defaultText :"white",
                    }}
                  />
                </div>
              )}
              {type === "icon" && renderIcon(cell)}
              {type === "text" && renderText(data, casing, themeWhite)}
              {type === "multiLineText" && renderMultiLineText(data, casing, themeWhite)}
              {type === "coinText" && renderCoinText(cell.value, casing, themeWhite)}
              {type === "link" && renderLink(data, casing)}
              {type === "stats" && renderStats(cell.value, themeWhite)}
              {type === "checkbox" && renderCheckbox(cell.value)}
              {type === "custom" && renderCustom(cell)}
              {type === "tooltip" && renderTooltip(cell.value, themeWhite)}
              {type === "textWithIcon" && renderTextWithIcon(cell.value, themeWhite)}
              {type === "button" && (
                <div className={`inline-flex`}>
                  {actionBtns.map((btn, i) => (
                    <div className={`${i > 0 ? "ml-2" : ""}`} key={btn.text}>
                      {renderButton(btn, cell)}
                    </div>
                  ))}
                  {actionBtns && actionBtns.length === 0 && <div>
                    <Button
                      style={`w-full inline-flex justify-center`}
                      onClickHandler={() => cell.value.onClickHandler()}
                      content={cell.value?.text}
                      type={cell.value?.type || "tertiary"}
                      size={"small"}
                      casing={"capitalize"}
                    />
                  </div>}
                </div>
              )}
              {row.cells.length - 1 === index && props.dropdownActions && props.dropdownActions.actions.length > 0 && renderDropdown(cell, props.dropdownActions)}
            </td>
          );
        })}
      </div>}

      {expanded && (
        <div
          className={`flex flex-col rounded-b-xl ${themeWhite ? whiteStyle.background :"bg-east-bay-500"}`}
          style={{ maxHeight: 240 }}
        >
          <div className={`border shadow-xs ${themeWhite ? whiteStyle.border : "border-lynch-rgba"}`} />
          <div className="grid grid-cols-2 gap-2 px-10 py-6 sm:px-20 md:px-40 lg:px-52">
            {Object.keys(props.metaDataContent).map((d, i) => (
              <div>{renderMetaData(props.metaDataContent[i], themeWhite)}</div>
            ))}
          </div>
        </div>
      )}
    </tr>
  );
};

Row.defaultProps = {
  row: {
    //@ts-ignore
    cell: [],
    getRowProps: () => undefined,
  },
  rowExpandable: false,
  id: 0,
  actionBtns: [],
  colWidth: 0,
  metaDataContent: [],
};

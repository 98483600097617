import { useConfig, useEthers, useUpdateConfig } from '@usedapp/core';
import { useEffect, useState } from 'react';

export const useChainId = (): [number] => { 
    const { account, chainId } = useEthers();
    const tconfig = useConfig();
    const updateConfig = useUpdateConfig();

    const [iChainId, setIChainId] = useState(tconfig?.readOnlyChainId);
    useEffect(() => {
        if(account && chainId && chainId !== iChainId) {
            setIChainId(chainId);
            updateConfig({...tconfig, readOnlyChainId: Number(chainId)});
        } else if(!account) {
            setIChainId(tconfig?.readOnlyChainId)
        }
    }, [account, tconfig?.readOnlyChainId, chainId, iChainId, tconfig, updateConfig]);

    return [iChainId];
   
}
import { Token } from '@uniswap/sdk-core'
import { FeeAmount } from '@uniswap/v3-sdk'
import { useMemo } from 'react'
import { TickProcessed, useConcentratedActiveLiquidity } from './useConcentratedActiveLiquidity'
import { Protocol } from 'utils/types'

export interface ChartEntry {
  activeLiquidity: number
  price0: number
}

export function  useDensityChartData({
  chainId,
  token0,
  token1,
  feeAmount,
  protocol
}: {
  chainId: number | undefined
  token0: Token | undefined
  token1: Token | undefined
  feeAmount: FeeAmount | undefined
  protocol: Protocol
}) {
  const { isLoading, error, data } = useConcentratedActiveLiquidity({
    chainId: chainId,
    token0,
    token1,
    feeAmount,
    protocol
  })
  const formatData = useMemo(() => {
    if (!data?.length) {
      return undefined
    }

    const newData: ChartEntry[] = []

    for (let i = 0; i < data.length; i++) {
      const t: TickProcessed = data[i]

      const chartEntry = {
        activeLiquidity: parseFloat(t.liquidityActive.toString()),
        price0: parseFloat(t.price0),
        tick: t.tick
      }

      // if (chartEntry.activeLiquidity > 0) {
        newData.push(chartEntry)
      // }
    }

    return newData
  }, [data])

  

  return useMemo(() => {
    return {
      isLoading,
      error,
      formattedData: !isLoading ? formatData : undefined,
    }
  }, [isLoading, error, formatData])
}

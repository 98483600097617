"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeHedgeRatio = void 0;
const ethers_1 = require("ethers");
const bignumber_1 = require("../utils/bignumber");
function computeHedgeRatio(currentCoveredAmount, targetHAHedge, stocksUsers) {
    currentCoveredAmount = ethers_1.BigNumber.from(currentCoveredAmount);
    targetHAHedge = ethers_1.BigNumber.from(targetHAHedge);
    stocksUsers = ethers_1.BigNumber.from(stocksUsers);
    const targetCoveredAmount = stocksUsers.mul(targetHAHedge).div((0, bignumber_1.gwei)(1));
    if (currentCoveredAmount.lt(targetCoveredAmount) && !targetCoveredAmount.eq(0)) {
        return currentCoveredAmount.mul((0, bignumber_1.gwei)(1)).div(targetCoveredAmount);
    }
    else {
        return (0, bignumber_1.gwei)(1);
    }
}
exports.computeHedgeRatio = computeHedgeRatio;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const addresses = {
    ANGLE: '0xB7e3617aDB58dc34068522bD20Cfe1660780B750',
    agEUR: {
        bridges: {
            RainbowBridge: '0xdc7AcDE9ff18B4D189010a21a44cE51ec874eA7C',
        },
    },
    ExternalStakings: [],
    Governor: '0xb87a5C4f023e6b9F0B78905dB0B00f82e47952bE',
    Guardian: '0x9864AeBFF39e1ED02545856aE0e595E97fC6fCb4',
};
exports.default = addresses;

import { FunctionComponent, useState, useEffect } from "react";
import {
  Button,
  Modal,
  IconDetailBox,
  Label,
  Search
} from "components/lib";
import { useHistory } from "react-router-dom";
import { getClient } from "services/graphql";
import { supportedChains, paginateData } from "utils";
import { useConfig, useEthers } from "@usedapp/core";
import FundGas from "pages/apps/dashboard/modals/fundGas";
import { gql, useLazyQuery } from "@apollo/client";
import { TransparentCard } from "components/TransparentCard";
import TitleWithDocLink from "components/TitleWithDocLink";
import AutomationCard from "components/customCards/AutomationCard";
import { useChainId } from "hooks/useChainId";

const GET_VAULT = gql`
  query getVaults($deployer: String!, $beaconName: String!) {
    vaults(where: { deployer: $deployer, beaconName: $beaconName }) {
      beaconName
      deployer
      id
    }
  }
`;

const FETCH_JOBS = gql`
  {
    jobs(orderBy: timestamp, orderDirection: desc) {
      id
      name
      timestamp
      targetAddresses
      status
      jobInfo
      gasUsed
      ipfsHash
      vaultAddress {
        deployer
        id
      }
    }
  }
`;

const Dashboard: FunctionComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<"deposit" | "withdraw">();
  const [jobs, setJobs] = useState([]);
  const [gqlJobCall, setJobGqlCall] = useState(false);
  const [gqlVaultCall, setVaultGqlCall] = useState(false);
  const [vault, setVault] = useState(null);
  const { account } = useEthers();

const [chainId] = useChainId();
  const [debouncedTerm, setDebouncedTerm] = useState(null);
  const pageIndex = 0;

  const history = useHistory();
  let graphClient = getClient(supportedChains[0].subgraphURl);
  const tconfig = useConfig();
  const supportedChain = supportedChains.find((chain) => {
    if(chainId) {
      return chain.id === chainId;
    } else {
      return chain.id === tconfig.readOnlyChainId
    }
  });  

  if (supportedChain) {
    graphClient = getClient(supportedChain.subgraphURl);
  }
  const [getJobs, jobsData] = useLazyQuery(FETCH_JOBS, {
    client: graphClient,
  });

  const [getVault, vaultData] = useLazyQuery(GET_VAULT, {
    client: graphClient,
  });

  const handleUserKeyPress = (event) => {
    const { keyCode } = event;
    if (keyCode === 27) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", handleUserKeyPress);
    return () => {
      window.removeEventListener("keyup", handleUserKeyPress);
    };
  }, []);

  useEffect(() => {
    let notUpdateState = false;
    if (!notUpdateState) {
      if (!gqlJobCall) {
        getJobs();
        setJobGqlCall(true);
      }
      if (!gqlVaultCall && account) {
        getVault({ variables: { deployer: account.toLowerCase(), beaconName: "ScheduledJobs" } });
        setVaultGqlCall(true);
      }
    }
    return () => {
      notUpdateState = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, gqlJobCall, gqlVaultCall]);

  useEffect(() => {
    if (jobsData?.data?.jobs && jobs.length === 0) {
      const jobReceived = jobsData?.data?.jobs;
      const pageBreakupData = paginateData(jobReceived);
      // const pageCount = Math.ceil(jobReceived.length / pageSize);
      if (jobs.length === 0) {
        // setPageCount(pageCount);
        setJobs(pageBreakupData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobsData.data]);

  useEffect(() => {
    if (vaultData?.data?.vaults && vaultData?.data?.vaults[0]) {
      const vaultId = vault?.id;
      const newVaultId = vaultData?.data?.vaults[0]?.id;
      if (vaultId !== newVaultId) {
        setVault(vaultData?.data?.vaults[0]);
      }
    } else {
      if (vault) {
        setVault(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vaultData]);

  useEffect(() => {
    if(chainId) {
      setJobs([]);
    }
  }, [chainId]);

  useEffect(() => {
    if (account) {
      vaultData.refetch({ deployer: account.toLowerCase(), beaconName: "ScheduledJobs" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const onSearch = (text) => {
    const _text = text.toLowerCase();
    if (text.length > 1) {
      const searchedData = jobsData?.data?.jobs.filter((d) => {
        return (
          d.name.toLowerCase().indexOf(_text) !== -1
        );
      });
      if(searchedData.length > 0) {
        const pageBreakupData = paginateData(searchedData);
        setJobs(pageBreakupData);
      }
    } else {
      const pageBreakupData = paginateData(jobsData?.data?.jobs);
      setJobs(pageBreakupData);
    }
  };


  useEffect(() => {
    if(debouncedTerm !== null) {
      const timer = setTimeout(() => onSearch(debouncedTerm), 1000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [debouncedTerm])


  // const fetchRowsData = (pageIndex, pageSize) => {
  //   setPageIndex(pageIndex);
  //   return jobs.length > pageIndex ? getJobPageDataObjs : [];
  // };

  // const getJobPageDataObjs = () => {
  //   return jobs.length > 0 ? jobs[pageIndex] : [];
  // };

  const getModalContainer = () => {
    if (modalType === "deposit") {
      return <FundGas vault={vault} closeModal={() => setIsModalOpen(false)} />;
    }
    if (modalType === "withdraw") {
      return (
        <FundGas type={'withdraw'} vault={vault} closeModal={() => setIsModalOpen(false)} />
      );
    }
    return <></>;
  };
  const registerBtn = () => {
    return <Button
      onClickHandler={() => {
        history.push("/register-automation");
      }}
      content={"Add Automation"}
      casing={`uppercase`}
      type={`tertiary`}
      size={"small"}
      disabled={!account}
    />
  }
  const renderBtns = () => {
    return (
      <div className="flex mb-8 justify-evenly md:flex-col md:justify-end lg:flex-row">
        <div className="mx-4 my-2 lg:my-0">
          <Button
            onClickHandler={() => {
              setIsModalOpen(true);
              setModalType("deposit");
            }}
            content={"Deposit Gas"}
            casing={`uppercase`}
            type={`tertiary`}
            size={"small"}
            disabled={
              !gqlVaultCall || !vault || jobs.length === 0 || !account
            }
          />
        </div>
        <div className="mx-4 my-2 lg:my-0">
          <Button
            onClickHandler={() => {
              setIsModalOpen(true);
              setModalType("withdraw");
            }}
            content={"Withdraw Gas"}
            casing={`uppercase`}
            type={`secondary`}
            size={"small"}
            disabled={!gqlVaultCall || !vault || jobs.length === 0 || !account}
          />
        </div>
        <div className="hidden mx-4 my-2 md:block lg:my-0">
          {registerBtn()}
        </div>
      </div>
    );
  };

  return (
    <>
       <div
      className={`py-4 m-auto lg:h-full w-10/12 lg:w-9/12`}
    >
        <TransparentCard>
        <TitleWithDocLink title={"Recurring Automations"} />
        {renderBtns()}
        {!account && (
          <div className={`flex flex-end justify-end my-4 px-4`}>
            <IconDetailBox
              msg="You need connect your wallet to register a automation"
              icon="faWallet"
            />
          </div>
        )}
        <div className="flex mx-4 my-8 md:hidden">
          {registerBtn()}
        </div>
        <div className="grid-cols-1 px-4 lg:px-0 lg:grid">
            <Search
              placeholder={`Search by Name`}
              onChangeHandler={(val) => setDebouncedTerm(val)}
              backgroundType="white"
            />
          </div>

          <div className="flex flex-wrap items-center justify-evenly">
            {!jobsData.loading && jobs.length > 0 && jobs[pageIndex].sort((a,b) => b.timestamp - a.timestamp).map((item) => {
              return (
                <div className="flex py-4">
                  <AutomationCard
                    info={{...item, chainId, onClick: () => {
                      history.push(`/automation/${item.id}`);
                    }}}
                    key={item.id}
                  />
                </div>
              );
            })}
          </div>

        {!jobsData.loading && jobs.length === 0 && (
          <div className="flex flex-col items-center justify-center">
            <div className="text-3xl">📝</div>
            <div className="my-4">
              <Label
                content={
                  "No Automation Added."
                }
                align={`center`}
                size={`large`}
                color={`white-500`}
              />
            </div>
          </div>
        )}
          </TransparentCard>

      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          size={"large"}
          content={getModalContainer()}
        />
      )}
    </>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import { useEthers } from "@usedapp/core";
import { ethers, utils } from "ethers";
import {
  Input,
  IconDetailBox,
  Dropdown,
  Icon,
  Textarea,
} from "components/lib";

import SharedButtons from "pages/apps/publish/common/sharedButton";
import { getAbiFromContractAddress, supportedChainIds } from "utils";
import { EthereumProvider, getImplementationAddress } from "utils/proxyToImplementation";
import { sendNotification } from "components/Container";
import { useChainId } from "hooks/useChainId";

const ContractInfo = ({
  steps,
  setSteps,
  setData,
  data,
}: {
  steps: number;
  setSteps: (step: number) => void;
  setData: (data: any) => void;
  data: any;
}) => {
  const { account, library } = useEthers();
  const [chainId] = useChainId();

  const [contractAddress, setContractAddress] = useState(data?.contractAddress);
  const vaildContractAddress = ethers.utils.isAddress(contractAddress);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(data?.selectedOption);
  const [callData, setCallData] = useState(data?.callData);
  const [loading, setLoading] = useState(false);
  const [contractAbi, setContractAbi] = useState('');
  const [isContractAbiValid, setIsContractAbiValid] = useState(true);


  const getFunctionParameter = (d) => {
    return d.reduce((acc, i) => {
      if (acc.length === 0) {
        acc = i.type + " " + i.name;
      } else {
        acc = `${acc}, ${i.type} ${i.name}`;
      }
      return acc;
    }, "");
  };

  const updateContractAddress = (val: string) => {
    setContractAbi('');
    setDropdownOptions([]);
    setContractAddress(val);
  }

  useEffect(() => {
    if (vaildContractAddress) {
      if (supportedChainIds.indexOf(chainId) !== -1) {
        (async () => {
          setLoading(true);
          setContractAbi('');
          let address = await getImplementationAddress(
            library as EthereumProvider,
            contractAddress
          );
          
          if (address === "0x0000000000000000000000000000000000000000") {
            address = contractAddress;
          }
          try {
            const abi = await getAbiFromContractAddress(address, chainId);
            
            const iface = new utils.Interface(abi);
            if (iface.fragments.length > 0) {
              setIsContractAbiValid(true);
              setContractAbi(JSON.stringify(abi, null, 2));
              updateDropDownOptions(abi);
            } else {
              setIsContractAbiValid(false);
            }
          } catch (e) {
            setIsContractAbiValid(false);
            console.log({ e });
            sendNotification({
              type: "error",
              transactionName: `Contract not verified`,
              transaction: {
                ...e,
                msg: `Please enter contract ABI!`,
              },
            });
          }
          setLoading(false);
        })();
      } else {
        sendNotification({
          type: "error",
          transactionName: `Bad Network Configuration`,
          transaction: {
            msg: `Please enter contract ABI!`,
          },
        });
      }
    } else {
      setDropdownOptions([]);
      setSelectedOption(undefined);
      setCallData(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vaildContractAddress, contractAddress]);

  const updateData = (val: string | number | object, key: string) => {
    const tempObj: Record<string, unknown> = {};
    if (typeof val === "string") {
      tempObj[key] = String(val);
    } else {
      tempObj[key] = val;
    }
    setCallData({
      ...callData,
      ...tempObj,
    });
  };

  const nextStep = () => {
    setData({
      contractAddress,
      callData,
      selectedOption,
    });
    setSteps(steps + 1);
  };

  const updateDropDownOptions = (abi: any) => {
    setDropdownOptions(
      abi
        .filter((d) => d.type === "function")
        .filter((d) => d.stateMutability !== "view")
        .map((d) => {
          const params = getFunctionParameter(d.inputs);
          return {
            label: `${d.name} (${params})`,
            value: d,
          };
        }
      )
    );
  }

  const updateContractAbi = (val: string) => {
 
    try { 
      setDropdownOptions([]);
      const abi = JSON.parse(val);
      const iface = new utils.Interface(abi);
      if (iface.fragments.length > 0) {
        setIsContractAbiValid(true);
        updateDropDownOptions(abi);
        setContractAbi(JSON.stringify(abi, null, 2));
      } else {
        setIsContractAbiValid(false);
      }
    } catch (e) {
      console.log({ e });
      setIsContractAbiValid(false);
    }
  }

  return (
    <div>
      {!account && (
        <div className={`mt-4 px-4`}>
          <IconDetailBox
            msg="You need connect your wallet in order to schedule a job."
            icon="faWallet"
          />
        </div>
      )}
      <div className={`mt-4 px-4`}>
        <Input
          heading={"Contract Address"}
          val={contractAddress}
          onChange={updateContractAddress}
          align={"vertical"}
          placeholder={"Ox..."}
          haserror={
            contractAddress !== undefined ? !vaildContractAddress : false
          }
          optionalText={loading && <Icon spin={true} name="faSpinner" />}
          backgroundType="white"
        />
      </div>

      <div className={`mt-4 px-4`}>
      <Textarea
          heading={"Contract ABI"}
          headingColor="white-500"
          val={contractAbi}
          onChange={updateContractAbi}
          align={"vertical"}
          placeholder={!isContractAbiValid ? "Add the ABI for non verified contract": "...."}
          rows={6}
          disabled={!contractAddress || !vaildContractAddress || loading}
          hasError={
            !isContractAbiValid
          }
          backgroundType="white"
          optionalText={contractAbi.length > 0 && !isContractAbiValid ? `Invalid ABI`: ''}
        />
      </div>
      {dropdownOptions.length > 0 && (
        <div className={`mt-4 px-4`}>
          <Dropdown
            options={dropdownOptions}
            onSelectHandler={setSelectedOption}
            placeholder={"Select function"}
            type={"normal"}
            hasIcon={false}
            selected={selectedOption}
            backgroundType="white"
          />
        </div>
      )}
      {selectedOption &&
        selectedOption.inputs.map((d) => (
          <div className={`mt-4 px-4`}>
            <Input
              heading={`${d.type} ${d.name}`}
              val={""}
              onChange={(val) => updateData(val, d.name)}
              align={"vertical"}
              placeholder={"value"}
              headingCase={"lowercase"}
              backgroundType="white"
            />
          </div>
        ))}

      <SharedButtons
        steps={steps}
        setSteps={setSteps}
        nextStep={nextStep}
        showOnlyNext={true}
        isDisabled={!vaildContractAddress || !account || !selectedOption}
      />
    </div>
  );
};

export default ContractInfo;

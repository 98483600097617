import React, { FunctionComponent, useState } from "react";
import { Icon } from "./Icon";

export interface SearchProps {
  text?: string;
  onChangeHandler: (e?: any) => void;
  placeholder?: string;
  backgroundType?: "white" | "base";
}

export const whiteStyle = {
  border: "border-gray-100",
  text: "text-black-500",
  background: "bg-white-500",
  placeholder: "placeholder-gray-500 focus:placeholder-black-500",
  iconColor: "#52e5a5",
  defaultBorder: "#f4f5f7",
  defaultText: "#242b57",
  defaultBackground: "#ffffff",
  defaultPlaceholder: "#6b7280",
  defaultPlaceholderFocus: "#242b57"
};

export const Search: FunctionComponent<SearchProps> = (props) => {
  const [value, setValue] = useState(props.text);

  const onChange = (e) => {
    setValue(e.target.value);
    if (props.onChangeHandler) {
      props.onChangeHandler(e.target.value);
    }
  };
  const themeWhite = props.backgroundType === "white";
  return (
    <div data-testid="search-component">
      <div className="block mb-3">
        <label htmlFor="search-field" className="sr-only">
          Search
        </label>
        <div
          className={`relative   ${
            themeWhite ? "" : "border border-lynch-500 rounded"
          } `}
        >
          <div
            className={`absolute top-3 left-1  flex items-center pl-4 pointer-events-none rounded ${
              themeWhite ? whiteStyle.background : "bg-rhino-500"
            }`}
          >
            <Icon name={"faSearch"} size={"sm"} style={{ color: themeWhite ? whiteStyle.iconColor : "#5C738A" }} />
          </div>
          <input
            id="search-field"
            className={`
                block py-3 pl-10 pr-3 
                ${
                  themeWhite
                    ? `${whiteStyle.placeholder} ${whiteStyle.background} ${whiteStyle.text}`
                    : "placeholder-lynch-500 focus:placeholder-lynch-500 bg-rhino-500 text-white-500 "
                }
                 
                focus:outline-none 
                 focus:ring-0 
                rounded
                focus:border-none
              `}
            placeholder={props.placeholder || "search"}
            type="search"
            name="Search"
            value={value || ""}
            onChange={onChange}
            style={{
              width: "100%",
            }}
          />
        </div>
      </div>
    </div>
  );
};

Search.defaultProps = {
  text: "",
  onChangeHandler: () => undefined,
};

import { useQuery } from "@tanstack/react-query";
// import { useEvmosTemp } from "hooks/useEvmosTemp";
import { supportedChains } from "utils";
import { useChainId } from "../hooks/useChainId";

const FETCH_VAULTS = (ids: Array<String>) => `
query {
  vaults(where:{
    id_in: ${JSON.stringify(ids)}
  }) {
    id
    decimals
    token0Balance
    token0Decimals
    token1Decimals
    token1Balance
    totalLPTokensIssued
    pool
    token0
    token1
  }
}`;

export const useStakePoolVault = (vaultIds: Array<string>) => {
  const [chainId] = useChainId();
  // const {evmosVaultDetails} = useEvmosTemp();

  let supportedChain = supportedChains.find(
    ({ id }) => id === chainId
  );
  let subgraphURl = null;
  if(supportedChain) {
    subgraphURl = supportedChain?.subgraphURl;
  }
  return useQuery(
    ["vaultForStakePool", vaultIds, subgraphURl],
    async () => {
      if(vaultIds.length === 0) {
        return [];
      }
      // if(chainId === 9001 && evmosVaultDetails?.["data"]) {
      //   return vaultIds.map(d => {
      //     return evmosVaultDetails?.["data"].find(x => x.id === d);
      //   })
      // }
      const res = await fetch(subgraphURl!, {
        method: "POST",
        body: JSON.stringify({
          query: FETCH_VAULTS(vaultIds),
          variables: {},
        }),
      });

      const data = await res.json();
      return data?.data?.vaults;
    },
    {
      enabled: !!subgraphURl && !!vaultIds && vaultIds.length > 0,
    }
  );
};




import ModalHeader from "./ModalHeader";

const GeoBlockModal = (props) => {
  return (
    <div className={``}>
      <ModalHeader
        closeModal={props.closeModal}
        description={null}
        title={`Feature restricted`}
      />
      <div className="flex flex-col items-center mt-6 text-base text-white-500">
        <span className="mb-2">
          Certain features of our app have been blocked due to compliance with
          the Office of Foreign Assets Control (OFAC) list. The OFAC list
          contains names of individuals, organizations, and countries that the
          United States government has placed under economic and trade
          sanctions.
        </span>
        <span className="my-2">
          As a result, certain features of our app may not be accessible to
          users located in sanctioned countries. We understand that this may
          cause inconvenience to some of our users, but compliance with OFAC
          regulations is important for us to continue providing our services to
          a wide range of users.
        </span>

        <span className="my-2">
          We apologize for any inconvenience this may cause and appreciate your
          understanding. If you have any questions or concerns regarding this
          matter, please do not hesitate to contact with chat interface.
        </span>
      </div>
    </div>
  );
};

export default GeoBlockModal;

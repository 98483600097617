"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ALL_TOKENS = void 0;
const ethers_1 = require("ethers");
const types_1 = require("../types");
const contracts_1 = require("./contracts");
const listStables = (chainId) => {
    return Object.keys(contracts_1.CONTRACTS_ADDRESSES[chainId])
        .filter((key) => key.startsWith('ag'))
        .map((key) => {
        var _a;
        const contractAddress = (_a = (0, contracts_1.registry)(chainId, key)) === null || _a === void 0 ? void 0 : _a.AgToken;
        if (!!contractAddress)
            return { key: key, contractAddress: contractAddress };
        return { key: '', contractAddress: '' };
    })
        .filter((obj) => {
        return !!obj.key && !!obj.contractAddress;
    })
        .map((obj) => {
        return new types_1.Token(chainId, obj.contractAddress, 18, obj.key, `Angle ${obj.key.slice(2)}`, true);
    });
};
const arrayOfTokensToTokenDict = (arr) => {
    return arr.reduce((acc, token) => {
        return Object.assign(Object.assign({}, acc), { [token.address.toLowerCase()]: token });
    }, {});
};
exports.ALL_TOKENS = {
    [types_1.AssetType.STABLE]: arrayOfTokensToTokenDict(listStables(types_1.ChainId.MAINNET)),
    [types_1.AssetType.COLLATERAL]: arrayOfTokensToTokenDict([
        new types_1.Token(types_1.ChainId.MAINNET, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18, 'DAI', 'Dai Stablecoin'),
        new types_1.Token(types_1.ChainId.MAINNET, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD Coin'),
        new types_1.Token(types_1.ChainId.MAINNET, '0x956f47f50a910163d8bf957cf5846d573e7f87ca', 18, 'FEI', 'Fei USD'),
        new types_1.Token(types_1.ChainId.MAINNET, '0x853d955acef822db058eb8505911ed77f175b99e', 18, 'FRAX', 'FRAX'),
        new types_1.Token(types_1.ChainId.MAINNET, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', 18, 'wETH', 'Wrapped Ether'),
    ]),
    [types_1.AssetType.EXTERNAL_STAKING]: arrayOfTokensToTokenDict([
        new types_1.Token(types_1.ChainId.MAINNET, '0x2bD9F7974Bc0E4Cb19B8813F8Be6034F3E772add', 18, 'G-Uni agEUR/USDC old', 'Gelato Uniswap agEUR/USDC LP old'),
        new types_1.Token(types_1.ChainId.MAINNET, '0xEDECB43233549c51CC3268b5dE840239787AD56c', 18, 'G-Uni agEUR/USDC', 'Gelato Uniswap agEUR/USDC LP'),
        new types_1.Token(types_1.ChainId.MAINNET, '0x26C2251801D2cfb5461751c984Dc3eAA358bdf0f', 18, 'G-Uni agEUR/wETH old', 'Gelato Uniswap agEUR/wETH LP old'),
        new types_1.Token(types_1.ChainId.MAINNET, '0x857E0B2eD0E82D5cDEB015E77ebB873C47F99575', 18, 'G-Uni agEUR/wETH', 'Gelato Uniswap agEUR/wETH LP'),
        new types_1.Token(types_1.ChainId.MAINNET, '0x1f4c763bde1d4832b3ea0640e66da00b98831355', 18, 'SLP agEUR/ANGLE', 'SushiSwap LP agEUR/ANGLE'),
        new types_1.Token(types_1.ChainId.MAINNET, '0xb9446c4Ef5EBE66268dA6700D26f96273DE3d571', 18, '3EURpool-f', 'Curve.fi Factory Plain Pool: 3EURpool'),
        new types_1.Token(types_1.ChainId.MAINNET, '0xb37d6c07482bc11cd28a1f11f1a6ad7b66dec933', 18, 'ag+ib-EUR-f', 'Curve.fi Factory Plain Pool:ag+ib-EUR-f'),
        new types_1.Token(types_1.ChainId.MAINNET, '0xF89CE5eD65737dA8440411544b0499c9FaD323B2', 18, 'UNI-V2 agEUR/FEI', 'Uniswap V2 agEUR/FEI'),
    ]),
    [types_1.AssetType.ANGLE]: new types_1.Token(types_1.ChainId.MAINNET, (_a = contracts_1.CONTRACTS_ADDRESSES[types_1.ChainId.MAINNET].ANGLE) !== null && _a !== void 0 ? _a : ethers_1.constants.AddressZero, 18, 'ANGLE', 'Angle Governance Token'),
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DistributionCreator__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        inputs: [],
        name: "InvalidParam",
        type: "error",
    },
    {
        inputs: [],
        name: "InvalidReward",
        type: "error",
    },
    {
        inputs: [],
        name: "InvalidSignature",
        type: "error",
    },
    {
        inputs: [],
        name: "NotGovernorOrGuardian",
        type: "error",
    },
    {
        inputs: [],
        name: "NotSigned",
        type: "error",
    },
    {
        inputs: [],
        name: "ZeroAddress",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "previousAdmin",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "newAdmin",
                type: "address",
            },
        ],
        name: "AdminChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "beacon",
                type: "address",
            },
        ],
        name: "BeaconUpgraded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "_distributor",
                type: "address",
            },
        ],
        name: "DistributorUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "userFeeRebate",
                type: "uint256",
            },
        ],
        name: "FeeRebateUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "_feeRecipient",
                type: "address",
            },
        ],
        name: "FeeRecipientUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "_fees",
                type: "uint256",
            },
        ],
        name: "FeesSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint8",
                name: "version",
                type: "uint8",
            },
        ],
        name: "Initialized",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "_messageHash",
                type: "bytes32",
            },
        ],
        name: "MessageUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                components: [
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "rewardToken",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken0",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "isOutOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes",
                        name: "additionalData",
                        type: "bytes",
                    },
                ],
                indexed: false,
                internalType: "struct DistributionParameters",
                name: "distribution",
                type: "tuple",
            },
            {
                indexed: true,
                internalType: "address",
                name: "sender",
                type: "address",
            },
        ],
        name: "NewDistribution",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "RewardTokenMinimumAmountUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "toggleStatus",
                type: "uint256",
            },
        ],
        name: "TokenWhitelistToggled",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "implementation",
                type: "address",
            },
        ],
        name: "Upgraded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "messageHash",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "UserSigned",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "toggleStatus",
                type: "uint256",
            },
        ],
        name: "UserSigningWhitelistToggled",
        type: "event",
    },
    {
        inputs: [],
        name: "BASE_9",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "EPOCH_DURATION",
        outputs: [
            {
                internalType: "uint32",
                name: "",
                type: "uint32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "core",
        outputs: [
            {
                internalType: "contract ICore",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "rewardToken",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken0",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "isOutOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes",
                        name: "additionalData",
                        type: "bytes",
                    },
                ],
                internalType: "struct DistributionParameters",
                name: "distribution",
                type: "tuple",
            },
        ],
        name: "createDistribution",
        outputs: [
            {
                internalType: "uint256",
                name: "distributionAmount",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "rewardToken",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken0",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "isOutOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes",
                        name: "additionalData",
                        type: "bytes",
                    },
                ],
                internalType: "struct DistributionParameters[]",
                name: "distributions",
                type: "tuple[]",
            },
        ],
        name: "createDistributions",
        outputs: [
            {
                internalType: "uint256[]",
                name: "",
                type: "uint256[]",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "distributionList",
        outputs: [
            {
                internalType: "bytes32",
                name: "rewardId",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "uniV3Pool",
                type: "address",
            },
            {
                internalType: "address",
                name: "rewardToken",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                internalType: "uint32",
                name: "propToken0",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "propToken1",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "propFees",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "epochStart",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "numEpoch",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "isOutOfRangeIncentivized",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "boostedReward",
                type: "uint32",
            },
            {
                internalType: "address",
                name: "boostingAddress",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "additionalData",
                type: "bytes",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "distributor",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "feeRebate",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "feeRecipient",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "fees",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getActiveDistributions",
        outputs: [
            {
                components: [
                    {
                        components: [
                            {
                                internalType: "bytes32",
                                name: "rewardId",
                                type: "bytes32",
                            },
                            {
                                internalType: "address",
                                name: "uniV3Pool",
                                type: "address",
                            },
                            {
                                internalType: "address",
                                name: "rewardToken",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "amount",
                                type: "uint256",
                            },
                            {
                                internalType: "address[]",
                                name: "positionWrappers",
                                type: "address[]",
                            },
                            {
                                internalType: "uint32[]",
                                name: "wrapperTypes",
                                type: "uint32[]",
                            },
                            {
                                internalType: "uint32",
                                name: "propToken0",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "propToken1",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "propFees",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "epochStart",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "numEpoch",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "isOutOfRangeIncentivized",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "boostedReward",
                                type: "uint32",
                            },
                            {
                                internalType: "address",
                                name: "boostingAddress",
                                type: "address",
                            },
                            {
                                internalType: "bytes",
                                name: "additionalData",
                                type: "bytes",
                            },
                        ],
                        internalType: "struct DistributionParameters",
                        name: "base",
                        type: "tuple",
                    },
                    {
                        internalType: "uint24",
                        name: "poolFee",
                        type: "uint24",
                    },
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "add",
                                type: "address",
                            },
                            {
                                internalType: "uint8",
                                name: "decimals",
                                type: "uint8",
                            },
                            {
                                internalType: "string",
                                name: "symbol",
                                type: "string",
                            },
                            {
                                internalType: "uint256",
                                name: "poolBalance",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct UniswapTokenData",
                        name: "token0",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "add",
                                type: "address",
                            },
                            {
                                internalType: "uint8",
                                name: "decimals",
                                type: "uint8",
                            },
                            {
                                internalType: "string",
                                name: "symbol",
                                type: "string",
                            },
                            {
                                internalType: "uint256",
                                name: "poolBalance",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct UniswapTokenData",
                        name: "token1",
                        type: "tuple",
                    },
                    {
                        internalType: "string",
                        name: "rewardTokenSymbol",
                        type: "string",
                    },
                    {
                        internalType: "uint8",
                        name: "rewardTokenDecimals",
                        type: "uint8",
                    },
                ],
                internalType: "struct ExtensiveDistributionParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "uniV3Pool",
                type: "address",
            },
        ],
        name: "getActivePoolDistributions",
        outputs: [
            {
                components: [
                    {
                        components: [
                            {
                                internalType: "bytes32",
                                name: "rewardId",
                                type: "bytes32",
                            },
                            {
                                internalType: "address",
                                name: "uniV3Pool",
                                type: "address",
                            },
                            {
                                internalType: "address",
                                name: "rewardToken",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "amount",
                                type: "uint256",
                            },
                            {
                                internalType: "address[]",
                                name: "positionWrappers",
                                type: "address[]",
                            },
                            {
                                internalType: "uint32[]",
                                name: "wrapperTypes",
                                type: "uint32[]",
                            },
                            {
                                internalType: "uint32",
                                name: "propToken0",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "propToken1",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "propFees",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "epochStart",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "numEpoch",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "isOutOfRangeIncentivized",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "boostedReward",
                                type: "uint32",
                            },
                            {
                                internalType: "address",
                                name: "boostingAddress",
                                type: "address",
                            },
                            {
                                internalType: "bytes",
                                name: "additionalData",
                                type: "bytes",
                            },
                        ],
                        internalType: "struct DistributionParameters",
                        name: "base",
                        type: "tuple",
                    },
                    {
                        internalType: "uint24",
                        name: "poolFee",
                        type: "uint24",
                    },
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "add",
                                type: "address",
                            },
                            {
                                internalType: "uint8",
                                name: "decimals",
                                type: "uint8",
                            },
                            {
                                internalType: "string",
                                name: "symbol",
                                type: "string",
                            },
                            {
                                internalType: "uint256",
                                name: "poolBalance",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct UniswapTokenData",
                        name: "token0",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "add",
                                type: "address",
                            },
                            {
                                internalType: "uint8",
                                name: "decimals",
                                type: "uint8",
                            },
                            {
                                internalType: "string",
                                name: "symbol",
                                type: "string",
                            },
                            {
                                internalType: "uint256",
                                name: "poolBalance",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct UniswapTokenData",
                        name: "token1",
                        type: "tuple",
                    },
                    {
                        internalType: "string",
                        name: "rewardTokenSymbol",
                        type: "string",
                    },
                    {
                        internalType: "uint8",
                        name: "rewardTokenDecimals",
                        type: "uint8",
                    },
                ],
                internalType: "struct ExtensiveDistributionParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getAllDistributions",
        outputs: [
            {
                components: [
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "rewardToken",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken0",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "isOutOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes",
                        name: "additionalData",
                        type: "bytes",
                    },
                ],
                internalType: "struct DistributionParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint32",
                name: "epochStart",
                type: "uint32",
            },
        ],
        name: "getDistributionsAfterEpoch",
        outputs: [
            {
                components: [
                    {
                        components: [
                            {
                                internalType: "bytes32",
                                name: "rewardId",
                                type: "bytes32",
                            },
                            {
                                internalType: "address",
                                name: "uniV3Pool",
                                type: "address",
                            },
                            {
                                internalType: "address",
                                name: "rewardToken",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "amount",
                                type: "uint256",
                            },
                            {
                                internalType: "address[]",
                                name: "positionWrappers",
                                type: "address[]",
                            },
                            {
                                internalType: "uint32[]",
                                name: "wrapperTypes",
                                type: "uint32[]",
                            },
                            {
                                internalType: "uint32",
                                name: "propToken0",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "propToken1",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "propFees",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "epochStart",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "numEpoch",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "isOutOfRangeIncentivized",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "boostedReward",
                                type: "uint32",
                            },
                            {
                                internalType: "address",
                                name: "boostingAddress",
                                type: "address",
                            },
                            {
                                internalType: "bytes",
                                name: "additionalData",
                                type: "bytes",
                            },
                        ],
                        internalType: "struct DistributionParameters",
                        name: "base",
                        type: "tuple",
                    },
                    {
                        internalType: "uint24",
                        name: "poolFee",
                        type: "uint24",
                    },
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "add",
                                type: "address",
                            },
                            {
                                internalType: "uint8",
                                name: "decimals",
                                type: "uint8",
                            },
                            {
                                internalType: "string",
                                name: "symbol",
                                type: "string",
                            },
                            {
                                internalType: "uint256",
                                name: "poolBalance",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct UniswapTokenData",
                        name: "token0",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "add",
                                type: "address",
                            },
                            {
                                internalType: "uint8",
                                name: "decimals",
                                type: "uint8",
                            },
                            {
                                internalType: "string",
                                name: "symbol",
                                type: "string",
                            },
                            {
                                internalType: "uint256",
                                name: "poolBalance",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct UniswapTokenData",
                        name: "token1",
                        type: "tuple",
                    },
                    {
                        internalType: "string",
                        name: "rewardTokenSymbol",
                        type: "string",
                    },
                    {
                        internalType: "uint8",
                        name: "rewardTokenDecimals",
                        type: "uint8",
                    },
                ],
                internalType: "struct ExtensiveDistributionParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint32",
                name: "epochStart",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "epochEnd",
                type: "uint32",
            },
        ],
        name: "getDistributionsBetweenEpochs",
        outputs: [
            {
                components: [
                    {
                        components: [
                            {
                                internalType: "bytes32",
                                name: "rewardId",
                                type: "bytes32",
                            },
                            {
                                internalType: "address",
                                name: "uniV3Pool",
                                type: "address",
                            },
                            {
                                internalType: "address",
                                name: "rewardToken",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "amount",
                                type: "uint256",
                            },
                            {
                                internalType: "address[]",
                                name: "positionWrappers",
                                type: "address[]",
                            },
                            {
                                internalType: "uint32[]",
                                name: "wrapperTypes",
                                type: "uint32[]",
                            },
                            {
                                internalType: "uint32",
                                name: "propToken0",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "propToken1",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "propFees",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "epochStart",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "numEpoch",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "isOutOfRangeIncentivized",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "boostedReward",
                                type: "uint32",
                            },
                            {
                                internalType: "address",
                                name: "boostingAddress",
                                type: "address",
                            },
                            {
                                internalType: "bytes",
                                name: "additionalData",
                                type: "bytes",
                            },
                        ],
                        internalType: "struct DistributionParameters",
                        name: "base",
                        type: "tuple",
                    },
                    {
                        internalType: "uint24",
                        name: "poolFee",
                        type: "uint24",
                    },
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "add",
                                type: "address",
                            },
                            {
                                internalType: "uint8",
                                name: "decimals",
                                type: "uint8",
                            },
                            {
                                internalType: "string",
                                name: "symbol",
                                type: "string",
                            },
                            {
                                internalType: "uint256",
                                name: "poolBalance",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct UniswapTokenData",
                        name: "token0",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "add",
                                type: "address",
                            },
                            {
                                internalType: "uint8",
                                name: "decimals",
                                type: "uint8",
                            },
                            {
                                internalType: "string",
                                name: "symbol",
                                type: "string",
                            },
                            {
                                internalType: "uint256",
                                name: "poolBalance",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct UniswapTokenData",
                        name: "token1",
                        type: "tuple",
                    },
                    {
                        internalType: "string",
                        name: "rewardTokenSymbol",
                        type: "string",
                    },
                    {
                        internalType: "uint8",
                        name: "rewardTokenDecimals",
                        type: "uint8",
                    },
                ],
                internalType: "struct ExtensiveDistributionParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint32",
                name: "epoch",
                type: "uint32",
            },
        ],
        name: "getDistributionsForEpoch",
        outputs: [
            {
                components: [
                    {
                        components: [
                            {
                                internalType: "bytes32",
                                name: "rewardId",
                                type: "bytes32",
                            },
                            {
                                internalType: "address",
                                name: "uniV3Pool",
                                type: "address",
                            },
                            {
                                internalType: "address",
                                name: "rewardToken",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "amount",
                                type: "uint256",
                            },
                            {
                                internalType: "address[]",
                                name: "positionWrappers",
                                type: "address[]",
                            },
                            {
                                internalType: "uint32[]",
                                name: "wrapperTypes",
                                type: "uint32[]",
                            },
                            {
                                internalType: "uint32",
                                name: "propToken0",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "propToken1",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "propFees",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "epochStart",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "numEpoch",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "isOutOfRangeIncentivized",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "boostedReward",
                                type: "uint32",
                            },
                            {
                                internalType: "address",
                                name: "boostingAddress",
                                type: "address",
                            },
                            {
                                internalType: "bytes",
                                name: "additionalData",
                                type: "bytes",
                            },
                        ],
                        internalType: "struct DistributionParameters",
                        name: "base",
                        type: "tuple",
                    },
                    {
                        internalType: "uint24",
                        name: "poolFee",
                        type: "uint24",
                    },
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "add",
                                type: "address",
                            },
                            {
                                internalType: "uint8",
                                name: "decimals",
                                type: "uint8",
                            },
                            {
                                internalType: "string",
                                name: "symbol",
                                type: "string",
                            },
                            {
                                internalType: "uint256",
                                name: "poolBalance",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct UniswapTokenData",
                        name: "token0",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "add",
                                type: "address",
                            },
                            {
                                internalType: "uint8",
                                name: "decimals",
                                type: "uint8",
                            },
                            {
                                internalType: "string",
                                name: "symbol",
                                type: "string",
                            },
                            {
                                internalType: "uint256",
                                name: "poolBalance",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct UniswapTokenData",
                        name: "token1",
                        type: "tuple",
                    },
                    {
                        internalType: "string",
                        name: "rewardTokenSymbol",
                        type: "string",
                    },
                    {
                        internalType: "uint8",
                        name: "rewardTokenDecimals",
                        type: "uint8",
                    },
                ],
                internalType: "struct ExtensiveDistributionParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "uniV3Pool",
                type: "address",
            },
            {
                internalType: "uint32",
                name: "epochStart",
                type: "uint32",
            },
        ],
        name: "getPoolDistributionsAfterEpoch",
        outputs: [
            {
                components: [
                    {
                        components: [
                            {
                                internalType: "bytes32",
                                name: "rewardId",
                                type: "bytes32",
                            },
                            {
                                internalType: "address",
                                name: "uniV3Pool",
                                type: "address",
                            },
                            {
                                internalType: "address",
                                name: "rewardToken",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "amount",
                                type: "uint256",
                            },
                            {
                                internalType: "address[]",
                                name: "positionWrappers",
                                type: "address[]",
                            },
                            {
                                internalType: "uint32[]",
                                name: "wrapperTypes",
                                type: "uint32[]",
                            },
                            {
                                internalType: "uint32",
                                name: "propToken0",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "propToken1",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "propFees",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "epochStart",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "numEpoch",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "isOutOfRangeIncentivized",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "boostedReward",
                                type: "uint32",
                            },
                            {
                                internalType: "address",
                                name: "boostingAddress",
                                type: "address",
                            },
                            {
                                internalType: "bytes",
                                name: "additionalData",
                                type: "bytes",
                            },
                        ],
                        internalType: "struct DistributionParameters",
                        name: "base",
                        type: "tuple",
                    },
                    {
                        internalType: "uint24",
                        name: "poolFee",
                        type: "uint24",
                    },
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "add",
                                type: "address",
                            },
                            {
                                internalType: "uint8",
                                name: "decimals",
                                type: "uint8",
                            },
                            {
                                internalType: "string",
                                name: "symbol",
                                type: "string",
                            },
                            {
                                internalType: "uint256",
                                name: "poolBalance",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct UniswapTokenData",
                        name: "token0",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "add",
                                type: "address",
                            },
                            {
                                internalType: "uint8",
                                name: "decimals",
                                type: "uint8",
                            },
                            {
                                internalType: "string",
                                name: "symbol",
                                type: "string",
                            },
                            {
                                internalType: "uint256",
                                name: "poolBalance",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct UniswapTokenData",
                        name: "token1",
                        type: "tuple",
                    },
                    {
                        internalType: "string",
                        name: "rewardTokenSymbol",
                        type: "string",
                    },
                    {
                        internalType: "uint8",
                        name: "rewardTokenDecimals",
                        type: "uint8",
                    },
                ],
                internalType: "struct ExtensiveDistributionParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "uniV3Pool",
                type: "address",
            },
            {
                internalType: "uint32",
                name: "epochStart",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "epochEnd",
                type: "uint32",
            },
        ],
        name: "getPoolDistributionsBetweenEpochs",
        outputs: [
            {
                components: [
                    {
                        components: [
                            {
                                internalType: "bytes32",
                                name: "rewardId",
                                type: "bytes32",
                            },
                            {
                                internalType: "address",
                                name: "uniV3Pool",
                                type: "address",
                            },
                            {
                                internalType: "address",
                                name: "rewardToken",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "amount",
                                type: "uint256",
                            },
                            {
                                internalType: "address[]",
                                name: "positionWrappers",
                                type: "address[]",
                            },
                            {
                                internalType: "uint32[]",
                                name: "wrapperTypes",
                                type: "uint32[]",
                            },
                            {
                                internalType: "uint32",
                                name: "propToken0",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "propToken1",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "propFees",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "epochStart",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "numEpoch",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "isOutOfRangeIncentivized",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "boostedReward",
                                type: "uint32",
                            },
                            {
                                internalType: "address",
                                name: "boostingAddress",
                                type: "address",
                            },
                            {
                                internalType: "bytes",
                                name: "additionalData",
                                type: "bytes",
                            },
                        ],
                        internalType: "struct DistributionParameters",
                        name: "base",
                        type: "tuple",
                    },
                    {
                        internalType: "uint24",
                        name: "poolFee",
                        type: "uint24",
                    },
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "add",
                                type: "address",
                            },
                            {
                                internalType: "uint8",
                                name: "decimals",
                                type: "uint8",
                            },
                            {
                                internalType: "string",
                                name: "symbol",
                                type: "string",
                            },
                            {
                                internalType: "uint256",
                                name: "poolBalance",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct UniswapTokenData",
                        name: "token0",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "add",
                                type: "address",
                            },
                            {
                                internalType: "uint8",
                                name: "decimals",
                                type: "uint8",
                            },
                            {
                                internalType: "string",
                                name: "symbol",
                                type: "string",
                            },
                            {
                                internalType: "uint256",
                                name: "poolBalance",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct UniswapTokenData",
                        name: "token1",
                        type: "tuple",
                    },
                    {
                        internalType: "string",
                        name: "rewardTokenSymbol",
                        type: "string",
                    },
                    {
                        internalType: "uint8",
                        name: "rewardTokenDecimals",
                        type: "uint8",
                    },
                ],
                internalType: "struct ExtensiveDistributionParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "uniV3Pool",
                type: "address",
            },
            {
                internalType: "uint32",
                name: "epoch",
                type: "uint32",
            },
        ],
        name: "getPoolDistributionsForEpoch",
        outputs: [
            {
                components: [
                    {
                        components: [
                            {
                                internalType: "bytes32",
                                name: "rewardId",
                                type: "bytes32",
                            },
                            {
                                internalType: "address",
                                name: "uniV3Pool",
                                type: "address",
                            },
                            {
                                internalType: "address",
                                name: "rewardToken",
                                type: "address",
                            },
                            {
                                internalType: "uint256",
                                name: "amount",
                                type: "uint256",
                            },
                            {
                                internalType: "address[]",
                                name: "positionWrappers",
                                type: "address[]",
                            },
                            {
                                internalType: "uint32[]",
                                name: "wrapperTypes",
                                type: "uint32[]",
                            },
                            {
                                internalType: "uint32",
                                name: "propToken0",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "propToken1",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "propFees",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "epochStart",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "numEpoch",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "isOutOfRangeIncentivized",
                                type: "uint32",
                            },
                            {
                                internalType: "uint32",
                                name: "boostedReward",
                                type: "uint32",
                            },
                            {
                                internalType: "address",
                                name: "boostingAddress",
                                type: "address",
                            },
                            {
                                internalType: "bytes",
                                name: "additionalData",
                                type: "bytes",
                            },
                        ],
                        internalType: "struct DistributionParameters",
                        name: "base",
                        type: "tuple",
                    },
                    {
                        internalType: "uint24",
                        name: "poolFee",
                        type: "uint24",
                    },
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "add",
                                type: "address",
                            },
                            {
                                internalType: "uint8",
                                name: "decimals",
                                type: "uint8",
                            },
                            {
                                internalType: "string",
                                name: "symbol",
                                type: "string",
                            },
                            {
                                internalType: "uint256",
                                name: "poolBalance",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct UniswapTokenData",
                        name: "token0",
                        type: "tuple",
                    },
                    {
                        components: [
                            {
                                internalType: "address",
                                name: "add",
                                type: "address",
                            },
                            {
                                internalType: "uint8",
                                name: "decimals",
                                type: "uint8",
                            },
                            {
                                internalType: "string",
                                name: "symbol",
                                type: "string",
                            },
                            {
                                internalType: "uint256",
                                name: "poolBalance",
                                type: "uint256",
                            },
                        ],
                        internalType: "struct UniswapTokenData",
                        name: "token1",
                        type: "tuple",
                    },
                    {
                        internalType: "string",
                        name: "rewardTokenSymbol",
                        type: "string",
                    },
                    {
                        internalType: "uint8",
                        name: "rewardTokenDecimals",
                        type: "uint8",
                    },
                ],
                internalType: "struct ExtensiveDistributionParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getValidRewardTokens",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "token",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "minimumAmountPerEpoch",
                        type: "uint256",
                    },
                ],
                internalType: "struct RewardTokenAmounts[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract ICore",
                name: "_core",
                type: "address",
            },
            {
                internalType: "address",
                name: "_distributor",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_fees",
                type: "uint256",
            },
        ],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "isWhitelistedToken",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "message",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "messageHash",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "nonces",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "proxiableUUID",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IERC20[]",
                name: "tokens",
                type: "address[]",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
        ],
        name: "recoverFees",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "rewardTokenMinAmounts",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "rewardTokens",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_feeRecipient",
                type: "address",
            },
        ],
        name: "setFeeRecipient",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_fees",
                type: "uint256",
            },
        ],
        name: "setFees",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "_message",
                type: "string",
            },
        ],
        name: "setMessage",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_distributor",
                type: "address",
            },
        ],
        name: "setNewDistributor",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "tokens",
                type: "address[]",
            },
            {
                internalType: "uint256[]",
                name: "amounts",
                type: "uint256[]",
            },
        ],
        name: "setRewardTokenMinAmounts",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "userFeeRebate",
                type: "uint256",
            },
        ],
        name: "setUserFeeRebate",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes",
                name: "signature",
                type: "bytes",
            },
        ],
        name: "sign",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "rewardToken",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken0",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "isOutOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes",
                        name: "additionalData",
                        type: "bytes",
                    },
                ],
                internalType: "struct DistributionParameters",
                name: "distribution",
                type: "tuple",
            },
            {
                internalType: "bytes",
                name: "signature",
                type: "bytes",
            },
        ],
        name: "signAndCreateDistribution",
        outputs: [
            {
                internalType: "uint256",
                name: "distributionAmount",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "toggleSigningWhitelist",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "token",
                type: "address",
            },
        ],
        name: "toggleTokenWhitelist",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newImplementation",
                type: "address",
            },
        ],
        name: "upgradeTo",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newImplementation",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "data",
                type: "bytes",
            },
        ],
        name: "upgradeToAndCall",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "userSignatureWhitelist",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "userSignatures",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
class DistributionCreator__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.DistributionCreator__factory = DistributionCreator__factory;
DistributionCreator__factory.abi = _abi;

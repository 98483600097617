import { createContext } from "react";

type stakingPoolProps = {
    stakingPools: Array<object>;
    loadingStakingPools: boolean;
  }

export const StakingPoolsContext = createContext<stakingPoolProps>({
  stakingPools: [],
  loadingStakingPools: true
});

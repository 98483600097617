import { getTickLensAddress } from 'utils'
import { Protocol } from 'utils/types'

export const getV3TickLensContractConfig = (chainId: number, protocol: Protocol) => ({
  address: getTickLensAddress(protocol, chainId),
  abi: [
    {
      inputs: [
        { internalType: 'address', name: 'pool', type: 'address' },
        { internalType: 'int16', name: 'tickBitmapIndex', type: 'int16' },
      ],
      name: 'getPopulatedTicksInWord',
      outputs: [
        {
          components: [
            { internalType: 'int24', name: 'tick', type: 'int24' },
            { internalType: 'int128', name: 'liquidityNet', type: 'int128' },
            { internalType: 'uint128', name: 'liquidityGross', type: 'uint128' },
          ],
          internalType: 'struct ITickLens.PopulatedTick[]',
          name: 'populatedTicks',
          type: 'tuple[]',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
  ] as const,
})

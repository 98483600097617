"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.estimateWithdraw = exports.estimateDeposit = void 0;
const bignumber_1 = require("@ethersproject/bignumber");
const ethers_1 = require("ethers");
const constants_1 = require("../constants");
const types_1 = require("../constants/types");
const helpers_1 = require("../helpers");
const index_1 = require("../index");
const utils_1 = require("../utils");
function estimateDeposit(amount, collateral, stablecoin, provider = ethers_1.ethers.getDefaultProvider()) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        const stable = (0, utils_1.parseStable)(stablecoin);
        const collat = (0, utils_1.parseCollat)(collateral);
        const stableMasterAddress = (_a = (0, constants_1.registry)(index_1.ChainId.MAINNET, { stablecoin: stable.symbol })) === null || _a === void 0 ? void 0 : _a.StableMaster;
        const poolManagerAddress = (_b = (0, constants_1.registry)(index_1.ChainId.MAINNET, stable.symbol, collat.symbol)) === null || _b === void 0 ? void 0 : _b.PoolManager;
        if (!stableMasterAddress || !poolManagerAddress) {
            console.error('Address do not exist');
            return 0;
        }
        else {
            const stablemaster = types_1.StableMasterFront__factory.connect(stableMasterAddress, provider);
            const sanRate = (yield stablemaster.collateralMap(poolManagerAddress)).sanRate;
            return bignumber_1.BigNumber.from(amount).mul(bignumber_1.BigNumber.from(10).pow(18)).div(sanRate);
        }
    });
}
exports.estimateDeposit = estimateDeposit;
function estimateWithdraw(amount, collateral, stablecoin, provider = ethers_1.ethers.getDefaultProvider()) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        const stable = (0, utils_1.parseStable)(stablecoin);
        const collat = (0, utils_1.parseCollat)(collateral);
        const stableMasterAddress = (_a = (0, constants_1.registry)(index_1.ChainId.MAINNET, { stablecoin: stable.symbol })) === null || _a === void 0 ? void 0 : _a.StableMaster;
        const poolManagerAddress = (_b = (0, constants_1.registry)(index_1.ChainId.MAINNET, stable.symbol, collat.symbol)) === null || _b === void 0 ? void 0 : _b.PoolManager;
        if (!stableMasterAddress || !poolManagerAddress) {
            console.error('Address do not exist');
            return 0;
        }
        else {
            const stablemaster = types_1.StableMasterFront__factory.connect(stableMasterAddress, provider);
            const sanRate = (yield stablemaster.collateralMap(poolManagerAddress)).sanRate;
            const collatRatio = yield stablemaster.getCollateralRatio();
            return (0, helpers_1.simulateWithdraw)(index_1.ChainId.MAINNET, stable.symbol.slice(2), collat.symbol, amount, sanRate, collatRatio);
        }
    });
}
exports.estimateWithdraw = estimateWithdraw;

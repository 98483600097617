import { useEffect, useState } from 'react';
import { useChainId } from 'hooks/useChainId';
import { supportedChains, getSupportedDexes } from '../utils/index';  // Import your utility functions
import { Pool } from 'utils/types';
import {getBadgeUrl} from 'components/lib/app/AppCard';

/**
 * 
 *   or: [{
        token0_:{
        symbol_contains_nocase: $symbol
      },
      token1_:{
        symbol_contains_nocase: $symbol
      }
      }]
 */
const FETCH_TOKENS = `
query pools($symbol: String!, $limit: Int!) {
  pools(
    where:{
      or: [
        {
          token0_:{
            symbol_contains_nocase: $symbol
          }
        },
        {
          token1_:{
            symbol_contains_nocase: $symbol
          }
        }
      ]
    },
    orderBy: volumeUSD
    orderDirection: desc
    first: $limit
  ) {
    id
    volumeUSD
    totalValueLockedETH
    totalValueLockedUSD
    token0 {
      id
      name
      symbol
      decimals
    }
    token1 {
      id
      name
      symbol
      decimals
    }
    feeTier
  }
}
`

const useTokenPools = (search, dex, limit=100) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pools, setPools] = useState<Pool[]>([]);
  const [chainId] = useChainId();
  const supportedChain = supportedChains.find((chain) => chain.id === chainId);
  useEffect(() => {
    const fetchData = async () => {
      try {

        const DEXs = getSupportedDexes(supportedChain);
        const dexDetail = DEXs.find(d => d.value === dex);
        const res = await fetch(dexDetail["url"], {
          method: "POST",
          body: JSON.stringify({
            query: FETCH_TOKENS,
            variables: { symbol: search.trim(), limit },
          }),
        });
        const data = await res.json();
        
        let dexPools: Pool[] = data?.data?.pools || [];

        // add a fixed url to all elements
        const dexImageUrl = getBadgeUrl(dexDetail.value);
        dexPools = dexPools.map((pool) => {
          pool.dex = dexDetail.value;
          pool.dexUrl = dexDetail.url;
          pool.dexImageUrl = dexImageUrl;
          return pool;
        })

        setPools((prevPools) => {
          // Create a new array to hold the merged and deduplicated pools
          const newPools = [...prevPools];
        
          // Loop over each pool in dexPools
          for (const pool of dexPools) {
            // Check if there is already a pool in newPools with the same id and dex
            if (!newPools.find(p => p.id === pool.id && p.dex === pool.dex)) {
              // If there is no such pool, add the current pool to newPools
              newPools.push(pool);
            }
          }
        
          // Return the new array
          return newPools.filter((p) => p.dex === dex);
        });// Assuming data is an array of pools
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }

    };
    if (search.length > 0) {
        setLoading(true);
        setPools([]);
        fetchData();
    } else {
      setLoading(false);
    }
  }, [dex, search, supportedChain, limit]);

  return { loading, error, pools };
};

export default useTokenPools;

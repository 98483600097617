import { gql, useLazyQuery } from "@apollo/client";
import { Contract } from "@ethersproject/contracts";
import { useCall, useConfig, useEthers } from "@usedapp/core";
import { AppDetailsCard, Modal } from "components/lib";
import { GeoIpContext } from "context/GeoIp";
import { utils } from "ethers";
import { useChainId } from "hooks/useChainId";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getClient } from "services/graphql";
import {
  appEngines,
  beaconNameMapper,
  getChainName,
  getContractByNetwork,
  getIpfsJSONConfig,
  getUniswapV3Subgraph,
  isTestnet,
  supportedChains,
} from "utils";

import GeoBlockModal from "components/GeoBlockModal";
import { SimilarAppsTable } from "components/SimilarAppTable";
import { TransparentCard } from "components/TransparentCard";
import { DeprecatedBundlesContext } from "context/DeprecatedBundles";
import DepositModal from "../dashboard/modals/deposit";
import FundGasModal from "../dashboard/modals/fundGas";
import Whitelist from "../dashboard/modals/whitelist";
import WithdrawModal from "../dashboard/modals/withdraw";
import WithdrawFeesModal from "../dashboard/modals/withdrawFees";

import Chart from "components/Chart";
import { MerklContext } from "context/Merkl";
import { SushiRewardPoolsContext } from "context/SushiRewardPools";
import AmmSection from "./sections/AmmSection";
import AppConfigDetailSection from "./sections/appConfigDetailsSection";
import AppDescriptionSection from "./sections/appDescriptionSection";
import AppHeaderSection from "./sections/appHeaderSection";
import AssetManagementSection from "./sections/assetManagementSection";
import ButtonSection from "./sections/buttonSection";
import ClaimMerklRewardSection from "./sections/claimMerklRewardSection";
import DataConnectorSection from "./sections/dataConnectorDetailsSection";
import EngineConfigDetailsSection from "./sections/engineConfigDetailsSection";
import LpTokenDetailsSection from "./sections/lpTokenDetailsSection";
import GasStatusSection from "./sections/GasStatusSection";
// import { useEvmosTempById } from "hooks/useEvmosTemp";

const GET_VAULT = gql`
  query getVault($id: String!) {
    vault(id: $id) {
      id
      payloadIpfs
      strategyToken {
        executionBundle
        id
        name
        creator {
          id
        }
      }
      createdAt
      accruedStrategistFees0
      accruedStrategistFees1
      annualPercentageYearlyYield
      annualFeeARR
      weeklyFeeAPR
      fees0
      fees1
      pool
      permissions {
        addresses
      }
      gasDeposited
      gasUsed
      feeTier
      state
      deployer
    }
  }
`;

const AppDetails = () => {
  const params = useParams();
  const vaultId = params?.vaultId?.split("?engine")[0];
  const strategyId = params?.appId;
  const _chainId = window.location.search?.split("chainId=")[1];
  const { deprecatedBundles } = useContext(DeprecatedBundlesContext);
  // const evmosVaultDetails = useEvmosTempById(vaultId);
  
  const appDeprecatedBundles = deprecatedBundles.filter((d) => (d["type"] === 'VAULT' || d["type"] === 'STRATEGY') && (d["visible"] === false && d["execute"] === false));

  const isDeprecated = (appDeprecatedBundles || []).find((d) => d["id"] === vaultId || d["id"] === strategyId );
  
  const { account } = useEthers();

  const [chainId] = useChainId();
  const { merklRewardPools } = useContext(MerklContext);

  const { sushiRewardPools } = useContext(SushiRewardPoolsContext);

  const testnet = isTestnet(chainId);
  const { geoBlocked } = useContext(GeoIpContext);

  const [showGeoBlockModal, setShowGeoBlockModal] = useState(false);
  const [vaultDetails, setVaultDetails] = useState({});
  const [ipfsConfig, setIpfsConfig] = useState(null);
  const [gqlCall, setGqlCall] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<
    | "deposit"
    | "withdraw"
    | "withdraw-fees"
    | "fund-gas"
    | "add-whitelist"
    | "remove-whitelist"
  >();
  const steerPeriphery = getContractByNetwork(chainId, "SteerPeriphery");

  const steerPeripheryInterface = new utils.Interface(steerPeriphery.abi);

  let graphClient = getClient(supportedChains[0].subgraphURl);
  let uniswapGraphClient = getClient(
    getUniswapV3Subgraph(window.location.search, supportedChains[0])
  );
  const tconfig = useConfig();
  const supportedChain = supportedChains.find((chain) => {
    if(chainId) {
      return chain.id === chainId;
    } else {
      return chain.id === tconfig.readOnlyChainId
    }
  });
  if (supportedChain) {
    graphClient = getClient(supportedChain.subgraphURl);
    uniswapGraphClient = getClient(
      getUniswapV3Subgraph(window.location.search, supportedChain)
    );
  }

  const [getVault, graphVaultData] = useLazyQuery(GET_VAULT, {
    client: graphClient,
  });

  // fetch vault details
  const vaultDetailsOnChain = useCall(
    steerPeriphery.address && vaultId
      ? {
          contract: new Contract(
            steerPeriphery.address,
            steerPeripheryInterface
          ),
          method: "vaultDetailsByAddress",
          args: [vaultId],
        }
      : false
  );
  //graph call to load data for apy and fees
  useEffect(() => {
    let notUpdateState = false;
    if (!gqlCall && !notUpdateState && vaultId) {
      getVault({ variables: { id: vaultId.toLowerCase() } });
      setGqlCall(true);
    }
    return () => {
      notUpdateState = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vaultId]);

  // const vaultData = chainId === 9001 && evmosVaultDetails ? evmosVaultDetails: graphVaultData?.data?.vault;
  const vaultData = graphVaultData?.data?.vault;


  //ipfsconfig load
  useEffect(() => {
    if (vaultData?.payloadIpfs && !ipfsConfig && vaultData.id === vaultId) {
      (async () => {
        const configJson = await getIpfsJSONConfig(vaultData?.payloadIpfs);
        setIpfsConfig(configJson);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vaultData]);

  // load on chain data on state
  useEffect(() => {
    if (vaultDetailsOnChain && vaultDetailsOnChain.value && vaultData) {
      const vaultDataOnChain = vaultDetailsOnChain.value.details;
      const vaultOnChainDetail = {
        // needed for modals
        id: vaultId,
        lpToken: vaultId,
        lpTokenTotalSupply: vaultDataOnChain.totalLPTokensIssued,
        lpTokenDecimals: vaultDataOnChain.decimals,
        lpTokenSymbol: vaultDataOnChain.symbol,
        getBalance0: vaultDataOnChain.token0Balance,
        getBalance1: vaultDataOnChain.token1Balance,
        vaultToken0: vaultDataOnChain.token0,
        vaultToken1: vaultDataOnChain.token1,
        pool: vaultData.pool,
        vaultGQLCall: vaultDataOnChain,
        asset: `${vaultDataOnChain.token0Symbol} - ${vaultDataOnChain.token1Symbol}`,
        getBalance0String: utils.formatUnits(
          vaultDataOnChain.token0Balance,
          vaultDataOnChain["token0Decimals"]
        ),
        getBalance1String: utils.formatUnits(
          vaultDataOnChain.token1Balance,
          vaultDataOnChain["token1Decimals"]
        ),
        lpTokenTotalSupplyString:
          vaultDataOnChain["totalLPTokensIssued"].toString(),
        fees0: utils.formatUnits(
          vaultData["fees0"],
          vaultDataOnChain["token0Decimals"]
        ),
        fees1: utils.formatUnits(
          vaultData["fees1"],
          vaultDataOnChain["token1Decimals"]
        ),
        ...vaultDataOnChain,
      };
      if (
        vaultDetails["getBalance0String"] !==
          vaultOnChainDetail["getBalance0String"] ||
        vaultDetails["getBalance1String"] !==
          vaultOnChainDetail["getBalance1String"]
      ) {
        setVaultDetails(vaultOnChainDetail);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vaultDetailsOnChain, vaultData]);

  const ipfsConfigStrategyData = ipfsConfig?.strategyConfigData;
  const beaconName = beaconNameMapper[vaultDetails["vaultType"]];

  const isDepositAppreciated = () => {
    const appDeprecatedBundles = deprecatedBundles.filter((d) => (d["type"] === 'VAULT' || d["type"] === 'STRATEGY') && d["visible"] === false && d["execute"] === true);

    const isDeprecated = (appDeprecatedBundles || []).find((d) => d["id"] === vaultId || d["id"] === strategyId );
    
    return !isDeprecated;
  }
  /* 
    check for strategy owner remove withdraw fees option 
    if strategy owner is not current user
  */

  const getModalContainer = () => {
    if (geoBlocked && modalType !== 'withdraw') {
      return <GeoBlockModal closeModal={() => setShowGeoBlockModal(false)} />;
    } else {
      const combinedVault = {
        ...vaultDetails,
        name: ipfsConfigStrategyData?.name,
      };
      combinedVault["pool"] =  vaultData ? vaultData["pool"]: "";
      // Return relevant modal type as per modal type
      if (modalType === "withdraw") {
        return (
          <WithdrawModal
            vault={combinedVault}
            closeModal={() => setIsModalOpen(false)}
          />
        );
      } else if (modalType === "deposit") {
        return (
          <DepositModal
            vault={combinedVault}
            isDepositAppreciated={isDepositAppreciated()}
            closeModal={() => setIsModalOpen(false)}
          />
        );
      } else if (modalType === "withdraw-fees") {
        return (
          <WithdrawFeesModal
            vault={combinedVault}
            closeModal={() => setIsModalOpen(false)}
          />
        );
      } else if (modalType === "fund-gas") {
        return (
          <FundGasModal
            //@ts-ignore
            vault={combinedVault}
            closeModal={() => setIsModalOpen(false)}
          />
        );
      } else if (
        modalType === "add-whitelist" ||
        modalType === "remove-whitelist"
      ) {
        return (
          <Whitelist
            //@ts-ignore
            vault={combinedVault}
            closeModal={() => setIsModalOpen(false)}
            type={modalType === "add-whitelist" ? "add" : "remove"}
          />
        );
      }
      return <></>;
    }
  };
  const differentChain = _chainId && parseInt(_chainId) !== chainId;
  let stakingEnabled = false;
  const sushiRewardPool = sushiRewardPools?.find((pool) => (pool["address"] || "")?.toLowerCase() === (vaultDetails['pool'] || "")?.toLowerCase());
  const merklRewardPool = merklRewardPools?.find((pool) => (pool["address"] || "")?.toLowerCase() === (vaultDetails['pool'] || "")?.toLowerCase());
  if (sushiRewardPool || (merklRewardPool && merklRewardPool['incentiveAprs'])) {
    stakingEnabled = true;
  }
  return (
    <div
      className={`py-4 m-auto lg:h-full sm:w-10/12 w-full px-2`}
      id="app-details"
      key={vaultId}
    >
      <TransparentCard class="px-4">
        {isDeprecated && (
          <div className="flex flex-wrap items-center justify-center p-2 mb-3 text-lg bg-red-100 border border-red-200">
            {" "}
            <div className="text-3xl">☠️</div>{" "}
            <span className="ml-2 text-red-500">{isDeprecated["reason"]}</span>{" "}
          </div>
        )}
        {differentChain && <div className="flex flex-wrap items-center justify-center p-2 mb-3 text-lg bg-red-100 border border-red-200">
            {" "}
            <div className="text-3xl">🔁</div>{" "}
            <span className="ml-2 text-red-500">{`This App is on ${getChainName(parseInt(_chainId)) || "Evmos"} chain. Switch to the ${getChainName(parseInt(_chainId)) || "Evmos"} chain.`}</span>{" "}
          </div>}
        {AppHeaderSection({
          vaultDetails,
          chainId,
          ipfsConfig,
          vaultData,
          sushiRewardPool,
          merklRewardPool
        })}
        <div className="flex flex-col-reverse grid-cols-3 gap-4 lg:grid">
          <div className="flex flex-col col-span-2">
          {!testnet && (
              <div className="my-4">
                <AppDetailsCard
                  title="Liquidity Placement"
                  description="The graph provide a visual representation of the app liquidity placement with the price inside the pool. If the trading pair has better placement or ranking, is liquidity will remain within a certain range of price movements, which can result in earning more fees."
                  content={Chart({
                    vaultId,
                    uniswapGraphClient,
                    graphClient,
                    pool: vaultDetails["pool"],
                    testnet,
                  })}
                />
              </div>
            )}
             <div className="my-4">
              {AssetManagementSection({
                ipfsConfig,
                vaultDetails,
                vaultData,
                chainId,
              })}
            </div>
            <div className="my-4">
              {LpTokenDetailsSection({
                chainId,
                vaultDetails,
                vaultData,
              })}
            </div>
            <div className="mt-4">
              {AppDescriptionSection({
                ipfsConfig,
                chainId,
                vaultData,
              })}
              <div className="my-4">
                {AppConfigDetailSection({
                  ipfsConfig,
                })}
              </div>
            </div>
            <div className="my-4">
              <AppDetailsCard
                title={`${beaconName || ""} App Engine`}
                description={`${appEngines.desc} ${
                  appEngines[vaultDetails["vaultType"]] || ""
                }`}
                content={
                  <a
                    className="my-2 text-sm text-gray-500 underline"
                    href="https://docs.steer.finance/concentrated-liquidity/dev-uniliquidity-manager"
                  >
                    To learn more read our docs.
                  </a>
                }
              />
            </div>
              {DataConnectorSection({
                graphClient,
                ipfsConfig,
                chainId
              })}
            
            <div className="my-4">
              {EngineConfigDetailsSection({
                ipfsConfig,
              })}
            </div>

            <div className="my-4">
              {vaultDetails["token0"] && account && (
                <SimilarAppsTable
                  graphClient={graphClient}
                  vaultInfo={{ ...vaultDetails }}
                />
              )}
            </div>
          </div>
          <div className="col-span-1 my-4">
            {ButtonSection({
              vaultData,
              ipfsConfig,
              vaultDetails,
              account,
              setShowGeoBlockModal,
              setIsModalOpen,
              setModalType,
              isDeprecated: (isDeprecated || !isDepositAppreciated()),
              differentChain,
              supportedChain
            })}
            {stakingEnabled  && account && vaultData && <ClaimMerklRewardSection vaultAddress={vaultData['id']} sushiRewardPool={sushiRewardPool}  merklRewardPool={merklRewardPool} />}
            <AmmSection beacon={beaconName}/>
            { account && vaultData && <GasStatusSection strategyId={vaultData?.strategyToken?.id} vaultId={vaultData['id']}></GasStatusSection>}
          </div>
        </div>
      </TransparentCard>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          size={"large"}
          content={getModalContainer()}
        />
      )}
      {showGeoBlockModal && (
        <Modal
          isOpen={showGeoBlockModal}
          size={"large"}
          content={getModalContainer()}
        />
      )}
    </div>
  );
};

export default AppDetails;

import React, { useEffect, useState, useRef } from "react";

import {
  JsonForm,
  Modal,
  Icon,
  Button,
} from "components/lib";

import { getIpfsBundleConfig, toTitleCase } from "utils";
import { Pool } from "utils/types";

export const getUniqTypes = (data) => {
  return data.reduce((acc, d) => {
    const alreadyAdded = acc.find(({label}) => d.label === label);
    if(!alreadyAdded) {
      acc.push(d);
    } 
    return acc;
  }, []);
}
const Config = ({
  data,
  type,
  setData,
  isOpen,
  setIsOpen,
  secondaryData,
  handleClose,
  executionBundle
}: {
  data: any;
  type: "strategy" | "dataConnector";
  setData: (data: any) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  secondaryData?: Pool;
  handleClose: () => void;
  executionBundle: string
}) => {
  const [formData, setFormData] = React.useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(null);
  let submitFormRef = useRef(null);
  const title =
    type === "strategy"
      ? `Configure App ${toTitleCase(data.name)} Bundle`
      : `Configure Data Connector for ${toTitleCase(
          data?.source
        )} Source & ${toTitleCase(data?.host)} Host`;
  const hash = type === "strategy" ? executionBundle : data.bundle;
  const [configJSON, setConfigJSON] = useState({});
  const secondaryHelperDataObj = {
    fee: Number(secondaryData.feeTier),
    subgraphEndpoint: secondaryData.dexUrl,
    poolAddress: secondaryData.id,
    poolFee: Number(secondaryData.feeTier),
  }

  useEffect(() => {
    (async () => {
      const ipfsData = await getIpfsBundleConfig(hash);
      if (ipfsData.hasError) {
        setHasError(ipfsData.msg);
      } else {
        const oldProperties = ipfsData.data?.properties;
        const obj = {};
        const propertiesValueArray = secondaryHelperDataObj
          ? Object.keys(secondaryHelperDataObj)
          : [];
        if(oldProperties) {
          Object.keys(oldProperties).map((d) => {
            obj[d] = oldProperties[d];
            if (propertiesValueArray.indexOf(d) !== -1) {
              obj[d] = {
                ...obj[d],
                default: secondaryHelperDataObj[d],
              };
            }
            return obj;
          });
        }
        //cloning
        if(data?.payload) {
          Object.keys(obj).map(key => {
            obj[key] = {
              ...obj[key],
              default: data?.payload[key]
            };
            return null;
          });
        }
        
        if(data.payloadHash) {
          Object.keys(obj).map(key => {
            obj[key] = {
              ...obj[key],
              default: data.payloadHash[key]
            };
            return null;
          });
        }
        
        const ipfsNewData = {
          ...ipfsData.data,
          properties: obj,
        };
        setConfigJSON(ipfsNewData);
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
	const hasProperties = Object.keys(configJSON["properties"] || {}).length > 0; 

  const handleSubmit = () => {
    // if (type === "strategy") {
    //   setSteps(steps + 1);
    // }
    setData({
      ...data,
      payloadHash: formData,
      dataType: (configJSON['parameters'] || configJSON['expectedDataTypes'] || ["OHLC"]).map(t => t.toLowerCase())
    });
  }
  return (
    <Modal
      isOpen={isOpen}
      size={"large"}
      content={
        <div className="flex flex-col p-4 mb-2" key={hash}>
          <div className="flex mb-4 text-base leading-6 text-white-500">
            {title}
          </div>
          <div
            className={`mb-4 border border-lynch-rgba shadow-xs`}
            style={{ width: "100%" }}
          />
					<a className="flex justify-center mb-2 capitalize text-white-500 hover:underline" 
          rel="noreferrer" 
          href={ type === "strategy" ? "https://docs.steer.finance/steer-apps/writing-an-app#setup-app-params" 
            : "https://docs.steer.finance/data-connectors/interface-api#configuration-form"} 
            target={'_blank'}>{"Learn about configuration paramters?"}</a>

          {hasError && (
            <div className="flex justify-center text-lg leading-6 text-red-500">
              {hasError}
            </div>
          )}
          {isLoading && (
            <div className="flex justify-center text-lg text-white-500">
              <Icon spin={true} name="faSpinner" />
            </div>
          )}
          {!isLoading && (
            <JsonForm
              schema={configJSON}
              ref={submitFormRef}
              //@ts-ignore
              setFormData={setFormData}
              formData={formData}
              onSubmit={() => {
               
              }}
              type={type === "strategy" ? 'app' : 'data-connector'}
            />
          )}
          {!isLoading && !hasProperties && (
						<div className="flex justify-center text-lg leading-6 text-white-500">
						{"No Config Parameters"}
					</div>
					)}

          <div className="flex justify-between mt-2">
            <Button
              content="close"
              type="tertiary"
              size="xsmall"
              onClickHandler={handleClose}>
            </Button>
            <Button 
              content="Save & Next"
              size="xsmall"
              type="nsecondary"
              onClickHandler={handleSubmit}
            >
            </Button>
          </div>
          

        </div>
      }
    />
  );
};

export default Config;

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import 'assets/react-datepicker.css';
import "./styles.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Gleap from 'gleap';
// Please make sure to call this method only once!
Gleap.initialize("xN6xOjpyLBU9GMZVm1FR5aL8majxrHAQ");
Gleap.setMaxNetworkRequests(100);
Sentry.init({
  dsn: "https://e7d20ebcdf744796b604ee1598ed22ca@o1339369.ingest.sentry.io/4504135771160576",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  release: process.env.REACT_APP_COMMIT_REF,
  enabled: window.location.host.indexOf("localhost") === -1,
  ignoreErrors: [
    "Non-Error promise rejection captured with keys: code, data, message, stack"
  ]
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);


//@ts-nocheck
import React, { FunctionComponent, createRef } from "react";
import { Input } from "./Input";
import { Checkbox } from "./Checkbox";
import { Textarea } from "./Textarea";
import { Button } from "./Button";
import Form from "@rjsf/core";
import { Dropdown } from "./Dropdown";
import { DateRange } from "./DateRange";
import { Label } from "./Label";
import {Slider} from './Slider';
import { Timeframe } from "./Timeframe";

export interface JsonFormProps {
  schema: object;
  ref?: any;
  onChange?: (e: any) => void;
  onError?: (e: any) => void;
  formData?: any;
  setFormData?: (data: any) => void;
  onSubmit?: (e: any) => void;
  type: 'data-connector' | 'app'
}
const CustomWidget = (props) => {

  function getInputControl() {
    if (props.schema.hidden) {
      return <></>;
    }
    if(props?.label?.toLowerCase().indexOf('lookback') !== -1) {
      return props.schema.type === 'integer' && props.formContext?.type === 'data-connector' ? (
        <div className="mb-2">
          <Timeframe
            onChange={async (val) => {
              await new Promise((resolve) => setTimeout(resolve, 500));
              props.onChange(parseInt(val));
            }}
            options={["Minute", "Hours","Days", "Weeks", "Months"]}
            defaultOption="30"
            heading="Lookback"
            tooltipContent={props?.schema?.description || props?.schema?.detailedDescription || ''}

          />
        </div>
      ) : (
        <div className="mb-2">
        <Input
            type={props.schema.type === "integer" ? "number" : "string"}
            onChange={props.onChange}
            val={props.value}
            placeholder={props.schema.description || ""}
            heading={'Lookback'}
            haserror={props.rawErrors && props.rawErrors.length > 0}
            tooltipContent={props?.schema?.description || props?.schema?.detailedDescription || ''}
            backgroundType="white"
          />
        </div>
      )
    }
    if(props?.label?.toLowerCase().indexOf('candlewidth') !== -1 || props?.label?.toLowerCase().indexOf('candle') !== -1 ) {
      return (
        <div className="mb-2">
            <Timeframe
              onChange={(val) => props.onChange(`${val}s`)}
              options={["Minute", "Hour", "Day"]}
              defaultOption="30"
              heading="Candle Width"
              tooltipContent={props.schema.detailedDescription}
            />
          </div>
      )
    }

    if(props?.label?.toLowerCase().indexOf('bins') !== -1) {
      return (
        <div className="flex flex-row p-6 mb-2 space-x-4 border border-solid rounded-md">
        <Slider onValueChange={props.onChange} defaultValue={[props.value]} step={1} min={1} max={12} />
        <div>{String(props.value)}</div>
      </div>
      )
    }
    if (
      (props.schema.type === "number" ||
        props.schema.type === "integer" ||
        props.schema.type === "string" ||
        props.schema.type === "array") &&
      !props.options.enumOptions
    ) {
      if (
        props.schema.format === "date" ||
        props.schema.format === "date-time" ||
        props.schema.format === "time"
      ) {
        return (
          <div className="mb-2">
            {/* <DateTime
              onSelectHandler={props.onChange}
              placeholder={props.schema.description || ""}
            /> */}
          </div>
        );
      } else if (props.schema.format === "date-range") {
        return (
          <div className="mb-2">
            <DateRange
              onSelectHandler={props.onChange}
              placeholder={props.schema.description || ""}
            />
          </div>
        );
      } else {
        return (
          <div className="mb-2">
            <Input
              type={props.schema.type === "integer" ? "number" : "string"}
              onChange={props.onChange}
              val={props.value}
              placeholder={props.schema.description || ""}
              haserror={props.rawErrors && props.rawErrors.length > 0}
              tooltipContent={props?.schema?.description || props?.schema?.detailedDescription || ''}

              backgroundType="white"
            />
          </div>
        );
      }
    }
    if (props.schema.type === "boolean" && !props.schema.hidden) {
      return (
        <div className="mb-2">
          <Checkbox
            onClickHandler={props.onChange}
            isChecked={props.value}
            text={props.label}
          />
        </div>
      );
    }
    if (props.schema.type === "textarea") {
      return (
        <div className="mb-2">
          <Textarea
            onChange={props.onChange}
            val={props.value}
            placeholder={props.schema.description || ""}
              tooltipContent={props?.schema?.description || props?.schema?.detailedDescription || ''}

            backgroundType="white"
          />
        </div>
      );
    }
    if (props.schema.enum) {
      return (
        <div className="mt-3 mb-2">
          <Dropdown
            placeholder={props.schema.description || ""}
            options={props.options.enumOptions}
            onSelectHandler={props.onChange}
            type={"normal"}
            hasIcon={false}
            selected={props.value}
              tooltipContent={props?.schema?.description || props?.schema?.detailedDescription || ''}

            backgroundType="white"
          />
        </div>
      );
    }    
  }
  return (
    <div className="">
      <pre>
        {/* {JSON.stringify(props, null, 2)} */}
      </pre>
      {(!props.schema.hidden && props?.label?.toLowerCase().indexOf('lookback') === -1 && props?.label?.toLowerCase().indexOf('candlewidth') === -1 && props?.label?.toLowerCase().indexOf('candle') === -1 ) && (
        <Label
          content={props.label || ""}
          size={"medium"}
          color={'white-500'}
          iconSize="small"
          tooltipContent={props?.schema?.description || props?.schema?.detailedDescription || ''}
          casing={'capitalize'}
        />
      )}
      {/* {props.label && props.schema.description && props.schema.description.length > 0 && <div className="mt-2">
        <Label
          content={props.schema.description || ""}
          size={"small"}
          color={'gray-500'}
        />
      </div>} */}
      {getInputControl()}
    </div>
  )
};

const customWidgets = { customWidgets: CustomWidget };

export const JsonForm: FunctionComponent<JsonFormProps> = React.forwardRef(
  (props, ref) => {
    let submitFormRef = ref ? ref : createRef();
    let uiSchema = {
      "ui:widgets": customWidgets,
    };


    const traverseObject = (obj, keys = {}) => {
      for (let key in obj) {
        if (key === "properties") {
          for (let subKey in obj[key]) {
            if (obj[key][subKey].type === "object") {
              keys[subKey] = {};
              traverseObject(obj[key][subKey], keys[subKey]);
            } else {
              keys[subKey] = {
                "ui:widget": "customWidgets",
                "ui:options": {
                  label: false,
                },
              };
            }
          }
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          traverseObject(obj[key], keys);
        }
      }
      return keys;
    }

    uiSchema = {
      ...uiSchema,
      ...traverseObject(props.schema)
    }
    return (
      <div data-testid="JsonForm-component">
        <Form
          {...props}
          uiSchema={uiSchema}
          widgets={customWidgets}
          onError={props.onError}
          formData={props.formData}
          onChange={(e) => {
            props.setFormData && props.setFormData(e.formData);
          }}
          onSubmit={() => props.onSubmit && props.onSubmit()}
          formContext={{
            type: props.type
          }}
        >
          <button
            ref={submitFormRef}
            type="submit"
            style={{ display: "none" }}
          />
        </Form>
        {!ref && (
          <div className="my-4">
            <Button
              onClickHandler={() => submitFormRef.current.click()}
              content={"Submit"}
              size={"small"}
              type={"primary"}
            />
          </div>
        )}
      </div>
    );
  }
);

JsonForm.defaultProps = {
  schema: {},
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SanTokenLevSwapper__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [],
        name: "EmptyReturnMessage",
        type: "error",
    },
    {
        inputs: [],
        name: "IncompatibleLengths",
        type: "error",
    },
    {
        inputs: [],
        name: "NotGovernorOrGuardian",
        type: "error",
    },
    {
        inputs: [],
        name: "TooSmallAmountOut",
        type: "error",
    },
    {
        inputs: [],
        name: "ZeroAddress",
        type: "error",
    },
    {
        inputs: [],
        name: "angleRouter",
        outputs: [
            {
                internalType: "contract IAngleRouterSidechain",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "angleStaker",
        outputs: [
            {
                internalType: "contract IBorrowStaker",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IERC20[]",
                name: "tokens",
                type: "address[]",
            },
            {
                internalType: "address[]",
                name: "spenders",
                type: "address[]",
            },
            {
                internalType: "uint256[]",
                name: "amounts",
                type: "uint256[]",
            },
        ],
        name: "changeAllowance",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "collateral",
        outputs: [
            {
                internalType: "contract IERC20",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [],
        name: "core",
        outputs: [
            {
                internalType: "contract ICoreBorrow",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "oneInch",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "poolManager",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [],
        name: "sanToken",
        outputs: [
            {
                internalType: "contract IERC20",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [],
        name: "stableMaster",
        outputs: [
            {
                internalType: "contract IStableMaster",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IERC20",
                name: "inToken",
                type: "address",
            },
            {
                internalType: "contract IERC20",
                name: "outToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "outTokenRecipient",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "outTokenOwed",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "inTokenObtained",
                type: "uint256",
            },
            {
                internalType: "bytes",
                name: "data",
                type: "bytes",
            },
        ],
        name: "swap",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "uniV3Router",
        outputs: [
            {
                internalType: "contract IUniswapV3Router",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
class SanTokenLevSwapper__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.SanTokenLevSwapper__factory = SanTokenLevSwapper__factory;
SanTokenLevSwapper__factory.abi = _abi;

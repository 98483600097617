import React, { useEffect } from "react";

import { Button } from "components/lib";
import { useContractFunction, useEthers } from "@usedapp/core";
import { Contract } from "@ethersproject/contracts";
import { utils } from "ethers";
import { getContractByNetwork } from "utils";
import { useChainId } from "hooks/useChainId";

const ToggleState = ({ vault, jobStatus, jobHash, refetch, deployer }) => {
  const { account } = useEthers();

const [chainId] = useChainId();
  const DynamicJobs = getContractByNetwork(chainId, "DynamicJobs");
  const dynamicJobsInterface = new utils.Interface(DynamicJobs.abi);
  const dynamicJobContract = new Contract(vault, dynamicJobsInterface);

  const { state, send: setJobState } = useContractFunction(
    dynamicJobContract,
    "setJobState",
    { transactionName: `Updating Automation Status ` }
  );
  const toggleStatus = () => {
    const newStatus = jobStatus === "Ongoing" ? 1 : 2;
    setJobState(jobHash, newStatus);
  };

  useEffect(() => {
    if (state.status === "Success") {
      setTimeout(() => {
        refetch();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const isDisabled =
    ((account && account.toLowerCase()) !==
    deployer?.toLowerCase()) || state.status === 'Mining';
  return (
		<div className="mx-4 my-2 lg:my-0">
			{jobStatus !== 'pending' && <Button
				onClickHandler={toggleStatus}
				content={jobStatus === "Ongoing" ? "Pause Automation" : "Start Automation"}
				casing={`uppercase`}
				type={`tertiary`}
				size={"small"}
				disabled={isDisabled}
			/>}
		</div>
	)
};

export default ToggleState;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const addresses = {
    ANGLE: '0x656B80B667a46869144047E6e6C0000C81610253',
    AngleHelpers: '0xdD6A0A00fE3353e813F3B3864694D55D2a7cE11C',
    bridges: {
        LayerZero: '0x366CEE609A64037a4910868c5b3cd62b9D019695',
    },
    AngleRouter: '0xC16B81Af351BA9e64C1a069E3Ab18c244A1E3049',
    AngleRouterV2: '0x9A33e690AA78A4c346e72f7A5e16e5d7278BE835',
    agEUR: {
        AgToken: '0xFA5Ed56A203466CbBC2430a43c66b9D8723528E7',
        borrowCollaterals: {
            USDC: {
                Oracle: '0x9485aca5bbBE1667AD97c7fE7C4531a624C8b1ED',
                VaultManager: '0x04437e94Af860AfBb0429a7D36b9c00A5a5173b9',
            },
            wBTC: {
                Oracle: '0x2Fa1255383364F6e17Be6A6aC7A56C9aCD6850a3',
                VaultManager: '0xF664118E79C0B34f1Ed20e6606a0068d213839b9',
            },
            wETH: {
                Oracle: '0x7AB641E661a9728913A44e06f6a4879481142DDb',
                VaultManager: '0xe9f183FC656656f1F17af1F2b0dF79b8fF9ad8eD',
            },
            'cvx-crvUSDCUSDT': {
                Oracle: '0x9De6Efe3454F8EFF8C8C8d1314CD019AF2432e59',
                VaultManager: '0xE682aa5f491bD775c89b160462f7911D8A1B0297',
                additionalProperties: {
                    LPToken: '0x7f90122BF0700F9E7e1F688fe926940E8839F353',
                    Rewards: ['0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978', '0xb952A807345991BD529FDded05009F5e80Fe8F45'],
                    Staker: '0x42dC54fb50dB556fA6ffBa765F1141536d4830ea',
                    Swapper: '0x9201cC18965792808549566e6B06B016d915313A',
                },
            },
            'sd-crvUSDCUSDT': {
                Oracle: '0x9De6Efe3454F8EFF8C8C8d1314CD019AF2432e59',
                VaultManager: '0x7f27082EABddDC9dc3CC6632C9f594d210B9d43c',
                additionalProperties: {
                    LPToken: '0x7f90122BF0700F9E7e1F688fe926940E8839F353',
                    Rewards: ['0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978'],
                    Staker: '0xc8711B1206cD3e89799Ec32973f583e696Cb553C',
                    Swapper: '0xE6239bAF1b79ACffF180bF7DD8647ef9B0cf97F5',
                },
            },
        },
        bridges: {
            Synapse: '0x16BFc5fe024980124bEf51d1D792dC539d1B5Bf0',
            LayerZero: '0x16cd38b1B54E7abf307Cb2697E2D9321e843d5AA',
        },
        OracleTokenUSD: '0xA14d53bC1F1c0F31B4aA3BD109344E5009051a84',
        Swapper: '0x9C215206Da4bf108aE5aEEf9dA7caD3352A36Dad',
        SwapperV2: '0x2904A4d96f88ad4bD548507BA139E892083894F7',
        Treasury: '0x0D710512E100C171139D2Cf5708f22C680eccF52',
    },
    CoreBorrow: '0x31429d1856aD1377A8A0079410B297e1a9e214c2',
    FlashAngle: '0x59153e939c5b4721543251ff3049Ea04c755373B',
    Governor: '0xAA2DaCCAb539649D1839772C625108674154df0B',
    Guardian: '0x55F01DDaE74b60e3c255BD2f619FEbdFce560a9C',
    Merkl: {
        CoreMerkl: '0xA86CC1ae2D94C6ED2aB3bF68fB128c2825673267',
        DistributionCreator: '0x8BB4C975Ff3c250e0ceEA271728547f3802B36Fd',
        Distributor: '0x3Ef3D8bA38EBe18DB133cEc108f4D14CE00Dd9Ae',
    },
    MulticallWithFailure: '0x97B6897AAd7aBa3861c04C0e6388Fc02AF1F227f',
    OracleNativeUSD: '0x639Fe6ab55C921f74e7fac1ee960C0B6293ba612',
    ProxyAdmin: '0x9a5b060Bd7b8f86c4C0D720a17367729670AfB19',
    ProxyAdminGuardian: '0xf2eDa0829E8A9CF53EBCB8AFCBb558D2eABCEF64',
};
exports.default = addresses;

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.harvest = exports.getStrategies = void 0;
const ethers_1 = require("ethers");
const constants_1 = require("../constants");
const types_1 = require("../types");
const logger_1 = require("./logger");
function getStrategies(chainId) {
    const stablesSymbols = Object.values(constants_1.ALL_TOKENS[types_1.AssetType.STABLE]).map((token) => token.symbol);
    const strategyContracts = stablesSymbols.reduce((acc, stableSymbol) => {
        var _a;
        const collaterals = (_a = (0, constants_1.registry)(chainId, { stablecoin: stableSymbol })) === null || _a === void 0 ? void 0 : _a.collaterals;
        if (!!collaterals) {
            for (const collateral of Object.values(collaterals)) {
                if (collateral.PoolManager) {
                    const collateralToken = Object.values(constants_1.ALL_TOKENS[types_1.AssetType.COLLATERAL]).filter((token) => token.symbol === collateral)[0];
                    if (collateral.Strategies) {
                        for (const strat of Object.values(collateral.Strategies)) {
                            if (typeof strat === 'string') {
                                acc.push({
                                    strategy: strat,
                                    poolManager: collateral.PoolManager,
                                    collateralDecimals: collateralToken.decimals,
                                });
                            }
                            else {
                                acc.push({
                                    strategy: strat.Contract,
                                    poolManager: collateral.PoolManager,
                                    collateralDecimals: collateralToken.decimals,
                                });
                            }
                        }
                    }
                }
            }
        }
        return acc;
    }, []);
    (0, logger_1.Logger)('strategies', strategyContracts);
    return strategyContracts;
}
exports.getStrategies = getStrategies;
function harvest(contract, provider, signer, chainId) {
    return __awaiter(this, void 0, void 0, function* () {
        const strategyContract = new ethers_1.Contract(contract.strategy, constants_1.Strategy__factory.createInterface(), provider);
        const poolManagerContract = new ethers_1.Contract(contract.poolManager, constants_1.PoolManager__factory.createInterface(), provider);
        const creditAvailable = yield poolManagerContract.creditAvailable();
        if (creditAvailable.gt(ethers_1.utils.parseUnits('500000', contract.collateralDecimals))) {
            yield strategyContract.connect(signer)['harvest()'];
        }
    });
}
exports.harvest = harvest;

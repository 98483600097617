import React, { useMemo, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useTable } from "react-table";

import { DraggableTableRow } from "./DraggableTableRow";
import { Row } from "./Row";

export const DnDTable = ({ columns, data, setData }) => {
  const [activeId, setActiveId] = useState();
  const [colWidth] = useState(100 / (columns.length ));
  const items = useMemo(() => data?.map(({ id }) => id), [data]);
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setData((data) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(data, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = rows.find(({ original }) => original.id === activeId);
    prepareRow(row);
    return row;
  }, [activeId, rows, prepareRow]);
  // Render the UI for your table
  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <div className="inline-block min-w-full py-2 align-middle">
        <div className="overflow-hidden">
          <table {...getTableProps()} className="min-w-full ">
            <thead className={``}>
              {headerGroups.map((headerGroup, key) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className={`flex`}
                  key={key}
                >
                  {headerGroup.headers.map((column, i) => {
                    return (
                      <th
                      {...column.getHeaderProps()}
                      className={`px-6 py-2 text-left uppercase leading-3 tracking-wider ${ 'text-lynch-500'}`}
                        style={{
                          width: `${colWidth}%`,
                          fontSize: "10px",
                        }}
                        key={i + column.render("Header")}
                      >
                        <div className="flex">
                          {column.render("Header")}
                          
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
              >
                {rows.map((row, i) => {
                  prepareRow(row);
                  return <DraggableTableRow key={row.original.id} row={row} />;
                })}
              </SortableContext>
            </tbody>
          </table>
        </div>
      </div>

      <DragOverlay>
        {activeId && (
          <table className="min-w-full ">
            <tbody>
            <Row
              row={selectedRow}
              isDragging={true}
              id={selectedRow.index}
              rowExpandable={false}
              colWidth={100/selectedRow.cells.length}
              />
            </tbody>
          </table>
        )}
      </DragOverlay>
    </DndContext>
  );
}

import React, { useState, FunctionComponent, useEffect } from "react";
import { Switch } from '@headlessui/react'
export interface ToggleProps {
  enabled: boolean;
  setEnabled: (t: boolean) => void;
}

export const Toggle: FunctionComponent<ToggleProps> = (props) => {
  const [enabled, setEnabled] = useState(props.enabled);
  const _setEnabled = (t) => {
    setEnabled(t);
    if(props.setEnabled) {
      props.setEnabled(t);
    }
  }

  useEffect(() => {
    setEnabled(props.enabled);
  }, [props.enabled])

  return (
    <Switch
      checked={enabled}
      onChange={_setEnabled}
      className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out
        ${enabled ? 'bg-shamrock-600' : 'bg-gray-200'}
      `}
    >
      <span
        aria-hidden="true"
        className={`pointer-events-none inline-block h-5 w-5 transform rounded-full shadow ring-0 transition duration-200 ease-in-out
        ${enabled ? 'translate-x-5 bg-shamrock-200 ' : 'translate-x-0 bg-gray-500 '}
      `}
      />
    </Switch>
  )
}



import { RebalancePreview, UniswapStrategyOutput } from "utils/types";
import { StrategyExecution } from "./strategyUtils";

export class Rebalances {
  strategy: StrategyExecution;
  candles: any[];
  // epochDuration: string;
  currentTick: any;

  constructor(strategy: StrategyExecution, candles, currentTick) {
    this.strategy = strategy;
    this.candles = candles;
    this.currentTick = currentTick;
    // if (epochDuration) {
    //   this.epochDuration = epochDuration;
    // } else {
    //   this.epochDuration = "1h";
    // }
  }


  public async previewRebalances(): Promise<Array<RebalancePreview>> {
    const epochResults: Array<UniswapStrategyOutput> | any = [];

    if (this.candles && this.candles.length > 0) {

      try {
          const result = (await this.strategy.execute(
            this.candles, false
          )) as unknown as UniswapStrategyOutput;
          
        // Add the results to the epochResults array
          epochResults.push({
            ...result,
            timestamp: Date.now()
          })
      } catch (error) {
        console.log("Strategy failed:", error, 'error message', error.message);
        if ('Error: Invalid configuration' in error.message) {
        }
      }
     
    } 

    if(this.currentTick) {
      const executionResult = await this.strategy.execute([this.currentTick?.tick.toString()], true);
      //@ts-ignore
      epochResults.push(JSON.parse(executionResult));
    }
    const rebalancePreviews = epochResults.map((output) => {
      const positions = [];
      const boundsAndWeight = output.valuesArray[1];
      for (let i = 0; i < boundsAndWeight[0].length; i++) {
        positions.push({
          lowerTick: boundsAndWeight[0][i],
          upperTick: boundsAndWeight[1][i],
          weight: boundsAndWeight[2][i],
        });
      }

      return {
        timestamp: this.currentTick ? Date.now() : output.timestamp,
        positions,
      };
    });

    return rebalancePreviews;
  }
}

import { useState } from "react";
import { Button, Icon, Label, Modal, Title } from "components/lib";

const ConsentModal = ({ showConsentModal, verifyEIP712Signature, closeModal }) => {
  const [signInprogress, setSignInprogress] = useState(false);

  const handleSignMessage = async () => {
    setSignInprogress(true);
    await verifyEIP712Signature({});
    setSignInprogress(false);
  };

  return (
    <Modal
      isOpen={showConsentModal}
      size={"large"}
      content={
        <div className="flex flex-col justify-center mx-4">
          <Label
            //@ts-ignore
            content={
              <>
                <div className="absolute top-5 right-7 text-white-100">
                  {closeModal && (
                    <Icon
                      name={`faTimes`}
                      className={`text-san-marino-50 hover:bg-white-900 `}
                      size={`1x`}
                      color={"bg-white-100"}
                      onClickHandler={closeModal}
                    />
                  )}
                </div>
                <div className="flex flex-col ">
                  <Title
                    content="Consent for usage"
                    size="lg"
                    casing="uppercase"
                  />
                  <div className="flex flex-col items-center mt-6">
                    <span className="text-sm">
                      I hereby affirm and declare that, to the best of my
                      understanding, I am abiding by all relevant international
                      and local laws, regulations, and sanctions, including
                      those enforced by the Office of Foreign Assets Control
                      (OFAC), that pertain to the usage, acquisition,
                      possession, and sale of digital tokens. This includes, but
                      is not limited to, anti-money laundering (AML) and
                      counter-terrorism financing (CTF) laws, tax reporting
                      requirements, economic sanctions legislation, the U.S.
                      Securities Act of 1933, Financial Action Task Force (FATF)
                      recommendations, the European Union's (EU) Fifth
                      Anti-Money Laundering Directive (5AMLD), Know Your
                      Customer (KYC) rules, United Nations (UN) Security Council
                      resolutions, International Organization of Securities
                      Commissions (IOSCO) guidelines, the EU's proposed Markets
                      in Crypto-Assets (MiCA) regulation, Bank for International
                      Settlements (BIS) guidelines, International Monetary Fund
                      (IMF) suggestions, data protection and privacy laws (e.g.,
                      GDPR, CCPA), consumer protection laws, and any other
                      pertinent financial, securities, and commodities
                      regulations. I confirm that I am not a resident, citizen,
                      green card holder, or subject to any restrictions imposed
                      by a regulatory body in any territory where the usage,
                      acquisition, possession, or sale of digital tokens is
                      forbidden or limited by international or local laws. I
                      further assure that I am not engaging in any activities
                      related to digital tokens that would infringe upon any
                      applicable laws and regulations in my jurisdiction or any
                      jurisdiction with which I have a connection, such as
                      citizenship or residency. By signing this statement, I
                      assume complete responsibility for ensuring my adherence
                      to all relevant legal and regulatory requirements,
                      including any reporting, registration, or licensing
                      obligations that may be applicable to my involvement with
                      digital tokens.
                    </span>
                  </div>
                  <div className="mt-8">
                    <Button
                      fullWidth={true}
                      type="tertiary"
                      content={"Sign Message"}
                      size="small"
                      disabled={signInprogress}
                      iconSpin={signInprogress}
                      icon={signInprogress ? "faSpinner" : ""}
                      //@ts-ignore
                      iconType={signInprogress ? "normal" : ""}
                      iconAlignment={"right"}
                      onClickHandler={handleSignMessage}
                    />
                  </div>
                </div>
              </>
            }
            align={`center`}
            casing={"capitalize"}
            color={`white-500`}
          />
        </div>
      }
    />
  );
};

export default ConsentModal;

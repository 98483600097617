import BN from 'bn.js';
import { keccak256, toChecksumAddress } from 'ethereumjs-util';
import { Buffer } from "buffer";
/**
 * Parses an address from a hex string which may come from storage or a returned address via eth_call.
 *
 * @param addressString The address hex string.
 * @returns The parsed checksum address, or undefined if the input string is not an address.
 */

function parseAddress(addressString: string): string | undefined {
  const buf = Buffer.from(addressString.replace(/^0x/, ''), 'hex');
  if (!buf.slice(0, 12).equals(Buffer.alloc(12, 0))) {
    return undefined;
  }
  const address = '0x' + buf.toString('hex', 12, 32); // grab the last 20 bytes
  return toChecksumAddress(address);
}

export interface EthereumProvider {
    send(method: 'eth_getStorageAt', params: [string, string, string]): Promise<string>;
  }

export async function getImplementationAddress(provider: EthereumProvider, address: string): Promise<string> {
  const storage = await getStorageFallback(
    provider,
    address,
    toEip1967Hash('eip1967.proxy.implementation'),
    toFallbackEip1967Hash('org.zeppelinos.proxy.implementation'),
  );

  return parseAddressFromStorage(storage);
}

async function getStorageFallback(provider: EthereumProvider, address: string, ...slots: string[]): Promise<string> {
  let storage = '0x0000000000000000000000000000000000000000000000000000000000000000'; // default: empty slot

  for (const slot of slots) {
    storage = await getStorageAt(provider, address, slot);
    if (!isEmptySlot(storage)) {
      break;
    }
  }

  return storage;
}

async function getStorageAt(
    provider: EthereumProvider,
    address: string,
    position: string,
    block = 'latest',
  ): Promise<string> {
    const storage = await provider.send('eth_getStorageAt', [address, position, block]);
    const padded = storage.replace(/^0x/, '').padStart(64, '0');
    return '0x' + padded;
  }

function toFallbackEip1967Hash(label: string): string {
  return '0x' + keccak256(Buffer.from(label)).toString('hex');
}

function toEip1967Hash(label: string): string {
  const hash = keccak256(Buffer.from(label));
  const bigNumber = new BN(hash).sub(new BN(1));
  return '0x' + bigNumber.toString(16);
}

function isEmptySlot(storage: string): boolean {
  storage = storage.replace(/^0x/, '');
  return new BN(storage, 'hex').isZero();
}

function parseAddressFromStorage(storage: string): string {
  const address = parseAddress(storage);
  if (address === undefined) {
    throw new Error(`Value in storage is not an address (${storage})`);
  }
  return address;
}
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { OFAC_LIST } from "utils";

type IpStackResponse = {
  ip: string;
  country_name: string;
  city: string;
  region_name: string;
  country_code: string;
  zip: string;
  latitude: number;
  longitude: number;
};

type IpStackResult = {
  geoBlocked: boolean;
  setGeoBlocked: (blocked: boolean) => void
};

const fetchGeoData = async (): Promise<IpStackResponse> => {
  const url = "https://ro81h8hq6b.execute-api.us-east-1.amazonaws.com/geoip";
  const response = await axios.get<IpStackResponse>(url);
  return response.data as IpStackResponse;
};

export const useGeoIP = (): IpStackResult => {
  const [geoBlocked, setGeoBlocked] = useState<boolean | null>(null);
  const { data: geoData, isError, isLoading } = useQuery({
    queryKey: ['geoIP'],
    queryFn: fetchGeoData,
    retry: 6,
  });

  useEffect(() => {
    if (geoData && !geoBlocked && !isLoading && !isError) {
      const countryCode = geoData?.country_code || null;
      const shouldBlock = countryCode ? OFAC_LIST.find(d => d.code.toLowerCase() === countryCode.toLowerCase()) : null;
      setGeoBlocked(!!shouldBlock);
    }
  }, [geoData, geoBlocked, isLoading, isError])

  return { geoBlocked, setGeoBlocked };
};


import { AppHeaderCard } from "components/lib";
import { StakingPoolsContext } from "context/StakingPools";
import { useRewardApr } from "hooks/useRewardApr";
import { useContext, useEffect, useState } from "react";
import { beaconNameMapper, getTVLAndFees, getTokenURL } from "utils";
import {useWeeklyApr} from 'hooks/useWeeklyApr';

const AppHeaderSection = ({ vaultDetails, chainId, ipfsConfig, vaultData, sushiRewardPool, merklRewardPool }) => {
  const [tvl, setTvl] = useState([]);
  const [feeEarned, setFeeEarned] = useState([]);
  const { stakingPools } = useContext(StakingPoolsContext);
  const incentivized = stakingPools && stakingPools.find(d => d['stakingToken']?.toLowerCase() === vaultDetails["id"]?.toLowerCase());
  const {weeklyApr} = useWeeklyApr(vaultDetails["id"], chainId);
  //TVL update after deposit
  useEffect(() => {
    let notUpdateState = false;
    if (vaultDetails["token0"] && vaultDetails["token1"] && !notUpdateState) {

      (async () => {
        try {

          const { tvl, fees } = await getTVLAndFees(vaultDetails, chainId);
          setTvl(tvl);
          setFeeEarned(fees);
        } catch (e) {
          console.log(e);
        }
      })();
    }
    return () => {
      notUpdateState = true;
    };
  }, [vaultDetails, chainId]);

  const ipfsConfigStrategyData = ipfsConfig?.strategyConfigData;
  const beaconName = beaconNameMapper[vaultDetails["vaultType"]];


  let rewardApr = useRewardApr(incentivized).apr

  let stakingEnabled = false;

  if (merklRewardPool) {
    merklRewardPool['incentiveApr'] = merklRewardPool?.incentiveAprs?.[vaultDetails['id']]
  }
  if (!incentivized) {
    if(merklRewardPool && merklRewardPool["incentiveApr"] !== undefined) {
      stakingEnabled = true;
      rewardApr = (merklRewardPool["incentiveApr"]).toFixed(2);
    }
    if (sushiRewardPool) {
      stakingEnabled = true;
      rewardApr = (sushiRewardPool["incentiveApr"]*100).toFixed(2);
    }
  }

  const token0URL = getTokenURL(vaultDetails["token0"], vaultDetails['token0Symbol'], chainId, beaconName); 
  const token1URL = getTokenURL(vaultDetails['token1'], vaultDetails['token1Symbol'], chainId, beaconName); 

  return (
    <AppHeaderCard
      {...{
        appImage:
          ipfsConfigStrategyData?.appImgUrl ||
          "https://steer.finance/wp-content/uploads/2022/11/blockchain-300x300.png",
        appName: ipfsConfigStrategyData?.name || "",
        incentivized,
        id: vaultDetails["id"],
        data: [
          {
            type: "link",
            label: `Fee APR ${incentivized || stakingEnabled? '✨' : ""}`,
            feeAPR: `~${parseFloat(weeklyApr ? weeklyApr.apr : vaultData?.["weeklyFeeAPR"] || 0).toFixed(2)}%`,
            rewardAPR: `~${rewardApr || 0}%`,
            val: {
              onClick: () =>
                window.open(
                  `https://docs.steer.finance/concentrated-liquidity/apy-and-feeapr#what-is-fee-apr-and-how-is-it-calculated`
                ),
              text: `${(parseFloat(weeklyApr ? weeklyApr.apr : vaultData?.["weeklyFeeAPR"] || 0) + parseFloat(rewardApr || '0')).toFixed(2)}%`,
            },
          },
          {
            type: "multilineText",
            val: feeEarned,
            label: "Fee Earned",
          },
          {
            type: "asset",
            val: [
              token0URL,
              token1URL,
            ],
            label: `${vaultDetails["token0Symbol"]} - ${vaultDetails["token1Symbol"]}`,
          },
          {
            type: "text",
            val: `${parseInt(vaultData?.["feeTier"] || 0) / 10000}%`,
            label: "Fee Tier",
          },
          {
            type: "multilineText",
            val: tvl,
            label: "TVL",
          },
        ],
        appType: beaconName || "",
        cardType: "uniswap",
      }
    }
    />
  );
};

export default AppHeaderSection;

import React, { FunctionComponent } from "react";
import { TooltipProps, Tooltip } from "./Tooltip";
import { Shimmer } from "./Shimmer";

export interface BadgeProps extends TooltipProps {
  size?: "xsmall" | "small" | "medium" | "large" | "xlarge" | "xxlarge";
  content: string;
  onClick?: () => void;
  casing?: "uppercase" | "lowercase" | "capitalize";
  bgColor?: string;
  textColor?: string;
  borderColor?: string;
  tooltipId?: string;
}

const getSizeCss = (size) => {
  switch (size) {
    case "xsmall":
      return "text-xs px-2 ";
    case "small":
      return "text-sm px-4 py-2";
    case "medium":
      return "text-base px-4 py-2";
    case "large":
      return "text-lg px-4 py-3";
    case "xlarge":
      return "text-xl px-5 py-4";
    case "xxlarge":
      return "text-2xl px-6 py-4";
  }
};

export const Badge: FunctionComponent<BadgeProps> = (props) => {
  return (
    <div data-testid="badge-component" onClick={props.onClick}>
      {props.content ? (
        <span
          data-tip={props.tooltipId || ""}
          data-for={props.tooltipId || ""}
          className={`inline-flex font-medium items-center py-0.5 rounded-full border
        ${getSizeCss(props.size)} bg-${props.bgColor} text-${props.textColor}
        ${props.casing}
        ${props.onClick && "cursor-pointer"}
        border-${props.borderColor || props.bgColor}
      `}
        >
          {props.content}
          {props.tooltipId && (
            <Tooltip {...props} tooltipId={props.tooltipId} />
          )}
        </span>
      ) : (
        <Shimmer height="7" width="52" styleProps="rounded-full" />
      )}
    </div>
  );
};

Badge.defaultProps = {
  size: "medium",
  content: "strategy name",
  bgColor: "indigo-100",
  textColor: "indigo-600",
  casing: "capitalize",
};

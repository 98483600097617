import { useQuery } from "@tanstack/react-query";
import { Strategy } from "../utils/types";

export const useDataConnectorConfigs = (strategy: Strategy) => {
  const hashes = strategy && strategy?.dataConnectorBundle ? strategy.dataConnectorBundle.map((dataConnector) => {
    return dataConnector.bundleHash;
  }): [];

  return useQuery({
    queryKey: ["strategy-execution-data-1", hashes],
    queryFn: async () => {
      try {
        const wasmConfigs = [];
        if (hashes.length > 0) {
          for(const hash of hashes) {
              const { loadWasm } = await import("@steerprotocol/app-loader")
              const res = await fetch('https://ipfs.io/ipfs/' + hash, {
                method: "GET"
              })
              const buffer = await res.arrayBuffer();
              const wasm = await loadWasm(buffer);
              const config = JSON.parse(wasm.config());
              wasmConfigs.push({
                      hash,
                      config
                  });
          }   
        }
        return wasmConfigs;
      } catch(error) {
        console.log('Issue at fetching the configs', error);
        return []
      }
    }
  })
};

import { Button, IconDetailBox } from "components/lib"
import { strategies } from 'utils/sample/strategies';
const STRATEGY_MODE = [
	{
		icon: "🚀",
		name: "Quick & Easy Mode",
		desc: "Employ our Channel Multiplier Strategy to effectively establish a price channel around your desired asset. Increase capital efficiency of your pools the minimum by 2.5x based on liquidity depth. Best suited for v2 pool to v3 pool migration.",
	},
	{
		icon: "⚙️",
		name: "Advance Mode",
		desc: "Select custom strategies from a curated list of Steer's featured approaches, tailored to suit your specific requirements and adapt to the volatility of your investment pool",
	},
	{
		icon: "👷🏻",
		name: "Expert Mode",
		desc: "Upload Your Own Custom Strategy With the Data Connector & With this advanced capability, you gain full control over the smart pool's underlying configuration, allowing you to tailor it precisely to your unique requirements.",
	},
]


export const ChooseMode = ({ setMode, mode, account, setSelectedStrategy, selectedPool, moveNext, setDataConnectorConfigs, chainId }) => {
	const quickStrategyStats = strategies.find(d => d.name === "Channel Multiplier Strategy");

	// Custom check for the kava chain
	if (chainId === 2222) {
		console.log('Setting custom bundle');
		quickStrategyStats.executionBundle.hash = 'Qmao8D4SNwXCjLUqSYuLgC44EBrNDSwxZ9JZWAPTo7pbqn'; 
	}

	return (
		<>
			<div className="grid-cols-2 gap-4 mx-auto my-4  lg:grid">
				<div className="">
					<div className="text-3xl font-bold capitalize text-white-500">Select Smart Pool Creation Mode</div>
					<div className="my-2 capitalize text-white-300 text-md">Choose your smart pool creation mode: Quick and Easy, Advanced, or Expert, based on your experience with concentrated liquidity pools.</div>
				</div>
				<div className="flex flex-col">
					{!account && (
						<div className={`my-2`}>
							<IconDetailBox
								msg="Connect your wallet in order to deploy a smart pool."
								icon="faWallet"
							/>
						</div>
					)}
					{STRATEGY_MODE.map(d => (
						<div key={d.name} className={`border-2 rounded-md text-white-500  ${mode === d.name ? 'border-solid border-shamrock-500' : "border-dotted"} px-4 pt-4 my-2 ${account ? "cursor-pointer" : "cursor-default"}`}
							onClick={() => {
								if (account && selectedPool) {
									if (d.name === 'Quick & Easy Mode') {
										setSelectedStrategy(quickStrategyStats);
										setDataConnectorConfigs([{
											hash: quickStrategyStats.dataConnectorBundle[0].bundleHash,
											configData: {
												...quickStrategyStats.dataConnectorBundle[0].configData,
												poolAddress: selectedPool.id,
												chainId: chainId
											}
										}]);
									}
									setMode(d.name)
								}
							}}
						>
							<div className="flex justify-between mb-4">

								<div className="mr-2 text-2xl">{d.icon}</div>
								<div className="">
									<div className="text-lg font-bold">
										{d.name}
										<span className="mx-2 text-sm font-normal">{d.name === "Quick & Easy Mode" && `( Avg. APR - ${quickStrategyStats.avgApr}% )`}</span>
									</div>
									<div className="text-sm mx-">
										{d.desc}
									</div>

								</div>
							</div>
						</div>
					))}

				</div>


			</div>
			<div className="flex justify-end mt-2 md:mt-0">
				<Button
					content="Move To Next Step"
					size="xsmall"
					type="nsecondary"
					onClickHandler={moveNext}
					disabled={!selectedPool || !account || !mode}
				/>
			</div>
		</>
	)
}
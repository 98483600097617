import React, { useState, FunctionComponent } from "react";
import { Button, Label, Textarea } from "components/lib";
import {
  useEthers,
  useEtherBalance,
  useContractFunction,
} from "@usedapp/core";
import { utils } from "ethers";
import { Contract } from "@ethersproject/contracts";
import { sendNotification } from "components/Container";
import ModalHeader from "components/ModalHeader";
import { useChainId } from "hooks/useChainId";
import {
  getContractByNetwork,
  toTitleCase,
} from "utils";

export interface WhitelistProps {
  vault: {
    id: string;
    asset?: string;
    name?: string;
    permissions?: Array<any>;
    vaultGQLCall?: any;
  };
  closeModal: () => void;
  type?: "add" | "remove";
}

const Whitelist: FunctionComponent<WhitelistProps> = (props) => {
  const { account } = useEthers();
  const [chainId] = useChainId();

  const whitelistAlreadyAdded =
    props.type === "add" ? props.vault.permissions.length !== 0 && props.vault.permissions[0].addresses.join(",") : "";
  const [whitelist, setWhitelist] = useState(whitelistAlreadyAdded);
  const etherBalance = useEtherBalance(account, { chainId });
  const ethBalance = utils.formatEther(etherBalance || "0");
  const whitelistRegistry = getContractByNetwork(chainId, "WhitelistRegistry");
  const whitelistRegistryInterface = new utils.Interface(whitelistRegistry.abi);

  const whitelistRegistryContract = new Contract(
    whitelistRegistry.address,
    whitelistRegistryInterface,
  );

  const { send: addPermissions } = useContractFunction(
    whitelistRegistryContract,
    "addPermissions",
    {
      transactionName: `Adding new whitelist for vault ${props.vault.name}`,
    }
  );

  const { send: revokePermissions } = useContractFunction(
    whitelistRegistryContract,
    "revokePermissions",
    {
      transactionName: `Removing whitelist for vault ${props.vault.name}`,
    }
  );

  const whitelistHandler = async () => {
    const address = whitelist.split(",").map((d) => d.trim().toLowerCase());
    try {
      props.closeModal();
      if (props.type !== "remove") {
        await addPermissions(props.vault.id, address);
      } else {
        await revokePermissions(props.vault.id, address);
      }
      props.vault.vaultGQLCall.refetch();
    } catch (e) {
      console.log({ e });
      sendNotification({
        type: "error",
        transactionName: `Fail to ${props.type} new whitelist for vault ${props.vault.name}`,
        transaction: {
          ...e,
          msg: `Fail to ${props.type} new whitelist for vault ${props.vault.name}`,
        },
      });
    }
  };

  return (
    <div className={``}>
      <ModalHeader
        closeModal={props.closeModal}
        description={`Whitelist are permissioned vaults that only allow the list of added user to perform actions like deposit, withdraw etc`}
        title={`${toTitleCase(props.type)} whitelist for vault ${
          props.vault.name
        }`}
      />
      <div className="m-4">
        <Textarea
          onChange={setWhitelist}
          val={whitelist}
          heading={""}
          placeholder={`${toTitleCase(
            props.type
          )} comma seperated list of address to ${props.type} from whitelist`}
        />
      </div>
      {ethBalance === "0.0" && (
        <div className="mt-2 ">
          <Label
            align={"center"}
            content={`Not enough balance to perform the action`}
            color={"radical-red-500"}
            casing={"uppercase"}
            size={"small"}
          />
        </div>
      )}
      <div className={`col-span-1 w-full place-self-end p-4`}>
        <Button
          onClickHandler={whitelistHandler}
          disabled={ethBalance === "0.0"}
          content={`${props.type} Whitelist`}
          casing={`uppercase`}
          type={`tertiary`}
          fullWidth={true}
          size="small"
        />
      </div>
    </div>
  );
};

export default Whitelist;

import {
  AppDataRender,
  AppDetailsCard,
} from "components/lib";


const EngineConfigDetailsSection = ({ ipfsConfig }) => {
  return (
    <AppDetailsCard
      title={`App Engine Configuration Params`}
      description={`Each app engine is associated with a set of configuration designed to enhance the security of the underlying application.`}
      content={
        <AppDataRender
          data={[
            {
              type: "text",
              label: "Max Slippage",
              val: ipfsConfig?.vaultPayload?.slippage,
            },
            {
              type: "text",
              label: "Error Ratio Tolerance",
              val: ipfsConfig?.vaultPayload?.ratioErrorTolerance,
            },
            {
              type: "text",
              label: "Max Tick Change",
              val: ipfsConfig?.vaultPayload?.maxTickChange,
            },
            {
              type: "text",
              label: "TWAP Interval",
              val: ipfsConfig?.vaultPayload?.twapInterval,
            },
          ]}
          size={"small"}
        />
      }
    />
  );
};

export default EngineConfigDetailsSection;

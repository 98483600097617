"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.drip = exports.poolsToDrip = exports.getStakingPools = void 0;
const ethers_1 = require("ethers");
const constants_1 = require("../constants");
const types_1 = require("../types");
const logger_1 = require("./logger");
const multicall_1 = require("./multicall");
function getStakingPools(chainId) {
    const stablesSymbols = Object.values(constants_1.ALL_TOKENS[types_1.AssetType.STABLE]).map((token) => token.symbol);
    const stakingContracts = stablesSymbols.reduce((acc, stableSymbol) => {
        var _a;
        const agToken = (0, constants_1.registry)(chainId, { stablecoin: stableSymbol });
        if (!!(agToken === null || agToken === void 0 ? void 0 : agToken.collaterals)) {
            for (const collateral of Object.values(agToken === null || agToken === void 0 ? void 0 : agToken.collaterals)) {
                if (collateral.Staking) {
                    acc.push(collateral.Staking);
                }
                if (collateral.PerpetualManager) {
                    acc.push(collateral.PerpetualManager);
                }
            }
            const externalStaking = (_a = constants_1.CONTRACTS_ADDRESSES[chainId].ExternalStakings) === null || _a === void 0 ? void 0 : _a.map((token) => token.stakingContractAddress);
            if (externalStaking && externalStaking.length > 0) {
                acc.push(...externalStaking);
            }
        }
        return acc;
    }, []);
    return stakingContracts;
}
exports.getStakingPools = getStakingPools;
function computeDripAmount(stakingParams) {
    if (stakingParams.distributedRewards.gte(stakingParams.amountToDistribute)) {
        return ethers_1.BigNumber.from(0);
    }
    const timestamp = ethers_1.BigNumber.from(Date.now()).div(1000);
    const dripAmount = stakingParams.amountToDistribute.mul(timestamp.sub(stakingParams.lastDistributionTime)).div(stakingParams.duration);
    const _duration = stakingParams.duration;
    const timePassed = timestamp.sub(stakingParams.timeStarted);
    const _timeSinceStart = timePassed.gt(_duration) ? _duration : timePassed;
    const timeLeft = stakingParams.duration.sub(_timeSinceStart);
    const rewardsLeftToDistribute = stakingParams.amountToDistribute.sub(stakingParams.distributedRewards);
    if (timeLeft.lt(stakingParams.updateFrequency) || rewardsLeftToDistribute.lt(dripAmount) || timeLeft.eq(0)) {
        return rewardsLeftToDistribute;
    }
    else {
        return dripAmount;
    }
}
function poolsToDrip(stakingContracts, provider, chainId) {
    return __awaiter(this, void 0, void 0, function* () {
        const rewardsDistributorAddress = constants_1.CONTRACTS_ADDRESSES[chainId].RewardsDistributor;
        const calls = stakingContracts.map((contract) => {
            return (0, multicall_1.addCall)(constants_1.RewardsDistributor__factory.createInterface(), rewardsDistributorAddress, constants_1.RewardsDistributor__factory.createInterface().functions['stakingContractsMap(address)'].name, [contract]);
        });
        const results = yield (0, multicall_1.execMulticall)(calls, provider, chainId);
        const dripAvailable = [];
        for (let i = 0; i < results.length; i++) {
            const stakingParams = results[i];
            const nextDripTimestamp = stakingParams.lastDistributionTime.add(stakingParams.updateFrequency);
            (0, logger_1.Logger)('nextDripTimestamp', new Date(nextDripTimestamp.toNumber() * 1000), stakingContracts[i]);
            if (Date.now() / 1000 >= nextDripTimestamp.toNumber() && computeDripAmount(stakingParams).gt(0)) {
                dripAvailable.push(stakingContracts[i]);
            }
        }
        (0, logger_1.Logger)(dripAvailable);
        return dripAvailable;
    });
}
exports.poolsToDrip = poolsToDrip;
function drip(stakingContract, provider, signer, chainId) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const address = (_a = (0, constants_1.registry)(chainId)) === null || _a === void 0 ? void 0 : _a.RewardsDistributor;
        if (!!address) {
            const contract = constants_1.RewardsDistributor__factory.connect(address, provider);
            const tx = yield contract.connect(signer).drip(stakingContract);
            (0, logger_1.Logger)('tx drip: ', tx);
            const receipt = yield tx.wait();
            (0, logger_1.Logger)('receipt drip: ', receipt);
        }
        else {
            console.error('RewardsDistributor does not exist on this chain');
        }
    });
}
exports.drip = drip;
function default_1(provider, signer, chainId) {
    return __awaiter(this, void 0, void 0, function* () {
        const stakingContracts = getStakingPools(chainId);
        const toDrip = yield poolsToDrip(stakingContracts, provider, chainId);
        for (const stakingContract of toDrip) {
            yield drip(stakingContract, provider, signer, chainId);
        }
    });
}
exports.default = default_1;

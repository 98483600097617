import React, { FunctionComponent } from "react";

export interface StepsProps {
  content: Array<{
    name: string;
    status: string;
  }>;
  type?: "horizontal" | "vertical";
  headingSize?: string;
  headingColor?: string;
  headingCase?: "uppercase" | "lowercase" | "capitalize";
}


export const Steps: FunctionComponent<StepsProps> = (props) => {
  return (
    <div data-testid="steps-component">
      <nav aria-label="Progress">
        <ol
          className="space-y-4 md:flex md:space-y-0 md:space-x-8"
        >
          {props.content.map((step, stepIdx) => (
            <li key={step.name} className="md:flex-1">
              <div
                className={`group md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4 flex flex-col
              ${
                step.status === "complete" || step.status === "current"
                  ? "border-shamrock-600"
                  : "border-waikawa-gray-500"
              }`}
              >
                <span
                  className={`text-xs font-semibold tracking-wide uppercase
                  ${
                    step.status === "complete" || step.status === "current"
                      ? "text-shamrock-600"
                      : "text-waikawa-gray-500"
                  }`}
                >
                  Step - {stepIdx + 1}
                </span>
                <span
                  className={`text-sm font-medium capitalize 
                  ${
                    step.status === "complete" || step.status === "current"
                      ? "text-white-500"
                      : "text-waikawa-gray-500"
                  }`}
                >
                  {step.name}
                </span>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

Steps.defaultProps = {
  content: [],
};

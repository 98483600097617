import React, { FunctionComponent } from "react";
import { Badge } from "../Badge";
// import Vector from "./appSVG";
import { LabelData } from "../LabelData";
import { Shimmer } from "../Shimmer";
import { getBadgeUrl } from "./AppCard";
// import { Icon } from "../Icon";

export interface AppCardProps {
  appType?: string;
  appDescription?: string;
  appImage: string;
  appName: string;
  data: Array<Object>;
  cardType?: string;
  tooltipId?: string | null;
  tooltipContent?: any ;

}

export const AppDataRender = ({ data, size = "normal", _class = "", id="" }) => {
  return (
    <div
      className={`grid grid-cols-2 gap-1 md:grid-cols-${size === 'normal' ? data.length: '2'} lg:mt-8 ${_class ? _class : "mt-4"} `}
    >
      {data.map((d) => {
        return (
          <div className={`pr-2 mt-4 lg:mt-0`} key={d.label}>
            <LabelData
              label={d?.label}
              labelStyle={`capitalize ${
                size === "normal" ? "text-base" : "text-sm"
              } text-gray-500`}
              data={d?.val}
              dataStyle={`${
                size === "normal" ? "text-lg" : "text-base"
              } text-black-500 font-semibold mt-0`}
              alignment="vertical"
              dataType={d?.type || "text"}
              //@ts-ignore
              tooltipContent={d?.label === 'Fee APR ✨' && <div className="m-1"> {d.feeAPR}: Fee APR <br/> {d.rewardAPR}: Reward APR</div>}
              tooltipId={d?.label === 'Fee APR ✨' && id}
            />
          </div>
        );
      })}
    </div>
  );
};

export const AppHeaderCard: FunctionComponent<AppCardProps> = (props) => {
  const { data, appType, appImage, appName } = props;
  return (
    <div
      data-testid="AppHeaderCard-component"
      className="relative p-6 lg:px-12 lg:pt-8"
      style={{
        background: "#EFEFFD",
        boxShadow: "0px 0px 30px 5px rgba(0, 0, 0, 0.1)",
        borderRadius: "12px",
      }}
    >
       
      <div
        className="absolute top-5 right-5 hidden xl:block"
        style={{
          width: '200px',
          height: '200px'
        }}
      >
        <img
          src={getBadgeUrl(appType)}
          alt={appType}
          style={{border: 'none', width: '100%'}}
          />
      </div>
      <div className="inline-flex">
        {appImage ? (
          <img
            className={`${"relative w-12 h-12 lg:w-20 lg:h-20 rounded-full"}`}
            src={appImage}
            alt={"app icon"}
          />
        ) : (
          <Shimmer height="12" width="12" />
        )}
        <div className="flex flex-col flex-wrap px-4 max-w-xxl ">
          <div className="text-xl font-semibold leading-10 lg:text-4xl text-black-500">
            {appName ? (
              appName
            ) : (
              <Shimmer height="7" width="52" styleProps={`rounded-full`} />
            )}
          </div>
          <div className="mt-6 block md:flex ">
            <Badge
              textColor="black-500"
              borderColor="black-500"
              bgColor="transparent"
              content={appType}
              size={"xsmall"}
            />
             {props["incentivized"] && <div className="mx-0 my-1 md:my-0 md:mx-1">
              <Badge
                textColor="black-500"
                borderColor="black-500"
                bgColor="transparent"
                content={'Incentivized App'}
                size={"xsmall"}
              />
             </div>}
          </div>
          <AppDataRender data={data} id={props["id"]}/>
        </div>
      </div>
    </div>
  );
};

AppHeaderCard.defaultProps = {
  cardType: "uniswap",
};

import { createContext } from 'react';

type sushiRewardPoolProps = {
    sushiRewardPools: Array<object>;
    loadingSushiRewardPools: boolean;
}

export const SushiRewardPoolsContext = createContext<sushiRewardPoolProps>({
    sushiRewardPools: [],
    loadingSushiRewardPools: true
});
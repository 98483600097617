import { FunctionComponent, useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  Label,
  Search,
} from "components/lib";
import { getClient } from "services/graphql";
import { supportedChains, paginateData } from "utils";
import { useConfig, useEthers } from "@usedapp/core";
import { gql, useLazyQuery } from "@apollo/client";
import { TransparentCard } from "components/TransparentCard";
import ViewConfig from "./modals/viewConfig";
import UploadConnector from "./modals/uploadConnector";
import TitleWithDocLink from "components/TitleWithDocLink";
import DataConnectorCard from "components/customCards/DataConnectorCard";
import { DeprecatedBundlesContext } from "context/DeprecatedBundles";
import { useChainId } from "hooks/useChainId";

const GET_BUNDLES = gql`
  query getBundles($ids: [String!]) {
    bundles(where:{id_not_in: $ids}) {
      creator
      host
      id
      bundle
      source
      output
      createdAt
      infoHash
    }
  }
`;

const DataMarketplace: FunctionComponent = () => {
  const { account } = useEthers();
  const [chainId] = useChainId();
  const [dataConnectors, setDataConnectors] = useState([]);
  const [viewDataConnector, setViewDataConnector] = useState(null);
  const [addedDataConnector, setAddedDataConnector] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gqlBundleCall, setGqlBundleCall] = useState(false);
  // const [pageCount, setPageCount] = useState(1000);
  const [debouncedTerm, setDebouncedTerm] = useState(null);
  const pageIndex = 0;
  let graphClient = getClient(supportedChains[0].subgraphURl);
  const tconfig = useConfig();
  const supportedChain = supportedChains.find((chain) => {
    if(chainId) {
      return chain.id === chainId;
    } else {
      return chain.id === tconfig.readOnlyChainId
    }
  });
  
  if (supportedChain) {
    graphClient = getClient(supportedChain.subgraphURl);
  }
  const [getBundles, bundles] = useLazyQuery(GET_BUNDLES, {
    client: graphClient,
  });
  const { deprecatedBundles } = useContext(DeprecatedBundlesContext);
  let deprecatedBundlesIds = (deprecatedBundles || []).filter((bundle) => bundle["type"] === "DATA_CONNECTOR").map(d => d["id"]);
  deprecatedBundlesIds = deprecatedBundlesIds.length === 0 ? [""] : deprecatedBundlesIds;

  useEffect(() => {
    if(chainId) {
      setDataConnectors([]);
    }
  }, [chainId]);

  useEffect(() => {
    if (account || addedDataConnector) {
      getBundles({variables: {ids: deprecatedBundlesIds}});
      setAddedDataConnector(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, addedDataConnector]);

  const handleUserKeyPress = (event) => {
    const { keyCode } = event;
    if (keyCode === 27) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", handleUserKeyPress);
    return () => {
      window.removeEventListener("keyup", handleUserKeyPress);
    };
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      getBundles({variables: {ids: deprecatedBundlesIds}});
    }, 5000);
    return () => {
      clearInterval(id);
    };
  }, [getBundles, deprecatedBundlesIds]);

  useEffect(() => {
    let notUpdateState = false;
    if (!notUpdateState && !gqlBundleCall) {
      getBundles({variables: {ids: deprecatedBundlesIds}});
      setGqlBundleCall(true);
    }
    return () => {
      notUpdateState = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gqlBundleCall, deprecatedBundlesIds]);


  useEffect(() => {
    const allDataConnectors = dataConnectors.reduce(
      (acc, item) => acc.concat(item),
      []
    );
    if (
      (bundles?.data?.bundles && dataConnectors.length === 0) ||
      (bundles?.data?.bundles?.length !== allDataConnectors.length &&
        allDataConnectors.length > 0)
    ) {
      const _bundles = bundles?.data?.bundles;
      const pageBreakupData = paginateData(_bundles);
      // const pageCount = Math.ceil(_bundles.length / pageSize);
      if (dataConnectors.length === 0) {
        // setPageCount(pageCount);
        setDataConnectors(pageBreakupData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundles.data]);

  const onSearch = (text) => {
    const _text = text.toLowerCase();
    if (text.length > 1) {
      const searchedData = bundles?.data?.bundles.filter((d) => {
        return (
          d.source.toLowerCase().indexOf(_text) !== -1 ||
          d.output.toLowerCase().indexOf(_text) !== -1
        );
      });
      if(searchedData.length > 0) {
        const pageBreakupData = paginateData(searchedData);
        setDataConnectors(pageBreakupData);
      }
    } else {
      const pageBreakupData = paginateData(bundles?.data?.bundles);
      setDataConnectors(pageBreakupData);
    }
  };

  useEffect(() => {
    if(debouncedTerm !== null) {
      const timer = setTimeout(() => onSearch(debouncedTerm), 1000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [debouncedTerm])

  // const fetchRowsData = (pageIndex, pageSize) => {
  //   setPageIndex(pageIndex);
  //   return dataConnectors.length > pageIndex ? getJobPageDataObjs : [];
  // };

  // const getJobPageDataObjs = () => {
  //   return dataConnectors.length > 0 ? dataConnectors[pageIndex] : [];
  // };

  const _closeModal = () => {
    if (viewDataConnector) {
      setViewDataConnector(null);
    }
    setModalType(null);
    setIsModalOpen(false);
  };

  return (
    <>
       <div
      className={`py-4 m-auto lg:h-full w-10/12 lg:w-9/12`}
    >
        <TransparentCard>
          <TitleWithDocLink
            title="Data Marketplace"
            link="https://docs.steer.finance/data-connectors/data-marketplace"
            linkText="Learn about data connectors?"
						secondaryElement={ <Button
							onClickHandler={() => {
								setIsModalOpen(true);
								setModalType("upload");
							}}
							content={"Upload Connector"}
							casing={`uppercase`}
							type={`tertiary`}
							size={"small"}
							disabled={!account}
						/>}
          />
					<div className="my-8">
          <div className="grid-cols-1 px-4 lg:px-0 lg:grid">
            <Search
              placeholder={`Search by Name`}
              onChangeHandler={(val) => setDebouncedTerm(val)}
              backgroundType="white"
            />
          </div>

          <div className="flex flex-wrap items-center justify-evenly">
            {!bundles.loading && dataConnectors.length > 0 && dataConnectors[pageIndex].map((item) => {
              return (
                <div className="flex py-4">
                  <DataConnectorCard
                    info={{...item, chainId, onClick: (data) => {
                      const newData = {...item, data};
                      setViewDataConnector(newData);
                      setIsModalOpen(true);
                      setModalType("view");
                    }}}
                    key={item.id}
                  />
                </div>
              );
            })}
          </div>
          {!bundles.loading && dataConnectors.length === 0 && (
            <div className="flex flex-col items-center justify-center">
              <div className="text-3xl">📝</div>
              <div className="my-4">
                <Label
                  content={
                    "No Data Connector Found. Add The First Data Connector."
                  }
                  align={`center`}
                  size={`large`}
                  color={`white-500`}
                />
              </div>
            </div>
          )}
					</div>
          
        </TransparentCard>
      </div>
      {isModalOpen && modalType === "view" && (
        <ViewConfig
          isOpen={isModalOpen}
          closeModal={_closeModal}
          data={viewDataConnector}
        />
      )}
      {isModalOpen && modalType === "upload" && (
        <Modal
          isOpen={isModalOpen}
          onClose={_closeModal}
          size={"large"}
          content={
            <UploadConnector
              sources={dataConnectors.reduce(
                (acc, item) => acc.concat(item),
                []
              )}
              setShowModal={_closeModal}
              setSources={setAddedDataConnector}
            />
          }
        />
      )}
    </>
  );
};

export default DataMarketplace;

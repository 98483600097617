import React, { useEffect, useState } from "react";

import { Icon, Label, Steps } from "components/lib";
import { useHistory } from "react-router-dom";

import Details from "./details";
import DataConnector from "./dataConnector";
import Assets from "./assets";
import ConfigVault from "./configVault";
import { useEthers } from "@usedapp/core";
import { TransparentCard } from "components/TransparentCard";
import TitleWithDocLink from "components/TitleWithDocLink";
import { getIpfsJSONConfig } from "utils";

const PUBLISHSTRATEGY = "Publish An App";
const CLONEAPP = "Clone An App";

const initData = {
  strategyData: null,
  dataConnectors: null,
  asset: null,
  clone: false,
  cloneAppBeaconName: '',
  payloadHash: null,
};
const AddStrategy = () => {
  const history = useHistory();
  const dataPassed = history?.location?.state?.data;
  const [loading, setLoading] = useState(false);
  const { account } = useEthers();
  const [data, setData] = useState(initData);
  const [steps, setSteps] = useState(0);
  const [strategySteps, setStrategySteps] = useState([
    { name: "Upload & Configure App", status: "current" },
    { name: "Configure Steer Engine For App", status: "incomplete" },
    {
      name: "Select & Config Data Connectors",
      status: "incomplete",
    },
    { name: "Publish App", status: "incomplete" },
  ]);

  useEffect(() => {
    if (dataPassed) {
      (async () => {
        setLoading(true);

        const payloadData = await getIpfsJSONConfig(dataPassed.payloadHash);

        setData({
          strategyData: {
            id: parseInt(dataPassed?.id).toString(),
            ...payloadData?.strategyConfigData,
            executionBundle: dataPassed?.executionHash,
            vault: {
              payload:  payloadData?.vaultPayload
            },
            isDisabled: true,
            vaultPayload: payloadData?.vaultPayload,
          },
          dataConnectors: payloadData?.dataConnectorsData.map((d) => ({
            bundle: d.bundleHash,
            payloadHash: d.configData,
          })),
          cloneAppBeaconName: dataPassed?.type,
          asset: `${dataPassed?.token0}-${dataPassed?.token1}`,
          clone: true,
          payloadHash: dataPassed.payloadHash
        });

        setLoading(false);
      })();
    }
  }, [dataPassed]);

  useEffect(() => {
    const newSteps = strategySteps.map((step, i) => {
      if (i === steps - 1) {
        step.status = "complete";
      } else if (i === steps) {
        step.status = "current";
      } else if (i > steps) {
        step.status = "incomplete";
      }
      return step;
    });
    setStrategySteps(newSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps]);
  useEffect(() => {
    if (!account) {
      setSteps(0);
      setData(initData);
    }
  }, [account]);
  return (
    <div
    className={`py-4 m-auto lg:h-full w-10/12 lg:w-9/12`}
  >
      <TransparentCard>
        <TitleWithDocLink
          title={dataPassed ? CLONEAPP : PUBLISHSTRATEGY}
          link="https://docs.steer.finance/steer-apps/"
          linkText="Learn more about an App?"
        />
        <div className="mx-4 mt-10 bottom-15">
          <Steps type="horizontal" content={strategySteps} />
        </div>
        <div
          className={`mt-4 border border-lynch-rgba shadow-xs`}
          style={{ width: "100%" }}
        />
        {loading && (
          <div className="flex flex-col items-center justify-center">
            <Icon
              name="faSpinner"
              spin={true}
              size={"4x"}
              type="normal"
              color={"bg-white-100"}
            />
            <div className="my-4">
              <Label
                content={"Cloning App for config..."}
                align={`center`}
                size={`large`}
                color={`white-500`}
              />
            </div>
          </div>
        )}
        {!loading && (
          <div>
            {steps === 0 && (
              <Details
                steps={steps}
                setSteps={setSteps}
                setData={setData}
                data={data}
              />
            )}
            {steps === 1 && (
              <ConfigVault
                steps={steps}
                setSteps={setSteps}
                setData={setData}
                data={data}
              />
            )}
            {steps === 2 && (
              <DataConnector
                steps={steps}
                setSteps={setSteps}
                setData={setData}
                data={data}
              />
            )}

            {steps === 3 && (
              <Assets
                data={data}
                steps={steps}
                setSteps={setSteps}
                setData={setData}
              />
            )}
          </div>
        )}
      </TransparentCard>
    </div>
  );
};

export default AddStrategy;

import {
  AppDetailsButton,
} from "components/lib";
import { GeoIpContext } from "context/GeoIp";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  beaconContractNameMapper,
  getClonePageDetails,
  getWhitelistVaults
} from "utils";

import { ConsentContext } from "context/Consent";
import { StakingPoolsContext } from "context/StakingPools";

const ButtonSection = ({
    vaultData,
    ipfsConfig,
    vaultDetails,
    account,
    setShowGeoBlockModal,
    setIsModalOpen,
    setModalType,
    isDeprecated,
    differentChain,
    supportedChain
}) => {
  const { geoBlocked } = useContext(GeoIpContext);
  const { setShowConsentModal, hasSigned } = useContext(ConsentContext);
  const history = useHistory();
  const { stakingPools } = useContext(StakingPoolsContext);
  let vaultActions = [
    { label: "deposit", key: "deposit" },
    { label: "Withdraw", key: "withdraw" },
    { label: "withdraw-fees", key: "withdraw-fees" },
    { label: "Remix App", key: "clone-app" },
    { label: "Fund Gas", key: "fund-gas" },
    { label: "add-whitelist", key: "add-whitelist" },
    { label: "remove-whitelist", key: "remove-whitelist" },
  ];

  const isStakeEnabled = stakingPools && stakingPools.find(d => d["stakingToken"].toLowerCase() === vaultData?.id?.toLowerCase()) ? true : false;


  if(isStakeEnabled) {
     vaultActions.splice(2, 0 , { label:  "Stake Lp Tokens", key: "stake" },)
  }
  const filterActions = (keys) => {
    vaultActions = vaultActions.filter(({ key }) => {
      return keys.indexOf(key) === -1;
    });
  };
  const strategy = vaultData?.strategyToken;
  const ipfsConfigStrategyData = ipfsConfig?.strategyConfigData;
  /* 
    check for strategy owner remove withdraw fees option 
    if strategy owner is not current user
  */
  if (account && strategy?.creator?.id !== account.toLowerCase()) {
    filterActions(["withdraw-fees", "add-whitelist", "remove-whitelist"]);
  }
  if ((vaultData && vaultData?.payloadIpfs.length === 0) || !account) {
    filterActions(["clone-app"]);
  }

  const permissions = vaultData ? vaultData?.permissions : [];

  if (
    vaultDetails["vaultDetails"] &&
    getWhitelistVaults.indexOf(
      beaconContractNameMapper[vaultDetails["vaultType"]]
    ) !== -1
  ) {
    const whitelistAddress = permissions;
    if (account && whitelistAddress.indexOf(account?.toLowerCase()) === -1) {
      filterActions(["withdraw", "withdraw-fees", "deposit"]);
    }
  } else {
    filterActions(["add-whitelist", "remove-whitelist"]);
  }

  const cloneObj = getClonePageDetails(
    strategy, vaultDetails, ipfsConfig, supportedChain
  );

  const handleAction = (action) => {
    if(action.key === 'stake') {
      history.push({
        pathname: "/stake",
        state: {
          data: {
            vaultId: vaultData.id,
          },
        },
      });
    }
    if (geoBlocked && action.key !== 'withdraw') {
      setShowGeoBlockModal(true);
      setModalType('geoblocked');

    } else if (!hasSigned && localStorage.getItem('consent-verified') !== 'true' && action.key !== 'withdraw') {
      setShowConsentModal(true);
      setModalType('consentRequired');
    } else {
      if (vaultDetails["token0Name"] !== "" && action.key !== "clone-app" &&  action.key !== "stake") {
        setIsModalOpen(true);
        setModalType(action.key);
      } else {
        if (action.key === "clone-app") {
          // clone
          history.push({
            pathname: "/publish-smart-pool",
            state: {
              data: cloneObj,
            },
          });
        }
      }
    }
  };
  return (
    <AppDetailsButton
    primaryActionables={[
      {
        type: "nprimary",
        name: vaultActions[0].label,
        onClick: () => handleAction(vaultActions[0]),
        disabled:
          !account ||
          !ipfsConfig ||
          !ipfsConfigStrategyData ||
          !vaultData || 
          Object.keys(vaultDetails).length === 0 ||
          vaultData?.state === "2" ||
          vaultData?.state === "4"
          || vaultData["vaultType"]
          || differentChain,
      },
      {
        type: "nsecondary",
        name: vaultActions[1].label,
        onClick: () => handleAction(vaultActions[1]),
        disabled: !account || Object.keys(vaultDetails).length === 0 || !vaultData || differentChain || vaultData["vaultType"],
      },
    ]}
    secondaryActionables={vaultActions
      .slice(2, vaultActions.length)
      .map((d) => {
        return {
          type: d.label === "Stake Lp Tokens" ? "primary" : "tertiary",
          name: d.label,
          onClick: () => handleAction(d),
          disabled: d.label === "Stake Lp Tokens" ? false : !account || differentChain || (d.label === 'Remix App' && isDeprecated),
          fullWidth: true,
          size: 'small'
        };
      })
      .concat([
        {
          type: "tertiary",
          name: "Backtest",
          onClick: () => {
            const download = window.open(
              `https://ipfs.io/ipfs/${strategy?.executionBundle}`
            );
            if(download) {
              download.focus();
              download.onblur = function () {
                window.open(
                  `https://codesandbox.io/p/sandbox/steer-protocol-development-environment-pj7g2v?file=%2FREADME.md&selection=%5B%7B%22endColumn%22%3A43%2C%22endLineNumber%22%3A20%2C%22startColumn%22%3A43%2C%22startLineNumber%22%3A20%7D%5D`
                );
              };
            }
          },
          disabled: !vaultData,
          fullWidth: true,
          size: 'small'
        },
      ])}
  />
  );
};

export default ButtonSection;
import { Token } from '@uniswap/sdk-core'
import { computePoolAddress, FeeAmount, Pool } from '@uniswap/v3-sdk'

import { ethers, utils } from 'ethers'
import { getFactoryAddress, getInitCodeHAsh } from 'utils'
import { Protocol } from 'utils/types'
import IUniswapV3Pool from "utils/IUniswapV3Pool.json";

export const getConcentratedLiquidityPools = async ({
  chainId,
  protocol,
  poolKeys,
  provider
}: {
  chainId: number,
  protocol: Protocol,
  provider: ethers.providers.Provider,
  poolKeys: [Token | undefined, Token | undefined, FeeAmount | undefined, Protocol | undefined][]
}): Promise<(Pool | null)[]> => {
  let poolTokens: ([Token, Token, FeeAmount] | undefined)[]
  if (!chainId) {
    poolTokens = new Array(poolKeys.length)
  } else {
    poolTokens = poolKeys.map(([currencyA, currencyB, feeAmount]) => {
      if (currencyA && currencyB && feeAmount) {
        const tokenA = currencyA.wrapped
        const tokenB = currencyB.wrapped
        if (tokenA.equals(tokenB)) return undefined

        return tokenA.sortsBefore(tokenB) ? [tokenA, tokenB, feeAmount] : [tokenB, tokenA, feeAmount]
      }
      return undefined
    })
  }
  const poolAddresses = poolTokens.map(
    (value) =>
      value &&
      computePoolAddress({
        factoryAddress: getFactoryAddress(protocol, chainId)!,
        tokenA: value[0],
        tokenB: value[1],
        fee: value[2],
        initCodeHashManualOverride: getInitCodeHAsh(protocol, chainId)
      })
  )
  const abi = [
    {
      inputs: [],
      name: 'slot0',
      outputs: [
        { internalType: 'uint160', name: 'sqrtPriceX96', type: 'uint160' },
        { internalType: 'int24', name: 'tick', type: 'int24' },
        { internalType: 'uint16', name: 'observationIndex', type: 'uint16' },
        { internalType: 'uint16', name: 'observationCardinality', type: 'uint16' },
        { internalType: 'uint16', name: 'observationCardinalityNext', type: 'uint16' },
        { internalType: 'uint8', name: 'feeProtocol', type: 'uint8' },
        { internalType: 'bool', name: 'unlocked', type: 'bool' },
      ],
      stateMutability: 'view',
      type: 'function',
    },
  ];
  
  // Create an array to store the results
  const slot0s = [];
  
  // Then, for each contract address, create a new Contract instance and call the 'slot0' function.
  for (let i = 0; i < poolAddresses.length; i++) {
    const contract = new ethers.Contract(poolAddresses[i], abi, provider);
    const result = await contract.slot0();

    slot0s.push(result);
  }
  
  // Create an array to store the results
  const liquidities = [];
    // Then, for each contract address, create a new Contract instance and call the 'liquidity' function.
  for (let i = 0; i < poolAddresses.length; i++) {
    const uniswapV3PoolInterface = new utils.Interface(IUniswapV3Pool.abi);

    const contract = new ethers.Contract(
      poolAddresses[i],
      uniswapV3PoolInterface,
      provider
    );
    const result = await contract.liquidity();
    liquidities.push(result);
  }

  const poo = poolKeys.map((_key, index) => {
    const tokens = poolTokens[index]
    if (!tokens) return null
    const [token0, token1, fee] = tokens

    if (!slot0s[index]) return null
    const slot0 = slot0s[index]

    if (!liquidities[index]) return null
    const liquidity = liquidities[index]

    if (!tokens || !slot0 || !liquidity) return null
    if (!slot0 || !liquidity) return null
    if (!slot0.at(0) || slot0?.at(0) === 0) return null


    return new Pool(token0, token1, fee, slot0[0].toString(), liquidity.toString(), slot0[1])
  });

  return poo;
}

export const getConcentratedLiquidityPool = async ({
  chainId,
  protocol,
  token0,
  token1,
  feeAmount,
  provider
}: {
  chainId: number
  protocol: Protocol,
  token0: Token | undefined
  token1: Token | undefined
  feeAmount: FeeAmount | undefined,
  provider: ethers.providers.Provider
}): Promise<Pool | null> => {
  const poolKeys: [Token | undefined, Token | undefined, FeeAmount | undefined, Protocol | undefined][] = [[token0, token1, feeAmount, protocol]]
  return (await getConcentratedLiquidityPools({ poolKeys, chainId, protocol, provider }))[0]
}

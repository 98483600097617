import React, { useEffect, useState } from "react";
import { Contract } from "@ethersproject/contracts";
import { Input, Button } from "components/lib";
import { useContractFunction, useEthers } from "@usedapp/core";
import { utils } from "ethers";
import { sendNotification } from "components/Container";

import ModalHeader from "components/ModalHeader";
import UploadFile from "../../apps/publish/common/fileUpload";
import { getContractByNetwork, uploadFileAndGetHash } from "utils";
// import { getUniqTypes } from "../../apps/publish/common/configModal";
import CommonAppConfig from "components/CommonAppConfig";
import { useChainId } from "hooks/useChainId";

const UploadConnector = ({
  setShowModal,
  setSources,
  sources,
}: {
  setShowModal: (show: boolean) => void;
  setSources: (data: any) => void;
  sources: any;
}) => {
  const { account } = useEthers();

const [chainId] = useChainId();
  // const outputDropdown = getUniqTypes(
  //   (sources || []).map((d) => ({
  //     label: d.output,
  //     value: d.output,
  //   }))
  // );
  const BundleRegistry = getContractByNetwork(chainId, "BundleRegistry")
  const bundleRegistryInterface = new utils.Interface(BundleRegistry.abi);

  const bundleRegistryContract = new Contract(
    BundleRegistry.address,
    bundleRegistryInterface
  );

  const [newDataConnector, setNewDataConnector] = useState({
    name: null,
    host: null,
    bundle: null,
    output: null,
    description: null,
    appImgUrl: null,
    jsonOutput: null,
  });

  const { send: registerDataConnector, state } = useContractFunction(
    bundleRegistryContract,
    "register",
    {
      transactionName: `Adding Data Connector for ${newDataConnector.name} & ${newDataConnector.host}`,
    }
  );

  const updateData = (val: string | number, key: string) => {
    const tempObj: Record<string, unknown> = {};
    if (typeof val === "string") {
      tempObj[key] = String(val);
    } else {
      tempObj[key] = val;
    }
    setNewDataConnector({
      ...newDataConnector,
      ...tempObj,
    });
  };

  const formJsonForAllPayload = async () => {
    let jsonFile = {};
    jsonFile["metaData"] = {
      ...newDataConnector,
    };

    const blobFile = new Blob([JSON.stringify(jsonFile)], {
      type: "application/json",
    });
    try {
      const hash = await uploadFileAndGetHash(blobFile);
      return hash;
    } catch (e) {
      console.log({ e });
      return "Issue uploading the hash";
    }
  };

  useEffect(() => {
    if (state.status === "Success" || state.status === "Mining") {
      const tempSources = [
        {
          ...newDataConnector,
          id: newDataConnector.bundle,
          __typename: "Bundle",
          creator: account,
        },
      ].concat([...sources]);
      setSources(tempSources);
      setShowModal(false);
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const addDataConnectorBundle = async () => {
    // do a contract call to save it on bundle registry
    const name = newDataConnector.name.replaceAll(" ", "-").toLowerCase();
    const nameAlreadyExits = sources.find(
      (source) => source.source.replaceAll(" ", "-").toLowerCase() === name
    );
    if(nameAlreadyExits) {
      sendNotification({
        type: "error",
        transactionName: `Data Connector Error`,
        transaction: {
          msg: `Data Connector bundle with same name already exits, please choose a different name and try again.`,
        },
      });
    } else {
      const alreadyExists = sources.find(
        (source) => source.bundle === newDataConnector.bundle
      );
      if (alreadyExists) {
        sendNotification({
          type: "error",
          transactionName: `Data Connector Error`,
          transaction: {
            msg: `Data Connector bundle already exists for ${alreadyExists.source} & ${alreadyExists.host}`,
          },
        });
      } else {
        const ipfsHash = await formJsonForAllPayload();
  
        try {
          registerDataConnector(
            newDataConnector.bundle,
            newDataConnector.name,
            newDataConnector.host,
            newDataConnector.output,
            ipfsHash,
            true
          );
        } catch (e) {
          console.log(e);
          sendNotification({
            type: "error",
            transactionName: `Add Data Connector `,
            transaction: {
              ...e,
              msg: `Failed to Add Data Connector for ${newDataConnector.name} & ${newDataConnector.host}`,
            },
          });
        }
      }
    }

    
  };
  return (
    <div>
      <ModalHeader
        closeModal={() => setShowModal(false)}
        title={"Add Data Connector"}
        description={null}
      />
      <div className="flex flex-col ">
        {CommonAppConfig({
          setData: setNewDataConnector,
          data: newDataConnector,
          appType: "dataConnector",
        })}
      </div>

      <div>
        <div className="grid-cols-2 gap-4 px-4 mt-4 lg:grid">
            <Input
              heading={"Host"}
              val={newDataConnector.host}
              onChange={(val: string) => updateData(val, "host")}
              align={"vertical"}
              placeholder={"Eg. thegraph.com"}
              backgroundType="white"
            />
          <div className={`lg:mt-0 mt-4`}>
            {/* <div className="mb-3">
              <Label content="Output" size="large" color="white-500" />
            </div> */}
            {/* <Dropdown
            options={outputDropdown}
            onSelectHandler={(val: string) => updateData(val, "output")}
            placeholder={"Output Data Types"}
            type={"normal"}
            hasIcon={false}
            selected={newDataConnector.output}
            backgroundType="white"
          />
          <div className="flex justify-center mt-2">
            <Label content="OR" size="small" color="white-500" />
          </div> */}
            <Input
              heading={"Output"}
              val={newDataConnector.output}
              onChange={(val: string) => updateData(val, "output")}
              align={"vertical"}
              placeholder={"Output - Eg. OHLC"}
              backgroundType="white"
            />
          </div>
        </div>
          
        <div className={`mt-4 px-4`}>
          <Input
            heading={"IPFS HASH"}
            val={newDataConnector.bundle}
            onChange={(val: string) => updateData(val, "bundle")}
            align={"vertical"}
            disabled={true}
            placeholder={"Upload a file to get a IPFS hash"}
            backgroundType="white"
            optionalText={
              <UploadFile
                setIpfsHash={(hash: string) => updateData(hash, "bundle")}
              />
            }
          />
        </div>
        <div className="px-4 mt-4">
          <Input
              heading={"JSON output"}
              val={newDataConnector.jsonOutput}
              onChange={(val: string) => updateData(val, "jsonOutput")}
              align={"vertical"}
              placeholder={"jsonOutput - Eg. {type: complex} "}
              backgroundType="white"
            />
          </div>
        <div className={`mt-6 px-4`}>
          <Button
            onClickHandler={async () => await addDataConnectorBundle()}
            disabled={
              !newDataConnector.name ||
              !newDataConnector.description ||
              !newDataConnector.host ||
              !newDataConnector.bundle ||
              !newDataConnector.output
            }
            content={"Upload"}
            casing={`uppercase`}
            type={`tertiary`}
            fullWidth={true}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadConnector;

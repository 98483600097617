import { useCalls } from "@usedapp/core";
import { Contract, utils } from "ethers";
import { useEffect, useState } from "react";
import { getContractByNetwork } from "utils";

export const useVaultPositions = (vault) => {
	const [vaultPositions, setVaultPositions] = useState(null);
	const multiPositionLiquidityManager = vault['beaconName'] && vault['beaconName'].toLowerCase().indexOf('multi') > -1 ? 
		getContractByNetwork(137, "MultiPositionLiquidityManager") :
		getContractByNetwork(137, "SinglePositionLiquidityManager") 
	
	const vaultContractInterface = new utils.Interface(multiPositionLiquidityManager?.abi);

	const vaultContract = new Contract(
		vault["id"],
		vaultContractInterface,
	)
	const vaultPositionCalls = useCalls(vault && vault['beaconName'] && vault['beaconName'].toLowerCase().indexOf('multi') > -1 ? [
		{
			contract: vaultContract,
			method: "getPositions",
			args: [],
		}
	] : [
		{
			contract: vaultContract,
			method: "upperTick",
			args: [],
		},
		{
			contract: vaultContract,
			method: "lowerTick",
			args: [],
		}
	])

	useEffect(() => {
		if (vaultPositionCalls && vaultPositionCalls.length > 0
			&& !vaultPositions
		) {
			if (vault && vault['beaconName'] && vault['beaconName'].toLowerCase().indexOf('multi') > -1) {
				const vaultPositionData = vaultPositionCalls[0];

				if (vaultPositionData?.value && vaultPositionData?.value?.length > 0) {
					const position = {
						lowerTicks: vaultPositionData?.value[0],
						upperTicks: vaultPositionData?.value[1],
						weights: vaultPositionData?.value[2],
					}
					setVaultPositions(position)
				}
			}
		}
	}, [vaultPositionCalls, vault, vaultPositions])

	return vaultPositions;
}

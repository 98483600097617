//@ts-nocheck
import { useEffect, useState } from 'react';
import { Select } from "components/lib";
import useTokenPools from "hooks/useTokenPools";
import { formatOptionLabel } from "pages/apps/dashboard";
import { Pool } from 'utils/types';

type PoolSelectProps = {
  pool: Pool,
  handleSelectPool: (data) => void,
  dex: any;
  filterPool?: string
}

const PoolSelect = ({ pool, handleSelectPool, dex, filterPool }: PoolSelectProps) => {
  const [tokenInput, setTokenInput] = useState("");
  const [options, setOptions] = useState(pool? [{value: pool.id, data: pool}]: []);
  const { loading, pools } = useTokenPools(tokenInput, dex);
  const [selectedPool, setSelectedPool] = useState(pool? {value: pool.id, data: pool}: null);
  const [debouncedTerm, setDebouncedTerm] = useState(null);

  useEffect(() => {
    if (debouncedTerm !== null) {
      const timer = setTimeout(() => handleInputChange(debouncedTerm), 1000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTerm]);

  useEffect(() => {
    if (pools && pools.length > 0) {
      
      pools.sort(
        (a, b) => Number(b.totalValueLockedUSD) - Number(a.totalValueLockedUSD)
      );
      let newOptions = pools.map((pool) => ({
        value: pool.id,
        data: pool,
      }));
      if(filterPool) {
        newOptions = newOptions.filter(d => d.value.toLowerCase() !== filterPool.toLowerCase());
      }
      setOptions(newOptions);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pools]);

  const handleInputChange = (inputValue) => {
    setTokenInput(inputValue);
  };

  const onSelectPool = (selectedOption) => {
    handleSelectPool(selectedOption);
    setSelectedPool(selectedOption);
  }

  const filterOption = (option, inputValue) => {
    const pool = option.data.data;

    const label = `${pool.token0.symbol} - ${pool.token1.symbol}`;

    return label.toLowerCase().includes(inputValue.toLowerCase());
  };
  
  return (
      <Select
        options={options}
        
        selected={selectedPool}
        onSelectHandler={onSelectPool}
        handleInputChange={(d) => setDebouncedTerm(d)}
        isLoading={loading}
        formatOptionLabel={formatOptionLabel}
        filterOption={filterOption}
        placeholder="Search Pool By Symbol.."
      />
  );
};

export default PoolSelect;

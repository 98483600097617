"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pair = void 0;
const index_1 = require("../index");
const lib_1 = require("../lib");
class Pair {
    constructor(stable, collateral, maintenanceMargin, totalHedgeAmount, limitHAHedge, targetHAHedge, lockTime, lowerExchangeRate, stocksUsers) {
        this.computeRequireForceClose = () => {
            const currentHedge = this.stocksUsers.mul(this.limitHAHedge);
            const requireForceClose = this.totalHedgeAmount.gt(currentHedge);
            const targetHedgeAmount = this.stocksUsers.mul(this.targetHAHedge);
            return { requireForceClose, targetHedgeAmount };
        };
        this.stable = stable;
        this.collateral = collateral;
        this.maintenanceMargin = lib_1.Int256.from(maintenanceMargin, (0, index_1.CONSTANTS)(stable.chainId).DECIMAL_PARAMS);
        this.totalHedgeAmount = lib_1.Int256.from(totalHedgeAmount, stable.decimals);
        this.limitHAHedge = lib_1.Int256.from(limitHAHedge, (0, index_1.CONSTANTS)(stable.chainId).DECIMAL_PARAMS);
        this.targetHAHedge = lib_1.Int256.from(targetHAHedge, (0, index_1.CONSTANTS)(stable.chainId).DECIMAL_PARAMS);
        this.lockTime = lockTime.toNumber() * 1000;
        this.lowerExchangeRate = lib_1.Int256.from(lowerExchangeRate);
        this.stocksUsers = lib_1.Int256.from(stocksUsers, stable.decimals);
    }
}
exports.Pair = Pair;

import React, { FunctionComponent, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export interface DateTimeProps {
  setDate: (date: Date) => void;
  placeholder?: string;
}

export const DateTime: FunctionComponent<DateTimeProps> = (props) => {
  const [startDate, setStartDate] = useState(new Date());

  const _setDate = (date) => {
    if(props.setDate) {
      props.setDate(date)
    }
    setStartDate(date)
  }

  const isSelectedDateInFuture = +startDate > +new Date();

  const date = new Date();
  let currentMins = date.getMinutes();
  let currentHour = date.getHours();
  if (isSelectedDateInFuture) {
    currentHour = 0;
    currentMins = 0;
  }

  return (
    <div data-testid="DateTime-component">
      <DatePicker
        selected={startDate}
        onChange={(date: Date) => _setDate(date)}
        minDate={new Date()}
        showTimeSelect
        minTime={new Date(new Date().setHours(currentHour, currentMins, 0, 0))}
        maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
        dateFormat="MMMM d, yyyy h:mm aa"
        placeholderText={props.placeholder || "Select date"}

      />
    </div>
  );
};

DateTime.defaultProps = {};

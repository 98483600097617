"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setLoggerOff = exports.setLoggerOn = exports.Logger = void 0;
let isLoggerOn = true;
function Logger(...args) {
    if (isLoggerOn) {
        console.log(...args);
    }
}
exports.Logger = Logger;
const setLoggerOn = () => (isLoggerOn = true);
exports.setLoggerOn = setLoggerOn;
const setLoggerOff = () => (isLoggerOn = false);
exports.setLoggerOff = setLoggerOff;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MerkleRewardManager__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        inputs: [],
        name: "InvalidParam",
        type: "error",
    },
    {
        inputs: [],
        name: "InvalidReward",
        type: "error",
    },
    {
        inputs: [],
        name: "InvalidSignature",
        type: "error",
    },
    {
        inputs: [],
        name: "NotGovernorOrGuardian",
        type: "error",
    },
    {
        inputs: [],
        name: "NotSigned",
        type: "error",
    },
    {
        inputs: [],
        name: "ZeroAddress",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "address",
                name: "previousAdmin",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "newAdmin",
                type: "address",
            },
        ],
        name: "AdminChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "beacon",
                type: "address",
            },
        ],
        name: "BeaconUpgraded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "userFeeRebate",
                type: "uint256",
            },
        ],
        name: "FeeRebateUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "_feeRecipient",
                type: "address",
            },
        ],
        name: "FeeRecipientUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "_fees",
                type: "uint256",
            },
        ],
        name: "FeesSet",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint8",
                name: "version",
                type: "uint8",
            },
        ],
        name: "Initialized",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "_merkleRootDistributor",
                type: "address",
            },
        ],
        name: "MerkleRootDistributorUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "_messageHash",
                type: "bytes32",
            },
        ],
        name: "MessageUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "token",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken2",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "outOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                ],
                indexed: false,
                internalType: "struct RewardParameters",
                name: "reward",
                type: "tuple",
            },
            {
                indexed: true,
                internalType: "address",
                name: "sender",
                type: "address",
            },
        ],
        name: "NewReward",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "toggleStatus",
                type: "uint256",
            },
        ],
        name: "TokenWhitelistToggled",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "implementation",
                type: "address",
            },
        ],
        name: "Upgraded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "messageHash",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "UserSigned",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "toggleStatus",
                type: "uint256",
            },
        ],
        name: "UserSigningWhitelistToggled",
        type: "event",
    },
    {
        inputs: [],
        name: "BASE_9",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "EPOCH_DURATION",
        outputs: [
            {
                internalType: "uint32",
                name: "",
                type: "uint32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "coreBorrow",
        outputs: [
            {
                internalType: "contract ICoreBorrow",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "token",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken2",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "outOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                ],
                internalType: "struct RewardParameters",
                name: "reward",
                type: "tuple",
            },
        ],
        name: "depositReward",
        outputs: [
            {
                internalType: "uint256",
                name: "rewardAmount",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "token",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken2",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "outOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                ],
                internalType: "struct RewardParameters[]",
                name: "rewards",
                type: "tuple[]",
            },
        ],
        name: "depositRewards",
        outputs: [
            {
                internalType: "uint256[]",
                name: "",
                type: "uint256[]",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "feeRebate",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "feeRecipient",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "fees",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "uniV3Pool",
                type: "address",
            },
        ],
        name: "getActivePoolRewards",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "token",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken2",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "outOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                ],
                internalType: "struct RewardParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getActiveRewards",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "token",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken2",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "outOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                ],
                internalType: "struct RewardParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getAllRewards",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "token",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken2",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "outOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                ],
                internalType: "struct RewardParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "uniV3Pool",
                type: "address",
            },
            {
                internalType: "uint32",
                name: "epochStart",
                type: "uint32",
            },
        ],
        name: "getPoolRewardsAfterEpoch",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "token",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken2",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "outOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                ],
                internalType: "struct RewardParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "uniV3Pool",
                type: "address",
            },
            {
                internalType: "uint32",
                name: "epochStart",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "epochEnd",
                type: "uint32",
            },
        ],
        name: "getPoolRewardsBetweenEpochs",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "token",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken2",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "outOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                ],
                internalType: "struct RewardParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "uniV3Pool",
                type: "address",
            },
            {
                internalType: "uint32",
                name: "epoch",
                type: "uint32",
            },
        ],
        name: "getPoolRewardsForEpoch",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "token",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken2",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "outOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                ],
                internalType: "struct RewardParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint32",
                name: "epochStart",
                type: "uint32",
            },
        ],
        name: "getRewardsAfterEpoch",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "token",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken2",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "outOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                ],
                internalType: "struct RewardParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint32",
                name: "epochStart",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "epochEnd",
                type: "uint32",
            },
        ],
        name: "getRewardsBetweenEpochs",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "token",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken2",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "outOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                ],
                internalType: "struct RewardParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint32",
                name: "epoch",
                type: "uint32",
            },
        ],
        name: "getRewardsForEpoch",
        outputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "token",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken2",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "outOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                ],
                internalType: "struct RewardParameters[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract ICoreBorrow",
                name: "_coreBorrow",
                type: "address",
            },
            {
                internalType: "address",
                name: "_merkleRootDistributor",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_fees",
                type: "uint256",
            },
        ],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "isWhitelistedToken",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "merkleRootDistributor",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "message",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "messageHash",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "nonces",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "proxiableUUID",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IERC20[]",
                name: "tokens",
                type: "address[]",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
        ],
        name: "recoverFees",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "rewardList",
        outputs: [
            {
                internalType: "address",
                name: "uniV3Pool",
                type: "address",
            },
            {
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                internalType: "uint32",
                name: "propToken1",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "propToken2",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "propFees",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "epochStart",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "numEpoch",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "outOfRangeIncentivized",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "boostedReward",
                type: "uint32",
            },
            {
                internalType: "address",
                name: "boostingAddress",
                type: "address",
            },
            {
                internalType: "bytes32",
                name: "rewardId",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_feeRecipient",
                type: "address",
            },
        ],
        name: "setFeeRecipient",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_fees",
                type: "uint256",
            },
        ],
        name: "setFees",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "string",
                name: "_message",
                type: "string",
            },
        ],
        name: "setMessage",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_merkleRootDistributor",
                type: "address",
            },
        ],
        name: "setNewMerkleRootDistributor",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "userFeeRebate",
                type: "uint256",
            },
        ],
        name: "setUserFeeRebate",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes",
                name: "signature",
                type: "bytes",
            },
        ],
        name: "sign",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "token",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken2",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "outOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                ],
                internalType: "struct RewardParameters",
                name: "reward",
                type: "tuple",
            },
            {
                internalType: "bytes",
                name: "signature",
                type: "bytes",
            },
        ],
        name: "signAndDepositReward",
        outputs: [
            {
                internalType: "uint256",
                name: "rewardAmount",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
        ],
        name: "toggleSigningWhitelist",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "token",
                type: "address",
            },
        ],
        name: "toggleTokenWhitelist",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newImplementation",
                type: "address",
            },
        ],
        name: "upgradeTo",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newImplementation",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "data",
                type: "bytes",
            },
        ],
        name: "upgradeToAndCall",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "userSignatureWhitelist",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "userSignatures",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
class MerkleRewardManager__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.MerkleRewardManager__factory = MerkleRewardManager__factory;
MerkleRewardManager__factory.abi = _abi;

import React, { useEffect } from "react";
import { Button, Label, Modal, Title } from 'components/lib';
import { Contract } from "@ethersproject/contracts";
import { useCall } from "@usedapp/core";
import { utils } from "ethers";
const ogTokenAddress = "0x7e38f06e9bc51fee72da1f7057a81ec5d96ddaec";
const erc721ABI = [
  {
    "constant": true,
    "inputs": [],
    "name": "totalSupply",
    "outputs": [
      {
        "name": "",
        "type": "uint256"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function",
    "signature": "0x18160ddd"
  },
  {
    "inputs": [],
    "name": "name",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true,
    "signature": "0x06fdde03"
  },
  {
    "inputs": [],
    "name": "symbol",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true,
    "signature": "0x95d89b41"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "balance",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true,
    "signature": "0x70a08231"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "tokenId",
        "type": "uint256"
      }
    ],
    "name": "tokenURI",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function",
    "constant": true,
    "signature": "0xc87b56dd"
  }
];

const TestnetModal = (
  { account, setHasMintedOg }
) => {
  const erc721Interface = new utils.Interface(erc721ABI);
  const tokenBalance = useCall({
    contract: new Contract(
      ogTokenAddress,
      erc721Interface
    ),
    method: 'balanceOf',
    args: [account],
  }, { chainId: 1 });
  const hasMinted = tokenBalance?.value?.balance.toString() === "1";
  const isBalanceUndefined = tokenBalance === undefined;

  useEffect(() => {
    if (hasMinted) {
      setHasMintedOg({
        state: true,
        account
      });
    }
  }, [hasMinted, account, setHasMintedOg]);
  
  if (!hasMinted) {
    return <Modal
      isOpen={true}
      size={"small"}
      content={
        <div className="absolute flex flex-col items-center justify-center mx-4 my-4 top-1/3 lg:top:0 lg:relative">
          <Label
            //@ts-ignore
            content={
              <div className="flex flex-col ">
                <Title 
                content="Welcome Steer Testnet"
                size="lg"
                casing="uppercase"
                />
                <div className="flex items-center mt-6" >
                
                <img src="https://steer.finance/wp-content/uploads/2023/05/testnet.png" alt="testnet" className=" w-28 text-white-500" />
                <span>
                  {`${isBalanceUndefined ? 'Checking for testnet whitelist': 'To Use Steer Testnet, Mint Our OG Token'}`}
                </span>
                </div>
                <div className="mt-8">
                  <Button
                    fullWidth={true}
                    type="tertiary"
                    content={isBalanceUndefined ? "Checking": "Mint"}
                    size="small"
                    disabled={isBalanceUndefined}
                    iconSpin={isBalanceUndefined}
                    icon={isBalanceUndefined ? "faSpinner" : ""}
                    //@ts-ignore
                    iconType={isBalanceUndefined ? "normal" : ""}
                    iconAlignment={"right"}
                    onClickHandler={() => window.open("https://app.syndicate.io/collectives/0x7e38f06e9bc51fee72da1f7057a81ec5d96ddaec?chain=mainnet")}
                  />
                </div>
              </div>
            }
            align={`center`}
            casing={"capitalize"}
            color={`white-500`}
          />
        </div>
      }
    />
  } else {
    return null;
  }
};

export default TestnetModal;

import React, { FunctionComponent } from "react";

export interface AnimatedLoaderProps {
  shape: "circle" | "box";
  height?: string;
  width?: string;
  rounded?: boolean;
}

export const AnimatedLoader: FunctionComponent<AnimatedLoaderProps> = (
  props
) => {
  return (
    <div data-testid="AnimatedLoader-component">
      <div className="flex space-x-4 animate-pulse">
        {props.shape === "circle" && (
          <div
            className="rounded-full bg-slate-200"
            style={{
              width: props.width || '100%',
              height: props.height || '30vh',
            }}
          />
        )}
        {props.shape === "box" && (
          <div
            className={`bg-slate-200 ${
              props.rounded ? "rounded-full" : ""
            }`}
            style={{
              width: props.width || "100%",
              height: props.height || '20px',
            }}
          />
        )}
      </div>
    </div>
  );
};

AnimatedLoader.defaultProps = {
  shape: 'box'
};

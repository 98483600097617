"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeFeeBurn = exports.computeFeeMint = exports.computeInverseBurn = exports.computeBurn = exports.computeInverseMint = exports.computeMint = void 0;
const ethers_1 = require("ethers");
const bignumber_1 = require("../utils/bignumber");
const feeManager_1 = require("./feeManager");
const spread_1 = require("./spread");
function computeMint(chainID, stableSymbol, collateralSymbol, amount, inDecimals, rate, totalHedgeAmount, stocksUsers, collatRatio, targetHAHedge = ethers_1.BigNumber.from(0), xFeeMint = [ethers_1.BigNumber.from(0)], yFeeMint = [ethers_1.BigNumber.from(0)], base = (0, bignumber_1.gwei)(1)) {
    amount = ethers_1.BigNumber.from(amount);
    base = ethers_1.BigNumber.from(base);
    inDecimals = ethers_1.BigNumber.from(inDecimals);
    const collatBase = (0, bignumber_1.multByPow)(1, inDecimals);
    let amountForUserInStable = amount.mul(rate).div(collatBase);
    const { percentageFee, hedgeRatio } = computeFeeMint(chainID, stableSymbol, collateralSymbol, amountForUserInStable, totalHedgeAmount, stocksUsers, collatRatio, targetHAHedge, xFeeMint, yFeeMint, base);
    amountForUserInStable = amountForUserInStable.mul(base.sub(percentageFee)).div(base);
    if (amountForUserInStable.lte(ethers_1.BigNumber.from(0))) {
        throw new Error('Invalid amount');
    }
    const mintingFee = amount.mul(percentageFee).div(base);
    return { amountForUserInStable, mintingFee, percentageFee, hedgeRatio };
}
exports.computeMint = computeMint;
function computeInverseMint(chainID, stableSymbol, collateralSymbol, amount, inDecimals, rate, totalHedgeAmount, stocksUsers, collatRatio, targetHAHedge = ethers_1.BigNumber.from(0), xFeeMint = [ethers_1.BigNumber.from(0)], yFeeMint = [ethers_1.BigNumber.from(0)], base = (0, bignumber_1.gwei)(1), iterations = 10) {
    amount = ethers_1.BigNumber.from(amount);
    base = ethers_1.BigNumber.from(base);
    inDecimals = ethers_1.BigNumber.from(inDecimals);
    const collatBase = (0, bignumber_1.multByPow)(1, inDecimals);
    const amountInC = amount.mul(collatBase).div(rate);
    let amountOfCollateralNeeded = amountInC;
    let percentageFee = 0;
    for (let i = 0; i < iterations; i++) {
        const amountForUserInStable = amountOfCollateralNeeded.mul(rate).div(collatBase);
        const computedOutput = computeFeeMint(chainID, stableSymbol, collateralSymbol, amountForUserInStable, totalHedgeAmount, stocksUsers, collatRatio, targetHAHedge, xFeeMint, yFeeMint, base);
        percentageFee = computedOutput.percentageFee;
        amountOfCollateralNeeded = amount.mul(collatBase).mul(base).div(base.sub(percentageFee)).div(rate);
    }
    const mintingFee = amountOfCollateralNeeded.sub(amountInC);
    return { amountOfCollateralNeeded, mintingFee };
}
exports.computeInverseMint = computeInverseMint;
function computeBurn(chainID, stableSymbol, collateralSymbol, amount, inDecimals, rate, totalHedgeAmount, stocksUsers, collatRatio, targetHAHedge = ethers_1.BigNumber.from(0), xFeeBurn = [ethers_1.BigNumber.from(0)], yFeeBurn = [ethers_1.BigNumber.from(0)], base = (0, bignumber_1.gwei)(1)) {
    amount = ethers_1.BigNumber.from(amount);
    base = ethers_1.BigNumber.from(base);
    rate = ethers_1.BigNumber.from(rate);
    inDecimals = ethers_1.BigNumber.from(inDecimals);
    const collatBase = (0, bignumber_1.multByPow)(1, inDecimals);
    const amountInC = amount.mul(collatBase).div(rate);
    const { percentageFee, hedgeRatio } = computeFeeBurn(chainID, stableSymbol, collateralSymbol, amount, totalHedgeAmount, stocksUsers, collatRatio, targetHAHedge, xFeeBurn, yFeeBurn, base);
    const amountForUserInCollateral = amount.mul(base.sub(percentageFee)).mul(collatBase).div(rate.mul(base));
    const burningFee = amountInC.sub(amountForUserInCollateral);
    return { amountForUserInCollateral, burningFee, percentageFee, hedgeRatio };
}
exports.computeBurn = computeBurn;
function computeInverseBurn(chainID, stableSymbol, collateralSymbol, amount, inDecimals, rate, totalHedgeAmount, stocksUsers, collatRatio, targetHAHedge = ethers_1.BigNumber.from(0), xFeeBurn = [ethers_1.BigNumber.from(0)], yFeeBurn = [ethers_1.BigNumber.from(0)], base = (0, bignumber_1.gwei)(1), iterations = 10) {
    amount = ethers_1.BigNumber.from(amount);
    base = ethers_1.BigNumber.from(base);
    inDecimals = ethers_1.BigNumber.from(inDecimals);
    const collatBase = (0, bignumber_1.multByPow)(1, inDecimals);
    let amountOfStablecoinNeeded = amount.mul(rate).div(collatBase);
    let percentageFee = 0;
    for (let i = 0; i < iterations; i++) {
        const computedOutput = computeFeeBurn(chainID, stableSymbol, collateralSymbol, amountOfStablecoinNeeded, totalHedgeAmount, stocksUsers, collatRatio, targetHAHedge, xFeeBurn, yFeeBurn, base);
        percentageFee = computedOutput.percentageFee;
        amountOfStablecoinNeeded = amount.mul(rate).mul(base).div(base.sub(percentageFee)).div(collatBase);
    }
    const burningFee = amount.mul(percentageFee).div(base);
    return { amountOfStablecoinNeeded, burningFee };
}
exports.computeInverseBurn = computeInverseBurn;
function computeFeeMint(chainID, stableSymbol, collateralSymbol, amount, totalHedgeAmount, stocksUsers, collatRatio, targetHAHedge = ethers_1.BigNumber.from(0), xFeeMint = [ethers_1.BigNumber.from(0)], yFeeMint = [ethers_1.BigNumber.from(0)], base = (0, bignumber_1.gwei)(1)) {
    amount = ethers_1.BigNumber.from(amount);
    stocksUsers = ethers_1.BigNumber.from(stocksUsers);
    const xArrayFeeMint = xFeeMint.map((e) => ethers_1.BigNumber.from(e));
    const yArrayFeeMint = yFeeMint.map((e) => ethers_1.BigNumber.from(e));
    base = ethers_1.BigNumber.from(base);
    const bonusMalusMint = (0, feeManager_1.computeBonusMalusMint)(chainID, stableSymbol, collateralSymbol, collatRatio);
    const hedgeRatio = (0, spread_1.computeHedgeRatio)(totalHedgeAmount, targetHAHedge, amount.add(stocksUsers));
    const feeMint = (0, bignumber_1.piecewiseFunction)(hedgeRatio, xArrayFeeMint, yArrayFeeMint);
    const percentageFee = feeMint.mul(bonusMalusMint).div(base);
    return { percentageFee, hedgeRatio };
}
exports.computeFeeMint = computeFeeMint;
function computeFeeBurn(chainID, stableSymbol, collateralSymbol, amount, totalHedgeAmount, stocksUsers, collatRatio, targetHAHedge = ethers_1.BigNumber.from(0), xFeeBurn = [ethers_1.BigNumber.from(0)], yFeeBurn = [ethers_1.BigNumber.from(0)], base = (0, bignumber_1.gwei)(1)) {
    amount = ethers_1.BigNumber.from(amount);
    stocksUsers = ethers_1.BigNumber.from(stocksUsers);
    const xArrayFeeBurn = xFeeBurn.map((e) => ethers_1.BigNumber.from(e));
    const yArrayFeeBurn = yFeeBurn.map((e) => ethers_1.BigNumber.from(e));
    base = ethers_1.BigNumber.from(base);
    const bonusMalusBurn = (0, feeManager_1.computeBonusMalusBurn)(chainID, stableSymbol, collateralSymbol, collatRatio);
    if (!stocksUsers.gt(amount)) {
        throw new Error(`Not enough stocksUsers`);
    }
    const hedgeRatio = (0, spread_1.computeHedgeRatio)(totalHedgeAmount, targetHAHedge, stocksUsers.sub(amount));
    const feeBurn = (0, bignumber_1.piecewiseFunction)(hedgeRatio, xArrayFeeBurn, yArrayFeeBurn);
    const percentageFee = feeBurn.mul(bonusMalusBurn).div(base);
    return { percentageFee, hedgeRatio };
}
exports.computeFeeBurn = computeFeeBurn;

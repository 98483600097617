//@ts-nocheck
import React, { FunctionComponent, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getIcons } from "utils/icons";

const icons = getIcons();
export interface IconProps {
  name?: string;
  size?:
    | "lg"
    | "xs"
    | "sm"
    | "md"
    | "1x"
    | "2x"
    | "3x"
    | "4x"
    | "5x"
    | "6x"
    | "7x"
    | "8x"
    | "9x"
    | "10x";
  style?: object;
  color?: string;
  className?: string;
  onClickHandler?: () => void;
  spin?: boolean;
  type?: "normal" | "token";
  token?: string;
  tokenSize?: "small" | "medium" | "large" | 'xl';
  width?: number;
  height?: number;
  tokenURL?:string;
}

const renderFontAwesomeIcon = (props) => {
  return (
    <FontAwesomeIcon
      icon={icons[props.name]}
      size={props.size}
      style={props.style}
      spin={props.spin}
      color={props.color}
    />
  );
};

export const Icon: FunctionComponent<IconProps> = (props) => {
  const [token, setToken] = useState(props.type === "token" ? props?.tokenURL?.url || props?.tokenURL || 'error' : null);
  const [isErrorSet, setIsErrorSet] = useState(false);
  const[showQuestionIcon, setShowQuestionIcon] = useState(false);

  const onError = () => {
    if (!isErrorSet) {
      setIsErrorSet(true);
      setToken(props?.tokenURL?.fallback || '');
      setShowQuestionIcon(true);
    }
  }

  return (
    <div
      data-testid="icon-component"
      onClick={props.onClickHandler}
      className={props.onClickHandler ? `cursor-pointer ${props.className}` : `cursor-default ${props.className}`}
    >
      {props.type === "normal" ? (
        renderFontAwesomeIcon(props)
      ) : token && !showQuestionIcon ? (
        <img
          src={token}
          alt={'icon'}
          className="rounded-full"
          style={{
            width: props.width
              ? props.width
              : props.tokenSize === "small"
              ? 20
              : props.tokenSize === "medium"
         
              ? 30
              : props.tokenSize === "large"
              ? 40
              : 45
          }}
          onError={onError}
        />
      ) : (
        <div className="pr-2">
          {renderFontAwesomeIcon({
            ...props,
            name: "faQuestionCircle",
            size: props.tokenSize
              ? props.tokenSize === "small"
                ? "sm"
                : props.tokenSize === "medium"
                ? "base"
                : "2x"
              : "lg",
            style: { color: props.color ? props.color : "#000" },
          })}
        </div>
      )}
    </div>
  );
};

Icon.defaultProps = {
  name: "faHome",
  size: "sm",
  spin: false,
  tokenSize: "small",
  token: "",
  type: "normal",
};

import { getBadgeUrl } from "components/lib/app/AppCard";
import { Link } from "components/lib/Link";
import { getDexDetails } from "utils";


const AmmSection = ({ beacon }) => {
	const ammDetails = getDexDetails(beacon);
	if(ammDetails) {
		const iconURL = getBadgeUrl(beacon);
		return (
			<div
				className="relative flex flex-col bg-white-500 mt-3"
				style={{
					borderRadius: "12px",
				}}
			>
				<div
					className="flex flex-col p-6 "
					style={{
						background: "rgba(93, 95, 239, 0.1)",
					}}
				>
					<div className="flex flex-col items-center">
						<div className="flex items-center">
							<img width="40px" height="40px" alt={ammDetails.name} src={iconURL} />
							<div className="text-xl text-black-500 font-bold mx-2 flex ">
								{ammDetails.name}
							</div>
	
						</div>
						<span className="text-sm text-center text-black-500 my-4">
							{ammDetails.desc}
						</span>
					</div>
				</div>
					<div className="px-8 py-3 flex items-center justify-center">
						<div className="text-lg text-black-500 my-1 flex  flex-col justify-center items-center">
							<span>Socials&nbsp;</span> 
							<div className="flex">
							<Link
								underline={true}
								onClick={() => window.open(ammDetails.website)}
								content={'website'}
								target={"_self"}
							/>&nbsp;or&nbsp;
								<Link
								underline={true}
								onClick={() => window.open(ammDetails.twitter)}
								content={'Twitter'}
								target={"_self"}
							/> {ammDetails.discord && <>&nbsp;or&nbsp;</>}
							{ammDetails.discord && <Link
								underline={true}
								onClick={() => window.open(ammDetails.discord)}
								content={'discord'}
								target={"_self"}
							/>}</div>
							
						</div>
					</div>
			</div>
		);
	} 
	return null;
};

export default AmmSection;
//@ts-nocheck
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useExpanded,
  usePagination,
  useRowSelect,
  useFlexLayout
} from "react-table/dist/react-table.production.min";
import { matchSorter } from "match-sorter";

import { Search } from "./Search";
import { Row } from "./Row";
import { Icon } from "./Icon";

export type ColumnTypeObj = {
  id?: string;
  Header: string; // don't change to header, its required the same way for react-table
  accessor: string | ((data) => void); // key name from api call
  filterable?: boolean;
  sortable?: boolean;
  type?:
    | "badge"
    | "link"
    | "toggle"
    | "button"
    | "icon"
    | "buttonWithIcon"
    | "text"
    | "stats"
    | "custom"
    | "dropdown"
    | "tooltip"
    | "coinText";
  searchable?: boolean;
  casing?: "uppercase" | "lowercase" | "capitalize";
};

export type ActionBtnTypeObj = {
  type?: "primary" | "secondary" | "tertiary";
  text: string;
  onClickHandler: (data) => void;
  casing?: "uppercase" | "lowercase" | "capitalize";
  getText?: () => string;
  disabled?: boolean;
};

export interface TableProps {
  columns: Array<ColumnTypeObj>;
  data: Array<any>;
  showSearchBar?: boolean;
  showPagination?: boolean;
  actionBtns?: Array<ActionBtnTypeObj>;
  rowExpandable?: boolean;
  metaData?: Array<{
    label: string;
    value: string;
    type?: string;
    optionalText?: string;
  }>;
  hasCustomRow?: boolean;
  pageCount?: Number;
  pageSize?: Number;
  customRows: Array<any>;
  fetchRowsData?: (pageIndex: Number, pageSize: Number) => void;
  pageIndex?: Number;
  isRowSelectable?: boolean;
  rowClickable?: boolean;
  selectedRows?: Array<any> | any;
  unSelectedRow?: any;
  rowIdentifierKey?: string;
  height?: string;
  setSelectedRows?: (data) => void;
  dropdownActions?: {
    actions: Array<string>;
    onClickHandler: (data: any) => void; 
  };
  headerClass?: string;
  totalElement?:number;
  backgroundType?: "white" | "base";
}

const IndeterminateCheckbox = React.forwardRef(
  //@ts-ignore
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef
    React.useEffect(() => {
      //@ts-ignore
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
      {
        //@ts-ignore
        <input type="checkbox" ref={resolvedRef} {...rest} />
      }
      </>
    )
  }
)

export const Table: FunctionComponent<TableProps> = (props) => {
  const data = useMemo(() => props.data, [props.data]);
  const columns = props.columns;
  const columnNames = props.columns.map(col => col.Header).filter(d => d !== 'Action');
  const dropdownOptions = props.dropdownActions ? {
    ...props.dropdownActions,
    actions: props.dropdownActions.actions.map(d => ({
      label: d,
      value: d
    }))
  } : null;
  const [colWidth] = useState(100 / (columns.length ));

  const defaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
    return (
      <Search
        text={filterValue || ""}
        onChangeHandler={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        backgroundType="white"
      />
    );
  };

  const fuzzyTextFilterFn = (rows, id, filterValue) => {
    //@ts-ignore
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
  };

  // Let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val) => !val;

  // const filterTypes = useMemo(
  //   () => ({
  //     // Add a new fuzzyTextFilterFn filter type.
  //     fuzzyText: fuzzyTextFilterFn,
  //     // Or, override the default text filter to use
  //     // "startWith"
  //     text: (rows, id, filterValue) => {
  //       return rows.filter((row) => {
  //         const rowValue = row.values[id];
  //         return rowValue !== undefined
  //           ? String(rowValue)
  //               .toLowerCase()
  //               .startsWith(String(filterValue).toLowerCase())
  //           : true;
  //       });
  //     },
  //   }),
  //   []
  // );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: defaultColumnFilter,
    }),
    []
  );

  const onSearchChange = (value) => {
    setGlobalFilter(value || undefined);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    selectedFlatRows,
    setGlobalFilter,
    toggleRowSelected,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      // filterTypes,
      initialState: {
        pageIndex: props.pageIndex ? props.pageIndex : 0,
        pageSize: props.pageSize || 4,
      }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: props.pageCount,
      autoResetGlobalFilter: false,
    },
    useGlobalFilter, // useGlobalFilter!
    useFilters, // useFilters!
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useFlexLayout,
    hooks =>  {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      props.isRowSelectable ? hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: () => (
            <div>
              Select Row
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => {
            return (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            )
          },
        },
        ...columns,
      ]): hooks.visibleColumns
    }
  );

  const { pageIndex, pageSize } = state;
  
  useEffect(() => {
    if(props.unSelectedRow && props.rowIdentifierKey && props.isRowSelectable) {
      const getRow = page.find((r) => r.original[props.rowIdentifierKey] === props.unSelectedRow);
      toggleRowSelected(getRow.id, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.unSelectedRow, props.isRowSelectable])

  useEffect(() => {
    if (props.isRowSelectable && selectedFlatRows.length !== props.selectedRows.length) {
      props.setSelectedRows(selectedFlatRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isRowSelectable, selectedFlatRows, props.selectedRows]);

  useEffect(() => {
    if (props.fetchRowsData) {
      props.fetchRowsData(pageIndex, pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize]);

  return (
    <div data-testid="table-component overflow-y-auto">
      <div className="flex flex-col">
        {props.showSearchBar && (
          <Search text={state.globalFilter} onChangeHandler={onSearchChange} 
            placeholder={`Search by ${columnNames.join(', ')}`} backgroundType="white"/>
        )}
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"
        style={{
          height: props.height || 'inherit'
        }}>
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table {...getTableProps()} className="min-w-full ">
                <thead className={``}>
                  {headerGroups.map((headerGroup, key) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      className={`flex`}
                      key={key}
                    >
                      {headerGroup.headers.map((column, i) => {
                        return (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className={`px-6 py-2 text-left uppercase leading-3 tracking-wider ${props.headerClass}`}
                            style={{
                              width: `${colWidth}%`,
                              fontSize: "10px",
                            }}
                            key={i + column.render("Header")}
                          >
                            <div className="flex">
                              {column.render("Header")}
                              <span className="ml-1">
                              {column.isSorted ?
                               <Icon name={column.isSortedDesc ? `faChevronDown`: `faChevronUp`} size="lg"/>
                              : ''}
                              </span>
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, index) => {
                    prepareRow(row);
                    return props.hasCustomRow && props.customRows.length > 0 ? (
                      props.customRows.find(({ id }) => id === row.original.id)
                        .component
                    ) : (
                      <div>
                        <Row
                        rowClickable={props.rowClickable}
                        setSelectedRows={props.setSelectedRows}
                        metaDataContent={props.metaData}
                        row={row}
                        id={index}
                        rowExpandable={props.rowExpandable}
                        actionBtns={props.actionBtns}
                        colWidth={colWidth}
                        dropdownActions={dropdownOptions}
                        backgroundType={props.backgroundType}
                        selectedRows={props.selectedRows}
                      />
                      </div>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Table.defaultProps = {
  columns: [],
  showSearchBar: true,
  showPagination: true,
  data: [],
  actionBtns: [],
  rowExpandable: true,
  metaData: [],
  headerClass: 'text-gray-600',
};

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.closePerpetual = exports.openPerpetual = void 0;
const ethers_1 = require("ethers");
const constants_1 = require("../constants");
const utils_1 = require("../utils");
function openPerpetual(chainId, collateral, stablecoin, signer, margin, committedAmount, maxOracleRate, minNetMargin, owner = '', options = {}) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const stable = (0, utils_1.parseStable)(stablecoin);
        const collat = (0, utils_1.parseCollat)(collateral);
        const perpetualManagerAddress = (_a = (0, constants_1.registry)(chainId, stable.symbol, collat.symbol)) === null || _a === void 0 ? void 0 : _a.PerpetualManager;
        if (!perpetualManagerAddress)
            throw new Error("Can't find contract's address");
        if (!ethers_1.ethers.utils.isAddress(owner))
            owner = yield signer.getAddress();
        return constants_1.PerpetualManagerFront__factory.connect(perpetualManagerAddress, signer)
            .connect(signer)
            .openPerpetual(owner, margin, committedAmount, maxOracleRate, minNetMargin, options);
    });
}
exports.openPerpetual = openPerpetual;
function closePerpetual(chainId, collateral, stablecoin, signer, perpetualID, minCashOutAmount, to = '', options = {}) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const stable = (0, utils_1.parseStable)(stablecoin);
        const collat = (0, utils_1.parseCollat)(collateral);
        const perpetualManagerAddress = (_a = (0, constants_1.registry)(chainId, stable.symbol, collat.symbol)) === null || _a === void 0 ? void 0 : _a.PerpetualManager;
        if (!perpetualManagerAddress)
            throw new Error("Can't find contract's address");
        if (!ethers_1.ethers.utils.isAddress(to))
            to = yield signer.getAddress();
        return constants_1.PerpetualManagerFront__factory.connect(perpetualManagerAddress, signer).closePerpetual(perpetualID, to, minCashOutAmount, options);
    });
}
exports.closePerpetual = closePerpetual;

import React, { ReactNode, FunctionComponent, useState } from "react";
import RModal from 'react-modal';

export interface ModalProps {
  manualClose?: boolean;
  content?: ReactNode;
  onClose?: () => void;
  onSubmit?: () => void;
  onSubmitDisabled?:boolean;
  size?: 'small' | 'medium' | 'large'
  isOpen: boolean;
}

export const Modal: FunctionComponent<ModalProps> = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const isSmallScreen = window.innerWidth < 500;
  const customStyles = {
    content: {
      borderRadius: isSmallScreen ? '0' : '20px',
      // backgroundColor: '#373E68',
      backgroundColor: '#171736',
      border: 'none',
      width: isSmallScreen ? "100%": '50%',
      left:isSmallScreen ? "0" : '50%',
      top:isSmallScreen ? "0" : '50%',
      maxHeight: "96%",
      maxWidth: isSmallScreen ? "100%" : '50%',
      height: isSmallScreen ? "-webkit-fill-available": 'fit-content',
      overflow: 'auto',
      transform: isSmallScreen ? 'none' : 'translate(-50%, -50%)'
    },
    overlay: {
      backgroundColor: 'rgba(41, 44, 47, 0.5)',
      backdropFilter: 'blur(8px)'
    },
  }
  const onClose = () => {
    if(props.onClose) {
      props.onClose();
    }
    setIsOpen(false);
  }

  return (
    <div data-testid="modal-component">
      <RModal
        isOpen={isOpen}
        onRequestClose={onClose}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
        ariaHideApp={false}
      >
        {props.content}
      </RModal>
    </div>
  );
};

Modal.defaultProps = {
  content: <div><h1> Hello </h1></div>,
  manualClose: false,
  size: 'small',
};

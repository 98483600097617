import { gql, useLazyQuery } from "@apollo/client";
import { Contract } from "@ethersproject/contracts";
import { useCall } from "@usedapp/core";
import { utils } from "ethers";

import Plotly from "plotly.js-basic-dist";
import { useEffect, useState } from "react";
import createPlotlyComponent from "react-plotly.js/factory";
import { getRebalancesData } from "utils";
const Plot = createPlotlyComponent(Plotly);

const GET_UNISWAP_SWAPS = gql`
  query getSwaps(
    $address: String!
    $startDate: BigInt!
    $endDate: BigInt!
    $limit: Int!
    $skip: Int!
  ) {
    swaps(
      first: $limit
      skip: $skip
      where: {
        timestamp_gt: $startDate
        timestamp_lt: $endDate
        pool: $address
      }
      orderBy: timestamp
      orderDirection: desc
    ) {
      timestamp
      tick
    }
  }
`;

const GET_VAULT_REBALANCES = gql`
  query getVaultRebalances($vaultId: String) {
    vaultPositions(
      where: { vault: $vaultId }
      limit: 500
      orderBy: timestamp
      orderDirection: desc
    ) {
      id
      upperTick
      lowerTick
      relativeWeight
      timestamp
    }
  }
`;

const dateForChart = () => {
  const end = new Date();
  // The start date is the end date minus 1 day.
  const start = new Date(end.getTime() - 5 * (24 * 60 * 60 * 1000));
  let startDate = (start.getTime() / 1000).toFixed(0);
  let endDate = (end.getTime() / 1000).toFixed(0);
  return { startDate, endDate };
};

const CandleStickChart = (props: {
  vaultId: string;
  uniswapGraphClient: any;
  graphClient: any;
  pool: string;
  testnet: boolean;
}) => {
  const { vaultId, uniswapGraphClient, graphClient, pool, testnet } = props;
  const [rebalances, setRebalances] = useState([]);
  const [rebalanceBars, setRebalanceBars] = useState([]);
  const [gqlPositionsCall, setGqlPositionsCall] = useState(false);
  const [gqlSwapCall, setGqlSwapCall] = useState(false);
  const currentEpochTime = Math.floor(Date.now() / 1000);
  const oneDayAgoEpochTime = currentEpochTime - 24 * 60 * 60;

  const [getSwaps, poolSwaps] = useLazyQuery(GET_UNISWAP_SWAPS, {
    client: uniswapGraphClient,
  });

  const [getVaultRebalances, vaultRebalances] = useLazyQuery(
    GET_VAULT_REBALANCES,
    {
      client: graphClient,
    }
  );
  const poolContractInterface = new utils.Interface([
    {
      constant: true,
      inputs: [],
      name: "slot0",
      outputs: [
        {
          name: "liquidity",
          type: "uint128",
        },
        {
          name: "tick",
          type: "int24",
        },
        {
          name: "observationIndex",
          type: "uint16",
        },
        {
          name: "observationCardinality",
          type: "uint16",
        },
        {
          name: "observationCardinalityNext",
          type: "uint16",
        },
        {
          name: "feeProtocol",
          type: "uint8",
        },
        {
          name: "unlocked",
          type: "bool",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
  ]);

  const currentTick = useCall(
    pool
      ? {
          contract: new Contract(pool, poolContractInterface),
          method: "slot0",
          args: [],
        }
      : false
  );

  useEffect(() => {
    let notUpdateState = false;
    if (
      !gqlSwapCall &&
      !notUpdateState &&
      pool &&
      !testnet
    ) {
      const { startDate, endDate } = dateForChart();
      getSwaps({
        variables: {
          address: pool.toLowerCase(),
          startDate: startDate,
          endDate: endDate,
          limit: 1000,
          skip: 0,
        },
      });
      // Need to discuss if we want to fetch pagewise data or not uncomment in that case
      setGqlSwapCall(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pool]);
 
  useEffect(() => {
    let notUpdateState = false;
    if (!gqlPositionsCall && !notUpdateState && vaultId && !testnet) {
      getVaultRebalances({
        variables: {
          vaultId: vaultId.toLowerCase(),
        },
      });
      setGqlPositionsCall(true);
    }
    return () => {
      notUpdateState = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vaultId]);

  const getRebalances = (startDate, rebalances, oldStartDate) => {
    const rebalanceINSwapDates = rebalances.filter(
      (d) => parseInt(d.timestamp) >= startDate
    );
    if (rebalanceINSwapDates.length > 0) {
        const rebalanceStartEndDateDiff =  parseInt(rebalanceINSwapDates[rebalanceINSwapDates.length - 1].timestamp) > oldStartDate;
        if(rebalanceStartEndDateDiff && rebalanceINSwapDates.length !== rebalances.length) {
          return getRebalances(startDate - 2 * 24 * 60 * 60, rebalances, oldStartDate);
        } else {
          return rebalanceINSwapDates;
        }
    } else {
      return getRebalances(startDate - 2 * 24 * 60 * 60, rebalances, oldStartDate);
    }
  };

  const getData = (startDate, endDate) => {
    const rebalances = vaultRebalances?.data?.vaultPositions;
    let rebalanceINSwapDates = getRebalances(startDate, rebalances, startDate);
    return rebalanceINSwapDates.map((d, i) => {
      const dateDifference = startDate - parseInt(d.timestamp) ;
      return {
        ...d,
        timestamp:  dateDifference < 0 ?  parseInt(d.timestamp): (parseInt(d.timestamp) + dateDifference) - endDate > 0 ? endDate : parseInt(d.timestamp) + dateDifference,
      };
    });
  };

  useEffect(() => {
    if (
      vaultRebalances?.data?.vaultPositions &&
      rebalances.length === 0 &&
      vaultRebalances?.data?.vaultPositions.length > 0 && 
      poolSwaps.networkStatus === 7 && 
      poolSwaps?.data?.swaps
    ) {
      const swaps = poolSwaps.data.swaps;
      if (swaps.length === 0) {
        setRebalances(
          getRebalancesData(getData(oneDayAgoEpochTime, currentEpochTime), oneDayAgoEpochTime)
        );
      } else {
        const startDate = parseInt(swaps[swaps.length - 1].timestamp);
        const endDate = parseInt(swaps[0].timestamp);
        setRebalances(
          getRebalancesData(getData(startDate, endDate), startDate).sort(
            (a, b) =>
              //@ts-ignore
              new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vaultRebalances, poolSwaps]);

  useEffect(() => {
    if (rebalanceBars.length === 0 && rebalances.length > 0) {

      let rebalanceBarsTemp = [];
      for (const rebalanceId in rebalances) {
        const rebalance = rebalances[rebalanceId];
        const x0 = rebalance?.["timestamp"]?.toISOString();
        const x1 =
          rebalances[Number(rebalanceId) + 1]?.["timestamp"]?.toISOString() ||
          new Date().toISOString();
        for (const position of rebalance["positions"]) {
          rebalanceBarsTemp.push({
            type: "rect",
            x0,
            y0: Math.pow(1.0001, Number(position.upperTick)),
            x1,
            y1: Math.pow(1.0001, Number(position.lowerTick)),
            opacity:
              rebalance["positions"].length > 1
                ? Number(
                    Number(position.weight) /
                      rebalance["positions"].reduce((pv, cv) => {
                        return pv + cv.weight;
                      }, 0)
                  )
                : 0.25,
            fillcolor: "#535ee5",
            layer: "below",
          });
        }
      }
      if(rebalanceBarsTemp.length > 0) {
        setRebalanceBars(rebalanceBarsTemp);
      }
    }
  }, [rebalanceBars, rebalances]);
  const newSwaps = (poolSwaps?.data?.swaps || []).map((swap) => {
    return {
      timestamp: new Date(parseInt(swap.timestamp) * 1000),
      tick: parseInt(swap.tick),
    };
  });  
  const x =
    newSwaps.length > 0
      ? newSwaps.map((data) => data?.["timestamp"]?.toISOString())
      : currentTick && currentTick.value
      ? [
          new Date(oneDayAgoEpochTime * 1000).toISOString(),
          new Date(currentEpochTime * 1000).toISOString(),
        ]
      : [];
  const y =
    newSwaps.length > 0
      ? newSwaps.map((data) => Math.pow(1.0001, data["tick"]))
      : currentTick && currentTick.value
      ? [
          Math.pow(1.0001, currentTick?.value?.["tick"]),
          Math.pow(1.0001, currentTick?.value?.["tick"]),
        ]
      : [];
  return (
    <div key={vaultId}>
      {poolSwaps.networkStatus === 7 && !vaultRebalances.loading && currentTick ? (
        <Plot
          className="w-full h-full"
          data={[
            {
              x,
              y,
            },
          ]}
          config={{
            staticPlot: true,
            height: 450,
            responsive: false,
            frameMargins: 0,
            displayModeBar: false,
            watermark: false,
            displaylogo: false,
          }}
          layout={{
            showlegend: false,
            shapes: rebalanceBars,
            autosize: true,
            xaxis: {
              showgrid: false,
              automargin: true,
            },
            margin: {
              l: 0,
              r: 0,
              b: 25,
              t: 25,
            },
            yaxis: {
              showgrid: false,
              automargin: true,
            },
          }}
          useResizeHandler
        />
      ) : (
        <div className="w-full h-32 animate-pulse bg-san-marino-300 rounded-xl" />
      )}
    </div>
  );
};

export default CandleStickChart;

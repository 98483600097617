import React, {
  useState,
  FunctionComponent,
  ReactNode,
  useEffect,
} from "react";
import { Label } from "./Label";
import Select, { components } from "react-select";
import { Icon } from "./Icon";
import { getBadgeUrl } from "./app/AppCard";
export interface DropdownProps {
  headingSize?: "small" | "medium" | "large";
  heading?: string;
  headingColor?: string;
  headingCase?: "uppercase" | "lowercase" | "capitalize";
  optionalText?: string;
  optionalTextStyle?: string;
  optionalComponent?: ReactNode;
  selected?: string;
  placeholder?: string;
  onSelectHandler: (data) => void;
  hasIcon: boolean;
  options: Array<{
    value: string;
    label: string;
  }>;
  type: "normal" | "filter";
  hideSelection?: boolean;
  tooltipContent?: string;
  placeholderColor?: string;
  disabled?: boolean;
  backgroundType?: "white" | "base";
  hideLabelOnMobileScreen?:boolean
}

export const whiteStyle = {
  border: "border-gray-100",
  text: "text-black-500",
  background: "bg-white-500",
  placeholder: "placeholder-gray-500 focus:placeholder-black-500",
  iconColor: "#52e5a5",
  defaultBorder: "#f4f5f7",
  defaultText: "#242b57",
  defaultBackground: "#ffffff",
  defaultPlaceholder: "#6b7280",
  defaultPlaceholderFocus: "#242b57"
};

// const getSymbol = (label) => {
//   let sym: any = manifest.find(
//     (item) => item.name.toLowerCase() === label.toLowerCase()
//   );
//   return sym?.symbol?.toLowerCase() || "eth";
// };

const CustomOption = ({ props }) => {
  const { data } = props;
  if (props.hasIcon) {
    return (
      <components.Option {...props}>
        <div className="flex gap-2 items-center">
          <div className=""> <Icon tokenURL={getBadgeUrl(data.value)} type={"token"} tokenSize="small" /> </div>
          <div className="">
            {data.label}
          </div>
        </div>
      </components.Option>
    );
  }
  return <components.Option {...props} />;
};

const CustomValueContainer = ({ props }) => {
  if (props.hasIcon && props.hasValue && !props.heading) {
    const label = props.getValue()[0].label;
    const value = props.getValue()[0].value;
    return (
      <components.ValueContainer {...props}>
        <div className="flex gap-2 items-center">
        <div className=""><Icon type={"token"} tokenURL={getBadgeUrl(value)} tokenSize="small" /></div>
          <div className={`${props.hideLabelOnMobileScreen ? 'hidden md:block': 'block' }`}>
            {label}</div>
            {props.children[1]}

        </div>
        
      </components.ValueContainer>
    );
  }
  if (props.hasValue && props.type === "filter") {
    const label = props.getValue()[0].label;
    return (
      <components.ValueContainer {...props}>
        <div className={`flex`}>
          <div className={`font-medium text-sm text-white-600`}>
            {props.heading}
          </div>
          <div className={`ml-2 text-sm text-white-700`}>{label}</div>
          {props.children[1]}
        </div>
      </components.ValueContainer>
    );
  }
  return <components.ValueContainer {...props} />;
};

export const customStyles = (props, hideDropdownIndicator) => {
  const themeWhite = props.backgroundType === "white";
  return {
    option: (provided, state) => ({
      ...provided,
      padding: "6px 12px",
      margin: 0,
      backgroundColor: themeWhite
        ? whiteStyle.defaultBackground
        : "transparent",
      color: themeWhite ? whiteStyle.defaultText : "#f2f2f2",
      "&:hover": {
        cursor: "pointer",
      },
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
        lineHeight: "18px",
      
    }),
    control: (styles, state) => {
      return {
        ...styles,
        border: `2px solid ${
          themeWhite ? whiteStyle.defaultBorder : "#5C738A"
        }`,
        boxShadow: "none",
        borderBottom: state.menuIsOpen
          ? "none"
          : `2px solid ${themeWhite ? whiteStyle.defaultBorder : "#5C738A"}`,
        borderRadius: state.menuIsOpen ? 0 : 4,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        justifyContent: "flex-start",
        fontSize: "14px",
        lineHeight: "18px",
        padding: props.hideSelection ? "0" : "4px",
        color: themeWhite ? whiteStyle.defaultText : "#F2F2F2",
        backgroundColor: themeWhite
          ? whiteStyle.defaultBackground
          : "transparent",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: themeWhite
            ? whiteStyle.defaultBackground
            : "transparent",
        },
      };
    },
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      fontSize: "14px",
      lineHeight: "18px",
      color: themeWhite
        ? whiteStyle.defaultPlaceholder
        : props.placeholderColor || "#52E5A5",
    }),
    valueContainer: (styles) => ({
      ...styles,
      display: "flex",
      padding: props.hideSelection ? "0" : "2px 4px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return {
        ...provided,
        opacity,
        transition,
        color: themeWhite ? whiteStyle.defaultText : "#F2f2f2",
      };
    },
    indicatorSeparator: (styles) => ({ display: "none" }),
    dropdownIndicator: (provided, state) => {
      const color = state.hasValue
        ? themeWhite
          ? whiteStyle.defaultPlaceholder
          : "#F2f2f2"
        : props.placeholderColor || "#52E5A5";
      const transform =
        state.isFocused || state.menuIsOpen ? "rotate(180deg)" : "rotate(0deg)";
      return hideDropdownIndicator ? {display: "none"} : {
        ...provided,
        color,
        "&:hover": {
          color,
        },
        transform,
        padding: "4px",
      };
    },
    menu: (styles) => ({
      ...styles,
      marginTop: 0,
      borderRadius: 0,
      border: `2px solid ${themeWhite ? whiteStyle.defaultBorder : "#5C738A"}`,
      borderTop: "none",
      backgroundColor: themeWhite ? whiteStyle.defaultBackground : "#171736",
      color: themeWhite ? whiteStyle.defaultText : "#F2f2f2",
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      boxShadow: "none",
      display: "table",
    }),
    menuList: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    input: (styles) => ({
      ...styles,
      color: themeWhite ? whiteStyle.defaultText : "#F2f2f2",
    })
  };
};

export const Dropdown: FunctionComponent<DropdownProps> = (props) => {
  const [selected, setSelected] = useState(props.selected);
  useEffect(() => {

    if (props.selected && props.selected !== selected) {
      setSelected(props.selected);
    }
  }, [props.selected, selected]);

  const onSelection = (data: any) => {
    setSelected(data);

    if (props.onSelectHandler) {
      props.onSelectHandler(data);
    }
  };

  const showLabel =
    props.optionalText || props.optionalComponent || props.heading;

  return (
    <div>
      {showLabel && (
        <div className={`flex justify-between mb-2`}>
          {props.heading && props.type === "normal" && (
            <Label
              content={props.heading || ""}
              size={props.headingSize || "large"}
              casing={`${props.headingCase ? props.headingCase : "capitalize"}`}
              color={props.headingColor || "white-500"}
              tooltipContent={props.tooltipContent}
            />
          )}
          <span
            className={`text-${props.headingSize} flex text-${
              props.headingColor || "white-500"
            } ${props.optionalTextStyle} ${props.headingCase || "capitalize"}`}
          >
            <span className={``}>{props.optionalText || ""}</span>
            <span className={`ml-2`}>{props.optionalComponent}</span>
          </span>
        </div>
      )}
      <Select
        //@ts-ignore
        menuPortalTarget={document.body}
        options={props.options}
        closeMenuOnSelect={true}
        components={
          props.hideSelection
            ? {
                SingleValue: () => null,
                Placeholder: () => null,
              }
            : {
                Option: (compProps: any) => (
                  <CustomOption props={{ ...props, ...compProps }} />
                ),
                ValueContainer: (compProps: any) => (
                  <CustomValueContainer
                    props={{
                      ...props,
                      ...compProps,
                      type: props.type,
                      heading: props.heading,
                    }}
                  />
                ),
              }
        }
        isSearchable={false}
        onChange={(e) => onSelection(e.value)}
        value={props.options.filter((item) => item.value === selected)}
        styles={customStyles(props, false)}
        placeholder={props.placeholder || "Select Chain"}
        isDisabled={props.disabled}
      />
    </div>
  );
};

Dropdown.defaultProps = {
  hasIcon: false,
  type: "filter",
};

import React from "react";
import { Title, Icon } from "components/lib";

const TitleWithDocLink = ({
  title,
  link,
  secondaryElement
}: {
  title: string;
  link?: string;
  linkText?: string;
  secondaryElement?:any;
}) => {
  return (
    <div className="flex flex-col items-center justify-center px-4 md:px-0 md:flex-row md:justify-between">
      <div className="flex">
        <Title content={title} size={"lg"} color="white-500" align={"left"} />
        {link && (
          <Icon
            name={"faInfoCircle"}
            size="lg"
            style={{
              color: "#FFf",
              marginLeft: "10px",
            }}
            onClickHandler={() => window.open(link)}
          />
        )}
      </div>
		<div className="mt-4 lg:mt-0">
			{secondaryElement}
		</div>
    </div>
  );
};

export default TitleWithDocLink;

import React, { useState, useRef } from "react";
import { Label, JsonForm } from "components/lib";

import SharedButtons from "../common/sharedButton";

const ConfigVault = ({
  steps,
  setSteps,
  setData,
  data,
}: {
  steps: number;
  setSteps: (step: number) => void;
  setData: (data: any) => void;
  data: any;
}) => {
  let submitFormRef = useRef(null);
  const strategyData = data?.strategyData;
  const [formData, setFormData] = useState(strategyData?.vault?.payload);
  const nextStep = async () => {
    setData({
      ...data,
      strategyData: {
        ...strategyData,
        vault: {
          ...strategyData?.vault,
          payload: formData,
        },
      },
    });
    setSteps(steps + 1);
  };
  return (
    <div>
      {strategyData?.vault?.configJson && (
        <div className="p-0 mx-4 mt-4 ">
          <div className="flex items-center justify-between">
            <Label
              content={"Config Steer Engine For App Type"}
              size={"large"}
              casing={"capitalize"}
              color={"white-500"}
            />

            <a
              className="capitalize text-white-500 hover:underline"
              rel="noreferrer"
              href={"https://docs.steer.finance/vaults"}
              target={"_blank"}
            >
              {"What is a vault?"}
            </a>
          </div>

          {strategyData?.vault?.configJson ? (
            <JsonForm
              schema={strategyData?.vault?.configJson}
              ref={submitFormRef}
              //@ts-ignore
              setFormData={setFormData}
              formData={formData}
              onSubmit={nextStep}
              type={"app"}
            />
          ) : (
            <div className="flex flex-col items-center justify-center">
              <div className="text-3xl">📝</div>
              <div className="my-4">
                <Label
                  content={
                    "No Engine configuration found, proceed to next step."
                  }
                  align={`center`}
                  size={`large`}
                  color={`white-500`}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <SharedButtons
        steps={steps}
        setSteps={setSteps}
        nextStep={() => submitFormRef.current.click()}
      />
    </div>
  );
};

export default ConfigVault;

import { createContext } from "react";

type deprecatedBundleType = {
    deprecatedBundles: Array<object>;
    loadingDeprecatedBundles: boolean;
  }

export const DeprecatedBundlesContext = createContext<deprecatedBundleType>({
  deprecatedBundles: [],
  loadingDeprecatedBundles: true
});

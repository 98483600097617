import React, { FunctionComponent, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export interface DateRangeProps {
  setDate: (date: number | Date) => void;
  placeholder?: string;
  onRangeSelect?: (startDate: Date, endDate: Date) => void;
}

export const DateRange: FunctionComponent<DateRangeProps> = (props) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const _setDateRange = (dates) => {
    if (props.setDate) {
      const [startDate, endDate] = dates;
      if(startDate && endDate) {
        props.setDate((endDate.getTime() - startDate.getTime()) / 1000);
        props.onRangeSelect(startDate, endDate);
      }
    }
    setDateRange(dates);
  };

  return (
    <div data-testid="DateRange-component">
      <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        _setDateRange(update);
      }}
      isClearable={false}
      placeholderText={props.placeholder || "Select date range"}
    />
    </div>
  );
};

DateRange.defaultProps = {};

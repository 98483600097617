import { useEffect, useState } from "react";
import { useIPFSQuery } from "./ipfs";
import { WasmModule } from "@steerprotocol/app-loader";
import { Candle, ExecutionBundleParameters, StrategyConfig } from "utils/types";
import { sha256 } from "js-sha256";
import { useQuery } from "@tanstack/react-query";
import { StrategyExecution } from "pages/apps/publish-pool/common/reviewGraph/simulation/strategyUtils";
import { Rebalances } from "pages/apps/publish-pool/common/reviewGraph/simulation/rebalancePreview";
import { getContractByNetwork } from "utils";
import { useChainId } from "hooks/useChainId";
import { Contract, utils } from "ethers";
import { useCall } from "@usedapp/core";

export const generateHash = (config: ExecutionBundleParameters) => {
  const str = JSON.stringify(config);
  return sha256(str);
};

export const useSteerAppLoader = (hash: string) => {
  const { data: bundle } = useIPFSQuery(hash, "file");
  const [config, setConfig] = useState<StrategyConfig>();
  const [wasm, setWasm] = useState<WasmModule>();

  useEffect(() => {
    const init = async () => {
      try {
        if (bundle) {
          const { loadWasm } = await import("@steerprotocol/app-loader");
          const wasm = await loadWasm(bundle);
          setWasm(wasm);
          setConfig(JSON.parse(wasm.config()));
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [bundle]);

  const response = {
    config,
    wasm,
    bundle,
  };

  return response;
};

export const useStrategyGasParams = (strategyId: string) => {
  const [chainId] = useChainId();

  const [strategyParams, setStrategyParams] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const strategyRegistry = getContractByNetwork(chainId, "StrategyRegistry");
  const strategyRegistryInterface = new utils.Interface(strategyRegistry.abi);
  const strategyRegistryContract = new Contract(
    strategyRegistry.address,
    strategyRegistryInterface
  );

  const strategyRegistryCall = useCall({
    contract: strategyRegistryContract,
    method: "getRegisteredStrategy",
    args: [strategyId],
  });

  useEffect(() => {
    if (strategyRegistryCall?.value?.[0]) {
      setIsLoading(false);
      setStrategyParams(strategyRegistryCall?.value?.[0]);
    }
  }, [strategyRegistryCall]);

  return {
    isLoading,
    strategyParams,
  };
};

export const useStrategyData = (
  candles: Candle[],
  hash: string,
  configPayload: ExecutionBundleParameters,
  strategyInterval?: string,
  currentTick?: string | { tick: any },
  candleWidth?: string | null,
  lookback?: number | null
) => {
  const { wasm, config } = useSteerAppLoader(hash);

  return useQuery({
    // queryKey: ["pool_strategy_rebalances", hash],
    queryKey: [
      "strategy-execution-data",
      hash,
      configPayload,
      candleWidth,
      lookback,
    ],
    queryFn: async () => {
      try {
        const strategyExecution = new StrategyExecution(
          wasm,
          config,
          configPayload
        );

        const rebalancePreview = new Rebalances(
          strategyExecution,
          candles,
          currentTick
        );
        const results = await rebalancePreview.previewRebalances(); // store the result of previewRebalances

        const rebalances = !results
          ? []
          : results.length < 1000
          ? results
          : results;
        return rebalances;
      } catch (error) {
        return [];
      }
    },
    enabled: !!hash && !!configPayload && !!wasm && !!config,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};

import { useState, useEffect } from "react";
import axios from "axios";

export type WeeklyApr = {
    apr: string;
    message: string;
};


  
type IpStackResponse = {
    weeklyApr: WeeklyApr;
    loadingWeeklyApr: boolean;
};

export const useWeeklyApr = (vaultAddress: string, chainId: number): IpStackResponse => {
    const [loadingWeeklyApr, setLoadingWeeklyApr] = useState(true);
    const [weeklyApr, setWeeklyApr] = useState<WeeklyApr | null>(null);
    // const url = `https://ro81h8hq6b.execute-api.us-east-1.amazonaws.com/pool/weekly-apr?address=${vaultAddress}&chain=${chainId}`;
    const url = `https://ro81h8hq6b.execute-api.us-east-1.amazonaws.com/pool/fee-apr?address=${vaultAddress}&chain=${chainId}&interval=604800`
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get<WeeklyApr>(url);
                setWeeklyApr( {apr: response?.data?.apr?.toString() || '0', message: response?.data?.message } );
                setLoadingWeeklyApr(false);
            } catch (err) {
                console.log("Error fetching staking-pools data");
                setLoadingWeeklyApr(false);
            }
        };
        setWeeklyApr(null);
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId, vaultAddress]);

    return { weeklyApr, loadingWeeklyApr };
}

import { useEffect, useState } from "react";
import ModalHeader from "components/ModalHeader";
import { Label, Button } from "components/lib";
import { useEthers, useContractFunction } from "@usedapp/core";
import { Contract } from "@ethersproject/contracts";
import { sendNotification } from "components/Container";
// ABIs
import { getContractByNetwork } from "utils";
import { utils } from "ethers";
import { useChainId } from "hooks/useChainId";

const CreateVault = (props: any) => {
  const { closeModal } = props;
  const { account } = useEthers();

const [chainId] = useChainId();
  const [vaultCreationInProgress, setVaultCreationInProgress] = useState(false);
  const VaultRegistry = getContractByNetwork(chainId, "VaultRegistry")
  const vaultRegistryInterface = new utils.Interface(VaultRegistry.abi);
  const vaultRegistryContract = new Contract(
    VaultRegistry.address,
    vaultRegistryInterface,
  );
  
  const { state, send: createVaultMethod } = useContractFunction(
    vaultRegistryContract,
    "createVault",
    { transactionName: `Creating vault for automation` }
  );

  const createVault = async () => {
    try {
      setVaultCreationInProgress(true);
      // get encoded params for vault
      await createVaultMethod(
        "0x",
        parseInt(props.strategyId, 16).toString(),
        "ScheduledJobs",
        account, // added orchestrator as a vault manager
        ""
      );
    } catch (e) {
      console.log({ e });
      sendNotification({
        type: "error",
        transactionName: `Create vault failed`,
        transaction: {
          ...e,
          msg: `Create vault failed`,
        },
      });
      setVaultCreationInProgress(false);
    }
  };

  useEffect(() => {
    if(state.status === 'Success') {
      setVaultCreationInProgress(false);
      props.setVault(null);
      closeModal();
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  return (
    <div>
      <ModalHeader description={""} title={`Create Vault For Automation`} />
      <div className="m-4">
        <Label
          content={
            "Each new wallet needs to setup a vault for automation once."
          }
          size={"medium"}
          align={"left"}
          color={"white-500"}
        />
      </div>

      <div className={`col-span-1 w-full place-self-end p-4`}>
        <Button
          onClickHandler={createVault}
          content={"Create Vault"}
          casing={`uppercase`}
          type={`tertiary`}
          fullWidth={true}
          disabled={vaultCreationInProgress}
          iconSpin={true}
          icon={vaultCreationInProgress ? "faSpinner" : ""}
          //@ts-ignore
          iconType={vaultCreationInProgress ? "normal" : ""}
          iconAlignment={"right"}
        />
      </div>
    </div>
  );
};

export default CreateVault;

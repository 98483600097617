import { FunctionComponent, useState, useEffect } from "react";
import { AppCard } from "components/lib";
import { getIpfsJSONConfig, getMinifedAddress, getExplorerLink } from "utils";
export interface DataConnectorCardProps {
  info: object;
  key?: number;
}

const DataConnectorCard: FunctionComponent<DataConnectorCardProps> = (props) => {
  const [ipfsConfig, setIpfsConfig] = useState(null);
  const { info } = props;
  const payloadHash = info["infoHash"];

  useEffect(() => {
    (async () => {
      if (!ipfsConfig && payloadHash && payloadHash !== "") {
        const payloadData = await getIpfsJSONConfig(payloadHash);
        setIpfsConfig(payloadData);
      }
    })();
  }, [payloadHash, ipfsConfig]);
  
	const appData = {
    ...info,
    icon:
      (ipfsConfig && ipfsConfig?.metaData?.appImgUrl) ||
      "https://steer.finance/wp-content/uploads/2022/11/blockchain-300x300.png",
    name: info["source"],
    cardType: "dataConnector",
    data: {
      host: {
        type: 'text',
        val: info["host"],
        label: "host"
      },
      creator: {
        type: 'link',
        label: 'Creator',
        val: {
          onClick: () => window.open(
            `${getExplorerLink(info["chainId"])}/address/${
              info["creator"]
            }`
          ),
          text: getMinifedAddress(info["creator"])
        }
      },
      output: {
        type: 'badge',
        val: info["output"],
        label: "Output"
      },
      btn: {
        val: {
          onClick: () => {
            info["onClick"](ipfsConfig);
          },
        },
        label: "View Config"
      },
    }
  };

  //@ts-ignore
  return <AppCard {...appData} />;
};

export default DataConnectorCard;

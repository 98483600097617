import { useQuery } from "@tanstack/react-query"

export const useIPFSQuery = (hash: string, type: 'json' | 'file' | 'text') => {
  const url = 'https://ipfs.io/ipfs/'
  return useQuery(
    ["ipfs", hash],
    async () => {
      const res = await fetch(url + hash, {
        method: "GET"
      })

      if (res.ok) {
        switch (type) {
          case 'json':
            return res.json()
          case 'file':
            const buffer = await res.arrayBuffer();
            return buffer;
          case 'text':
            return res.text()
        }
      }

      throw new Error('Failed to fetch IPFS file');
    },
    {
      enabled: !!url && !!hash && !!type,
    }
  )
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ethers_1 = require("ethers");
const utils_1 = require("ethers/lib/utils");
const bignumber_1 = require("../../utils/bignumber");
const interestRate = ethers_1.BigNumber.from('158153934393112649');
const stablesParameters = {
    EUR: {
        currencySymbol: '€',
        flashloan: {
            maxBorrowable: bignumber_1.parseAmount.ether('1000000'),
            flashLoanFee: bignumber_1.parseAmount.gwei('0'),
        },
        bridges: [
            {
                name: 'Synapse',
                token: '0x16BFc5fe024980124bEf51d1D792dC539d1B5Bf0',
                params: {
                    fees: bignumber_1.parseAmount.gwei('0.002'),
                    limit: (0, utils_1.parseEther)('0'),
                    hourlyLimit: (0, utils_1.parseEther)('50000'),
                },
            },
        ],
        vaultManagers: [
            {
                collateral: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
                symbol: 'wETH-EUR',
                oracle: 'ETH_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('150000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.75'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.05'),
                    borrowFee: bignumber_1.parseAmount.gwei('0'),
                    repayFee: bignumber_1.parseAmount.gwei('0'),
                    interestRate: interestRate,
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.1'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('1.5'),
                },
            },
            {
                collateral: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
                symbol: 'USDC-EUR',
                oracle: 'USDC_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('150000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.88'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.05'),
                    borrowFee: bignumber_1.parseAmount.gwei('0'),
                    repayFee: bignumber_1.parseAmount.gwei('0'),
                    interestRate: interestRate,
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.04'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('1.5'),
                },
            },
            {
                collateral: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
                symbol: 'wBTC-EUR',
                oracle: 'BTC_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('150000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.725'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.1'),
                    borrowFee: bignumber_1.parseAmount.gwei('0'),
                    repayFee: bignumber_1.parseAmount.gwei('0'),
                    interestRate: interestRate,
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.075'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('0.4'),
                },
            },
            {
                collateral: '0x42dC54fb50dB556fA6ffBa765F1141536d4830ea',
                symbol: 'cvx-crvUSDCUSDT',
                oracle: 'crvUSDCUSDT_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('50000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.8'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.1'),
                    borrowFee: bignumber_1.parseAmount.gwei('0'),
                    repayFee: bignumber_1.parseAmount.gwei('0'),
                    interestRate: interestRate,
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.09'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('1.5'),
                },
            },
            {
                collateral: '0xc8711B1206cD3e89799Ec32973f583e696Cb553C',
                symbol: 'sd-crvUSDCUSDT',
                oracle: 'crvUSDCUSDT_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('50000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.8'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.1'),
                    borrowFee: bignumber_1.parseAmount.gwei('0'),
                    repayFee: bignumber_1.parseAmount.gwei('0'),
                    interestRate: interestRate,
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.09'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('1.5'),
                },
            },
        ],
    },
};
exports.default = {
    stablesParameters: stablesParameters,
};

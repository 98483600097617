//@ts-nocheck
import { Contract } from "@ethersproject/contracts";
import { Steps } from "components/lib";
import {
  useContractFunction,
  useEthers
} from "@usedapp/core";
import { BigNumber, utils } from "ethers";
import { useEffect, useState } from "react";
import { getContractByNetwork } from 'utils';
import ERC20 from 'utils/Erc20.json';

import {
  Button,
} from "components/lib";

const Approval = ({
  vault,
  token0Input,
  token1Input,
  handleBack,
  deposit,
  token0Decimals,
  token1Decimals,
  depositState,
  closeModal,
  allowanceNeeded,
  chainId,
  token0Allowance,
  token1Allowance,
  account
}: {
  vault: object;
  token0Input: string;
  token1Input: string;
  handleBack: () => void;
  deposit: () => void;
  token0Decimals: BigNumber;
  token1Decimals: BigNumber;
  depositState: Object;
  closeModal: () => void;
  token0Allowance: BigNumber;
  token1Allowance: BigNumber;
  chainId: number;
  account: string;
}) => {
  const {
    library,
  } = useEthers()
  const [token0Approved, setToken0Approved] = useState(token0Allowance && token0Allowance.gte(utils.parseUnits(token0Input, token0Decimals)));
  const [token1Approved, setToken1Approved] = useState(token1Allowance && token1Allowance.gte(utils.parseUnits(token1Input, token1Decimals)));
  const [approavalList, setApprovalList] = useState([
    { name: `Approve ${vault["token0Symbol"]}`, id: "token0", status: token0Approved ? "complete": "current"},
    { name: `Approve ${vault["token1Symbol"]}`, id: "token1", status: token1Approved && token0Approved ? "complete": token0Approved ? "current": "incomplete" },
    { name: "Deposit", id: "deposit", status: token0Approved && token1Approved ? "current": "incomplete" },
  ]);

  useEffect(() => {
    // check if current token0Approved is other then token1Approved then change it
    if(token0Approved !== (token0Allowance && token0Allowance.gte(utils.parseUnits(token0Input, token0Decimals)))) {
      setToken0Approved(token0Allowance && token0Allowance.gte(utils.parseUnits(token0Input, token0Decimals)));
      setApprovalList(approavalList.map((step) => {
        if (step.id === "token0") {
          step.status = "complete";
        }
        return step;
      }));
      if (token1Approved) {
        setApprovalList(approavalList.map((step) => {
          if (step.id === "token1") {
            step.status = "complete";
          }
          return step;
        }));
        changeStepStatus(`Approve ${vault["token1Symbol"]}`, "Deposit");
      } else {
        changeStepStatus(`Approve ${vault["token0Symbol"]}`, `Approve ${vault["token1Symbol"]}`);
        setApprovalList(approavalList.map((step) => {
          if (step.id === "token1") {
            step.status = "current";
          }
          return step;
        }));
      }
      setShowCheckMessage(false);
      setInProgress(false);
    }
    // check if current token1Approved is other then token1Approved then change it
    if(token1Approved !== (token1Allowance && token1Allowance.gte(utils.parseUnits(token1Input, token1Decimals)))) {
      setToken1Approved(token1Allowance && token1Allowance.gte(utils.parseUnits(token1Input, token1Decimals)));
      changeStepStatus(`Approve ${vault["token1Symbol"]}`, "Deposit");
      setApprovalList(approavalList.map((step) => {
        if (step.id === "token1") {
          step.status = "complete";
        }
        return step;
      }));
      setShowCheckMessage(false);
      setInProgress(false);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approavalList, token0Allowance, token0Approved, token0Decimals, token0Input, token1Allowance, token1Approved, token1Decimals, token1Input, vault])
  
  const [showCheckMessage, setShowCheckMessage] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [inProgress, setInProgress] = useState(false);

  const ERC20Contract = ERC20;
  const SteerPeriphery = getContractByNetwork(chainId, "SteerPeriphery");
  const erc20Interface = new utils.Interface(ERC20Contract.abi);
  const steerPeripheryAddress = SteerPeriphery.address;
  const token0Contract = new Contract(vault["vaultToken0"], erc20Interface);
  const token1Contract = new Contract(vault["vaultToken1"], erc20Interface);

  const { state: token0State, send: approveToken0 } = useContractFunction(
    token0Contract,
    "approve",
    { transactionName: `Approval of ${token0Input} ${vault["token0Symbol"]} for asset ${vault['asset']} in strategy ${vault['name']}` }
  );

  const { state: token1State, send: approveToken1 } = useContractFunction(
    token1Contract,
    "approve",
    { transactionName: `Approval of ${token1Input} for ${vault["token1Symbol"]} for asset ${vault['asset']} in strategy ${vault['name']}` }
  );
  const approveToken = async (tokenNumber: 0 | 1) => {
    try {
      setInProgress(true);
      if (tokenNumber === 0) {
        await approveToken0(
          steerPeripheryAddress,
          utils.parseUnits(token0Input, token0Decimals)
        );
      }
      if (tokenNumber === 1) {
        await approveToken1(
          steerPeripheryAddress,
          utils.parseUnits(token1Input, token1Decimals)
        );
      }
    } catch (error) {
      setInProgress(false);
      console.log(error);
    }
  };
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeStepStatus = (currentStep, nextStep) => {
    const newSteps = approavalList.map((step) => {
      if (step.name === currentStep) {
        step.status = "complete";
      } else if (step.name === nextStep && step.status !== "complete") {
        step.status = "current";
      }
      return step;
    });

    setApprovalList(newSteps);
  };
    
  useEffect(() => {

    if (!token0Approved) {
      const handleToken0Approval = async () => {
        if (
          token0State &&
          (token0State["status"] === "Exception" || !token0State["status"])
        ) {
          setShowCheckMessage(false);
          setInProgress(false);
        } else if (token0State && token0State["status"] === "Success" && token0State?.receipt?.transactionHash) {
          try {
            const contract = new Contract(vault['token0'], erc20Interface, library)
            const allowance = await contract.allowance(account, steerPeripheryAddress);
            if(allowance.lt(utils.parseUnits(token0Input, token0Decimals)) && !showCheckMessage) {
              setInProgress(false);
              setErrorMsg(`Approval Amount for ${vault["token0Symbol"]} is less than the input amount`);
              setShowCheckMessage(true);
            }
          } catch(error) {
            console.log('Error token0 allowance : ', error);
          }
        
        }
      }
      handleToken0Approval();
    }

    if (!token1Approved) {
      const handleToken1Approval = async () => {
        if (
          token1State &&
          (token1State["status"] === "Exception" || !token1State["status"])
        ) {
          setInProgress(false);
          setShowCheckMessage(false);
        } else if (token1State && token1State["status"] === "Success" && token1State?.receipt?.transactionHash) {
          try {
            const contract = new Contract(vault['token1'], erc20Interface, library);
            const allowance = await contract.allowance(account, steerPeripheryAddress);
    
            if(allowance.lt(utils.parseUnits(token1Input, token1Decimals)) && !showCheckMessage) {
              setInProgress(false);
              setShowCheckMessage(true);
              setErrorMsg(`Approval Amount for ${vault["token1Symbol"]} is less than the input amount`);
            }
          } catch(error) {
            console.log('Allowance call error token1')
          }
        }
      }
      handleToken1Approval();
    }

    if (
      depositState &&
      (depositState["status"] === "Exception" || !depositState["status"])
    ) {
      setInProgress(false);
    } else if (depositState && depositState["status"] === "Mining") {
      closeModal();
      setInProgress(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token1State, token0State, depositState, token0Approved, token1Approved, token0Allowance, token1Allowance]);

  const getButtonLabel = () => {
    return !token0Approved
      ? `Approve (${vault["token0Symbol"]})`
      : !token1Approved
      ? `Approve (${vault["token1Symbol"]})`
      : "Deposit";
  };

  const handleApprove = () => {
    if (!token0Approved) {
      approveToken(0);
    } else if (!token1Approved) {
      approveToken(1);
    } else {
      setInProgress(true);
      deposit();
    }
  };

  return (
    <div className="relative grid w-full grid-rows-2 gap-2">
      <div className="mt-10 bottom-15">
        <Steps type="horizontal" content={approavalList} />
      </div>
      
      <div className="grid w-full grid-cols-2 mt-4 b-0">
        <div className="self-end place-self-start">
          <Button
            onClickHandler={() => handleBack()}
            content={"Back"}
            casing={`uppercase`}
            type={`tertiary`}
            disabled={inProgress}
            size="small"
            
          />
        </div>
        
        <div className="self-end place-self-end">
          <Button
            onClickHandler={() => handleApprove()}
            content={getButtonLabel()}
            casing={`uppercase`}
            type={`tertiary`}
            disabled={inProgress}
            iconSpin={true}
            icon={inProgress ? "faSpinner" : ""}
            iconType={inProgress ? "normal" : ""}
            iconAlignment={"right"}
            size="small"
          />
        </div>
      </div>
      {showCheckMessage && <div className="flex justify-center font-semibold text-red-400 capitalize">
          <p>{errorMsg}</p>
        </div>}
    </div>
  );
};

export default Approval;

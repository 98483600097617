import React, { FunctionComponent, useState, useEffect, ReactNode } from "react";
import { Label } from "./Label";
import { whiteStyle } from "./Dropdown";
export interface InputProps {
  headingSize?: string;
  heading?: string;
  headingColor?: string;
  headingCase?: "uppercase" | "lowercase" | "capitalize";
  placeholder?: string;
  optionalText?: string | ReactNode;
  optionalTextStyle?: string;
  maxButton? : ReactNode;
  type?: "email" | "password" | "text" | "number" | "search" | "date";
  disabled?: boolean;
  haserror?: boolean;
  align?: "horizontal" | "vertical";
  onChange: (data) => void;
  val: string;
  onBlur?: any;
  required?: boolean;
  tooltipContent?: string;
  backgroundType?: "white" | "base";
  
}

export const Input: FunctionComponent<InputProps> = (props) => {
  const [value, setValue] = useState("");
  const [isActive, setIsActive] = useState(false);
  const themeWhite = props.backgroundType === "white";

  useEffect(() => {
    if(props.val) {
      setValue(props.val);
    }
  }, [props.val])


  const onChangeHandler = (e) => {
    setValue(e.target.value);
    props.onChange(e.target.value);
  };

  return (
    <div data-testid="input-component" className={`
      ${props.align === 'vertical' ? 'block' : 'flex items-center'}
    `}>
      <div className={`flex lg:flex-row flex-col justify-between`}>
        {props.heading && <Label
          content={props.heading || ""}
          size={"medium"}
          casing={props.headingCase || 'capitalize'}
          color={props.headingColor || 'white-500'}
          tooltipContent={props.tooltipContent}
        />}
        {(props.optionalText || props.maxButton) &&<span
          className={`mt-4 lg:mt-0 text-${props.headingSize} flex text-${props.headingColor || 'white-500'} ${props.optionalTextStyle} ${props.headingCase || 'capitalize'}`}
        >
          <span className = {``} >{props.optionalText || ""}</span>
          <span className  = {`ml-2`}>{props.maxButton}</span> 
        </span>}
      </div>
      <div className={`mt-2 grid ${props.align === "horizontal" ? "ml-10" : ""}`}>
        <input
          {...props}
          type={props.type || 'text'}
          disabled={props.disabled}
          onFocus={() => setIsActive(true)}
          required={!!props.required}
          onBlur={(e) => {
            setIsActive(false);
            if(props.onBlur) {
              props.onBlur(e);
            }
          }}
          className={`border-2 box-border rounded-md px-5 text-small leading-6 overscroll-none
            ${
              !props.disabled && !props.haserror
                ? ` ${themeWhite? whiteStyle.text : "text-shamrock-500"} ${
                    isActive
                      ? `border-shamrock-500 focus:outline-none focus:border-shamrock-500`
                      : `focus:outline-none ${themeWhite? `${whiteStyle.border} foucs:${whiteStyle.border}` : "border-lynch-500  focus:border-lynch-500"}`
                  }`
                : props.disabled
                ? "text-lynch-500 border-lynch-500 focus:outline-none focus:border-lynch-500"
                : `${themeWhite? whiteStyle.text : "text-radical-red-500"} border-radical-red-500 focus:outline-none focus:border-radical-red-500`
            }
          `}
          style={{
            padding: "6px",
            background:
            themeWhite? whiteStyle.defaultBackground : isActive ? "rgba(92, 115, 138, 0.3)": props.haserror ? "rgba(255, 67, 101, 0.1)" :  "rgba(92, 115, 138, 0.3)",
          }}
          placeholder={props.placeholder || ""}
          onChange={onChangeHandler}
          value={value}
        />
      </div>
    </div>
  );
};

Input.defaultProps = {
  align: "vertical",
};

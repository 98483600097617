import React, { FunctionComponent, useEffect, useState } from "react";
import { Label } from "./Label";
import { whiteStyle } from "./Dropdown";
export interface TextareaProps {
  headingSize?: string;
  heading?: string;
  headingColor?: string;
  headingCase?: "uppercase" | "lowercase" | "capitalize";
  placeholder?: string;
  optionalText?: any;
  disabled?: boolean;
  hasError?: boolean;
  align?: "horizontal" | "vertical";
  onChange: (data) => void;
  val: string;
  validations?: any
  rows?: number;
  tooltipContent?:string;
  backgroundType?: 'white'| 'base';
}

export const Textarea: FunctionComponent<TextareaProps> = (props) => {
  const [value, setValue] = useState("");
  const [isActive, setIsActive] = useState(false);
  const themeWhite = props.backgroundType === "white";

  useEffect(() => {
    if(props.val || props.val === "") {
      setValue(props.val);
    }
  }, [props.val])

  const onChangeHandler = (e) => {
    setValue(e.target.value);
    props.onChange(e.target.value);
  };
  return (
    <div data-testid="Textarea-component" className={`
      ${props.align === 'vertical' ? 'block' : 'flex items-center'}
    `}>
      <div className={`flex justify-between`}>
        <Label
          content={props.heading || ""}
          size={"large"}
          casing={props.headingCase}
          color={props.headingColor || 'white-500'}
          tooltipContent={props.tooltipContent}
        />
        <span
          className={`text-${props.headingSize} text-${props.headingColor} ${props.headingCase}`}
        >
          {props.optionalText || ""}
        </span>
      </div>
      <div className={`mt-2.5 grid ${props.align === "horizontal" ? "ml-10" : ""}`}>
        <textarea
          disabled={props.disabled}
          onFocus={() => setIsActive(true)}
          onBlur={(e) => setIsActive(false)}
          className={`border-2 box-border rounded-xl	py-3.5 px-5 text-base leading-6
            ${
              !props.disabled && !props.hasError
              ? ` ${themeWhite? whiteStyle.text : "text-shamrock-500"} ${
                  isActive
                    ? `border-shamrock-500 focus:outline-none focus:border-shamrock-500`
                    : `focus:outline-none ${themeWhite? `${whiteStyle.border} foucs:${whiteStyle.border}` : "border-lynch-500  focus:border-lynch-500"}`
                }`
              : props.disabled
              ? "text-lynch-500 border-lynch-500 focus:outline-none focus:border-lynch-500"
              : `${themeWhite? whiteStyle.text : "text-radical-red-500"} border-radical-red-500 focus:outline-none focus:border-radical-red-500`
            }
          `}
          style={{
            background:
            themeWhite ?  !props.disabled ?  whiteStyle.defaultBackground : "#bab6b6"  : isActive  ? "rgba(92, 115, 138, 0.3)": props.hasError ? "rgba(255, 67, 101, 0.1)" :  "rgba(92, 115, 138, 0.3)",
          }}
          placeholder={props.placeholder || ""}
          onChange={onChangeHandler}
          value={value}
          {...props.validations}
          rows={props.rows || 4}
          cols={4}
        />
      </div>
    </div>
  );
};

Textarea.defaultProps = {
  heading: "Textarea Label",
  headingColor: "black-800",
  headingCase: "capitalize",
  disabled: false,
  hasError: false,
  align: "vertical",
};

import { Button, LabelData } from "components/lib";
import { getTokenURL } from "utils";
import { DataConnectorSchemaConfig, DataConnectorUpdateConfig, ExecutionBundleParameters, Pool, Strategy } from "utils/types";
import StrategyConfigForm from "./config/strategyConfig";
import LiquidityGraph from "./liquidityGraph";

type StrategyScreenProps = {
  selectedStrategy: Strategy;
  selectedPool: Pool;
  handleDataConnectorConfig: (cohandleDataConnectorConfignfigs: DataConnectorUpdateConfig[]) => void;
  handleExecutionBundleConfig: (any) => void;
  dataConnectorConfigs: DataConnectorUpdateConfig [] ;
  executionConfigs: ExecutionBundleParameters | null;
  chainId: number;
  moveNext: () => void;
  handleBack: () => void;
  resetDefaults: () => void;
  setIsModalOpen: any;
  mode:string;
  schemaConfigs: DataConnectorSchemaConfig[],
};

const StrategyScreen = ({
  selectedStrategy,
  selectedPool,
  handleDataConnectorConfig,
  handleExecutionBundleConfig,
  dataConnectorConfigs,
  executionConfigs,
  chainId,
  handleBack,
  resetDefaults,
  setIsModalOpen,
  mode,
  schemaConfigs
}: StrategyScreenProps) => {
  return (
    <div className="">
      <div className="lg:grid grid-cols-3 gap-4">
        <div className="md:col-span-2">
          <div className="text-lg text-white-500 font-bold ">Pool Details</div>
          <div className="my-2 grid grid-cols-4">
            <LabelData
              dataType="asset"
              label={`${selectedPool.token0.symbol}-${selectedPool.token1.symbol}`}
              data={[getTokenURL(selectedPool.token0.id, selectedPool.token0.symbol, chainId, selectedPool.dex), getTokenURL(selectedPool.token1.id, selectedPool.token1.symbol, chainId, selectedPool.dex)]}
              labelStyle={`text-white-500`}
            />

            <LabelData
              dataType="text"
              label="Fee Tier"
              data={`${Number(selectedPool.feeTier) / 10000} %`}
              labelStyle={`text-white-500`}
              dataStyle={`text-white-500`}
            />

            <LabelData
              dataType="multilineText"
              label="TVL"
              data={[
                `${parseFloat(selectedPool.totalValueLockedUSD)} USD`,
                " ",
              ]}
              labelStyle={`text-white-500`}
              dataStyle={`text-white-500`}
            />
            <LabelData
              dataType="multilineText"
              label="24Hr Volume"
              data={[`${parseFloat(selectedPool.volumeUSD)} USD`, " "]}
              labelStyle={`text-white-500`}
              dataStyle={`text-white-500`}
            />
          </div>
        </div>
        <div className="lg:col-span-1">
          <div className="mt-4 lg:mt-10 flex justify-between">
          <Button
            content="back"
            type="secondary"
            size="small"
            onClickHandler={handleBack}
            casing={'capitalize'}

          />
            <Button
              content="Deploy Pool & Deposit"
              size="small"
              type="nprimary"
              casing={'capitalize'}
              onClickHandler={() => setIsModalOpen(true)}
            />
          </div>
        </div>
      </div>
      <div className=" lg:grid grid-cols-3 gap-4">
        <div className="md:col-span-2">
          <LiquidityGraph
            dataConnectorConfigs={dataConnectorConfigs}
            executionConfigs={executionConfigs}
            selectedPool={selectedPool}
            selectedStrategy={selectedStrategy}
            chainId={chainId}
          />
        </div>
        <div className="md:col-span-1">
        <div className="text-lg text-white-500 font-bold mb-2">
            {`${selectedStrategy.name} `}
          </div>
          <div className="">
            <StrategyConfigForm
              onConfigSubmit={handleExecutionBundleConfig}
              selectedStrategy={selectedStrategy}
              selectedPool={selectedPool}
              executionConfigs={executionConfigs}
              handleBack={handleBack}
              resetDefaults={resetDefaults}
              setIsModalOpen={setIsModalOpen}
              mode={mode}
              schemaConfigs={schemaConfigs}
              dataConnectorConfigs={dataConnectorConfigs}
              onDataConnectorConfigChange={handleDataConnectorConfig}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrategyScreen;

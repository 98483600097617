import React, { useState } from "react";
import { Input, Dropdown, DateTime, Label } from "components/lib";
import SharedButtons from "pages/apps/publish/common/sharedButton";
import {
  frequencyOptions,
  getContractByNetwork,
  getGasTokenSymbol,
  uploadFileAndGetHash,
} from "utils";
import AbiCoder from "web3-eth-abi";
import { useEthers, useEtherBalance, useContractFunction } from "@usedapp/core";
import { utils } from "ethers";
import { Contract } from "@ethersproject/contracts";
import { sendNotification } from "components/Container";
import CommonAppConfig from "components/CommonAppConfig";
import { useChainId } from "hooks/useChainId";

const JobDetails = ({
  steps,
  setSteps,
  setData,
  data,
  vault,
}: {
  steps: number;
  setSteps: (step: number) => void;
  setData: (data: any) => void;
  data: any;
  vault: any;
}) => {
  const { account } = useEthers();

const [chainId] = useChainId();
  const [jobData, setJobData] = useState({
    name: null,
    description: null,
    appImgUrl: null,
    epochStart: null,
    epochLength: null,
  });

  const [gasAmount, setGasAmount] = useState(null);
  const etherBalance = useEtherBalance(account, { chainId });
  const ethBalance = utils.formatEther(etherBalance || "0");
  const gasToken = getGasTokenSymbol(chainId);
  const [executing, setExecuting] = useState(false);
  const DynamicJob = getContractByNetwork(chainId, "DynamicJobs");
  const dynamicJobInterface = new utils.Interface(DynamicJob.abi);
  const dynamicJobContract = new Contract(
    vault["id"],
    dynamicJobInterface,
  );

  const { send: registerJobAndDepositGas } = useContractFunction(
    dynamicJobContract,
    "registerJobAndDepositGas",
    { transactionName: `Register of ${jobData.name} job` }
  );

  const formJsonForAllPayload = async () => {
    let jsonFile = {};
    jsonFile["metaData"] = {
      ...data,
      ...jobData,
      gasAmount,
      function: data.selectedOption,
    };

    const blobFile = new Blob([JSON.stringify(jsonFile)], {
      type: "application/json",
    });
    try {
      const hash = await uploadFileAndGetHash(blobFile);
      return hash;
    } catch (e) {
      console.log({ e });
      return "Issue uploading the hash";
    }
  };

  const getFunctionSignature = () => {
    return data.selectedOption.inputs.reduce((acc, i) => {
      if (acc.length === 0) {
        acc = i.type;
      } else {
        acc = acc + "," + i.type;
      }
      return acc;
    }, "");
  };

  const getCallData = () => {
    const params = getFunctionSignature();
    const methodSignature = `${data.selectedOption.name}(${params})`;
    const _method = AbiCoder.encodeFunctionSignature(methodSignature);
    let _params = "";
    if(data.callData) {
      _params = AbiCoder.encodeParameters(
        params.split(","),
        Object.values(data.callData)
      );
    }
    return _method + _params.slice(2, _params.length);
  };

  
  const nextStep = async () => {
    try {
      setExecuting(true);
      const ipfsHash = await formJsonForAllPayload();
      const callData = getCallData();

      await registerJobAndDepositGas(
        [callData],
        [data.contractAddress],
        jobData.name,
        ipfsHash, {
          value: utils.parseEther(gasAmount),
        }
      )
      setData(null);
      setSteps(0);
      setExecuting(false);
    } catch (e) {
      console.log({ e });
      setExecuting(false);
      sendNotification({
        type: "error",
        transactionName: `Job ${jobData.name} failed to register for function ${data.selectedOption.name}`,
        transaction: {
          ...e,
          msg: `Job ${jobData.name} failed to register for function ${data.selectedOption.name}`,
        },
      });
    }
  };
  return (
    <div>
      {CommonAppConfig({
        setData: setJobData,
        data: jobData,
        appType: "jobs"
      })}
      <div className={`mt-4 px-4 lg:grid grid-cols-2 gap-4 `}>
        <div>
          <Label
            content={"Job Start Time"}
            size={"large"}
            casing={"capitalize"}
            color={"white-500"}
          />
          <div className="mt-4">
            <DateTime
              setDate={(val: Date) => setJobData({...jobData, epochStart: val.getTime() / 1000})}
            />
          </div>
        </div>
        <div className="mt-4 lg:px-0 lg:mt-0">
          <Label
            content={"Job Interval"}
            size={"large"}
            casing={"capitalize"}
            color={"white-500"}
          />
          <div className="mt-4">
            <Dropdown
              options={frequencyOptions}
              onSelectHandler={(val) => setJobData({...jobData, epochLength: val})}
              placeholder={"Select  Interval"}
              type={"normal"}
              hasIcon={false}
              selected={jobData.epochLength}
              backgroundType="white"
            />
          </div>
        </div>
      </div>
      <div className={`mt-4 px-4`}>
        <Input
          heading={`Fund Gas ${gasToken} For Task Execution`}
          val={gasAmount}
          onChange={setGasAmount}
          align={"vertical"}
          backgroundType="white"
          placeholder={
            ethBalance === "0.0"
              ? `${gasToken} Balance is low, please fund the account.`
              : ""
          }
          haserror={ethBalance === "0.0"}
        />
      </div>
      <SharedButtons
        steps={steps}
        setSteps={setSteps}
        nextStep={nextStep}
        callableType={"job"}
        showOnlyNext={false}
        isDisabled={
          !jobData.name ||
          !jobData.epochLength ||
          !jobData.epochStart ||
          !gasAmount ||
          (gasAmount &&
            !!parseFloat(gasAmount) &&
            utils.parseEther(gasAmount).isZero()) ||
          ethBalance === "0.0" ||
          executing
        }
        inProgress={executing}
      />
    </div>
  );
};

export default JobDetails;

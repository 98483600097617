import { useQuery } from "@tanstack/react-query";
import axios from "axios";

function getApiUrl(chainId, account) {
    return `https://api.angle.money/v1/merkl?chainId=${chainId}&user=${account}`;
}


async function fetchMerklUserRewards(chainId: number, account: string) {
    const url = getApiUrl(chainId, account);
    const response = await axios.get(url);
    return response.data;
}

export const useMerklUserRewards = (chainId: number, account: string) => {
    const { data, isLoading, isError } = useQuery(
        ['merklUserRewards', chainId, account],
        () => fetchMerklUserRewards(chainId, account),
        {
            refetchOnWindowFocus: false, // This is to prevent refetch on window focus. You can remove it if you want the default behavior.
        }
    );

    if (isError) {
        console.log("Error fetching merkel-user-rewards data");
    }
    return { merklUserRewards: data, loadingMerklUserRewards: isLoading };
}

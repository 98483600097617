import { useQuery } from "@tanstack/react-query";
import { GasConfig } from "utils/types";

const GAS_CONFIG_URL = "https://9i52h964s3.execute-api.us-east-1.amazonaws.com/dev/execution-gas";

export interface GasConfigResponse {
  data: GasConfig;
}

export const useGasConfig = () => {
  return useQuery(
    ["gas_config_data"],
    async () => {
    
      const res = await fetch(GAS_CONFIG_URL);

      const data = await res.json() as GasConfigResponse;

      return data?.data || null;
    }
  );
};
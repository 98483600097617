import { Modal, Steps } from "components/lib";
import TitleWithDocLink from "components/TitleWithDocLink";
import { TransparentCard } from "components/TransparentCard";
import { useChainId } from "hooks/useChainId";
import React, { useContext, useEffect, useState } from "react";
import {
  DataConnectorBundle,
  DataConnectorUpdateConfig,
  ExecutionBundle,
  ExecutionBundleParameters,
  Pool,
  Strategy,
} from "../../../utils/types";
import StrategySelection from "./advance";
// Import other components and utilities...
import { DexContext } from "context/Dex";
import { useHistory } from "react-router-dom";
import { ChooseMode } from "./common/mode";
import { SelectPool } from "./common/pool";
import StrategyEditScreen from "./common/reviewGraph";
import StrategyDeployModal from "./common/deploy/strategyDeployModal";
import DataConnector from "./expert/dataConnector";
import UploadStrategy from "./expert";
import { useEthers } from "@usedapp/core";
import ConfigVault from "./expert/configVault";
import { VaultConfig } from "./common/helper";
import { useDataConnectorConfigs } from "queries/useDataConnectorConfigs";

const getDataConnectors = (customStrategy: Strategy) => {
  if (!customStrategy) {
      return [];
  }

  if (!customStrategy.dataConnectorBundle) {
      return [];
  }

  return customStrategy.dataConnectorBundle.map((dataConnector) => {
      return {
          bundle: dataConnector.bundleHash,
          dataType: dataConnector.dataType,
          output: dataConnector.output,
          configData: dataConnector.configData
      }
  })
}

const NUM_CHILDREN = 2;

const STEP_TAGS = {
  SELECT_POOL_MODE: "select-pool-mode",
  SELECT_POOL_STRATEGY: "select-pool-strategy",
  SELECT_DATA_CONNECTORS: "select-data-connectors",
  REVIEW_SMART_VAULT: "review-smart-vault",
  DEPLOY_DEPOSIT_STRATEGY: "deploy-deposit-strategy",
};

export const ResetContext = React.createContext({
  reset: false,
  handleChildReset: () => { },
});

const PublishSmartPool = () => {
  const { account } = useEthers();
  const [chainId] = useChainId();
  const [reset, setReset] = useState(false);
  const [numChildrenReset, setNumChildrenReset] = useState(0);
  const [mode, setMode] = useState(null);
  const history = useHistory();
  let dataPassed = history?.location?.state?.data;
  // const testPool: Pool = {
  //   feeTier: '3000',
  //   id: '0xD8F1360A64e560C8EB7A65De4131cE7ED8BDdd32',
  //   token0: {
  //     id: '0x125116e39Ec5CECfE29F3d139050cd036Ea2ce19',
  //     symbol: 'TEST_1',
  //     decimals: '18',
  //     name: 'TEST_TOKEN_1'
  //   },
  //   token1: {
  //     id: '0x1673a527836C577c8807480C89CE6de529ab0017',
  //     symbol: 'TEST_2',
  //     decimals: '18',
  //     name: 'TEST_TOKEN_1'
  //   },
  //   totalValueLockedETH: '0',  // dataPassed = {
  //     //   pool: testPool,
  //     //   // strategy: da
  //   totalValueLockedUSD: '0',
  //   volumeUSD: '0',
  //   dex: 'uniswap',
  //   dexImageUrl: '',
  //   dexUrl: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon',
  // }

  // dataPassed = {
  //   pool: testPool,
  //   // strategy: dataPassed?.strategy
  // }

  // const filterPool = dataPassed?.poolId;
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const filterPool =  dataPassed?.poolId;
  const clone = dataPassed?.strategy?.deploymentType === 'clone';
 
  const [selectedPool, setSelectedPool] = useState<Pool | null>(
    dataPassed ? !clone ? dataPassed.pool : null : null
  );

  const [selectedStrategy, setSelectedStrategy] = useState<Strategy | null>(
    dataPassed ? dataPassed.strategy : null
  );
  const [executionBundle, setExecutionBundle] = useState<ExecutionBundle | null>(selectedStrategy ? selectedStrategy.executionBundle : null);
  const [dataConnectors, setDataConnectors] = useState(getDataConnectors(selectedStrategy));
  const [vaultConfig, setVaultConfig] = useState<VaultConfig | null>(null);
  const dex = useContext(DexContext);

  const [dataConnectorConfigs, setDataConnectorConfigs] = useState<DataConnectorUpdateConfig[]>([]);
  const [executionBundleConfig, setExecutionBundleConfig] =
    useState<ExecutionBundleParameters | null>(null);

  const [step, setStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {data: connectorSchemaConfigs } = useDataConnectorConfigs(selectedStrategy);

  // Handler function that child components will call when they finish resetting
  const handleChildReset = () => {
    setNumChildrenReset((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    if (numChildrenReset === NUM_CHILDREN) {
      setReset(false);
      setNumChildrenReset(0); // Reset the count
    }
  }, [numChildrenReset]);

  const handleSelectPool = (selectedOption) => {
    setSelectedPool(selectedOption?.data);
    const fee = Number(selectedOption?.data?.feeTier || "");
    setVaultConfig({
      ...vaultConfig,
      fee
    });
  };

  const handleSelectStrategy = (strategy: Strategy) => {
    resetDefaults();
    setSelectedStrategy(strategy);
  };

  const handleDataConnectorConfig = (values) => {
    setDataConnectorConfigs(values);
  };

  const handleExecutionBundleConfig = (values) => {
    setExecutionBundleConfig(values);

  };

  const resetDefaults = () => {
    setReset(true);
    setDataConnectorConfigs([]);
    setExecutionBundleConfig(null);
  };

  const moveNext = () => {

    setStep((prevStep) => prevStep + 1);
  };

  const moveBack = () => {
    setStep((prevStep) => {
      return prevStep === 0 ? prevStep : prevStep - 1
    });
  };
  const [smartPoolSteps, setSmartPoolSteps] = useState([
    {
      name: "Select Pool & Strategy Mode",
      status: "current",
      tag: STEP_TAGS.SELECT_POOL_MODE
    },
    {
      name: "Review, Deploy Strategy",
      status: "incomplete",
      tag: STEP_TAGS.DEPLOY_DEPOSIT_STRATEGY
    },
  ]);

  useEffect(() => {
    const newSteps = smartPoolSteps.map((stepDetail, i) => {
      if (i === step - 1) {
        stepDetail.status = "complete";
      } else if (i === step) {
        stepDetail.status = "current";
      } else if (i > step) {
        stepDetail.status = "incomplete";
      }
      return stepDetail;
    });
    setSmartPoolSteps(newSteps);
    if(step === 0 && (selectedStrategy || mode)) {
      setSelectedStrategy(
        clone ? selectedStrategy : null
      );
      setMode(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, clone]);

  useEffect(() => {
    let steps = [
      {
        name: `Select Pool ${clone? "": "& Strategy Mode"}`,
        status: "current",
        tag: STEP_TAGS.SELECT_POOL_MODE
      },
      {
        name: "Review, Deploy Strategy",
        status: "incomplete",
        tag: STEP_TAGS.DEPLOY_DEPOSIT_STRATEGY
      },
    ]
    
    if (mode === 'Advance Mode') {
      steps = [
        {
          name: "Select Pool & Strategy Mode",
          status: "current",
          tag: STEP_TAGS.SELECT_POOL_MODE
        },
        {
          name: "Select Strategy",
          status: "current",
          tag: STEP_TAGS.SELECT_POOL_STRATEGY
        },
        {
          name: "Review, Deploy Strategy",
          status: "incomplete",
          tag: STEP_TAGS.DEPLOY_DEPOSIT_STRATEGY
        },
      ]
    }
    
    if (mode === 'Expert Mode') {
      steps = [
        {
          name: "Select Pool & Strategy Mode",
          status: "current",
          tag: STEP_TAGS.SELECT_POOL_MODE
        },
        {
          name: "Upload Strategy",
          status: "current",
          tag: STEP_TAGS.SELECT_POOL_STRATEGY
        },
        {
          name: "Select Data Connectors",
          status: "incomplete",
          tag: STEP_TAGS.SELECT_DATA_CONNECTORS
      },
        {
          name: "Review Steer Smart Vault",
          status: "incomplete",
          tag: STEP_TAGS.REVIEW_SMART_VAULT
        },
        {
          name: "Review, Deploy Strategy",
          status: "incomplete",
          tag: STEP_TAGS.DEPLOY_DEPOSIT_STRATEGY
        },
      ];
    }
    
    setSmartPoolSteps(steps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);




  const handleExecutionBundleUpdate = (values) => {
    setExecutionBundle(values);
    setSelectedStrategy((prevStrategy) => {
      return {
        ...prevStrategy,
        executionBundle: values
      }
    })
  }

  const handleStrategyUpdate = (values) => {
    setSelectedStrategy({
      ...selectedStrategy,
      ...values
    })
  }

  const getDataConnector = (dataConnector): DataConnectorBundle => {

    return {
      bundleHash: dataConnector.bundle,
      dataType: dataConnector.dataType,
      output: dataConnector.output,
      configData: dataConnector.payloadHash
    }
  }

  const updateStrateyAndMoveNext = () => {
    const finalStrategy: Strategy = {
      ...selectedStrategy,
      executionBundle: executionBundle,
      dataConnectorBundle: dataConnectors.map((dataConnector) => {
        return getDataConnector(dataConnector)
      }),
      deploymentType: 'create',
      new: true
    }

    setSelectedStrategy((prevStrategy) => {
      return {
        ...prevStrategy,
        ...finalStrategy
      }
    });
    moveNext();
  }


  const handleUpdateDataConnectors = (dataConnectors: Array<any>) => {
    setDataConnectors(dataConnectors);
  }

  const updateVaultConfig = (vaultConfig: VaultConfig) => {
    setVaultConfig(vaultConfig);
    moveNext();
  }

  return (
    <div className={`py-4 m-auto lg:h-full w-10/12 lg:w-9/12`}>
     
      <ResetContext.Provider value={{ reset, handleChildReset }}>
        <TransparentCard>
          <TitleWithDocLink title={`${clone ? 'Remix': "Add"} Smart Pool`} />
          <div className="mx-4 mt-10 bottom-15">
            <Steps type="horizontal" content={smartPoolSteps} />
          </div>
          <div
            className={`mt-4 border border-lynch-rgba shadow-xs`}
            style={{ width: "100%" }}
          />
          <div className="mx-4 my-2">
            {smartPoolSteps[step].tag === STEP_TAGS.SELECT_POOL_MODE &&<SelectPool
              selectedPool={selectedPool}
              handleSelectPool={handleSelectPool}
              filterPool={''}
              dex={dex}
              chainId={chainId}
            />
            }
            {!clone && smartPoolSteps[step].tag === STEP_TAGS.SELECT_POOL_MODE && 
              <ChooseMode 
                moveNext={moveNext} 
                selectedPool={selectedPool} 
                mode={mode} 
                setMode={setMode} 
                account={account} 
                chainId={chainId}
                setDataConnectorConfigs={setDataConnectorConfigs}
                setSelectedStrategy={setSelectedStrategy}/>}
            {selectedPool && ((mode && mode === 'Quick & Easy Mode' && smartPoolSteps[step].tag === STEP_TAGS.DEPLOY_DEPOSIT_STRATEGY) || (clone)) &&
              <StrategyEditScreen
                selectedStrategy={selectedStrategy}
                selectedPool={selectedPool}
                handleDataConnectorConfig={handleDataConnectorConfig}
                chainId={chainId}
                dataConnectorConfigs={dataConnectorConfigs}
                executionConfigs={executionBundleConfig}
                handleExecutionBundleConfig={handleExecutionBundleConfig}
                handleBack={moveBack}
                moveNext={moveNext}
                resetDefaults={resetDefaults}
                setIsModalOpen={setIsModalOpen}
                mode={mode}
                schemaConfigs={connectorSchemaConfigs}
              />
            }

            {selectedPool && mode && mode === 'Advance Mode' &&
              <div>
                {smartPoolSteps[step].tag === STEP_TAGS.SELECT_POOL_STRATEGY && <StrategySelection
                  selectedPool={selectedPool}
                  handleSelectStrategy={handleSelectStrategy}
                  preSelectedStrategy={selectedStrategy}
                  chainId={chainId}
                  handleBack={moveBack}
                  moveNext={moveNext}
                  setDataConnectorConfigs={setDataConnectorConfigs}
     
     
                />}
                {smartPoolSteps[step].tag === STEP_TAGS.DEPLOY_DEPOSIT_STRATEGY && (
                  <StrategyEditScreen
                    selectedStrategy={selectedStrategy}
                    selectedPool={selectedPool}
                    handleDataConnectorConfig={handleDataConnectorConfig}
                    chainId={chainId}
                    dataConnectorConfigs={dataConnectorConfigs}
                    executionConfigs={executionBundleConfig}
                    handleExecutionBundleConfig={handleExecutionBundleConfig}
                    handleBack={moveBack}
                    moveNext={moveNext}
                    resetDefaults={resetDefaults}
                    setIsModalOpen={setIsModalOpen}
                    mode={mode}
                    schemaConfigs={connectorSchemaConfigs}
                  />
                )}
              </div>
            }

            {selectedPool && mode && mode === 'Expert Mode' &&
              <>
                {smartPoolSteps[step].tag === STEP_TAGS.SELECT_POOL_STRATEGY && <UploadStrategy
                  moveNext={moveNext}
                  setExecutionBundleDetails={handleExecutionBundleUpdate}
                  setStrategyDetails={handleStrategyUpdate}
                  selectedPool={selectedPool}
                  handleBack={moveBack}
                  customStrategy={selectedStrategy}
                />}

                {smartPoolSteps[step].tag === STEP_TAGS.SELECT_DATA_CONNECTORS &&
                  <DataConnector
                    handleBack={moveBack}
                    prevDataConnectors={dataConnectors}
                    updateAndCloseModal={updateStrateyAndMoveNext}
                    selectedPool={selectedPool}
                    updateDataConnectors={handleUpdateDataConnectors}
                    strategy={selectedStrategy}
                    
                  />
                }

                {smartPoolSteps[step].tag === STEP_TAGS.REVIEW_SMART_VAULT &&
                  <ConfigVault
                    handleBack={moveBack}
                    updateVaultConfig={updateVaultConfig}
                    selectedDex={selectedPool.dex}
                    vaultConfig={vaultConfig}
                    poolFee={selectedPool.feeTier}
                  />
                }

                {smartPoolSteps[step].tag === STEP_TAGS.DEPLOY_DEPOSIT_STRATEGY && (
                  <StrategyEditScreen
                    selectedStrategy={selectedStrategy}
                    selectedPool={selectedPool}
                    handleDataConnectorConfig={handleDataConnectorConfig}
                    chainId={chainId}
                    dataConnectorConfigs={dataConnectorConfigs}
                    executionConfigs={executionBundleConfig}
                    handleExecutionBundleConfig={handleExecutionBundleConfig}
                    handleBack={moveBack}
                    moveNext={moveNext}
                    resetDefaults={resetDefaults}
                    setIsModalOpen={setIsModalOpen}
                    mode={mode}
                    schemaConfigs={connectorSchemaConfigs}
                  />
                )}
              </>

            }
          </div>
        </TransparentCard>
      </ResetContext.Provider>

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          size={"large"}
          content={
            <StrategyDeployModal
              closeModal={() => setIsModalOpen(false)}
              dataConnectorConfigs={dataConnectorConfigs}
              executionConfig={executionBundleConfig}
              selectedPool={selectedPool}
              selectedStrategy={selectedStrategy}
              mode={mode}
              _vaultConfig={vaultConfig}
            />
          }
        />
      )}
    </div>
  );
};

export default PublishSmartPool;

// import backgroundImg from './svg/Background.svg'
export const TransparentCard = (props: { children: any, class?: string}) => {
    return (
        <div className={`relative md:px-8 py-8 my-1 bg-black-900 bg-opacity-80 rounded-2xl ${props.class}`} style={{
            boxShadow: 'black -1px 0px 30px -10px'
      }}>
        {/* <img
            className='absolute right-8 -top-2 -z-0'
            src={backgroundImg}
            /> */}
      {props.children}
      </div>
    )
}
"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseCollat = exports.parseStable = exports.requireEnvVars = exports.BN2Number = void 0;
const utils_1 = require("ethers/lib/utils");
const index_1 = require("../index");
__exportStar(require("./merkl"), exports);
__exportStar(require("./thegraph"), exports);
function BN2Number(bn, base = 18) {
    return parseFloat((0, utils_1.formatUnits)(bn, base));
}
exports.BN2Number = BN2Number;
const requireEnvVars = (vars) => {
    const missingEnvVars = vars.filter((v) => !process.env[v]);
    if (missingEnvVars.length) {
        throw new Error(`Missing env vars: ${missingEnvVars.join(', ')}`);
    }
    return vars.reduce((acc, envVar) => {
        acc[envVar] = process.env[envVar];
        return acc;
    }, {});
};
exports.requireEnvVars = requireEnvVars;
function parseStable(stablecoin) {
    let stable;
    for (const token of Object.values(index_1.ALL_TOKENS[index_1.AssetType.STABLE])) {
        if (token.symbol === stablecoin || token.address === stablecoin || token.name === stablecoin || token.symbol === 'ag' + stablecoin) {
            stable = token;
        }
    }
    if (stable === undefined)
        throw 'Incorrect stablecoin value';
    return stable;
}
exports.parseStable = parseStable;
function parseCollat(collateral) {
    let collat;
    for (const token of Object.values(index_1.ALL_TOKENS[index_1.AssetType.COLLATERAL])) {
        if (token.symbol === collateral || token.address === collateral || token.name === collateral) {
            collat = token;
        }
    }
    if (collat === undefined)
        throw 'Incorrect collateral value';
    return collat;
}
exports.parseCollat = parseCollat;

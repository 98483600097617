import {
  AppDataRender,
  AppDetailsCard,
} from "components/lib";

import {
  getExplorerLink,
  getMinifedAddress,

} from "utils";

const LpTokenDetailsSection = ({
    chainId,
    vaultDetails,
    vaultData
}) => {

  return (
    <AppDetailsCard
    title={`LP Token Details`}
    description={`LP tokens serve as proof of ownership of a share in the liquidity of a specific pool on the platform. LP tokens provide a unique opportunity for users to not only earn fees but also gain access to a wide range of benefits like Yield farming, Staking and more. Steer also incentivised staking pool on LP token for user to earn more rewards.`}
    content={
      <AppDataRender
        data={[
          {
            type: "link",
            label: "Lp Token Name ",
            val: {
              onClick: () =>
                window.open(
                  `${getExplorerLink(chainId)}/address/${
                    vaultDetails["lpToken"]
                  }`
                ),
              text: vaultDetails["name"],
            },
          },
          {
            type: "text",
            label: "Lp Token Symbol",
            val: vaultDetails["symbol"],
          },
          {
            type: "text",
            label: "Current Supply",
            val:
              parseFloat(
                vaultDetails["lpTokenTotalSupplyString"]
              ) || 0,
          },
          {
            type: "link",
            label: "Pool",
            val: {
              onClick: () =>
                window.open(
                  `${getExplorerLink(chainId)}/address/${
                    vaultData?.pool
                  }`
                ),
              text: getMinifedAddress(vaultData?.pool || ""),
            },
          },
        ]}
        size={"small"}
      />
    }
  />
  );
};

export default LpTokenDetailsSection;
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Int_BASE_18 = exports.Int_BASE = exports.Int_ONE = exports.Int_ZERO = exports.Int256 = void 0;
const ethers_1 = require("ethers");
const types_1 = require("../types");
const bignumber_1 = require("../utils/bignumber");
class Int256 {
    constructor(value, base = 18, parse = false) {
        var _a;
        if (value instanceof Int256) {
            this.value = value.value;
            this.base = value.base;
            return;
        }
        if (!value) {
            value = 0;
        }
        if (parse && (typeof value === 'string' || typeof value === 'number')) {
            let valueString;
            if (typeof value === 'number') {
                valueString = value.toFixed(base);
            }
            else {
                valueString = value;
            }
            if (valueString.indexOf('.') !== -1) {
                const trimDecimals = (_a = valueString.match(RegExp(`^\\d*\\.\\d{0,${base}}`, 'g'))) === null || _a === void 0 ? void 0 : _a[0];
                if (!!trimDecimals) {
                    valueString = trimDecimals;
                }
            }
            this.value = ethers_1.utils.parseUnits(valueString, base);
        }
        else {
            this.value = ethers_1.BigNumber.from(value);
        }
        this.base = base;
    }
    static from(value, base = 18) {
        return new Int256(value, base, false);
    }
    static parse(value, base = 18) {
        return new Int256(value, base, true);
    }
    get raw() {
        return this.value;
    }
    toFixed(decimals = 3, output = 'number', round = false) {
        const num = parseFloat(ethers_1.utils.formatUnits(this.value, this.base));
        return (0, bignumber_1.formatNumber)(num, decimals, output, round);
    }
    toNumber(decimals = -1) {
        return parseFloat(ethers_1.utils.formatUnits(this.value, this.base));
    }
    toExact(decimals = -1) {
        if (decimals === -1) {
            return ethers_1.utils.formatUnits(this.value, this.base);
        }
        else {
            return parseFloat(ethers_1.utils.formatUnits(this.value, this.base)).toFixed(decimals);
        }
    }
    rebase(base) {
        const newNumber = ethers_1.utils.parseUnits(ethers_1.utils.formatUnits(this.value, this.base), base);
        return Int256.from(newNumber, base);
    }
    get abs() {
        return Int256.from(this.value.abs(), this.base);
    }
    lt(other) {
        if (other instanceof Int256) {
            return this.value.mul((0, types_1.BigNumber_BASE)(other.base)).lt(other.value.mul((0, types_1.BigNumber_BASE)(this.base)));
        }
        return this.value.lt(other);
    }
    lte(other) {
        if (other instanceof Int256) {
            return this.value.mul((0, types_1.BigNumber_BASE)(other.base)).lte(other.value.mul((0, types_1.BigNumber_BASE)(this.base)));
        }
        return this.value.lte(other);
    }
    eq(other) {
        if (other instanceof Int256) {
            return this.value.mul((0, types_1.BigNumber_BASE)(other.base)).eq(other.value.mul((0, types_1.BigNumber_BASE)(this.base)));
        }
        return this.value.eq(other);
    }
    gt(other) {
        if (other instanceof Int256) {
            return this.value.mul((0, types_1.BigNumber_BASE)(other.base)).gt(other.value.mul((0, types_1.BigNumber_BASE)(this.base)));
        }
        return this.value.gt(other);
    }
    gte(other) {
        if (other instanceof Int256) {
            return this.value.mul((0, types_1.BigNumber_BASE)(other.base)).gte(other.value.mul((0, types_1.BigNumber_BASE)(this.base)));
        }
        return this.value.gte(other);
    }
    add(other) {
        if (!other)
            return undefined;
        return new Int256(this.value
            .mul((0, types_1.BigNumber_BASE)(other.base))
            .add(other.value.mul((0, types_1.BigNumber_BASE)(this.base)))
            .div((0, types_1.BigNumber_BASE)(other.base)), this.base);
    }
    sub(other) {
        if (!other)
            return undefined;
        return new Int256(this.value
            .mul((0, types_1.BigNumber_BASE)(other.base))
            .sub(other.value.mul((0, types_1.BigNumber_BASE)(this.base)))
            .div((0, types_1.BigNumber_BASE)(other.base)), this.base);
    }
    mul(other) {
        if (!other)
            return undefined;
        if (other instanceof Int256) {
            return new Int256(this.value.mul(other.value).div((0, types_1.BigNumber_BASE)(other.base)), this.base);
        }
        return new Int256(this.value.mul(other), this.base);
    }
    div(other) {
        if (!other)
            return undefined;
        if (other instanceof Int256) {
            return new Int256(this.value.mul((0, types_1.BigNumber_BASE)(other.base)).div(other.value), this.base);
        }
        return new Int256(this.value.div(other), this.base);
    }
}
exports.Int256 = Int256;
exports.Int_ZERO = Int256.from('0');
exports.Int_ONE = Int256.parse('1');
const Int_BASE = (base) => Int256.from((0, types_1.BigNumber_BASE)(base), base);
exports.Int_BASE = Int_BASE;
exports.Int_BASE_18 = (0, exports.Int_BASE)(18);

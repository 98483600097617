import React, { useRef, useState } from "react";
import { Icon } from "components/lib";
import { uploadFileAndGetHash } from "utils";

const UploadFile = ({
  setIpfsHash,
}: {
  setIpfsHash: (ipfsHash: string) => void;
}) => {
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const onClickHandler = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const fileUploadHandler = async (e) => {
    const file = e.target.files[0];
    setLoading(true);
    const hash = await uploadFileAndGetHash(file);
    setLoading(false);
    setIpfsHash(hash);
  };

  return (
    <div className="flex">
      <input type="file" hidden onChange={fileUploadHandler} ref={inputRef} accept=".wasm"/>
      <div
        className="cursor-pointer text-white-500 hover:underline"
        onClick={(e) => onClickHandler(e)}
      >
        Upload WASM File & Get IPFS Hash 
      </div>
      {loading && <>&nbsp; &nbsp;<Icon spin={true} name="faSpinner" /></>}
    </div>
  );
};

export default UploadFile;

import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg
      width={34}
      height={34}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 9a8 8 0 00-4.8 14.4v6.4a3.2 3.2 0 003.2 3.2h3.2a3.2 3.2 0 003.2-3.2v-6.4A8 8 0 0017 9zm2.4 12.144a1.6 1.6 0 00-.8 1.392V29.8h-3.2v-7.264a1.6 1.6 0 00-.8-1.392A4.8 4.8 0 0112.2 17a4.8 4.8 0 019.6 0 4.8 4.8 0 01-2.4 4.144zM17 7.4a1.6 1.6 0 001.6-1.6V2.6a1.6 1.6 0 00-3.2 0v3.2A1.6 1.6 0 0017 7.4zm14.4 8h-3.2a1.6 1.6 0 000 3.2h3.2a1.6 1.6 0 000-3.2zm-25.6 0H2.6a1.6 1.6 0 000 3.2h3.2a1.6 1.6 0 000-3.2zm4.256-7.328L7.752 5.8a1.618 1.618 0 00-2.224 2.352l2.304 2.224A1.6 1.6 0 009 10.824a1.6 1.6 0 001.152-.496 1.6 1.6 0 00-.096-2.256zM28.504 5.88a1.6 1.6 0 00-2.256 0l-2.304 2.192a1.6 1.6 0 000 2.256 1.6 1.6 0 001.152.496 1.6 1.6 0 001.104-.448l2.304-2.224a1.599 1.599 0 000-2.272z"
        fill="#D3AC22"
        stroke="#5F5D54"
        strokeWidth={0.4}
      />
    </svg>
  )
}

export default SvgComponent

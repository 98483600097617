import React from "react";

import { Button } from "components/lib";

const SharedButtons = ({
  steps,
  setSteps,
  nextStep,
  isDisabled,
  showOnlyNext,
  previousStep,
  callableType,
  inProgress
}: {
  steps: number;
  setSteps: (step: number) => void;
  nextStep: () => void;
  isDisabled?: boolean;
  showOnlyNext?:boolean;
  previousStep?: () => void;
  inProgress?:boolean;
  callableType?: 'strategy' | 'job'
}) => {
  return (
    <div
      className={`flex items-center mt-10 mx-2 flex-col w-full md:flex-row ${
        !showOnlyNext ? "justify-between" : "justify-center"
      }`}
    >
      {!showOnlyNext && (
        <div className="mb-4 md:mb-0">
        <Button
          content={"back"}
          onClickHandler={previousStep || (() => setSteps(steps - 1))}
          iconAlignment={"left"}
          type={"tertiary"}
          casing={"capitalize"}
          iconType={"normal"}
          icon={"faArrowLeft"}
        />
        </div>
      )}
      <Button
        content={callableType === 'job' && steps === 1 ? 'Schedule' : steps === 3 ? "publish" : "next"}
        icon={inProgress ? 'faSpinner' : callableType === 'job' && steps === 1 ? 'faClock' : steps === 2 ? "faCode" : "faArrowRight"}
        onClickHandler={nextStep}
        iconAlignment={"right"}
        type={"tertiary"}
        casing={"capitalize"}
        disabled={isDisabled}
        iconSpin={!!inProgress}
      />
    </div>
  );
};

export default SharedButtons;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ethers_1 = require("ethers");
const utils_1 = require("ethers/lib/utils");
const bignumber_1 = require("../../utils/bignumber");
const yearlyRate = 1.03;
const ratePerSecond = Math.pow(yearlyRate, (1 / (365 * 24 * 3600))) - 1;
const yearlyRate2 = 1.03;
const ratePerSecond2 = Math.pow(yearlyRate2, (1 / (365 * 24 * 3600))) - 1;
const stablesParameters = {
    EUR: {
        currencySymbol: '€',
        flashloan: {
            maxBorrowable: bignumber_1.parseAmount.ether('1000000'),
            flashLoanFee: bignumber_1.parseAmount.gwei('0.001'),
        },
        vaultManagers: [
            {
                collateral: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
                symbol: 'wETH/EUR',
                oracle: 'ETH_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('100000000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.5'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.1'),
                    borrowFee: bignumber_1.parseAmount.gwei('0'),
                    repayFee: bignumber_1.parseAmount.gwei('0'),
                    interestRate: ethers_1.ethers.utils.parseUnits('0.000000001243680714', 27),
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.1'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('1'),
                },
            },
            {
                collateral: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
                symbol: 'wBTC/EUR',
                oracle: 'BTC_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('100000000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.66'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.2'),
                    borrowFee: bignumber_1.parseAmount.gwei('0.003'),
                    repayFee: bignumber_1.parseAmount.gwei('0.003'),
                    interestRate: (0, utils_1.parseUnits)(ratePerSecond.toFixed(27), 27),
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.15'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('1'),
                },
            },
            {
                collateral: '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0',
                symbol: 'wstETH/EUR',
                oracle: 'WSTETH_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('100000000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.75'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.2'),
                    borrowFee: bignumber_1.parseAmount.gwei('0'),
                    repayFee: bignumber_1.parseAmount.gwei('0'),
                    interestRate: (0, utils_1.parseUnits)(ratePerSecond2.toFixed(27), 27),
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.1'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('1'),
                },
            },
        ],
    },
};
exports.default = {
    stablesParameters: stablesParameters,
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SOLIDITY_TYPE_MAXIMA = exports.SolidityType = exports.BASE_18 = exports.BigNumber_BASE = exports.ActionType = exports.AssetType = exports.NETWORK_LABELS = exports.ChainId = void 0;
const ethers_1 = require("ethers");
var ChainId;
(function (ChainId) {
    ChainId[ChainId["ARBITRUM"] = 42161] = "ARBITRUM";
    ChainId[ChainId["AURORA"] = 1313161554] = "AURORA";
    ChainId[ChainId["AVALANCHE"] = 43114] = "AVALANCHE";
    ChainId[ChainId["BSC"] = 56] = "BSC";
    ChainId[ChainId["CELO"] = 42220] = "CELO";
    ChainId[ChainId["FANTOM"] = 250] = "FANTOM";
    ChainId[ChainId["LOCAL"] = 1337] = "LOCAL";
    ChainId[ChainId["MAINNET"] = 1] = "MAINNET";
    ChainId[ChainId["OPTIMISM"] = 10] = "OPTIMISM";
    ChainId[ChainId["POLYGON"] = 137] = "POLYGON";
    ChainId[ChainId["GNOSIS"] = 100] = "GNOSIS";
})(ChainId = exports.ChainId || (exports.ChainId = {}));
exports.NETWORK_LABELS = {
    [ChainId.ARBITRUM]: 'Arbitrum',
    [ChainId.CELO]: 'Celo Mainnet',
    [ChainId.LOCAL]: 'Local Chain',
    [ChainId.MAINNET]: 'Ethereum',
    [ChainId.FANTOM]: 'Fantom',
    [ChainId.POLYGON]: 'Polygon',
    [ChainId.AURORA]: 'Aurora',
    [ChainId.AVALANCHE]: 'Avalanche',
    [ChainId.BSC]: 'BNB Smart Chain',
    [ChainId.OPTIMISM]: 'Optimism',
    [ChainId.GNOSIS]: 'Gnosis Chain',
};
var AssetType;
(function (AssetType) {
    AssetType[AssetType["STABLE"] = 0] = "STABLE";
    AssetType[AssetType["COLLATERAL"] = 1] = "COLLATERAL";
    AssetType[AssetType["ANGLE"] = 2] = "ANGLE";
    AssetType[AssetType["EXTERNAL_STAKING"] = 3] = "EXTERNAL_STAKING";
})(AssetType = exports.AssetType || (exports.AssetType = {}));
var ActionType;
(function (ActionType) {
    ActionType[ActionType["MINT"] = 0] = "MINT";
    ActionType[ActionType["BURN"] = 1] = "BURN";
    ActionType[ActionType["POOL_DEPOSIT"] = 2] = "POOL_DEPOSIT";
    ActionType[ActionType["POOL_WITHDRAW"] = 3] = "POOL_WITHDRAW";
    ActionType[ActionType["PERPETUAL_CREATE"] = 4] = "PERPETUAL_CREATE";
    ActionType[ActionType["PERPETUAL_LIQUIDATE"] = 5] = "PERPETUAL_LIQUIDATE";
    ActionType[ActionType["ROUTER_TX"] = 6] = "ROUTER_TX";
    ActionType[ActionType["STAKE_SANTOKEN"] = 7] = "STAKE_SANTOKEN";
    ActionType[ActionType["STAKE_AGTOKEN"] = 8] = "STAKE_AGTOKEN";
    ActionType[ActionType["STAKE_EXTERNAL"] = 9] = "STAKE_EXTERNAL";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
const BigNumber_BASE = (decimals) => ethers_1.BigNumber.from(10).pow(decimals);
exports.BigNumber_BASE = BigNumber_BASE;
exports.BASE_18 = (0, exports.BigNumber_BASE)(18);
var SolidityType;
(function (SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType = exports.SolidityType || (exports.SolidityType = {}));
exports.SOLIDITY_TYPE_MAXIMA = {
    [SolidityType.uint8]: ethers_1.BigNumber.from('0xff'),
    [SolidityType.uint256]: ethers_1.BigNumber.from('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'),
};

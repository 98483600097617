import { Button, Dropdown, Toggle } from "components/lib";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import millify from 'millify';

import { gql, useLazyQuery } from "@apollo/client";
import { useConfig, useEthers } from "@usedapp/core";
import TestnetFaucet from "components/TestnetFaucet";
import TitleWithDocLink from "components/TitleWithDocLink";
import { TransparentCard } from "components/TransparentCard";
import { getClient } from "services/graphql";
// ABIs

import { beaconNameMapper, supportedChains, paginateData, isTestnet, appTypes } from "utils";

import AppDetails from "components/customCards/AppDetailsCard";
import MockCardApp from 'components/customCards/MockAppCard';

import { getQuery } from "./helper";
import { ConsentContext } from "context/Consent";
import { DeprecatedBundlesContext } from "context/DeprecatedBundles";
import { StakingPoolsContext } from "context/StakingPools";
import { useChainId } from "hooks/useChainId";
import { SushiRewardPoolsContext } from "context/SushiRewardPools";
import { MerklContext } from "context/Merkl";

import { getTokenPair } from "services/rest";
import { DexContext } from "context/Dex";
import PoolSelect from 'components/PoolSelect';
// import {useEvmosTemp} from 'hooks/useEvmosTemp';

// frontend search, removed for now
// const filterDataBySearch = (data, searchText) => {
//   return data.filter((d) => {
//     return d.strategyToken.name.toLowerCase().indexOf(searchText) !== -1 || 
//     d.token0Symbol.toLowerCase().indexOf(searchText) !== -1 || 
//     d.token1Symbol.toLowerCase().indexOf(searchText) !== -1 ||
//     d.deployer.toLowerCase().indexOf(searchText) !== -1 ||
//     `${ d.token0Symbol.toLowerCase()}-${d.token1Symbol.toLowerCase()}`.indexOf(searchText) !== -1;
//   })
// }

const FETCH_VAULTS = gql`
  query getVaults($where: Vault_filter!, $orderBy: String!) {
    vaults(where: $where, first: 1000, orderBy: $orderBy
      orderDirection: desc) {
      id
      payloadIpfs
      beaconName
      token0
      token0Symbol
      token1
      token1Symbol
      feeTier
      decimals
      annualFeeARR
      weeklyFeeAPR
      strategyToken {
        executionBundle
        id
        name
      }
      createdAt
      token0Balance
      token0Decimals
      token1Decimals
      token1Balance
      annualPercentageYearlyYield
      lastSnapshot
      deployer
      pool
    }
  }
`;

export const formatOptionLabel = ({ data }) => {
  return (
    <div className="flex items-center">
      <img
        src={
          data.dexImageUrl
        }
        alt={data.id}
        className="inline-block w-5 h-5 mr-2"
      />
      <span className="mr-2 text-sm">{`${data.token0.symbol}-${data.token1.symbol}`}</span>
      <span className="mr-2 text-sm">{`Fees: ${(
        parseInt(data.feeTier) / 10000
      ).toFixed(2)}`}</span>
      <span className="text-sm">{`TVL: ${millify(
        parseFloat(data.totalValueLockedUSD)
      )}$`}</span>
    </div>
  );
};

export const loadOptions = async (inputValue, chainId, selectedDex) => {
  const dexPools = await getTokenPair(inputValue, chainId, selectedDex)
  dexPools.sort(
    (a, b) => Number(b.totalValueLockedUSD) - Number(a.totalValueLockedUSD)
  );
  return dexPools.map((pool) => ({
    value: pool.id,
    data: pool,
  }));
}

const Dashboard: FunctionComponent = () => {
  const { account } = useEthers();
  const [chainId] = useChainId();
  const [pageIndex] = useState(0);
  const [vaults, setVaults] = useState([]);
  const history = useHistory();
  const [selectedPool, setSelectedPool] = useState(null);
  const [sortByTvl, setSortByTvl] = useState(true);
  const { setShowConsentModal, hasSigned } = useContext(ConsentContext);
  const [showMyApps, setShowMyApps] = useState(false);
  const { deprecatedBundles } = useContext(DeprecatedBundlesContext);
  const { stakingPools } = useContext(StakingPoolsContext);
  const [selectedAppType, setSelectedAppType] = useState(null);
  const { sushiRewardPools } = useContext(SushiRewardPoolsContext);
  const { merklRewardPools } = useContext(MerklContext);
  const { selectedDex } = useContext(DexContext);
  const [showBoostedPools, setShowBoostedPools] = useState(false);
  // const {evmosVaultDetails, isLoading } = useEvmosTemp();
  let deprecatedBundlesIds = (deprecatedBundles || []).filter((bundle) => (bundle["visible"] === false || bundle["execute"] === false) && bundle["type"] === "VAULT").map(d => d["id"]);
  deprecatedBundlesIds = deprecatedBundlesIds.length === 0 ? [""] : deprecatedBundlesIds;

  let deprecatedBundlesStrategyIds = (deprecatedBundles || []).filter((bundle) => (bundle["visible"] === false || bundle["execute"] === false)  && bundle["type"] === "STRATEGY").map(d => d["id"]);
  deprecatedBundlesStrategyIds = deprecatedBundlesStrategyIds.length === 0 ? [""] : deprecatedBundlesStrategyIds;

  const [gqlVaults, setGqlVaults] = useState([]);
  const tconfig = useConfig();
  let graphClient = getClient(supportedChains[0].subgraphURl);
  const supportedChain = supportedChains.find((chain) => {
    if(chainId) {
      return chain.id === chainId;
    } else {
      return chain.id === tconfig.readOnlyChainId
    }
  });

  const beaconNames = Object.keys(beaconNameMapper)

  const [queryObj, setQueryObj] = useState({
    appEngine: beaconNames.filter(k => k.toLowerCase().indexOf(selectedDex) !== -1)
  }); 

  const categoryFilters = [
    {
      id: "appEngine",
      label: "Filter By App Type",
      options: appTypes,
    },
    {
      id: "sort",
      label: "Sort By",
      options: [{label: "Swap Fee APR", value: "weeklyFeeAPR"}, {label: "TVL", value: "tvl"}]
    }
  ];
  if (supportedChain) {
    graphClient = getClient(supportedChain.subgraphURl);
  }

  const [getStrategyVaults, strategyVaults] = useLazyQuery(FETCH_VAULTS, {
    client: graphClient,
  });

  const doSubgraphCall = (currentQueryObj, addDeprecatedIds = false) => {
    const query = getQuery(currentQueryObj, deprecatedBundlesIds, deprecatedBundlesStrategyIds, addDeprecatedIds);

    setVaults([]);
    setGqlVaults([]);

    getStrategyVaults({
      variables: { where: query,  orderBy: currentQueryObj.sort || 'weeklyFeeAPR' },
    });
  };

  useEffect(() => {
    setSelectedAppType(null);
    setShowMyApps(false);
    setQueryObj({
      appEngine: beaconNames.filter(k => k.toLowerCase().indexOf(selectedDex) !== -1)
    });
    setShowBoostedPools(false);
    setVaults([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[chainId]);
  
  useEffect(() => {
    let notUpdateState = false;
    if (!notUpdateState) {

      doSubgraphCall(queryObj);
      // setGqlStrategiesCall(true);
    }
    return () => {
      notUpdateState = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId, deprecatedBundles]);
  
  useEffect(() => {
    const loadNewData = gqlVaults && gqlVaults.length > 0 && strategyVaults && strategyVaults?.data && gqlVaults[0].beaconName !== strategyVaults?.data?.vaults?.[0]?.beaconName;
    if (
      (strategyVaults?.data?.vaults &&
      strategyVaults?.data?.vaults.length !== gqlVaults.length) || (strategyVaults?.data?.vaults && loadNewData)
    ) {
      const vaultData = strategyVaults?.data?.vaults.map(item => {
        const incentivized = stakingPools && stakingPools.find(d => d["stakingToken"]?.toLowerCase() === item["id"]?.toLowerCase())
        const sushiRewardPool = sushiRewardPools?.find((pool) => pool["address"]?.toLowerCase() === item['pool']?.toLowerCase());
        const merklRewardPool = merklRewardPools?.find((pool) => {
          return !!pool['incentiveAprs'][item['id']];
        });
        if (merklRewardPool) {
          merklRewardPool["incentiveApr"] = merklRewardPool['incentiveAprs'][item['id']] || 0;
        }
        
        return {
          ...item,
          incentivized,
          sushiRewardPool,
          merklRewardPool: merklRewardPool && merklRewardPool["incentiveApr"]? merklRewardPool : undefined
        }
      });

      const pageBreakupData = paginateData(vaultData);
      setVaults(pageBreakupData);
      setGqlVaults(vaultData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategyVaults]);

  // useEffect(() => {
  //   if(chainId === 9001 && !isLoading && evmosVaultDetails?.['data'] && vaults.length === 0) {
  //     const vaultData = evmosVaultDetails?.['data'].map(item => {
  //       const incentivized = stakingPools && stakingPools.find(d => d["stakingToken"]?.toLowerCase() === item["id"]?.toLowerCase())
  //       return {
  //         ...item,
  //         incentivized,
  //         sushiRewardPool: undefined,
  //         merklRewardPool: undefined
  //       }
  //     });
  //     setVaults([vaultData]);
  //   }
  // }, [chainId, isLoading, evmosVaultDetails, vaults, stakingPools]);

  useEffect(() => {
    const updateObj = !showMyApps ? {
      gasUsed_gt: "0",
    } : {
      deployer: account.toLowerCase(),
    };

    const existingQuery = queryObj;
    Object.keys(updateObj).map((key) => {
      if (!showMyApps) {
        delete queryObj["deployer"];
      } else {
        delete queryObj["gasUsed_gt"];
      }
      existingQuery[key] = updateObj[key];
      return null;
    })
    doSubgraphCall(existingQuery, showMyApps);
    setQueryObj(existingQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMyApps]);

  useEffect(() => {
    if(selectedAppType || selectedDex) {

      let beaconNames = Object.keys(beaconNameMapper)
      if(selectedDex) {
        beaconNames = beaconNames.filter(k => k.toLowerCase().indexOf(selectedDex) !== -1)
      }
      if(selectedAppType) {
        beaconNames = beaconNames.filter(k => k.toLowerCase().indexOf(selectedAppType.toLowerCase()) !== -1);
      }
      callGraph('appEngine', beaconNames);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppType, selectedDex])

  const refetchSubgraphData = (queryOps) => {
    const existingQuery = queryObj;
    existingQuery[queryOps.id] = queryOps.option;
    doSubgraphCall(existingQuery);
    setQueryObj(existingQuery);
  };

  const callGraph = (id, option) => {
    refetchSubgraphData({
      id,
      option,
    });
  };

  const handleCategorySelect = (category, option) => {
    if (category.id === 'sort') {
      let newVaults = vaults[pageIndex];
      if(option === 'tvl' && newVaults) {
        setSortByTvl(true);
        newVaults = newVaults.sort((a, b) => b.tvl - a.tvl); 
      }
      if(option === 'weeklyFeeAPR' && newVaults) {
        setSortByTvl(false);
        newVaults = newVaults.sort((a, b) => b.weeklyApr - a.weeklyApr); 
      }
      if(newVaults) {
        const pageBreakupData = paginateData(newVaults);
        setVaults(pageBreakupData);
      }
    } else if( category.id === 'appEngine') {
        setSelectedAppType(option);
      }
  };

  // const handleSubCategorySelect = (event, option) => {
  //   if (event.target.checked === true) {
  //     setSelectedTokenPair(selectedTokenPair.concat([option]));
  //     // callGraph(subcategory.id, option);
  //   } else {
  //     setSelectedTokenPair(selectedTokenPair.filter(d => d !== option));
  //     // callGraph(subcategory.id, "all");
  //   }
  // };

  const renderPublishBtn = () => {
    return (
      <div className="flex">
        <Button
          onClickHandler={() => {
            if (!hasSigned && localStorage.getItem('consent-verified') !== 'true') {
              setShowConsentModal(true);
            } else {
              history.push("/publish-smart-pool");
            }
          }}
          content={"Deploy Smart Pool"}
          casing={`capitalize`}
          type={`nprimary`}
          size={"small"}
        />
      </div>
    );
  };

  const renderFaucetBtn = () => {
    return (
      <div className="flex mr-2">
        <TestnetFaucet />
      </div>
    );
  };


  // const filterSelectedToken = (vaults) => {
  //   return vaults.filter(v => {
  //     // console.log(v.pool, v?.weeklyApr, v?.tvl, v)
  //     return isTestnet(chainId) || showMyApps || v.id === 'na' ? true: (parseFloat(v?.weeklyApr || 1) + +(parseFloat(v?.["sushiRewardPool"]?.["incentiveApr"] || 0)*100).toFixed(2))  > 0
  //   }) 
  // }

  const storeTVL = (vaultId, tvl) => {
    const index = vaults[pageIndex].findIndex(d => d.id === vaultId);
    if (!vaults[pageIndex][index]['tvl']) {
      vaults[pageIndex].splice(index, 1, {
        ...vaults[pageIndex][index],
        tvl
      });
      if(sortByTvl) {
        const newVaults = vaults[pageIndex].sort((a, b) => b.tvl - a.tvl);
        const pageBreakupData = paginateData(newVaults);
        setVaults(pageBreakupData);
      } else {
        setVaults(vaults);
        }
    }
  }

  const storeWeeklyApr = (vaultId, weeklyFeeAPR) => {
    const index = vaults[pageIndex].findIndex(d => d.id === vaultId);
    if (!vaults[pageIndex][index]['weeklyApr']) {
      vaults[pageIndex].splice(index, 1, {
        ...vaults[pageIndex][index],
        weeklyApr: weeklyFeeAPR
      });

      if(!sortByTvl) {
        const newVaults = vaults[pageIndex].sort((a, b) => b.weeklyApr - a.weeklyApr);
        const pageBreakupData = paginateData(newVaults);
        setVaults(pageBreakupData);
      } else {
        setVaults(vaults);
      }
    }
  }

  // if (!isTestnet(chainId) && vaults.length > 0 && checkIfVaultsHasTVL()) {
  //   const sort = categoryFilters.find(d => d.id === 'sort');
  //   const hasTVL = sort.options.find(d => d.value === 'tvl');
  //   if(!hasTVL) {
  //     sort.options.push(
  //       {label: "TVL", value: "tvl"}
  //     );
  //   }
  // }

  const noPoolFound = strategyVaults?.data?.vaults && strategyVaults?.data?.vaults.length === 0;

  const filterBoostedPools = (val) => {
    if(val) {
      const newVaults = vaults[pageIndex].filter((a) => a.incentivized || a["sushiRewardPool"] || a["merklRewardPool"]) ;
      const pageBreakupData = paginateData(newVaults);
      setVaults(pageBreakupData);
      setShowBoostedPools(true);
    } else {
      setShowBoostedPools(false);
      doSubgraphCall(queryObj);
    }
  }

  const renderFilter = (category) => {
    return  (
      <div className="my-4 lg:my-0">
    <Dropdown
      onSelectHandler={(data) =>
        handleCategorySelect(category, data)
      }
      hasIcon={false}
      options={category.options}
      placeholder={category.label}
      key={category.id}
      type="normal"
      headingColor="white"
      placeholderColor="white"
      backgroundType="white"
    />
  </div>
    )
  }

  const handleSelectPool = (selectedOption) => {
    if(selectedOption && vaults) {
      const fvaults = vaults[pageIndex].filter(d => d.pool === selectedOption.data.id);
      setSelectedPool(selectedOption.data);
      if(fvaults.length > 0) {
        const newVaults = fvaults;
        const pageBreakupData = paginateData(newVaults);
        setVaults(pageBreakupData);
      } else {
        const newVaults = [
          {
            id: 'na',
            pool: selectedOption.data,
            beaconName: selectedDex
          }
        ];
        const pageBreakupData = paginateData(newVaults);
        setVaults(pageBreakupData);
      }
    } else {
      callGraph('search', "");
    }
  };
  
  const filterNoTvlBlankAprVaults = (vaults) => {

    return vaults.filter((v) => {
      if (isTestnet(chainId) || showMyApps) return true;
      if (v?.weeklyApr === undefined) return true;
      if(v?.tvl === undefined && v?.token0Balance === '0' && v?.token1Balance === '0' && v?.weeklyApr !== 0) return false;
      if(v?.tvl === undefined && v?.token0Balance !== '0' && v?.token1Balance !== '0') return true;
      return v?.weeklyApr > 0 || v?.tvl > 0;
    })
  }

  return (
    <div
      className={`py-4 m-auto lg:h-full w-10/12 lg:w-9/12`}
    >
      <TransparentCard>
        <TitleWithDocLink
          title="Smart Pools"
          link="https://docs.steer.finance/concentrated-liquidity/"
          linkText="Learn more about concentrated-liquidity"
          secondaryElement={
            <div className="flex">
              {account && isTestnet(chainId) && renderFaucetBtn()}
              {!noPoolFound && renderPublishBtn()}
            </div>
          }
        />
        <div className="grid-cols-3 gap-4 px-4 mt-4 lg:px-0 lg:grid">

        {/* <Select
            isSearchable={true}
            handleInputChange={(d) => setDebouncedTerm(d)}
            options={tokenOptions}
            selected={selectedToken}
            onSelectHandler={setSelectedToken}
            placeholder=""
            />
             */}
          {/* <Search
            placeholder={`Search Tokens`}
            onChangeHandler={(val) => setDebouncedTerm(val)}
            backgroundType="white"
          /> */}
          <div className="">
          {vaults[pageIndex] && <PoolSelect 
            handleSelectPool={handleSelectPool}
            pool={selectedPool}
            dex={selectedDex}
        />}
          </div>
          {vaults[pageIndex] && categoryFilters.map((category) => renderFilter(category))}
          
        </div>
        <div className="flex justify-center px-4 mt-4 md:flex-end md:justify-end flex-end lg:px-0">
          {account &&  vaults[pageIndex] && <div className="flex items-center mx-6"> <span className="mr-2 text-white-500">My Apps</span>
          <Toggle enabled={showMyApps} setEnabled={(t) => setShowMyApps(t)} /></div>}
          {<div className="flex items-center"> <span className="mr-2 text-white-500">Boosted Pools ✨</span>
          <Toggle enabled={showBoostedPools} setEnabled={(t) => filterBoostedPools(t)} /></div>}
        </div>
        {/* {chainId === 9001 && <div className="m-4">
        <IconDetailBox
            msg="The Steer Evmos Subgraph Provider is currently experiencing an outage, causing some data to display as 0. The issue will be resolved automatically once the Subgraph is restored."
            icon="faLink"
          />  
        </div>} */}
        {/* {strategyVaults?.data?.vaults && strategyVaults?.data?.vaults.length === 0  && 
          <div className="flex flex-col items-center justify-center my-4">
            <div className="text-3xl">📝</div>
            <div className="my-4">
              <Label
                content={
                  "No Apps Deployed Yet."
                }
                align={`center`}
                size={`large`}
                color={`white-500`}
              />
            </div>
          </div>
        } */}
        {noPoolFound && 
          <div className="flex flex-col items-center justify-center my-4">
            <div className="text-lg text-white-500">No Smart Pool Found. </div>
            <div className="text-sm text-white-500">Looking for a recently created pool? Check "My Apps"</div>
            <div className="my-4">
              {renderPublishBtn()}
            </div>
          </div>
        }
        <div className="flex flex-wrap justify-evenly">
          {vaults.length > 0 &&
            filterNoTvlBlankAprVaults(vaults[pageIndex]).map((item, index) => {
              // const isDeprecated = deprecatedBundlesIds.indexOf(item.id) !== -1;
              const isDeprecated = false;
              return (
                <div className="flex py-4 mx-1" key={item.id}>
                  {item.id !== 'na' ? <AppDetails vaultInfo={{ ...item, chainId, key: index, isDeprecated, storeTVL, storeWeeklyApr }} /> :
                    <MockCardApp vaultInfo={{ ...item, chainId, key: index, isDeprecated, storeTVL, storeWeeklyApr }} />}
                </div>
              );
            })}
        </div>
      </TransparentCard>
    </div>
  );
};

export default Dashboard;

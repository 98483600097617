import { Button, Input, Label, Textarea } from "components/lib";
import React, { useRef, useState } from "react";
import { uploadFileAndGetHash } from "utils";

const getPlaceholders = (appType: string) => {
  if (appType === "apps") {
    return {
      title: 'App',
      name: "Name - Eg. Bollinger Bands, Atleast 4 character long.",
      description:
        "Description Eg. Yield generating app that use multi position to have better liquidity spread.",
      optionalText: (
        <a
          className="capitalize text-white-500 hover:underline"
          rel="noreferrer"
          href={"https://docs.steer.finance/steer-apps/writing-an-app"}
          target={"_blank"}
        >
          {"how to create an app?"}
        </a>
      ),
    };
  }

  if (appType === "dataConnector") {
    return {
      title: 'Data Connector',
      name: "Name - Eg. Uniswap Data Connector",
      description:
        "Description Eg. Data connector that transform data for open, high, low ,close and volume in json object.",
      optionalText: (
        <a
          className="capitalize text-white-500 hover:underline"
          rel="noreferrer"
          href={
            "https://docs.steer.finance/data-connectors/writing-a-data-connector"
          }
          target={"_blank"}
        >
          {"Learn how to create an data connector?"}
        </a>
      ),
    };
  }

  if (appType === "jobs") {
    return {
      title: 'Job',
      name: "Name - Weekly Mint",
      description:
        "Description Eg. This job weekly mint the NFT from an open market place contracts with few custom fields.",
      optionalText: null,
    };
  }
};

const CommonAppConfig = ({
  setData,
  data,
  appType,
  isDisabled
}: {
  data: any;
  setData: any;
  appType: "apps" | "dataConnector" | "jobs";
  isDisabled?: boolean;
}) => {
  const inputRef = useRef(null);
  const [appImagePreview, setAppImagePreview] = useState(data?.appImgUrl);
  const onClickHandler = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const imageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = async () => {
      setAppImagePreview(reader.result);
      const hash = await uploadFileAndGetHash(file);
      _setData('appImgUrl', `https://steerprotocol.mypinata.cloud/ipfs/${hash}`);
    };
    
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const placeholder = getPlaceholders(appType);

  const _setData = (key, val) => {
    let obj = data;
    obj[key] = val;
    setData(obj);
  }

  const renderImageUpload = () => {
    return (
      <div className={`w-min-60`}>
        <Label
          content={`Icon`}
          size={"large"}
          casing={"capitalize"}
          color={"white-500"}
        />
        <label className="relative flex flex-col p-2 mb-4 rounded-full cursor-pointer">
          <img
            className={`${
              data?.appImgUrl || appImagePreview
                ? "relative w-48 h-36 overflow-hidden rounded-full"
                : "hidden"
            }`}
            src={data?.appImgUrl || appImagePreview}
            alt={"app icon"}
          />
          <input
            className={`opacity-0 w-0 h-0`}
            id="photo-upload"
            type="file"
            ref={inputRef}
            onChange={imageUpload}
            disabled={isDisabled}
            accept="image/png, image/jpeg"
          />
        </label>
        {!data?.appImgUrl && !appImagePreview && (
          <Button
            content={`Upload Icon`}
            type="tertiary"
            onClickHandler={onClickHandler}
            size={"xsmall"}
            disabled={isDisabled}
            casing={'capitalize'}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <div className={`mt-4 w-full`}>
        <Input
          heading={`Name`}
          val={data?.name}
          onChange={(val) => _setData('name', val)}
          align={"vertical"}
          placeholder={placeholder.name}
          optionalText={placeholder.optionalText}
          disabled={isDisabled}
          backgroundType="white"
        />
      </div>
      <div className="flex flex-col mt-4 lg:flex-row">
        {renderImageUpload()}
        <div className={`w-full ml-0 lg:ml-6 lg:mt-0 mt-4`}>
          <Textarea
            heading={`Description`}
            headingColor={"white-500"}
            val={data?.description}
            onChange={(val) => _setData('description', val)}
            align={"vertical"}
            placeholder={placeholder.description}
            disabled={isDisabled}
            backgroundType="white"
          />
        </div>
      </div>
    </div>
  );
};

export default CommonAppConfig;

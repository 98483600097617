"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchPairs = exports.getCalls = void 0;
const constants_1 = require("../../constants");
const types_1 = require("../../types");
const pair_1 = require("../pair");
const index_1 = require("./index");
const getCalls = (chainId) => {
    var _a;
    const allPairs = [];
    const maintenanceMargins = [];
    const totalHedgeAmounts = [];
    const limitHAHedges = [];
    const targetHAHedges = [];
    const lockTimes = [];
    const rates = [];
    const collateralMaps = [];
    for (const stableAddr in constants_1.ALL_TOKENS[types_1.AssetType.STABLE]) {
        const stable = constants_1.ALL_TOKENS[types_1.AssetType.STABLE][stableAddr];
        const stableMasterAddr = (_a = (0, constants_1.registry)(chainId, { stablecoin: stable.symbol })) === null || _a === void 0 ? void 0 : _a.StableMaster;
        for (const collatAddr in constants_1.ALL_TOKENS[types_1.AssetType.COLLATERAL]) {
            const collateral = constants_1.ALL_TOKENS[types_1.AssetType.COLLATERAL][collatAddr];
            const collateralAddresses = (0, constants_1.registry)(chainId, { stablecoin: stable.symbol, collateral: collateral.symbol });
            const perpetualManagerAddr = collateralAddresses === null || collateralAddresses === void 0 ? void 0 : collateralAddresses.PerpetualManager;
            const poolManagerAddr = collateralAddresses === null || collateralAddresses === void 0 ? void 0 : collateralAddresses.PoolManager;
            const oracleAddr = collateralAddresses === null || collateralAddresses === void 0 ? void 0 : collateralAddresses.Oracle;
            if (perpetualManagerAddr && oracleAddr) {
                allPairs.push({ stable: stable, collateral: collateral });
                const Perpetual_Manager_Interface = constants_1.PerpetualManagerFront__factory.createInterface();
                maintenanceMargins.push((0, index_1.addCall)(Perpetual_Manager_Interface, perpetualManagerAddr, Perpetual_Manager_Interface.functions['maintenanceMargin()'].name));
                totalHedgeAmounts.push((0, index_1.addCall)(Perpetual_Manager_Interface, perpetualManagerAddr, Perpetual_Manager_Interface.functions['totalHedgeAmount()'].name));
                limitHAHedges.push((0, index_1.addCall)(Perpetual_Manager_Interface, perpetualManagerAddr, Perpetual_Manager_Interface.functions['limitHAHedge()'].name));
                targetHAHedges.push((0, index_1.addCall)(Perpetual_Manager_Interface, perpetualManagerAddr, Perpetual_Manager_Interface.functions['targetHAHedge()'].name));
                lockTimes.push((0, index_1.addCall)(Perpetual_Manager_Interface, perpetualManagerAddr, Perpetual_Manager_Interface.functions['lockTime()'].name));
                rates.push((0, index_1.addCall)(constants_1.Oracle__factory.createInterface(), oracleAddr, constants_1.Oracle__factory.createInterface().functions['readAll()'].name));
                if (!!stableMasterAddr) {
                    collateralMaps.push((0, index_1.addCall)(constants_1.StableMasterFront__factory.createInterface(), stableMasterAddr, constants_1.StableMasterFront__factory.createInterface().functions['collateralMap(address)'].name, [poolManagerAddr]));
                }
                else {
                    console.error('Non existent StableMaster');
                }
            }
        }
    }
    return {
        allPairs,
        calls: encodeCalls(allPairs, maintenanceMargins, totalHedgeAmounts, limitHAHedges, targetHAHedges, lockTimes, rates, collateralMaps),
    };
};
exports.getCalls = getCalls;
const encodeCalls = (pairs, maintenanceMargins, totalHedgeAmounts, limitHAHedges, targetHAHedges, lockTimes, rates, collateralMaps) => {
    const calls = [];
    for (let i = 0; i < pairs.length; i++) {
        calls.push(maintenanceMargins[i]);
        calls.push(totalHedgeAmounts[i]);
        calls.push(limitHAHedges[i]);
        calls.push(targetHAHedges[i]);
        calls.push(lockTimes[i]);
        calls.push(rates[i]);
        calls.push(collateralMaps[i]);
    }
    return calls;
};
const decodeResults = (pairs, results) => {
    const decoded = [];
    const ratio = results.length / pairs.length;
    for (let i = 0; i < pairs.length; i++) {
        decoded.push({
            maintenanceMargin: results[i * ratio],
            totalHedgeAmount: results[1 + i * ratio],
            limitHAHedge: results[2 + i * ratio],
            targetHAHedge: results[3 + i * ratio],
            lockTime: results[4 + i * ratio],
            rate: results[5 + i * ratio],
            collateralMap: results[6 + i * ratio],
        });
    }
    return decoded;
};
const fetchPairs = (calls, pairs, provider, chainId) => __awaiter(void 0, void 0, void 0, function* () {
    console.time('fetchPairs');
    const results = yield (0, index_1.execMulticall)(calls, provider, chainId);
    const decoded = decodeResults(pairs, results);
    console.timeEnd('fetchPairs');
    return pairs.map((pair, i) => {
        const result = decoded[i];
        return new pair_1.Pair(pair.stable, pair.collateral, result.maintenanceMargin[0], result.totalHedgeAmount[0], result.limitHAHedge[0], result.targetHAHedge[0], result.lockTime[0], result.rate[0], result.collateralMap.stocksUsers);
    });
});
exports.fetchPairs = fetchPairs;

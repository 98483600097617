import { FunctionComponent, useState } from "react";
import {
  Badge,
  Button,
  Input,
} from "components/lib";
import {
  useContractFunction,
  useEthers,
  useTokenBalance,
} from "@usedapp/core";
import { BigNumber, utils } from "ethers";
import ModalHeader from "components/ModalHeader";
import { Contract } from "@ethersproject/contracts";
import { useChainId } from "hooks/useChainId";
import {getVaultTokensFromLptoken, isValidNumber, getContractByNetwork, logEventInSentry, beaconContractNameMapper} from 'utils';

export interface WithdrawProps {
  vault: Object;
  closeModal: () => void;
}

const Withdraw: FunctionComponent<WithdrawProps> = (props) => {
  const {vault} = props;
  const { account } = useEthers();
  const [chainId] = useChainId();
  let userLPTokenBalance = useTokenBalance(props.vault["id"], account);
  const [isInvalidWithdrawInput, setIsInvalidWithdrawInput] = useState(false);
  const [token0WithdrawInput, setToken0WithdrawInput] = useState("");
  const [token1WithdrawInput, setToken1WithdrawInput] = useState("");
  const [LPWithdrawInput, setLPWithdrawInput] = useState("");
  const lpTokenDecimals = vault['lpTokenDecimals'] !== 'No Data' ? vault['lpTokenDecimals'] : 18;

  if (!userLPTokenBalance) {
    userLPTokenBalance = BigNumber.from('0');
  }
  
  const etherToNumber = (inputValue, lpTokenDecimals) => {
    // return inputValue.toString();
    return utils.formatUnits(inputValue, lpTokenDecimals);
  }

  const handleWithdrawInput = (withDrawInputVal) => {
    if (withDrawInputVal.length === 0 ) {
      withDrawInputVal = '0';
    }
    const withDrawValue = utils.parseUnits(withDrawInputVal, lpTokenDecimals);    
    if (userLPTokenBalance.gte(withDrawValue) && isValidNumber(withDrawInputVal) && !vault['lpTokenTotalSupply'].isZero()) {
      // checks if user has enough LPToken to withdraw
      setIsInvalidWithdrawInput(false); // validates the input
      setLPWithdrawInput(withDrawInputVal); // sets the deposit input
      // convert current integration to token decimal points
      const {token0Val, token1Val } = getVaultTokensFromLptoken(
        withDrawInputVal,
        vault['lpTokenTotalSupply'],
        vault['getBalance1'],
        vault['getBalance0'],
        lpTokenDecimals
      )

      setToken0WithdrawInput(etherToNumber(token0Val, vault['token0Decimals']));
      setToken1WithdrawInput(etherToNumber(token1Val, vault['token1Decimals']));
    } else {
      setIsInvalidWithdrawInput(true);
    }
  };
  const VaultContract = getContractByNetwork(chainId, vault['vaultType'] ? beaconContractNameMapper[vault['vaultType']] : '');
  const vaultInterface = new utils.Interface(VaultContract.abi);
  const vaultContract = new Contract(props.vault["id"], vaultInterface);
  const {send: withdraw } = useContractFunction(
    //@ts-ignore
    vaultContract,
    "withdraw",
    { transactionName: `Withdrawal of ${LPWithdrawInput} from strategy ${vault['name']} from asset ${vault['asset']}` }
  );

  const handleWithdraw = async () => {
    try {
      logEventInSentry('withdraw', {
        vaultId: vault["id"],
        LPWithdrawInput,
        lpTokenDecimals,// Input
        account,
        date: new Date()
      });
      withdraw(
        utils.parseUnits(LPWithdrawInput, lpTokenDecimals), // Input
        BigNumber.from(0), //Min amount 0
        BigNumber.from(0), //Min amount 1
        account // Address of the user
      );
      props.closeModal();
    } catch (error) {
      console.log("error", error);
      // props.showError("withdraw failed", error);
    }
  };

  const maxBtnClickHandler = () => {
    if(userLPTokenBalance.gt(0)) {
      handleWithdrawInput(utils.formatUnits(userLPTokenBalance, lpTokenDecimals))
    } else {
      return;
    }
    
  }

  const renderDisableInput = (type) => (
    <div className={``}>
      <Input
        heading={props.vault[type === 0 ? "token0Symbol" : "token1Symbol"]}
        type={`text`}
        disabled={true}
        placeholder={type === 0 ? token0WithdrawInput : token1WithdrawInput}
        align={`vertical`}
        onChange={() => {}}
        val={""}
      />
    </div>
  );

  return (
    <div className={``}>
      <ModalHeader
        closeModal={props.closeModal}
        description={(props.vault["name"] + " " + props.vault["asset"]).toUpperCase()}
        title={`Withdraw ${props.vault["name"]}`}
      />
      <div className="p-4">
        <Input
          maxButton={<Badge
            content={`max`}
            size={`xsmall`}
            bgColor={`green-200 p-1`}
            onClick={() => maxBtnClickHandler()}
          />}
          heading={`Liqudity Token: ${props.vault["lpTokenSymbol"]}`}
          type={`number`}
          optionalText={`Balance ${parseFloat(utils.formatUnits(
            userLPTokenBalance || "0",
            lpTokenDecimals)).toFixed(4)}`}
          placeholder={`0.0`}
          align={`vertical`}
          val={LPWithdrawInput}
          haserror={isInvalidWithdrawInput}
          onChange={handleWithdrawInput}
        />
      </div>
      <div className={`grid grid-rows-2 gap-5 p-4`}>
        {renderDisableInput(0)}
        {renderDisableInput(1)}
      </div>
      <div className={`col-span-1 w-full place-self-end p-4`}>
        <Button
          onClickHandler={handleWithdraw}
          disabled={isInvalidWithdrawInput || LPWithdrawInput.length === 0 || userLPTokenBalance.eq(0)}
          content={
            !isInvalidWithdrawInput
              ? "Withdraw"
              : "Insufficient LPToken Balance"
          }
          casing={`uppercase`}
          type={`tertiary`}
          fullWidth={true}
          size="small"
        />
      </div>
    </div>
  );
};

export default Withdraw;

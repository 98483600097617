//@ts-nocheck
import React from "react";
import {Icon, Title} from 'components/lib';

const ModalHeader = ({
	closeModal,
  title,
  description,
}: {closeModal?: () => void, title: string, description: string}) => {
  return (
    <>
      <div className="absolute top-5 right-7 text-white-100 hover:cursor-pointer">
        {closeModal && <Icon
          name={`faTimes`}
          className={`text-san-marino-50 `}
          size={`1x`}
          color={"bg-white-100"}
          onClickHandler={closeModal}
        />}
      </div>

      <div className="p-1">
        <Title
          content={title}
          align={`left`}
          casing={`uppercase`}
          size={`base`}
        />
        <div
          className={`border-0 border-t border-solid border-lynch-rgba shadow-xs h-0  mt-2.5`}
        />
        {description && <p className={`text-sm text-san-marino-50 mt-2 `}>
          {description}
        </p>}
      </div>
    </>
  );
};

export default ModalHeader;

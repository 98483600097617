import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg
      width={34}
      height={34}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 17.75l4.5 4.5 7.5-9"
        stroke="#52E5A5"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 32c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C8.716 2 2 8.716 2 17c0 8.284 6.716 15 15 15z"
        stroke="#52E5A5"
        strokeWidth={3}
      />
    </svg>
  )
}

export default SvgComponent

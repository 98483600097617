import { FunctionComponent, useContext, useEffect, useState } from "react";
import { AppDetailsCard, Table } from "components/lib";
import { gql, useLazyQuery } from "@apollo/client";
import { VaultDetailsRow } from "components/customRows/VaultDetailsRow";
import { useHistory } from "react-router-dom";
import { useEthers } from "@usedapp/core";
import { DeprecatedBundlesContext } from "context/DeprecatedBundles";
import { beaconNameMapper } from "utils";
import { useChainId } from "hooks/useChainId";

export const columns = [
  {
    Header: "App Engine",
    accessor: "beaconName",
    filterable: true,
    sortable: true,
    type: "text",
    searchable: true,
    casing: "capitalize",
  },
  {
    Header: "Asset",
    accessor: "asset",
    filterable: true,
    sortable: true,
    type: "coinText",
    searchable: true,
    casing: "capitalize",
  },
  {
    Header: "TVL",
    accessor: "tvl",
    filterable: false,
    sortable: true,
    type: "multiLineText",
    searchable: true,
    casing: "capitalize",
  },
  {
    Header: "Fee APR",
    accessor: "annualFeeARR",
    filterable: false,
    sortable: true,
    type: "stats",
    searchable: true,
    casing: "capitalize",
  },
  {
    Header: "Explore",
    accessor: "state",
    filterable: false,
    sortable: false,
    type: "button",
    searchable: false,
  },
];
export interface AppTableProps {
  vaultInfo: object;
  graphClient: any;
}

const GET_VAULTS = gql`
query getVaults($where: Vault_filter!) {
  vaults(where: $where, first: 1000, orderBy: weeklyFeeAPR
    orderDirection: desc) {
      id
      annualFeeARR
      weeklyFeeAPR
      strategyToken {
        id
        name
      }
      token0
      token1
      token0Symbol
      token1Symbol
      token0Balance
      token1Balance
      token0Decimals
      token1Decimals
      beaconName
    }
  }
`;

export const SimilarAppsTable: FunctionComponent<AppTableProps> = (props) => {
  const { deprecatedBundles } = useContext(DeprecatedBundlesContext);
  let deprecatedBundlesIds = (deprecatedBundles || []).filter((bundle) => bundle["type"] === "VAULT").map(d => d["id"]);
  deprecatedBundlesIds = deprecatedBundlesIds.length === 0 ? [""] : deprecatedBundlesIds;
  
  let deprecatedBundlesStrategyIds = (deprecatedBundles || []).filter((bundle) => bundle["type"] === "STRATEGY").map(d => d["id"]);
  deprecatedBundlesStrategyIds = deprecatedBundlesStrategyIds.length === 0 ? [""] : deprecatedBundlesStrategyIds;
  
  const [getVaults, vaultData] = useLazyQuery(GET_VAULTS, {
    client: props.graphClient,
  });
  const [gqlCall, setGqlCall] = useState(false);
  const history = useHistory();
  const { account } = useEthers();

const [chainId] = useChainId();

  const actionBtns = [
    {
      type: "primary" as "primary" | "secondary" | "tertiary",
      text: "Explore",
      onClickHandler: (data) => {
        history.push({
          pathname: `/app/${data.tokenId}/vault/${data.id}?engine=${data.beaconName}&chainId=${chainId}`,
        });
        window.location.reload();
      },
      casing: "capitalize" as "capitalize" | "lowercase",
    },
  ];

  useEffect(() => {
    let notUpdateState = false;
    if (!gqlCall && !notUpdateState && props.vaultInfo) {
      getVaults({
        variables: {
          where: {
            token0: props.vaultInfo["token0"].toLowerCase(),
            token1: props.vaultInfo["token1"].toLowerCase(),
            strategyToken_not_in: deprecatedBundlesStrategyIds,
            id_not_in: [props.vaultInfo["id"].toLowerCase()].concat(deprecatedBundlesIds),
            gasUsed_gt: 0
          }
        },
      });
      setGqlCall(true);
    }
    return () => {
      notUpdateState = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gqlCall]);

  const getVaultData = () => {
    return vaultData?.data?.vaults
      ? vaultData?.data?.vaults.map((data) => {
          return {
            id: data.id,
          };
        })
      : [];
  };

  const getVaultPageData = () => {
    return vaultData?.data?.vaults
      ? vaultData?.data?.vaults.map((data) => {
          const vaultData = { ...data };
          vaultData.vaultAddress = data.id;
          vaultData.weeklyFeeAPR = parseFloat(data?.weeklyFeeAPR).toFixed(2) || 0;
          vaultData['beaconName'] = beaconNameMapper[data["beaconName"]];

          return {
            id: data.id,
            component: (
              <VaultDetailsRow
                vaultInfo={vaultData}
                columns={columns}
                actionBtns={actionBtns}
                account={account}
                chainId={chainId}
                strategy={data.strategyToken}
              />
            ),
          };
        })
      : [];
  };
  return (
    <>
      {vaultData?.data?.vaults && !vaultData.loading && vaultData?.data?.vaults.length > 0 && (
        <AppDetailsCard
        title="More Apps"
        description="Below table shows more apps with same pools to choose from. Having a variety of options for asset pairs can help Lp's take advantage of different market conditions and maximize their profits"
        content={
          <div className="mt-4">
            <Table
              //@ts-ignore
              columns={columns}
              data={getVaultData()}
              rowExpandable={false}
              showPagination={false}
              hasCustomRow={true}
              customRows={getVaultPageData()}
              showSearchBar={false}
            />
          </div>
        }
        />
      )}
    </>
  );
};

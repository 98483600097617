"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MulticallWithFailure__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [],
        name: "SubcallFailed",
        type: "error",
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "target",
                        type: "address",
                    },
                    {
                        internalType: "bytes",
                        name: "data",
                        type: "bytes",
                    },
                    {
                        internalType: "bool",
                        name: "canFail",
                        type: "bool",
                    },
                ],
                internalType: "struct MultiCallWithFailure.Call[]",
                name: "calls",
                type: "tuple[]",
            },
        ],
        name: "multiCall",
        outputs: [
            {
                internalType: "bytes[]",
                name: "",
                type: "bytes[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
class MulticallWithFailure__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.MulticallWithFailure__factory = MulticallWithFailure__factory;
MulticallWithFailure__factory.abi = _abi;

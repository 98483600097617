import { BigNumber, utils } from "ethers";
import { useCoinPrice } from "hooks/useCoinPrice";
// import { useEvmosTempById } from "hooks/useEvmosTemp";
import { useEffect, useState } from "react";
import { getVaultTokensFromLptokenDirect } from "utils";
import { useStakePoolVault } from './useStakePoolVault';


export const useTokenPrice = (token0: string) => {
  const {data: usdPrices} = useCoinPrice(token0, token0);
  return usdPrices[token0];
}



export const useLpTokenInT0AndT1 = (lpTokenInput: string|null, vaultId: string) => {
  console.log({vaultId})
  let { data: stakeVaultData } = useStakePoolVault(vaultId ? [vaultId]: []);
  // const evmosVaultData = useEvmosTempById(vaultId);
  // if(evmosVaultData) {
  //   stakeVaultData = [evmosVaultData];
  // }
  const [lpTokenBreakup, setlpTokenBreakup] = useState(null);

  const token0 = stakeVaultData && stakeVaultData.length > 0 ? stakeVaultData[0].token0.toLowerCase() : null;
  const token1 = stakeVaultData && stakeVaultData.length > 0 ? stakeVaultData[0].token1.toLowerCase() : null;
  
  const {data: usdPrices} = useCoinPrice(token0, token1);
  useEffect(() => {

    if ((!lpTokenBreakup && lpTokenInput && stakeVaultData && stakeVaultData.length > 0 && usdPrices && Object.keys(usdPrices).length > 0) || ( lpTokenBreakup && lpTokenInput !== lpTokenBreakup.lpTokenInput)) {
        const vault = stakeVaultData[0];
       
        const {token1Val, token0Val} = getVaultTokensFromLptokenDirect(
            lpTokenInput.toString(),
            BigNumber.from(vault.totalLPTokensIssued),
            BigNumber.from(vault.token1Balance),
            BigNumber.from(vault.token0Balance),
            BigNumber.from(vault.decimals),
        )
        const token0Eth = utils.formatUnits(token0Val, vault.token0Decimals);
        const token1Eth = utils.formatUnits(token1Val, vault.token1Decimals);

        const token0Usd = parseFloat(token0Eth) * (usdPrices[token0]);
        const token1Usd = parseFloat(token1Eth) * (usdPrices[token1]);

        const tvl = token0Usd + token1Usd;

        setlpTokenBreakup({
            token0Eth,
            token1Eth,
            token0Usd: token0Usd.toFixed(8),
            token1Usd: token1Usd.toFixed(8),
            tvl: tvl.toFixed(8),
            lpTokenInput
        })

    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usdPrices, stakeVaultData, lpTokenInput])
  

  return lpTokenBreakup;
};

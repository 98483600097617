import { Badge, Button, Icon, JsonForm, Label } from "components/lib";
import { ResetContext } from "pages/apps/publish-pool";
import { useContext, useEffect, useRef, useState } from "react";
import { getIpfsBundleConfig } from "utils";
import {
  DataConnectorConfig,
  DataConnectorSchemaConfig,
  DataConnectorUpdateConfig,
  ExecutionBundleParameters,
  Pool,
  Strategy,
} from "utils/types";
import { getHashForStrategy, isDataConnectorHashCandles, quickModeSpreadOptions, updateDataConnectorConfigs } from "../../helper";
import DataConnectorConfigForm from "./dataConnectorConfig";

type StrategyConfigFormProps = {
  selectedStrategy: Strategy;
  selectedPool: Pool;
  onConfigSubmit: (config: any) => void;
  executionConfigs: ExecutionBundleParameters;
  handleBack: () => void;
  resetDefaults: () => void;
  setIsModalOpen: (status: boolean) => void;
  mode: string;
  schemaConfigs: DataConnectorSchemaConfig[];
  dataConnectorConfigs: DataConnectorUpdateConfig[];
  onDataConnectorConfigChange: (configs: DataConnectorUpdateConfig[] ) => void;
};

const StrategyConfigForm = ({
  selectedStrategy,
  selectedPool,
  executionConfigs,
  onConfigSubmit,
  resetDefaults,
  mode,
  schemaConfigs,
  dataConnectorConfigs,
  onDataConnectorConfigChange
}: StrategyConfigFormProps) => {
  const [configValues, setConfigValues] = useState({});
  const [hasError, setHasError] = useState(null);
  const [configJSON, setConfigJSON] = useState({});
  const [dataConfigUpdate, setDataConfigUpdate] = useState<DataConnectorConfig>(null);

  const [isLoading, setIsLoading] = useState(true);
  const { reset, handleChildReset } = useContext(ResetContext);
  const submitFormRef = useRef(null);
  const [msg, setMsg] = useState(
    quickModeSpreadOptions.find(d => d.val === selectedStrategy?.executionBundle?.parameters?.spreadPercentage)?.details
  );

  const updateDefaultState = () => {
    const parameters = {
      ...selectedStrategy.executionBundle.parameters,
      poolFee: Number(selectedPool.feeTier),
      ...executionConfigs,
    };

    const newConfigValues = Object.entries(parameters).reduce(
      (acc, [key, value]) => {
        acc[key] = value;
        return acc;
      },
      {}
    );
    setConfigValues(newConfigValues);
  };

  useEffect(() => {
    (async () => {
      const ipfsData = await getIpfsBundleConfig(
        getHashForStrategy(selectedStrategy, selectedPool)
      );
      if (ipfsData.hasError) {
        setHasError(ipfsData.msg);
      } else {
        const oldProperties = ipfsData.data?.properties;
        let obj = {};
        const propertiesValueArray = configValues
          ? Object.keys(configValues)
          : [];
        if (oldProperties) {
          Object.keys(oldProperties).map((d) => {
            obj[d] = oldProperties[d];
            if (propertiesValueArray.indexOf(d) !== -1) {
              obj[d] = {
                ...obj[d],
                default: configValues[d],
              };
            }
            return obj;
          });
        }
        Object.keys(obj).map(d => {
          obj[d] = {
            ...obj[d],
            hidden: d.toLowerCase().indexOf('fee') !== -1
          };
          return null;
        });

        const ipfsNewData = {
          ...ipfsData.data,
          properties: obj,
        };
        setConfigJSON(ipfsNewData);
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedStrategy) {
      updateDefaultState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStrategy]);

  const updateConfigValues = (changedValues) => {
    setConfigValues({
      ...configValues,
      ...changedValues,
    });
  };

  useEffect(() => {
    if (reset) {
      updateDefaultState();
      handleChildReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);


  const handleSubmit = () => {
    const updatedConfigs: DataConnectorUpdateConfig [] =
    updateDataConnectorConfigs(dataConfigUpdate, schemaConfigs, dataConnectorConfigs)
    // event.preventDefault();
    onConfigSubmit({ ...configValues });
    onDataConnectorConfigChange(updatedConfigs);
  };

  const handleDataConnectorConfig = (dataConfig: DataConnectorConfig) => {
  
    setDataConfigUpdate(dataConfig);
  };

  const _setVal = (data) => {
    onConfigSubmit({
      ...configValues,
      spreadPercentage: data.val
    });

    setMsg(data.details);
  }

  const isQuickMode = mode === 'Quick & Easy Mode';

  const hasProperties = Object.keys(configJSON["properties"] || {}).length > 0;

  return (
    <div className="space-y-2">
      <div className="text-md font-bold mb-2 text-white-500">
        Strategy Configuration
      </div>
      {hasError && !isQuickMode && (
        <div className="flex justify-center text-lg leading-6 text-red-500">
          {hasError}
        </div>
      )}
      {isLoading && !isQuickMode && (
        <div className="flex justify-center text-lg text-white-500">
          <Icon spin={true} name="faSpinner" />
        </div>
      )}
      {!isLoading && hasProperties && !isQuickMode && (
        <JsonForm
          schema={configJSON}
          onSubmit={() => { }}
          ref={submitFormRef}
          //@ts-ignore
          setFormData={(data) => {
            updateConfigValues(data);
          }}
          formData={configValues}
          type={'app'}
        />
      )}
      {!isLoading && !hasProperties && !isQuickMode && (
        <div className="flex justify-center text-lg leading-6 text-white-500">
          {"No Config Parameters"}
        </div>
      )}
      {isQuickMode && <div className="flex-col my-2">
        <div className="">
          <Label
            content={'Position price spread from current price'}
            size={"medium"}
            casing={'capitalize'}
            color={'white-500'}
            tooltipContent={'The percentage spead of position prioces from up and down from the current price'}
          />
        </div>
        <div className="flex my-4">
          {quickModeSpreadOptions.map((d, i) => (
            <div className={i === 0 ? "mr-2" : "mx-2"} key={d.spread}>
              <Badge
                onClick={() => _setVal(d)}
                content={d.spread}
                size={"xsmall"}
              />

            </div>
          ))}

        </div>
      </div>
      }
      {msg && <span className="py-2 text-white-500">{msg}</span>}
      {/* {!hasError && (
        <div className="flex justify-center text-lg leading-6 deployStrategy text-white-500">
          {"Error on fetching configurations"}
        </div>
      )} */}
      {isDataConnectorHashCandles(selectedStrategy, schemaConfigs) && <DataConnectorConfigForm
        onSubmit={handleDataConnectorConfig}
        selectedStrategy={selectedStrategy}
      />}
      {!isQuickMode && <div className="flex justify-between py-4">

        <Button
          content="Reset Config Change"
          type="tertiary"
          size="xsmall"
          fullWidth={true}
          onClickHandler={resetDefaults}
          casing={'capitalize'}
        />
        <Button
          content="Save Config Change"
          type="tertiary"
          size="xsmall"
          fullWidth={true}
          onClickHandler={handleSubmit}
          casing={'capitalize'}
        />
      </div>}
    </div>
  );
};

export default StrategyConfigForm;

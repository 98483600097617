import { Chain, Connector, ConnectorEvent, ConnectorUpdateData } from '@usedapp/core'
import EthereumProvider from "@walletconnect/ethereum-provider"
import { EthereumProviderOptions, EthereumRpcMap } from '@walletconnect/ethereum-provider/dist/types/EthereumProvider'
import { providers } from 'ethers'

interface WalletConnectV2ConnectorOptions {
  projectId: string,
  chains: Chain[],
  rpcMap?: Record<string, string>,
  checkGnosisSafe?: boolean,
  optionalChains: number[]
}

declare type ArrayOneOrMore<T> = {
  0: T;
} & Array<T>;

export class WalletConnectV2Connector implements Connector {
  public provider?: providers.Web3Provider
  public readonly name = 'WalletConnectV2'

  readonly update = new ConnectorEvent<ConnectorUpdateData>()

  private ethereumProvider: EthereumProvider | undefined
  private readonly chains: number[]
  private readonly optionalChains: ArrayOneOrMore<number>
  private readonly rpcMap: EthereumRpcMap
  

  constructor(private readonly opts: WalletConnectV2ConnectorOptions) {
    this.chains = opts.chains.map((chain) => chain.chainId)
    // @ts-ignore
    this.optionalChains = opts.optionalChains;

    this.rpcMap = opts.rpcMap;
  }

  private async init() {
    // @ts-ignore
    const options: EthereumProviderOptions = {

      projectId: this.opts.projectId,
      showQrModal: true,
      chains: this.chains,
      // optionalChains: this.optionalChains,
      rpcMap: this.rpcMap,
    }
    this.ethereumProvider = await EthereumProvider.init(options);
  }

  async connectEagerly(): Promise<void> {
    try {
      await this.init()
      if (!this.ethereumProvider) {
        throw new Error('Could not initialize connector')
      }
      const accounts = await this.ethereumProvider.request({ method: "eth_accounts" }) as string[]

      const chainId = await this.ethereumProvider.request({ method: "eth_chainId" }) as any
      this.provider = new providers.Web3Provider(this.ethereumProvider)
      this.update.emit({ chainId: parseInt(chainId), accounts })
    } catch (e) {
      console.debug(e)
    }
  }

  async activate(): Promise<void> {
    try {
      await this.init()
      if (!this.ethereumProvider) {
        throw new Error('Could not initialize connector')
      }
      await this.ethereumProvider.connect({
        chains: this.chains,
      })

      const accounts = await this.ethereumProvider.request({ method: "eth_accounts" }) as string[]

      const chainId = await this.ethereumProvider.request({ method: "eth_chainId" }) as any
      this.provider = new providers.Web3Provider(this.ethereumProvider)
      this.update.emit({ chainId: parseInt(chainId), accounts })
    } catch (e: any) {
      console.log(e)
      throw new Error('Could not activate connector: ' + (e.message ?? ''))
    }
  }

  async deactivate(): Promise<void> {
    this.ethereumProvider?.disconnect()
    this.provider = undefined
  }
}
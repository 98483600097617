import { FunctionComponent, useState, useEffect, useMemo } from "react";
import { AppCard } from "components/lib";
import { getIpfsJSONConfig, beaconNameMapper, getMinifedAddress, getTVL, getTokenURL, getMaxBounds, calculatePositionWidth, frequencyOptions } from "utils";
import { useHistory } from "react-router-dom";
import { useCoinPrice } from "hooks/useCoinPrice";
import { useRewardApr } from "hooks/useRewardApr";
import {useWeeklyApr} from 'hooks/useWeeklyApr';
import {useSlot0} from "hooks/useSlot0";
import { useVaultPositions } from "hooks/useVaultPositions";


export interface AppDetailsProps {
  vaultInfo: object;
  key?: number;
}



const AppDetails: FunctionComponent<AppDetailsProps> = (props) => {
  const history = useHistory();
  const [ipfsConfig, setIpfsConfig] = useState(null);

  const { vaultInfo } = props;
  const payloadHash = vaultInfo["payloadIpfs"];
  const {data} = useCoinPrice(vaultInfo['token0'], vaultInfo['token1']);
  const {weeklyApr} = useWeeklyApr(vaultInfo["id"], vaultInfo["chainId"]);

  const position = useVaultPositions(vaultInfo)
  const slot0 = useSlot0(vaultInfo['pool']);

  const { maxUpperBound: upperTick, maxLowerBound: lowerTick } = useMemo(() => {
    return !!position
      ? getMaxBounds(position)
      : {
          maxUpperBound: 1,
          maxLowerBound: -1,
        }
        //
  }, [position])

  // const inRange = useMemo(() => {
  //   if (position && slot0) {
  //     const currentTick = slot0?.tick || 0;
  //     return isCurrentTickInRange(currentTick, {
  //       maxUpperBound: upperTick,
  //       maxLowerBound: lowerTick,
  //     })
  //   } else {
  //     return null
  //   }
  // }, [slot0, position, upperTick, lowerTick])


  const positionWidthPercent = useMemo(() => {
    return calculatePositionWidth(slot0?.tick || 0, upperTick, lowerTick)
  }, [slot0, upperTick, lowerTick])

  const percentageToMultiplier = (percentage: number): number => {
    // Convert percentage to multiplier
    const multiplier = 1 + percentage / 100
    return multiplier
  }
  

  useEffect(() => {
    (async () => {
      if (!ipfsConfig && payloadHash && payloadHash !== "") {
        const payloadData = await getIpfsJSONConfig(payloadHash);
        setIpfsConfig(payloadData);
      }
    })();
  }, [payloadHash, ipfsConfig]);

  useEffect(() => {
    if(data && Object.keys(data).length > 0 && !('tvl' in vaultInfo)) {
      let tvl:any = getTVL(vaultInfo, data);
      tvl = tvl?.[0]?.split('USD')?.[0]?.trim();
      if(tvl && vaultInfo["storeTVL"] && !('tvl' in vaultInfo)) {
        vaultInfo["storeTVL"](vaultInfo["id"], parseFloat(tvl));
      }
    }
  }, [data, vaultInfo]);

  useEffect(() => {
    if(!('weeklyApr' in vaultInfo)) {
      if(weeklyApr && vaultInfo["storeTVL"] && !('weeklyApr' in vaultInfo)) {
        vaultInfo["storeWeeklyApr"](vaultInfo["id"], weeklyApr && weeklyApr.apr ? weeklyApr.apr: 0);
      }
    }
  }, [weeklyApr, vaultInfo])



  const getDate = (timestamp) => {
    if (timestamp) {
      const date = new Date(timestamp * 1000);
      return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()+1}`;
    }
    return "";
  };

  let rewardApr = useRewardApr(vaultInfo['incentivized']).apr;
  let stakingEnabled = false;

  if (!vaultInfo['incentivized']) {
    if (vaultInfo["merklRewardPool"] && vaultInfo["merklRewardPool"]["incentiveApr"] !== undefined) {
      stakingEnabled = true;
      rewardApr = (vaultInfo["merklRewardPool"]["incentiveAprs"][vaultInfo["id"]]).toFixed(2);
    }
    if (vaultInfo["sushiRewardPool"]) {
      stakingEnabled = true;
      rewardApr = (vaultInfo["sushiRewardPool"]["incentiveApr"]*100).toFixed(2);
    }
  }


  const token0URL = getTokenURL(vaultInfo['token0'], vaultInfo['token0Symbol'], vaultInfo["chainId"], vaultInfo["beaconName"]); 
  const token1URL = getTokenURL(vaultInfo['token1'], vaultInfo['token1Symbol'], vaultInfo["chainId"], vaultInfo["beaconName"]); 
  const appData = {
    id: vaultInfo["id"],
    icon:
      (ipfsConfig && ipfsConfig?.strategyConfigData?.appImgUrl) ||
      "https://steer.finance/wp-content/uploads/2022/11/blockchain-300x300.png",
    name: vaultInfo["strategyToken"]["name"],
    data: {
      isDeprecated: vaultInfo["isDeprecated"],
      fee: {
        type: "text",
        val: `${parseInt(vaultInfo["feeTier"]) / 10000}%`,
        label: "fee tier",
      },
      apr : {
        type: "text",
        val: `~${(parseFloat(weeklyApr ? weeklyApr.apr: "0") + parseFloat(rewardApr || '0')).toFixed(2)}%`,
        label: `Fee APR ${!!vaultInfo["incentivized"] || stakingEnabled ? '✨' : ""}`,
        feeAPR: `~${parseFloat((weeklyApr ? weeklyApr.apr: "0")).toFixed(2)}%`,
        rewardAPR: `~${rewardApr || '0'}%`,
      },
      tvl: {
        type: "multilineText",
        val: getTVL(vaultInfo, data),
        label: "TVL",
      },
      asset: {
        type: "asset",
        val: [token0URL, token1URL],
        label: `${vaultInfo["token0Symbol"]} - ${vaultInfo["token1Symbol"]}/Fee: ${parseInt(vaultInfo["feeTier"]) / 10000}%`,
      },
      creator: {
        type: "link",
        label: "Creator",
        val: {
          onClick: () => console.log("test"),
          text: getMinifedAddress(vaultInfo["deployer"]),
        },
      },
      vaults: {
        type: 'text',
        val: "1",
        label: "Strategies"
      },
      publishedOn: {
        type: "text",
        val: getDate(vaultInfo["createdAt"]),
        label: "Published On",
      },
      interval: {
        type: "text",
        val: frequencyOptions.find(
          (d) =>
            d.value === ipfsConfig?.strategyConfigData?.epochLength
        )?.label || "",

        label: "Interval",
      },
      liquidity: {
        currentTick: slot0?.tick,
        minTick: lowerTick,
        maxTick: upperTick,
        token0Symbol: vaultInfo["token0Symbol"],
        token1Symbol: vaultInfo["token1Symbol"],
        // inRange: inRange === null ? true: inRange,
        positionType: (vaultInfo["strategyToken"]["name"]?.toLowerCase())?.indexOf('stable') !== -1 ? 'Stable': 
          percentageToMultiplier(positionWidthPercent) > 1.20 ? "Wide": "Narrow"
      }
    },
    badgeData: beaconNameMapper[vaultInfo["beaconName"]],
    cardType: "app",
    onClick: () =>
      history.push({
        pathname: `/app/${vaultInfo["strategyToken"]["id"]}/vault/${vaultInfo["id"].toLowerCase()}?engine=${vaultInfo["beaconName"]}&chainId=${vaultInfo["chainId"]}`,
      }),
    incentivized: !!vaultInfo["incentivized"]
  };


  //@ts-ignore
  return <AppCard {...appData} />;
};

export default AppDetails;

import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {Row} from './Row';

export const DraggableTableRow = ({ row }) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging
  } = useSortable({
    id: row.original.id
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition
  };
  return (
    <tr ref={setNodeRef} style={style} {...row.getRowProps()}>
      {isDragging ? (
        <td 
        className="w-full h-8 rounded-xl bg-east-bay-500"
        colSpan={row.cells.length}>&nbsp;</td>
      ) : (
        <Row
        row={row}
        id={row.index}
        rowExpandable={false}
        dragAttributes={attributes}
        dragListner={listeners}
        colWidth={100/row.cells.length}
        
        />
      )}
    </tr>
  );
};

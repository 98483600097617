import { FunctionComponent, useContext } from "react";

import { useEthers } from "@usedapp/core";
import TitleWithDocLink from "components/TitleWithDocLink";
import { TransparentCard } from "components/TransparentCard";
// ABIs

import { StakingPoolsContext } from "context/StakingPools";
import { useChainId } from "hooks/useChainId";
import { MerklContext } from "context/Merkl";
import { useUserDeposits } from "queries/userDeposits";
import { useVaultsByIds } from "queries/useVault";
import UserDetailsCard from "components/customCards/UserDetailsCard";
import { useMerklUserRewards } from "hooks/useMerklUserRewards";
import { utils } from "ethers";
// import { DexContext } from "context/Dex";

// const filterDataBySearch = (data, searchText) => {
//   return data.filter((d) => {
//     return d.strategyToken.name.toLowerCase().indexOf(searchText) !== -1 || 
//     d.token0Symbol.toLowerCase().indexOf(searchText) !== -1 || 
//     d.token1Symbol.toLowerCase().indexOf(searchText) !== -1 ||
//     d.deployer.toLowerCase().indexOf(searchText) !== -1 ||
//     `${ d.token0Symbol.toLowerCase()}-${d.token1Symbol.toLowerCase()}`.indexOf(searchText) !== -1;
//   })
// }


const Dashboard: FunctionComponent = () => {
  const { account } = useEthers();
  const [chainId] = useChainId();
  const {data: userData, isLoading: userDataLoading} = useUserDeposits();
  // const { selectedDex } = useContext(DexContext);
  // console.log({selectedDex})
  const vaultIds = userData ? userData.map(d => d?.vault?.id) : [];
  const {data: vaultData, isLoading} = useVaultsByIds(vaultIds);
  const {merklUserRewards} = useMerklUserRewards(chainId, account);
  const { stakingPools } = useContext(StakingPoolsContext);
  const { merklRewardPools } = useContext(MerklContext);

  const vaultDatas = vaultData ? vaultData
  // .filter(d => d.beaconName.toLowerCase().indexOf(selectedDex) !== -1)
  .map(item => {
  const incentivized = stakingPools && stakingPools.find(d => d["stakingToken"]?.toLowerCase() === item["id"]?.toLowerCase())
  const merklRewardPool = merklRewardPools?.find((pool) => {
    return !!pool['incentiveAprs'][item['id']];
  });
  if (merklRewardPool) {
    merklRewardPool["incentiveApr"] = merklRewardPool['incentiveAprs'][item['id']] || 0;
  }
  return {
    ...item,
    incentivized,
    merklRewardPool: merklRewardPool && merklRewardPool["incentiveApr"]? merklRewardPool : undefined
  }
}): [];

  const noPoolFound = vaultDatas.length === 0;

  return (
    <div
      className={`py-4 m-auto lg:h-full w-10/12 lg:w-9/12`}
    >
      <TransparentCard>
        <TitleWithDocLink
          title="User Dashboard"
        />
        {!account && 
            <div className="flex flex-col items-center justify-center p-3 my-4">
              <div className="text-lg text-white-500 text-center">Connect Your Account! </div>
              <div className="text-sm text-white-500 text-center">To view your deposited smart pools, you need to connect the wallet to the Steer dApp.</div>
            </div>
          }
          
          {account && ((noPoolFound && !isLoading) || (userData && userData.length === 0 && !userDataLoading)) &&  
            <div className="flex flex-col items-center justify-center p-3 my-4">
              <div className="text-sm lg:text-lg text-white-500 text-center">No Deposit On Smart Pool Yet From Current Wallet.</div>
              <div className="text-sm text-white-500 text-center">Checkout out deposits on other chains</div>
            </div>
          }
        <div className="flex flex-wrap justify-evenly">
          {vaultDatas.map((item, index) => {
              const userDeposits = userData.find(d => d.vault.id === item.id);
              const poolAddress = utils.getAddress(item["pool"]);
              const rewardPool = item?.["merklRewardPool"]?.["incentive"]?.["rewardToken"]?.["address"];
              const merklUserReward = merklUserRewards?.["pools"]?.[poolAddress]?.["rewardsPerToken"]?.[rewardPool]?.["unclaimed"];
              return (
                <div className="flex py-4 mx-1" key={item.id}>
                  <UserDetailsCard vaultInfo={{ ...item, merklUserReward, isUserDashboard: true , userDeposits, chainId, key: index, account }} />
                </div>
              );
            })}
        </div>
      </TransparentCard>
    </div>
  );
};

export default Dashboard;

import React from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import {Icon} from 'components/lib';

const CloseButton = ({ closeToast }:any) => (
  <div className="absolute top-4 right-4">
    <Icon
      name={'faTimes'}
      onClickHandler={closeToast}
    />
  </div>
);

const ToastContainerComp = () => {
  return <ToastContainer
    position="bottom-right"
    autoClose={8000}
    hideProgressBar={false}
    newestOnTop={false}
    closeButton={CloseButton}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    toastClassName={() =>
      "relative p-6 justify-between overflow-hidden cursor-pointer "
    }
    bodyClassName={() => "p-0"}
  />;
};

export default ToastContainerComp;

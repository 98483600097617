
import { ChainId, DAppProvider, Polygon, Arbitrum, ThunderCore, Optimism, Andromeda, BSC, Mumbai, MetamaskConnector,Avalanche, Celo, CoinbaseWalletConnector, Config, Mainnet } from "@usedapp/core";
import Container from "components/Container";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Strategies from "pages/apps/dashboard";
import PublishApp from "pages/apps/publish";
import Stake from "pages/stake";
import Automation from "pages/automation/dashboard";
import JobView from "pages/automation/details";
import RegisterAutomation from "pages/automation/register";
import DataMarketplace from "pages/dataMarketplace";
import TestnetLeaderBoard from "pages/testnetLeaderBoard";
import AppDetails from "pages/apps/details";
import { Base, Evmos, ZkEvm, Kava } from "utils";
import PublishPool from "pages/apps/publish-pool";
// import { WalletConnectConnector } from '@usedapp/wallet-connect-connector'
import { WalletConnectV2Connector } from 'utils/walletconnector';
import { QueryClient } from "@tanstack/query-core";
import { QueryClientProvider } from "@tanstack/react-query";
import UserDashboard from 'pages/userDashboard';
// Create a client
const queryClient = new QueryClient()

export const readOnlyUrls = {
  [ChainId.Mainnet]: 'https://eth-mainnet.g.alchemy.com/v2/pCCCcUD3MqwJ-KcX8GJKVsK6IL2TCR4u',
  [ChainId.BSC]: 'https://bsc-mainnet.nodereal.io/v1/73737dfd66c54fc587dffec7dbce4b7f',
  // [ChainId.Goerli]: 'https://eth-goerli.g.alchemy.com/v2/y2KbZA2U81xgFxIg6-GJ_xHdBeweChDv',
  [ChainId.Mumbai]: 'https://polygon-mumbai.gateway.pokt.network/v1/lb/e2e725826773754677c39204',
  [ChainId.Polygon]: 'https://polygon-rpc.com',
  // [ChainId.ArbitrumGoerli]: 'https://arb-goerli.g.alchemy.com/v2/CypigYSfj-Xx5I8MXYVPhrpAqIB8XqB-',
  [ChainId.Arbitrum]: 'https://arbitrum-one.gateway.pokt.network/v1/lb/e2e725826773754677c39204',
  [ChainId.Optimism]: 'https://optimism-mainnet.gateway.pokt.network/v1/lb/e2e725826773754677c39204',
  // [Evmos.chainId]: 'https://evmos-mainnet.blastapi.io/b0dd23af-c229-446f-8b45-450db21e2d68',
  [Evmos.chainId]: 'https://evmos-evm.publicnode.com',
  [ChainId.Andromeda]: 'https://metis-mainnet.gateway.pokt.network/v1/lb/e2e725826773754677c39204',
  [ChainId.Avalanche]: 'https://avax-mainnet.gateway.pokt.network/v1/lb/e2e725826773754677c39204',
  // [ChainId.OptimismGoerli]: 'https://opt-goerli.g.alchemy.com/v2/_F5QV-uw_g1WMa2AWFyRieEL6_k7rfWk'
  [ZkEvm.chainId]: 'https://polygonzkevm-mainnet.g.alchemy.com/v2/VcbyD9Yjek4RMPTcURSajf1ATWkMLUt6',
  [ChainId.Celo]: 'https://celo-mainnet.infura.io/v3/4406092f06794d4792693f76de665e38',
  [ChainId.ThunderCore]: 'https://mainnet-rpc.thundercore.com',
  [Base.chainId]: 'https://mainnet.base.org',
  [Kava.chainId]: 'https://evm2.kava.io/'
}

const App = (): JSX.Element => {
  const config: Config = {
    readOnlyChainId: ChainId.Polygon,
    networks: [ Mumbai, Base, Polygon, Arbitrum, ThunderCore, Optimism, BSC, ZkEvm, Evmos, Celo, Andromeda, Avalanche, Mainnet, Kava],
    noMetamaskDeactivate: true,
    readOnlyUrls: readOnlyUrls,
    connectors: {
      metamask: new MetamaskConnector(),
      coinbase: new CoinbaseWalletConnector(),
      //@ts-ignore
      walletConnectV2_polygon: new WalletConnectV2Connector({
        projectId: "59f890090d621f37ea9fb26fff5b1ccf",
        chains: [Polygon],
        rpcMap: readOnlyUrls,
        optionalChains: [ChainId.Mumbai, Base.chainId, Evmos.chainId, ZkEvm.chainId, ChainId.Arbitrum, ChainId.ThunderCore, ChainId.Optimism, ChainId.BSC, ChainId.Celo, ChainId.Andromeda, ChainId.Avalanche, ChainId.Mainnet]
      }),
      walletConnectV2_evmos: new WalletConnectV2Connector({
        projectId: "59f890090d621f37ea9fb26fff5b1ccf",
        chains: [Evmos],
        rpcMap: {
          '9001': 'https://evmos-evm.publicnode.com'
        },
        optionalChains: [ChainId.Mumbai, ChainId.Polygon, ZkEvm.chainId, ChainId.Arbitrum, ChainId.ThunderCore, ChainId.Optimism, ChainId.BSC, ChainId.Celo, ChainId.Andromeda, ChainId.Avalanche, ChainId.Mainnet]
      }),
      walletConnectV2_kava: new WalletConnectV2Connector({
        projectId: "59f890090d621f37ea9fb26fff5b1ccf",
        chains: [Kava],
        rpcMap: {
          '2222': 'https://kava-mainnet.gateway.pokt.network/v1/lb/e2e725826773754677c39204'
        },
        optionalChains: [ChainId.Mumbai, ChainId.Polygon, ZkEvm.chainId, ChainId.Arbitrum, ChainId.ThunderCore, ChainId.Optimism, ChainId.BSC, ChainId.Celo, ChainId.Andromeda, ChainId.Avalanche, ChainId.Mainnet]
      }),
      walletConnectV2_zkevm: new WalletConnectV2Connector({
        projectId: "59f890090d621f37ea9fb26fff5b1ccf",
        chains: [ZkEvm],
        rpcMap: readOnlyUrls,
        optionalChains: [ChainId.Mumbai, Base.chainId, Evmos.chainId, ChainId.Polygon, ChainId.Arbitrum, ChainId.ThunderCore, ChainId.Optimism, ChainId.BSC, ChainId.Celo, ChainId.Andromeda, ChainId.Avalanche, ChainId.Mainnet]
      }),
      walletConnectV2_mumbai: new WalletConnectV2Connector({
        projectId: "59f890090d621f37ea9fb26fff5b1ccf",
        chains: [Mumbai],
        rpcMap: readOnlyUrls,
        optionalChains: [ChainId.Polygon, Base.chainId, Evmos.chainId, ZkEvm.chainId, ChainId.Arbitrum, ChainId.ThunderCore, ChainId.Optimism, ChainId.BSC, ChainId.Celo, ChainId.Andromeda, ChainId.Avalanche, ChainId.Mainnet]
      }),
      walletConnectV2_arbitrum: new WalletConnectV2Connector({
        projectId: "59f890090d621f37ea9fb26fff5b1ccf",
        chains: [Arbitrum],
        rpcMap: readOnlyUrls,
        optionalChains: [ChainId.Mumbai, Base.chainId, Evmos.chainId, ZkEvm.chainId, ChainId.Polygon, ChainId.ThunderCore, ChainId.Optimism, ChainId.BSC, ChainId.Celo, ChainId.Andromeda, ChainId.Avalanche, ChainId.Mainnet]
      }),
      walletConnectV2_thundercore: new WalletConnectV2Connector({
        projectId: "59f890090d621f37ea9fb26fff5b1ccf",
        chains: [ThunderCore],
        rpcMap: readOnlyUrls,
        optionalChains: [ChainId.Mumbai, Base.chainId, Evmos.chainId, ZkEvm.chainId, ChainId.Arbitrum, ChainId.Polygon, ChainId.Optimism, ChainId.BSC, ChainId.Celo, ChainId.Andromeda, ChainId.Avalanche, ChainId.Mainnet]
      }),
      walletConnectV2_optimism: new WalletConnectV2Connector({
        projectId: "59f890090d621f37ea9fb26fff5b1ccf",
        chains: [Optimism],
        rpcMap: readOnlyUrls,
        optionalChains: [ChainId.Mumbai, Base.chainId, Evmos.chainId, ZkEvm.chainId, ChainId.Arbitrum, ChainId.ThunderCore, ChainId.Polygon, ChainId.BSC, ChainId.Celo, ChainId.Andromeda, ChainId.Avalanche, ChainId.Mainnet]
      }),
      walletConnectV2_bsc: new WalletConnectV2Connector({
        projectId: "59f890090d621f37ea9fb26fff5b1ccf",
        chains: [BSC],
        rpcMap: readOnlyUrls,
        optionalChains: [ChainId.Mumbai, Base.chainId, Evmos.chainId, ZkEvm.chainId, ChainId.Arbitrum, ChainId.ThunderCore, ChainId.Optimism, 
          ChainId.Polygon, ChainId.Celo, ChainId.Andromeda, ChainId.Avalanche, ChainId.Mainnet]
      }),
      walletConnectV2_celo: new WalletConnectV2Connector({
        projectId: "59f890090d621f37ea9fb26fff5b1ccf",
        chains: [Celo],
        rpcMap: readOnlyUrls,
        optionalChains: [ChainId.Mumbai, Base.chainId, Evmos.chainId, ZkEvm.chainId, ChainId.Arbitrum, ChainId.ThunderCore, ChainId.Optimism, ChainId.BSC, ChainId.Polygon, ChainId.Andromeda, ChainId.Avalanche, ChainId.Mainnet]
      }),
      walletConnectV2_andromeda: new WalletConnectV2Connector({
        projectId: "59f890090d621f37ea9fb26fff5b1ccf",
        chains: [Andromeda],
        rpcMap: readOnlyUrls,
        optionalChains: [ChainId.Mumbai, Base.chainId, Evmos.chainId, ZkEvm.chainId, ChainId.Arbitrum, ChainId.ThunderCore, ChainId.Optimism, ChainId.BSC, ChainId.Celo, ChainId.Polygon, ChainId.Avalanche, ChainId.Mainnet]
      }),
      walletConnectV2_avalanche: new WalletConnectV2Connector({
        projectId: "59f890090d621f37ea9fb26fff5b1ccf",
        chains: [Avalanche],
        rpcMap: readOnlyUrls,
        optionalChains: [ChainId.Mumbai, Base.chainId, Evmos.chainId, ZkEvm.chainId, ChainId.Arbitrum, ChainId.ThunderCore, ChainId.Optimism, ChainId.BSC, ChainId.Celo, ChainId.Andromeda, ChainId.Polygon, ChainId.Mainnet]
      }),
      walletConnectV2_mainnet: new WalletConnectV2Connector({
        projectId: "59f890090d621f37ea9fb26fff5b1ccf",
        chains: [Mainnet],
        rpcMap: readOnlyUrls,
        optionalChains: [ChainId.Mumbai, Base.chainId, Evmos.chainId, ZkEvm.chainId, ChainId.Arbitrum, ChainId.ThunderCore, ChainId.Optimism, ChainId.BSC, ChainId.Celo, ChainId.Andromeda, ChainId.Avalanche, ChainId.Polygon]
      }),
      walletConnectV2_base: new WalletConnectV2Connector({
        projectId: "59f890090d621f37ea9fb26fff5b1ccf",
        chains: [Base],
        rpcMap: {
          '8453': readOnlyUrls[Base.chainId]
        },
        optionalChains: [ChainId.Mumbai, Base.chainId, ChainId.Polygon, ZkEvm.chainId, ChainId.Arbitrum, ChainId.ThunderCore, ChainId.Optimism, ChainId.BSC, ChainId.Celo, ChainId.Andromeda, ChainId.Avalanche, ChainId.Mainnet]
      }),
    },
  }
  return (
    <DAppProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Container>
            <Switch>
              <Route path="/app/:appId/vault/:vaultId">
                <AppDetails />
              </Route>
              <Route path="/automation/:id">
                <JobView />
              </Route>
              <Route path="/automation">
                <Automation />
              </Route>

              <Route path="/register-automation">
                <RegisterAutomation />
              </Route>
              <Route path="/publish-app">
                <PublishApp />
              </Route>
              <Route path="/publish-smart-pool">
                <PublishPool />
              </Route>
              <Route path="/stake">
                <Stake />
              </Route>
              <Route path="/data-marketplace">
                <DataMarketplace />
              </Route>
              <Route path="/testnet-leaderboard">
                <TestnetLeaderBoard />
              </Route>
              <Route path="/user-dashboard">
                <UserDashboard />
              </Route>
              <Route path="/">
                <Strategies />
              </Route>
            </Switch>
          </Container>
        </Router>
      </QueryClientProvider>
    </DAppProvider>
  );
};

export default App;


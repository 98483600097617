// import MockAppCard from "components/customCards/MockAppCard";
import PoolSelect from "components/PoolSelect"
// import useTokenPools from "hooks/useTokenPools"

export const SelectPool = ({ selectedPool, handleSelectPool, filterPool, dex, chainId }) => {
	// const {pools} = useTokenPools(" ", dex.selectedDex, 10);
	return (
		<div>
			<div className="text-md text-white-500 capitalize mb-2">Search Pool</div>
			<PoolSelect
				pool={selectedPool}
				handleSelectPool={handleSelectPool}
				dex={dex.selectedDex}
				filterPool={filterPool}
			/>
			{/* {!selectedPool && <div className="text-md text-white-500 capitalize my-4">Choose from Top 10 Pools</div>}
			{!selectedPool && <div className="flex justify-evenly flex-wrap my-4">
				{pools.map((item, index) => <div className="my-2"><MockAppCard vaultInfo={{ ...{
					 id: 'na',
					 pool: item,
					 beaconName: dex.selectedDex,
					 onClick: () => {
						console.log('ere', item)
						handleSelectPool({data: item});
					 }
				}, chainId, key: index }} /></div>)}
			</div>} */}
		</div>
	)
}
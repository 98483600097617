import React, { useEffect, useState } from "react"
import timestring from "timestring"
import Select from "react-select"
import { Input } from "components/lib"

type TimeframeProps = {
  onChange: (seconds: number) => void
  options: string[]
  defaultOption?: string
  heading?: string
  tooltipContent?:string
  defaultSelection?: {
    seconds?: number,
    period?: string
  }
}

export const formatOptionLabel = ({ data }) => {
  return (
    <div className="flex items-center">
      test
    </div>
  );
};

const convertToDuration = (seconds?: number, period?: string): string => {
  if (seconds) {
    return (parseInt((seconds / 60).toString())).toString();
  } 
  if (period) {
    return (parseInt(period.replaceAll('s', '')) / 60).toString();
  }
  return "";
};

export const Timeframe = ({
  onChange,
  options,
  defaultOption,
  heading,
  tooltipContent,
  defaultSelection
}: TimeframeProps) => {
  const newOptions = options.map(d => {
    return {
      label: d,
      value: d
    }
  }) 

  const [isDefaultSet, setIsDefaultSet] = useState(false);

  const [selection, setSelection] = useState(null);
  const [duration, setDuration] = useState('');

  useEffect(() => {

    if (!selection && duration.length === 0) {
      // Set default values
      setSelection(newOptions[0]);
      setDuration(defaultOption || "");
      return;
    }

    const seconds = timestring(`${duration || 0} ${selection.value}`, "s");
    onChange(seconds);

    if (defaultSelection && !isDefaultSet) {
      const timeSelection = 'Minutes';
      const durationValue = convertToDuration(defaultSelection.seconds, defaultSelection.period);

      setSelection({
        label: timeSelection,
        value: timeSelection,
      });

      setDuration(durationValue);
      setIsDefaultSet(true);

    } 
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, selection, defaultSelection]);

  return (
    <div className="">
      <Input
        heading={heading || ''}
        val={duration}
        tooltipContent={tooltipContent}
        onChange={(data) => {
          //@ts-ignore
          setDuration(parseInt(data))
        }}
        align={"vertical"}
        backgroundType="white"
        optionalText={
          <Select
          options={newOptions}
          value={selection}
          onChange={setSelection}
          isSearchable={false}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              padding: "0",
              fontSize: "12px",
              minHeight:"24px"
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              padding: "2px"
            }),
            indicatorSeparator: (styles) => ({ display: "none" }),
            dropdownIndicator: (styles) => ({ ...styles, padding: 0 }),
            menu: (styles) => ({
              ...styles,
              marginTop: 15,
              color: "#000",
            }),
            menuList: (styles) => ({
              ...styles,
              paddingTop: 0,
              paddingBottom: 0,
            }),
          }}
        />
        }
      />
      
    </div>
  )
}

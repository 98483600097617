import { Button, Dropdown, Label } from "components/lib";
import { FunctionComponent, useContext, useEffect, useState } from "react";

import { useEthers } from "@usedapp/core";
import { TransparentCard } from "components/TransparentCard";


import { LabeledProgressBar } from "components/Container";
import TitleWithDocLink from "components/TitleWithDocLink";
import { StakingPoolsContext } from "context/StakingPools";
import { useHistory } from "react-router-dom";
import StakePool from "./stakePool";
import StakePoolNonSteer from "./stakePoolNonSteer";
import { useStakePoolVault } from "queries/useStakePoolVault";

const stakePools = "Stake Pools";

const progressMsg = {
  0: 'Establishing secure connection to fetch staking pools...',
  18: 'Connection established,  fetching staking pools...',
  36: 'Fetching pools details...',
  54: 'Staking pools data fetched...',
  72: 'Transforming data for UI render...',
  90: 'Loading the data...'
};

const Stake: FunctionComponent = () => {
  const { account } = useEthers();
  const history = useHistory();
  const dataPassed = history?.location?.state?.data;
  let { stakingPools, loadingStakingPools } = useContext(StakingPoolsContext);
  const [poolData, setPoolData] = useState([]);
  const [loadingPoolsData, setLoadingPoolsData] = useState(dataPassed ? false : true);

  const [progressPercent, setProgressPercent] = useState(0);

  if (stakingPools && dataPassed) {
    stakingPools = stakingPools.filter(d => d['stakingToken'].toLowerCase() === dataPassed["vaultId"])
  }
  const pools = stakingPools;
  const smartPoolIds = pools ? pools.map(d => d["stakingToken"].toLowerCase()) : [];
  const {data: vaultData} = useStakePoolVault(smartPoolIds);

  useEffect(() => {
    if (pools && pools.length === 0) {
      setLoadingPoolsData(false);
    }
  }, [pools])


  const filters = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Your Staked",
      value: "staked",
    },
    {
      label: "Active",
      value: "active",
    },
  ];

  const [selectedFilter, setSelectedFilter] = useState(filters[0]);

  const _setFilter = (data) => {
    setSelectedFilter(filters.find((d) => d.value === data));
  };

  const updatePoolData = (poolInfo) => {
    // check pools by id and update the array or upsert into it
    const index = poolData.findIndex((item) => item.id === poolInfo.id);
    if (index < 0) {
      poolData.push(poolInfo);
      setPoolData(poolData);
    }
    if(poolData.length === pools.length) {
      setLoadingPoolsData(false);
    }
  }


  const checkFilters = (pool: string) => {
    const poolInfo = poolData.find((item) => item.id === pool);

    if (poolData.length === 0 || selectedFilter.value === "All") {
      return true;
    }

    if (!poolInfo) {
      return false;
    }

    if (selectedFilter.value === "staked") {
      return poolInfo['userStakeBalance'] !== '0';
    } else {
      return poolInfo['isActive'] === 'Active';
    }
  }


  useEffect(() => {
    if (progressPercent <= 72) {
      let progressInterval = setInterval(() => {
        setProgressPercent(progressPercent + 18)
      }, 500);
      return () => {
        clearInterval(progressInterval);
      }
    }
  }, [progressPercent])

  const newPools = pools && pools.length > 0 ?
  pools.filter((pool) => checkFilters(pool["stakingPool"])) : [];
  
  return (
    <div className={`py-4 m-auto lg:h-full w-10/12 lg:w-9/12`}>
      <TransparentCard>

        <TitleWithDocLink
          title={stakePools}
          secondaryElement={
            account && poolData.length > 0 && (
              <div className="flex justify-end mb-3 ">
                <Dropdown
                  selected={selectedFilter?.value}
                  hasIcon={false}
                  type={"filter"}
                  options={filters}
                  onSelectHandler={_setFilter}
                  placeholder={"Filter"}
                  heading={"Show: "}
                />
              </div>
            )
          }
        />

        {(loadingStakingPools) && LabeledProgressBar(progressMsg, progressPercent)}

        {!loadingPoolsData && pools && pools.length === 0 &&
          <div className="flex flex-col items-center justify-center my-4">
            <div className="text-3xl">📝</div>
            <div className="my-4">
              <Label
                content={
                  "No Stake Pool Deployed Currently. Checkout Smart Pools!"
                }
                align={`center`}
                size={`large`}
                color={`white-500`}
              />
              <div className="flex my-4 justify-center">
                <Button
                  onClickHandler={() => {
                    history.push("/");
                  }}
                  content={"View Smart Pool"}
                  casing={`capitalize`}
                  type={`nprimary`}
                  size={"small"}
                />
              </div>
            </div>
          </div>
        }
        <div className="my-2">
          <div className="flex flex-wrap items-center justify-evenly">
            {vaultData && newPools && newPools.map((item) => {
                const smartPoolData = vaultData.find(d => d.id === item["stakingToken"].toLowerCase()); 
                return (
                  <div className="flex py-2" key={item["stakingPool"]}>
                    {item["isSteerVault"] === true ?<StakePool
                      poolDetails={{...item, smartPoolData}}
                      key={item["stakingPool"]}
                      updatePoolData={updatePoolData}
                    />: <StakePoolNonSteer 
                      poolDetails={{...item, smartPoolData}}
                      key={item["stakingPool"]}
                      updatePoolData={updatePoolData}
                    />}
                  </div>
                );
              })}
          </div>
        </div>

      </TransparentCard>
    </div>
  );
};

export default Stake;

import { FunctionComponent, useState, useEffect, useMemo } from "react";
import { AppCard } from "components/lib";
import { getIpfsJSONConfig, beaconNameMapper, getTVL, getTokenURL, getMaxBounds, frequencyOptions, isCurrentTickInRange } from "utils";
import { useHistory } from "react-router-dom";
import { useRewardApr } from "hooks/useRewardApr";
import { useWeeklyApr } from 'hooks/useWeeklyApr';
import { useSlot0 } from "hooks/useSlot0";
import { useVaultPositions } from "hooks/useVaultPositions";
import { useUserData } from "hooks/useUserData";
import { useCoinPrice } from "hooks/useCoinPrice";
import { useVaultGasInfo } from "queries/vaults";


export interface UserDetailsCardProps {
	vaultInfo: object;
	key?: number;
}

const UserDetailsCard: FunctionComponent<UserDetailsCardProps> = (props) => {
	const history = useHistory();
	const { vaultInfo } = props;
	const { weeklyApr } = useWeeklyApr(vaultInfo["id"], vaultInfo["chainId"]);
	const metrics = useVaultGasInfo(vaultInfo["id"], vaultInfo["strategyToken"]["id"]);
	const position = useVaultPositions(vaultInfo)
	const slot0 = useSlot0(vaultInfo['pool']);
	const userData = useUserData(vaultInfo);
	const [ipfsConfig, setIpfsConfig] = useState(null);
	const { data } = useCoinPrice(vaultInfo['token0'], vaultInfo['token1']);

	const payloadHash = vaultInfo["payloadIpfs"];

	const { maxUpperBound: upperTick, maxLowerBound: lowerTick } = useMemo(() => {
		return !!position
			? getMaxBounds(position)
			: {
				maxUpperBound: 1,
				maxLowerBound: -1,
			}
		//
	}, [position])

	const inRange = useMemo(() => {
		if (position && slot0) {
			const currentTick = slot0?.tick || 0;
			return isCurrentTickInRange(currentTick, {
				maxUpperBound: upperTick,
				maxLowerBound: lowerTick,
			})
		} else {
			return null
		}
	}, [slot0, position, upperTick, lowerTick])


	let rewardApr = useRewardApr(vaultInfo?.['incentivized']).apr;
	let stakingEnabled = false;

	if (!vaultInfo?.['incentivized']) {
		if (vaultInfo["merklRewardPool"] && vaultInfo["merklRewardPool"]["incentiveApr"] !== undefined) {
			stakingEnabled = true;
			rewardApr = (vaultInfo["merklRewardPool"]["incentiveAprs"][vaultInfo["id"]]).toFixed(2);
		}
	}

	useEffect(() => {
		(async () => {
			if (!ipfsConfig && payloadHash && payloadHash !== "") {
				const payloadData = await getIpfsJSONConfig(payloadHash);
				setIpfsConfig(payloadData);
			}
		})();
	}, [payloadHash, ipfsConfig]);


	const token0URL = getTokenURL(vaultInfo['token0'], vaultInfo['token0Symbol'], vaultInfo["chainId"], vaultInfo["beaconName"]);
	const token1URL = getTokenURL(vaultInfo['token1'], vaultInfo['token1Symbol'], vaultInfo["chainId"], vaultInfo["beaconName"]);
	console.log({userData})

	const appData = {
		id: vaultInfo["id"],
		name: vaultInfo["strategyToken"]["name"],
		data: {
			fee: {
				type: "text",
				val: `${parseInt(vaultInfo["feeTier"]) / 10000}%`,
				label: "fee tier",
			},
			apr: {
				type: "text",
				val: `~${(parseFloat(weeklyApr ? weeklyApr.apr : "0") + parseFloat(rewardApr || '0')).toFixed(2)}%`,
				label: `Fee APR ${!!vaultInfo?.["incentivized"] || stakingEnabled ? '✨' : ""}`,
				feeAPR: `~${parseFloat((weeklyApr ? weeklyApr.apr : "0")).toFixed(2)}%`,
				rewardAPR: `~${rewardApr || '0'}%`,
			},
			asset: {
				type: "asset",
				val: [token0URL, token1URL],
				label: `${vaultInfo["token0Symbol"]} - ${vaultInfo["token1Symbol"]}/Fee: ${parseInt(vaultInfo["feeTier"]) / 10000}%`,
			},
			userDeposit: {
				type: "multilineText",
				val: [`${userData?.userDepositedToken0} ${vaultInfo["token0Symbol"]}`, `${userData?.userDepositedToken1} ${vaultInfo["token1Symbol"]}`],
				label: "Your Current Deposits",
			},
			// userDepositInUSD: {
			//   type: "multilineText",
			//   val: [`${userTotalDeposit["token0USD"]+userTotalDeposit["token1USD"]} USD`, " "],
			//   label: "User USD Deposit",
			//   tooltip: 'You might see your value in USD in positive or negative because of IL, apperication/deperacation of asset'
			// },
			vaultGas: {
				type: "text",
				val: `${Math.floor(metrics.remainingRuns)}`,
				label: "Execution Remaining",
			},
			tvl: {
				type: "multilineText",
				val: getTVL(vaultInfo, data),
				label: "TVL",
			},
			interval: {
				type: "text",
				val: frequencyOptions.find(
					(d) =>
						d.value === ipfsConfig?.strategyConfigData?.epochLength
				)?.label || "",

				label: "Interval",
			},
			// userFees: {
			// 	type: "multilineText",
			// 	val: [`${userData?.currenToken0Fees} ${vaultInfo["token0Symbol"]}`, `${userData?.currenToken1Fees} ${vaultInfo["token1Symbol"]}`],
			// 	label: "Your Fees Earned",
			// 	tooltip: <div className="w-60 p-4">
			// 		Fees encompass IL via Swap and may exhibit negative 
			// 		<br/>
			// 		values until the smart pools achieve a specific TVL 
			// 		<br/>
			// 		threshold, at which point it can generate positive results.
			// 	</div>
			// },
			// userFeesInUSD: {
			//   type: "multilineText",
			//   val: [`${userApproxFees["token0USD"]+userApproxFees["token1USD"]} USD`, " "],
			//   label: "User USD Fees",
			//   tooltip: 'You might see your value in USD in positive or negative because of IL, apperication/deperacation of asset'
			// },
			reward: {
				type: "multilineText",
				val: userData.reward,
				label: "Unclaimed Rewards",
			},
			inRange: inRange === null ? true : inRange,
		},
		badgeData: beaconNameMapper[vaultInfo["beaconName"]],
		cardType: "app",
		card: vaultInfo["isUserDashboard"] ? 'user' : 'normal',
		onClick: () =>
			history.push({
				pathname: `/app/${vaultInfo["strategyToken"]["id"]}/vault/${vaultInfo["id"].toLowerCase()}?engine=${vaultInfo["beaconName"]}&chainId=${vaultInfo["chainId"]}`,
			}),
		incentivized: !!vaultInfo?.["incentivized"]
	};


	//@ts-ignore
	return <AppCard {...appData} />;
};

export default UserDetailsCard;

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.estimateClosePerpetual = exports.estimateOpenPerpetual = void 0;
const ethers_1 = require("ethers");
const constants_1 = require("../constants");
const types_1 = require("../constants/types");
const helpers_1 = require("../helpers");
const index_1 = require("../index");
const utils_1 = require("../utils");
function estimateOpenPerpetual(margin, leverage, collateral, stablecoin, provider = ethers_1.ethers.getDefaultProvider()) {
    var _a, _b, _c, _d;
    return __awaiter(this, void 0, void 0, function* () {
        const stable = (0, utils_1.parseStable)(stablecoin);
        const collat = (0, utils_1.parseCollat)(collateral);
        const stableMasterAddress = (_a = (0, constants_1.registry)(index_1.ChainId.MAINNET, { stablecoin: stable.symbol })) === null || _a === void 0 ? void 0 : _a.StableMaster;
        const oracleAddress = (_b = (0, constants_1.registry)(index_1.ChainId.MAINNET, stable.symbol, collat.symbol)) === null || _b === void 0 ? void 0 : _b.Oracle;
        const perpetualManagerAddress = (_c = (0, constants_1.registry)(index_1.ChainId.MAINNET, stable.symbol, collat.symbol)) === null || _c === void 0 ? void 0 : _c.PerpetualManager;
        const poolManagerAddress = (_d = (0, constants_1.registry)(index_1.ChainId.MAINNET, stable.symbol, collat.symbol)) === null || _d === void 0 ? void 0 : _d.PoolManager;
        if (!stableMasterAddress || !oracleAddress || !perpetualManagerAddress || !poolManagerAddress) {
            console.error('Address do not exist');
            return { percentageFee: 0, fees: 0, positionSize: 0 };
        }
        else {
            const stablemaster = types_1.StableMasterFront__factory.connect(stableMasterAddress, provider);
            const oracle = types_1.Oracle__factory.connect(oracleAddress, provider);
            const perpetualManager = types_1.PerpetualManagerFront__factory.connect(perpetualManagerAddress, provider);
            const rate = yield oracle.readLower();
            const totalCoveredAmount = yield perpetualManager.totalHedgeAmount();
            const stocksUsers = (yield stablemaster.collateralMap(poolManagerAddress)).stocksUsers;
            return (0, helpers_1.computeOpenPerpetualFromMarginLeverage)(1, stable.symbol.slice(2), collat.symbol, margin, leverage, collat.decimals, totalCoveredAmount, stocksUsers, rate);
        }
    });
}
exports.estimateOpenPerpetual = estimateOpenPerpetual;
function estimateClosePerpetual(margin, positionSize, entryRate, collateral, stablecoin, provider = ethers_1.ethers.getDefaultProvider()) {
    var _a, _b, _c, _d;
    return __awaiter(this, void 0, void 0, function* () {
        const stable = (0, utils_1.parseStable)(stablecoin);
        const collat = (0, utils_1.parseCollat)(collateral);
        const stableMasterAddress = (_a = (0, constants_1.registry)(index_1.ChainId.MAINNET, { stablecoin: stable.symbol })) === null || _a === void 0 ? void 0 : _a.StableMaster;
        const oracleAddress = (_b = (0, constants_1.registry)(index_1.ChainId.MAINNET, stable.symbol, collat.symbol)) === null || _b === void 0 ? void 0 : _b.Oracle;
        const perpetualManagerAddress = (_c = (0, constants_1.registry)(index_1.ChainId.MAINNET, stable.symbol, collat.symbol)) === null || _c === void 0 ? void 0 : _c.PerpetualManager;
        const poolManagerAddress = (_d = (0, constants_1.registry)(index_1.ChainId.MAINNET, stable.symbol, collat.symbol)) === null || _d === void 0 ? void 0 : _d.PoolManager;
        if (!stableMasterAddress || !oracleAddress || !perpetualManagerAddress || !poolManagerAddress) {
            console.error('Address do not exist');
            return 0;
        }
        else {
            const stablemaster = types_1.StableMasterFront__factory.connect(stableMasterAddress, provider);
            const oracle = types_1.Oracle__factory.connect(oracleAddress, provider);
            const perpetualManager = types_1.PerpetualManagerFront__factory.connect(perpetualManagerAddress, provider);
            const rate = yield oracle.readLower();
            const totalCoveredAmount = yield perpetualManager.totalHedgeAmount();
            const stocksUsers = (yield stablemaster.collateralMap(poolManagerAddress)).stocksUsers;
            const maintenanceMargin = yield perpetualManager.maintenanceMargin();
            return (0, helpers_1.computeClosePerpetual)(1, stable.symbol.slice(2), collat.symbol, margin, positionSize, entryRate, rate, collat.decimals, totalCoveredAmount, stocksUsers, maintenanceMargin).cashOutAmount;
        }
    });
}
exports.estimateClosePerpetual = estimateClosePerpetual;

import { BigNumber, Contract, utils } from "ethers";
import { useCall, useEthers, useTokenBalance } from "@usedapp/core";
// import { getVaultTokensFromLptokenDirect } from "utils";
import StakingAbi from "utils/Staking.json";
import millify from 'millify';
import { useLpTokenInT0AndT1 } from "queries/lp-token";

export const useUserData = (vaultInfo) => {
	const { account } = useEthers();
	let userLPTokenBalance = useTokenBalance(vaultInfo["id"], account);
	if (!userLPTokenBalance) {
		userLPTokenBalance = BigNumber.from('0');
	}

	const stakingReward = useCall(
		vaultInfo?.["incentivized"]
			? {
				contract: new Contract(
					vaultInfo?.["incentivized"]?.stakingPool,
					new utils.Interface(StakingAbi.abi)
				),
				method: "earned",
				args: [vaultInfo["account"]],
			}
			: false
	);

	let stakeBalance = useCall(
		vaultInfo?.["incentivized"] 
		  ? {
			contract: new Contract(
				vaultInfo?.["incentivized"]?.stakingPool,
				new utils.Interface(StakingAbi.abi)
			),
			method: "balanceOf",
			args: [vaultInfo["account"]],
		  }
		  : false
	  );
		let totalLpTokens = userLPTokenBalance;
	  if (stakeBalance && stakeBalance?.value && stakeBalance.value.length > 0) {
			totalLpTokens = totalLpTokens.add(stakeBalance.value[0]);
	  }
	  const llpTokenBreakup = useLpTokenInT0AndT1(totalLpTokens.toString(), vaultInfo["id"]);
	// const userDepositedToken0 =  vaultInfo["userDeposits"] ? parseFloat(utils.formatUnits(
	// 	vaultInfo["userDeposits"]["liquidityAmount0"],
	// 	vaultInfo["token0Decimals"]
	// )): null;
	// const userDepositedToken1 =  vaultInfo["userDeposits"] ? parseFloat(utils.formatUnits(
	// 	vaultInfo["userDeposits"]["liquidityAmount1"],
	// 	vaultInfo["token1Decimals"]
	// )): null;
	
	// let totalLPTokensIssued = BigNumber.from(vaultInfo["totalLPTokensIssued"] || "0");
	// totalLPTokensIssued = totalLPTokensIssued.toString() === "0" ? BigNumber.from('1') : totalLPTokensIssued;
	// const { token1Val, token0Val } = getVaultTokensFromLptokenDirect(
	// 	totalLpTokens.toString(),
	// 	totalLPTokensIssued,
	// 	BigNumber.from(vaultInfo["token1Balance"]),
	// 	BigNumber.from(vaultInfo["token0Balance"]),
	// 	BigNumber.from(vaultInfo["decimals"]),
	// );
	// const userCurrentToken0 =  token0Val.sub(BigNumber.from(vaultInfo["userDeposits"]["liquidityAmount0"])) ;
	// const userCurrentToken1 =  token1Val.sub(BigNumber.from(vaultInfo["userDeposits"]["liquidityAmount1"]));

	// const currenToken0Fees = vaultInfo["userDeposits"] ? utils.formatUnits(userCurrentToken0, vaultInfo["token0Decimals"]) : 0;
	// const currenToken1Fees = vaultInfo["userDeposits"] ? utils.formatUnits(userCurrentToken1, vaultInfo["token1Decimals"]) : 0;

	const merklRewardToken = vaultInfo?.["merklRewardPool"]?.["incentive"]?.["rewardToken"]["symbol"];
	const stakingRewardToken = vaultInfo?.["incentivized"]?.["reward"]?.["symbol"];


	let stakingRewardBalance = stakingReward && stakingReward?.value && stakingReward.value.length > 0 ? stakingReward.value[0] : 0;

	stakingRewardBalance = millify(parseFloat(
		utils.formatUnits(stakingRewardBalance, vaultInfo?.["incentivized"]?.["reward"]?.["decimals"])
	), {
		precision: 2,
	});

	const reward = [];
	if (merklRewardToken) {
		reward.push(`${vaultInfo["merklUserReward"] || 0} ${merklRewardToken}`);
	}

	if (stakingRewardToken) {
		reward.push(`${stakingRewardBalance || 0} ${stakingRewardToken}`);
	}

	if(reward.length === 1) {
		reward.push(" ")
	}
	return { userDepositedToken0: llpTokenBreakup?.["token0Usd"], userDepositedToken1: llpTokenBreakup?.["token1Usd"], reward };

};


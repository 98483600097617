import { useQuery } from "@tanstack/react-query"
import { getCandlesFromSwaps } from "utils"
import { Candle, Swap } from "utils/types"



export interface QueryResponse {
  data: Data
}

export interface Data {
  swaps: Swap[]
}

export const usePoolSwaps = (pool: string, url: string, timestamp: number|null) => {

  const limit = 1000;


  const fetchSwapsRecursively = async (skip = 0) => {
    if (!url) {
      return [];
    }

    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        query: GET_UNISWAP_SWAPS,
        variables: {
          address: pool,
          limit: limit,
          skip: skip,
          timestamp: timestamp
        },
      }),
    })

    const data = await res.json() as QueryResponse;
    const fetchedSwaps = data?.data?.swaps || [];

    if (fetchedSwaps.length === limit) {
      return [
        ...fetchedSwaps,
        ...await fetchSwapsRecursively(skip + limit)
      ];
    }

    return fetchedSwaps;
  }

  return useQuery(
    ["pool_swaps_data", pool, timestamp],
    async () => {
      if (!url) { 
        return []
      }

      const allSwaps = await fetchSwapsRecursively();

      return allSwaps
    },
    {
      enabled: !!url,
    }
  )
}

export const useCandleFromSwaps = (pool: string, chainId: number, swaps: Swap[], candleWidth: string, lookback: number, currentTick: any ) => {
  return useQuery({
    queryKey: ['pool-candles-data', pool, candleWidth, lookback],
    queryFn: async () => {
     try {
      if(currentTick || !candleWidth) {
        return [];
      }
        const candles = getCandlesFromSwaps(
         chainId,
         swaps,
         candleWidth
        );
 
        return candles as Array<Candle>
     } catch (error) {
        console.log("Error in converting swaps to candles:", error)
        return [];
     }
    },
    enabled: !!swaps && swaps.length > 0,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  })
}

const GET_UNISWAP_SWAPS = `
  query getSwaps(
    $address: String!
    $limit: Int!
    $skip: Int!
    $timestamp: Int!
  ) {
    swaps(
      first: $limit
      skip: $skip
      where: {
        pool: $address
        timestamp_gte: $timestamp
      }
      orderBy: timestamp
      orderDirection: desc
    ) {
      timestamp
      tick
      id
      token0 {
        id
        name
        symbol
        decimals
      }
      token1 {
        id
        name
        symbol
        decimals
      }
      sqrtPriceX96
    }
  }
`;

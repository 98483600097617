"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlacklistWrapper = exports.Wrapper = exports.UniswapV3Wrapper = exports.AMMType = exports.isMerklSupportedChainId = void 0;
const _1 = require(".");
const MerklSupportedChainIds = [_1.ChainId.ARBITRUM, _1.ChainId.MAINNET, _1.ChainId.OPTIMISM, _1.ChainId.POLYGON];
const isMerklSupportedChainId = (chainId) => {
    return MerklSupportedChainIds.includes(chainId);
};
exports.isMerklSupportedChainId = isMerklSupportedChainId;
var AMMType;
(function (AMMType) {
    AMMType[AMMType["UniswapV3"] = 0] = "UniswapV3";
    AMMType[AMMType["SushiSwapV3"] = 1] = "SushiSwapV3";
    AMMType[AMMType["Retro"] = 2] = "Retro";
})(AMMType = exports.AMMType || (exports.AMMType = {}));
var UniswapV3Wrapper;
(function (UniswapV3Wrapper) {
    UniswapV3Wrapper[UniswapV3Wrapper["Arrakis"] = 0] = "Arrakis";
    UniswapV3Wrapper[UniswapV3Wrapper["Gamma"] = 2] = "Gamma";
    UniswapV3Wrapper[UniswapV3Wrapper["DefiEdge"] = 4] = "DefiEdge";
    UniswapV3Wrapper[UniswapV3Wrapper["Ichi"] = 5] = "Ichi";
    UniswapV3Wrapper[UniswapV3Wrapper["Steer"] = 6] = "Steer";
})(UniswapV3Wrapper = exports.UniswapV3Wrapper || (exports.UniswapV3Wrapper = {}));
exports.Wrapper = {
    [AMMType.UniswapV3]: UniswapV3Wrapper,
    [AMMType.SushiSwapV3]: null,
    [AMMType.Retro]: null,
};
var BlacklistWrapper;
(function (BlacklistWrapper) {
    BlacklistWrapper[BlacklistWrapper["Blacklist"] = 3] = "Blacklist";
})(BlacklistWrapper = exports.BlacklistWrapper || (exports.BlacklistWrapper = {}));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RewardsDistributor__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [
            {
                internalType: "address[]",
                name: "governorList",
                type: "address[]",
            },
            {
                internalType: "address",
                name: "guardian",
                type: "address",
            },
            {
                internalType: "address",
                name: "rewardTokenAddress",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "_amountToDistribute",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "_stakingContract",
                type: "address",
            },
        ],
        name: "AmountToDistributeUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "stakingContract",
                type: "address",
            },
        ],
        name: "DeletedStakingContract",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "_caller",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "_stakingContract",
                type: "address",
            },
        ],
        name: "Dripped",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "_duration",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "_stakingContract",
                type: "address",
            },
        ],
        name: "DurationUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "_frequency",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "_stakingContract",
                type: "address",
            },
        ],
        name: "FrequencyUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "_incentiveAmount",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "_stakingContract",
                type: "address",
            },
        ],
        name: "IncentiveUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "newRewardsDistributor",
                type: "address",
            },
        ],
        name: "NewRewardsDistributor",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "_stakingContract",
                type: "address",
            },
        ],
        name: "NewStakingContract",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "uint256",
                name: "_amount",
                type: "uint256",
            },
        ],
        name: "RewardTokenWithdrawn",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "role",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "bytes32",
                name: "previousAdminRole",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "bytes32",
                name: "newAdminRole",
                type: "bytes32",
            },
        ],
        name: "RoleAdminChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "role",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "address",
                name: "account",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "sender",
                type: "address",
            },
        ],
        name: "RoleGranted",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "bytes32",
                name: "role",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "address",
                name: "account",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "sender",
                type: "address",
            },
        ],
        name: "RoleRevoked",
        type: "event",
    },
    {
        inputs: [],
        name: "DEFAULT_ADMIN_ROLE",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "GOVERNOR_ROLE",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "GUARDIAN_ROLE",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IStakingRewards",
                name: "stakingContract",
                type: "address",
            },
        ],
        name: "drip",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "role",
                type: "bytes32",
            },
        ],
        name: "getRoleAdmin",
        outputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "tokenAddress",
                type: "address",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                internalType: "contract IStakingRewards",
                name: "stakingContract",
                type: "address",
            },
        ],
        name: "governorRecover",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
        ],
        name: "governorWithdrawRewardToken",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "role",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "grantRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "role",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "hasRole",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IStakingRewards",
                name: "stakingContract",
                type: "address",
            },
        ],
        name: "removeStakingContract",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "role",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "renounceRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "role",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "account",
                type: "address",
            },
        ],
        name: "revokeRole",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "rewardToken",
        outputs: [
            {
                internalType: "contract IERC20",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_amountToDistribute",
                type: "uint256",
            },
            {
                internalType: "contract IStakingRewards",
                name: "stakingContract",
                type: "address",
            },
        ],
        name: "setAmountToDistribute",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_duration",
                type: "uint256",
            },
            {
                internalType: "contract IStakingRewards",
                name: "stakingContract",
                type: "address",
            },
        ],
        name: "setDuration",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_incentiveAmount",
                type: "uint256",
            },
            {
                internalType: "contract IStakingRewards",
                name: "stakingContract",
                type: "address",
            },
        ],
        name: "setIncentiveAmount",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newRewardsDistributor",
                type: "address",
            },
        ],
        name: "setNewRewardsDistributor",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "_stakingContract",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "_duration",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_incentiveAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_updateFrequency",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "_amountToDistribute",
                type: "uint256",
            },
        ],
        name: "setStakingContract",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "_updateFrequency",
                type: "uint256",
            },
            {
                internalType: "contract IStakingRewards",
                name: "stakingContract",
                type: "address",
            },
        ],
        name: "setUpdateFrequency",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "stakingContractsList",
        outputs: [
            {
                internalType: "contract IStakingRewards",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IStakingRewards",
                name: "",
                type: "address",
            },
        ],
        name: "stakingContractsMap",
        outputs: [
            {
                internalType: "uint256",
                name: "distributedRewards",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "lastDistributionTime",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "updateFrequency",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "incentiveAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "timeStarted",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "duration",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "amountToDistribute",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
class RewardsDistributor__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.RewardsDistributor__factory = RewardsDistributor__factory;
RewardsDistributor__factory.abi = _abi;

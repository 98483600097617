import React, {ReactNode} from 'react';

import Error from './svg/Error';
import Info from './svg/Info';
import Success from './svg/Success';

const ToastContent = ({type, title, description}: {
    type: string;
    title: string;
    description: string | ReactNode;
}) => {
    return (
      <div className="flex items-start">
        <div className="flex-shrink-0 pl-1">
          {type === "success" && (
            <Success />
          )}
          {type === "info" && (
            <Info />
          )}
          {type === "error" && (
           <Error/>
          )}
        </div>
        <div className="ml-3 w-0 flex-1 pt-0.5">
          <p className="text-base font-bold text-white-500">
            {title || "Title"}
          </p>
          <p className="mt-1 text-sm text-white-500">
            {description || "Description of the notification "}
          </p>
        </div>
      </div>
    );
  };

  export default ToastContent;
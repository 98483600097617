import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStrategyData } from "queries/strategies";
import { ExecutionBundleParameters, Pool, Strategy } from "utils/types";
import { getHash, getParameters, mergeConfigurations } from "../../helper";
import { DensityChart } from "./densityChart";

type StrategySimulationProps = {
	candles: any[]; // replace 'any' with the actual type of your candles
	executionConfigs: ExecutionBundleParameters | null;
	selectedStrategy: Strategy;
	selectedPool: Pool;
	currentTick: any;
	lookback: number|null,
	candleWidth: string|null
};


const StrategySimulation = ({
	candles,
	executionConfigs,
	selectedStrategy,
	selectedPool,
	currentTick,
	lookback,
	candleWidth
}: StrategySimulationProps) => {
	const poolFee = (Number(selectedPool.feeTier) / 10000).toFixed(2);
	const strategyHash = getHash(selectedStrategy, poolFee);

	const strategyConfig = mergeConfigurations(
		getParameters(selectedStrategy, poolFee),
		executionConfigs
	);

	if (strategyConfig && 'poolFee' in strategyConfig) {
		if (!strategyConfig['poolFee']) {
			strategyConfig['poolFee'] = Number(selectedPool.feeTier)
		}
	}

	const { data: rebalanceResults, isLoading } = useStrategyData(
		candles,
		strategyHash,
		strategyConfig,
		executionConfigs ? executionConfigs.strategyInterval : "15m",
		currentTick,
		candleWidth,
		lookback
	);

	return (
		<>
			<div className="flex justify-between item-center">
				<div className="flex justify-between mt-4 mb-1 text-lg font-bold text-white-500">
					Strategy Liquidity Placement Graph
				</div>
				{isLoading && (
					<div className="flex items-center justify-center mt-2 text-white-500">
						<FontAwesomeIcon
							icon={faSpinner}
							spin
							size="2x"
							className="text-white"
						/>
						<span className="ml-2 text-white">Updating Rebalances...</span>
					</div>
				)}
				{rebalanceResults && rebalanceResults.length === 0 && !isLoading && (
					<div className="flex items-center justify-center mt-2 text-red-400 capitalize">
						No rebalances for this pool from this strategy!
					</div>
				)}
			</div>

			<div className="flex flex-col items-center justify-center ">

				{<DensityChart
					selectedPool={selectedPool}
					rebalances={rebalanceResults ? rebalanceResults : []}
				/>}

			</div>
		</>
	);
};

export default StrategySimulation;

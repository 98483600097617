"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VeBoostProxy__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        name: "CommitAdmin",
        inputs: [
            {
                name: "admin",
                type: "address",
                indexed: false,
            },
        ],
        anonymous: false,
        type: "event",
    },
    {
        name: "ApplyAdmin",
        inputs: [
            {
                name: "admin",
                type: "address",
                indexed: false,
            },
        ],
        anonymous: false,
        type: "event",
    },
    {
        name: "DelegationSet",
        inputs: [
            {
                name: "delegation",
                type: "address",
                indexed: false,
            },
        ],
        anonymous: false,
        type: "event",
    },
    {
        stateMutability: "nonpayable",
        type: "constructor",
        inputs: [
            {
                name: "_voting_escrow",
                type: "address",
            },
            {
                name: "_delegation",
                type: "address",
            },
            {
                name: "_admin",
                type: "address",
            },
        ],
        outputs: [],
    },
    {
        stateMutability: "view",
        type: "function",
        name: "adjusted_balance_of",
        inputs: [
            {
                name: "_account",
                type: "address",
            },
        ],
        outputs: [
            {
                name: "",
                type: "uint256",
            },
        ],
    },
    {
        stateMutability: "nonpayable",
        type: "function",
        name: "kill_delegation",
        inputs: [],
        outputs: [],
    },
    {
        stateMutability: "nonpayable",
        type: "function",
        name: "set_delegation",
        inputs: [
            {
                name: "_delegation",
                type: "address",
            },
        ],
        outputs: [],
    },
    {
        stateMutability: "nonpayable",
        type: "function",
        name: "commit_admin",
        inputs: [
            {
                name: "_admin",
                type: "address",
            },
        ],
        outputs: [],
    },
    {
        stateMutability: "nonpayable",
        type: "function",
        name: "accept_transfer_ownership",
        inputs: [],
        outputs: [],
    },
    {
        stateMutability: "view",
        type: "function",
        name: "voting_escrow",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
            },
        ],
    },
    {
        stateMutability: "view",
        type: "function",
        name: "delegation",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
            },
        ],
    },
    {
        stateMutability: "view",
        type: "function",
        name: "admin",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
            },
        ],
    },
    {
        stateMutability: "view",
        type: "function",
        name: "future_admin",
        inputs: [],
        outputs: [
            {
                name: "",
                type: "address",
            },
        ],
    },
];
class VeBoostProxy__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.VeBoostProxy__factory = VeBoostProxy__factory;
VeBoostProxy__factory.abi = _abi;

import { useConfig, useEthers } from "@usedapp/core";
import { useChainId } from "hooks/useChainId";
import { useEffect, useState } from "react";
import { supportedChains } from "utils";

export const GET_DEPOSITS = `
    query getDepositors(
        $account: String!
    ) {
      depositors(where:{
        and: [
          {
            account:$account,
          }
        ]
        or:[{
          shares_gt:"0",
        }]
      } orderBy:createdTimestamp, orderDirection: desc) {
        shares
        vault {
          id
        }
      }
    }
    
`

export const useUserDeposits = () => {
    const { account } = useEthers();
    const [chainId] = useChainId();
    const tconfig = useConfig();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const supportedChain = supportedChains.find((chain) => {
        if(chainId) {
          return chain.id === chainId;
        } else {
          return chain.id === tconfig.readOnlyChainId
        }
      });
    const fetchDeposits = async () => {
        if(!account) {
          setIsLoading(false);
          return [];
        }
        const res = await fetch(supportedChain?.subgraphURl, {
            method: "POST",
            body: JSON.stringify({
              query: GET_DEPOSITS,
              variables: {
                account: account.toLowerCase(),
              },
            }),
          })

        const data = (await res.json())?.data?.depositors;
        setIsLoading(false);
        if(data && data.length > 0) {
          setData(data);
        } else { 
          return [];
        }
    }

    useEffect(() => {
        setData([]);
        fetchDeposits();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, chainId]);
    return { data, isLoading };
}
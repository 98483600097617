import React, { FunctionComponent } from "react";
import { AnimatedLoader } from "components/lib";

export interface CardLoaderProps {
  rowCount?: Number;
}

const CardLoader: FunctionComponent<CardLoaderProps> = (props) => {
  return (
    <div data-testid="CardLoader-component overflow-y-auto">
      <div className="flex flex-col">
        {Array.apply(0, Array(props.rowCount || 3)).map((_, i) => (
          <div
            className="relative min-w-full px-6 py-3 mt-3 h-25 rounded-xl bg-indigo-primary"
            key={"table loader" + i}
          >
            <AnimatedLoader shape="box" height="100px" rounded={false} />
          </div>
        ))}
      </div>
    </div>
  );
};

CardLoader.defaultProps = {};
export default CardLoader;

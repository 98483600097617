"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchPerpetuals = void 0;
const graphql_request_1 = require("graphql-request");
const logger_1 = require("../logger");
const THEGRAPH_URL = 'https://api.thegraph.com/subgraphs/name/picodes/perpetual';
const fetchPerpetuals = (chainId, lastId = '') => __awaiter(void 0, void 0, void 0, function* () {
    const fetchLength = 1000;
    const query = (0, graphql_request_1.gql) `
    query manyPerpetuals($lastId: String) {
      perpetuals(where: { status: open, id_gt: $lastId }, first: ${fetchLength}, orderBy: id) {
        id
        perpetualID
        owner
        margin
        committedAmount
        entryRate
        perpetualManager
        stableAddress
        collatAddress
        stableName
        collatName
        openingBlockNumber
        openingTimestamp
        lastUpdateBlockNumber
        lastUpdateTimestamp
        status
        liquidationPrice
      }
    }
  `;
    let response = [];
    try {
        const firstQuery = yield (0, graphql_request_1.request)(THEGRAPH_URL, query, { lastId });
        response = firstQuery.perpetuals;
    }
    catch (e) {
        console.error('The Graph error (in fetchPerpetuals):', e);
        throw e;
    }
    if (response.length === fetchLength) {
        const nextLastId = response[response.length - 1].id;
        const secondQuery = yield (0, exports.fetchPerpetuals)(chainId, nextLastId);
        response.push(...secondQuery);
    }
    if (lastId === '') {
        (0, logger_1.Logger)('Perpetuals: ', response.length);
    }
    return response;
});
exports.fetchPerpetuals = fetchPerpetuals;

import { useQuery } from "@tanstack/react-query";
import { BigNumber, Contract, utils } from "ethers";
import { getContractByNetwork, supportedChains } from "utils";
import { useChainId } from "../hooks/useChainId";
import { useEffect, useState } from "react";
import { useCall, useGasPrice } from "@usedapp/core";
import { useSystemGasMetrics } from "./gas";
import { useStrategyGasParams } from "./strategies";

export type VaultGasInfo = {
  balance: number;
  remainingRuns: number;
  healthScore: number;
  gasStats: {
    maxGasUsed: number;
    minGasUsed: number;
    medianGasUsed: number;
    totalGasUsed: number;
  };
};

const FIND_VAULT_GAS_USED = (vaultAddress: string) => `
query {
  vaultGasUseds(where:{
    vault: "${vaultAddress}"
  }) {
    id
    amount
  }
}`;

export const useVaultGasUsed = (vaultId: string, subgraphUrl: string) => {
  const url = subgraphUrl;

  return useQuery(
    ["vaultGasUsed", vaultId, url],
    async () => {
      const res = await fetch(url!, {
        method: "POST",
        body: JSON.stringify({
          query: FIND_VAULT_GAS_USED(vaultId),
          variables: {},
        }),
      });

      const data = await res.json();
      return data?.data?.vaultGasUseds as VaultGasUsedDetails[];
    },
    {
      enabled: !!url && !!vaultId,
    }
  );
};

export const useGasVaultEthBalances = (vaultId: string, chainId: number) => {
  const [vaultGas, setVaultGas] = useState(BigNumber.from("0"));
  const [isLoading, setIsLoading] = useState(true);

  const gasVault = getContractByNetwork(chainId, "GasVault");

  const vaultBalanceCall = useCall(chainId ? {
    contract: new Contract(gasVault.address, new utils.Interface(gasVault.abi)),
    method: "ethBalances",
    args: [vaultId],
  }: false);

  useEffect(() => {
    if (vaultBalanceCall && vaultBalanceCall?.value?.[0]) {
      setIsLoading(false);
      setVaultGas(vaultBalanceCall?.value?.[0]);
    }
  }, [vaultBalanceCall]);

  return {
    isLoading,
    vaultGas,
  };
};

export type VaultGasUsedDetails = {
  id: string;
  amount: string;
};

export const useVaultGasDetails = (vaultId: string) => {
  const [chainId] = useChainId();

  const supportedChain = supportedChains.find(
    ({ id }) => id === chainId
  );
  let subgraphUrl = null;
  if(supportedChain) {
    subgraphUrl = supportedChain?.subgraphURl;
  }

  const { data: gasUsages } = useVaultGasUsed(vaultId, subgraphUrl);

  const gasUsagesInEth =
    gasUsages?.map((gasUsage) =>
      Number(utils.formatUnits(BigInt(gasUsage.amount || "0"), 18))
    ) || [];

  // Calculate average gas usage in ETH
  const avgGasUsage =
    gasUsagesInEth.length > 0
      ? gasUsagesInEth.reduce((sum, gas) => sum + gas, 0) /
        gasUsagesInEth.length
      : 0;

  const sortedGasUsages = [...gasUsagesInEth].sort((a, b) => a - b);
  const midIndex = Math.floor(sortedGasUsages.length / 2);
  const medianGasUsed =
    sortedGasUsages.length % 2 === 0
      ? (sortedGasUsages[midIndex - 1] + sortedGasUsages[midIndex]) / 2
      : sortedGasUsages[midIndex];

  const gasStats = {
    avgGasUsage,
    maxGasUsed: Math.max(...gasUsagesInEth),
    minGasUsed: Math.min(...gasUsagesInEth),
    medianGasUsed: medianGasUsed,
    totalGasUsed: gasUsagesInEth.reduce((sum, gas) => sum + gas, 0),
  };

  const systemGasStats = useSystemGasMetrics();

  return { data: avgGasUsage !== 0 ? gasStats : systemGasStats };
};

export const useVaultGasInfo = (
  vaultId: string,
  strategyId: string
): VaultGasInfo => {
  const [chainId] = useChainId();

  const { strategyParams } = useStrategyGasParams(strategyId);
  const gasPrice = useGasPrice();

  const minRequiredBalance = (gasPrice || BigNumber.from("0")).mul(
    strategyParams && strategyParams.maxGasPerAction
      ? strategyParams.maxGasPerAction
      : BigNumber.from("0")
  );

  //@ts-ignore
  const { vaultGas: gasBalance } = useGasVaultEthBalances(vaultId, chainId);
  const { data: vaultGasParams } = useVaultGasDetails(vaultId);

  //@ts-ignore
  let balance = Number(utils.formatUnits(gasBalance?.toString() || "0", 18));
  const minBalance = Number(utils.formatUnits(minRequiredBalance?.toString() || "0", 18));

  balance = balance - minBalance;

  if (balance < 0) {
    balance = 0
  }

  // Calculate remaining runs based on average gas usage
  const remainingRuns =
    vaultGasParams.avgGasUsage > 0 ? balance / vaultGasParams.avgGasUsage : 0;

  // Calculate health score
  const healthScore =
    vaultGasParams.avgGasUsage > 0
      ? balance / (balance + vaultGasParams.avgGasUsage)
      : 1;

  const gasStats = {
    maxGasUsed: vaultGasParams.maxGasUsed,
    minGasUsed: vaultGasParams.minGasUsed,
    medianGasUsed: vaultGasParams.medianGasUsed,
    totalGasUsed: vaultGasParams.totalGasUsed,
  };

  return {
    balance: Number(balance),
    remainingRuns: Number(remainingRuns),
    healthScore: Number(healthScore),
    gasStats,
  };
};

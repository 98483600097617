import React from "react"

export interface ShimmerProps {
  height: string
  width: string
  styleProps?: string
}

export const Shimmer = ({
  height,
  width,
  styleProps
}: ShimmerProps) => {
  return (
    <div data-testid="badge-component">
      <div
        className={`py-1 ${styleProps} h-${height} w-${width} border bg-white-600 animate-pulse transition-all`}
        style={{ borderRadius: "12px" }}
      />
    </div>
  )
}
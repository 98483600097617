import { useQuery } from "@tanstack/react-query";
import { useChainId } from "hooks/useChainId";
import { useEffect, useState } from "react";
import { supportedChains } from "utils";

const FETCH_GAS_USAGE = `query {
    vaultGasUseds(first: 500, orderBy: timestamp, orderDirection: desc){
      amount
      timestamp
    }
  }`;

type GasData = {
  amount: number;
  timestamp: string; // Assuming timestamp is in string format
};


export const useSystemGasMetrics = () => {
  // Get the data from useVaultGasInfo hook
  const { data: info, status } = useVaultGasInfo();

  // Define state for the metrics we want to compute
  const [averageGasUsage, setAverageGasUsage] = useState(0);
  const [maxGasUsage, setMaxGasUsage] = useState(0);
  const [minGasUsage, setMinGasUsage] = useState(Infinity);
  const [medianGasUsed, setMedianGasUsed] = useState(0)
  const [totalGas, setTotalGas] = useState(0);

  // Process the data to compute metrics whenever the info changes
  useEffect(() => {
    if (status === "success" && !!info) {
      const gasAmounts = info.map((record) => record.amount);

      // Compute the average gas usage
      const totalGas = gasAmounts.reduce(
        (acc, curr) => acc + parseFloat(curr.toString()),
        0
      );

      const sortedGasUsages = [...gasAmounts].sort((a, b) => a - b);
      const midIndex = Math.floor(sortedGasUsages.length / 2);
      const medianGasUsed = sortedGasUsages.length % 2 === 0
        ? (sortedGasUsages[midIndex - 1] + sortedGasUsages[midIndex]) / 2
        : sortedGasUsages[midIndex];

      setMedianGasUsed(medianGasUsed);

      setAverageGasUsage(totalGas / gasAmounts.length);

      setTotalGas(totalGas);

      // Find the maximum and minimum gas usage
      setMaxGasUsage(Math.max(...gasAmounts));
      setMinGasUsage(Math.min(...gasAmounts));
    }
  }, [info, status]);

  // Return the computed metrics
  return {
    avgGasUsage: averageGasUsage,
    maxGasUsed: maxGasUsage,
    minGasUsed: minGasUsage,
    medianGasUsed,
    totalGasUsed: totalGas,
    status, // Return the status so that components can handle loading, error, and success states
  };
};

export const useVaultGasInfo = () => {
  const [chainId] = useChainId();
  const supportedChain = supportedChains.find(
    ({ id }) => id === chainId
  );
  let subgraphUrl = null;
  if(supportedChain) {
    subgraphUrl = supportedChain?.subgraphURl;
  }

  return useQuery({
    queryKey: ["system-gas-metrics", chainId],
    queryFn: async () => {
      const res = await fetch(subgraphUrl!, {
        method: "POST",
        body: JSON.stringify({
          query: FETCH_GAS_USAGE,
          variables: {},
        }),
      });

      const { data } = await res.json();
      return data.vaultGasUseds as GasData[];
    },
    enabled: !!subgraphUrl
  });
};

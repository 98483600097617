import { useEffect, useMemo, useState } from "react";
import { ChartEntry, DataItem, MergedDatasetType, Pool } from "utils/types";

import { Token } from "@uniswap/sdk-core";
import { tickToPrice } from "@uniswap/v3-sdk";
import { useChainId } from "hooks/useChainId";
import { useDensityChartData } from "queries/concentrated-liquidity/useDensityChartData";
import {
  CartesianGrid,
  ResponsiveContainer,
  Area,
  AreaChart,
  YAxis,
  XAxis,
} from "recharts";
import { useSlot0 } from "hooks/useSlot0";

export type DensityChartProps = {
  selectedPool: Pool;
  rebalances: Array<any>;
};

export const DensityChart = ({
  selectedPool,
  rebalances,
}: DensityChartProps) => {
  const [chainId] = useChainId();

  const Token0 = useMemo(
    () =>
      new Token(
        chainId,
        selectedPool.token0.id,
        Number(selectedPool.token0.decimals),
        selectedPool.token0.symbol,
        selectedPool.token0.name
      ),
    [selectedPool, chainId]
  );

  // and we can create a token object for token1
  const Token1 = useMemo(
    () =>
      new Token(
        chainId,
        selectedPool.token1.id,
        Number(selectedPool.token1.decimals),
        selectedPool.token1.symbol,
        selectedPool.token1.name
      ),
    [selectedPool, chainId]
  );

  const { formattedData } = useDensityChartData({
    chainId,
    token0: Token0,
    token1: Token1,
    feeAmount: Number(selectedPool.feeTier),
    protocol: selectedPool.dex,
  });
  let currentTick = useSlot0(selectedPool.id);
  currentTick = currentTick ? currentTick?.tick : currentTick;

  const [currentTickToPrice, setCurrentTickToPrice] = useState<string>();

  useEffect(() => {
    if (currentTick && Token0 && Token1) {
      const tickPrice = tickToPrice(
        Token0,
        Token1,
        Number(currentTick.toString())
      ).toFixed(8);

      setCurrentTickToPrice(tickPrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTick]);

  // convert use useMemo
  const data = useMemo(() => {
    return rebalances
      .map((rebalance) => {
        return rebalance.positions;
      })
      .reduce((allItems, items) => allItems.concat(items), [])
      .map((position) => {
        return {
          x1: tickToPrice(Token0, Token1, position.upperTick).toFixed(8),
          x2: tickToPrice(Token0, Token1, position.lowerTick).toFixed(8),
          y: position.weight,
        };
      });
  }, [rebalances, Token0, Token1]);

  return (
    <div className="flex flex-col justify-start w-full m-2">
      {/* {isLoading ? "Loading..." : "Loaded"} */}
      <div className={"w-full"}>
        {formattedData && formattedData.length > 0 && (
          <BarGraph
            currentTick={Number(currentTickToPrice || 0)}
            //@ts-ignore
            liquidity={formattedData}
            data={data}
          />
        )}
      </div>
    </div>
  );
};

function mergeDatasets(
  dataset1: DataItem[],
  dataset2: ChartEntry[]
): MergedDatasetType[] {
  // Merging datasets
  const mergedDataset = dataset2.map((data) => {
    // find if current price0 is between x1 and x2 in dataset1
    const found = dataset1.find((d) => {
      return data.price0 <= Number(d.x1) && data.price0 >= Number(d.x2);
    });
    // console.log({data})
    // if found, add the y from dataset1 as activeLiquidity1 in the current item
    return {
      ...data,
      activeLiquidity1: found ? found.y : 0,
    };
  });

  //@ts-ignore
  return mergedDataset;
}

interface BarGraphProps {
  data: DataItem[];
  currentTick?: number;
  liquidity?: Array<ChartEntry>;
}

const BarGraph = ({ data, liquidity }: BarGraphProps) => {
  const stuff = useMemo(
    () => mergeDatasets(data || [], liquidity || []),
    [data, liquidity]
  );

  if (stuff.length > 0) {
  }

  return (
    <div className="w-full p-4 border-2 border-gray-600 border-solid rounded-md">
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={stuff}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
          </linearGradient>
        </defs>
        
          <CartesianGrid strokeDasharray="0 1" />
          {/* <Tooltip /> */}
          
          <XAxis
            // scale={'linear'}
            dataKey="price0"
            orientation="bottom"
            xAxisId={"bottom"}
            hide={true}
            domain={["dataMin", "dataMax"]}
          />
          <YAxis
            yAxisId="left"
            orientation="left"
            stroke="#82ca9d"
            hide={true}
            domain={["0", "dataMax"]}
          />
          <Area
            dataKey="activeLiquidity"
            stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)"
            yAxisId={"left"}
            type={"step"}
            xAxisId={"bottom"}
          />
          
          <XAxis
            // scale={'linear'}
            dataKey="price0"
            orientation="bottom"
            xAxisId={"top"}
            // hide={true}
            domain={["dataMin", "dataMax"]}
          />
          <YAxis
            yAxisId={"right"}
            orientation="left"
            stroke="#82ca9d"
            hide={true}
            domain={["0", "dataMax"]}
          />
          <Area
            dataKey="activeLiquidity1"
            stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)"
            yAxisId={"right"}
            type={"step"}
            xAxisId={"top"}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

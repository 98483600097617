import React, { useState, useEffect } from "react";
import { useLazyQuery, gql } from "@apollo/react-hooks";
import { utils } from "ethers";
import { useCall, useConfig, useContractFunction, useEtherBalance, useEthers } from "@usedapp/core";

import { Dropdown, Input, Label, Select, Icon } from "components/lib";
import SharedButtons from "../common/sharedButton";
import {
  uploadFileAndGetHash,
  getContractByNetwork,
  beaconContractNameMapper,
  getWhitelistVaults,
  getGasTokenSymbol,
  supportedChains,
  toGraphNumberHex,
  logEventInSentry,
  getUniswapV3Subgraph
} from "utils";
import { Contract } from "@ethersproject/contracts";
import { sendNotification } from "components/Container";
import { useHistory } from "react-router-dom";
import UniswapFactory from "contracts/polygon/UniswapV3Factory.json";
import { getClient } from "services/graphql";
import { ZERO_ADDRESS, isTestnet } from "utils";
import { useChainId } from "hooks/useChainId";

const GET_VAULTS = gql`
  query getVaults($beaconName: String, $token0: String, $token1: String, $feeTier: String, $strategyId: String) {
    vaults(
      where: { beaconName: $beaconName, token0: $token0, token1: $token1, feeTier: $feeTier, strategyToken: $strategyId }
    ) {
      id
      strategyToken {
        id
      }
    }
  }
`;

const FETCH_TOKENS = gql`
query tokens($symbol: String!) {
  tokens(
    where: {or: [{symbol_contains_nocase: $symbol}, {name_contains_nocase: $symbol}]},
    orderBy: volumeUSD
    orderDirection: desc
  ) {
    id
    name
    symbol
    decimals
  }
}
`
const FETCH_POOLS_BY_TOKEN_1 = gql`
query pools($tokenAddress: String!) {
  pools(where: {
    or: [{ token0: $tokenAddress}, {token1: $tokenAddress}]
  }, orderBy: volumeUSD, orderDirection: desc) {
    id
    token0 {
      symbol
      id
    }
    token1 {
      symbol
      id
    }
    feeTier
  }
}
`;


const getTokenPairs = (asset, customTokens) => {
  const tokens = (
    customTokens.token0.length > 0 && customTokens.token1.length > 0
      ? [customTokens.token0.trim(), customTokens.token1.trim()]: 
      asset.value.split("-").map((d) => d.trim())
  ).sort();
  return tokens;
}

const Assets = ({
  steps,
  setSteps,
  data,
}: {
  steps: number;
  setSteps: (step: number) => void;
  setData: (data: any) => void;
  data: any;
}) => {
  const history = useHistory();
  const [debouncedTerm, setDebouncedTerm] = useState(null);
  const [asset, setAsset] = useState(null);
  const [checkPool, setCheckPool] = useState(false);
  const { account } = useEthers();
const [chainId] = useChainId();
  const testnet = isTestnet(chainId);
  const [customTokens, setCustomTokens] = useState({
    token0: "",
    token1: "",
  });
  const [gasAmount, setGasAmount] = useState(null);
  const etherBalance = useEtherBalance(account, { chainId });
  const ethBalance = utils.formatEther(etherBalance || "0");
  const gasToken = getGasTokenSymbol(chainId);
  let graphClient = getClient(supportedChains[0].subgraphURl);
  
  const tconfig = useConfig();

  const supportedChain = supportedChains.find((chain) => {
    if(chainId) {
      return chain.id === chainId;
    } else {
      return chain.id === tconfig.readOnlyChainId
    }
  });

  let uniswapGraphClient = getClient(getUniswapV3Subgraph(data?.strategyData?.vault?.name, supportedChains[0]))
  if (supportedChain) {
    graphClient = getClient(supportedChain.subgraphURl);
    uniswapGraphClient = getClient(getUniswapV3Subgraph(data?.strategyData?.vault?.name, supportedChain));
  }

  const [getVaults, vaults] = useLazyQuery(GET_VAULTS, {
    client: graphClient,
  });

  const [cloneCallCheck, setCloneCallCheck] = useState(false);
  const vaultConfigData = data?.strategyData?.vault?.payload;
  const [contractCallSent, setContractCallSent] = useState(false);
  const steerPeriphery = getContractByNetwork(chainId, "SteerPeriphery");
  const steerPeripheryInterface = new utils.Interface(steerPeriphery.abi);

  
  // const testToken = getContractByNetwork(chainId, "TestToken");
  // const testToken2 = getContractByNetwork(chainId, "TestToken2");
  // const testTokens = [
  //   {
  //     label: "Steer-test_0 - Steer-test_1",
  //     value: `${testToken?.address}-${testToken2?.address}`,
  //   },
  // ];

  const orchestratorAddress = getContractByNetwork(chainId, "Orchestrator")?.address;
  const uniswapFactoryInterface = new utils.Interface(UniswapFactory.abi);
  const uniswapFactoryAddress = getContractByNetwork(
    chainId,
    "UniswapFactory"
  );

  const uniswapFactoryContract = new Contract(
    uniswapFactoryAddress.address,
    uniswapFactoryInterface
  );
  
  const checkPoolResponse = useCall(checkPool ? {
    contract: uniswapFactoryContract,
    method: "getPool",
    args: [
      getTokenPairs(asset, customTokens)[0],
      getTokenPairs(asset, customTokens)[1],
      vaultConfigData?.fee
    ],
  } : false);
  
  const steerPeripheryContract = new Contract(
    steerPeriphery.address,
    steerPeripheryInterface
  );

  const showCustomTokens = () => {
    if (testnet) {
      return true;
    }

    const beaconName = data?.strategyData?.vault?.name;
    // its a uniswap pool
    if (beaconName && beaconName.indexOf('Sushi')  >  -1) {
      if (!supportedChain.sushiswapSubgraphURL || supportedChain.sushiswapSubgraphURL.length === 0) {
        return true;
      }
    } else if(beaconName && beaconName.indexOf('Forge')  > -1) {
      if (!supportedChain.forgeSubgraphURL || supportedChain.forgeSubgraphURL.length === 0) {
        return true;
      }
    } else if (beaconName && beaconName.indexOf('MAIA')  > -1) {
      if (!supportedChain.maiaSubgraphUrl || supportedChain.maiaSubgraphUrl.length === 0) {
        return true;
      }
    }
    else {
      if (!supportedChain.uniswapSubgraphURL || supportedChain.uniswapSubgraphURL.length === 0) {
        return true;
      }
    }

    return false;
  }

  const { state, send: createVaultAndStrategy } = useContractFunction(
    steerPeripheryContract,
    "createVaultAndStrategy",
    { transactionName: `Publish App for ${asset?.label}` }
  );

  const { state: cloneState, send: createVaultAndDepositGas } =
    useContractFunction(steerPeripheryContract, "createVaultAndDepositGas", {
      transactionName: `Remix App for ${asset?.label}`,
    });

  const isDisabled = (showCustomTokens() &&
    (customTokens.token0.length === 0 || customTokens.token1.length === 0)) ||
    (!showCustomTokens() && !asset) ||
    state.status === "PendingSignature" ||
    contractCallSent;
  
  
  const [getTokens, tokens] = useLazyQuery(FETCH_TOKENS, {
    client: uniswapGraphClient,
  });
  const [getPools, pools] = useLazyQuery(FETCH_POOLS_BY_TOKEN_1, {
    client: uniswapGraphClient,
  });
  const [selectedToken, setSelectedToken] = useState(null);

  const callPoolQuery = () => {
    setSelectedToken(null);
    getTokens({variables: {symbol: debouncedTerm.toUpperCase()}});
  };


  const formJsonForAllPayload = async () => {
    let jsonFile = {};
    jsonFile["strategyConfigData"] = {
      ...data.strategyData.payloadHash,
      epochStart: data.strategyData.epochStart,
      epochLength: data.strategyData.epochLength,
      name: data.strategyData.name,
      description: data.strategyData.description,
      appImgUrl: data.strategyData.appImgUrl,
    };
    jsonFile["vaultPayload"] = data.strategyData.vault.payload;
    jsonFile["dataConnectorsData"] = data.dataConnectors.map((d) => {
      return {
        bundleHash: d.bundle,
        configData: d.payloadHash,
      };
    });

    const blobFile = new Blob([JSON.stringify(jsonFile)], {
      type: "application/json",
    });
    const hash = await uploadFileAndGetHash(blobFile);
    return hash;
  };

  const getParamsAndHash = async () => {
    const hash = await formJsonForAllPayload();
    const tokens = (
      customTokens.token0.length && customTokens.token1.length ? [customTokens.token0.trim(), customTokens.token1.trim()]: 
      asset.value.split("-").map((d) => d.trim())
    ).sort();

    let encodedParams = utils.defaultAbiCoder.encode(
      ["address", "address", "uint24", "int24", "uint32"],
      [
        tokens[0],
        tokens[1],
        vaultConfigData?.fee,
        vaultConfigData?._maxTickChange || 250,
        vaultConfigData?._twapInterval || 45,
      ]
    );
  
    const contractName = beaconContractNameMapper[data.strategyData.vault.name];
    if (getWhitelistVaults.indexOf(contractName) !== -1) {
      encodedParams = utils.defaultAbiCoder.encode(
        ["address", "bytes"],
        [account, encodedParams]
      );
    }
    return {
      hash,
      encodedParams,
      tokens
    };
  };

  const deployTheThing = () => {
    (async () => {
      setContractCallSent(true);
      const { encodedParams, hash, tokens } = await getParamsAndHash();
      if (data.clone) {
        // if(data.payloadHash.toLowerCase() === hash.toLowerCase()) {
        //   sendNotification({
        //     type: "error",
        //     transactionName: `Clone App failed`,
        //     transaction: {
        //       msg: `The app is already deployed on same strategy configuration`,
        //     },
        //   });
        // } else {
          getVaults({
            variables: {
              beaconName: data?.strategyData?.vault?.name,
              token0: tokens[0].toLowerCase(),
              token1: tokens[1].toLowerCase(),
              feeTier: data?.strategyData?.vault?.payload?.fee?.toString(),
              strategyId: toGraphNumberHex(data?.strategyData?.id)
            },
          });
          setCloneCallCheck(true);
        // }
      } else {
        try {
          const createVaultAndStrategyParams = {
            strategyCreator: account,
            name: data.strategyData?.name,
            execBundle: data.strategyData?.executionBundle,
            maxGasCost: utils.parseUnits("900", "gwei"),
            maxGasPerAction: "1000000",
            params: encodedParams,
            beaconName: data.strategyData?.vault?.name,
            vaultManager: orchestratorAddress,
            payloadIpfs: hash
          }
          logEventInSentry('publish-app', {
            strategyCreator: account,
            name: data.strategyData?.name,
            execBundle: data.strategyData?.executionBundle,
            params: encodedParams,
            beaconName: data.strategyData?.vault?.name,
            vaultManager: orchestratorAddress,
            payloadIpfs: hash,
            tokens,
            date: new Date()
          });
          await createVaultAndStrategy(
            createVaultAndStrategyParams,
            {
              value: utils.parseEther(gasAmount || "0"),
            }
          );
          setContractCallSent(false);
        } catch (e) {
          console.log({e})
          setContractCallSent(false);
          sendNotification({
            type: "error",
            transactionName: `Publish App failed`,
            transaction: {
              ...e,
              msg: `Publish App failed for ${asset?.label}`,
            },
          });
        }
      }
    })();
  }

  useEffect(() => {
    if (
      state.status === "Success" ||
      state.status === "Mining" ||
      cloneState.status === "Success" ||
      cloneState.status === "Mining"
    ) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, cloneState]);

  useEffect(() => {
    if(selectedToken) {
      getPools({variables: {
        tokenAddress: selectedToken?.value,
        // feeTier: data?.strategyData?.vault?.payload?.fee?.toString()
      }});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedToken])

  useEffect(() => {
    if (debouncedTerm !== null && debouncedTerm && debouncedTerm.length > 2) {
      const timer = setTimeout(() => callPoolQuery(), 1000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTerm]);

  useEffect(() => {
    if (!vaults.loading && cloneCallCheck && data.clone) {
      setCloneCallCheck(false);
      if (vaults?.data?.vaults && vaults?.data?.vaults.length > 0) {
        sendNotification({
          type: "error",
          transactionName: `Remix App failed`,
          transaction: {
            msg: `The app is already deployed on same pair and same app engine.`,
          },
        });
        setContractCallSent(false);
      } else {
        (async () => {
          const { encodedParams, hash, tokens } = await getParamsAndHash();
          try {
            const params = {
              tokenId:  data?.strategyData?.id,
              params: encodedParams,
              beaconName: data?.strategyData?.vault?.name,
              vaultManager: orchestratorAddress,
              payloadIpfs: hash
            }
            logEventInSentry('remix-app', {
              ...params,
              tokens,
              date: new Date()
            });
            await createVaultAndDepositGas(
              params,
              {
                value: utils.parseEther(gasAmount),
              }
            );
            setContractCallSent(false);
          } catch (e) {
            console.log('error', { e });
            sendNotification({
              type: "error",
              transactionName: `Remix App failed`,
              transaction: {
                ...e,
                msg: `Remix App failed for ${asset?.label}`,
              },
            });
            setContractCallSent(false);
          }
        })();
      }
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vaults]);

  useEffect(() => {
    if(checkPool && checkPoolResponse && checkPoolResponse?.value) {
      setCheckPool(false);
      if (checkPoolResponse?.value[0] !== ZERO_ADDRESS) {
        deployTheThing();
      } else {
        sendNotification({
          type: "error",
          transactionName: `${data.clone ? 'Remix': 'Publish'} failed.`,
          transaction: {
            msg: `The pool for selected fee, tokens ${asset?.label} does not exits.`,
          },
        });
      }
    }
  //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkPool, checkPoolResponse]);

  const deployStrategy = async () => {
    // clone with custom tokens, check from the data already have
    // if thats a repeat
    if (data?.asset && customTokens.token0.length && customTokens.token1.length) {
      const tokens = `${customTokens.token0}-${customTokens.token1}`;
      if (tokens.indexOf(data?.asset) !== -1 && data.cloneAppBeaconName === data.strategyData.vault.name) {
        sendNotification({
          type: "error",
          transactionName: `Cannot remix app`,
          transaction: {
            msg: `Cannot remix app on same token pair ${data?.asset}`,
          },
        });
        return;
      }
    }
    if((customTokens.token0.length && customTokens.token1.length) && showCustomTokens()) {
      setCheckPool(true);
    } else {
      deployTheThing();
    }
  };

  const onSelect = (val) => {
    setAsset(null);
    const token = poolOptions.find((t) => t.value === val);
    setAsset(token);
  };

  const _renderInput = (tokenNum) => {
    return (
      <Input
        heading={`Token ${tokenNum} Address`}
        val={customTokens[tokenNum === 0 ? "token0" : "token1"]}
        onChange={(val) => {
          let _customTokens = { ...customTokens };
          _customTokens[tokenNum === 0 ? "token0" : "token1"] = val;
          setCustomTokens(_customTokens);
        }}
        align={"vertical"}
        placeholder={`Add Custom token ${tokenNum} Address`}
      />
    );
  };

  const poolOptions = pools.data ? pools.data?.pools.filter(d => d["feeTier"] === data?.strategyData?.vault?.payload?.fee?.toString())
  .map(d => {
    return {
      label: `${d.token0.symbol}/${d.token1.symbol} | Fee tier: ${parseInt(d["feeTier"]) / 10000}% | Address: ${d.id} `,
      value: `${d.token0.id}-${d.token1.id}`
    }
  })
  // .filter((d) => {
  //   console.log('a', d, data?.asset);
  //   return data?.asset ? d.value.toLowerCase() !== data?.asset.toLowerCase(): true
  // })
  : []

  const tokenOptions = tokens.data?.tokens.map(d => {
    return {
      label: `${d.name} (${d.symbol})`,
      value: `${d.id}`
    }
  });

 
  
  return (
    <div className="flex flex-col">
      {!showCustomTokens() && <div className="block px-4 mt-4">
        <Label
          content={"Select a token symbol for pool"}
          size={"large"}
          casing={"capitalize"}
          color={"white-500"}
        />
        <div className="mt-3">
          <Select
            isSearchable={true}
            handleInputChange={(d) => setDebouncedTerm(d)}
            options={tokenOptions}
            selected={selectedToken}
            onSelectHandler={setSelectedToken}
            placeholder=""
            />
        </div>
      </div>}
      {!showCustomTokens() && <div className="block px-4 mt-4">
      {selectedToken && 
        <Label
          content={`Select a pool for ${selectedToken.label}`}
          size={"large"}
          casing={"capitalize"}
          color={"white-500"}
        />
      }
      {pools.loading && <div className="flex justify-center"><Icon
              name={`faSpinner`}
              size={`1x`}
              spin={true}
              type={"normal"}
              style={{ color: "#fff" }}
            /></div>}
       {!pools.loading && poolOptions.length > 0 && <div className="mt-3">
          <Dropdown
            placeholder={"Select Pool"}
            options={poolOptions}
            onSelectHandler={onSelect}
            type={"normal"}
            hasIcon={false}
            selected={asset?.value}
          />
        </div>}
      </div>}
      {showCustomTokens() && <>
      <div className="block px-4 mt-4">
        <Label
          content={"Add custom token addresses"}
          size={"large"}
          casing={"capitalize"}
          color={"white-500"}
        />
        <div className="mt-3">
          <div className="my-3">{_renderInput(0)}</div>
          {_renderInput(1)}
        </div>
        </div>
      </>}

      <div className={`mt-4 px-4`}>
        <Input
          heading={`Fund Gas (${gasToken.toUpperCase()})`}
          val={gasAmount}
          type={"number"}
          onChange={setGasAmount}
          align={"vertical"}
          placeholder={
            ethBalance === "0.0"
              ? `${gasToken} Balance is low, please fund the account.`
              : ""
          }
          haserror={ethBalance === "0.0"}
        />
      </div>
      <SharedButtons
        steps={steps}
        setSteps={setSteps}
        nextStep={async () => {
          await deployStrategy();
        }}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default Assets;

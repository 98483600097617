export const ALL_IN_TOKEN0 = "ALL_IN_TOKEN0";
export const ALL_IN_TOKEN1 = "ALL_IN_TOKEN1";
export const PART_IN_TOKEN0_PART_IN_TOKEN1 = "PART_IN_TOKEN0_PART_IN_TOKEN1";

export interface VaultPositions {
  lowerTicks: number[];
  upperTicks: number[];
  weights: number[];
}

export function isOneSided(
  vaultPositions: VaultPositions,
  currentTick: number
): number {
  const smallestBound = Math.min(...vaultPositions.lowerTicks);
  const largestBound = Math.max(...vaultPositions.upperTicks);
  if (smallestBound > currentTick) return 1;
  if (largestBound < currentTick) return 0;
  return 2;
}

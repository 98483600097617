import { useQuery } from "@tanstack/react-query";
import { ChainId } from "@usedapp/core";
import {
    getUSDPriceAvalanche,
    getUSDPrices,
    getUsdPriceCelo,
    getUsdPriceEvmos,
    getUsdPriceKava,
    getUsdPriceMetis,
    getUsdPriceZkEvm
} from "services/rest";
import { Evmos, Kava, ZkEvm, getChainName } from 'utils';
import { useChainId } from './useChainId';

const fetchCoinPrice = async ({ queryKey }) => {
    const [chainId, token0, token1] = queryKey;

    if (!token0 || !token1) {
      return {};
    }
    let chainName = getChainName(chainId || 137);
    chainName = chainName === 'mainnet' ? 'ethereum' : chainName.toLowerCase();

    const priceId0 = `${chainName}:${token0}`;
    const priceId1 = `${chainName}:${token1}`;
    const priceData = [priceId0, priceId1];
    let fetchedPrice = null;

    if (chainId === Evmos.chainId) {
        fetchedPrice = await getUsdPriceEvmos([token0, token1]);
    } else if(chainId === ChainId.Andromeda) {
        fetchedPrice = await getUsdPriceMetis([token0, token1]);
    } else if(chainId === ChainId.Avalanche) {
        fetchedPrice = await getUSDPriceAvalanche([token0, token1]);
    } else if(chainId === ChainId.Celo) {
        fetchedPrice = await getUsdPriceCelo([token0, token1]);
    } else if(chainId === ZkEvm.chainId) {
        fetchedPrice = await getUsdPriceZkEvm([token0, token1]);
    } else if(chainId === Kava.chainId) {
        fetchedPrice = await getUsdPriceKava([token0, token1]);
    }

    if (!fetchedPrice || (fetchedPrice && (fetchedPrice.token0 === '0' || fetchedPrice.token1 === '0'))) {
        fetchedPrice = await getUSDPrices(priceData);
    }

    const price0 = fetchedPrice ? fetchedPrice.token0: null;
    const price1 = fetchedPrice ? fetchedPrice.token1: null;
    let _prices = {};

    _prices[priceId0] = price0? price0.toString(): null;
    _prices[priceId1] = price1? price1.toString(): null;

    _prices[token0] = price0? price0.toString(): null;
    _prices[token1] = price1? price1.toString(): null;

    return _prices;
}

export const useCoinPrice = (token0?: string, token1?: string) => { 
    const [chainId] = useChainId();

    return useQuery(
        {
            queryKey: [chainId, token0, token1],
            queryFn: async () => {
                const prices = await fetchCoinPrice(
                   {queryKey: [chainId, token0, token1]}
                );
                return prices;
            },
            enabled: !!token0 && !!chainId && !!token1,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
        }
    );
}

import { WasmModule } from "@steerprotocol/app-loader";
import { StrategyConfig } from "utils/types";

export class StrategyExecution {
    private filePath: string;
    public module: WasmModule | null = null;
    private results: any[] = [];
    public config: { [key: string]: any };
    public configParams: { [key: string]: any } = {};
    public candles: any[] = [];
    public numCandles = 15;
    public ipfsHash = '';
  
    constructor(module: WasmModule, config: StrategyConfig, configParams: { [key: string]: any }  = {}) {
      this.config = {};
      this.configParams = configParams;
      this.module = module;
      this.config = config;

      this.module.initialize(JSON.stringify(this.configParams));
    }
    
    public async execute(candles?: any[], isChainRead?: boolean): Promise<any[] | string> {
      let result;
      const results = [];
      try {
  
        if (!this.module) {
          throw new Error('Strategy not loaded');
        }
        
        const jsonPrices = !isChainRead ? JSON.stringify((candles || this.candles).map(val => {
          return {
            open: Number(val.open),
            high: Number(val.high),
            close: Number(val.close),
            low: Number(val.low),
          };
        })) : candles[0];
        
        try {
          result = this.module.execute(jsonPrices);
        } catch {
          console.error('Error executing strategy:', jsonPrices);
        }
      } catch(e) {
        console.log('Error on execute ', e);
        throw Error(e)
      }
  
      if (result === 'continue') return result;
      const parsedResult = !isChainRead ? JSON.parse(result || '{}'): result;
  
      results.push(parsedResult);
      this.results = results;
      return parsedResult;
    }
  
    private async _getConfig() {
      if (!this.module) {
        throw new Error('Strategy not loaded');
      }
  
      const result = this.module.config();
  
      return JSON.parse(result);
    }
  
    public async getConfig(): Promise<{ [key: string]: any }> {
      if (!this.config) {
        throw new Error('Strategy not loaded, load strategy before using getConfig()');
      }
  
      return this.config;
    }
  
    public setConfigParams(configParams: { [key: string]: any }): {
      [key: string]: any;
    } {
      this.configParams = configParams;
      return this.configParams;
    }
  
    public setCandles(candles: any[]): any[] {
      this.candles = candles;
      return this.candles;
    }
  
    public validateParams(configParams: string): [boolean, { [key: string]: any } | undefined] {
      // Removed ajv validation due to incompatibility with the browser environment
      // Replace with a client-side validation library if needed
  
      // For now, return true and no errors
      return [true, undefined];
    }
  
    public getResults(): any[] {
      if (!this.results) {
        throw new Error('Strategy not executed');
      }
  
      return this.results;
    }
  }
  

  interface Position {
    lowerTick: number;
    upperTick: number;
    weight: number;
  }
  
  interface DataItem {
    timestamp: number;
    positions: Position[];
  }
  
  export function decimateData(data: DataItem[]): DataItem[] {
    // Create an empty array to hold the decimated data
    let decimatedData: DataItem[] = [];
  
    // Sort the data by timestamp
    data.sort((a, b) => a.timestamp - b.timestamp);
  
    // Choose a bin size based on the size of the data
    let binSize = Math.max(1, Math.floor(data.length / 40));  // 10% of the data size, but at least 1
  
    // Divide the data into bins
    for (let i = 0; i < data.length; i += binSize) {
      // Get the data for this bin
      let binData = data.slice(i, i + binSize);
  
      let minLowerTick = Infinity;
      let maxLowerTick = -Infinity;
      let minUpperTick = Infinity;
      let maxUpperTick = -Infinity;
  
      binData.forEach(item => {
        item.positions.forEach(position => {
          minLowerTick = Math.min(minLowerTick, position.lowerTick);
          maxLowerTick = Math.max(maxLowerTick, position.lowerTick);
          minUpperTick = Math.min(minUpperTick, position.upperTick);
          maxUpperTick = Math.max(maxUpperTick, position.upperTick);
        });
      });
  
      // Create a new data item for this bin
      let binItem: DataItem = {
        timestamp: binData[0].timestamp,  // Use the timestamp of the first item in the bin
        positions: [
          {
            lowerTick: minLowerTick,
            upperTick: maxUpperTick,
            weight: binData[0].positions[0].weight  // Use the weight of the first item in the bin
          },
          {
            lowerTick: maxLowerTick,
            upperTick: minUpperTick,
            weight: binData[binData.length-1].positions[0].weight  // Use the weight of the last item in the bin
          }
        ]
      };
  
      // Add the new item to the decimated data array
      decimatedData.push(binItem);
    }
  
    return decimatedData;
  }
  
  
import React, { FunctionComponent, useState } from "react";

export interface CheckboxProps {
  textAlign?: 'left' | 'right';
  text?: string;
  description?: string;
  isChecked: boolean;
  onClickHandler?: (id) => void
}

export const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
  const [checked, setChecked] = useState(props.isChecked || false);

  const onClickHandler = () => {
    setChecked(!checked);
    props.onClickHandler(checked)
  }

  const renderTextDetails = () => <div className={`text-sm ${props.textAlign === 'left'? 'mr-3' : 'ml-3'}`}>
    <label htmlFor="comments" className="font-medium text-white-500">
      {props.text || ''}
    </label>
    <p id="comments-description" className="text-white-500">
      {props.description || ''}
    </p>
  </div>

  return (
    <div data-testid="checkbox-component">
      <fieldset className="space-y-5">
        <div className="relative flex items-start">
        {props.textAlign === 'left' && renderTextDetails()}
          <div className="flex items-center mt-1">
            <input
              checked={checked}
              onClick={onClickHandler}
              type="checkbox"
              className="w-4 h-4 border-gray-300 rounded "
            />
          </div>
        {props.textAlign === 'right' && renderTextDetails()}
        </div>
      </fieldset>
    </div>
  )
}

 
Checkbox.defaultProps = {
  textAlign: 'left',
  isChecked: false
};

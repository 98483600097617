import { Label, ProgressBar } from "components/lib";
import { useChainId } from "hooks/useChainId";
import { useVaultGasInfo } from "queries/vaults";
import { getGasTokenSymbol } from "utils";

const convertToPer = (value) => {
  return (value * 100).toFixed(2);
}


const GasStatusSection = ({ vaultId, strategyId }) => {
  const metrics = useVaultGasInfo(vaultId, strategyId);
  const [chainId] = useChainId();

  return (
    <div
      className="relative flex flex-col bg-white-500 mt-3 p-12"
      style={{
        borderRadius: "12px",
      }}
    >
      <div className="flex flex-col space-y-">
        <div className="my-4 space-y-1 text-center">
          <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">{Math.floor(metrics.remainingRuns)}</h1>
          <h4 className="scroll-m-20 text-xl font-semibold tracking-tight">Executions Remaining</h4>
        </div>
      </div>
      <div className="flex flex-col mt-3">
        <div className="pt-2 text-md font-bold text-right">
          Health: {convertToPer(metrics.healthScore) || "0"}%
        </div>
      </div>
      <div className="mx-auto w-full">
        <Label
            content={'Health Score'}
            size={"large"}
            casing={"capitalize"}
            color={"white-500"}
            align="center"
        />
        <div className="mt-4">
            <ProgressBar percent={Number(metrics?.healthScore || 0) * 100} color={`#52E5A5`} />
        </div>
      </div>
      <div className="text-md text-muted">
        Balance: {parseFloat(metrics?.balance.toString() || '0').toFixed(2)} {getGasTokenSymbol(chainId)}
      </div>
    </div>
  );
};

export default GasStatusSection;

import { useState, useEffect } from "react";
import axios from "axios";

export type StakingPool = {
    chainId: number;
    stakingPool: string;
    stakingToken: string;
    protocol: string;
};


type responseData = Array<StakingPool>;
  
type IpStackResponse = {
    pools: responseData;
    loadingStakingPools: boolean;
};

export const useStakingPools = (chainId: number): IpStackResponse => {
    const [loadingStakingPools, setLoadingStakingPools] = useState(true);
    const [pools, setPools] = useState<responseData | null>(null);
    const url = "https://9i52h964s3.execute-api.us-east-1.amazonaws.com/dev/staking-pools";
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get<responseData>(url);
                setPools(
                    response["data"]["pools"].filter(
                        (stakingPool: any) => stakingPool["chainId"] === chainId
                    )
                );
                setLoadingStakingPools(false);
            } catch (err) {
                console.log("Error fetching staking-pools data");
                setLoadingStakingPools(false);
            }
        };
        setPools(null);
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId]);

    return { pools, loadingStakingPools };
}

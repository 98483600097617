import React, { FunctionComponent } from "react";
import { AnimatedLoader } from "./AnimatedLoader";

export interface ContainerProps {
  loading?: boolean;
}

export const Container: FunctionComponent<
  ContainerProps & JSX.IntrinsicElements["div"]
> = (props) => {
  return (
    <div
      data-testid="container-container-component"
      {...props}
      className={`border border-lynch-rgba rounded-3xl px-4	py-4 flex flex-col ${props.className}`}
    >
      {!props.loading ? (
        props.children
      ) : (
        <AnimatedLoader shape="box" height="50px" />
      )}
    </div>
  );
};

Container.defaultProps = {
  loading: false,
};

Container.defaultProps = {};

import { Button, LabelData } from "components/lib";

// import { Distributor__factory, registry } from "@angleprotocol/sdk";
import { Distributor__factory, registry } from "@angleprotocol/sdk";
import { useEthers } from "@usedapp/core";
import { BigNumber } from "ethers";
import { useMerklUserRewards } from "hooks/useMerklUserRewards";
import millify from "millify";

const ClaimMerklRewardSection = ({
  vaultAddress,
  sushiRewardPool,
  merklRewardPool,
}) => {
  const { account, chainId, library } = useEthers();

  const { merklUserRewards, loadingMerklUserRewards } = useMerklUserRewards(
    chainId,
    account
  );


  const transactionData = merklUserRewards?.["transactionData"] || {};
  
  let tokens = Object.keys(transactionData).filter(
    (k) => transactionData[k].proof !== undefined
  );

  const claims = tokens.map((t) => transactionData[t].claim);
  

  const proofs = tokens.map((t) => transactionData[t].proof);
  if (merklRewardPool) {
    merklRewardPool["incentiveApr"] =
      merklRewardPool["incentiveAprs"][vaultAddress];
  }

  const contractAddress = registry(chainId)?.Merkl?.Distributor;
  let rewardApr = merklRewardPool
    ? (merklRewardPool["incentiveApr"] || 0).toFixed(2)
    : 0;
  if (!rewardApr) {
    rewardApr = (sushiRewardPool["incentiveApr"] * 100).toFixed(2);
  }

  // // sum all claims in big number
  const totalClaim = claims.reduce(
    (acc, t) => acc.add(BigNumber.from(t)),
    BigNumber.from(0)
  );

  const claimTokens = async () => {
    // @ts-ignore
    const contract = Distributor__factory.connect(
      contractAddress,
	//   @ts-ignore
      library.getSigner(account)
    );
    await (
      await contract.claim(
        tokens.map((t) => account),
        tokens,
        claims,
        proofs as string[][]
      )
    ).wait();
  };

  const poolAddress = merklRewardPool?.["address"];

  return (
    <div
      className="relative flex flex-col bg-white-500 mt-3"
      style={{
        borderRadius: "12px",
      }}
    >
      <div
        className="flex flex-col p-6 "
        style={{
          background: "rgba(93, 95, 239, 0.1)",
        }}
      >
        <div className="flex flex-col items-center">
          <div className="flex items-center">
            <img
              width="40px"
              height="40px"
              alt="Merkl Incentive"
              src="https://raw.githubusercontent.com/AngleProtocol/angle-assets/main/2_protocol-icon-only/0-MAIN-icon/PNG/angle-mark-topview-gradient.png"
            />
            <div className="text-xl text-black-500 font-bold mx-2 flex ">
              Angle Liquidity Mining Program
            </div>
          </div>
          <span className="text-md text-center text-black-500 my-4">
            Claim your ANGLE rewards from your positions.
            {/* <a href="cursor-pointer capitalize underline text-black-400 mx-2 flex"> Learn more 🔗
						</a> */}
          </span>
        </div>
      </div>
      <div className="px-16 py-3">
        <div className="grid grid-cols-2 gap-2 my-2">
          {merklRewardPool?.["incentive"]?.["allRewardTokens"]?.map((d) => {
            const rewardTokenAddress = d?.["address"];
            // const rewardTokenDecimal = d?.['tokenDecimals'];
            const symbol = d?.["symbol"];
            const poolReward = millify(
              merklUserRewards?.pools?.[poolAddress]?.["rewardsPerToken"]?.[
                rewardTokenAddress
              ]?.["unclaimed"],
              {
                precision: 2,
              }
            );
            // const claims = tokens.filter(m => {
            //     return (m && rewardTokenAddress) && m.toLowerCase() === rewardTokenAddress.toLowerCase();
            // }).map((t) => transactionData[t].claim);
            // const userPerTokenClaim = claims.reduce((acc, t) => acc.add(BigNumber.from(t)), BigNumber.from(0));

            // const userRewardBalance = millify(parseFloat(
            //     utils.formatUnits(userPerTokenClaim, rewardTokenDecimal)
            // ), {
            //     precision: 2,
            // });
            return (
              <LabelData
                label={"Unclaimed Rewards"}
                labelStyle={"capitalize text-sm text-gray-500"}
                data={`${
                  poolReward === "undefined" ? 0 : poolReward
                } ${symbol}`}
                dataStyle={
                  " text-sm lg:text-lg text-black-500 font-semibold mt-0"
                }
                alignment="vertical"
              />
            );
          })}
        </div>
        <div className="my-4">
          <Button
            type="nprimary"
            disabled={
              parseFloat(totalClaim.toString()) < 1 || loadingMerklUserRewards
            }
            content="Claim Total Rewards"
            onClickHandler={() => {
              claimTokens();
            }}
            iconSpin={true}
            icon={loadingMerklUserRewards ? "faSpinner" : ""}
            iconType={loadingMerklUserRewards ? "normal" : "normal"}
            iconAlignment={"right"}
            fullWidth={true}
            size={"small"}
          />
        </div>
      </div>
    </div>
  );
};

export default ClaimMerklRewardSection;

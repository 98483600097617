import { AppCard } from "components/lib";
import { useCall, useContractFunction } from "@usedapp/core";
import { sendNotification } from "components/Container";
import { Contract, utils } from "ethers";
import { FunctionComponent, useEffect, useState } from "react";
import { getTokenURL } from "utils";
import stakingContract from 'utils/Staking.json';
import stakingDualContract from 'utils/StakingDual.json';

import { useHistory } from "react-router-dom";

export interface StakeCardProps {
  info: object;
  key?: number;
  account: string;
}

const StakeCard: FunctionComponent<StakeCardProps> = (props) => {
  const { info } = props;

  const [inProgress, setInProgress] = useState(false);
  const history = useHistory();
  const stakingRewards = info["isDualFactory"] ? stakingDualContract : stakingContract;
  const stakingRewardInterface = new utils.Interface(stakingRewards.abi);
  const stakingRewardContract = new Contract(
    info['id'],
    stakingRewardInterface
  );
  
  const rewardPools = useCall({
    contract: stakingRewardContract,
    method: "isPaused",
    args: [info["id"]],

  });

  const { state: claimState, send: getReward } = useContractFunction(stakingRewardContract, "getReward", {
    transactionName: `Claiming ${props.info["userRewardBalanceA"] ? `${props.info['userRewardBalanceA']} for ${props.info['earnTokenA']}` :'' } ${props.info["userRewardBalanceB"] ? `${props.info['userRewardBalanceB']} for ${props.info['earnTokenB']}` :'' }  reward tokens.`
  });  


  const actionHandler = async () => {
      setInProgress(true);
      try {
          getReward();
          // setInProgress(false);
      } catch (e) {
        console.log({ e });
        sendNotification({
          type: "error",
          transactionName: `Claim failed for
          ${props.info["stakeToken"]}`,
          transaction: {
            ...e,
            msg: `Claim failed for
            ${props.info["stakeToken"]}`,
          },
        });
      }
  };

  useEffect(() => {
    if (
        claimState.status === "Success" ||
        claimState.status === "Mining"
        ) {
        setInProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimState]);

  const isDisabled = () => {
    if ((info['rewardBalanceBigNumA'] && info['rewardBalanceBigNumA'].gt(0)) || (info['rewardBalanceBigNumB'] && info['rewardBalanceBigNumB'].gt(0)) || inProgress) {
        return false;
    }
    return true;
  }
const token0URL = info?.["vault"] ? getTokenURL(info?.["vault"]?.['token0']?.['address'], info?.["vault"]?.['token0']?.['symbol'], info?.["chainId"], info?.["beaconName"]): 
getTokenURL(info?.["stakingToken"], info?.["stakeToken"], info?.["chainId"], info?.["protocol"]); 
const token1URL = info?.["vault"] ? getTokenURL(info?.["vault"]?.['token1']?.['address'], info?.["vault"]?.['token1']?.['symbol'], info?.["chainId"], info?.["beaconName"]): ''; 
const rewardAURL = info?.["vault"] ? getTokenURL(info?.["rewardTokenADetail"]?.['address'], info?.["rewardTokenADetail"]?.['symbol'], info?.["chainId"], info?.["beaconName"]): 
getTokenURL(info?.["rewardTokenADetail"]?.['address'], info?.["rewardTokenADetail"]?.['symbol'], info?.["chainId"], info?.["protocol"]); 
const rewardBURL = info?.["vault"] ? getTokenURL(info?.["rewardTokenBDetail"]?.['address'], info?.["rewardTokenBDetail"]?.['symbol'], info?.["chainId"], info?.["beaconName"]): 
getTokenURL(info?.["rewardTokenBDetail"]?.['address'], info?.["rewardTokenBDetail"]?.['symbol'], info?.["chainId"], info?.["protocol"]); ; 

const tokensURL = [token0URL]

if(token1URL) {
  tokensURL.push(token1URL);
}

const getTotalStaked = () => {

  if (info?.["isSteerVault"] === true) {
    return `${info?.['totalLpTokenDetails']?.["tvl"] || "0"} USD`;
  } else {
    return `${info?.['TST'] || "0"} USD`
  }
}

const getUserStaked = () => {

  if (info?.["isSteerVault"] === true) {
    return `${info?.['userlpTokenDetails']?.["tvl"] || "0"} USD`;
  } else {
    return `${info?.['userStakeAmount'] || "0"} USD`
  }
}

const disableState = !info['account'] || info['totalRewardsA'] === undefined ? ['Stake', 'Unstake'] : 
    info['isActive'] === 'Expired' && info['userStake'] && info["userStake"] !== '0' ? ['Stake'] : 
    (info['isActive'] === 'Expired' && info['userStake'] && info["userStake"] === '0') || rewardPools?.value?.[0] ? ['Stake', 'Unstake']:
    info['isActive'] !== 'Expired' && info['userStake'] && info["userStake"] === '0' ? ['Unstake'] : []
	const appData = {
    ...info,
    icon: "",
    name: "",
    cardType: "stake",
    badgeData: `${info['badgeText'] || ''}`,
    data: {
      totalStaked: {
        type: 'multilineText',
        val:  [getTotalStaked(), " "],
        label: "Total Staked"
      },
      apr: {
        type: 'text',
        val: `${info['rewardApr']['apr'] || '0'}%`,
        label: "Reward APR",
        tooltipId: `${info['id']}`,
        tooltip: <div className="w-60 p-4">
          The APR is derived from the ratio of total rewards to total staked 
          for remaining duration of staking pool. The Yield Percentage change based on total 
          amount of token staked/unstaked & amount of token claimed.
          <br/>
          <br/>
          Participants in the staking pool receive their share of the Total Rewards
          based on how their individual stake amount and staking duration
          compare to the overall stakes in the pool. In other words, the Yield Percentage reflects
          the dynamic nature of rewards distribution as the pool's token
          holdings evolve due to stake movements and claims.
          <br />
          <br />
          APR Split
          <br />
          {info?.["rewardTokenADetail"]?.['symbol'] ? `${info?.["rewardTokenADetail"]?.['symbol']} -${info['rewardApr']['aprA']}% APR`: ''} <br />
          {info?.["rewardTokenBDetail"]?.['symbol'] ? `${info?.["rewardTokenBDetail"]?.['symbol']} -${info['rewardApr']['aprB']}% APR`: ''} <br />
          <br />
          </div>
      },
      stakedBalance: {
        type: 'text',
        val: `${info['userStakeBalance'] || '0'}  LP Token`,
        label: "Staked LP Token"
      },
      stakedBalanceInUSD: {
        type: 'multilineText',
        val:  [getUserStaked(), " "],
        label: "Staked USD"
      },
      earnedRewards: {
        type: 'text',
        val: [`${info['userRewardBalanceA'] || '0'} ${info['earnTokenA']} ${info['userRewardBalanceB'] ? `${info['userRewardBalanceB'] || '0'} ${info['earnTokenB']}`: " "}`],
        label: "Unclaimed Rewards"
      },
      reward: {
        type: 'assetWithInfo',
        label: `Total Rewards/${info['totalRewardsA'] || 0} ${info['earnTokenA']} ${info["earnTokenB"] ? `/${info['totalRewardsB'] || 0} ${info['earnTokenB']}`: ""}`,
        val:  info?.["rewardTokenA"] && info?.["rewardTokenB"] ?  [rewardAURL, rewardBURL]: [rewardAURL],
      },
      asset: {
        type: "asset",
        val: tokensURL,
        label: info?.["vault"] ? `${info?.["vault"]?.['token0']?.['symbol']} - ${info?.["vault"]?.['token1']?.['symbol']}/Fee: ${parseInt(info?.['feeTier']) / 10000}%` : 
          `Stake Token/${info?.["stakeToken"]}`
      },
      liquidityInRange:  {
        type: 'badge',
        val:  info["inRange"],
        label: `Liquidity Range`,
        tooltip: <div className="p-4">
          The Pool liqudity is {`${info["range"] ? 'IN': 'OUT OF'}`} RANGE <br/> 
          <br/> 
            The current liqudity range   
          <br/> {info?.["range"]?.["lowerTickPrice"]} - {info?.["range"]?.["upperTickPrice"]} 
          <br/> 
          <br/> 

          Your Liquidity Split of the Staked LP Token 
          <br/> {info?.["userlpTokenDetails"]?.["token0Usd"]} 
          <br/> {info?.["userlpTokenDetails"]?.["token1Usd"]} 
        </div>
      },
      btns: [
        {
          val: {
            onClick: () => history.push({
              pathname: `/app/${info["strategyId"]}/vault/${info["stakingToken"].toLowerCase()}?engine=${info["beaconName"]}&chainId=${info["chainId"]}`,
            }),
          },
          label: "View Pool"
        },
        {
          val: {
            onClick: actionHandler,
            disable: isDisabled()
          },
          label: "Claim Reward"
        },
        {
          val: {
            onClick: info['btns'][1],
            disable: disableState.indexOf('Unstake') !== -1
          },
          label: "Unstake"
        },
        {
        val: {
          onClick: info['btns'][0],
          disable: disableState.indexOf('Stake') !== -1
        },
        label: "Stake"
      },
    ]
    },
  };


  //@ts-ignore
  return <AppCard {...appData} 
   stakeProtocol={info['protocol']}
  />;
};

export default StakeCard;

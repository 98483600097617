"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Orchestrator__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        inputs: [
            {
                internalType: "contract IStableMaster",
                name: "stableMaster",
                type: "address",
            },
            {
                internalType: "contract IPoolManager",
                name: "poolManager",
                type: "address",
            },
            {
                internalType: "contract IPerpetualManager",
                name: "perpetualManager",
                type: "address",
            },
            {
                internalType: "contract IFeeManager",
                name: "feeManager",
                type: "address",
            },
            {
                components: [
                    {
                        internalType: "uint64[]",
                        name: "xFeeMint",
                        type: "uint64[]",
                    },
                    {
                        internalType: "uint64[]",
                        name: "yFeeMint",
                        type: "uint64[]",
                    },
                    {
                        internalType: "uint64[]",
                        name: "xFeeBurn",
                        type: "uint64[]",
                    },
                    {
                        internalType: "uint64[]",
                        name: "yFeeBurn",
                        type: "uint64[]",
                    },
                    {
                        internalType: "uint64[]",
                        name: "xHAFeesDeposit",
                        type: "uint64[]",
                    },
                    {
                        internalType: "uint64[]",
                        name: "yHAFeesDeposit",
                        type: "uint64[]",
                    },
                    {
                        internalType: "uint64[]",
                        name: "xHAFeesWithdraw",
                        type: "uint64[]",
                    },
                    {
                        internalType: "uint64[]",
                        name: "yHAFeesWithdraw",
                        type: "uint64[]",
                    },
                    {
                        internalType: "uint256[]",
                        name: "xSlippageFee",
                        type: "uint256[]",
                    },
                    {
                        internalType: "uint64[]",
                        name: "ySlippageFee",
                        type: "uint64[]",
                    },
                    {
                        internalType: "uint256[]",
                        name: "xSlippage",
                        type: "uint256[]",
                    },
                    {
                        internalType: "uint64[]",
                        name: "ySlippage",
                        type: "uint64[]",
                    },
                    {
                        internalType: "uint256[]",
                        name: "xBonusMalusMint",
                        type: "uint256[]",
                    },
                    {
                        internalType: "uint64[]",
                        name: "yBonusMalusMint",
                        type: "uint64[]",
                    },
                    {
                        internalType: "uint256[]",
                        name: "xBonusMalusBurn",
                        type: "uint256[]",
                    },
                    {
                        internalType: "uint64[]",
                        name: "yBonusMalusBurn",
                        type: "uint64[]",
                    },
                    {
                        internalType: "uint64[]",
                        name: "xKeeperFeesClosing",
                        type: "uint64[]",
                    },
                    {
                        internalType: "uint64[]",
                        name: "yKeeperFeesClosing",
                        type: "uint64[]",
                    },
                    {
                        internalType: "uint64",
                        name: "haFeeDeposit",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "haFeeWithdraw",
                        type: "uint64",
                    },
                    {
                        internalType: "uint256",
                        name: "capOnStableMinted",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "maxInterestsDistributed",
                        type: "uint256",
                    },
                    {
                        internalType: "uint64",
                        name: "feesForSLPs",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "interestsForSLPs",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "targetHAHedge",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "limitHAHedge",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "maxLeverage",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "maintenanceMargin",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "lockTime",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "keeperFeesLiquidationRatio",
                        type: "uint64",
                    },
                    {
                        internalType: "uint256",
                        name: "keeperFeesLiquidationCap",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "keeperFeesClosingCap",
                        type: "uint256",
                    },
                ],
                internalType: "struct PoolParameters",
                name: "p",
                type: "tuple",
            },
        ],
        name: "initCollateral",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
];
class Orchestrator__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.Orchestrator__factory = Orchestrator__factory;
Orchestrator__factory.abi = _abi;

import { Strategy } from "../types";


export const strategies: Strategy[] = [
  {
    name: "Channel Multiplier Strategy",
    description:
      "This strategy uses a Multiplier to make channel around the price. The wider the multiplier the wider area of coverage on channel on both side of current price.",
    dataConnectorBundle: [{
      bundleHash: "QmXTAyXam74ncsh6q8YHRrdH1ydiAYifxityXexAAbNVyQ",
      configData: {
        poolAddress: "Per Dex",
        chainId: null,
        isChainRead: true,
      },
    }],
    executionBundle: {
      hash: "QmU7CprLf7RUxQsyYJ4yHYrtXqNy6HiaNnbG6dbKppn8RJ",
      parameters: {
        spreadPercentage: 0.25,
        poolFee: null,
      },
    },
    recommendedUsageScenarios:
      "For token pairs on low to high volatility. By tuning multiplier this strategy can be either conservative with position width or very aggressive. Best suited for v2 pool to v3 migration.",
    beacon: "MultiPosition",
    label: "Low to High Volatility Pool",
    avgApr: "30",
    totalTvl: "0",
    chartImgUrl: "https://publissteerimages.s3.amazonaws.com/classic-f.png",
    docUrl: "https://docs.steer.finance/concentrated-liquidity/official-apps/classic-rebalance",
    appImgUrl: "https://ipfs.io/ipfs/QmTk33MiEFkFBF5vjCivnkCUJQtf27d6ecJZcwewtfsgZQ"
  }
];

import { Contract } from "@ethersproject/contracts";
import {
  Badge,
  Button,
  Dropdown,
  Input,
  Label,
} from "components/lib";
import {
  RawCall,
  useCall,
  useCalls,
  useContractFunction,
  useEthers,
  useRawCall,
  useTokenAllowance,
  useTokenBalance,
} from "@usedapp/core";
import ModalHeader from "components/ModalHeader";
import { BigNumber, utils } from "ethers";
import { useChainId } from "hooks/useChainId";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
  addTokenToMetaMask,
  beaconContractNameMapper,
  getContractByNetwork,
  getLpToken,
  getTokenValue,
  getVaildAddress,
  isMetaMask,
  isValidNumber,
  logEventInSentry
} from "utils";
import IUniswapV3Pool from "utils/IUniswapV3Pool.json";
import { VaultPositions, isOneSided } from "utils/ratio-helper";
import ApproveTokens from "./Approval";

export interface DepositProps {
  vault: Object;
  closeModal: () => void;
  isDepositAppreciated: boolean
}

const Deposit: FunctionComponent<DepositProps> = (props) => {
  const { vault, closeModal } = props;
  const { account } = useEthers();
  const [chainId] = useChainId();

  const [lpTokenAdded, setLpTokenAdded] = useState(false);
  const [approveTokens, setApproveTokens] = useState(false);
  const [currentSlippage, setCurrentSlippage] = useState("1");
  const [LPTokenOutput, setLPTokenOutput] = useState("0");
  const [isInvalidToken0Value, setIsInvalidToken0Value] = useState(false);
  const [isInvalidToken1Value, setIsInvalidToken1Value] = useState(false);
  const [token0Input, setToken0Input] = useState("");
  const [token1Input, setToken1Input] = useState("");
  const [nativeTokenRatio, setNativeTokenRatio] = useState(0);
  const [vaultPositions, setVaultPositions] = useState(null);
  const [vaultBalances, setVaultBalances] = useState(null);
  const [positionStatus, setPositionStatus] = useState(null);

  const [slippageRatios] = useState([
    { value: "2", label: "2%", range: [98, 100] },
    { value: "1", label: "1%", range: [99, 100] },
    { value: "0.5", label: ".5%", range: [199, 200] },
    { value: "0.25", label: ".25%", range: [399, 400] },
  ]);

  const SteerPeriphery = getContractByNetwork(
    chainId || 137,
    "SteerPeriphery"
  )
  const multiPositionLiquidityManager = getContractByNetwork(
    chainId || 137,
    vault["vaultType"] ? beaconContractNameMapper[vault["vaultType"]] : ''
  )

  const singlePositionLiquidityManager = getContractByNetwork(
    chainId || 137,
    vault["vaultType"] ? beaconContractNameMapper[vault["vaultType"]] : ""
  )

  const steerPeripheryInterface = useMemo(() => new utils.Interface(SteerPeriphery.abi), [SteerPeriphery.abi]);
  // const steerPeripheryInterface = ;
  
  let steerPeripheryAddress = SteerPeriphery.address;
  const steerPeripheryContract = new Contract(
    steerPeripheryAddress,
    steerPeripheryInterface
  );

  const uniswapV3PoolInterface = new utils.Interface(IUniswapV3Pool.abi);
  let uniswapV3PoolAddress = vault["pool"];

  const uniswapV3PoolContract = new Contract(
    uniswapV3PoolAddress,
    uniswapV3PoolInterface
  );
  
  // vault contract instance
  let vaultContractInterface;

  if (vault && vault['vaultType'] && vault['vaultType'].toLowerCase().indexOf('multi') > -1) {
    vaultContractInterface = new utils.Interface(multiPositionLiquidityManager.abi);
  } else {
    vaultContractInterface = new utils.Interface(singlePositionLiquidityManager.abi);
  }
  const vaultContract = new Contract(
    vault["id"],
    vaultContractInterface,
  )


  const vaultPositionCalls = useCalls(vault && vault['vaultType'] && vault['vaultType'].toLowerCase().indexOf('multi') > -1 ? [
    {
      contract: vaultContract,
      method: "getPositions",
      args: [],
    }
  ]: [
    {
      contract: vaultContract,
      method: "upperTick",
      args: [],
    },
    {
      contract: vaultContract,
      method: "lowerTick",
      args: [],
    }
  ])

  const functionSelector = 'vaultBalancesByAddressWithFees(address)';
  
  const addressArgument = vault['id'];

  const data = steerPeripheryInterface.encodeFunctionData(functionSelector, [
    addressArgument,
  ]);
  
  const rawCall: RawCall = {
    address: steerPeripheryAddress,
    data: data,
    chainId: chainId,
    isStatic: true,
  }

  const staticCall = useRawCall(
    rawCall,
  )
  
  useEffect(() => {
    if(staticCall && staticCall.value && !vaultBalances) {
      try {
        const data = steerPeripheryInterface.decodeFunctionResult(functionSelector, staticCall.value)
        const balances = {
          amount0: data?.balances?.amountToken0,
          amount1: data?.balances?.amountToken1,
        }
        console.log({balances})
        setVaultBalances(balances)
      } catch(e) {
        console.log('error on decode function result', e);
      }
     
    }
  }, [staticCall, steerPeripheryInterface, vaultBalances])
  
  useEffect(() => {
    if (vaultPositionCalls && vaultPositionCalls.length > 0
      && !vaultPositions
      ) {
        if (vault && vault['vaultType'] && vault['vaultType'].toLowerCase().indexOf('multi') > -1) {
          const vaultPositionData = vaultPositionCalls[0];

          if (vaultPositionData?.value && vaultPositionData?.value?.length > 0) {
            const position: VaultPositions = {
              lowerTicks: vaultPositionData?.value[0],
              upperTicks: vaultPositionData?.value[1],
              weights: vaultPositionData?.value[2],
            }
            setVaultPositions(position)
          }
        }
      }
  }, [vaultPositionCalls, vault, vaultPositions])

  const poolDetails = useCall({
    contract: uniswapV3PoolContract,
    method: "slot0",
    args: [],
  });

  useEffect(() => {
    if (
      poolDetails &&
      poolDetails?.value?.sqrtPriceX96 &&
      nativeTokenRatio === 0
    ) {
      const sqrtPriceX96 = poolDetails?.value?.sqrtPriceX96;
      if (sqrtPriceX96) {
        let price: BigNumber = sqrtPriceX96
          .pow(2)
          .mul(BigNumber.from("10").pow(18))
          .div(BigNumber.from("2").pow(192));

        const nativeTokenRatio: number = parseFloat(price.toString()) /( 10 ** 18);
        setNativeTokenRatio(nativeTokenRatio);
      }
    }
  }, [poolDetails, nativeTokenRatio]);


  useEffect(() => {
    if(!positionStatus && poolDetails?.value?.tick && vaultPositions) {

      const positionStatus = isOneSided(
        vaultPositions,
        poolDetails?.value?.tick,
      );
      
      setPositionStatus(positionStatus);
    }
  }, [positionStatus, poolDetails, vaultPositions])

  const token0Address = getVaildAddress(vault["vaultToken0"]);
  const token1Address = getVaildAddress(vault["vaultToken1"]);
  const lpTokenDecimals = vault["lpTokenDecimals"];
  const lpTokenSymbol = vault["lpTokenSymbol"];

  const { state: depositState, send: deposit } = useContractFunction(
    //@ts-ignore
    steerPeripheryContract,
    "deposit",
    {
      transactionName: `Deposit of ${vault["token0Symbol"]}: ${token0Input},  
      ${vault["token1Symbol"]}: ${token1Input} for ${LPTokenOutput} ${lpTokenSymbol} in Strategy ${vault["name"]}`,
    }
  );

  const userToken0Balance = useTokenBalance(token0Address, account);
  const userToken1Balance = useTokenBalance(token1Address, account);
  const userLpTokenBalance = useTokenBalance(vault["lpToken"], account);
  // Repetitive function call already existing vault token contains this data
  const token0Allowance = useTokenAllowance(
    token0Address,
    account,
    steerPeripheryAddress
  );
  const token1Allowance = useTokenAllowance(
    token1Address,
    account,
    steerPeripheryAddress
  );

  const isTokenValueInvalid = (
    number: Number,
    tokenValue: string,
    tokenDecimal: number | BigNumber
  ) => {
    // Here also conversion is required to decimal points

    if (isValidNumber(tokenValue)) {
      const value = utils.parseUnits(tokenValue, tokenDecimal);
      if (number === 0 && userToken0Balance && userToken0Balance.gte(value)) {
        return false;
      }

      if (number === 1 && userToken1Balance && userToken1Balance.gte(value)) {
        return false;
      }

      return true;
    }
    return true;
  };

  const handleTokenInput = async (inputValue: string, type: number) => {
    const inputVal = inputValue.length > 0 ? inputValue : "0";
    const invalidTokenAmount = isTokenValueInvalid(
      type,
      inputVal,
      type === 0 ? vault["token0Decimals"] : vault["token1Decimals"]
    );
    if (!invalidTokenAmount && vaultBalances && vaultBalances?.["amount0"] && vaultBalances?.["amount1"]) {
      const otherInput = getTokenValue(
        vaultBalances["amount0"],
        vaultBalances["amount1"],
        type === 0 ? vault["token0Decimals"] : vault["token1Decimals"],
        type === 0 ? vault["token1Decimals"] : vault["token0Decimals"],
        type === 0 ? 1 : 0,
        inputVal,
        nativeTokenRatio
      );

      const otherInvalidTokenAmount = isTokenValueInvalid(
        type === 0 ? 1 : 0,
        otherInput,
        type === 0 ? vault["token1Decimals"] : vault["token0Decimals"]
      );

      if (type === 0) {
        setToken1Input(otherInput.toString());
      } else {
        setToken0Input(otherInput.toString());
      }
      const lpTokenAmount = getLpToken(
        type === 0 ? inputVal : otherInput,
        type === 0 ? otherInput : inputVal,
        vault["lpTokenTotalSupply"],
        vault["getBalance1"],
        vault["getBalance0"],
        vault["token0Decimals"],
        vault["token1Decimals"],
        lpTokenDecimals
      );
      setLPTokenOutput(lpTokenAmount);

      if (otherInvalidTokenAmount) {
        if (type === 0) {
          setIsInvalidToken1Value(otherInvalidTokenAmount);
        } else {
          setIsInvalidToken0Value(otherInvalidTokenAmount);
        }
      }
    }
    if (type === 0) {
      setToken0Input(inputVal);
      setIsInvalidToken0Value(invalidTokenAmount);
    } else {
      setToken1Input(inputVal);
      setIsInvalidToken1Value(invalidTokenAmount);
    }
  };

  const performDesposit = async () => {
    const ratios = slippageRatios.find(
      ({ value }) => value === currentSlippage
    ).range;

    const token0Value = utils.parseUnits(token0Input, vault["token0Decimals"]);
    const token1Value = utils.parseUnits(token1Input, vault["token1Decimals"]);

    let min0Amount = token0Value.mul(ratios[0]).div(ratios[1]);
    let min1Amount = token1Value.mul(ratios[0]).div(ratios[1]);

    if (min0Amount.gt(token0Value)) {
      min0Amount = token0Value;
    }

    if (min1Amount.gt(token1Value)) {
      min1Amount = token1Value;
    }

    logEventInSentry('deposit', {
      vaultId: vault["id"],
      chainId,
      vault,
      vaultBalances,
      token0Input,
      token1Input,
      token0Decimals: vault["token0Decimals"],
      token1Decimals: vault["token1Decimals"],
      min0Amount,
      min1Amount,
      account,
      date: new Date()
    });

    try {
      deposit(
        vault["id"],
        utils.parseUnits(token0Input, vault["token0Decimals"]),
        utils.parseUnits(token1Input, vault["token1Decimals"]),
        min0Amount,
        min1Amount,
        account
      );
      closeModal();
    } catch (error) {
      console.log(error);
      // showError("deposit failed", error);
    }
  };

  const handleDeposit = async () => {
    const approvalNeedForToken0 =
      token0Allowance.eq(0) ||
      utils
        .parseUnits(token0Input, vault["token0Decimals"])
        .gt(token0Allowance);
    const approvalNeedForToken1 =
      token1Allowance.eq(0) ||
      utils
        .parseUnits(token1Input, vault["token1Decimals"])
        .gt(token1Allowance);
    
    if (approvalNeedForToken0 || approvalNeedForToken1) {
      setApproveTokens(true);
    } else {
      performDesposit();
    }
  };

  const renderLPToken = () => (
    <div className={`mt-2`}>
      <Input
        heading={`LP Token: ${vault["lpTokenSymbol"]}`}
        type={`text`}
        optionalText={`Balance ${parseFloat(
          utils.formatUnits(userLpTokenBalance || "0", lpTokenDecimals)
        ).toFixed(4)}`}
        optionalTextStyle={`text-san-marino-100`}
        placeholder={`0.0`}
        align={`vertical`}
        val={LPTokenOutput}
        disabled={true}
        onChange={() => null}
      />
    </div>
  );

  const renderSlippage = () => (
    <div className={"mt-2"}>
      <Dropdown
        heading={`Slippage`}
        headingSize={"large"}
        onSelectHandler={(data) => {
          setCurrentSlippage(data);
        }}
        options={slippageRatios}
        type={"normal"}
        hasIcon={false}
        selected={currentSlippage}
      />
    </div>
  );

  const renderTokenInput = (tokenType: number) => {
    const name = vault[tokenType === 1 ? "token1Name" : "token0Name"];
    const symbol = vault[tokenType === 1 ? "token1Symbol" : "token0Symbol"];
    const decimals =
      tokenType === 1 ? vault["token1Decimals"] : vault["token0Decimals"];

    const optionalText = `${parseFloat(
      utils.formatUnits(
        tokenType === 1
          ? userToken1Balance
            ? userToken1Balance
            : "0"
          : userToken0Balance
          ? userToken0Balance
          : "0",
        decimals
      )
    ).toFixed(4)} ${symbol}`;
    const value = tokenType === 1 ? token1Input : token0Input;
    const hasError =
      tokenType === 1 ? isInvalidToken1Value : isInvalidToken0Value;
    const onChange = (e) => {
      setIsInvalidToken1Value(false);
      setIsInvalidToken0Value(false);
      handleTokenInput(e, tokenType);
    }

    return (
      <div className={`mt-2`}>
        <>
          <Input
            heading={name}
            type={`text`}
            optionalText={`Balance : ${optionalText}`}
            optionalTextStyle={`text-san-marino-100`}
            disabled={
              (positionStatus !== null && positionStatus === tokenType) || !vaultBalances ? true : false
            }
            maxButton={
              <Badge
                content={`max`}
                size={`xsmall`}
                bgColor={`green-200 p-1`}
                onClick={() => {
                  if (userToken0Balance && userToken1Balance && vaultBalances) {
                    handleTokenInput(
                      tokenType === 0
                        ? utils.formatUnits(
                            userToken0Balance,
                            vault["token0Decimals"]
                          )
                        : utils.formatUnits(
                            userToken1Balance,
                            vault["token1Decimals"]
                          ),
                      tokenType
                    );
                  }
                }}
              />
            }
            placeholder={`0.0`}
            align={`vertical`}
            val={value}
            haserror={hasError}
            onChange={onChange}
          />
          {hasError && (
            <div className="mt-2">
              <Label
                align={"right"}
                content={`Invalid Token Value`}
                color={"radical-red-500"}
                casing={"capitalize"}
                size={"medium"}
              />
            </div>
          )}
        </>
      </div>
    );
  };
  const buttonDisable =
    !vault["id"] ||
    isInvalidToken1Value ||
    isInvalidToken0Value ||
    !token0Input ||
    !token1Input ||
    !vault["token0Decimals"] ||
    !vault["token1Decimals"] ||
    parseFloat(token0Input) <= 0 ||
    parseFloat(token1Input) <= 0 ||
    !vaultBalances
  const addToken = () => {
    (async () => {
      await addTokenToMetaMask(
        vault["lpToken"],
        lpTokenSymbol,
        lpTokenDecimals
      );
      setLpTokenAdded(true);
    })();
  };
  const hasNoLpToken = lpTokenAdded
    ? false
    : userLpTokenBalance
    ? userLpTokenBalance.eq(0)
    : true;
  return (
    <>
      <ModalHeader
        description={props.isDepositAppreciated?(
          props.vault["name"] +
          " " +
          props.vault["asset"]
        ).toUpperCase(): `There is a new version live for this vault and strategy. We recommend deposit on new version !`}
        title={`Deposit ${props.vault["name"]}`}
        closeModal={closeModal}
      />
      {!approveTokens && (
        <div className={`grid grid-rows-2 gap-2 p-3`}>
          {renderTokenInput(0)}
          {renderTokenInput(1)}
          {renderSlippage()}
          {renderLPToken()}

          <div
            className={`mt-7 flex items-center ${
              hasNoLpToken ? "justify-between" : "justify-end"
            }`}
          >
            {hasNoLpToken && isMetaMask && (
              <Button
                type={"secondary"}
                onClickHandler={addToken}
                size={"small"}
                disabled={!lpTokenSymbol && !account}
                content={`
                Add Token
              `}
                casing={`uppercase`}
              />
            )}
            <Button
              onClickHandler={handleDeposit}
              disabled={buttonDisable}
              content={"Deposit"}
              casing={`uppercase`}
              type={`tertiary`}
              size={"small"}
              fullWidth={true}
            />
          </div>
        </div>
      )}
      {approveTokens && account && (
        <ApproveTokens
          token0Input={token0Input}
          token1Input={token1Input}
          vault={vault}
          deposit={() => performDesposit()}
          handleBack={() => setApproveTokens(false)}
          token0Decimals={vault["token0Decimals"]}
          token1Decimals={vault["token1Decimals"]}
          depositState={depositState}
          closeModal={closeModal}
          token0Allowance={token0Allowance}
          token1Allowance={token1Allowance}
          chainId={chainId}
          account={account}
        />
      )}
    </>
  );
};

export default Deposit;

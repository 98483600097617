import React, { FunctionComponent, useState } from "react";
import { Icon } from "./Icon";
import copy from 'copy-to-clipboard';
import {TooltipProps, Tooltip} from './Tooltip';
export interface ButtonProps extends TooltipProps {
  onClickHandler: (data) => void;
  content?: string;
  casing?: "uppercase" | "lowercase" | "capitalize";
  disabled?: boolean;
  type?: "primary" | "secondary" | "tertiary" | "rounded" | "copy" | 'nprimary' | 'nsecondary';
  icon?: string;
  iconType?: 'svg' | 'normal';
  size?: "xsmall" | "small" | "medium" | "large";
  iconAlignment?: "left" | "right";
  style?: string;
  svgName?: string
  svgColor?: string,
  fullWidth?: boolean
  iconSpin?: boolean
  tooltipId?: string;
}


const getIconComponent = (props) => {
  return <Icon name={props.icon} size={props.type === "copy" ? 'sm' : "lg"} style={{ color: props.color }} spin={props.iconSpin || false}/>
};

const renderContent = (props) => (
  <div
    className={`inline-flex items-center 
 ${
    props.size === 'xsmall' ? "px-2 py-1.5" : props.size === "small" ? `px-5 py-2` : props.size === "medium" ? `px-3 py-2.5 ` : `px-11 py-2.5 `
  }`} 
  >
    {props.icon && props.iconAlignment === "left" && (
      <span className={`-ml-0.5 mr-2`}>{getIconComponent(props)}</span>
    )}
    {props.content}
    {props.icon && props.iconAlignment === "right" && (
      <span className={"-mr-0.5 ml-2"}>{getIconComponent(props)}</span>
    )}
  </div>
);

export const Button: FunctionComponent<ButtonProps> = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const onClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation(); // <-- this stops the click going through to the parent div
    props.onClickHandler(e);
    setIsHovered(false);
  };

  return (
    <div data-testid="button-component">
      {props.type !== "rounded" && props.type !== "copy" && (
        <button
          type="button"
          disabled={props.disabled}
          onMouseOver={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={onClickHandler}
          data-tip={props.tooltipId|| ''}
          data-for={props.tooltipId|| ''}
          className={`
            rounded-lg ${props.casing} duration-100 p-1
            ${props.fullWidth ? 'w-full': ''}
            shadow-lg
            ${
              props.size === 'xsmall' ? "text-xs leading-3" :
              props.size === "small"
                ? `text-sm leading-4	`
                : props.size === "medium"
                ? `text-xl leading-7`
                : `text-base leading-6`
            }
            ${!props.disabled ? `
              cursor-pointer ${props.type === 'nprimary' ? "bg-indigo-nprimary" : props.type === 'nsecondary' ? "bg-teal-500" : "bg-east-bay-default"}  ${
                props.type === "tertiary" ||  props.type === 'nprimary' ||  props.type === 'nsecondary'
                  ? "text-white-500"
                  : props.type === "secondary"
                  ? `text-radical-red-500`
                  : `text-shamrock-500`
              }
            `: `
            cursor-default bg-waikawa-gray-400 text-waikawa-gray-500
            `
          
          }
            ${props.style}
          `}
        >
          {isHovered && (
            <div className={`rounded-lg 
              ${!props.disabled ? props.type === 'nprimary' ? "bg-indigo-nprimary" : props.type === 'nsecondary' ? "bg-teal-500" : " bg-indigo-default shadow-inner " : "bg-indigo-default shadow-inner"}`}>
              {renderContent(props)}
            </div>
          )}
          {!isHovered && renderContent(props)}
        </button>
      )}

      {props.type === "copy" && (
        <button
          type="button"
          data-tip={props.tooltipId|| ''}
          data-for={props.tooltipId|| ''}
          disabled={props.disabled}
          onClick={() => copy(props.content)}
          onMouseOver={() => setIsHovered(props.disabled ? false : true)}
          onMouseLeave={() => setIsHovered(false)}
          className={`shadow-base rounded-lg px-2 py-0.5
            ${!isHovered ? `text-lynch-500` : "text-shamrock-500"}
            cursor-pointer bg-indigo-default
          `}
          >
            <span>{getIconComponent({...props, icon: 'faCopy'})}</span>
        </button>
      )}

      {props.tooltipId && <Tooltip {...props} tooltipId={props.tooltipId}/>}
    </div>
  );
};

Button.defaultProps = {
  content: "Test button",
  onClickHandler: (data) => undefined,
  casing: "uppercase",
  type: "rounded",
  disabled: false,
  iconAlignment: "left",
  size: "large",
  iconType: 'normal',
};

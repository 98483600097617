import React, { FunctionComponent, ReactNode, useRef } from "react";
import ReactTooltip from 'react-tooltip';
export interface TooltipProps {
  tooltipPlace?: 'top' | 'right' | 'bottom' | 'left';
  tooltipType?: 'dark' | 'success' | 'warning' | 'error' | 'info' | 'light';
  tooltipEffect?: 'solid' | 'float';
  tooltipMultiline?: boolean;
  tooltipContent?: ReactNode; // data that you want to show as tooltip
  tooltipId?: string; // connect a specific tooltip with a target
  tooltipClickable?: boolean;
}

export const Tooltip: FunctionComponent<TooltipProps> = (props) => {

  const reactTooltipRef = useRef(null);
  return (
    <div data-testid="Tooltip-component"
      data-tip
      data-for={props.tooltipId}
      onMouseEnter={() => props.tooltipClickable ? ReactTooltip.hide() : ""}
    >
      <ReactTooltip id={props.tooltipId}
        type={props.tooltipType || 'dark'}
        effect={props.tooltipEffect || 'solid'}
        place={props.tooltipPlace || 'top'}
        multiline={props.tooltipMultiline || true}
        clickable={props.tooltipClickable || false}
        delayHide={props.tooltipClickable ? 500 : 0}
        event={props.tooltipClickable ? 'click' : ''}
        globalEventOff={props.tooltipClickable ? 'click' : ''}
        className="px-2 py-1 text-sm leading-4 rounded-lg bg-black-500 text-white-500 overflow-auto"
        isCapture={true}
        getContent={() => {
          return (
            <div className="tooltip-content">
              {props.tooltipContent}
            </div>
          )
        }
        }
        ref={reactTooltipRef}
        overridePosition={({ left, top }, _e, _t, node) => {
          if(node) {
            return {
              top,
              left: typeof node === 'string' ? left : Math.max(left, 20),
            };
          } else {
            return {top, left};
          }
        }}
      />
    </div>
  );
};

Tooltip.defaultProps = {
  tooltipMultiline: true,
  tooltipClickable: false,
  tooltipPlace: 'top',
  tooltipType: 'dark',
};

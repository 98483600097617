import React from "react";
import SliderComp from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";


export const Slider = (props) => {
  return (
   <div className="flex flex-col">
     <div className="flex justify-bewteen item-center">
     <div className="mb-2 text-white-500">{props.heading || ''}</div>
     <div className="mb-2 text-white-500">{props.value || ''}</div>
     </div>
     <SliderComp
    className={`relative flex w-full touch-none select-none items-center single-thumb`}
    {...props}
    />
   </div>
  );
}


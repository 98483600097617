import { useQuery } from "@tanstack/react-query"
import { useConfig } from "@usedapp/core";
import { useChainId } from "hooks/useChainId";
import { supportedChains } from "utils";

export const FETCH_VAULT = `
    query getVault(
        $deployer: String!
        $timestamp: Int!
    ) {
        vaults (where:{deployer: $deployer, createdAt_gt: $timestamp}) {
            id
            strategyToken {
                id
                name
            }
            beaconName
        }
    }
`;

export const FETCH_VAULTS_BY_IDS = `
    query getVaultsByIds($vaultIds: [String]!) {
        vaults(where: {
            id_in: $vaultIds
        }) {
        id
        payloadIpfs
        beaconName
        token0
        token0Symbol
        token1
        token1Symbol
        feeTier
        decimals
        annualFeeARR
        weeklyFeeAPR
        strategyToken {
            executionBundle
            id
            name
        }
        createdAt
        token0Balance
        token0Decimals
        token1Decimals
        token1Balance
        annualPercentageYearlyYield
        lastSnapshot
        deployer
        pool
        totalLPTokensIssued
    }
  }
`

export const useVaultData = (
    account: String,
    timestamp: number,
    enabled: boolean,
    url: string,
  ) => {
    
    const fetchVault = async () => {

        if (!url || !timestamp) {
            return [];
        }

        const res = await fetch(url, {
            method: "POST",
            body: JSON.stringify({
              query: FETCH_VAULT,
              variables: {
                deployer: account.toLowerCase(),
                timestamp: parseInt(timestamp.toString()),
              },
            }),
          })

        return (await res.json())?.data?.vaults;
    }

    return useQuery({
        queryKey: ['vault', account],
        queryFn: async () => {
           const vaults = await fetchVault();
           return vaults && vaults.length > 0 ? vaults[0]: null;
        },
        refetchInterval: 1000,
        enabled
    });
}

export const useVaultsByIds = (
    vaultIds: Array<string>,
  ) => {
    const [chainId] = useChainId();
    const tconfig = useConfig();

    const supportedChain = supportedChains.find((chain) => {
        if(chainId) {
          return chain.id === chainId;
        } else {
          return chain.id === tconfig.readOnlyChainId
        }
      });
    const fetchVault = async () => {

        const res = await fetch(supportedChain?.subgraphURl, {
            method: "POST",
            body: JSON.stringify({
              query: FETCH_VAULTS_BY_IDS,
              variables: {
                vaultIds: vaultIds,
              },
            }),
          })

        return (await res.json())?.data?.vaults;
    }

    return useQuery({
        queryKey: ['vaultByIds', vaultIds, chainId],
        queryFn: async () => {
           const vaults = await fetchVault();
           return vaults && vaults.length > 0 ? vaults: [];
        },
        enabled : vaultIds.length !== 0,
    });
}
 
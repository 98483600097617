import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";

let apolloClient: ApolloClient<NormalizedCacheObject> | null | {} = {};

export const initializeApollo = (url: string, chainId) => {
  const createApolloClient = new ApolloClient({
    ssrMode: typeof window === "undefined",
    uri: url,
    cache: new InMemoryCache(),
  });
  
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") {
    return createApolloClient;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient[chainId]) {
    apolloClient[chainId] = createApolloClient;

  }

  return apolloClient[chainId];
};

export const getClient = (url: string) => {
  if(!apolloClient[url]) {
    const client = new ApolloClient({
      uri: url,
      cache: new InMemoryCache(),
    });
    apolloClient[url] = client;
  }
  return apolloClient[url];
}

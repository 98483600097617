import { FunctionComponent, useState, useEffect } from "react";
import { AppCard } from "components/lib";
import { getIpfsJSONConfig, getMinifedAddress, getExplorerLink, getGasTokenSymbol, JOB_STATUS } from "utils";
import { utils } from "ethers";
export interface JobProps {
  info: object;
  key?: number;
}

const JobCard: FunctionComponent<JobProps> = (props) => {
  const [ipfsConfig, setIpfsConfig] = useState(null);
  const { info } = props;
  const payloadHash = info["ipfsHash"];
  const gasToken = getGasTokenSymbol(info['chainId']);
  useEffect(() => {
    (async () => {
      if (!ipfsConfig && payloadHash && payloadHash !== "") {
        const payloadData = await getIpfsJSONConfig(payloadHash);
        setIpfsConfig(payloadData);
      }
    })();
  }, [payloadHash, ipfsConfig]);

  const appData = {
    ...info,
    icon:
      (ipfsConfig && ipfsConfig?.metaData?.appImgUrl) ||
      "https://steer.finance/wp-content/uploads/2022/11/blockchain-300x300.png",
    name: info["name"],
    cardType: "job",
    data: {
      gasSpent: {
        type: "text",
        val: `${utils.formatEther(info["gasUsed"] || '0').toString().slice(0, 6)} ${gasToken}`,
        label: "Gas Spent",
      },
      creator: {
        type: "link",
        label: "Creator",
        val: {
          onClick: () =>
            window.open(
              `${getExplorerLink(info["chainId"])}/address/${info["vaultAddress"]["deployer"]}`
            ),
          text: getMinifedAddress(info["vaultAddress"]["deployer"]),
        },
      },
      status: {
        type: "text",
        val: JOB_STATUS[info["status"]],
        label: "Status",
      },
      btn: {
        val: {
          onClick: () => {
            info["onClick"](ipfsConfig);
          },
        },
        label: "View Details",
      },
    },
  };

  //@ts-ignore
  return <AppCard {...appData} />;
};

export default JobCard;

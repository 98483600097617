import React, { FunctionComponent } from "react";
import { Tooltip } from "./Tooltip";
//@ts-ignore
import logo from './Logo.svg';

export interface LogoProps {
  showName?: boolean;
  style?:object;
  onClickHandler?: () => void;
  tooltipId?:string;
}

export const Logo: FunctionComponent<LogoProps> = (props) => {
  return (
    <div data-testid="logo-component"  
      onClick={props.onClickHandler}
    className={`hidden md:block  items-center hover:cursor-pointer  md:flex`} 
    data-tip={props.tooltipId|| ''}
      data-for={props.tooltipId|| ''}>
      <img src={logo} alt={'logo'} style={{
        width: props.showName ? 30 : 60,
        height: props.showName ? 30: 60,
        ...props.style
      }}
      />
      {props.showName && <div className={`hidden ml-2 text-lg font-extrabold leading-8 md:block text-white-500`}>{'Steer Protocol'}</div>}
      {props.tooltipId && <Tooltip {...props} tooltipId={props.tooltipId}/>}
    </div>
  )
}

 
Logo.defaultProps = {
  showName: true,
  style: {}
};

import { Button, Modal, Table, TableLoader } from "components/lib";
import ModalHeader from "components/ModalHeader";
import { useState } from "react";
import { DataConnectorUpdateConfig, Pool, Strategy } from "utils/types";
import { useStrategies } from "queries/strategy-suggestions";


type StrategySelectionProps = {
  handleSelectStrategy: (strategy: Strategy) => void;
  selectedPool: Pool;
  preSelectedStrategy: Strategy | null,
  chainId: any; 
  filterPool? : string;
  moveNext: () => void;
  handleBack: () => void;
  setDataConnectorConfigs: (d: DataConnectorUpdateConfig[]) => void
}

const StrategySelection = ({ 
  handleSelectStrategy, selectedPool,
  preSelectedStrategy,
  chainId,
  handleBack,
  moveNext,
  setDataConnectorConfigs
}: StrategySelectionProps) => {

  const [selectedStrategy, setSelectedStrategy] = useState<Strategy | null>(preSelectedStrategy);
  const {isLoading: isStrategiesLoading, data: strategies} = useStrategies(chainId);
  const newStrategies = strategies ? strategies.map(d => {
    const aprs = (Object.values(d?.["aprs"]).map(d => d["apr"])).sort((a, b) => a-b);
    return {
      ...d,
      aprRange: aprs.length === 0 ? `4%-300%` : `${aprs[0]}% - ${d.name === 'Static Stable Strategy' ? "12%": `${aprs.length < 1? "": aprs[aprs.length -1]}% `}`,
    }
  }): [];
  
  const handleSelect = async (strategy: Strategy) => {

    strategy.deploymentType = 'clone';
    setSelectedStrategy(strategy);
    handleSelectStrategy(strategy);
    setDataConnectorConfigs(strategy.name === "Channel Multiplier Strategy" ? [{
      hash: strategy?.dataConnectorBundle?.[0]?.bundleHash,
      configData: {
        ...strategy?.dataConnectorBundle?.[0]?.configData,
        address: selectedPool.id,
        chainId: chainId
      }
    
    }]: [{
      hash: strategy.dataConnectorBundle?.[0]?.bundleHash,
      configData: {
        ...strategy.dataConnectorBundle?.[0]?.configData,
        subgraphEndpoint: selectedPool.dexUrl,
        poolAddress: selectedPool.id,
      }
    }]);
  };

  const [showStrategyDetails, setShowStrategyDetails] = useState(false);
  const [viewStrategyDetails, setViewStrategyDetails] = useState<Strategy | null>(null);

  const editBtn = {
    type: 'nprimary',
    text: 'Learn More',
    onClickHandler: (data) => {
      setViewStrategyDetails(data);
      setShowStrategyDetails(true)
    },
    casing: 'capitalize'
  }

  const columns = [
    {
      Header: 'Strategy',
      accessor: 'name',
      filterable: true,
      sortable: true,
      type: 'text',
      searchable: true,
      casing: 'capitalize',
    },
    {
      Header: 'TVL',
      accessor: 'totalTvl',
      filterable: true,
      sortable: true,
      type: 'text',
      searchable: true,
      casing: 'capitalize',
    },
    {
      Header: 'APR',
      accessor: 'aprRange',
      filterable: false,
      sortable: true,
      type: 'text',
      searchable: true,
      casing: 'capitalize',
    },
    {
      Header: 'Recommended',
      accessor: 'label',
      filterable: false,
      sortable: true,
      type: 'text',
      searchable: true,
      casing: 'capitalize',
    },
    {
      Header: '',
      accessor: 'Learn More',
      filterable: false,
      sortable: false,
      type: 'button',
      searchable: false,
    },
  ];

  const modalContent = () => {
    return (
      <div className="px-4">
        <ModalHeader
          description=""
          title={`${viewStrategyDetails["name"]} Details`}
          closeModal={() => setShowStrategyDetails(false)}
        />
        <div className="text-lg text-white-500 font-bold mt-4">
          Strategy Description
        </div>
        <div className="text-sm text-white-500">
          {viewStrategyDetails.description}
        </div>
        <div className="text-lg text-white-500 font-bold mt-4">
          Recommendation
        </div>
        <div className="text-sm text-white-500">
          {viewStrategyDetails.recommendedUsageScenarios}
        </div>
        {viewStrategyDetails.chartImgUrl && viewStrategyDetails.chartImgUrl.length > 0 && <div className="text-lg text-white-500 font-bold mt-4">
          Sample Chart View
        </div>}
        {viewStrategyDetails.chartImgUrl && viewStrategyDetails.chartImgUrl.length > 0 && <div className="flex justify-center my-2"><img alt="" src={`${viewStrategyDetails.chartImgUrl}`} /></div>}
        <div className="my-4">
          <Button
            content="Learn More"
            type="nsecondary"
            size="small"
            fullWidth={true}
            casing={"uppercase"}
            onClickHandler={() =>
              window.open(viewStrategyDetails.docUrl)
            }
          />
        </div>
      </div>
    )
  }

  return (
    <div className="p-4">
       {isStrategiesLoading && (
        <TableLoader
          //@ts-ignore
          columns={columns}
          rowCount={4}
        />
      )}
    {/* <Select
      asyncOptionLabel={formatOptionLabel}
      asyncSelect={true}
      onSelectHandler={handleSelectPool}
      asyncOption={(d) => loadOptions(d, chainId, selectedDex)}
      placeholder="Search Token By Symbol.."
    /> */}
    {selectedPool && !isStrategiesLoading && <div className="my-4">
      {/* <div className="text-md text-white-500 text-center capitalize my-2">Select the Strategy as per your pool volatility</div> */}
      <Table
        data={newStrategies}
        //@ts-ignore
        columns={columns}
        showSearchBar={false}
        rowExpandable={false}
        showPagination={false}
        headerClass={'text-gray-300'}
        rowClickable={true}
        setSelectedRows={(row) => {
          handleSelect(row.original)
        }}
        //@ts-ignore
        selectedRows={selectedStrategy}
        //@ts-ignore
        actionBtns={[editBtn]}
      />
      </div>}
     
     
      {showStrategyDetails && (
        <Modal
          isOpen={showStrategyDetails}
          size={'large'}
          content={modalContent()}
        />
      )}

        <div className="mt-4 lg:mt-6 flex flex-col-reverse md:flex-row justify-center md:justify-between">
        <div className="mt-2 md:mt-0">
          <Button
            content="back"
            type="secondary"
            size="xsmall"
            onClickHandler={handleBack}
            casing={'capitalize'}

          />
          </div>
            <Button
              content="Review Strategy"
              size="xsmall"
              type="nsecondary"
              onClickHandler={moveNext}
              disabled={!selectedStrategy}
            />

          </div>
      
    </div>
  );
};

export default StrategySelection;


"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MerklGaugeMiddleman__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [
            {
                internalType: "contract ICore",
                name: "_accessControlManager",
                type: "address",
            },
        ],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        inputs: [],
        name: "InvalidParams",
        type: "error",
    },
    {
        inputs: [],
        name: "NotGovernorOrGuardian",
        type: "error",
    },
    {
        inputs: [],
        name: "ZeroAddress",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "gauge",
                type: "address",
            },
        ],
        name: "GaugeSet",
        type: "event",
    },
    {
        inputs: [],
        name: "accessControlManager",
        outputs: [
            {
                internalType: "contract ICore",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "angle",
        outputs: [
            {
                internalType: "contract IERC20",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "gaugeParams",
        outputs: [
            {
                internalType: "bytes32",
                name: "rewardId",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "uniV3Pool",
                type: "address",
            },
            {
                internalType: "address",
                name: "rewardToken",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                internalType: "uint32",
                name: "propToken0",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "propToken1",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "propFees",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "epochStart",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "numEpoch",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "isOutOfRangeIncentivized",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "boostedReward",
                type: "uint32",
            },
            {
                internalType: "address",
                name: "boostingAddress",
                type: "address",
            },
            {
                internalType: "bytes",
                name: "additionalData",
                type: "bytes",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "merklDistributionCreator",
        outputs: [
            {
                internalType: "contract DistributionCreator",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "gauge",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "notifyReward",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "gauge",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "notifyRewardWithTransfer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "setAngleAllowance",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "gauge",
                type: "address",
            },
            {
                components: [
                    {
                        internalType: "bytes32",
                        name: "rewardId",
                        type: "bytes32",
                    },
                    {
                        internalType: "address",
                        name: "uniV3Pool",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "rewardToken",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amount",
                        type: "uint256",
                    },
                    {
                        internalType: "address[]",
                        name: "positionWrappers",
                        type: "address[]",
                    },
                    {
                        internalType: "uint32[]",
                        name: "wrapperTypes",
                        type: "uint32[]",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken0",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propToken1",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "propFees",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "epochStart",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "numEpoch",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "isOutOfRangeIncentivized",
                        type: "uint32",
                    },
                    {
                        internalType: "uint32",
                        name: "boostedReward",
                        type: "uint32",
                    },
                    {
                        internalType: "address",
                        name: "boostingAddress",
                        type: "address",
                    },
                    {
                        internalType: "bytes",
                        name: "additionalData",
                        type: "bytes",
                    },
                ],
                internalType: "struct DistributionParameters",
                name: "params",
                type: "tuple",
            },
        ],
        name: "setGauge",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
class MerklGaugeMiddleman__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.MerklGaugeMiddleman__factory = MerklGaugeMiddleman__factory;
MerklGaugeMiddleman__factory.abi = _abi;

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVaultsForPoolId = exports.vaultQuery = void 0;
const graphql_request_1 = require("graphql-request");
const index_1 = require("../helpers/index");
exports.vaultQuery = (0, graphql_request_1.gql) `
  query Vaults($poolId: String!) {
    vaults(where: { pool: $poolId }) {
      id
    }
  }
`;
function getVaultsForPoolId(poolAddress, graphUrl) {
    return __awaiter(this, void 0, void 0, function* () {
        const vaultData = yield (0, index_1.withRetry)(graphql_request_1.request, [
            graphUrl,
            exports.vaultQuery,
            {
                poolId: poolAddress.toLowerCase(),
            },
        ]);
        const vaults = vaultData === null || vaultData === void 0 ? void 0 : vaultData.vaults;
        const vaultAddresses = [];
        if (!vaults) {
            return vaultAddresses;
        }
        for (const vault of vaults) {
            vaultAddresses.push(vault.id);
        }
        return vaultAddresses;
    });
}
exports.getVaultsForPoolId = getVaultsForPoolId;

import { createContext } from "react";

export type ConsentType = {
    hasSigned?: boolean
    showConsentModal?: boolean
    setShowConsentModal: (show: boolean) => void
  }

export const ConsentContext = createContext<ConsentType>({
    hasSigned: false,
    showConsentModal: false,
    setShowConsentModal: (show: boolean) => {}
  });

import React, { FunctionComponent } from "react";
import { Tooltip } from "./Tooltip";
import { Icon } from "./Icon";
export interface LabelProps {
  align?: 'left' | 'right' | 'center';
  content: string;
  size: 'small' | 'medium' | 'large' | 'xlarge';
  color?: string;
  casing?: 'uppercase' | 'lowercase' | 'capitalize';
  tooltipContent?: string;
  iconSize?: "lg" | "xs" | "sm" | "1x" | "2x" | "3x" | "4x" | "5x" | "6x" | "7x" | "8x" | "9x" | "10x";
}


export const Label: FunctionComponent<LabelProps> = (props) => (
  <div data-testid="label-component">
    <div className={`${props.tooltipContent ? 'cursor-pointer' : ''} ${props.casing} text-${props.align} text-${props.color || 'shamrock-500'} font-medium	
    ${props.size === 'small' ? 'text-sm leading-3' : props.size === 'medium' ? 'text-base leading-4' : props.size === "large" ? 'text-lg leading-5' : "text-xl leading-6"}`}>
      {props.tooltipContent && <Tooltip tooltipContent={props.tooltipContent} tooltipId={props.tooltipContent} tooltipClickable={false} />}
      {props.tooltipContent ? <div className="flex" data-tip data-for={props.tooltipContent}>{props.content}
        <div className={`pl-2`}>
          <Icon name={'faQuestionCircle'} size={'md'}
            style={{
              color: '#52e5a5'
            }}
          />
        </div>
      </div> : props.content}
    </div>
  </div>
);

Label.defaultProps = {
  content: 'Test',
  iconSize: 'lg',
};


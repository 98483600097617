import React, { FunctionComponent, useEffect, useState } from "react";
// import copy from 'copy-to-clipboard';
import { Button } from "./Button";
import { Icon } from "./Icon";
import { Logo } from "./Logo";
import { Disclosure } from "@headlessui/react";
import { Dropdown } from "./Dropdown";
export interface NavbarProps {
  walletAddress?: string;
  selectedNetwork?: { label: string; value: string };
  networks?: Array<{
    value: string;
    label: string;
  }>;
  onSelectHandler?: (data) => void;
  onDocsClick?: () => void;
  onTwitterClick?: () => void;
  onDiscordClick?: () => void;
  content?: Array<{
    label: string;
    onClick: () => void;
  }>;
  type?: "miner" | "dapp";
  logoClickHandler?: () => void;
  showConnectButton?: boolean;
  handleConnectWallet?: () => void;
  balance?: string;
  wrapperClass?: string;
  selectedDex?: { label: string; value: string };
  dexs?: Array<{
    value: string;
    label: string;
  }>;
  onDexSelect?: (data) => void;
  showDexFilter?: boolean;
  onUDashClick?:  () => void;
}

export const Navbar: FunctionComponent<NavbarProps> = (props) => {
  // const [copyAddress, setCopyAddress] = useState(false);
  const [showDexFilter, setShowDexFilter] = useState(true);
  useEffect(() => {
    if(showDexFilter !== props.showDexFilter) {
      setShowDexFilter(props.showDexFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showDexFilter])

  const getGreeting = () => {
    const today = new Date();
    const curHr = today.getHours();
    if (curHr < 12) {
      return "Good Morning";
    } else if (curHr < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  const getMinifedAddress = (address) => {
    return (
      address.slice(0, 5) +
      "..." +
      address.slice(address.length - 3, address.length)
    );
  };

  const renderDesktopLinkView = (label, onClick, i = 0) => {
    return <div
      key={`${label}-${i}`}
      onClick={onClick}
      className="inline-flex items-center px-1 text-small cursor-pointer text-white-500"
    >
      {label}
    </div>
  }



  const renderMoibleLinkView = (label, onClick, i = 0) => {
    return <Disclosure.Button
      as="div"
      key={`${label}-${i}`}
      className="block px-4 py-2 text-base cursor-pointer text-white-500 xl:px-6 hover:bg-gray-50 hover:text-gray-700"
    >
      <div
        onClick={onClick}
      >
        {label}
      </div>
    </Disclosure.Button>
  }

  // const _setCopyAddress = () => {
  //   setCopyAddress(true);
  //   copy(props.walletAddress);
  //   setTimeout(() => {
  //     setCopyAddress(false);
  //   }, 1000);
  // }

  const moreOptions = props.type !== "miner" ?
    props.content.slice(2, props.content.length).map(d => ({
      ...d,
      value: d.label
    })).concat([{
      label: "Documentation",
      value: "Documentation",
      onClick: props.onDocsClick
    }]) : [];


  return (
    <>
      <Disclosure as="nav" className={`shadow ${props.wrapperClass}`}>
        {({ open }) => (
          <>
            <div className="px-4 mx-auto xl:px-6 xl:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex items-center flex-shrink-0">
                    {props.type === "miner" ? (
                      <div className="hidden text-xl leading-8 xl:flex text-white-500">
                        {getGreeting() +
                          (props.walletAddress
                            ? getMinifedAddress(props.walletAddress)
                            : "")}
                        {
                          <div className="ml-3">
                            <Button
                              size={"small"}
                              type="copy"
                              onClickHandler={() => { }}
                              content={props.walletAddress}
                            />
                          </div>
                        }
                      </div>
                    ) : (
                      <Logo
                        onClickHandler={() =>
                          props.logoClickHandler && props.logoClickHandler()
                        }
                      />
                    )}
                  </div>
                  <div className="hidden xl:ml-6 xl:flex xl:space-x-8">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}

                    {props.type !== "miner" &&
                      props.content.slice(0, 2).map((d, i) => renderDesktopLinkView(d.label, d.onClick, i))}
                    {/* {renderDesktopLinkView('Documentation', props.onDocsClick)} */}
                    {props.type !== "miner" && <div className="flex-shrink-0 xl:ml-4 xl:flex xl:items-center"><Dropdown
                      selected="More"
                      hasIcon={false}
                      type={'normal'}
                      options={moreOptions}
                      onSelectHandler={(val) => {
                        const opt = moreOptions.find(op => op.value === val);
                        opt.onClick();
                      }}
                      placeholder={"More"}
                      placeholderColor={"#F2f2f2"}
                    /></div>}

                  </div>
                </div>
                <div className="flex flex-row space-x-4">
                  <div className="flex items-center">
                    <div className="flex xl:ml-4 items-center ">
                      {showDexFilter && (
                        <div className="block mx-0 md:mx-4">
                          <Dropdown
                            selected={props.selectedDex?.value}
                            hasIcon={true}
                            type={'normal'}
                            options={props.dexs}
                            onSelectHandler={props.onDexSelect}
                            placeholder={"Select Dex"}
                            hideLabelOnMobileScreen={true}
                          />
                        </div>

                      )}
                      {props.onSelectHandler && (
                        <div className="block mx-2 md:ml-0">
                          <Dropdown
                            selected={props.selectedNetwork?.value}
                            hasIcon={true}
                            type={'normal'}
                            options={props.networks}
                            onSelectHandler={props.onSelectHandler}
                            placeholder={"Select Network"}
                            hideLabelOnMobileScreen={true}
                          />
                        </div>

                      )}
                      {props.showConnectButton && (
                        <div className="ml-2 md:ml-3">
                          <Button
                            content={"Connect Wallet"}
                            onClickHandler={() => props.handleConnectWallet()}
                            type={"primary"}
                            size={"small"}
                            casing={'capitalize'}
                          />
                        </div>

                      )}

                      {!props.showConnectButton && <div className="hidden mx-4 xl:flex ">
                        <Button
                          content={`${props.balance || '0.0'}`}
                          onClickHandler={() => { }}
                          type={"tertiary"}
                          size={"small"}
                        />
                        <div className="ml-2">
                          <Button
                            content={`${getMinifedAddress(props.walletAddress)}`}
                            onClickHandler={props?.["addressClick"]}
                            type={"tertiary"}
                            size={"small"}
                          />
                        </div>
                      </div>}
                    </div>
                  </div>
                  <div className="flex items-center mr-2 -ml-2 xl:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white-400 hover:text-white-500 ">
                      <span className="sr-only">Open main menu</span>
                      <Icon
                        type="normal"
                        name={`${open ? "faTimes" : "faBars"}`}
                        size="lg"
                      />
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="xl:hidden">
              <div className="pt-4 pb-3 space-y-1">
                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                {props.type !== "miner" &&
                  props.content.map((d, i) => renderMoibleLinkView(d.label, d.onClick, i))}
                {renderMoibleLinkView('User Dashboard', props.onUDashClick)}
                {renderMoibleLinkView('Documentation', props.onDocsClick)}
                {renderMoibleLinkView('Twitter', props.onTwitterClick)}
                {renderMoibleLinkView('Discord', props.onDiscordClick)}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

Navbar.defaultProps = {
  walletAddress: null,
  selectedNetwork: { label: "", value: "" },
  type: "miner",
};
import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 0a18 18 0 100 36 18 18 0 000-36zm0 32.4a14.4 14.4 0 110-28.802A14.4 14.4 0 0118 32.4z"
        fill="#FF4365"
      />
      <path
        d="M18 27a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM18 9a1.8 1.8 0 00-1.8 1.8v9a1.8 1.8 0 003.6 0v-9A1.8 1.8 0 0018 9z"
        fill="#FF4365"
      />
    </svg>
  )
}

export default SvgComponent

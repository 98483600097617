"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.merklSubgraphALMEndpoints = exports.merklSubgraphAMMEndpoints = exports.getMerklSubgraphPrefix = exports.merklFallbackTGEndpoint = exports.swapsSubgraphsEndpoint = void 0;
const constants_1 = require("../../types/constants");
const merkl_1 = require("../../types/merkl");
const sushiswapV3SubgraphPrefix = 'https://api.thegraph.com/subgraphs/name/sushi-v3/v3-';
exports.swapsSubgraphsEndpoint = {
    [constants_1.ChainId.ARBITRUM]: {
        [merkl_1.AMMType.Retro]: '',
        [merkl_1.AMMType.SushiSwapV3]: sushiswapV3SubgraphPrefix + 'arbitrum',
        [merkl_1.AMMType.UniswapV3]: 'https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-minimal',
    },
    [constants_1.ChainId.MAINNET]: {
        [merkl_1.AMMType.Retro]: '',
        [merkl_1.AMMType.SushiSwapV3]: sushiswapV3SubgraphPrefix + 'ethereum',
        [merkl_1.AMMType.UniswapV3]: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
    },
    [constants_1.ChainId.OPTIMISM]: {
        [merkl_1.AMMType.Retro]: '',
        [merkl_1.AMMType.SushiSwapV3]: sushiswapV3SubgraphPrefix + 'optimism',
        [merkl_1.AMMType.UniswapV3]: 'https://api.thegraph.com/subgraphs/name/ianlapham/optimism-post-regenesis',
    },
    [constants_1.ChainId.POLYGON]: {
        [merkl_1.AMMType.Retro]: '',
        [merkl_1.AMMType.SushiSwapV3]: sushiswapV3SubgraphPrefix + 'polygon',
        [merkl_1.AMMType.UniswapV3]: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon',
    },
};
const merklFallbackSubgraphPrefix = 'https://api.thegraph.com/subgraphs/name/anglekeeper/backup-';
const merklFallbackSubgraphPrefixUniswapV3 = `${merklFallbackSubgraphPrefix}uniswapv3-`;
const merklFallbackSubgraphPrefixSushiswapV3 = `${merklFallbackSubgraphPrefix}sushiswapv3-`;
const merklFallbackSubgraphPrefixRetro = `${merklFallbackSubgraphPrefix}retro-`;
exports.merklFallbackTGEndpoint = {
    [constants_1.ChainId.ARBITRUM]: {
        [merkl_1.AMMType.Retro]: merklFallbackSubgraphPrefixRetro + 'arbitrum',
        [merkl_1.AMMType.SushiSwapV3]: merklFallbackSubgraphPrefixSushiswapV3 + 'arbitrum',
        [merkl_1.AMMType.UniswapV3]: merklFallbackSubgraphPrefixUniswapV3 + 'arbitrum',
    },
    [constants_1.ChainId.MAINNET]: {
        [merkl_1.AMMType.Retro]: merklFallbackSubgraphPrefixRetro + 'ethereum',
        [merkl_1.AMMType.SushiSwapV3]: merklFallbackSubgraphPrefixSushiswapV3 + 'ethereum',
        [merkl_1.AMMType.UniswapV3]: merklFallbackSubgraphPrefixUniswapV3 + 'ethereum',
    },
    [constants_1.ChainId.OPTIMISM]: {
        [merkl_1.AMMType.Retro]: merklFallbackSubgraphPrefixRetro + 'optimism',
        [merkl_1.AMMType.SushiSwapV3]: merklFallbackSubgraphPrefixSushiswapV3 + 'optimism',
        [merkl_1.AMMType.UniswapV3]: merklFallbackSubgraphPrefixUniswapV3 + 'optimism',
    },
    [constants_1.ChainId.POLYGON]: {
        [merkl_1.AMMType.Retro]: merklFallbackSubgraphPrefixRetro + 'polygon',
        [merkl_1.AMMType.SushiSwapV3]: merklFallbackSubgraphPrefixSushiswapV3 + 'polygon',
        [merkl_1.AMMType.UniswapV3]: merklFallbackSubgraphPrefixUniswapV3 + 'polygon',
    },
};
const merklSubgraphPrefix = 'https://api.thegraph.com/subgraphs/name/angleprotocol/';
const getMerklSubgraphPrefix = (env) => {
    return merklSubgraphPrefix + (env !== 'prod' ? 'test-merkl-' : '');
};
exports.getMerklSubgraphPrefix = getMerklSubgraphPrefix;
const merklSubgraphAMMEndpoints = (merklSubgraphPrefix) => {
    return {
        [constants_1.ChainId.ARBITRUM]: {
            [merkl_1.AMMType.Retro]: '',
            [merkl_1.AMMType.SushiSwapV3]: merklSubgraphPrefix + 'sushiswapv3-arb',
            [merkl_1.AMMType.UniswapV3]: merklSubgraphPrefix + 'uniswapv3-arb',
        },
        [constants_1.ChainId.MAINNET]: {
            [merkl_1.AMMType.Retro]: '',
            [merkl_1.AMMType.SushiSwapV3]: merklSubgraphPrefix + 'sushiswapv3-eth',
            [merkl_1.AMMType.UniswapV3]: merklSubgraphPrefix + 'uniswapv3-eth',
        },
        [constants_1.ChainId.OPTIMISM]: {
            [merkl_1.AMMType.Retro]: '',
            [merkl_1.AMMType.SushiSwapV3]: merklSubgraphPrefix + 'sushiswapv3-opt',
            [merkl_1.AMMType.UniswapV3]: merklSubgraphPrefix + 'uniswapv3-opt',
        },
        [constants_1.ChainId.POLYGON]: {
            [merkl_1.AMMType.Retro]: merklSubgraphPrefix + 'retro-pol',
            [merkl_1.AMMType.SushiSwapV3]: merklSubgraphPrefix + 'sushiswapv3-pol',
            [merkl_1.AMMType.UniswapV3]: merklSubgraphPrefix + 'uniswapv3-pol',
        },
    };
};
exports.merklSubgraphAMMEndpoints = merklSubgraphAMMEndpoints;
const merklSubgraphALMEndpoints = (merklSubgraphPrefix) => {
    return {
        [constants_1.ChainId.ARBITRUM]: {
            [merkl_1.AMMType.UniswapV3]: {
                [merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Arrakis]: merklSubgraphPrefix + 'arrakis-arb',
                [merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Gamma]: merklSubgraphPrefix + 'gamma-arb',
                [merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].DefiEdge]: merklSubgraphPrefix + 'defiedge-arb',
                [merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Steer]: merklSubgraphPrefix + 'steer-arb',
            },
        },
        [constants_1.ChainId.MAINNET]: {
            [merkl_1.AMMType.UniswapV3]: {
                [merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Arrakis]: merklSubgraphPrefix + 'arrakis-eth',
                [merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Gamma]: merklSubgraphPrefix + 'gamma-eth',
                [merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].DefiEdge]: merklSubgraphPrefix + 'defiedge-eth',
                [merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Ichi]: merklSubgraphPrefix + 'ichi-eth',
            },
        },
        [constants_1.ChainId.OPTIMISM]: {
            [merkl_1.AMMType.UniswapV3]: {
                [merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Arrakis]: merklSubgraphPrefix + 'arrakis-opt',
                [merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Gamma]: merklSubgraphPrefix + 'gamma-opt',
                [merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].DefiEdge]: merklSubgraphPrefix + 'defiedge-opt',
                [merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Steer]: merklSubgraphPrefix + 'steer-opt',
            },
        },
        [constants_1.ChainId.POLYGON]: {
            [merkl_1.AMMType.UniswapV3]: {
                [merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Arrakis]: merklSubgraphPrefix + 'arrakis-pol',
                [merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Gamma]: merklSubgraphPrefix + 'gamma-pol',
                [merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].DefiEdge]: merklSubgraphPrefix + 'defiedge-pol',
                [merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Ichi]: merklSubgraphPrefix + 'ichi-pol',
                [merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Steer]: merklSubgraphPrefix + 'steer-pol',
            },
        },
    };
};
exports.merklSubgraphALMEndpoints = merklSubgraphALMEndpoints;

function subtractDate(timestamp, hours): number {
  const date = new Date(timestamp);
  date.setHours(date.getHours() - hours);

  return date.getTime();
}

function addDate(timestamp, hours): number {
  const date = new Date(timestamp);
  date.setHours(date.getHours() + hours);

  return date.getTime();
}

function firstDayOfWeek(dateObject, firstDayOfWeekIndex) {
  const dayOfWeek = dateObject.getDay(),
    firstDayOfWeek = new Date(dateObject),
    diff =
      dayOfWeek >= firstDayOfWeekIndex
        ? dayOfWeek - firstDayOfWeekIndex
        : 6 - dayOfWeek;

  firstDayOfWeek.setDate(dateObject.getDate() - diff);
  firstDayOfWeek.setHours(0, 0, 0, 0);

  return firstDayOfWeek.getTime();
}

function firstDateOfMonth(dateObject) {
  return new Date(dateObject.getFullYear(), dateObject.getMonth(), 1).getTime();
}

function firstDateOfYear(dateObject) {
  return new Date(dateObject.getFullYear(), 0, 1).getTime();
}

export function getQuery(
  queryObj,
  excludedVaults,
  excludedStrategyIds,
  addDeprecatedIds
) {
  const whereClause = {
    and: []
  };

  const andClause= {};
  if (queryObj["appEngine"] && queryObj["appEngine"] !== "all" && queryObj["appEngine"].length > 0) {
    andClause["beaconName_in"] = queryObj["appEngine"];
  } else {
    delete andClause["beaconName_in"];
    andClause["beaconName_not_in"] = ["ScheduledJobs", "DynamicJobs", "MultiPositionQuickSwap", "SinglePositionQuickSwap", "PermissionedSinglePositionQuickSwap", "PermissionedMultiPositionQuickSwap"];
  }

  if (queryObj["appCreatedDate"] && queryObj["appCreatedDate"] !== "all") {
    let startDate = Date.now();
    let endDate = addDate(Date.now(), 24);

    const subtractCount = {
      this_day: subtractDate(startDate, 24),
      this_week: firstDayOfWeek(new Date(), 1),
      this_month: firstDateOfMonth(new Date()),
      this_year: firstDateOfYear(new Date()),
    };

    startDate = subtractCount[queryObj["appCreatedDate"]];

    andClause["createdAt_gt"] = Math.floor(startDate / 1000);
    andClause["createdAt_lt"] = Math.ceil(endDate / 1000);
  }

  if (queryObj["assets"] && queryObj["assets"] !== "all") {
    andClause["token0"] = queryObj["assets"].split("-")[0];
    andClause["token1"] = queryObj["assets"].split("-")[1];
  }

  if (queryObj["gasUsed_gt"]) {
    andClause["gasUsed_gt"] = queryObj["gasUsed_gt"];
  }

  if (excludedStrategyIds.length > 0 && !addDeprecatedIds) {
    // convert it to big number and then to number
    excludedStrategyIds = excludedStrategyIds.map((id) => {
      return "0x" + parseInt(id, 16).toString(16);
    });
  }

  andClause["strategyToken_not_in"] = !addDeprecatedIds
    ? excludedStrategyIds
    : [""];

  if (queryObj["deployer"]) {
    andClause["deployer"] = queryObj["deployer"];
  }

  andClause["id_not_in"] = !addDeprecatedIds ? excludedVaults : [""];


  whereClause["and"] = [
    andClause,
    {
      "or": [
        { token0Symbol_contains_nocase: queryObj["search"] || "" },
        { token1Symbol_contains_nocase: queryObj["search"] || "" }
      ]
    }
  ]

  return whereClause;
}

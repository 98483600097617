import { useState, useEffect } from "react";
import axios from "axios";

type responseData = Array<{
  id: string;
  reason: string;
}>;

type IpStackResponse = {
  deprecatedBundles: responseData;
  loadingDeprecatedBundles: boolean;
};

export const useDeprecatedBundles = (chainId: number): IpStackResponse => {
  const [loadingDeprecatedBundles, setLoadingDeprecatedBundles] =
    useState(true);
  const [deprecatedBundles, setDeprecatedBundles] =
    useState<responseData | null>(null);
  const url =
    "https://ro81h8hq6b.execute-api.us-east-1.amazonaws.com/deprecated-bundles";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<responseData>(url);

        setDeprecatedBundles(
          response["data"].filter(
            (deprecatedBundle: any) => deprecatedBundle["chainId"] === chainId
          )
        );
        setLoadingDeprecatedBundles(false);
      } catch (err) {
        console.log("Error fetching deprecated-bundles data");
        setLoadingDeprecatedBundles(false);
      }
    };
    setDeprecatedBundles([]);
    fetchData();
  }, [chainId]);

  return { loadingDeprecatedBundles, deprecatedBundles };
};

import React, { FunctionComponent } from "react";

export interface TitleProps {
  align?: 'left' | 'right' | 'center';
  content: string;
  size: 'sm' | 'base' | 'lg' | 'xl';
  color?: string;
  casing?: 'uppercase' | 'lowercase' | 'capitalize';
}

export const Title: FunctionComponent<TitleProps> = (props) => (
  <div data-testid="title-component">
    <div className={`${props.casing} text-${props.align} text-${props.color || 'shamrock-500'}
    font-bold
    ${props.size === 'sm' ? 'text-base leading-5' : props.size === 'base' ? 'text-lg leading-6' : props.size === 'lg' ? 'text-2xl leading-8' : 'text-4xl leading-10'}`}>
      {props.content}
    </div>
  </div>
);

Title.defaultProps = {
  content: 'Test',
};


import React, { FunctionComponent, ReactNode } from "react";
import { Icon } from "./Icon";
import { Container } from "./Container";

export interface CardProps {
  value?: string;
  label: string;
  secondaryLabel?: string;
  onClick?: () => void;
  showIcon?: boolean;
  content?: ReactNode;
  size?: "small" | "medium" | "large";
}

export interface BaseCardProps {
  children?: ReactNode;
  loading?: boolean;
}

export const Card: FunctionComponent<CardProps> = (props) => {
  return (
    <div data-testid="card-component"
      style={{
        minWidth: "140px"
      }}
    >
      <Container loading={!(props.value && props.value.length !== 0) && !props.content}
      >
        {((props.value && props.value.length !== 0) || props.content) && (
          <div
            className={`text-white-400 font-medium
            text-sm lg:text-lg`}
          >
            {props.value ? props.value : props.content}
          </div>
        )}
        <div
          className={`border-0 border-t border-solid border-lynch-rgba shadow-xs h-0  mt-2.5`}
        />
        <div className="flex pt-2.5 items-center justify-between">
          <div className="flex items-center">
            {props.showIcon && (
              <Icon type="token" tokenSize="small" token={"steer"} />
            )}
            <div
              className={`font-medium text-xs	leading-4	tracking-wider	uppercase text-shamrock-500`}
            >
              {props.label}
            </div>
          </div>
          {props.secondaryLabel && (
            <div
              className="text-xs underline cursor-pointer text-gray-primary"
              onClick={props.onClick}
            >
              {props.secondaryLabel}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

Card.defaultProps = {
  size: "large",
};

Card.defaultProps = {};

import React, { FunctionComponent } from "react";
import { AnimatedLoader } from "./AnimatedLoader";
import { ColumnTypeObj } from "./Table";

export interface TableLoaderProps {
  columns: Array<ColumnTypeObj>;
  rowCount?: Number;
  headerClass?: string;
}

export const TableLoader: FunctionComponent<TableLoaderProps> = (props) => {
  const colWidth = 100 / props.columns.length;

  return (
    <div data-testid="TableLoader-component overflow-y-auto">
      <div className="flex flex-col">
        <div className={`flex items-center header justify-evenly flex-start ${props.headerClass}`}>
          {props.columns.map((column, index) => {
            return (
              <div
                key={column.Header + index}
                className={`px-6 py-2 text-left uppercase leading-3	tracking-wider`}
                style={{
                  width: `${colWidth}%`,
                  fontSize: "10px",
                }}
              >
                {column.Header}
              </div>
            );
          })}
        </div>
        <div>
          {Array.apply(0, Array(props.rowCount || 3)).map((_, i) => (
            <div className="relative min-w-full px-6 py-2.5 mt-3 h-10 rounded-xl bg-indigo-primary" key={'table loader' +i}>
              <AnimatedLoader shape="box" height="20px" rounded={true} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

TableLoader.defaultProps = {
  columns: [],
  headerClass: "text-lynch-500",
};

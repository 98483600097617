import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSlot0 } from "hooks/useSlot0";
import { useCandleFromSwaps, usePoolSwaps } from 'queries/pool-swaps';
import { useDataConnectorConfigs } from "queries/useDataConnectorConfigs";
import { DataConnectorUpdateConfig, ExecutionBundleParameters, Pool, Strategy } from 'utils/types';
import { getDataFromConfig, hasStrategyChainReadForSlot0 } from '../helper';
import StrategySimulation from './simulation/strategySimulation';

type LiquidityGraphProps = {
  dataConnectorConfigs:  DataConnectorUpdateConfig[]; // replace 'any' with the actual type of your strategyConfigs
  executionConfigs: ExecutionBundleParameters | null;
  selectedStrategy: Strategy;
  selectedPool: Pool;
  chainId: number,
};


const LiquidityGraph = ({ dataConnectorConfigs, executionConfigs, selectedStrategy, selectedPool, chainId  }: LiquidityGraphProps) => {
  // const { configuredCandleWidth } = configureSettings(selectedStrategy);
  const isChainRead = hasStrategyChainReadForSlot0(selectedStrategy);
  const currentTick = useSlot0(isChainRead ? selectedPool.id: null);

  const {data: connectorConfigs, isLoading: isConnectorConfigLoading} = useDataConnectorConfigs(selectedStrategy);
  const candleWidth =  isConnectorConfigLoading? null: getDataFromConfig('candleWidth', selectedStrategy, connectorConfigs, dataConnectorConfigs)
  const lookback  = isConnectorConfigLoading? null: getDataFromConfig('lookback', selectedStrategy, connectorConfigs, dataConnectorConfigs);
  const { data: swaps , isLoading} = usePoolSwaps(selectedPool.id, selectedPool.dexUrl, lookback);


  const {data: candles} = useCandleFromSwaps(
    selectedPool.id,
    chainId,
    swaps,
    candleWidth,
    lookback,
    currentTick,
  );

  const showChart = isChainRead ? currentTick : candles && candles.length > 0
  return (
    <div className="">

      {isLoading && (
        <div className="flex justify-center items-center mt-2 text-white-500">
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            size="2x"
            className="text-white"
          />
          <span className="ml-2 text-white">Fetching swaps ...</span>
        </div>
      )}

      {!isLoading && swaps && swaps.length === 0 && 
        <div className="flex justify-center font-bold text-white-500">
          No swaps available for this pool
        </div>
      }
      {showChart && <StrategySimulation 
        candles={candles}
        executionConfigs={executionConfigs}
        selectedPool={selectedPool}
        selectedStrategy={selectedStrategy}
        currentTick={currentTick}
        candleWidth={candleWidth}
        lookback={lookback}
      />}
    </div>
  );
};

export default LiquidityGraph;

import { Badge, Button, Dropdown, Input, Label } from "components/lib";
import { useEtherBalance, useEthers } from "@usedapp/core";
import { utils } from "ethers";
import { useChainId } from "hooks/useChainId";
import { useState } from "react";
import { frequencyOptions, getChainName, getGasTokenSymbol } from 'utils';
import { Strategy, Pool, StrategyDeploymentConfig } from "utils/types";
import { useGasConfig } from "queries/gas-config";

type StrategyDeploymentConfigProps = {
  handleStrategyDeploymentConfig: (key, value) => void;
  strategyDeploymentConfig: StrategyDeploymentConfig;
  handleNext: () => void;
  selectedStrategy: Strategy;
  selectedPool: Pool;
};

const StrategyDeploymentConfigForm = ({
  handleStrategyDeploymentConfig,
  strategyDeploymentConfig,
  handleNext,
  selectedStrategy,
  selectedPool
}: StrategyDeploymentConfigProps) => {
  
  const [chainId] = useChainId();
  const {account} = useEthers();
  const gasToken = getGasTokenSymbol(chainId);
  const etherBalance = useEtherBalance(account, { chainId });

  const ethBalance = utils.formatEther(etherBalance || "0");

  const [fundBalance, setFundBalance] = useState('');

  const {isLoading: isGasConfigLoading, data: gasConfig } = useGasConfig();

  let options = [];

  if (!isGasConfigLoading && gasConfig && options.length === 0) {
    options = Array.from({ length: 4 }, (_, counter) => {
      const gasRequired = gasConfig[getChainName(chainId).toLowerCase()] * (counter + 1);
      return {
        label: `${gasRequired} ${getGasTokenSymbol(chainId)}`,
        value: gasRequired,
      };
    });

    // options.push({
    //   label: `0.01 ${getGasTokenSymbol(chainId)}`,
    //   value: '0.01',
    // })
  }

  const hasError = Number(ethBalance) < Number(fundBalance);

  const handleFundGasUpdate = (fundBalance) => {
    setFundBalance(fundBalance);
    handleStrategyDeploymentConfig("fundGas", fundBalance);
  }
  
  return (
    <div className="w-full">
       <div className="mt-4">
          {/* <div className="flex justify-between">
            <div className="text-md text-white-500 capitalize mb-2"></div>
            <div className="flex text-white-500 mr-2">{`${gasToken} Balance: ${millify(Number(ethBalance))} ${gasToken}`}</div>
          </div> */}
            <Input
              type={'number'}
              heading="Fund gas"
              haserror={hasError}
              onChange={handleFundGasUpdate}
              val={fundBalance}
              placeholder={ethBalance === "0.0" || hasError
              ? `${gasToken} Balance is low, please fund the account.`
              : "Select required gas"}
              optionalText={
                <div className="flex">
                  {options.map((d, i) => (
                  <div className={i === 0 ? "mr-1" : "mx-1"} key={d.val}>
                    <Badge
                      onClick={() => {
                        handleFundGasUpdate(d.value)
                      }}
                      content={d.label}
                      size={"xsmall"}
                    />
      
                  </div>
                ))}
                </div>
                
              }
            />
            {hasError && <span className="text-red-300 flex justify-end">Wallet Balance Is Not Sufficient.</span>}
          {/* <Dropdown
              options={options}
              onSelectHandler={(value) => {
                setFundBalance(value);
                
              }}
              
              type={"normal"}
              hasIcon={false}
              selected={fundBalance}
              backgroundType="white"
            /> */}
        </div>
        <div className="mt-4">
          <Label
            content={"Strategy Frequency"}
            size={"medium"}
            casing={"capitalize"}
            color={"white-500"}
          />
          <div className="mt-4">
            <Dropdown
              options={frequencyOptions}
              onSelectHandler={(val: string) =>
                handleStrategyDeploymentConfig("epochLength", val)
              }
              placeholder={"Execute every 1hr, 2hr, daily etc"}
              type={"normal"}
              hasIcon={false}
              selected={strategyDeploymentConfig.epochLength}
            />
          </div>
        </div>
       
      <div className="flex items-center justify-end flex-col w-full md:flex-row mt-4">
        <Button
          content="Deploy"
          type="nprimary"
          size="xsmall"
          casing={"uppercase"}
          onClickHandler={() => {
            handleStrategyDeploymentConfig("fundGas", fundBalance);
            handleNext();
          }}
          disabled={
            !selectedStrategy ||
            !selectedPool ||
            hasError || 
            Number(fundBalance) <= 0
          }
        />
      </div>
    </div>
  );
};

export default StrategyDeploymentConfigForm;

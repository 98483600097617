import { FunctionComponent, useState } from "react";
import {
  Badge,
  Button,
  Input,
} from "components/lib";
import {
  useContractFunction,
  useEthers,
} from "@usedapp/core";
import { BigNumber, utils } from "ethers";
import ModalHeader from "components/ModalHeader";
import { Contract } from "@ethersproject/contracts";
import { isValidNumber, getContractByNetwork, beaconContractNameMapper } from 'utils';
import { useCall } from "@usedapp/core";
import { useChainId } from "hooks/useChainId";

export interface WithdrawFeesProps {
  vault: Object;
  closeModal: () => void;
}

const WithdrawFees: FunctionComponent<WithdrawFeesProps> = (props) => {
  const {vault} = props;
  const { account } = useEthers();
  const [chainId] = useChainId();

  const [isInvalidWithdrawInput, setIsInvalidWithdrawInput] = useState(false);
  const [fees0WithdrawInput, setFees0WithdrawInput] = useState("");
  const [fees1WithdrawInput, setFees1WithdrawInput] = useState("");

  const [accruedStrategiesFees0, setAccruedStrategiesFees0] = useState(BigNumber.from('0'));
  const [accruedStrategiesFees1, setAccruedStrategiesFees1] = useState(BigNumber.from('0'));
  
  const [fees0Loading, setFees0Loading] = useState(false);
  const [fees1Loading, setFees1Loading] = useState(false);
  
  const VaultContract = getContractByNetwork(chainId,  vault["vaultType"] ? beaconContractNameMapper[vault["vaultType"]] : '');
  const vaultInterface = new utils.Interface(VaultContract.abi);
  const vaultContract = new Contract(vault["id"], vaultInterface);
  const {send: withdraw } = useContractFunction(
    //@ts-ignore
    vaultContract,
    "strategistCollectFees",
    { transactionName: `Withdrawal of fees0 ${fees0WithdrawInput} and fees1 ${fees1WithdrawInput} from strategy ${vault['name']} from asset ${vault['asset']}` }
  );
  
  const fees0 = useCall({
    contract: vaultContract,
    method: "accruedStrategistFees0",
    args: [],
  });

  if (fees0 && fees0Loading === false) {
    setAccruedStrategiesFees0(fees0 && fees0.value.length > 0 ? fees0.value[0]: BigNumber.from('9'));
    setFees0Loading(true);
  }
 
  const fees1 = useCall({
    contract: vaultContract,
    method: "accruedStrategistFees1",
    args: [],
  });

  if (fees1 && fees1Loading === false) {
    setAccruedStrategiesFees1(fees1 && fees1.value.length > 0 ? fees1.value[0]: BigNumber.from('9'));
    setFees1Loading(true);
  }
 

  const handleWithdrawInput = async () => {

    const amount0Value = utils.parseUnits(fees0WithdrawInput, vault['token0Decimals']);    
    const amount1Value = utils.parseUnits(fees1WithdrawInput, vault['token1Decimals']);    
  

    if (accruedStrategiesFees0.gte(amount0Value) && isValidNumber(fees0WithdrawInput) 
      && accruedStrategiesFees1.gte(amount1Value) && isValidNumber(fees1WithdrawInput) ) {
      // checks if user has enough LPToken to withdraw
      setIsInvalidWithdrawInput(false); // validates the input

      try {
        await withdraw(
          utils.parseUnits(fees0WithdrawInput, vault["token0Decimals"]), // Fees0 Occurred
          utils.parseUnits(fees1WithdrawInput, vault["token1Decimals"]), // Fees1 Occurred
          account // Address of the user
        );
        props.closeModal();
      } catch (error) {
        console.log("error", error);
        // props.showError("withdraw failed", error);
      }
    } else {
      setIsInvalidWithdrawInput(true);
    }
  };

  
  const maxBtnClickHandler = (feesType: number) => {

    if (feesType === 0) {
      if(accruedStrategiesFees0.gt(0)) {
        setFees0WithdrawInput(utils.formatUnits(
            accruedStrategiesFees0, vault["token0Decimals"])
          )
      } else {
        return;
      }
    } else {
      if(accruedStrategiesFees1.gt(0)) {
        setFees1WithdrawInput(utils.formatUnits(
            accruedStrategiesFees1, vault["token1Decimals"])
          )
      } else {
        return;
      }
    }
    
  }

  const handleFeesInput = (type, inputText) => {
    if (type === 0) {
      setFees0WithdrawInput(inputText);
    }
    else if(type === 1) {
      setFees1WithdrawInput(inputText);
    }
  }

  const renderDisableInput = (type) => {
    const optionalText = `${parseFloat(
      utils.formatUnits(
        type === 0
          ? accruedStrategiesFees0
          : accruedStrategiesFees1,
        type === 0
          ? vault['token0Decimals']
          : vault['token1Decimals']
      )
    ).toFixed(4)}`;

    return (
      <div className={``}>
        <Input
          heading={`Fees{${type}) - ${vault[type === 0 ? "token0Symbol" : "token1Symbol"]}`}
          type={`text`}
          disabled={false}
          optionalText={`Fees-${type} : ${optionalText}`}
          placeholder={type === 0 ? fees0WithdrawInput : fees1WithdrawInput}
          align={`vertical`}
          onChange={(e) => handleFeesInput(type, e)}
          val={type === 0 ? fees0WithdrawInput : fees1WithdrawInput}
          maxButton={<Badge
            content={`max`}
            size={`xsmall`}
            bgColor={`green-200 p-1`}
            onClick={() => maxBtnClickHandler(type)}
          />}
        />
      </div>
    )
  };

  const isWithdrawDisabled = () => {

    if (accruedStrategiesFees0.lte(0) || accruedStrategiesFees1.lte(0)) {
      return true;
    }

    if(fees0WithdrawInput.length === 0 && fees0WithdrawInput.length === 0) {
      return true;
    }
    
    return false;
  }

  return (
    <div className={``}>
      <ModalHeader
        closeModal={props.closeModal}
        description={(vault["name"] + " " + vault["asset"]).toUpperCase()}
        title={`Withdraw Fees ${vault["name"]}`}
      />
      <div className={`grid grid-rows-2 gap-5 p-4`}>
        {renderDisableInput(0)}
        {renderDisableInput(1)}
      </div>
      <div className={`col-span-1 w-full place-self-end p-4`}>
        <Button
          onClickHandler={handleWithdrawInput}
          disabled={isInvalidWithdrawInput || isWithdrawDisabled()}
          content={
            !isInvalidWithdrawInput
              ? "Withdraw"
              : "Insufficient Fees Earned"
          }
          casing={`uppercase`}
          type={`tertiary`}
          fullWidth={true}
          size="small"
        />
      </div>
    </div>
  );
};

export default WithdrawFees;

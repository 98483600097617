import React, { FunctionComponent } from "react";
import { Icon } from "./Icon";
export interface FooterProps {
  links?: Array<{
    name: string;
    onClick: () => void;
  }>;
  wrapperClass?: string;
  onTwitterClick?: () => void;
  onDiscordClick?: () => void;
}

const showLinks = (link) => {
  return <div key={link.name} className="mx-1 underline cursor-pointer lg:mx-2" onClick={link.onClick}>{link.name}</div>
}

const renderDesktopIconView = (type, onClick) => {
  return <div className="hidden xl:flex-shrink-0 xl:flex xl:items-center xl:ml-4">
    <Icon
      type="token"
      token={type}
      tokenSize="small"
      onClickHandler={onClick}
    />
  </div>
}

export const Footer: FunctionComponent<FooterProps> = (props) => {
  return (
    <div data-testid="Footer-component"
    className={`w-full md:absolute md:left-0 md:right-0 mx-auto md:bottom-0 ${props.wrapperClass}`}
    >
      <div className={`relative flex flex-col flex-wrap items-center justify-center text-white-500 `}>
        <span className="">Made with ❤️ by Steer Protocol</span>
        <div className="flex lg:hidden">
          {props?.links.map(l => showLinks(l))}
        </div>
     
      </div>
      <div className="absolute bottom-0 hidden py-2.5 lg:flex right-20 text-white-500">
        {renderDesktopIconView('Twitter', props.onTwitterClick)}
        {renderDesktopIconView('Discord', props.onDiscordClick)}
      </div>
      <div className="absolute bottom-0 hidden py-2 lg:flex right-30 text-white-500">
      {props?.links.map(l => showLinks(l))}
      </div>
    </div>
  );
};

Footer.defaultProps = {
  links: []
};

import React, { useState, FunctionComponent } from "react";
import Select, { components } from "react-select";
import AsyncSelect from 'react-select/async';

import { Icon } from "./Icon";
import {customStyles} from './Dropdown';
export interface SelectProps {
  type?: string;
  selected?: any;
  placeholder?: string;
  onSelectHandler: (data) => void;
  options?: any;
  isSearchable?: boolean;
  backgroundType?: string;
  handleInputChange?:(data) => void;
  asyncSelect?: boolean;
  filterOption?: any;
  formatOptionLabel?: any;
  isLoading?: boolean;

}

const defaultCustomStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: "10px 24px",
    margin: 0,
    backgroundColor: "transparent",
    color: "#f2f2f2",
    "&:hover": {
      cursor: "pointer",
    },
    display: "flex",
    alignItems: "center",
  }),
  control: (styles, state) => {
    return {
      ...styles,
      boxShadow:
        "-10px -10px 20px rgba(255, 255, 255, 0.05), 10px 10px 20px rgba(0, 0, 0, 0.15)",
      borderRadius: 10,
      justifyContent: "flex-start",
      padding: "8px",
      fontSize: "16px",
      lineHeight: "22px",
      color: "#F2F2F2",
      backgroundColor: "transparent",
      border: "none",
      width: "236px",
    };
  },
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#52E5A5",
  }),
  valueContainer: (styles) => ({
    ...styles,
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#373E68",
    boxShadow:
      "inset -2px -2px 2px rgba(50, 56, 99, 0.9), inset 2px 2px 8px rgba(24, 29, 64, 0.4)",
    borderRadius: 10,
    borderRight: 0,
    paddingRight: "20px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition, color: "#F2f2f2" };
  },
  indicatorSeparator: (styles) => ({ display: "none" }),
  dropdownIndicator: (styles) => ({ display: "none" }),
  menu: (styles) => ({
    ...styles,
    marginTop: 15,
    border: "0.5px solid #5C738A",
    borderRadius: 10,
    backgroundColor: "#373E68",
    color: "#F2f2f2",
    width: "236px",
  }),
  menuList: (styles) => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

const CustomOption = ({ props }) => {
  const { data } = props;
  // const symbol =
  //   otherNetwork.indexOf(data.label) === -1
  //     ? manifest.find((item) => item.name === data.label).symbol.toLowerCase()
  //     : "eth";
  const isSelected = !!props.selected
    ? props.selected.label === data.label
    : false;
  return (
    <components.Option {...props}>
      <div
        className={`flex bg-cloud-burst-500 shadow-xl rounded-full mr-2`}
        style={{
          width: 18,
          height: 18,
          padding: 1,
        }}
      >
        <div
          className={`bg-cloud-burst-500 shadow-${
            isSelected ? "xxs" : "sm"
          } rounded-full`}
          style={{
            width: 15.6,
            height: 15.6,
            padding: 2.8,
          }}
        >
          {isSelected && (
            <div
              className={`bg-shamrock-500  rounded-full`}
              style={{
                width: 10,
                height: 10,
              }}
            />
          )}
        </div>
      </div>
      {/* <Icon token={symbol || "eth"} type={"token"} tokenSize="small" /> */}
      {data.label}
    </components.Option>
  );
};

const CustomValueContainer = ({ props }) => {
  const label = props.getValue()[0]?.label;
  if (!label) {
    return (
      <components.ValueContainer {...props}>
        <div>{props.placeholder}</div>
        <Icon
          size-={"lg"}
          name={props.isMenuOpen ? "faChevronUp" : "faChevronDown"}
          style={{ color: "#f2f2f2" }}
        />
      </components.ValueContainer>
    );
  } else {
    // const symbol =
    //   otherNetwork.indexOf(label) === -1
    //     ? manifest.find((item) => item.name === label)?.symbol.toLowerCase()
    //     : "eth";
    return (
      <components.ValueContainer {...props}>
        <div className={`flex px-2 py-2`}>
          {/* <Icon token={symbol || "eth"} type={"token"} tokenSize="small" /> */}
          {label}
        </div>
        <Icon
          size-={"lg"}
          name={props.isMenuOpen ? "faChevronUp" : "faChevronDown"}
          style={{ color: "#f2f2f2" }}
        />
      </components.ValueContainer>
    );
  }
};

const CustomControl = ({ props }) => {
  return (
    <div onClick={() => props.setIsMenuOpen(!props.menuIsOpen)}>
      <components.Control {...props} />
    </div>
  );
};

const CustomMenu = ({ props }) => {
  return <components.Menu {...props} />;
};

export const SelectComponent: FunctionComponent<SelectProps> = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onSelection = (data: Object) => {
    if (props.onSelectHandler) {
      props.onSelectHandler(data);
    }
  };

  const handleInputChange = (text: string) => {
    if(props.handleInputChange) {
      props.handleInputChange(text);
    }
  }

  if(!props.asyncSelect) {
    if(props.formatOptionLabel) {
      return (
        <Select
        options={props.options}
        value={props.selected}
        onChange={props.onSelectHandler}
        onInputChange={props.handleInputChange}
        isClearable={true}
        isLoading={props.isLoading}
        formatOptionLabel={props.formatOptionLabel}
        filterOption={props.filterOption}
        placeholder={props.placeholder}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            padding: "1px",
            fontSize: "14px",
            lineHeight: "18px",
          }),
        }}
      />
      )
    } else {
      return (
        <Select
          options={props.options}
          components={
            !props.isSearchable && 
             {
                  Option: (compProps: any) => (
                    <CustomOption props={{ ...props, ...compProps }} />
                  ),
                  ValueContainer: (compProps: any) => (
                    <CustomValueContainer
                      props={{ ...props, ...compProps, isMenuOpen }}
                    />
                  ),
                  Control: (compProps: any) => (
                    <CustomControl
                      props={{ ...props, ...compProps, setIsMenuOpen, isMenuOpen }}
                    />
                  ),
                  Menu: (compProps: any) => (
                    <CustomMenu
                      props={{ ...props, ...compProps, setIsMenuOpen, isMenuOpen }}
                    />
                  ),
                }
          
          }
          onChange={(e) => onSelection(e)}
          styles={props.isSearchable ? customStyles(props, true): defaultCustomStyles}
          value={props?.selected?.label?.length === 0 ? null : props.selected}
          placeholder={props.placeholder || ""}
          onMenuOpen={() => setIsMenuOpen(true)}
          onMenuClose={() => setIsMenuOpen(false)}
          onInputChange={(e) => handleInputChange(e)}
          menuIsOpen={isMenuOpen}
          isSearchable={props.isSearchable}
        />
      );
    }
  } else {
    return (
      <AsyncSelect
      className="block w-full rounded-md bg-white border-gray-300"
      formatOptionLabel={props.formatOptionLabel}
      onChange={onSelection}
      loadOptions={props.filterOption}
      placeholder={props.placeholder}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          padding: "1px",
          fontSize: "14px",
          lineHeight: "18px",
        }),
      }}
    />
    )
  }
};

SelectComponent.defaultProps = {};

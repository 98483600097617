import React, { FunctionComponent } from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

export interface ProgressBarProps {
  percent: number;
  color?: string;
}

export const ProgressBarComponent: FunctionComponent<ProgressBarProps> = (props) => (
  <div data-testid="ProgressBar-component">
    <ProgressBar
      percent={props.percent}
      filledBackground={props.color}
    />
  </div>
);

ProgressBarComponent.defaultProps = {
  percent: 10,
};


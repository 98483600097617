//@ts-nocheck
import { Icon, Label, Modal, Title } from "components/lib";
import { useEthers } from "@usedapp/core";
import { useEffect } from 'react';
import { sendNotification } from './Container';
import CoinbaseWallet from './svg/CoinbaseWallet';
import Metamask from "./svg/Metamask";
import WalletConnect from "./svg/WalletConnect";
import { useChainId } from "hooks/useChainId";
import {logEventInSentry, getChainName} from 'utils';

const ConnectWalletModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const {
    activateBrowserWallet,
    error
  } = useEthers();
  const [chainId] = useChainId();

  

  const walletConnections = [
    {
      name: "Metamask",
      logo: Metamask,
      size: "h-28 w-28",
      onClick: () => {
        try {
          activateBrowserWallet({ type: 'metamask' })
        } catch(e) {
          logEventInSentry('metamask-account-failed', {
            ...e,
            date: new Date()
          });
          console.log('error in connecting with metamask', e);
        }
      },
    },
    {
      name: "WalletConnect",
      logo: WalletConnect,
      size: "h-24 w-24 mb-4",
      onClick: async () => {
        try {
          // await provider.enable();
          // await activate(provider);
            activateBrowserWallet({type: `walletConnectV2_${getChainName(chainId).toLowerCase()}`})
        } catch(e) {
          logEventInSentry('wallet-connect-account-failed', {
            error: e,
            date: new Date()
          });
        }
      },
    },
    // {
    //   name: "Coinbase Wallet",
    //   logo: CoinbaseWallet,
    //   size: "h-24 w-24 mb-4",
    //   onClick: () => {
    //     try {
    //       activateBrowserWallet({ type: 'coinbase' })
    //     } catch(e) {
    //       logEventInSentry('coinbase-account-failed', {
    //         ...e,
    //         date: new Date()
    //       });
    //       console.log('error in connecting with coinbase', e);
    //     }
    //   }
    // }
  ];

  useEffect(() => {
    if(error) {
      sendNotification({
        type: "error",
        transactionName: `Failed to connect.`,
        transaction: {
          msg: `Please Check On Wallet Connector Used`,
        },
      });
    }
  }, [error])

  return (
    <Modal
      isOpen={isOpen}
      size={"small"}
      content={
        <>
          <div className="absolute top-5 right-7 text-white-100">
            <Icon
              name={`faTimes`}
              className={`text-san-marino-50 `}
              size={`1x`}
              color={"bg-white-100"}
              onClickHandler={() => setIsOpen(false)}
            />
          </div>

          <div className="p-1">
            <Title
              content={`Connect Wallet`}
              align={`left`}
              casing={`uppercase`}
              size={`base`}
            />
            <div
              className={`border-0 border-t border-solid border-lynch-rgba shadow-xs h-0  mt-2.5`}
            />
            <div className="flex flex-col justify-between my-10 md:flex-row item-center">
              {walletConnections.map((connection, key) => {
                return (
                  <div
                    key={key}
                    className="flex flex-col items-center justify-center flex-1 w-full h-full mt-8  md:mt-0"
                    onClick={() => {
                      try {
                        connection.onClick();
                      } catch(e) {
                        console.log('connect error', e);
                      }
                      setIsOpen(false);
                    }}
                  >
                    <div className="p-2 cursor-pointer">
                      <div
                        className={`${connection.size} md:mr-2 z-10 `}
                        style={{ minWidth: "1rem" }}
                        height="auto"
                        width="auto"
                      >
                        {connection["name"] === 'Metamask' && <Metamask/>}
                        {connection["name"] === 'WalletConnect' && <WalletConnect/>}
                        {connection["name"] === 'Coinbase Wallet' && <CoinbaseWallet/>}
                        </div>
                      <Label
                        align={"center"}
                        content={connection.name}
                        color={"white-500"}
                        casing={"capitalize"}
                        size={"medium"}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      }
    />
  );
};
export default ConnectWalletModal;

import React, { FunctionComponent } from "react";
export interface LinkProps {
  type?: string;
  content: string;
  onClick?: () => void;
  color?: string;
  hoverColor?: string;
  target?: '_blank' | '_self';
  underline?: boolean;
  casing?: 'uppercase' | 'lowercase' | 'capitalize';
  href?: string;
  textSize?: string
}

export const Link: FunctionComponent<LinkProps> = (props) => {
  const onClickHandler = (e) => {
    if(props.onClick){
      e.preventDefault();
      props.onClick();
    }else {
      if(props.href) {
        window.open(props.href);
      }
    }
  }
  return (
    <div data-testid="link-component">
      <div
        onClick={(e) => {
          onClickHandler(e)
        }}
        className={`cursor-pointer
          text-${props.textSize}
          ${props.casing}
          ${props.underline ? 'underline' : 'no-underline'}
          ${
          props.type === "primary"
            ? "text-black-400 hover:text-indigo-500 bg-transparent"
            : "text-white-400 hover:text-green-500 "
        }`}
      >
        {props.content}
      </div>
    </div>
  );
};

Link.defaultProps = {
  type: "primary",
  target: '_blank',
  casing: 'capitalize',
};

import React, { useState, useRef } from "react";
import { Label, JsonForm, Button } from "components/lib";
import uniswapV3Json from 'utils/vaultConfig/uniswapV3.json';
import pancakeV3Json from 'utils/vaultConfig/pancakeV3.json';
import { VaultConfig } from "../common/helper";

const ConfigVault = ({
  handleBack,
  updateVaultConfig,
  selectedDex,
  vaultConfig,
  poolFee,
}: {
  handleBack: () => void;
  updateVaultConfig: (data) => void;
  selectedDex: string;
  vaultConfig: VaultConfig;
  poolFee: string;
}) => {
  let submitFormRef = useRef(null);
  let config = uniswapV3Json;
  if(selectedDex.indexOf('pancake') !== -1) {
    config = pancakeV3Json;
  }
  if(vaultConfig) {
    Object.keys(vaultConfig).map((key) => {
      config["properties"][key] = {
        ...config["properties"][key],
        default: vaultConfig[key],
      };
      return null;
    });
  } 

  const [formData, setFormData] = useState(vaultConfig);
  return (
    <div>
      <div className="p-0 mx-4 mt-4 ">
        <div className="flex items-center justify-between">
          <Label
            content={"Config Steer Engine For App Type"}
            size={"large"}
            casing={"capitalize"}
            color={"white-500"}
          />

          <a
            className="capitalize text-white-500 hover:underline"
            rel="noreferrer"
            href={"https://docs.steer.finance/vaults"}
            target={"_blank"}
          >
            {"What is a vault?"}
          </a>
        </div>

        <JsonForm
          schema={config}
          ref={submitFormRef}
          //@ts-ignore
          setFormData={setFormData}
          formData={formData}
          onSubmit={() => {
               
          }}
          type="app"
        />

      </div>

      <div className="flex flex-col-reverse justify-center mt-4 lg:mt-6 md:flex-row md:justify-between">
        <div className="mt-2 md:mt-0">
          <Button
            content="back"
            type="secondary"
            size="xsmall"
            onClickHandler={handleBack}
            casing={'capitalize'}

          />
        </div>
        <Button
          content="Review Strategy"
          size="xsmall"
          type="nsecondary"
          onClickHandler={() => {
            submitFormRef.current.click();
            updateVaultConfig(formData)
          }}
        />

      </div>
    </div>
  )
};

export default ConfigVault;

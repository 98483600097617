import { createContext } from "react";

export type GeoIpType = {
    geoBlocked: boolean
    setGeoBlocked: (blocked: boolean) => void
  }

export const GeoIpContext = createContext<GeoIpType>({
  geoBlocked: true,
    setGeoBlocked: (blocked: boolean) => {},
  });

import { Contract } from "@ethersproject/contracts";
import { Badge, Button, Input } from "components/lib";
import { useContractFunction, useEthers, useTokenAllowance, useTokenBalance } from "@usedapp/core";
import { sendNotification } from "components/Container";
import ModalHeader from "components/ModalHeader";
import { utils } from "ethers";
import { useChainId } from "hooks/useChainId";
import { FunctionComponent, useEffect, useState } from "react";
import { logEventInSentry, toTitleCase } from "utils";
import ERC20 from 'utils/Erc20.json';
import StakingAbi from "utils/Staking.json";
export interface stakeUnstakeProps {
  pool: any;
  alreadyStakeAmount?: string;
  closeModal: () => void;
  type?: 'stake' | 'unstake'
}

const StakeUnstake: FunctionComponent<stakeUnstakeProps> = (props) => {
  const { account } = useEthers();

  const [chainId] = useChainId();
  const [amount, setAmount] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const stakeTokenAB = useTokenBalance(props.pool.stakingToken, account, { chainId });
  const stakeTokenAccountBalance = utils.formatUnits(stakeTokenAB || "0", props.pool['stakeTokenDecimals']);
  const _userStake = utils.formatUnits(props.pool['userStake'] || "0", props.pool['stakeTokenDecimals']);
  const steerToken = ERC20;
  const stakingRewardInterface = new utils.Interface(StakingAbi.abi);
  const stakingRewardContract = new Contract(
    props.pool["id"],
    stakingRewardInterface,
  );

  const tokenAllowance = useTokenAllowance(
    props.pool.stakingToken,
    account,
    props.pool.id
  );

  const { state: stakeState, send: stake } = useContractFunction(stakingRewardContract, "stake", {
    transactionName: `Staking ${amount} ${props.pool.stakeToken} tokens`
  });

  const { state: unstakeState, send: withdraw } = useContractFunction(stakingRewardContract, "withdraw", {
    transactionName: `Unstaking ${props.pool.stakeToken} tokens on pool ${props.pool.symbol}`
  });
  const erc20Interface = new utils.Interface(steerToken.abi);
  const stakeTokenContract = new Contract(props.pool.stakingToken, erc20Interface);

  const { state: tokenState, send: approveToken } = useContractFunction(
    stakeTokenContract,
    "approve",
    { transactionName: `Approval for ${amount} ${props.pool.stakeToken} tokens` }
  );


  useEffect(() => {
    if (stakeState.status === 'Mining' || unstakeState.status === 'Mining') {
      props.closeModal();
    }

    if (stakeState.status === 'Exception' || unstakeState.status === 'Exception') {
      setInProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stakeState, unstakeState]);

  const stakeAndSetDefault = async (stakeSteerAmount) => {
    await stake(stakeSteerAmount);
    setAmount(null);
    setInProgress(false);
    props.closeModal();
  };

  useEffect(() => {
    if (tokenState.status === "Success") {
      (async () => {

        let decimals;

        if(props.type !== 'stake') {
          decimals = props.pool['stakeTokenDecimals'];
        } else {
          decimals = props.pool['rewardTokenDecimals'];
        }

        const data = utils.parseUnits(amount, decimals);
        await stakeAndSetDefault(data);
      })(); 
    } else if(tokenState.status === "Exception") {
      setInProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenState.status]);

  const actionHandler = async () => {
    setInProgress(true);

    logEventInSentry('staking', {
      account,
      date: new Date(),
      type: props.type,
      amount,
      stakeToken: props.pool["stakeToken"],
      rewardToekn: props.pool["rewardToekn"]
    });
    try {
      if (props.type !== 'stake') {
        const data = utils.parseUnits(amount, props.pool['stakeTokenDecimals']);
        await withdraw(data);
        setInProgress(false);
        props.closeModal();
      } else {
        const data = utils.parseUnits(amount, props.pool['rewardTokenDecimals']);;
        if (tokenAllowance.eq(0) || data.gt(tokenAllowance)) {
          await approveToken(stakingRewardContract.address, data);
        } else {
          await stakeAndSetDefault(data);
          setInProgress(false);
          props.closeModal(); 
        }
      }
    } catch (e) {
      console.log({ e });
      sendNotification({
        type: "error",
        transactionName: `${props.type} failed for
         ${props.pool.stakeToken}`,
        transaction: {
          ...e,
          msg: `${props.type} failed for
          ${props.pool.stakeToken}`,
        },
      });
    }
  };

  const isDisabled = () => {
    if (!amount) {
      return true;
    }

    if (props.type !== 'stake') {
      if (parseFloat(amount.toString()) < 0 || (parseFloat(amount.toString()) >  parseFloat(_userStake))) {
        return true;

      }
    }

    if (props.type === 'stake') {
      if (parseFloat(amount) < 0 || (parseFloat(amount) > parseFloat(stakeTokenAccountBalance)) || stakeTokenAccountBalance === "0.0") {
        return true;
      }
    }

  }


  const title = toTitleCase(props.type);
  const description = `${title} ${props.pool.stakeToken} Token ${props.type === 'stake' ? `For ${props.pool.earnTokenA} Token as Rewards`: ``}`;
  const stakedTB = parseFloat(stakeTokenAccountBalance).toPrecision(3);
  return (
    <div className={``}>
      <ModalHeader
        closeModal={!inProgress ? props.closeModal : undefined}
        description={''}
        title={`${description}`}
      />
      <div className="m-4">
        {props.type === 'stake' ?
          <div className="flex flex-col">

            <Input
              heading={` `}
              val={amount}
              type={"number"}
              onChange={setAmount}
              align={"vertical"}
              disabled={stakeTokenAccountBalance === "0.0"}
              optionalText={
                <div className="flex text-white-500">{`${props.pool.stakeToken} Balance: ${stakedTB}`}</div>
              }
              maxButton={
                <Badge
                  content={`max`}
                  size={`xsmall`}
                  bgColor={`green-200 p-1`}
                  onClick={() => {
                    setAmount(stakeTokenAccountBalance)
                  }}
                />
              }
            />

          </div> : <div className="flex flex-col">
                {/* {`${props.pool.userStake}`} */}

                <Input
                  heading={` `}
                  val={amount}
                  type={"number"}
                  onChange={setAmount}
                  align={"vertical"}
                  disabled={_userStake === "0.0"}
                  optionalText={
                    <div className="flex text-white-500">{`${props.pool.stakeToken} Balance: ${parseFloat(_userStake).toPrecision(3)} `}</div>
                  }
                  maxButton={
                    <Badge
                      content={`max`}
                      size={`xsmall`}
                      bgColor={`green-200 p-1`}
                      onClick={() => {
                        setAmount(_userStake)
                      }}
                    />
                  }
                />
            </div>
        }

      </div>
      <div className="flex flex-col">
        <div className={`col-span-1 w-full place-self-end p-4`}>
          <Button
            onClickHandler={actionHandler}
            disabled={isDisabled() || inProgress}
            content={`${title}`}
            casing={`uppercase`}
            type={`tertiary`}
            fullWidth={true}
            iconSpin={true}
            icon={inProgress ? "faSpinner" : ""}
            iconType={"normal"}
            iconAlignment={"right"}
            size={'xsmall'}
          />
        </div>
      </div>

    </div>
  );
};

export default StakeUnstake;

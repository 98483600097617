import {
  AppDataRender,
  AppDetailsCard,
} from "components/lib";
import {
  frequencyOptions,
  getExplorerLink,
  getMinifedAddress,
} from "utils";


const AppDescriptionSection = ({
    ipfsConfig,
    chainId,
    vaultData
}) => {
 
  const ipfsConfigStrategyData = ipfsConfig?.strategyConfigData;
 
  let nextExecutionTime = null;

  if (
    ipfsConfigStrategyData?.epochLength &&
    ipfsConfigStrategyData?.epochStart
  ) {
    const startTime = Number(ipfsConfigStrategyData?.epochStart) * 1000;
    const epochLengthSeconds = Number(ipfsConfigStrategyData?.epochLength) * 1000;
    const duration = Date.now() - startTime;
    nextExecutionTime = (startTime + Math.floor(duration / epochLengthSeconds) * epochLengthSeconds) + epochLengthSeconds;
  }

  return (
    <AppDetailsCard
    title="App Description"
    description={ipfsConfigStrategyData?.description || ""}
    content={
      <AppDataRender
        data={[
          {
            type: "text",
            val: `${Number(vaultData?.feeTier || 0) / 10000}%`,
            label: "fee tier",
          },
          {
            type: "text",
            val: `${
              new Date(
                ipfsConfigStrategyData?.epochStart * 1000
              )?.toLocaleDateString() || ""
            }`,
            label: "Published Date",
          },
          {
            type: "text",
            val: `${
              new Date(
                ipfsConfigStrategyData?.epochStart * 1000
              )?.toLocaleTimeString() || ""
            }`,
            label: "Published Time",
          },
          {
            type: "text",
            val:
              frequencyOptions.find(
                (d) =>
                  d.value === ipfsConfigStrategyData?.epochLength
              )?.label || "",
            label: "Execution Interval",
          },
          {
            type: "text",
            val: nextExecutionTime
              ? `${new Date(
                nextExecutionTime
                )?.toLocaleDateString()}`
              : "",
            label: "Next Execution Date",
          },
          {
            type: "text",
            val: `${new Date(
              nextExecutionTime
            )?.toLocaleTimeString()}`,
            label: "Next Execution Time",
          },
          {
            type: "link",
            label: "Creator",
            val: {
              onClick: () =>
                window.open(
                  `${getExplorerLink(chainId)}/address/${
                    vaultData?.deployer || ""
                  }`
                ),
              text: getMinifedAddress(vaultData?.deployer || ""),
            },
          },
        ]}
        size={"small"}
      />
    }
  />
  );
};

export default AppDescriptionSection;
import {
  AppDataRender,
  AppDetailsCard
} from "components/lib";

const fixedStrategyParams = [
  "appImgUrl",
  "appImageUrl",
  "description",
  "epochLength",
  "epochStart",
  "name",
];

const AppConfigDetailSection = ({
    ipfsConfig
}) => {

  const ipfsConfigStrategyData = ipfsConfig?.strategyConfigData;
 
  return (
    <AppDetailsCard
    title="App Configuration"
    description={
      "App configuration can play a critical role in defining the liquidity placements of a strategy on an pool. This can include factors such as the asset's volatility and risk. The configuration parameters are decided by the deployer of the vault."
    }
    content={
      <AppDataRender
        data={
          ipfsConfigStrategyData
            ? Object.keys(ipfsConfigStrategyData)
                .filter(
                  (key) => fixedStrategyParams.indexOf(key) === -1
                )
                .map((d) => {
                  return {
                    type: "text",
                    val: JSON.stringify(ipfsConfigStrategyData[d]),
                    label: d,
                  };
                })
            : []
        }
        size={"small"}
      />
    }
  />
  );
};

export default AppConfigDetailSection;
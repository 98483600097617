import * as Sentry from '@sentry/react';
import axios from "axios";
import { BigNumber, ContractReceipt, ethers, Event, utils } from "ethers";
// import * as AsBind from "as-bind/dist/as-bind.cjs.js";
import { loadWasm } from "@steerprotocol/app-loader";
import { Price, Token } from "@uniswap/sdk-core";
import { Chain, ChainId } from "@usedapp/core";
import arbitrum from "contracts/arbitrum";
import arbitrumGoerli from "contracts/arbitrum-goerli";
import avalanche from "contracts/avalanche";
import bsc from 'contracts/bsc';
import goerli from "contracts/goerli";
import mumbai from "contracts/mumbai";
import optimism from "contracts/optimism";
import optimismGoerli from "contracts/optimism-goerli";
import polygon from "contracts/polygon";
import { formatDuration, intervalToDuration } from "date-fns";
import JSBI from "jsbi";
import {
  duration,
  first,
  Index,
  last,
  max,
  min,
  window as PondWindow,
  timeSeries,
} from "pondjs";
import { TimeAlignment } from "pondjs/lib/types";
import { getForgeTokenList, getUSDPriceAvalanche, getUsdPriceBase, getUsdPriceCelo, getUsdPriceEvmos, getUsdPriceMetis, getUSDPrices, getUsdPriceZkEvm, TOKEN_PRICES } from "services/rest";
import ERC20 from './Erc20.json';
// import { getAddressLink, getTransactionLink } from '@usedapp/core/src/helpers/chainExplorerLink'
import base from 'contracts/base';
import celo from "contracts/celo";
import evmos from "contracts/evmos";
import metis from "contracts/metis";
import thundercore from "contracts/thundercore";
import zkEvm from "contracts/zkEvm";
import kava from "contracts/kava";
import { Candle, DexName, FactoryAddresses, PositionType, Protocol } from "./types";


const provider = ethers.getDefaultProvider(); // Add Providers API here

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const Q96 = JSBI.exponentiate(JSBI.BigInt(2), JSBI.BigInt(96));
export const Q192 = JSBI.exponentiate(Q96, JSBI.BigInt(2));

export const supportedChains = [
  // {
  //   id: 5,
  //   name: "Goerli",
  //   value: "goerli",
  //   label: "Goerli",
  //   subgraphURl:
  //     "https://api.thegraph.com/subgraphs/name/steerprotocol/subgraph",
  //   uniswapSubgraphURL:
  //     "https://api.thegraph.com/subgraphs/name/liqwiz/uniswap-v3-goerli",
  //   networkConfig: {
  //       chainId: '0x5', // Replace with the chain ID of the network you want to add
  //       chainName: 'Goerli', // Replace with the name of the network
  //       rpcUrls: ['https://goerli.infura.io/v3/'], // Replace with the RPC endpoint URL of the network
  //       nativeCurrency: {
  //         name: 'ETH', // Replace with the native token symbol of the network
  //         symbol: 'ETH',
  //         decimals: 18,
  //       },
  //       blockExplorerUrls: ['https://goerli.etherscan.io'], // Replace with the block explorer URL of the network (optional)
  //   }
  // },
  {
    id: 137,
    name: "Polygon",
    value: "polygon",
    label: "Polygon",
    subgraphURl:
      "https://api.thegraph.com/subgraphs/name/steerprotocol/steer-protocol-polygon",
    uniswapSubgraphURL:
      "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon",
    retroSubgraphURL: "https://api.thegraph.com/subgraphs/name/ruvlol/univ3-test",
    sushiswapSubgraphURL: "https://api.thegraph.com/subgraphs/name/sushi-v3/v3-polygon",
    networkConfig: {
      chainId: '0x89', // Replace with the chain ID of the network you want to add
      chainName: 'Polygon', // Replace with the name of the network
      rpcUrls: ['https://polygon-rpc.com/'], // Replace with the RPC endpoint URL of the network
      nativeCurrency: {
        name: 'MATIC', // Replace with the native token symbol of the network
        symbol: 'MATIC',
        decimals: 18,
      },
      blockExplorerUrls: ['https://polygonscan.com/'], // Replace with the block explorer URL of the network (optional)
  }
  },
  // {
  //   id: 80001,
  //   name: "Mumbai",
  //   value: "mumbai",
  //   label: "Mumbai",
  //   subgraphURl: "https://api.thegraph.com/subgraphs/name/steerprotocol/mumbai",
  //   uniswapSubgraphURL:
  //     "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon",
  //   networkConfig: {
  //       chainId: '0x13881', // Replace with the chain ID of the network you want to add
  //       chainName: 'Mumbai', // Replace with the name of the network
  //       rpcUrls: ['https://matic-mumbai.chainstacklabs.com/'], // Replace with the RPC endpoint URL of the network
  //       nativeCurrency: {
  //         name: 'MATIC', // Replace with the native token symbol of the network
  //         symbol: 'MATIC',
  //         decimals: 18,
  //       },
  //       blockExplorerUrls: ['https://mumbai.polygonscan.com/'], // Replace with the block explorer URL of the network (optional)
  //   }

  // },
  // {
  //   id: 421613,
  //   name: "Arbitrum Goerli",
  //   value: "arbitrum-goerli",
  //   label: "Arbitrum Goerli",
  //   subgraphURl:
  //     "https://api.thegraph.com/subgraphs/name/steerprotocol/steer-protocol---arb-goerli",
  //   // uniswapSubgraphURL:"", 
  //   networkConfig: {
  //     chainId: '421613', // Replace with the chain ID of the network you want to add
  //     chainName: 'Arbitrum Goerli', // Replace with the name of the network
  //     rpcUrls: ['https://goerli-rollup.arbitrum.io/rpc/'], // Replace with the RPC endpoint URL of the network
  //     nativeCurrency: {
  //       name: 'ETH', // Replace with the native token symbol of the network
  //       symbol: 'ETH',
  //       decimals: 18,
  //     },
  //     blockExplorerUrls: ['https://goerli-rollup-explorer.arbitrum.io'], // Replace with the block explorer URL of the network (optional)
  //   }
  // },
  {
    id: 42161,
    name: "Arbitrum",
    value: "arbitrum",
    label: "Arbitrum",
    subgraphURl:"https://api.thegraph.com/subgraphs/name/steerprotocol/steer-protocol-arbitrum",
    uniswapSubgraphURL:"https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-arbitrum-one",
    sushiswapSubgraphURL: "https://api.thegraph.com/subgraphs/name/sushi-v3/v3-arbitrum",
    networkConfig: {
      chainId: '42161', // Replace with the chain ID of the network you want to add
      chainName: 'Arbitrum One', // Replace with the name of the network
      rpcUrls: ['https://arb-mainnet.g.alchemy.com/v2/k4pN3WdBEFdAf2tsqMb1fe1QE6aG0s4_'], // Replace with the RPC endpoint URL of the network
      nativeCurrency: {
        name: 'ETH', // Replace with the native token symbol of the network
        symbol: 'ETH',
        decimals: 18,
      },
      blockExplorerUrls: ['https://arbiscan.io/'], // Replace with the block explorer URL of the network (optional)
    }
  },
  {
    id: 10,
    name: "Optimism",
    value: "optimism",
    label: "Optimism",
    subgraphURl: "https://api.thegraph.com/subgraphs/name/steerprotocol/steer-protocol-optimism",
    uniswapSubgraphURL:"https://api.thegraph.com/subgraphs/name/ianlapham/optimism-post-regenesis",
    sushiswapSubgraphURL: "https://api.thegraph.com/subgraphs/name/sushi-v3/v3-optimism",
    networkConfig: {
      chainId: '10', // Replace with the chain ID of the network you want to add
      chainName: 'Optimism', // Replace with the name of the network
      rpcUrls: ['https://mainnet.optimism.io/'], // Replace with the RPC endpoint URL of the network
      nativeCurrency: {
        name: 'ETH', // Replace with the native token symbol of the network
        symbol: 'ETH',
        decimals: 18,
      },
      blockExplorerUrls: ['https://optimistic.etherscan.io/'], // Replace with the block explorer URL of the network (optional)
    }
  },
  {
    id: 56,
    name: "BSC",
    value: "bsc",
    label: "BSC",
    subgraphURl: "https://api.thegraph.com/subgraphs/name/steerprotocol/steer-protocol-bsc",
    uniswapSubgraphURL:"https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-bsc",
    sushiswapSubgraphURL: "https://api.thegraph.com/subgraphs/name/sushi-v3/v3-bsc",
    pancakeSwapURL: "https://api.thegraph.com/subgraphs/name/pancakeswap/exchange-v3-bsc",
    networkConfig: {
      chainId: '10', // Replace with the chain ID of the network you want to add
      chainName: 'BSC', // Replace with the name of the network
      rpcUrls: ['https://bscscan.com/F8A6IB195K2M5AGU8RMCEXSZK29VY3DMY7'], // Replace with the RPC endpoint URL of the network
      nativeCurrency: {
        name: 'ETH', // Replace with the native token symbol of the network
        symbol: 'ETH',
        decimals: 18,
      },
      blockExplorerUrls: ['https://bscscan.com/'], // Replace with the block explorer URL of the network (optional)
    }
  },
  {
    id: 9001,
    name: "Evmos",
    value: "evmos",
    label: "Evmos",
    subgraphURl: "https://subgraph.satsuma-prod.com/769a117cc018/steer/steer-protocol-evmos/api",
    forgeSubgraphURL: "https://subgraph.satsuma-prod.com/748ee71b5343/orbital-apes/v3-subgraph/api",
    networkConfig: {
      chainId: '9001', // Replace with the chain ID of the network you want to add
      chainName: 'Evmos', // Replace with the name of the network
      rpcUrls: ["https://evmos-mainnet.gateway.pokt.network/v1/lb/e2e725826773754677c39204"], // Replace with the RPC endpoint URL of the network
      nativeCurrency: {
        name: 'Evmos', // Replace with the native token symbol of the network
        symbol: 'Evmos',
        decimals: 18,
      },
      blockExplorerUrls: ['https://escan.live/'], // Replace with the block explorer URL of the network (optional)
    }
  },
  {
    id: 1088,
    name: "Metis",
    value: "metis",
    label: "Metis",
    subgraphURl: "https://subgraph.satsuma-prod.com/769a117cc018/steer/steer-protocol-metis/api",
    maiaSubgraphUrl: "https://subgraph.steer.finance/evmos/subgraphs/name/maia-dao/uniswap-v3",
    networkConfig: {
      chainId: 1088, // Replace with the chain ID of the network you want to add
      chainName: 'Metis', // Replace with the name of the network
      rpcUrls: ["https://metis-mainnet.blastapi.io/b0dd23af-c229-446f-8b45-450db21e2d68"], // Replace with the RPC endpoint URL of the network
      nativeCurrency: {
        name: 'Metis', // Replace with the native token symbol of the network
        symbol: 'Metis',
        decimals: 18,
      },
      blockExplorerUrls: ['https://andromeda-explorer.metis.io/'], // Replace with the block explorer URL of the network (optional)
    }
  },
  {
    id: 8453,
    name: "Base",
    value: "base",
    label: "Base",
    subgraphURl: "https://subgraph.satsuma-prod.com/769a117cc018/steer/steer-protocol-base/api",
    sushiswapSubgraphURL: "https://api.studio.thegraph.com/query/32073/v3-base/v0.0.1",
    networkConfig: {
      chainId: '8453', // Replace with the chain ID of the network you want to add
      chainName: 'Base', // Replace with the name of the network
      rpcUrls: ["https://mainnet.base.org"], // Replace with the RPC endpoint URL of the network
      nativeCurrency: {
        name: 'Eth', // Replace with the native token symbol of the network
        symbol: 'ETh',
        decimals: 18,
      },
      blockExplorerUrls: ['https://basescan.org/'], // Replace with the block explorer URL of the network (optional)
    }
  },
  {
    id: 43114,
    name: "Avalanche",
    value: "avax",
    label: "Avalanche",
    subgraphURl: "https://api.thegraph.com/subgraphs/name/steerprotocol/steer-protocol-avalanche",
    sushiswapSubgraphURL: "https://api.thegraph.com/subgraphs/name/sushi-v3/v3-avalanche",
    networkConfig: {
      chainId: '43114', // Replace with the chain ID of the network you want to add
      chainName: 'AVALANCHE', // Replace with the name of the network
      rpcUrls: ["https://snowtrace.io/F8A6IB195K2M5AGU8RMCEXSZK29VY3DMY7"], // Replace with the RPC endpoint URL of the network
      nativeCurrency: {
        name: 'AVAX', // Replace with the native token symbol of the network
        symbol: 'AVAX',
        decimals: 18,
      },
      blockExplorerUrls: ['https://snowtrace.io/'], // Replace with the block explorer URL of the network (optional)
    }
  },

  {
    id: 1101,
    name: "zkEVM",
    value: "zkEVM",
    label: "zkEVM",
    subgraphURl: "https://subgraph.steer.finance/zkevm/subgraphs/name/steerprotocol/steer-zkevm",
    sushiswapSubgraphURL: "https://api.studio.thegraph.com/query/32073/v3-polygon-zkevm/v0.0.2",
    networkConfig: {
      chainId: '1101', // Replace with the chain ID of the network you want to add
      chainName: 'zkEVM', // Replace with the name of the network
      rpcUrls: ["https://snowtrace.io/F8A6IB195K2M5AGU8RMCEXSZK29VY3DMY7"], // Replace with the RPC endpoint URL of the network
      nativeCurrency: {
        name: 'ETH', // Replace with the native token symbol of the network
        symbol: 'ETH',
        decimals: 18,
      },
      blockExplorerUrls: ['https://zkevm.polygonscan.com'], // Replace with the block explorer URL of the network (optional)
    }
  },
  {
    id: 42220,
    name: "Celo",
    value: "celo",
    label: "Celo",
    subgraphURl: "https://api.thegraph.com/subgraphs/name/steerprotocol/steer-protocol-celo",
    uniswapSubgraphURL:"https://api.thegraph.com/subgraphs/name/jesse-sawa/uniswap-celo",
    networkConfig: {
      chainId: '42220', // Replace with the chain ID of the network you want to add
      chainName: 'Celo', // Replace with the name of the network
      rpcUrls: ["https://snowtrace.io/F8A6IB195K2M5AGU8RMCEXSZK29VY3DMY7"], // Replace with the RPC endpoint URL of the network
      nativeCurrency: {
        name: 'Celo', // Replace with the native token symbol of the network
        symbol: 'Celo',
        decimals: 18,
      },
      blockExplorerUrls: ['https://celoscan.io/'], // Replace with the block explorer URL of the network (optional)
    }
  },
  {
    id: 108,
    name: "ThunderCore",
    value: "thundercore",
    label: "ThunderCore",
    subgraphURl: "https://subgraph.steer.finance/thundercore/subgraphs/name/steerprotocol/steer-thundercore",
    sushiswapSubgraphURL: "https://graph-node.thundercore.com/subgraphs/name/sushi-v3/v3-thundercore",
    networkConfig: {
      chainId: '108', // Replace with the chain ID of the network you want to add
      chainName: 'ThunderCore', // Replace with the name of the network
      rpcUrls: ["https://snowtrace.io/F8A6IB195K2M5AGU8RMCEXSZK29VY3DMY7"], // Replace with the RPC endpoint URL of the network
      nativeCurrency: {
        name: 'TT', // Replace with the native token symbol of the network
        symbol: 'TT',
        decimals: 18,
      },
      blockExplorerUrls: ['https://scan.thundercore.com/'], // Replace with the block explorer URL of the network (optional)
    }
  },
  {
    id: 2222,
    name: "Kava",
    value: "kava",
    label: "Kava",
    subgraphURl: "https://subgraph.steer.finance/kava/subgraphs/name/steerprotocol/steer-kava-evm",
    kinetixSubgraphURL: "https://the-graph.kava.io/subgraphs/name/kinetixfi/v3-subgraph",
    networkConfig: {
      chainId: '2222', // Replace with the chain ID of the network you want to add
      chainName: 'Kava', // Replace with the name of the network
      rpcUrls: ["https://kava-mainnet.gateway.pokt.network/v1/lb/e2e725826773754677c39204"], // Replace with the RPC endpoint URL of the network
      nativeCurrency: {
        name: 'Kava', // Replace with the native token symbol of the network
        symbol: 'Kava',
        decimals: 18,
      },
      blockExplorerUrls: ['https://kavascan.com/'], // Replace with the block explorer URL of the network (optional)
    }
  },
 
    // {
    //   id: 420,
    //   name: "Optimism Goerli",
    //   value: "optimism-goerli",
    //   label: "Optimism Goerli",
    //   subgraphURl: "https://api.thegraph.com/subgraphs/name/steerprotocol/steer-protocol-optimism-goerli",
    //   uniswapSubgraphURL:"",
    //   networkConfig: {
    //     chainId: '420', // Replace with the chain ID of the network you want to add
    //     chainName: 'Optimism Goerli', // Replace with the name of the network
    //     rpcUrls: ['https://goerli.optimism.io'], // Replace with the RPC endpoint URL of the network
    //     nativeCurrency: {
    //       name: 'ETH', // Replace with the native token symbol of the network
    //       symbol: 'ETH',
    //       decimals: 18,
    //     },
    //     blockExplorerUrls: ['https://goerli-optimism.etherscan.io'], // Replace with the block explorer URL of the network (optional)
    //   }
    // }
];

export const supportedChainIds = supportedChains.map(c => c.id);

export const frequencyOptions = [
  // {
  //   label: "5 min",
  //   value: "300",
  // },
  // {
  //   label: "15 min",
  //   value: "900",
  // },
  // {
  //   label: "30 min",
  //   value: "1800",
  // },
  {
    label: "1 hr",
    value: "3600",
  },
  {
    label: "2 hr",
    value: "7200",
  },
  {
    label: "4 hr",
    value: "14400",
  },
  {
    label: "12 hr",
    value: "43200",
  },
  {
    label: "Daily",
    value: "86400",
  },
  {
    label: "Weekly",
    value: "604800",
  },
];

export const filterEvents = (
  blockEvents: ContractReceipt,
  name: string
): Array<Event> => {
  return blockEvents.events?.filter((event) => event.event === name) || [];
};

export const toTitleCase = (str: string): string => {
  const data = str.replace(/([a-z])([A-Z])/g, "$1 $2");
  return data.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};


export const appTypes = [
  { label: "All", value: "all" },
  { label: "Multi Position", value: "MultiPosition" },
  { label: "Single Position", value: "SinglePosition" },
  { label: "Whitelist Multi Position", value: "PermissionedSinglePosition" },
  { label: "Whitelist Single Position", value: "PermissionedMultiPosition" },
]

export const getSupportedDexes = (supportedChain) => {
  const DEXs = [];
  if (supportedChain && supportedChain.sushiswapSubgraphURL) {
    DEXs.push({ label: "Sushiswap V3", value: "sushi", url: supportedChain.sushiswapSubgraphURL});
  }
  if (supportedChain && supportedChain.uniswapSubgraphURL) {
    DEXs.push({ label: "Uniswap V3", value: "uniswap", url: supportedChain.uniswapSubgraphURL });
  }
  if (supportedChain && supportedChain.forgeSubgraphURL) {
    DEXs.push({ label: "Forge", value: "forge", url: supportedChain.forgeSubgraphURL });
  }
  if (supportedChain && supportedChain.maiaSubgraphUrl) {
    DEXs.push({ label: "Maia V3", value: "maia", url: supportedChain.maiaSubgraphUrl });
  }
  if (supportedChain && supportedChain.pancakeSwapURL) {
    DEXs.push({ label: "Pancakeswap AMM V3", value: "pancake", url: supportedChain.pancakeSwapURL });
  }

  if (supportedChain && supportedChain.retroSubgraphURL) {
    DEXs.push({ label: "Retro", value: "retro", url: supportedChain.retroSubgraphURL });
  }

  if (supportedChain && supportedChain.kinetixSubgraphURL) {
    DEXs.push({ label: "kinetix", value: "kinetix", url: supportedChain.kinetixSubgraphURL });
  }

  return DEXs;
}


export const getTokenBalance = async (
  address: string,
  tokenAddress: string
): Promise<string> => {
  const erc20 = new ethers.Contract(
    tokenAddress.toLowerCase(),
    ERC20.abi,
    provider
  );
  try {
    const balance = await erc20.balanceOf(address);
    return balance;
  } catch (error) {
    return "0";
  }
};

export const toUSD = async (address: string) => {
  const aIndex = address.toLowerCase();
  try {
    const response = await fetch(
      `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${aIndex}&vs_currencies=usd`
    );
    const data = await response.json();
    return data[aIndex].usd;
  } catch (error) {
    console.log(error);
    return 0;
  }
};

export const formatTime = (seconds: number): string => {
  const pad = (s: number) => {
    return (s < 10 ? "0" : "") + s;
  };
  const hours = Math.floor(seconds / (60 * 60));
  const minutes = Math.floor((seconds % (60 * 60)) / 60);
  seconds = Math.floor(seconds % 60);
  return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds);
};

export const getNameFromPath = (path: string) => {
  let filePath = path.split("/");
  return filePath.length > 0 ? filePath[filePath.length - 1] : "";
};

export const getMinifedAddress = (address: string) => {
  return (
    address.slice(0, 6) +
    "..." +
    address.slice(address.length - 4, address.length)
  );
};

export const delayExecution = (millisec: number, callbackFunc?: any) => {
  return setTimeout(() => callbackFunc, millisec);
};


export const getNotificationStyle = (type: string) => {
  const generalStyle = {
    width: window.innerWidth < 500 ? 350 : 370,
    right: window.innerWidth < 500 ? "-1rem" : "4em",
    borderRadius: 20,
    marginBottom: window.innerWidth < 500 ? 40 : 20,
  };
  if (type === "success") {
    return {
      background: "linear-gradient(95.64deg, #466D7F -13.65%, #454D7C 93.74%)",
      ...generalStyle,
    };
  } else if (type === "error") {
    return {
      background: "linear-gradient(95.64deg, #5A446C -13.65%, #454D7C 93.74%)",
      ...generalStyle,
    };
  } else {
    return {
      background: "linear-gradient(95.64deg, #625B3B -13.65%, #454D7C 93.74%)",
      ...generalStyle,
    };
  }
};

export const HexToString = (value) => {
  return parseInt(value).toFixed(6).toString();
};

export const getKeyByValue = (map, searchValue) => {
  for (let [key, value] of map.entries()) {
    if (value === searchValue) return key;
  }
};

export const getTokenValue = (
  vaultToken0Balance: BigNumber,
  vaultToken1Balance: BigNumber,
  tokenInputDecimal: BigNumber,
  tokenOutputDecimal: BigNumber,
  tokenType: number,
  tokenValue: string,
  nativeTokenRatio: number
): string => {
  // token type is desired Token (1/0)

  // const tokenValueBn = BigNumber.from(tokenValue);
  const tokenValueBn = utils.parseUnits(tokenValue, tokenInputDecimal);
  // We can choose any decimal units token 1 or token 0
  if (vaultToken0Balance.isZero() && vaultToken1Balance.isZero()) {
    let ratio;
    if (tokenType === 0 && nativeTokenRatio !== 0) {
      ratio = 1 / nativeTokenRatio;
    } else {
      ratio = nativeTokenRatio;
    }

    return utils.formatUnits(
      tokenValueBn
        .mul(
          BigNumber.from(
            Math.round(ratio * 10 ** 18).toLocaleString("fullwide", {
              useGrouping: false,
            })
          )
        )
        .div(BigNumber.from(10).pow("18")),
      tokenOutputDecimal
    );
  } else {
    let ratio;

    if (tokenType === 1) {
      if(vaultToken0Balance.isZero()) {
        ratio = parseInt(vaultToken1Balance.toString());
      } else {
        ratio = parseInt(vaultToken1Balance.toString()) / parseInt(vaultToken0Balance.toString());
      }
    } else {
      if(vaultToken1Balance.isZero()) {
        ratio = parseInt(vaultToken0Balance.toString());
      } else {
        ratio = parseInt(vaultToken0Balance.toString()) / parseInt(vaultToken1Balance.toString());
      }
    }

    return utils.formatUnits(
      tokenValueBn
        .mul(
          BigNumber.from(
            Math.round(ratio * 10 ** 18).toLocaleString("fullwide", {
              useGrouping: false,
            })
          )
        )
        .div(BigNumber.from(10).pow("18")),
      tokenOutputDecimal
    );
  }
};

export const bigNumberMax = (value1: BigNumber, value2: BigNumber) => {
  if (value1.gt(value2)) {
    return value1;
  } else {
    return value2;
  }
};

export const bigNumberMin = (value1: BigNumber, value2: BigNumber) => {
  if (value1.lt(value2)) {
    return value1;
  } else {
    return value2;
  }
};

export const getLpToken = (
  token0InputValue: string,
  token1InputValue: string,
  lpTokenSupply: BigNumber,
  vaultToken1Balance: BigNumber,
  vaultToken0Balance: BigNumber,
  token0Decimal: number,
  token1Decimal: number,
  lpTokenDecimals: number
) => {
  const token0Value = BigNumber.from(
    utils.parseUnits(token0InputValue, token0Decimal)
  );
  const token1Value = BigNumber.from(
    utils.parseUnits(token1InputValue, token1Decimal)
  );
  let lpTokens;
  if (lpTokenSupply.isZero()) {
    // For first deposit, just use the amounts desired
    lpTokens = bigNumberMax(token0Value, token1Value);
  } else if (vaultToken0Balance.isZero()) {
    //We can assume here that total1 != 0, no need to check
    lpTokens = token1Value
      .mul(lpTokenSupply)
      .div(vaultToken1Balance)
      .toString();
  } else if (vaultToken1Balance.isZero()) {
    //No need for safemath in division because we know vaultToken0Balance != 0
    lpTokens = token0Value
      .mul(lpTokenSupply)
      .div(vaultToken0Balance)
      .toString();
  } else {
    const cross = bigNumberMin(
      token0Value.mul(vaultToken1Balance),
      token1Value.mul(vaultToken0Balance)
    );

    lpTokens = cross
      .mul((lpTokenSupply.mul(BigNumber.from(10).pow(18))).div(vaultToken0Balance))
      .div(vaultToken1Balance).div(BigNumber.from(10).pow(18));
  }

  return utils.formatUnits(lpTokens, lpTokenDecimals);
};

export const getVaultTokensFromLptoken = (
  lpToken: string,
  lpTokenSupply: BigNumber,
  vaultToken1Balance: BigNumber,
  vaultToken0Balance: BigNumber,
  lpTokenDecimals: BigNumber
) => {
  const lpTokenValue = utils.parseUnits(lpToken, lpTokenDecimals);
  return {
    token1Val: vaultToken1Balance.mul(lpTokenValue).div(lpTokenSupply),
    token0Val: vaultToken0Balance.mul(lpTokenValue).div(lpTokenSupply),
  };
};

export const getVaultTokensFromLptokenDirect = (
  lpToken: string,
  lpTokenSupply: BigNumber,
  vaultToken1Balance: BigNumber,
  vaultToken0Balance: BigNumber,
  lpTokenDecimals: BigNumber
) => {
  return {
    token1Val: lpTokenSupply.gt(0) ? (vaultToken1Balance.mul(BigNumber.from(lpToken))).div(lpTokenSupply) : BigNumber.from(1),
    token0Val: lpTokenSupply.gt(0) ? (vaultToken0Balance.mul(BigNumber.from(lpToken))).div(lpTokenSupply) : BigNumber.from(1)
  };
};

export const getValue = (inputArray, key) => {
  if (
    inputArray &&
    inputArray.value &&
    inputArray.value.length > 0 &&
    inputArray.value[0][key] !== ""
  ) {
    return inputArray.value[0][key];
  }
  return "No Data";
};

export const getValueBigNumber = (input, key) => {
  const isTrue =
    input &&
    input.value &&
    input.value.length > 0 &&
    input.value[0][key] !== "";
  return {
    original: isTrue ? input.value[0][key] : BigNumber.from(0),
    stringifiedData: isTrue ? input.value[0][key].toString() : "0",
  };
};

export const isValidNumber = (input) => {
  if (input.trim().length === 0 || input === '.') {
    return false;
  }
  const regex = new RegExp(/^\d*\.?\d*$/);
  const validNumber = regex.test(input);
  return validNumber;
};

export const getVaildAddress = (address: string) => {
  return address !== "No Data"
    ? address
    : "0x0000000000000000000000000000000000000000";
};

export const isMetaMask = () => {
  return (window as any).ethereum?.isMetaMask;
};

export const addTokenToMetaMask = async (
  tokenAddress,
  tokenSymbol,
  lpTokenDecimals
) => {
  try {
    await (window as any).ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol.slice(0, 10), // A ticker symbol or shorthand, up to 5 chars.
          decimals: lpTokenDecimals.toString(), // The number of decimals in the token,
          image: "https://steer.finance/wp-content/uploads/2021/04/favicon.png",
        },
      },
    });
  } catch (e) {
    console.log({ e });
  }
};
// const memory = new WebAssembly.Memory({ initial: 256, maximum: 256 });
// const importObj = {
//   env: {
//     abortStackOverflow: () => {
//       throw new Error("overflow");
//     },
//     table: new WebAssembly.Table({
//       initial: 0,
//       maximum: 0,
//       element: "anyfunc",
//     }),
//     tableBase: 0,
//     memory: memory,
//     memoryBase: 1024,
//     STACKTOP: 0,
//     STACK_MAX: memory.buffer.byteLength,
//   },
//   console: {
//     // File which you are injecting
//     log(strPtr) {
//       console.log(strPtr);
//     },
//   },
// };

export const getIpfsBundleConfig = async (ipfsHash: string) => {
  // return axios
  //   .get(`https://ipfs.io/ipfs/${ipfsHash}`, {
  //     responseType: "arraybuffer",
  //   })
  //   .then(async (response) => {
  //     const instance = await AsBind.instantiate(response.data, importObj);
  //     let result = instance.exports;
  //     if (result.Strategy) {
  //       result = result.Strategy.config();
  //     } else {
  //       if (result.config) {
  //         result = result.config();
  //       } else {
  //         result = result.configForm();
  //       }
  //     }
  //     console.log("wasm result", result);
  //     let parsedResult;
  //     if (typeof result === "number") {
  //       parsedResult = JSON.parse(instance.exports.__getString(result));
  //     } else {
  //       parsedResult = JSON.parse(result);
  //     }

  //     return {
  //       data: parsedResult,
  //       hasError: false,
  //       msg: null,
  //     };
  //   })
  //   .catch((e) => {
  //     return {
  //       data: null,
  //       msg: e.message,
  //       hasError: true,
  //     };
  //   });

  try {
    const result = await loadWasm(
      `https://ipfs.io/ipfs/${ipfsHash}`
    );
    return {
      data: JSON.parse(result.config()),
      hasError: false,
      msg: null,
    };
  } catch (e) {
    return {
      data: null,
      msg: e.message,
      hasError: true,
    };
  }
};

export const getIpfsJSONConfig = async (ipfsHash: string) => {
  return axios
    .get(`https://ipfs.io/ipfs/${ipfsHash}`)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const uploadFileAndGetHash = async (file: any) => {
  const formData = new FormData();
  formData.append("theFiles", file);
  return axios
    .post("https://upload.steer.finance/api/upload/", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then(({ data }) => {
      return data.data.IpfsHash;
    })
    .catch((e) => {
      console.log("error uploading file and getting hash", e);
    });
};

export const vaildateStringAsTimestamp = (time: string) => {
  return new Date(parseInt(time) * 1000).getTime() > 0;
};

export const getAbiFromContractAddress = (
  contract: string,
  chainId: number
) => {
  let scan = getChainScanURLs(chainId);
  const URL = `${scan.url}api?module=contract&action=getabi&address=${contract}&apiKey=${scan.key}`;
  return axios
    .get(URL)
    .then(({ data }) => {
      return JSON.parse(data.result);
    })
    .catch((e) => {
      return new Error("Contract not verified");
    });
};

export const range = (size) => {
  return Array.from(Array(size).keys());
};

export const getContractByNetwork = (
  chainId: number,
  contractName: string
) => {
  if (chainId === 80001) {
    return mumbai[contractName];
  }

  if (chainId === 137) {
    return polygon[contractName];
  }

  if (chainId === 5) {
    return goerli[contractName];
  }

  if (chainId === 421613) {
    return arbitrumGoerli[contractName];
  }

  if (chainId === 42161) {
    return arbitrum[contractName];
  }

  if (chainId === 10) {
    return optimism[contractName];
  }

  if (chainId === 420) {
    return optimismGoerli[contractName];
  }

  if (chainId === 56) {
    return bsc[contractName]
  }

  if (chainId === 43114) {
    return avalanche[contractName]
  }

  if (chainId === 9001) {
    return evmos[contractName]
  }

  if (chainId === 1088) {
    return metis[contractName]
  }

  if (chainId === 108) {
    return thundercore[contractName]
  }

  if (chainId === 42220) {
    return celo[contractName]
  }

  if (chainId === 1101) {
    return zkEvm[contractName]
  }

  if (chainId === 8453) {
    return base[contractName];
  }

  if (chainId === 2222) {
    return kava[contractName]
  }

  return polygon[contractName];
};

export const getGasTokenSymbol = (chain: ChainId): string => {

  if (chain === Evmos.chainId) {
    return 'Evmos';
  }

  if (chain === ZkEvm.chainId) {
    return 'ETH';
  }

  if (chain === Base.chainId) {
    return 'ETH';
  }

  if (chain === Kava.chainId) {
    return 'Kava';
  }

  switch (ChainId[chain]) {
    case ChainId[ChainId.Mumbai]:
      return 'MATIC';
    case ChainId[ChainId.Polygon]: 
    return "MATIC"
    case ChainId[ChainId.ArbitrumGoerli]:
      return 'ETH';
    case ChainId[ChainId.Arbitrum]:
      return 'ETH';
    case ChainId[ChainId.OptimismGoerli]:
      return 'ETH';
    case ChainId[ChainId.Optimism]:
      return 'ETH';
    case ChainId[ChainId.BSC]:
      return 'BNB';
    case ChainId[ChainId.Avalanche]:
      return 'AVAX'
    case ChainId[ChainId.Andromeda]:
      return 'METIS'
    case ChainId[ChainId.ThunderCore]:
      return 'TT'
    case ChainId[ChainId.Celo]:
      return 'CELO'
    default:
      return "MATIC";
  }
};

export const getExplorerLink = (chain: ChainId) => {

  if (chain === Evmos.chainId) {
    return 'https://escan.live';
  }

  if (chain === ZkEvm.chainId) {
    return 'https://zkevm.polygonscan.com';
  }

  if (chain === Base.chainId) {
    return 'https://basescan.org'
  }

  if (chain === Kava.chainId) {
    return "https://kavascan.com"
  }
  
  switch (ChainId[chain]) {
    case ChainId[ChainId.Mumbai]:
      return "https://mumbai.polygonscan.com";
    case ChainId[ChainId.Polygon]:
      return "https://polygonscan.com";
    case ChainId[ChainId.Goerli]:
      return "https://goerli.etherscan.io";
    case ChainId[ChainId.ArbitrumGoerli]:
      return "https://goerli.arbiscan.io";
    case ChainId[ChainId.Arbitrum]:
      return "https://arbiscan.io";
    case ChainId[ChainId.OptimismGoerli]:
      return "https://goerli.optimistic.etherscan.io";
    case ChainId[ChainId.Optimism]:
      return "https://optimistic.etherscan.io";
    case ChainId[ChainId.BSC]:
      return "https://bscscan.com"
    case ChainId[ChainId.Avalanche]:
      return "https://snowtrace.io/"
    case Evmos.chainName:
      return "https://escan.live"
    case ChainId[ChainId.Andromeda]:
      return "https://andromeda-explorer.metis.io"
    case ChainId[ChainId.ThunderCore]:
      return "https://scan.thundercore.com"
    case ChainId[ChainId.Celo]:
      return "https://celoscan.io"
    default:
      return "https://polygonscan.com";
  }
};

export const Fee = {
  "100": "Lowest",
  "500": "Low",
  "3000": "Medium",
  "10000": "High",
};

export const testnetMsg =
  "Testnet Found! Use our backtesting toolkit to get precise results.";

export const getStateTooltipText = (state: string) => {
  switch (state) {
    case "PendingThreshold":
      return {
        text: "Required TVL does not meet the requirement to pay for fees.",
      };
    case "Paused":
      return {
        text: "Vault activity is paused due to uncertain event, Deposits are active however Withdrawals are disabled.",
        disable: "deposit",
      };
    case "Active":
      return {
        text: "Deposits and Withdrawals are active and ready to use.",
      };
    case "Retired":
      return {
        text: "Vault is retired due to uncertain event, Withdrawls are active however Deposits are disabled.",
        disable: "deposit",
      };
  }
};

export enum VaultState {
  PendingApproval,
  PendingThreshold, // R
  Paused, // can deposit or can't withdraw because the vault is paused
  Active,
  Retired, // can't deposit, hide deposit button
}

export const getPoolLink = (type: string, address: string, chainId) => {
  if (chainId === 80001 || chainId === 137) {
    return `https://info.uniswap.org/#/polygon/pools/${address}`;
  }
  if (chainId === 5 || chainId === 1) {
    return `https://info.uniswap.org/#/pools/${address}`;
  }
  if (chainId === 421613 || chainId === 42161) {
    return `https://info.uniswap.org/#/arbitrum/pools/${address}`;
  }
  
  if (chainId === 10 || chainId === 420) {
    return `https://info.uniswap.org/#/optimism/pools/${address}`;
  }

  if (chainId === 56) {
    // https://pancakeswap.finance/info/v3/pairs/${address}
    return `https://info.uniswap.org/#/bnb/pools/${address}`;
  }
};

export const getUniswapV3Subgraph = (beaconName: string, supportedChain) => {
  if (!beaconName) {
    return supportedChain.uniswapSubgraphURL;
  }

  if (beaconName.indexOf('Sushi') > -1) {
    return supportedChain.sushiswapSubgraphURL;
  } 

  if (beaconName.indexOf('Forge') > -1) {
    return supportedChain.forgeSubgraphURL;
  }

  if(beaconName.indexOf('MAIA') > -1) {
    return supportedChain.maiaSubgraphUrl;
  }

  if (beaconName.indexOf('Pancake') > -1) {
    return supportedChain.pancakeSwapURL;
  }

  if(beaconName.indexOf('Retro') > -1) {
    return supportedChain.retroSubgraphURL;
  }

  if (beaconName.indexOf('Kinetix') > -1) {
    return supportedChain.kinetixSubgraphURL;
  }

  return supportedChain.uniswapSubgraphURL;
}

export const getChainScanURLs = (chainId: number) => {
  switch (chainId) {
    case 5:
      return {
        url: `https://api-goerli.etherscan.io/`,
        key: "X4M3M9FM323W7IBH8HWEB3FSNB5XKEYYHA",
      };
    case 80001:
      return {
        url: `https://api-testnet.polygonscan.com/`,
        key: "Y51ZS9IGMRPQ39IW57IR86A6MYI4B4BC35",
      };
    case 137:
      return {
        url: "https://api.polygonscan.com/",
        key: "Y51ZS9IGMRPQ39IW57IR86A6MYI4B4BC35",
      };
    case 421613:
      return {
        url: "https://api-goerli.arbiscan.io/",
        key: "CypigYSfj-Xx5I8MXYVPhrpAqIB8XqB-",
      };
    case 42161:
      return {
        url: "https://api.arbiscan.io/",
        key: "CypigYSfj-Xx5I8MXYVPhrpAqIB8XqB-",
      };
    case 420:
      return {
        url: "https://api-goerli.optimistic.etherscan.io/",
        key: "CZUZ37EC31BFMZKHCQWE2J7GWGIWFVPNWA",
      };
    case 10:
      return {
        url: "https://api.optimistic.etherscan.io/",
        key: "CZUZ37EC31BFMZKHCQWE2J7GWGIWFVPNWA",
      };
    case 56:
      return {
        url: "https://api.bscscan.com/",
        key: "F8A6IB195K2M5AGU8RMCEXSZK29VY3DMY7",
      }
    case 9001:
      return {
        url: "https://escan.live/",
        key: ""
      }
    case 1088:
      return {
        url: "https://andromeda-explorer.metis.io",
        key: "",
      }
    case 42220:
      return {
        url: "https://celoscan.io",
        key: "",
      }
    case 108:
      return {
        url: "https://scan.thundercore.com",
        key: "",
      }
    case 1101:
      return {
        url: "https://zkevm.polygonscan.com",
        key: "",
      }
    case 8453:
      return {
        url: 'https://basescan.org/',
        key: '5JAMGSZSCWD8HGNVS23UZC4QEA65HMR8W5'
      }
    case 2222:
      return {
        url: "https://kavascan.com/",
        key: "",
      }
  }
};

export const JOB_STATUS = {
  "1": "Paused",
  "2": "Ongoing",
  "3": "Pending",
};

export const beaconContractNameMapper = {
  MultiPositionUniswapV3: "MultiPositionLiquidityManager",
  SinglePositionUniswapV3: "SinglePositionLiquidityManager",
  PermissionedSinglePositionUniswapV3: "WhitelistedSinglePositionBeaconName",
  PermissionedMultiPositionUniswapV3: "WhitelistedMultiPositionBeaconName",
  MultiPositionSushi: "SushiMultiPositionLiquidityManager",
  SinglePositionSushi: "SushiSinglePositionLiquidityManager",
  PermissionedSinglePositionSushi: "SushiWhitelistedMultiLiquidityManager",
  PermissionedMultiPositionSushi: "SushiWhitelistedSingleLiquidityManager",
  MultiPositionForge: "ForgeMultiPositionLiquidityManager",
  SinglePositionForge: "ForgeSinglePositionLiquidityManager",
  PermissionedSinglePositionForge: "ForgeWhitelistedMultiLiquidityManager",
  PermissionedMultiPositionForge: "ForgeWhitelistedSingleLiquidityManager",
  MultiPositionMAIA: "MAIAMultiPositionLiquidityManager",
  SinglePositionMAIA: "MAIASinglePositionLiquidityManager",
  PermissionedSinglePositionMAIA: "MAIAWhitelistedSingleLiquidityManager",
  PermissionedMultiPositionMAIA: "MAIAWhitelistedMultiLiquidityManager",
  MultiPositionPancake: "PancakeMultiPositionLiquidityManager",
  SinglePositionPancake: "PancakeSinglePositionLiquidityManager",
  PermissionedSinglePositionPancake: "PancakeWhitelistedSingleLiquidityManager",
  PermissionedMultiPositionPancake: "PancakeWhitelistedMultiLiquidityManager",
  MultiPositionRetro: "RetroMultiPositionLiquidityManager",
  SinglePositionRetro: "RetroSinglePositionLiquidityManager",
  PermissionedMultiPositionRetro: "RetroWhitelistedMultiLiquidityManager",
  PermissionedSinglePositionRetro: "RetroWhitelistedSingleLiquidityManager",
  MultiPositionKinetix: "KinetixMultiPositionLiquidityManager",
  SinglePositionKinetix: "KinetixSinglePositionLiquidityManager",
  PermissionedMultiPositionKinetix: "KinetixWhitelistedMultiLiquidityManager",
  PermissionedSinglePositionKinetix: "KinetixWhitelistedSingleLiquidityManager",
};

export const getWhitelistVaults = [
  "WhitelistedSinglePositionBeaconName",
  "WhitelistedMultiPositionBeaconName",
  "SushiWhitelistedMultiLiquidityManager",
  "SushiWhitelistedSingleLiquidityManager",
  "ForgeWhitelistedMultiLiquidityManager",
  "ForgeWhitelistedSingleLiquidityManager",
  "PermissionedSinglePositionMAIA",
  "PermissionedMultiPositionMAIA",
  "PermissionedSinglePositionPancake",
  "PermissionedMultiPositionPancake",
  "PermissionedSinglePositionRetro",
  "PermissionedMultiPositionRetro",
  "PermissionedSinglePositionKinetix",
  "PermissionedMultiPositionKinetix"
];

export const beaconNameMapper = {
  MultiPositionUniswapV3: "Uniswap v3 - Multi Position",
  SinglePositionUniswapV3: "Uniswap v3 - Single Position",
  PermissionedSinglePositionUniswapV3: "Uniswap v3 - Whitelist Single Position",
  PermissionedMultiPositionUniswapV3: "Uniswap v3 - Whitelist Multi Position",
  MultiPositionSushi: "SushiSwap v3 - Multi Position",
  SinglePositionSushi: "SushiSwap v3 - Single Position",
  PermissionedSinglePositionSushi: "SushiSwap v3 - Whitelist Single Position",
  PermissionedMultiPositionSushi: "SushiSwap v3 - Whitelist Single Position",
  MultiPositionForge: "Forge - Multi Position",
  SinglePositionForge: "Forge - Single Position",
  PermissionedSinglePositionForge: "Forge - Whitelist Single Position",
  PermissionedMultiPositionForge: "Forge - Whitelist Single Position",
  MultiPositionMAIA: "MAIA - Multi Position",
  SinglePositionMAIA: "MAIA - Single Position",
  PermissionedSinglePositionMAIA: "MAIA - Whitelist Single Position",
  PermissionedMultiPositionMAIA: "MAIA - Whitelist Single Position",
  MultiPositionPancake: "Pancake - Multi Position",
  SinglePositionPancake: "Pancake - Single Position",
  PermissionedSinglePositionPancake: "Pancake - Whitelist Single Position",
  PermissionedMultiPositionPancake: "Pancake - Whitelist Single Position",
  MultiPositionRetro: "Retro - Multi Position",
  SinglePositionRetro: "Retro - Single Position",
  PermissionedMultiPositionRetro: "Retro - Whitelist Multi Position",
  PermissionedSinglePositionRetro: "Retro - Whitelist Single Position",
  MultiPositionKinetix: "Kinetix - Multi Position",
  SinglePositionKinetix: "Kinetix - Single Position",
  PermissionedMultiPositionKinetix: "Kinetix - Whitelist Multi Position",
  PermissionedSinglePositionKinetix: "Kinetix - Whitelist Single Position"
};

export const uniswapBeacons = [
  "MultiPositionUniswapV3",
  "SinglePositionUniswapV3",
  "PermissionedSinglePositionUniswapV3",
  "PermissionedMultiPositionUniswapV3",
];

export const sushiswapBeacaons = [
  "MultiPositionSushi",
  "SinglePositionSushi",
  "PermissionedSinglePositionSushi",
  "PermissionedMultiPositionSushi",
];

export const forgeBeacons = [
  "MultiPositionForge",
  "SinglePositionForge",
  "PermissionedSinglePositionForge",
  "PermissionedMultiPositionForge"
];

export const maiaBeacons = [
  "MultiPositionMAIA",
  "SinglePositionMAIA",
  "PermissionedSinglePositionMAIA",
  "PermissionedMultiPositionMAIA"
];

export const pancakeBeacons = [
  "MultiPositionPancake",
  "SinglePositionPancake",
  "PermissionedSinglePositionPancake",
  "PermissionedMultiPositionPancake"
];

export const retroBeacons = [
  "MultiPositionRetro",
  "SinglePositionRetro",
  "PermissionedMultiPositionRetro",
  "PermissionedSinglePositionRetro",
];

export const kavaBeacons = [
  "MultiPositionKava",
  "SinglePositionKava",
  "PermissionedMultiPositionKava",
  "PermissionedSinglePositionKava",
];

export const appEngines = {
  desc: "App engines are apps focused steer contracts that users must choose when deploying their app on the Steer system. These app engines play a crucial role in the functioning and security of the Steer system.",
  MultiPositionUniswapV3:
    "This Engine has the ability to split and manage liquidity into just a multiple price range at any given point in time.",
  SinglePositionUniswapV3:
    "This Engine has the ability to manage 100% of liquidity into just a single price range at any given point in time.",
  PermissionedSinglePositionUniswapV3:
    "This Engine has the ability to manage 100% of liquidity into just a single price range at any given point in time. Also, this engines comes with an additional feature of providing a whitelist of addresses that can only perform actions like a deposit or more into the app.",
  PermissionedMultiPositionUniswapV3:
    "This Engine has the ability to split and manage liquidity into just a multiple price range at any given point in time. Also, this engines comes with an additional feature of providing a whitelist of addresses that can only perform actions like a deposit or more into the app.",
  MultiPositionSushi:
  "This Engine has the ability to split and manage liquidity into just a multiple price range at any given point in time.",
  SinglePositionSushi:
  "This Engine has the ability to manage 100% of liquidity into just a single price range at any given point in time.",
  PermissionedSinglePositionSushi:
  "This Engine has the ability to manage 100% of liquidity into just a single price range at any given point in time. Also, this engines comes with an additional feature of providing a whitelist of addresses that can only perform actions like a deposit or more into the app.",
  PermissionedMultiPositionSushi:
  "This Engine has the ability to split and manage liquidity into just a multiple price range at any given point in time. Also, this engines comes with an additional feature of providing a whitelist of addresses that can only perform actions like a deposit or more into the app.",
  MultiPositionForge:
  "This Engine has the ability to split and manage liquidity into just a multiple price range at any given point in time.",
  SinglePositionForge:
  "This Engine has the ability to manage 100% of liquidity into just a single price range at any given point in time.",
  PermissionedSinglePositionForge:
  "This Engine has the ability to manage 100% of liquidity into just a single price range at any given point in time. Also, this engines comes with an additional feature of providing a whitelist of addresses that can only perform actions like a deposit or more into the app.",
  PermissionedMultiPositionForge:
  "This Engine has the ability to split and manage liquidity into just a multiple price range at any given point in time. Also, this engines comes with an additional feature of providing a whitelist of addresses that can only perform actions like a deposit or more into the app.",
  MultiPositionMaia:
  "This Engine has the ability to split and manage liquidity into just a multiple price range at any given point in time.",
  SinglePositionMAIA:
  "This Engine has the ability to manage 100% of liquidity into just a single price range at any given point in time.",
  PermissionedSinglePositionMAIA:
  "This Engine has the ability to manage 100% of liquidity into just a single price range at any given point in time. Also, this engines comes with an additional feature of providing a whitelist of addresses that can only perform actions like a deposit or more into the app.",
  PermissionedMultiPositionMAIA:
  "This Engine has the ability to split and manage liquidity into just a multiple price range at any given point in time. Also, this engines comes with an additional feature of providing a whitelist of addresses that can only perform actions like a deposit or more into the app.",
  MultiPositionPancake:
  "This Engine has the ability to split and manage liquidity into just a multiple price range at any given point in time.",
  SinglePositionPancake:
  "This Engine has the ability to manage 100% of liquidity into just a single price range at any given point in time.",
  PermissionedSinglePositionPancake:
  "This Engine has the ability to manage 100% of liquidity into just a single price range at any given point in time. Also, this engines comes with an additional feature of providing a whitelist of addresses that can only perform actions like a deposit or more into the app.",
  PermissionedMultiPositionPancake:
  "This Engine has the ability to split and manage liquidity into just a multiple price range at any given point in time. Also, this engines comes with an additional feature of providing a whitelist of addresses that can only perform actions like a deposit or more into the app.",
  MultiPositionRetro:
  "This Engine has the ability to split and manage liquidity into just a multiple price range at any given point in time.",
  SinglePositionRetro:
  "This Engine has the ability to manage 100% of liquidity into just a single price range at any given point in time.",
  PermissionedSinglePositionRetro:
  "This Engine has the ability to manage 100% of liquidity into just a single price range at any given point in time. Also, this engines comes with an additional feature of providing a whitelist of addresses that can only perform actions like a deposit or more into the app.",
  PermissionedMultiPositionRetro:
  "This Engine has the ability to split and manage liquidity into just a multiple price range at any given point in time. Also, this engines comes with an additional feature of providing a whitelist of addresses that can only perform actions like a deposit or more into the app.",
  MultiPositionKinetix:
  "This Engine has the ability to split and manage liquidity into just a multiple price range at any given point in time.",
  SinglePositionKinetix:
  "This Engine has the ability to manage 100% of liquidity into just a single price range at any given point in time.",
  PermissionedSinglePositionKinetix:
  "This Engine has the ability to manage 100% of liquidity into just a single price range at any given point in time. Also, this engines comes with an additional feature of providing a whitelist of addresses that can only perform actions like a deposit or more into the app.",
  PermissionedMultiPositionKinetix:
  "This Engine has the ability to split and manage liquidity into just a multiple price range at any given point in time. Also, this engines comes with an additional feature of providing a whitelist of addresses that can only perform actions like a deposit or more into the app.",
};

export const paginateData = (data, pageSize = 1000) => {
  return (data || []).reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / pageSize);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
};

export const OFAC_LIST = [
  { name: "Cuba", code: "CUB" },
  { name: "Iran", code: "IRN" },
  { name: "North Korea", code: "PRK" },
  { name: "Russia", code: "RUS" },
  { name: "Syria", code: "SYR" },
  { name: "Crimea", code: "UKR-CR" },
  { name: "Donetsk", code: "UKR-DO" },
  { name: "Luhansk", code: "UKR-LU" },
  { name: "Balkans", code: "BALK" },
  { name: "Belarus", code: "BLR" },
  { name: "Burma (Myanmar)", code: "MMR" },
  { name: "Central African Republic", code: "CAF" },
  { name: "Congo, Dem. Rep. of", code: "COD" },
  { name: "Ethiopia", code: "ETH" },
  { name: "Hong Kong", code: "HKG" },
  { name: "Iraq", code: "IRQ" },
  { name: "Lebanon", code: "LBN" },
  { name: "Libya", code: "LBY" },
  { name: "Sudan", code: "SDN" },
  { name: "Venezuela", code: "VEN" },
  { name: "Yemen", code: "YEM" },
  { name: "Zimbabwe", code: "ZWE" },
  { name: "United States", code: "US" },
];

export const getTVL = (vaultInfo: any, data: any) => {
  let chainName = getChainName(vaultInfo["chainId"] || 137);
  chainName = chainName === 'mainnet' ? 'ethereum' : chainName.toLocaleLowerCase();
  let priceId0: any;
  let priceId1: any;

  if (data) {
    priceId0 = data[`${chainName}:${vaultInfo["token0"].toLowerCase()}`];
    priceId1 = data[`${chainName}:${vaultInfo["token1"].toLowerCase()}`];
  }
  
  vaultInfo["getBalance0String"] =
    vaultInfo["getBalance0String"] ||
    utils.formatUnits(vaultInfo["token0Balance"], vaultInfo["token0Decimals"]);
  vaultInfo["getBalance1String"] =
    vaultInfo["getBalance1String"] ||
    utils.formatUnits(vaultInfo["token1Balance"], vaultInfo["token1Decimals"]);

  const token0: any = priceId0
    ? (priceId0 * parseFloat(vaultInfo["getBalance0String"])).toFixed(6)
    : `${parseFloat(vaultInfo["getBalance0String"]).toFixed(6)} ${
        vaultInfo["token0Symbol"]
      }`;
  const token1: any = priceId1
    ? (priceId1 * parseFloat(vaultInfo["getBalance1String"])).toFixed(6)
    : `${parseFloat(vaultInfo["getBalance1String"]).toFixed(6)} ${
        vaultInfo["token1Symbol"]
      }`;

  const token1Eth: any = `${parseFloat(vaultInfo["getBalance1String"]).toFixed(6)} ${
      vaultInfo["token1Symbol"]
    }`;

  const token0Eth: any = `${parseFloat(vaultInfo["getBalance0String"]).toFixed(6)} ${
    vaultInfo["token0Symbol"]
  }`;

  const sum = +token0 + +token1;
  return (!priceId0 || !priceId1)
    ? [token0Eth, token1Eth]
    : [`${sum ? sum : "0"} USD`, " "];
};

export const getTVLAndFees = async (vaultDetails, chainId) => {
  let chainName = getChainName(chainId || 137);
  chainName = chainName === 'mainnet' ? 'ethereum' : chainName.toLowerCase();
  const priceId0 = `${chainName}:${vaultDetails["token0"].toLowerCase()}`;
  const priceId1 = `${chainName}:${vaultDetails["token1"].toLowerCase()}`;
  const priceData = [priceId0, priceId1];
  const tokenPairs = [vaultDetails["token0"].toLowerCase(), vaultDetails["token1"].toLowerCase() ];


  let fetchedPrices: TOKEN_PRICES = null;            
  // if (!fetchedPrices || !fetchedPrices.token0 || !fetchedPrices.token1) {
  if (chainId === Evmos.chainId) {
    fetchedPrices = await getUsdPriceEvmos(tokenPairs);
  } else if(chainId === ChainId.Andromeda) {
    fetchedPrices = await getUsdPriceMetis(tokenPairs);
  } else if(chainId === ChainId.Avalanche) {
    fetchedPrices = await getUSDPriceAvalanche(tokenPairs);
  } else if (chainId === ChainId.Celo) {
    fetchedPrices = await getUsdPriceCelo(tokenPairs);
  } else if (chainId === ZkEvm.chainId) {
    fetchedPrices = await getUsdPriceZkEvm(tokenPairs);
  } else if (chainId === Base.chainId) {
    fetchedPrices = await getUsdPriceBase(tokenPairs);
  }

  if (!fetchedPrices || (fetchedPrices && (fetchedPrices.token0 === '0' || fetchedPrices.token1 === '0'))) {
    fetchedPrices = await getUSDPrices(priceData);
  }
  

  if (fetchedPrices?.token0 && fetchedPrices?.token1 && parseFloat(fetchedPrices?.token1) > 0 && parseFloat(fetchedPrices?.token0) > 0) {
    const token0Price = parseFloat(fetchedPrices.token0) * parseFloat(
      vaultDetails["getBalance0String"]
    );
  
    const token1Price = parseFloat(fetchedPrices.token1) *  parseFloat(
      vaultDetails["getBalance1String"]
    );
  
    const tvl = token0Price + token1Price;
  
    const fees = parseFloat(fetchedPrices.token0) * parseFloat(vaultDetails["fees0"]) + parseFloat(fetchedPrices.token1) * parseFloat(vaultDetails["fees1"]);
  
    return {
      tvl: [`${tvl.toFixed(8)} USD`],
      fees: [`${fees.toFixed(8)} USD`],
    };
  } else {
    return {
      tvl: [
        `${parseFloat(vaultDetails["getBalance0String"]).toFixed(8)} ${
          vaultDetails["token0Symbol"]
        }`,
        `${parseFloat(vaultDetails["getBalance1String"]).toFixed(8)} ${
          vaultDetails["token1Symbol"]
        }`,
      ],
      fees: [
        `${parseFloat(vaultDetails["fees0"]).toFixed(8)} ${
          vaultDetails["token0Symbol"]
        }`,
        `${parseFloat(vaultDetails["fees1"]).toFixed(8)} ${
          vaultDetails["token1Symbol"]
        }`,
      ],
    };
  }

  // const prices = await getUSDPrices(priceData);
  // if (Object.keys(prices.coins).length > 0) {
  //   const newPrice = Object.keys(prices.coins).reduce(
  //     (all, item) => {
  //       all.tvl =
  //         all.tvl +
  //         prices.coins[item].price *
  //           parseFloat(
  //             vaultDetails[
  //               item === priceId0 ? "getBalance0String" : "getBalance1String"
  //             ]
  //           );
  //       all.fees =
  //         all.fees +
  //         prices.coins[item].price *
  //           parseFloat(vaultDetails[item === priceId0 ? "fees0" : "fees1"]);
  //       return all;
  //     },
  //     {
  //       tvl: 0,
  //       fees: 0,
  //     }
  //   );
  //   return {
  //     tvl: [`${newPrice.tvl.toFixed(8)} USD`],
  //     fees: [`${newPrice.fees.toFixed(8)} USD`],
  //   };
  // } else {
  //   return {
  //     tvl: [
  //       `${parseFloat(vaultDetails["getBalance0String"]).toFixed(8)} ${
  //         vaultDetails["token0Symbol"]
  //       }`,
  //       `${parseFloat(vaultDetails["getBalance1String"]).toFixed(8)} ${
  //         vaultDetails["token1Symbol"]
  //       }`,
  //     ],
  //     fees: [
  //       `${parseFloat(vaultDetails["fees0"]).toFixed(8)} ${
  //         vaultDetails["token0Symbol"]
  //       }`,
  //       `${parseFloat(vaultDetails["fees1"]).toFixed(8)} ${
  //         vaultDetails["token1Symbol"]
  //       }`,
  //     ],
  //   };
  // }
};

export const getUniswapCandles = (
  chainId: number,
  vaultDetails: any,
  swaps: any,
  positions: any
) => {
  if (vaultDetails["token0Decimals"] && swaps.length > 0) {
    const Token0 = new Token(
      chainId,
      vaultDetails["token0"],
      Number(vaultDetails["token0Decimals"]),
      vaultDetails["token0Symbol"],
      vaultDetails["token0Name"]
    );

    // and we can create a token object for token1
    const Token1 = new Token(
      chainId,
      vaultDetails["token1"],
      Number(vaultDetails["token1Decimals"]),
      vaultDetails["token1Symbol"],
      vaultDetails["token1Name"]
    );
    
    if (positions && positions.length > 0) {
      const firstPosition = positions[0];
      // filter swaps greater then first position timestamp
      swaps = swaps.filter((d: any) => d.timestamp >= parseInt(firstPosition.timestamp));
    }

    const convertedSwaps = swaps.map((d: any) => {
      return {
        index: new Date(d.timestamp * 1000).getTime(),
        value: (
          Number(
            new Price(
              Token0,
              Token1,
              JSBI.BigInt(Q192),
              JSBI.multiply(
                JSBI.BigInt(d.sqrtPriceX96),
                JSBI.BigInt(d.sqrtPriceX96)
              )
            ).toFixed(26)
          ) *
          10 ** (Token1.decimals - Token0.decimals)
        ).toString(),
      };
    });

    if (
      convertedSwaps[0] === undefined ||
      Object.keys(convertedSwaps[0]).length === 0
    ) {
      return [];
    }

    const series = timeSeries({
      name: "swaps",
      columns: [...Object.keys(convertedSwaps[0])],
      points: convertedSwaps.map((point: any) => {
        return [...Object.values(point)].map((value) => Number(value));
      }),
    });

    const windowRollup = series
      .fixedWindowRollup({
        window: PondWindow(duration("1h")),
        aggregation: {
          high: ["value", max()],
          low: ["value", min()],
          close: ["value", last()],
          open: ["value", first()],
        },
      })
      .fill({
        fieldSpec: "value",
        method: 2,
      });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (
      windowRollup
        .toJSON()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .points.map((p: any) => {
          // Convert time range to timestamp of beginning of range
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          p[0] = new Index(p[0])
            .toTimeRange()
            .toTime(TimeAlignment.Begin)
            .toDate()
            .getTime();
          return p;
        })
        .map((p: any) => {
          return {
            periodStartUnix: p[0],
            high: p[1],
            low: p[2],
            close: p[3],
            open: p[4],
          };
        })
    );
  }
  return [];
};


export const getCandlesFromSwaps = (
  chainId: number,
  swaps: any,
  candleSize: string
): Candle[] => {
  let candleDuration;

  try {
    candleDuration = duration(candleSize)
  } catch(e) {
    candleDuration = duration('15m');
  }

  if (swaps.length > 0) {
    const Token0 = new Token(
      chainId,
      swaps[0].token0.id,
      Number(swaps[0].token0.decimals),
      swaps[0].token0.symbol,
      swaps[0].token0.name
    );
    // and we can create a token object for token1
    const Token1 = new Token(
      chainId,
      swaps[0].token1.id,
      Number(swaps[0].token1.decimals),
      swaps[0].token1.symbol,
      swaps[0].token1.name
    );
    
    const convertedSwaps = swaps.map((d: any) => {
      return {
        index: new Date(d.timestamp * 1000).getTime(),
        value: (
          Number(
            new Price(
              Token0,
              Token1,
              JSBI.BigInt(Q192),
              JSBI.multiply(
                JSBI.BigInt(d.sqrtPriceX96),
                JSBI.BigInt(d.sqrtPriceX96)
              )
            ).toFixed(26)
          ) *
          10 ** (Token1.decimals - Token0.decimals)
        ).toString(),
      };
    });
    

    if (
      convertedSwaps[0] === undefined ||
      Object.keys(convertedSwaps[0]).length === 0
    ) {
      return [];
    }

    const series = timeSeries({
      name: "swaps",
      columns: [...Object.keys(convertedSwaps[0])],
      points: convertedSwaps.map((point: any) => {
        return [...Object.values(point)].map((value) => Number(value));
      }),
    });

    const windowRollup = series
      .fixedWindowRollup({
        window: PondWindow(candleDuration),
        aggregation: {
          high: ["value", max()],
          low: ["value", min()],
          close: ["value", last()],
          open: ["value", first()],
        },
      })
      .fill({
        fieldSpec: "value",
        method: 2,
      });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (
      windowRollup
        .toJSON()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .points.map((p: any) => {
          // Convert time range to timestamp of beginning of range
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          p[0] = new Index(p[0])
            .toTimeRange()
            .toTime(TimeAlignment.Begin)
            .toDate()
            .getTime();
          return p;
        })
        .map((p: any) => {
          return {
            periodStartUnix: p[0],
            high: p[1],
            low: p[2],
            close: p[3],
            open: p[4],
          };
        })
    );
  }
  return [];
};

export const getRebalancesData = (vaultRebalances: any, startDate: any) => {
  const uniqData = {};

  vaultRebalances.filter(d => parseInt(d.timestamp) >= startDate)
    .map((d: any) => {
      const positions = d.upperTick.map((value: any, index: number) => {
        return {
          upperTick: value,
          lowerTick: d.lowerTick[index],
          weight: parseInt(d.relativeWeight[index]),
        };
      });
      const newDate = parseInt(d.timestamp);

      uniqData[newDate] = {
        timestamp: new Date(parseInt(d.timestamp) * 1000),
        positions: positions,
      }
      return null;
    });

  return Object.values(uniqData);
};

const testnetChainIds = [
  3, 4, 5, 11155111, 18, 338, 97, 365, 80001, 11297108099, 1337, 4002, 43113,
  42261, 420, 69, 421611, 421613, 1313161555, 111, 280, 5391184,
];

export const isTestnet = (chainId) => {
  return testnetChainIds.indexOf(chainId) !== -1 || chainId === undefined;
};

// convert number to number as per thegraph
export function toGraphNumberHex(num: number): string {
  return '0x' + num.toString(16);
}


export const logEventInSentry = (from, data={}) => {
  Sentry.captureEvent({
    message: from,
    level: 'info',
    extra: {
      ...data
    }
  });
}

const evmosBaseExplorerUrl = 'https://escan.live/';

export const getAddressLink = (explorerUrl: string) => (address: string) => `${explorerUrl}/address/${address}`;

export const getTransactionLink = (explorerUrl: string) => (txnId: string) => `${explorerUrl}/tx/${txnId}`;

export const getChainName = (chainId: number) => { 
  if (ChainId[chainId]) {
    return ChainId[chainId];
  }

  if (chainId === Evmos.chainId) {
    return Evmos.chainName;
  }

  if (chainId === ZkEvm.chainId) {
    return ZkEvm.chainName;
  }

  if (chainId === Base.chainId) {
    return Base.chainName;
  }

  if (chainId === Kava.chainId) {
    return Kava.chainName;
  }

  return 'Polygon';

}

export const Evmos: Chain = {
  chainId: 9001,
  chainName: 'Evmos',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0xcA11bde05977b3631167028862bE2a173976CA11',
  rpcUrl: 'https://evmos-mainnet.gateway.pokt.network/v1/lb/e2e725826773754677c39204',
  nativeCurrency: {
    name: 'Evmos',
    symbol: 'Evmos',
    decimals: 18,
  },
  blockExplorerUrl: evmosBaseExplorerUrl,
  getExplorerAddressLink: getAddressLink(evmosBaseExplorerUrl),
  getExplorerTransactionLink: getTransactionLink(evmosBaseExplorerUrl),
}


export const ZkEvm: Chain = {
  chainId: 1101,
  chainName: 'zkEVM',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0xcA11bde05977b3631167028862bE2a173976CA11',
  rpcUrl: 'https://polygonzkevm-mainnet.g.alchemy.com/v2/VcbyD9Yjek4RMPTcURSajf1ATWkMLUt6',
  nativeCurrency: {
    name: 'Eth',
    symbol: 'Eth',
    decimals: 18,
  },
  blockExplorerUrl: 'https://zkevm.polygonscan.com/',
  getExplorerAddressLink: getAddressLink('https://zkevm.polygonscan.com/'),
  getExplorerTransactionLink: getTransactionLink('https://zkevm.polygonscan.com/'),
}



export const getAssetUrls = (dex: string, chainId: number, tokenAddress: string): string => {
  if (dex === 'uniswap') {
   return `https://raw.githubusercontent.com/uniswap/assets/master/blockchains/${getChainName(chainId)}/assets/${tokenAddress}/logo.png`
  }

  return '';
}

export const getPositionTypeFromBeacon = (beacon: string): PositionType => {
  const positionTypes: PositionType[] = ['MultiPosition', 'SinglePosition', 'PermissionedSinglePosition', 'PermissionedMultiPosition'];

  if (!beacon) {
    return 'MultiPosition';
  }

  for (const positionType of positionTypes) {
    if (beacon.includes(positionType)) {
      return positionType;
    }
  }

  throw new Error(`Unknown position type in beacon: ${beacon}`);
};

export const getDexNameFromBeacon = (beacon: string): DexName => {

  if (!beacon) {
    return 'uniswap';
  }

  const dexName = beacon.replace(/^(.*?)(UniswapV3|Sushi|Forge|MAIA|Pancake|Retro)$/, '$2').toLowerCase();
  return dexName as DexName;
};

export const getClonePageDetails = (strategybundle, vaultDetails, payloadIpfs, supportedChain) => {

  if (!strategybundle || !vaultDetails || !payloadIpfs || !supportedChain) {
    return null;
  }
  const strategyConfig = payloadIpfs.strategyConfigData;
 
  const strategyParams =  Object.keys(strategyConfig).reduce((params, key) => {
    if (['appImageUrl', 'description', 'epochLength', 'epochStart', 'name'].indexOf(key) < 0) {
      params[key] = strategyConfig[key];
    }
    return params;
  }, {});

  return {
    strategy: {
      beacon: getPositionTypeFromBeacon(vaultDetails.vaultType),
      name: strategyConfig["name"],
      description: strategyConfig["description"],
      appImgUrl: strategyConfig["appImgUrl"],
      strategyInterval: strategyConfig["epochLength"],
      strategyId: strategybundle.id,
      deploymentType: 'clone',
      executionBundle: {
        hash: strategybundle.executionBundle,
        parameters: {
         
          ...strategyParams
        }
      },
      dataConnectorBundle: payloadIpfs.dataConnectorsData
    },
    poolId: vaultDetails.pool,

  }

}


export const factoryAddress: FactoryAddresses = {
  uniswap: {
    '137': {
      factory: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
      tickLens: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573'
    },
    '10': {
      factory: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
      tickLens: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573'
    },
    '42161': {
      factory: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
      tickLens: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573'
    },
    '56': {
      factory: '0xdB1d10011AD0Ff90774D0C6Bb92e5C5c8b4461F7',
      tickLens: '0xD9270014D396281579760619CCf4c3af0501A47C'
    },
    '43114': {
      factory: '0x740b1c1de25031C31FF4fC9A62f554A55cdC1baD',
      tickLens: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573'
    },
    '42220': {
      factory: '0xAfE208a311B21f13EF87E33A90049fC17A7acDEc',
      tickLens: '0x5f115D9113F88e0a0Db1b5033D90D4a9690AcD3D'
    },
    '80001': {
      factory: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
      tickLens: '0xbfd8137f7d1516D3ea5cA83523914859ec47F573'
    }
    
  },
  sushi: {
    '137': {
      factory: "0x917933899c6a5F8E37F31E19f92CdBFF7e8FF0e2",
      tickLens: '0x9fdeA1412e50D78B25aCE4f96d35801647Fdf7dA'
    },
    '10': {
      factory: '0x9c6522117e2ed1fE5bdb72bb0eD5E3f2bdE7DBe0',
      tickLens: '0x0367a647A68f304f2A6e453c25033a4249d7F2C6'
    },
    '42161': {
      factory: '0x1af415a1EbA07a4986a52B6f2e7dE7003D82231e',
      tickLens: '0x8516944E89f296eb6473d79aED1Ba12088016c9e'
    },
    '56': {
      factory: '0x126555dd55a39328F69400d6aE4F782Bd4C34ABb',
      tickLens: '0x10c19390E1Ac2Fd6D0c3643a2320b0abA38E5bAA'
    },
    '43114': {
      factory: '0x3e603C14aF37EBdaD31709C4f848Fc6aD5BEc715',
      tickLens: '0xDdC1b5920723F774d2Ec2C3c9355251A20819776'
    },
    '108': {
      factory: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
      tickLens: '0x0BE808376Ecb75a5CF9bB6D237d16cd37893d904'
    },
    '1101': {
      factory: '0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506',
      tickLens: '0x0BE808376Ecb75a5CF9bB6D237d16cd37893d904'
    },
    '8453': {
      factory: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
      tickLens: '0xF4d73326C13a4Fc5FD7A064217e12780e9Bd62c3'
    }
  },
  forge: {
    '9001': {
      factory: "0xf544365e7065966f190155F629cE0182fC68Eaa2",
      tickLens: "0x8Ae03dB96E16C5cEec346Aae78A8103365F5232B"
    }
  },
  maia: {
    '1088': {
      factory: '0xf5fd18Cd5325904cC7141cB9Daca1F2F964B9927',
      tickLens: '0x7ddEecA94ce57264410d51E9F73a0f8983b2eAA7'
    }
  },
  pancake: {
    '56': {
      factory: '0x0BFbCF9fa4f9C56B0F40a671Ad40E0805A091865',
      tickLens: '0x9a489505a00cE272eAa5e07Dba6491314CaE3796'
    }
  },
  retro: {
    "137": {
      factory: '0x91e1B99072f238352f59e58de875691e20Dc19c1',
      tickLens: "0x9fdeA1412e50D78B25aCE4f96d35801647Fdf7dA",
      initCodeHash: '0x817e07951f93017a93327ac8cc31e946540203a19e1ecc37bc1761965c2d1090'
    }
  },
  kinetix: {
    "2222": {
      factory: '0x2dBB6254231C5569B6A4313c6C1F5Fe1340b35C2',
      tickLens: '0xdc7A5276aB4C6cd25e16b6118B230109945D2426'
    }
  }
}


export const getFactoryAddress = (protocol: Protocol, chainId: number) => {
  const dexes = Object.keys(factoryAddress);
  for (const dex of dexes) {
    if (protocol.toLowerCase().indexOf(dex) > -1) {
      return factoryAddress[dex]?.[`${chainId}`]?.factory;
    }
  }
}

export const getInitCodeHAsh = (protocol: Protocol, chainId: number) => {
  const dexes = Object.keys(factoryAddress);
  for (const dex of dexes) {
    if (protocol.toLowerCase().indexOf(dex) > -1) {
      const hash = factoryAddress[dex]?.[`${chainId}`]?.initCodeHash;
      return hash
      
    }
  }
}


export const getTickLensAddress = (protocol: Protocol, chainId: number) => {
  const dexes = Object.keys(factoryAddress);
  for (const dex of dexes) {
    if (protocol.toLowerCase().indexOf(dex) > -1) {
      return factoryAddress[dex]?.[`${chainId}`]?.tickLens;
    }
  }
}

interface TickBounds {
  maxUpperBound: number
  maxLowerBound: number
}

interface Position {
  upperTicks: number[]
  lowerTicks: number[]
}


export const isCurrentTickInRange = (
  currentTick: number,
  bounds: TickBounds
): boolean =>  {
  const { maxUpperBound, maxLowerBound } = bounds;
  return currentTick <= maxUpperBound && currentTick >= maxLowerBound
}

export const getMaxBounds = (input: Position): TickBounds => {
  // Parse strings to numbers and find the max values
  const maxUpperBound = Math.max(...input.upperTicks.map(Number))
  const maxLowerBound = Math.min(...input.lowerTicks.map(Number))
  return {
    maxUpperBound,
    maxLowerBound,
  }
}

export const calculatePositionWidth = (
  currentTick: number,
  upperTick: number,
  lowerTick: number
): number => {
  const currentPrice = Math.pow(1.0001, Number(currentTick))
  const upperPrice = Math.pow(1.0001, Number(upperTick))
  const lowerPrice = Math.pow(1.0001, Number(lowerTick))

  // Calculate upper and lower bounds width in percentage
  const upperBoundWidthPercent =
    ((upperPrice - currentPrice) / currentPrice) * 100
  const lowerBoundWidthPercent =
    ((currentPrice - lowerPrice) / currentPrice) * 100

  // Calculate average width of the position
  const positionWidthPercent =
    (upperBoundWidthPercent + lowerBoundWidthPercent) / 2

  return Math.abs(positionWidthPercent);
}

export const convertSecondsToHumanReadable = (seconds: number): string => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
  const formattedDuration = formatDuration(duration, {
    format: ["days", "hours", "minutes"],
  })
  return formattedDuration
}

export const getTokenURL = (address, symbol, chainId, dex) => {
  let url;
  const chain = getChainName(chainId);
  const chainName = chain === "BSC" ? 'binance' : chain === "Mainnet"? "ethereum": chain?.toLowerCase();
  if(chainName && address && dex) {
    const checkSumAddress = utils.getAddress(address);
    if (dex && dex.toLowerCase().indexOf('uniswap') !== -1) {
      //check the name of all the chains
      url = `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/${chain?.toLowerCase()}/assets/${checkSumAddress}/logo.png`
    } else if (dex.toLowerCase().indexOf('forge') !== -1) {
      const tokenUrl = getForgeTokenList(symbol.toLowerCase());
      url = tokenUrl;
    } else if (dex.toLowerCase().indexOf('maia') !== -1) {
      url = `https://raw.githubusercontent.com/MaiaDAO/token-list/main/${checkSumAddress}.svg`;
    } else if (dex.toLowerCase().indexOf('sushi') !== -1) {
      url = `https://cdn.sushi.com/image/upload/f_auto,c_limit,w_64,q_auto/tokens/${chainId}/${checkSumAddress}.jpg`
    } else if (dex.toLowerCase().indexOf('pancake') !== -1) {
      //add chain wise logic
      url = `https://tokens.pancakeswap.finance/images/eth/${checkSumAddress}.png`;
    }  else if (dex.toLowerCase().indexOf('retro') !== -1) {
      //add chain wise logic
      url = `https://retro.finance/images/tokens/${symbol.toUpperCase()}.png`;
    } else if(dex.toLowerCase().indexOf('kinetix') !== -1) {
      // url = `https://res.cloudinary.com/kinetix/image/upload/q_auto/v1/website-assets/coins/others/${symbol.toUpperCase()}-original.png?_a=BATCtdAA0`
      url = `https://raw.githubusercontent.com/kinetixfi/assets/master/blockchains/kavaevm/assets/${checkSumAddress}/logo.png`;
      if(symbol.toLowerCase() === 'pkfi') {
        url = `https://www.gitbook.com/cdn-cgi/image/width=40,dpr=2,height=40,fit=contain,format=auto/https%3A%2F%2F3367236650-files.gitbook.io%2F~%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FfCvG3GJjhzqh94soelRm%252Ficon%252FrxkFrW722xy3eYWie0uK%252FKFI_Token%25201.png%3Falt%3Dmedia%26token%3D91528fc6-c2f8-40ee-8637-a71a9dcbf202`
      }
    } 
    return {
      url,
      fallback: `https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/${chain?.toLowerCase()}/assets/${checkSumAddress}/logo.png` 
    }
    
  }
  return {
    url,
    fallback: null
  };
};

export const Base: Chain = {
  chainId: 8453,
  chainName: 'Base',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0xcA11bde05977b3631167028862bE2a173976CA11',
  rpcUrl: 'https://mainnet.base.org',
  nativeCurrency: {
    name: 'Eth',
    symbol: 'Eth',
    decimals: 18,
  },
  blockExplorerUrl: 'https://basescan.org/',
  getExplorerAddressLink: getAddressLink('https://basescan.org/'),
  getExplorerTransactionLink: getTransactionLink('https://basescan.org/'),
}

export const Kava: Chain = {
  chainId: 2222,
  chainName: 'Kava',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0xcA11bde05977b3631167028862bE2a173976CA11',
  rpcUrl: 'https://kava-mainnet.gateway.pokt.network/v1/lb/e2e725826773754677c39204',
  nativeCurrency: {
    name: 'kava',
    symbol: 'Kava',
    decimals: 18,
  },
  blockExplorerUrl: 'https://kavascan.com/',
  getExplorerAddressLink: getAddressLink('https://kavascan.com/'),
  getExplorerTransactionLink: getTransactionLink('https://kavascan.com/'),
}

export const getDexDetails = (beacon) => {
  if(beacon && beacon?.toLowerCase().indexOf('retro') !== -1) {
    return {
      name: 'Retro Finance',
      desc: "Retro finance is a AMM on Polygon, which serves as a solution for Polygon protocols, enabling them to boost liquidity and generate revenue. Users benefit from a blend of established and novel ve(3,3) tokenomics, enhancing their yields and liquidity.",
      website: "https://retro.finance/",
      twitter: 'https://twitter.com/Retro_finance',
      discord: "https://discord.com/invite/stabllabs",
      value: 'retro'
    }
  }
  if(beacon && beacon?.toLowerCase().indexOf('forge') !== -1) {
    return {
      name: 'ForgeDEX',
      desc: "The strongest interchain exchange. Swap, earn, and build on the premier Evmos community owned DEX.",
      website: "https://forge.trade/#/?intro=true",
      twitter: 'https://twitter.com/forgeDEX',
      discord: "",
      value: 'forge'
    }
  }
  if(beacon && beacon?.toLowerCase().indexOf('maia') !== -1) {
    return {
      name: 'Maia Dao',
      desc: "​Maia is the yield powerhouse of Metis with its community rooted in this Ethereum L2. With a 100% fair launch Maia is a truly community owned.",
      website: "https://maiadao.io/#/stake",
      twitter: 'https://twitter.com/MaiaDAOEco',
      discord: "https://discord.com/invite/7hXXFRC8Wv",
      value: 'maia'
    }
  }
  if(beacon && beacon?.toLowerCase().indexOf('sushi') !== -1) {
    return {
      name: 'Sushiswap',
      desc: "A Decentralised Finance (DeFi) app with features such as swap, cross chain swap, streaming, vesting, and permissionless market making for liquidity.",
      website: "https://www.sushi.com/",
      twitter: 'https://twitter.com/sushiswap',
      discord: "https://discord.com/invite/NVPXN4e",
      value: 'sushi'
    }
  }
  if(beacon && beacon?.toLowerCase().indexOf('uniswap') !== -1) {
    return {
      name: 'Uniswap',
      desc: " Uniswap is leading decentralized crypto trading protocol on multiple chains that works without any involvement with any centralized third party.",
      website: "https://uniswap.org/",
      twitter: 'https://twitter.com/uniswap/',
      discord: "https://discord.com/invite/FCfyBSbCU5",
      value: 'uniswap'
    }
  }
  if(beacon && beacon?.toLowerCase().indexOf('pancake') !== -1) {
    return {
      name: 'Pancakeswap',
      desc: "The most popular AMM on BSC by user count! Earn CAKE through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens!",
      website: "https://pancakeswap.finance/",
      twitter: 'https://twitter.com/pancakeswap',
      discord: "https://discord.com/invite/pancakeswap",
      value: 'pancake'
    }
  } 

  if (beacon && beacon?.toLowerCase().indexOf('kinetix') !== -1) {
    return {
      name: 'Kinetix',
      desc: "The Kinetix DeFi hub will encompass a suite of the most sophisticated and popular DeFi instruments in the Web3 landscape, providing the Kava blockchain with an one-stop platform for all DeFi things, offering the greatest solutions in decentralized finance under one umbrella.",
      website: "https://kinetix.finance/",
      twitter: 'https://twitter.com/KinetixFi',
      discord: "https://discord.gg/kinetixfi",
      value: 'kinetix'
    }
  }
  return null;
}

import { Contract, utils } from "ethers";
import { beaconContractNameMapper, getContractByNetwork, uploadFileAndGetHash, ZERO_ADDRESS } from "utils";
import { DataConnectorConfig, DataConnectorSchemaConfig, DataConnectorUpdateConfig, DexName, ExecutionBundleParameters, Pool, PositionType, Strategy } from "utils/types";

const contractNames: Record<DexName, Record<PositionType, string>> = {
  uniswap: {
    MultiPosition: "MultiPositionLiquidityManager",
    SinglePosition: "SinglePositionLiquidityManager",
    PermissionedSinglePosition: "WhitelistedSinglePositionBeaconName",
    PermissionedMultiPosition: "WhitelistedMultiPositionBeaconName",
  },
  sushi: {
    MultiPosition: "SushiMultiPositionLiquidityManager",
    SinglePosition: "SushiSinglePositionLiquidityManager",
    PermissionedSinglePosition: "SushiWhitelistedMultiLiquidityManager",
    PermissionedMultiPosition: "SushiWhitelistedSingleLiquidityManager",
  },
  forge: {
    MultiPosition: "ForgeMultiPositionLiquidityManager",
    SinglePosition: "ForgeSinglePositionLiquidityManager",
    PermissionedSinglePosition: "ForgeWhitelistedMultiLiquidityManager",
    PermissionedMultiPosition: "ForgeWhitelistedSingleLiquidityManager",
  },
  maia: {
    MultiPosition: "MAIAMultiPositionLiquidityManager",
    SinglePosition: "MAIASinglePositionLiquidityManager",
    PermissionedSinglePosition: "MAIAWhitelistedSingleLiquidityManager",
    PermissionedMultiPosition: "MAIAWhitelistedMultiLiquidityManager",
  },
  pancake: {
    MultiPosition: "PancakeMultiPositionLiquidityManager",
    SinglePosition: "PancakeSinglePositionLiquidityManager",
    PermissionedSinglePosition: "PancakeWhitelistedSingleLiquidityManager",
    PermissionedMultiPosition: "PancakeWhitelistedMultiLiquidityManager",
  },
  retro: {
    MultiPosition: "RetroMultiPositionLiquidityManager",
    SinglePosition: "RetroSinglePositionLiquidityManager",
    PermissionedSinglePosition: "RetroWhitelistedSingleLiquidityManager",
    PermissionedMultiPosition: "RetroWhitelistedMultiLiquidityManager",
  },
  kinetix: {
    MultiPosition: "KinetixMultiPositionLiquidityManager",
    SinglePosition: "KinetixSinglePositionLiquidityManager",
    PermissionedSinglePosition: "KinetixWhitelistedSingleLiquidityManager",
    PermissionedMultiPosition: "KinetixWhitelistedMultiLiquidityManager",
  }
};


export function getHashForStrategy(strategy: Strategy, pool: Pool): string | undefined {
  const poolFee = (Number(pool.feeTier)/10000).toFixed(2);
  return getHash(strategy, poolFee);
}

export function getHash(
  strategy: Strategy,
  feeTierPoolFee: string
): string | undefined {
  // Check for execution bundle hash
  if (strategy.executionBundle?.hash) {
    return strategy.executionBundle.hash;
  }

  // If no execution bundle hash, check for hash of specified fee tier

  if (strategy.executionBundle?.feeTiers) {
    const feeTier = strategy.executionBundle.feeTiers.find(
      (ft) => ft.poolFee === Number(feeTierPoolFee)
    );
    
    if (feeTier?.hash) {
      return feeTier.hash;
    }
  }

  // If no hashes found, return undefined
  return undefined;
}

export function getParameters(
  strategy: Strategy,
  feeTierPoolFee: string
): ExecutionBundleParameters | undefined {
  // Check for execution bundle hash
  if (strategy.executionBundle?.hash) {
    return strategy.executionBundle.parameters;
  }

  // If no execution bundle hash, check for hash of specified fee tier
  if (strategy.executionBundle?.feeTiers) {
    const feeTier = strategy.executionBundle.presets.find(
      (ft) => ft.poolFee === Number(feeTierPoolFee)
    );
    if (feeTier?.parameters) {
      return feeTier.parameters;
    }
  }

  // If no hashes found, return undefined
  return undefined;
}

export function mergeConfigurations(
  strategyConfig: ExecutionBundleParameters,
  modifiedConfig: ExecutionBundleParameters
): ExecutionBundleParameters {
  if (!modifiedConfig) {
    return strategyConfig;
  }
  const filteredModifiedConfig = Object.fromEntries(
    Object.entries(modifiedConfig).filter(([key, value]) => value !== undefined)
  );
  return { ...strategyConfig, ...filteredModifiedConfig };
}

export function getStrategyConfig(strategy: Strategy,  modifiedConfig: ExecutionBundleParameters, pool: Pool) {
  const poolFee = (Number(pool.feeTier) / 10000).toFixed(2);
  const strategyConfig = mergeConfigurations(
    getParameters(strategy, poolFee),
    modifiedConfig
  );

  return strategyConfig

}

export function getContractName(dex: DexName, position: PositionType): string | undefined {
  return contractNames[dex][position];
}

export function getBeaconName(contractName: string) {
    const reversedBeaconContractNameMapper: {[key: string]: string} = {};

    Object.keys(beaconContractNameMapper).forEach(key => {
    const value = beaconContractNameMapper[key];
        reversedBeaconContractNameMapper[value] = key;
    });
    return reversedBeaconContractNameMapper[contractName];
}

export type VaultConfig = {
    fee: number,
    slippage: number,
    ratioErrorTolerance: number,
    maxTickChange: number;
    twapInterval: number
}

export const vaultConfig = (selectedPool: Pool): VaultConfig => {
  
    return {
        fee: Number(selectedPool.feeTier),
        slippage: 2,
        ratioErrorTolerance: 20,
        maxTickChange: 750,
        twapInterval: 45
    }
}

export const getPayloadHash = async (
    dataConnectorConfigs:  DataConnectorUpdateConfig[],
    executionConfig: ExecutionBundleParameters,
    strategyName: string,
    selectedStrategy: Strategy,
    epochStart: number,
    selectedPool: Pool,
    epochLength: string
) => {

    const strategyConfigParams = getStrategyConfig(
      selectedStrategy,
      executionConfig,
      selectedPool
    );

    const strategyConfigData = {
        ...strategyConfigParams,
        epochStart: epochStart,
        name: strategyName,
        description: selectedStrategy.description,
        appImageUrl: selectedStrategy.appImgUrl,
        epochLength: epochLength
    }

    if (Object.keys(strategyConfigData).indexOf('poolFee') > -1) {
      strategyConfigData.poolFee = parseInt(selectedPool.feeTier);
    }

    const dataConnectors = selectedStrategy.dataConnectorBundle || [];

    const strategyConfig = {
        strategyConfigData: strategyConfigData,
        vaultPayload: vaultConfig(selectedPool),
        dataConnectorsData: dataConnectors.map((bundle, index) => {
            const config = dataConnectorConfigs.find(({hash}) => hash === bundle.bundleHash) || {configData: {}};

            const parameter = {
              ...bundle.configData,
              ...config.configData,
            }

            if (Object.keys(parameter).indexOf('url') > -1) {
              parameter.url = selectedPool.dexUrl;
            }
            if (Object.keys(parameter).indexOf('subgraphEndpoint') > -1) {
              parameter.subgraphEndpoint = selectedPool.dexUrl;
            }
            if (Object.keys(parameter).indexOf('poolAddress') > -1) {
              parameter.poolAddress = selectedPool.id;
            }
            if (Object.keys(parameter).indexOf('address') > -1) {
              parameter.address = selectedPool.id
            }

            return {
                bundleHash: bundle.bundleHash,
                configData: parameter
            }
        })
    }
    const blobFile = new Blob([JSON.stringify(strategyConfig)], {
      type: "application/json",
    });
    const hash = await uploadFileAndGetHash(blobFile);
    return hash;
  };



export type ConfiguredSettings = {
    configuredLookback: number;
    configuredCandleWidth: string;
};

export function configureSettings(selectedStrategy: Strategy): ConfiguredSettings {
    let configuredLookback: number = 121312;
    let configuredCandleWidth: string = '6h';

    const dataConnectors = selectedStrategy?.dataConnectorBundle || []; 

    for (let connector of dataConnectors) {
        if (connector?.configData?.lookback) {
            configuredLookback = connector?.configData?.lookback;
        }
        if (connector?.configData?.candleWidth) {
            configuredCandleWidth = connector?.configData?.candleWidth;
        }
    }

    return { configuredLookback, configuredCandleWidth };
}

export const hasStrategyChainReadForSlot0 = (selectedStrategy: Strategy) : boolean => {
  let isChainRead = false;
  selectedStrategy?.dataConnectorBundle?.map(d => {
    if(d.configData["isChainRead"]) {
      isChainRead = true;
    }
    return false;
  });
  return isChainRead;
}

export const quickModeSpreadOptions = [
  {
    spread: "5%",
    val: 0.05,
    details: ``
  },
  {
    spread: "10%",
    val: 0.10,
    details: ``
  },
  {
    spread: "25%",
    val: 0.25,
    details: `~8.5x more fees than v2 ($150k minimum required in v3 pool for equivelent $1m in v2 liquidity), Reduce Slippage approx by 25% Compared to V2 based on liqudity depth of the pool.`
  },
  {
    spread: "50%",
    val: 0.5,
    details: "~4x fees ($250k minimum required in v3 pool for equivelent $1m in v2 liquidity), Reduce Slippage approx by 50% Compared to V2 based on liqudity depth of the pool."
  },
  {
    spread: "75%",
    val: 0.75,
    details: "~2.5x fees ($450k  minimum required for equivelent $1m in v2 liquidity), Reduce Slippage approx by 75% Compared to V2 based on liqudity depth of the pool."
  }
]

export function getDataFromConfig(key: string, strategy: Strategy, connectorConfigs, dataConnectorConfigs: DataConnectorUpdateConfig[]) {

  const dataConnectorWithCandleWidth = connectorConfigs.find(({config}) => {
    return Object.keys(config.properties).indexOf(key) > -1;
  })
  
  if (dataConnectorWithCandleWidth) {
    const availableConfig = dataConnectorConfigs.find(({hash}) => hash === dataConnectorWithCandleWidth.hash);
    if (availableConfig) {
      return availableConfig.configData[key]
    }

    const strategyPreset = (strategy?.dataConnectorBundle || []).find(({bundleHash}) => {
       return bundleHash === dataConnectorWithCandleWidth.hash
    })

    if (strategyPreset) {
      return strategyPreset.configData[key];
    }
  }
  
  if (!strategy?.dataConnectorBundle || (strategy?.dataConnectorBundle && strategy?.dataConnectorBundle.length === 0)) {
    if (key === 'candleWidth') {
      return '30m';
    } else if(key === 'lookback') {
      return 100000;
    }
  }

  return null;
}

export function updateDataConnectorConfigs(
  dataConfig: DataConnectorConfig | {spreadPercentage: string},
  schemaConfigs: DataConnectorSchemaConfig[],
  savedConfigs: DataConnectorUpdateConfig[]
) {
  
  let newConfig = [...savedConfigs];

  if(!dataConfig){
    return newConfig;
  }

  for (const key of Object.keys(dataConfig)) {
    for (const schemaConfig of schemaConfigs) {
       if (Object.keys(schemaConfig.config.properties).indexOf(key) > -1) {
          const savedDataConfig = newConfig.find(({hash}) => hash === schemaConfig.hash);
          if (!savedDataConfig) {
            newConfig.push({
              hash:  schemaConfig.hash,
              configData: {
                ...{[key]: dataConfig[key]}
              }
            })
          } else {
            savedDataConfig.configData[key] = dataConfig[key]
            const filtered = newConfig.filter(({hash}) => hash !== savedDataConfig.hash);
            filtered.push(savedDataConfig);
            newConfig = filtered;
          }
       }
    }
  }

  return newConfig;
}

export const checkIfStrategyAlreadyExists = async (hash, chainId, library) => {
  const strategyRegistry = getContractByNetwork(chainId, "StrategyRegistry");

  const strategyRegistryInterface = new utils.Interface(strategyRegistry.abi);

  const strategyRegistryContract = new Contract(
    strategyRegistry.address,
    strategyRegistryInterface,
    library
  );
  
  const strategyDetails = await strategyRegistryContract.strategies(
    hash
  );

  if (
    strategyDetails.owner !== ZERO_ADDRESS 
  ) {
    return strategyDetails.id.toHexString() 
  } else {
    return null;
  }
};

export const isDataConnectorHashCandles = (strategy: Strategy, schemaConfigs: DataConnectorSchemaConfig[]) => {
  if ( strategy.name === 'Channel Multiplier Strategy' || strategy.name === "Static Stable Strategy") {
    return false;
  }

  if (schemaConfigs) {
    const config = schemaConfigs.find((schemaConfig) => Object.keys(schemaConfig.config.properties).indexOf('candleWidth') > -1);

    return config ? true : false;
  }

  return false;
}
import React, { FunctionComponent } from "react";
import millify from "millify";
import { Icon } from "./Icon";
import { Link } from "./Link";
import { TooltipProps, Tooltip } from "./Tooltip";
import { Shimmer } from "./Shimmer";

export interface LabelDataProps extends TooltipProps {
  alignment?: "vertical" | "horizontal";
  label: string;
  labelStyle?: string;
  contentAlignment?: "center" | "left" | "right";
  data: any;
  dataStyle?: string;
  dataType?: "link" | "text" | "multilineText" | "asset" | "assetWithInfo";
}

const renderData = (data, type = "text", dataStyle, label) => {
  if (type === "link") {
      return data.text ? (
        <Link
          underline={true}
          onClick={() => data.onClick()}
          content={data.text}
          target={"_self"}
        />
      ) : (
        <Shimmer width="2/3" height="8" />
      );
  }
  if (type === "multilineText") {
    return (
      <div className={`capitalize`}>
        {data.length !== 0 ? (
          data.map((text) => {
            // 123 BTC -> [123, BTC];
            const splitData = text.split(" ");
            return (
              <div className={`whitespace-normal ${dataStyle}`}>
                {millify(splitData[0], {
                  precision: 3,
                })}{" "}
                {splitData[1]}
              </div>
            );
          })
        ) : (
          <Shimmer width="2/3" height="8" />
        )}
      </div>
    );
  }

  if (type === "asset") {

    return (
      <div className={`flex flex-row`}>
          {data[0] ? (
            <Icon
              tokenURL={data[0]}
              type={"token"}
              tokenSize="large"
              color={"#242b57"}
            />
          ) : (
            <Shimmer width="12" height="8" />
          )}
          {data[1] ? (
            <Icon
              tokenURL={data[1]}
              type={"token"}
              tokenSize="large"
              color={"#242b57"}
            />
          ) : data.length > 1 ? (
            <Shimmer width="12" height="8" />
          ): null}
      </div>
    );
  }
  if (type === "assetWithInfo") {
    return (
      <div className={`flex ${data.length > 1? "justify-end": ""}`}>
          {data[0] ? (
            data.map(d => (
              <Icon
              tokenURL={d}
              type={"token"}
              tokenSize="large"
              color={"#242b57"}
            />
            ))
          ) : (
            <Shimmer width="12" height="8" />
          )}
      </div>
    );
  }

  return data;
};

export const LabelData: FunctionComponent<LabelDataProps> = (props) => {
  const labelSplit = props.label?.split('/');
  return (
    <div
      data-testid="labelData-component"
      data-tip={props.tooltipId || ""}
      data-for={props.tooltipId || ""}
      className={`
      ${
        props.alignment === "vertical"
          ? "flex-inline justify-center"
          : "flex justify-between"
      }
    `}
    >
      <div
        data-testid="labelData-component__heading"
        className={`${props.labelStyle}`}
      >
        {props.dataType === 'asset' || props.dataType === "assetWithInfo"  ? <div className="flex flex-col"> 
        <div>{labelSplit?.[0] || ''}</div>
        <div className="flex">
          {labelSplit?.[1] || ''}
          {labelSplit?.[2] || ''}
        </div>
        </div>: props.label}
      </div>
      <div
        data-testid="labelData-component__content"
       
        className={`${props.dataStyle} ${
          props.dataType === "asset" || props.dataType === "assetWithInfo"  ? "" : "truncate"
        }`}
      >
        {renderData(props.data, props.dataType, props.dataStyle, props.label)}
      </div>
      {props.tooltipId && props.tooltipContent && <Tooltip {...props} tooltipId={props.tooltipId} />}
    </div>
  );
};

LabelData.defaultProps = {
  alignment: "vertical",
  label: "Label - 1",
  contentAlignment: "center",
  data: "Data - 1 %",
  dataType: "text",
};

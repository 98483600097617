import { gql, useLazyQuery } from "@apollo/client";
import { Contract } from "@ethersproject/contracts";
import {
  DateTime,
  Dropdown,
  IconDetailBox,
  Input,
  Label,
} from "components/lib";
import { useConfig, useEthers } from "@usedapp/core";
import { utils } from "ethers";
import { useEffect, useState } from "react";

import CommonAppConfig from "components/CommonAppConfig";
import { sendNotification } from "components/Container";
import { getClient } from "services/graphql";
import {
  ZERO_ADDRESS,
  beaconNameMapper,
  frequencyOptions,
  getContractByNetwork,
  supportedChains,
  getSupportedDexes,
  appTypes
} from "utils";
import uniswapV3Json from 'utils/vaultConfig/uniswapV3.json';
import pancakeV3Json from 'utils/vaultConfig/pancakeV3.json';
import ConfigModal from "../common/configModal";
import UploadFile from "../common/fileUpload";
import SharedButtons from "../common/sharedButton";

const GET_BEACONS = gql`
  query getBeacons {
    vaultBeacons {
      id
      name
      ipfsHash
      status
    }
  }
`;

const Strategy = ({
  steps,
  setSteps,
  setData,
  data,
}: {
  steps: number;
  setSteps: (step: number) => void;
  setData: (data: any) => void;
  data: any;
}) => {
  const { account, chainId, library } = useEthers();
  const strategyRegistry = getContractByNetwork(chainId, "StrategyRegistry");

  const [isOpen, setIsOpen] = useState(false);
  const [gqlCall, setGqlCall] = useState(false);
  const [selectedDex, setSelectedDex] = useState(null);
  const [selectedAppType, setSelectedAppType] = useState(null);

  let graphClient = getClient(supportedChains[0].subgraphURl);
  const tconfig = useConfig();
  const supportedChain = supportedChains.find((chain) => {
    if(chainId) {
      return chain.id === chainId;
    } else {
      return chain.id === tconfig.readOnlyChainId
    }
  });
  if (supportedChain) {
    graphClient = getClient(supportedChain.subgraphURl);
  }
  const [getBeacons, beacons] = useLazyQuery(GET_BEACONS, {
    client: graphClient,
  });

  // const [getBundles, bundles] = useLazyQuery(GET_BUNDLES, {
  //   client: graphClient,
  // });

  // const provider = new ethers.providers.Web3Provider(window["ethereum"]);
  const strategyRegistryInterface = new utils.Interface(strategyRegistry.abi);
  const strategyRegistryContract = new Contract(
    strategyRegistry.address,
    strategyRegistryInterface,
    library
  );

  useEffect(() => {
    if (!gqlCall) {
      getBeacons();
      // getBundles();
      setGqlCall(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gqlCall]);
  const [strategyData, setStrategyData] = useState({
    id: data.strategyData?.id,
    name: data.strategyData?.name,
    description: data.strategyData?.description,
    appImgUrl: data.strategyData?.appImgUrl,
    epochStart: data.strategyData?.epochStart,
    epochLength: data.strategyData?.epochLength,
    executionBundle: data.strategyData?.executionBundle,
    payloadHash: data?.strategyData?.payloadHash,
    vault: data.strategyData?.vault,
    payload: data?.strategyData
  });


  // useEffect(() => {
  //   // clone
  //   if (
  //     strategyData.vault &&
  //     strategyData.vault.name &&
  //     !strategyData.vault.ipfsHash &&
  //     beacons.data?.vaultBeacons &&
  //     data.clone
  //   ) {
  //     const vault = (beacons.data?.vaultBeacons || []).find(
  //       (v) => v.name === strategyData.vault.name
  //     );
  //     updateData(vault, "vault");
  //   }
  //   //  eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [strategyData.vault, beacons.data?.vaultBeacons]);

  const updateData = (val: string | number | object, key: string) => {
    const tempObj: Record<string, unknown> = {};
    if (typeof val === "string") {
      tempObj[key] = String(val);
    } else if (typeof val === 'number') {
      tempObj[key] = val;
    } else {
      if (key === 'vault') {
        let configJson = uniswapV3Json;
        if (selectedDex === 'pancake') {
          configJson = pancakeV3Json;
        }
       
        const vaultPayload = data?.strategyData?.vaultPayload;
        if (vaultPayload) {
          Object.keys(vaultPayload).map((key) => {
            configJson["properties"][key] = {
              ...configJson["properties"][key],
              default: vaultPayload[key],
            };
            return null;
          });
        }
        tempObj[key] = {
          ...val,
          configJson
        };
      } else {
        tempObj[key] = val;
      }
    }

    setStrategyData({
      ...strategyData,
      ...tempObj,
    });
  };

  const nextStep = async () => {
    const isStrategyAlreadyExists = await strategyRegistryContract.strategies(
      strategyData.executionBundle
    );
    // Check if the strategy for this execution bundle already exits if yes throw the error
    if (
      isStrategyAlreadyExists.owner !== ZERO_ADDRESS &&
      !data?.strategyData?.isDisabled &&
      !data?.clone
    ) {
      sendNotification({
        type: "error",
        transactionName: "App already exists",
        transaction: {
          msg: "The execution bundle you are trying to publish is already published by another user.",
        },
      });
    } else {
      setIsOpen(true);
    }
  };
  const updateEpochStart = (date) => {
    updateData(date.getTime() / 1000, "epochStart");
  };

  const DEXs = getSupportedDexes(supportedChain);

  const selectDex = (dex) => {
    setSelectedDex(dex);
  }

  const selectAppType = (appType) => {
    setSelectedAppType(appType);
  }

  useEffect(() => {
    if(selectedAppType && selectedDex) {
      const beaconName = Object.keys(beaconNameMapper).filter(k => k.toLowerCase().indexOf(selectedDex) !== -1).find(k => k.toLowerCase().indexOf(selectedAppType.toLowerCase()) !== -1)
      const vault = (beacons.data?.vaultBeacons || []).find((v) => v.name === beaconName);
      updateData(vault, "vault");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDex, selectedAppType])

  return (
    <div>
      {!account && (
        <div className={`mt-4 px-4`}>
          <IconDetailBox
            msg="You need connect your wallet in order to publish an app."
            icon="faWallet"
          />
        </div>
      )}
      <div className={`mt-4 px-4 lg:grid grid-cols-2 gap-4 `}>
        <div>
          <Label
            content={"Select Dex"}
            size={"large"}
            casing={"capitalize"}
            color={"white-500"}
          />
          <div className="mt-4">
            <Dropdown
              options={DEXs}
              onSelectHandler={selectDex}
              placeholder={"Select Dex"}
              type={"normal"}
              hasIcon={false}
              selected={selectedDex}
              backgroundType="white"
            />
          </div>
        </div>
        <div className="mt-4 lg:px-0 lg:mt-0">
          <Label
            content={"Select App Type"}
            size={"large"}
            casing={"capitalize"}
            color={"white-500"}
          />
          <div className="mt-4">
            <Dropdown
              options={appTypes}
              onSelectHandler={selectAppType}
              // onSelectHandler={(val: string) => updateData(val, "epochLength")}
              placeholder={"Select App Type"}
              type={"normal"}
              hasIcon={false}
              selected={selectedAppType}
              backgroundType="white"
            />
          </div>
        </div>
      </div>


      {CommonAppConfig({
        setData: setStrategyData,
        data: strategyData,
        appType: "apps",
      })}

      <div className={`mt-4 px-4 lg:grid grid-cols-2 gap-4 `}>
        <div>
          <Label
            content={"App Start Time"}
            size={"large"}
            casing={"capitalize"}
            color={"white-500"}
          />
          <div className="mt-4">
            <DateTime setDate={(val: Date) => updateEpochStart(val)} />
          </div>
        </div>
        <div className="mt-4 lg:px-0 lg:mt-0">
          <Label
            content={"App Interval"}
            size={"large"}
            casing={"capitalize"}
            color={"white-500"}
          />
          <div className="mt-4">
            <Dropdown
              options={frequencyOptions}
              onSelectHandler={(val: string) => updateData(val, "epochLength")}
              placeholder={"Select  Interval"}
              type={"normal"}
              hasIcon={false}
              selected={strategyData?.epochLength}
              backgroundType="white"
            />
          </div>
        </div>
      </div>

      <div className={`mt-4 px-4`}>
        <Input
          heading={"App IPFS Hash"}
          val={strategyData.executionBundle}
          onChange={(val: string) => updateData(val, "executionBundle")}
          align={"vertical"}
          disabled={true}
          backgroundType="white"
          placeholder={"Upload WASM file to get a IPFS hash"}
          optionalText={
            <UploadFile
              setIpfsHash={(hash) => {
                updateData(hash, "executionBundle");
              }}
            />
          }
        />
      </div>

      <SharedButtons
        steps={steps}
        setSteps={setSteps}
        nextStep={() => nextStep()}
        showOnlyNext={true}
        isDisabled={
          !strategyData.vault ||
          !strategyData.vault?.name ||
          !strategyData.name ||
          !strategyData.epochStart ||
          !strategyData.epochLength ||
          !strategyData.description ||
          !strategyData.executionBundle ||
          !selectedDex ||
          !selectedAppType ||
          !account
        }
      />
      {isOpen && (
        <ConfigModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setSteps={setSteps}
          steps={steps}
          data={{
            ...strategyData,
            // bundles: bundles?.data?.bundles,
          }}
          type={"strategy"}
          setData={(dataObj) => {
            setData({
              ...data,
              strategyData: dataObj,
            });
          }}
        />
      )}
    </div>
  );
};

export default Strategy;

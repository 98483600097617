import { Contract } from "@ethersproject/contracts";
import {
  Button,
  Dropdown,
  Input,
  Label
} from "components/lib";
import { useEthers } from "@usedapp/core";
import { utils } from "ethers";
import { useEffect, useState } from "react";

import CommonAppConfig from "components/CommonAppConfig";
import { sendNotification } from "components/Container";
import { useChainId } from "hooks/useChainId";
import {
  ZERO_ADDRESS,
  appTypes,
  getContractByNetwork
} from "utils";
import { ExecutionBundle, ExecutionBundleParameters, Pool, Strategy } from "utils/types";
import ConfigModal from "./configModal";
import UploadFile from "./fileUpload";

type StrategyDetailsPorps = {
    setStrategyDetails: (strategy: Strategy) => void;
    setExecutionBundleDetails: (bundle: ExecutionBundle) => void;
    moveNext: () => void;
    handleBack: () => void;
    selectedPool: Pool,
    customStrategy: Strategy
}

const StrategyDetails = ({
  setStrategyDetails,
  setExecutionBundleDetails,
  moveNext,
  handleBack,
  selectedPool,
  customStrategy
}: StrategyDetailsPorps) => {
  const { library } = useEthers();
  const [chainId] = useChainId();
  const [isOpen, setIsOpen] = useState(false);
  
  const [executionBundle, setExecutionBundle] = useState<ExecutionBundle | null>(customStrategy? customStrategy.executionBundle: null)
  const [strategy, setStrategy] = useState<Strategy | null>(customStrategy);

  const handleInputChange = (key, value) => {
    setStrategy({
      ...strategy,
      [key]: value
    });
  };
  
  const handleBundleChange = async (value) => {
    // const config = await getIpfsBundleConfig(value);

    // if (config.hasError) {
    //     sendNotification({
    //         type: "error",
    //         transactionName: "Invalid execution bundle",
    //         transaction: {
    //           msg: "Unable to fetch required configuration",
    //         },
    //     });
    // } else {
      setExecutionBundle((prevBundle) => {
        return {
          ...prevBundle,
          hash: value
        }
      })
    // }
  }

  const strategyRegistry = getContractByNetwork(chainId, "StrategyRegistry");

  const strategyRegistryInterface = new utils.Interface(strategyRegistry.abi);
  const strategyRegistryContract = new Contract(
    strategyRegistry.address,
    strategyRegistryInterface,
    library
  );


  const handleNext = async () => {
    const isStrategyAlreadyExists = await strategyRegistryContract.strategies(
      executionBundle.hash
    );
    // Check if the strategy for this execution bundle already exits if yes throw the error
    if (
      isStrategyAlreadyExists.owner !== ZERO_ADDRESS 
    ) {
      sendNotification({
        type: "error",
        transactionName: "Strategy already exists",
        transaction: {
          msg: "The execution bundle you are trying to publish is already published by another user.",
        },
      });
    } else {
      // setStrategyDetails(strategy);
      // setExecutionBundleDetails(executionBundle);
      // moveNext();
      setIsOpen(true);
    }
  }

  const handleCommonInputUpdate = (values) => {
    setStrategy((prevValue) => {
      return {
        ...prevValue,
        ...values
      }
    })
  }

  const handleConfigDataUpdate = (configUpdate: any) => {
    setStrategy((prev) => {
      return {
        ...prev,
        dataType: configUpdate.dataType,
      }
    })

    const executionBundleParams: ExecutionBundleParameters = configUpdate.payloadHash;
    setExecutionBundle((prev) => {
      return {
        ...prev,
        parameters: executionBundleParams
      }
    })

    setStrategyDetails({...strategy, dataType: configUpdate.dataType});
    setExecutionBundleDetails({...executionBundle,parameters: executionBundleParams });
    moveNext();
  }

  useEffect(() => {
    setStrategyDetails(strategy);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategy])

  useEffect(() => {
    setExecutionBundleDetails(executionBundle);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executionBundle])
  
  

  return (
    <div>
      <div className={`mt-4 lg:grid  gap-4 `}>
        <div className="mt-4 lg:px-0 lg:mt-0">
          <Label
            content={"Select App Type"}
            size={"large"}
            casing={"capitalize"}
            color={"white-500"}
          />
          <div className="mt-4">
            <Dropdown
              options={appTypes}
              onSelectHandler={(val) => handleInputChange('beacon', val)}
              // onSelectHandler={(val: string) => updateData(val, "epochLength")}
              placeholder={"Select App Type"}
              type={"normal"}
              hasIcon={false}
              selected={strategy ? strategy.beacon: '' }
              backgroundType="white"
            />
          </div>
        </div>
      </div>

      {CommonAppConfig({
        setData: handleCommonInputUpdate,
        data: strategy,
        appType: "apps",
      })}

      <div className={`mt-4`}>
        <Input
          heading={"App IPFS Hash"}
          val={executionBundle ? executionBundle.hash: ''}
          onChange={(val: string) => handleBundleChange(val)}
          align={"vertical"}
          disabled={true}
          backgroundType="white"
          placeholder={"Upload WASM file to get a IPFS hash"}
          optionalText={
            <UploadFile
              setIpfsHash={(hash) => {
                handleBundleChange(hash);
              }}
            />
          }
        />
      </div>
      <div className="mt-4 lg:mt-6 flex flex-col-reverse md:flex-row justify-center md:justify-between">
        <div className="mt-2 md:mt-0">
          <Button
            content="back"
            type="secondary"
            size="xsmall"
            onClickHandler={handleBack}
            casing={'capitalize'}

          />
          </div>
            <Button
              content="Add Strategy Config"
              size="xsmall"
              type="nsecondary"
              onClickHandler={handleNext}
              disabled={
                !(strategy && strategy?.name && strategy?.beacon)
                || !(executionBundle && executionBundle.hash)
              }
            />
          </div>
      {isOpen && (
        <ConfigModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          data={{
            ...strategy,
            // bundles: bundles?.data?.bundles,
          }}
          secondaryData={selectedPool}
          type={"strategy"}
          setData={(dataObj) => {
            handleConfigDataUpdate(dataObj)
          }}
          executionBundle={executionBundle.hash}
          handleClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default StrategyDetails;

import React, { useEffect, useState } from "react";

import { Title, Steps, Modal } from "components/lib";

import ContractInfo from "./contractInfo";
import JobDetails from "./jobDetails";
import CreateVault from "./modals/createVault";
import { useConfig, useEthers } from "@usedapp/core";
import { gql, useLazyQuery } from "@apollo/client";
import { supportedChains } from "utils";
import { getClient } from "services/graphql";
import { TransparentCard } from "components/TransparentCard";
import { useChainId } from "hooks/useChainId";

const ScheduleJob = "Add Recurring Automation";
const strategyName = "ScheduledJobs";

const GET_VAULT = gql`
  query getVaults($deployer: String!, $beaconName: String!) {
    vaults(where: { deployer: $deployer, beaconName: $beaconName }) {
      beaconName
      deployer
      id
    }
  }
`;

const GET_STRATEGY = gql`
  query strategies($name: String!) {
    strategies(where: { name: $name }) {
      id
      name
    }
  }
`;

const AddStrategy = () => {
  const { account } = useEthers();

const [chainId] = useChainId();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState(null);
  const [steps, setSteps] = useState(0);
  const [vault, setVault] = useState(null);
  const [strategyId, setStrategyId] = useState(null);

  const [jobSteps, setJobSteps] = useState([
    {
      name: "Provide Contract Address & Configure its Function",
      status: "current",
    },
    {
      name: "Add Automation Details, Deposit Gas & Schedule",
      status: "incomplete",
    },
  ]);

  const tconfig = useConfig();
  const supportedChain = supportedChains.find((chain) => {
    if(chainId) {
      return chain.id === chainId;
    } else {
      return chain.id === tconfig.readOnlyChainId
    }
  });
  let graphClient = getClient(supportedChains[0].subgraphURl);
  if (supportedChain) {
    graphClient = getClient(supportedChain.subgraphURl);
  }

  const [gqlCall, setGqlCall] = useState(false);
  const [getVault, vaultData] = useLazyQuery(GET_VAULT, {
    client: graphClient,
  });

  const [getStrategies, startegiesData] = useLazyQuery(GET_STRATEGY, {
    client: graphClient,
  });

  useEffect(() => {
    let notUpdateState = false;
    if (!gqlCall && !notUpdateState && account) {
      getVault({
        variables: {
          deployer: account.toLowerCase(),
          beaconName: "ScheduledJobs"
        },
      });
      getStrategies({ variables: { name: strategyName } });
      setGqlCall(true);
    }
    return () => {
      notUpdateState = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, gqlCall]);

  useEffect(() => {
    if (startegiesData?.data?.strategies && !strategyId) {
      setStrategyId(startegiesData?.data?.strategies[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startegiesData.data]);

  useEffect(() => {
    if (vaultData?.data?.vaults) {
      setIsModalOpen(false);
      if (!vaultData?.data?.vaults[0]) {
        setIsModalOpen(true);
      } else {
        const vaultId = vault?.id;
        const newVaultId = vaultData?.data?.vaults[0]?.id;
        if (vaultId !== newVaultId) {
          setVault(vaultData?.data?.vaults[0]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vaultData.data]);

  useEffect(() => {
    const newSteps = jobSteps.map((step, i) => {
      if (i === steps - 1) {
        step.status = "complete";
      } else if (i === steps) {
        step.status = "current";
      } else if (i > steps) {
        step.status = "incomplete";
      }
      return step;
    });
    setJobSteps(newSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps]);

  const refetchGQL = () => {
    getVault({
      variables: {
        deployer: account.toLowerCase(),
        beaconName: "ScheduledJobs",
      },
    });
  };

  useEffect(() => {
    if (account) {
      refetchGQL();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const closeModal = () => {
    refetchGQL();
    setIsModalOpen(false);
  };
  return (
    <div
    className={`py-4 m-auto lg:h-full w-10/12 lg:w-9/12`}
  >
        <TransparentCard>
        <Title
          content={ScheduleJob}
          size={"xl"}
          color="white-500"
          align={"center"}
        />

        <div className="mx-5 mt-10 bottom-15">
          <Steps type="horizontal" content={jobSteps} />
        </div>
        <div
          className={`mt-4 border border-lynch-rgba shadow-xs`}
          style={{ width: "100%" }}
        />

        {
          <div>
            {steps === 0 && (
              <ContractInfo
                data={data}
                steps={steps}
                setSteps={setSteps}
                setData={setData}
              />
            )}
            {steps === 1 && vault && (
              <JobDetails
                data={data}
                steps={steps}
                setSteps={setSteps}
                setData={setData}
                vault={vault}
              />
            )}
          </div>
        }
      </TransparentCard>

      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          size={"large"}
          content={
            <CreateVault
              closeModal={closeModal}
              account={account}
              strategyId={strategyId}
              refetch={() => refetchGQL()}
              setVault={setVault}
            />
          }
        />
      )}
    </div>
  );
};

export default AddStrategy;

import { createContext } from 'react';

type dexProps = {
    selectedDex: string;
}

export const DexContext = createContext<dexProps>({
    selectedDex: null,
});


import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={90}
      height={97}
      viewBox="0 0 90 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx={130}
        cy={28}
        rx={76}
        ry={48}
        stroke="#000"
        strokeOpacity={0.1}
      />
      <ellipse
        cx={137}
        cy={17.5}
        rx={66}
        ry={41.5}
        stroke="#000"
        strokeOpacity={0.1}
      />
      <ellipse
        cx={113}
        cy={35}
        rx={112}
        ry={61}
        stroke="#000"
        strokeOpacity={0.1}
      />
      <ellipse
        cx={122}
        cy={23}
        rx={97}
        ry={53}
        stroke="#000"
        strokeOpacity={0.1}
      />
    </svg>
  )
}

export default SvgComponent

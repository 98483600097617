import {
  AppDataRender,
  AppDetailsCard,
} from "components/lib";

import {
  getExplorerLink,
} from "utils";


const AssetManagementSection = ({
    ipfsConfig,
    vaultDetails,
    vaultData,
    chainId
}) => {
  const ipfsConfigStrategyData = ipfsConfig?.strategyConfigData;
  return (
    <AppDetailsCard
    title={`Asset Under Management`}
    description={`The details below outline the ${ipfsConfigStrategyData?.name} employed by the app market maker on the pool`}
    content={
      <AppDataRender
        data={[
          {
            type: "link",
            label: "Token 1",
            val: {
              onClick: () =>
                window.open(
                  `${getExplorerLink(chainId)}/address/${
                    vaultDetails["token0"]
                  }`
                ),
              text: vaultDetails["token0Symbol"],
            },
          },
          {
            type: "link",
            label: "Token 2",
            val: {
              onClick: () =>
                window.open(
                  `${getExplorerLink(chainId)}/address/${
                    vaultDetails["token1"]
                  }`
                ),
              text: vaultDetails["token1Symbol"],
            },
          },
          {
            type: "text",
            label: "Token 1 Balance",
            val: parseFloat(vaultDetails["getBalance0String"]) || 0,
          },
          {
            type: "text",
            val: parseFloat(vaultDetails["getBalance1String"]) || 0,
            label: "Token 2 Balance",
          },
          // {
          //   type: "link",
          //   label: "APY",
          //   val: {
          //     onClick: () =>
          //       window.open(
          //         `https://docs.steer.finance/concentrated-liquidity/apy-and-feeapr#what-is-apy-and-how-is-it-calculated`
          //       ),
          //     text: `${Number(
          //       vaultData?.annualPercentageYearlyYield || 0
          //     )}%`,
          //   },
          // },
          // {
          //   type: "link",
          //   label: "Weekly Fee APR",
          //   val: {
          //     onClick: () =>
          //       window.open(
          //         `https://docs.steer.finance/concentrated-liquidity/apy-and-feeapr#what-is-apy-and-how-is-it-calculated`
          //       ),
          //     text: `${Number(vaultData?.weeklyFeeAPR || 0)}%`
          //   },
          // },
        ]}
        size={"small"}
      />
    }
  />
  );
};

export default AssetManagementSection;
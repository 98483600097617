"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertTGPerpToPerpetual = exports.getContractAddressFromPairName = exports.getAllPairNames = exports.getPair = void 0;
const constants_1 = require("../constants");
const lib_1 = require("../lib");
const types_1 = require("../types");
const getPairs = (chainId) => {
    var _a;
    const allPairs = [];
    for (const stableAddr in constants_1.ALL_TOKENS[types_1.AssetType.STABLE]) {
        const stable = constants_1.ALL_TOKENS[types_1.AssetType.STABLE][stableAddr];
        for (const collatAddr in constants_1.ALL_TOKENS[types_1.AssetType.COLLATERAL]) {
            const collateral = constants_1.ALL_TOKENS[types_1.AssetType.COLLATERAL][collatAddr];
            if ((_a = (0, constants_1.registry)(chainId, { stablecoin: stable.symbol, collateral: collateral.symbol })) === null || _a === void 0 ? void 0 : _a.PerpetualManager) {
                allPairs.push({ stable: stable, collateral: collateral });
            }
        }
    }
    return allPairs;
};
const getPair = (stableSymbol, collateralSymbol, chainId) => {
    return getPairs(chainId).filter((pair) => pair.stable.symbol.toUpperCase() === stableSymbol.toUpperCase() &&
        pair.collateral.symbol.toUpperCase() === collateralSymbol.toUpperCase())[0];
};
exports.getPair = getPair;
const getAllPairNames = (chainId) => {
    const pairs = getPairs(chainId);
    return pairs.map((pair) => `${pair.stable.symbol}/${pair.collateral.symbol}`);
};
exports.getAllPairNames = getAllPairNames;
const getContractAddressFromPairName = (pairName, chainId) => {
    var _a;
    const [stableSymbol, collateralSymbol] = pairName.split('/');
    if (!stableSymbol || !collateralSymbol)
        return undefined;
    const contract = (_a = (0, constants_1.registry)(chainId, { stablecoin: stableSymbol, collateral: collateralSymbol })) === null || _a === void 0 ? void 0 : _a.PerpetualManager;
    return contract;
};
exports.getContractAddressFromPairName = getContractAddressFromPairName;
const convertTGPerpToPerpetual = (perp, chainId) => {
    const { stable, collateral } = (0, exports.getPair)(perp.stableName, perp.collatName, chainId);
    return lib_1.Perpetual.fromTgPerp(perp, stable, collateral, chainId);
};
exports.convertTGPerpToPerpetual = convertTGPerpToPerpetual;

import { FunctionComponent } from "react";
import { utils } from "ethers";
import { Row } from "components/lib";

import { getTVL, getTokenURL } from "utils";
import { useCoinPrice } from "hooks/useCoinPrice";
import { useChainId } from "hooks/useChainId";

export interface VaultRowProps {
  vaultInfo: object;
  columns: Array<any>;
  key?: number;
  account?: string;
  chainId?: number;
  strategy: object;
  actionBtns: Array<any>;
}

export const VaultDetailsRow: FunctionComponent<VaultRowProps> = (props) => {

  const vaultInfo = props.vaultInfo;
  const [chainId] = useChainId();
  const {data} = useCoinPrice(vaultInfo['token0'], vaultInfo['token1']);
  const tvl = getTVL(vaultInfo, data);
  const tableData = {
    tokenId: props.strategy["id"],
    id: vaultInfo["vaultAddress"],
    name: props.strategy["name"],
    asset: `${getTokenURL(vaultInfo["token0"], vaultInfo["token0Symbol"], chainId, vaultInfo["beaconName"])} - ${getTokenURL(vaultInfo["token1"], vaultInfo["token1Symbol"],chainId, vaultInfo["beaconName"])}`,
    // apy: vaultInfo["annualPercentageYield"],
    tvl,
    ...vaultInfo
  };

  tableData["getBalance0String"] = utils.formatUnits(
    tableData["token0Balance"],
    tableData["token0Decimals"]
  );
  tableData["getBalance1String"] = utils.formatUnits(
    tableData["token1Balance"],
    tableData["token1Decimals"]
  );



  const colWidth = 100 / props.columns.length;
  const rowData = {
    index: tableData["id"],
    cells: props.columns.map((column) => {
      return {
        column,
        getCellProps: () => {},
        value: tableData[column["accessor"]] || "",
        row: {
          original: tableData,
        },
      };
    }),
    getRowProps: () => [{ id: tableData["id"], rolo: "row" }],
    originalRow: tableData,
  };
  return (
    <div className="w-full">
      <Row
        row={rowData}
        rowExpandable={false}
        id={tableData["id"]}
        colWidth={colWidth}
        actionBtns={props.actionBtns}
        backgroundType="white"
      />
    </div>
  );
};

import UniswapFactory from "./UniswapV3Factory.json"

import data from '@steerprotocol/contracts/deployments/thundercore.json';

const contract = {
  UniswapFactory,
  ...data.contracts,
};

export default contract;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OneInchAggregatorV4__factory = exports.OFTCore__factory = exports.MultiStakerCurveBPAMO__factory = exports.MulticallWithFailure__factory = exports.Multicall__factory = exports.MockLiquidityGaugeV4__factory = exports.MerklGaugeMiddleman__factory = exports.MerkleRootDistributor__factory = exports.MerkleRewardManager__factory = exports.Marketplace__factory = exports.LiquidityGaugeV4__factory = exports.Guardian__factory = exports.Governor__factory = exports.GenericEulerStaker__factory = exports.GenericCompound__factory = exports.GenericAave__factory = exports.GaugeController__factory = exports.GammaPool__factory = exports.FeeManager__factory = exports.FeeDistributor__factory = exports.ERC4626__factory = exports.Erc20__factory = exports.Erc20_bytes32__factory = exports.Distributor__factory = exports.DistributionCreator__factory = exports.CurveLevSwapper3TokensWithBP__factory = exports.CurveLevSwapper3Tokens__factory = exports.CurveLevSwapper2Tokens__factory = exports.Core__factory = exports.ConvexTokenStaker__factory = exports.BPAMOJob__factory = exports.BorrowStaker__factory = exports.BondingCurve__factory = exports.BaseSurplusConverter__factory = exports.BaseStrategy4626__factory = exports.ArrakisPool__factory = exports.ArrakisGauge__factory = exports.AngleRouterV2__factory = exports.AngleRouterPolygon__factory = exports.AngleRouter__factory = exports.AngleRegistry__factory = exports.AngleMiddleman__factory = exports.AngleHelpers__factory = exports.AngleDistributor__factory = exports.AngleBorrowHelpers__factory = exports.ANGLE__factory = exports.AgTokenSideChainMultiBridge__factory = exports.AgToken__factory = exports.AaveConvexStaker__factory = exports.factories = void 0;
exports.Weth__factory = exports.Vesting__factory = exports.VeBoostProxy__factory = exports.VeANGLE__factory = exports.VaultManager__factory = exports.UniswapV3Pool__factory = exports.UniswapV3NFTManager__factory = exports.Treasury__factory = exports.Timelock__factory = exports.SurplusConverterUniV3__factory = exports.SurplusConverterSanTokens__factory = exports.StrategyMarketplace__factory = exports.StrategyLeverageAave__factory = exports.Strategy__factory = exports.StakingRewards__factory = exports.StableMasterFront__factory = exports.SmartWalletWhitelist__factory = exports.SavingsRateL2__factory = exports.SavingsRateIlliquid__factory = exports.SavingsRateFactory__factory = exports.SavingsRate__factory = exports.SanTokenLevSwapper__factory = exports.SanToken__factory = exports.RewardsDistributor__factory = exports.ProxyAdmin__factory = exports.PoolManager__factory = exports.PerpetualManagerFront__factory = exports.Orchestrator__factory = exports.OracleMulti__factory = exports.Oracle__factory = exports.OptimizerAPRStrategy__factory = void 0;
exports.factories = __importStar(require("./factories"));
var AaveConvexStaker__factory_1 = require("./factories/AaveConvexStaker__factory");
Object.defineProperty(exports, "AaveConvexStaker__factory", { enumerable: true, get: function () { return AaveConvexStaker__factory_1.AaveConvexStaker__factory; } });
var AgToken__factory_1 = require("./factories/AgToken__factory");
Object.defineProperty(exports, "AgToken__factory", { enumerable: true, get: function () { return AgToken__factory_1.AgToken__factory; } });
var AgTokenSideChainMultiBridge__factory_1 = require("./factories/AgTokenSideChainMultiBridge__factory");
Object.defineProperty(exports, "AgTokenSideChainMultiBridge__factory", { enumerable: true, get: function () { return AgTokenSideChainMultiBridge__factory_1.AgTokenSideChainMultiBridge__factory; } });
var ANGLE__factory_1 = require("./factories/ANGLE__factory");
Object.defineProperty(exports, "ANGLE__factory", { enumerable: true, get: function () { return ANGLE__factory_1.ANGLE__factory; } });
var AngleBorrowHelpers__factory_1 = require("./factories/AngleBorrowHelpers__factory");
Object.defineProperty(exports, "AngleBorrowHelpers__factory", { enumerable: true, get: function () { return AngleBorrowHelpers__factory_1.AngleBorrowHelpers__factory; } });
var AngleDistributor__factory_1 = require("./factories/AngleDistributor__factory");
Object.defineProperty(exports, "AngleDistributor__factory", { enumerable: true, get: function () { return AngleDistributor__factory_1.AngleDistributor__factory; } });
var AngleHelpers__factory_1 = require("./factories/AngleHelpers__factory");
Object.defineProperty(exports, "AngleHelpers__factory", { enumerable: true, get: function () { return AngleHelpers__factory_1.AngleHelpers__factory; } });
var AngleMiddleman__factory_1 = require("./factories/AngleMiddleman__factory");
Object.defineProperty(exports, "AngleMiddleman__factory", { enumerable: true, get: function () { return AngleMiddleman__factory_1.AngleMiddleman__factory; } });
var AngleRegistry__factory_1 = require("./factories/AngleRegistry__factory");
Object.defineProperty(exports, "AngleRegistry__factory", { enumerable: true, get: function () { return AngleRegistry__factory_1.AngleRegistry__factory; } });
var AngleRouter__factory_1 = require("./factories/AngleRouter__factory");
Object.defineProperty(exports, "AngleRouter__factory", { enumerable: true, get: function () { return AngleRouter__factory_1.AngleRouter__factory; } });
var AngleRouterPolygon__factory_1 = require("./factories/AngleRouterPolygon__factory");
Object.defineProperty(exports, "AngleRouterPolygon__factory", { enumerable: true, get: function () { return AngleRouterPolygon__factory_1.AngleRouterPolygon__factory; } });
var AngleRouterV2__factory_1 = require("./factories/AngleRouterV2__factory");
Object.defineProperty(exports, "AngleRouterV2__factory", { enumerable: true, get: function () { return AngleRouterV2__factory_1.AngleRouterV2__factory; } });
var ArrakisGauge__factory_1 = require("./factories/ArrakisGauge__factory");
Object.defineProperty(exports, "ArrakisGauge__factory", { enumerable: true, get: function () { return ArrakisGauge__factory_1.ArrakisGauge__factory; } });
var ArrakisPool__factory_1 = require("./factories/ArrakisPool__factory");
Object.defineProperty(exports, "ArrakisPool__factory", { enumerable: true, get: function () { return ArrakisPool__factory_1.ArrakisPool__factory; } });
var BaseStrategy4626__factory_1 = require("./factories/BaseStrategy4626__factory");
Object.defineProperty(exports, "BaseStrategy4626__factory", { enumerable: true, get: function () { return BaseStrategy4626__factory_1.BaseStrategy4626__factory; } });
var BaseSurplusConverter__factory_1 = require("./factories/BaseSurplusConverter__factory");
Object.defineProperty(exports, "BaseSurplusConverter__factory", { enumerable: true, get: function () { return BaseSurplusConverter__factory_1.BaseSurplusConverter__factory; } });
var BondingCurve__factory_1 = require("./factories/BondingCurve__factory");
Object.defineProperty(exports, "BondingCurve__factory", { enumerable: true, get: function () { return BondingCurve__factory_1.BondingCurve__factory; } });
var BorrowStaker__factory_1 = require("./factories/BorrowStaker__factory");
Object.defineProperty(exports, "BorrowStaker__factory", { enumerable: true, get: function () { return BorrowStaker__factory_1.BorrowStaker__factory; } });
var BPAMOJob__factory_1 = require("./factories/BPAMOJob__factory");
Object.defineProperty(exports, "BPAMOJob__factory", { enumerable: true, get: function () { return BPAMOJob__factory_1.BPAMOJob__factory; } });
var ConvexTokenStaker__factory_1 = require("./factories/ConvexTokenStaker__factory");
Object.defineProperty(exports, "ConvexTokenStaker__factory", { enumerable: true, get: function () { return ConvexTokenStaker__factory_1.ConvexTokenStaker__factory; } });
var Core__factory_1 = require("./factories/Core__factory");
Object.defineProperty(exports, "Core__factory", { enumerable: true, get: function () { return Core__factory_1.Core__factory; } });
var CurveLevSwapper2Tokens__factory_1 = require("./factories/CurveLevSwapper2Tokens__factory");
Object.defineProperty(exports, "CurveLevSwapper2Tokens__factory", { enumerable: true, get: function () { return CurveLevSwapper2Tokens__factory_1.CurveLevSwapper2Tokens__factory; } });
var CurveLevSwapper3Tokens__factory_1 = require("./factories/CurveLevSwapper3Tokens__factory");
Object.defineProperty(exports, "CurveLevSwapper3Tokens__factory", { enumerable: true, get: function () { return CurveLevSwapper3Tokens__factory_1.CurveLevSwapper3Tokens__factory; } });
var CurveLevSwapper3TokensWithBP__factory_1 = require("./factories/CurveLevSwapper3TokensWithBP__factory");
Object.defineProperty(exports, "CurveLevSwapper3TokensWithBP__factory", { enumerable: true, get: function () { return CurveLevSwapper3TokensWithBP__factory_1.CurveLevSwapper3TokensWithBP__factory; } });
var DistributionCreator__factory_1 = require("./factories/DistributionCreator__factory");
Object.defineProperty(exports, "DistributionCreator__factory", { enumerable: true, get: function () { return DistributionCreator__factory_1.DistributionCreator__factory; } });
var Distributor__factory_1 = require("./factories/Distributor__factory");
Object.defineProperty(exports, "Distributor__factory", { enumerable: true, get: function () { return Distributor__factory_1.Distributor__factory; } });
var Erc20_bytes32__factory_1 = require("./factories/Erc20_bytes32__factory");
Object.defineProperty(exports, "Erc20_bytes32__factory", { enumerable: true, get: function () { return Erc20_bytes32__factory_1.Erc20_bytes32__factory; } });
var Erc20__factory_1 = require("./factories/Erc20__factory");
Object.defineProperty(exports, "Erc20__factory", { enumerable: true, get: function () { return Erc20__factory_1.Erc20__factory; } });
var ERC4626__factory_1 = require("./factories/ERC4626__factory");
Object.defineProperty(exports, "ERC4626__factory", { enumerable: true, get: function () { return ERC4626__factory_1.ERC4626__factory; } });
var FeeDistributor__factory_1 = require("./factories/FeeDistributor__factory");
Object.defineProperty(exports, "FeeDistributor__factory", { enumerable: true, get: function () { return FeeDistributor__factory_1.FeeDistributor__factory; } });
var FeeManager__factory_1 = require("./factories/FeeManager__factory");
Object.defineProperty(exports, "FeeManager__factory", { enumerable: true, get: function () { return FeeManager__factory_1.FeeManager__factory; } });
var GammaPool__factory_1 = require("./factories/GammaPool__factory");
Object.defineProperty(exports, "GammaPool__factory", { enumerable: true, get: function () { return GammaPool__factory_1.GammaPool__factory; } });
var GaugeController__factory_1 = require("./factories/GaugeController__factory");
Object.defineProperty(exports, "GaugeController__factory", { enumerable: true, get: function () { return GaugeController__factory_1.GaugeController__factory; } });
var GenericAave__factory_1 = require("./factories/GenericAave__factory");
Object.defineProperty(exports, "GenericAave__factory", { enumerable: true, get: function () { return GenericAave__factory_1.GenericAave__factory; } });
var GenericCompound__factory_1 = require("./factories/GenericCompound__factory");
Object.defineProperty(exports, "GenericCompound__factory", { enumerable: true, get: function () { return GenericCompound__factory_1.GenericCompound__factory; } });
var GenericEulerStaker__factory_1 = require("./factories/GenericEulerStaker__factory");
Object.defineProperty(exports, "GenericEulerStaker__factory", { enumerable: true, get: function () { return GenericEulerStaker__factory_1.GenericEulerStaker__factory; } });
var Governor__factory_1 = require("./factories/Governor__factory");
Object.defineProperty(exports, "Governor__factory", { enumerable: true, get: function () { return Governor__factory_1.Governor__factory; } });
var Guardian__factory_1 = require("./factories/Guardian__factory");
Object.defineProperty(exports, "Guardian__factory", { enumerable: true, get: function () { return Guardian__factory_1.Guardian__factory; } });
var LiquidityGaugeV4__factory_1 = require("./factories/LiquidityGaugeV4__factory");
Object.defineProperty(exports, "LiquidityGaugeV4__factory", { enumerable: true, get: function () { return LiquidityGaugeV4__factory_1.LiquidityGaugeV4__factory; } });
var Marketplace__factory_1 = require("./factories/Marketplace__factory");
Object.defineProperty(exports, "Marketplace__factory", { enumerable: true, get: function () { return Marketplace__factory_1.Marketplace__factory; } });
var MerkleRewardManager__factory_1 = require("./factories/MerkleRewardManager__factory");
Object.defineProperty(exports, "MerkleRewardManager__factory", { enumerable: true, get: function () { return MerkleRewardManager__factory_1.MerkleRewardManager__factory; } });
var MerkleRootDistributor__factory_1 = require("./factories/MerkleRootDistributor__factory");
Object.defineProperty(exports, "MerkleRootDistributor__factory", { enumerable: true, get: function () { return MerkleRootDistributor__factory_1.MerkleRootDistributor__factory; } });
var MerklGaugeMiddleman__factory_1 = require("./factories/MerklGaugeMiddleman__factory");
Object.defineProperty(exports, "MerklGaugeMiddleman__factory", { enumerable: true, get: function () { return MerklGaugeMiddleman__factory_1.MerklGaugeMiddleman__factory; } });
var MockLiquidityGaugeV4__factory_1 = require("./factories/MockLiquidityGaugeV4__factory");
Object.defineProperty(exports, "MockLiquidityGaugeV4__factory", { enumerable: true, get: function () { return MockLiquidityGaugeV4__factory_1.MockLiquidityGaugeV4__factory; } });
var Multicall__factory_1 = require("./factories/Multicall__factory");
Object.defineProperty(exports, "Multicall__factory", { enumerable: true, get: function () { return Multicall__factory_1.Multicall__factory; } });
var MulticallWithFailure__factory_1 = require("./factories/MulticallWithFailure__factory");
Object.defineProperty(exports, "MulticallWithFailure__factory", { enumerable: true, get: function () { return MulticallWithFailure__factory_1.MulticallWithFailure__factory; } });
var MultiStakerCurveBPAMO__factory_1 = require("./factories/MultiStakerCurveBPAMO__factory");
Object.defineProperty(exports, "MultiStakerCurveBPAMO__factory", { enumerable: true, get: function () { return MultiStakerCurveBPAMO__factory_1.MultiStakerCurveBPAMO__factory; } });
var OFTCore__factory_1 = require("./factories/OFTCore__factory");
Object.defineProperty(exports, "OFTCore__factory", { enumerable: true, get: function () { return OFTCore__factory_1.OFTCore__factory; } });
var OneInchAggregatorV4__factory_1 = require("./factories/OneInchAggregatorV4__factory");
Object.defineProperty(exports, "OneInchAggregatorV4__factory", { enumerable: true, get: function () { return OneInchAggregatorV4__factory_1.OneInchAggregatorV4__factory; } });
var OptimizerAPRStrategy__factory_1 = require("./factories/OptimizerAPRStrategy__factory");
Object.defineProperty(exports, "OptimizerAPRStrategy__factory", { enumerable: true, get: function () { return OptimizerAPRStrategy__factory_1.OptimizerAPRStrategy__factory; } });
var Oracle__factory_1 = require("./factories/Oracle__factory");
Object.defineProperty(exports, "Oracle__factory", { enumerable: true, get: function () { return Oracle__factory_1.Oracle__factory; } });
var OracleMulti__factory_1 = require("./factories/OracleMulti__factory");
Object.defineProperty(exports, "OracleMulti__factory", { enumerable: true, get: function () { return OracleMulti__factory_1.OracleMulti__factory; } });
var Orchestrator__factory_1 = require("./factories/Orchestrator__factory");
Object.defineProperty(exports, "Orchestrator__factory", { enumerable: true, get: function () { return Orchestrator__factory_1.Orchestrator__factory; } });
var PerpetualManagerFront__factory_1 = require("./factories/PerpetualManagerFront__factory");
Object.defineProperty(exports, "PerpetualManagerFront__factory", { enumerable: true, get: function () { return PerpetualManagerFront__factory_1.PerpetualManagerFront__factory; } });
var PoolManager__factory_1 = require("./factories/PoolManager__factory");
Object.defineProperty(exports, "PoolManager__factory", { enumerable: true, get: function () { return PoolManager__factory_1.PoolManager__factory; } });
var ProxyAdmin__factory_1 = require("./factories/ProxyAdmin__factory");
Object.defineProperty(exports, "ProxyAdmin__factory", { enumerable: true, get: function () { return ProxyAdmin__factory_1.ProxyAdmin__factory; } });
var RewardsDistributor__factory_1 = require("./factories/RewardsDistributor__factory");
Object.defineProperty(exports, "RewardsDistributor__factory", { enumerable: true, get: function () { return RewardsDistributor__factory_1.RewardsDistributor__factory; } });
var SanToken__factory_1 = require("./factories/SanToken__factory");
Object.defineProperty(exports, "SanToken__factory", { enumerable: true, get: function () { return SanToken__factory_1.SanToken__factory; } });
var SanTokenLevSwapper__factory_1 = require("./factories/SanTokenLevSwapper__factory");
Object.defineProperty(exports, "SanTokenLevSwapper__factory", { enumerable: true, get: function () { return SanTokenLevSwapper__factory_1.SanTokenLevSwapper__factory; } });
var SavingsRate__factory_1 = require("./factories/SavingsRate__factory");
Object.defineProperty(exports, "SavingsRate__factory", { enumerable: true, get: function () { return SavingsRate__factory_1.SavingsRate__factory; } });
var SavingsRateFactory__factory_1 = require("./factories/SavingsRateFactory__factory");
Object.defineProperty(exports, "SavingsRateFactory__factory", { enumerable: true, get: function () { return SavingsRateFactory__factory_1.SavingsRateFactory__factory; } });
var SavingsRateIlliquid__factory_1 = require("./factories/SavingsRateIlliquid__factory");
Object.defineProperty(exports, "SavingsRateIlliquid__factory", { enumerable: true, get: function () { return SavingsRateIlliquid__factory_1.SavingsRateIlliquid__factory; } });
var SavingsRateL2__factory_1 = require("./factories/SavingsRateL2__factory");
Object.defineProperty(exports, "SavingsRateL2__factory", { enumerable: true, get: function () { return SavingsRateL2__factory_1.SavingsRateL2__factory; } });
var SmartWalletWhitelist__factory_1 = require("./factories/SmartWalletWhitelist__factory");
Object.defineProperty(exports, "SmartWalletWhitelist__factory", { enumerable: true, get: function () { return SmartWalletWhitelist__factory_1.SmartWalletWhitelist__factory; } });
var StableMasterFront__factory_1 = require("./factories/StableMasterFront__factory");
Object.defineProperty(exports, "StableMasterFront__factory", { enumerable: true, get: function () { return StableMasterFront__factory_1.StableMasterFront__factory; } });
var StakingRewards__factory_1 = require("./factories/StakingRewards__factory");
Object.defineProperty(exports, "StakingRewards__factory", { enumerable: true, get: function () { return StakingRewards__factory_1.StakingRewards__factory; } });
var Strategy__factory_1 = require("./factories/Strategy__factory");
Object.defineProperty(exports, "Strategy__factory", { enumerable: true, get: function () { return Strategy__factory_1.Strategy__factory; } });
var StrategyLeverageAave__factory_1 = require("./factories/StrategyLeverageAave__factory");
Object.defineProperty(exports, "StrategyLeverageAave__factory", { enumerable: true, get: function () { return StrategyLeverageAave__factory_1.StrategyLeverageAave__factory; } });
var StrategyMarketplace__factory_1 = require("./factories/StrategyMarketplace__factory");
Object.defineProperty(exports, "StrategyMarketplace__factory", { enumerable: true, get: function () { return StrategyMarketplace__factory_1.StrategyMarketplace__factory; } });
var SurplusConverterSanTokens__factory_1 = require("./factories/SurplusConverterSanTokens__factory");
Object.defineProperty(exports, "SurplusConverterSanTokens__factory", { enumerable: true, get: function () { return SurplusConverterSanTokens__factory_1.SurplusConverterSanTokens__factory; } });
var SurplusConverterUniV3__factory_1 = require("./factories/SurplusConverterUniV3__factory");
Object.defineProperty(exports, "SurplusConverterUniV3__factory", { enumerable: true, get: function () { return SurplusConverterUniV3__factory_1.SurplusConverterUniV3__factory; } });
var Timelock__factory_1 = require("./factories/Timelock__factory");
Object.defineProperty(exports, "Timelock__factory", { enumerable: true, get: function () { return Timelock__factory_1.Timelock__factory; } });
var Treasury__factory_1 = require("./factories/Treasury__factory");
Object.defineProperty(exports, "Treasury__factory", { enumerable: true, get: function () { return Treasury__factory_1.Treasury__factory; } });
var UniswapV3NFTManager__factory_1 = require("./factories/UniswapV3NFTManager__factory");
Object.defineProperty(exports, "UniswapV3NFTManager__factory", { enumerable: true, get: function () { return UniswapV3NFTManager__factory_1.UniswapV3NFTManager__factory; } });
var UniswapV3Pool__factory_1 = require("./factories/UniswapV3Pool__factory");
Object.defineProperty(exports, "UniswapV3Pool__factory", { enumerable: true, get: function () { return UniswapV3Pool__factory_1.UniswapV3Pool__factory; } });
var VaultManager__factory_1 = require("./factories/VaultManager__factory");
Object.defineProperty(exports, "VaultManager__factory", { enumerable: true, get: function () { return VaultManager__factory_1.VaultManager__factory; } });
var VeANGLE__factory_1 = require("./factories/VeANGLE__factory");
Object.defineProperty(exports, "VeANGLE__factory", { enumerable: true, get: function () { return VeANGLE__factory_1.VeANGLE__factory; } });
var VeBoostProxy__factory_1 = require("./factories/VeBoostProxy__factory");
Object.defineProperty(exports, "VeBoostProxy__factory", { enumerable: true, get: function () { return VeBoostProxy__factory_1.VeBoostProxy__factory; } });
var Vesting__factory_1 = require("./factories/Vesting__factory");
Object.defineProperty(exports, "Vesting__factory", { enumerable: true, get: function () { return Vesting__factory_1.Vesting__factory; } });
var Weth__factory_1 = require("./factories/Weth__factory");
Object.defineProperty(exports, "Weth__factory", { enumerable: true, get: function () { return Weth__factory_1.Weth__factory; } });

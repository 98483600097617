import axios from "axios";

import { useQuery } from "@tanstack/react-query";


const fetchMerklData = async (chainId: number) => {
    // const url = `https://ro81h8hq6b.execute-api.us-east-1.amazonaws.com/merkl=${chainId}`;
    const url = `https://api.angle.money/v1/merkl?chainId=${chainId}`;
    const response = await axios.get(url);
    const data = response.data;

    if (!data || !data["pools"]) return [];

    const pools = Object.values(data["pools"]).map(d => {
        const reward = d?.["distributionData"].find(x => x.isLive);
        const allRewards = d?.["distributionData"].filter(x => x.isLive).reduce((unique, item) => {
            return unique.some(i => i.token === item.token) ? unique : [...unique, item];
        }, []);

        const steerAprs = Object.keys(d["aprs"])
            .filter((key) => key.toLowerCase().indexOf('steer') > -1)
            .map((key) => {
                return {
                    vault: key.replaceAll('Steer ', '').trim(),
                    apr: d["aprs"]?.[key] || 0
                };
            })
            .reduce((aprObj, apr) => {
                aprObj[apr.vault] = apr.apr;
                return aprObj; 
            }, {});

        return {
            incentiveAprs: steerAprs,
            address: d?.["pool"] ,
            poolFee: d?.["poolFee"],
            incentive: {
                rewardToken: {
                    address: reward?.token,
                    symbol: reward?.tokenSymbol,
                    ...reward
                },
                allRewardTokens: allRewards.map(d => ({
                        address: d?.token,
                        symbol: d?.tokenSymbol,
                        ...d
                }))
            }
        };
    });

    return pools;
}

export const useMerkl = (chainId: number) => {
    const { data: merklRewardPools, isLoading: loadingMerklPools, isError } = useQuery(
        ["merklData", chainId],
        () => fetchMerklData(chainId)
    );

    if (isError) {
        console.log("Error fetching merkl-pools data");
    }

    return { merklRewardPools, loadingMerklPools };
}

import { gql, useLazyQuery } from "@apollo/client";
import { AppDataRender, AppDetailsCard } from "components/lib";
import {  utils } from "ethers";
import copy from 'copy-to-clipboard';

import { useEffect, useState } from "react";
import { getMinifedAddress } from "utils";

const GET_BUNDLES = gql`
  query getBundles($ids: [String!]) {
    bundles(where: { id_in: $ids }) {
      host
      source
      id
    }
  }
`;

const DataConnectorSection = ({ graphClient, ipfsConfig, chainId }) => {
  const [dataConnectors, setDataConnectors] = useState([]);

  const [getBundleData, bundleData] = useLazyQuery(GET_BUNDLES, {
    client: graphClient,
  });
  const [gqlBundleCall, setGqlBundleCall] = useState(false);

  useEffect(() => {
    if (
      ipfsConfig &&
      ipfsConfig.dataConnectorsData &&
      ipfsConfig.dataConnectorsData.length > 0 &&
      !gqlBundleCall
    ) {
      const ids = ipfsConfig.dataConnectorsData.map((d) => d.bundleHash);
      getBundleData({ variables: { ids: ids } });
      setGqlBundleCall(true);
    }
  }, [ipfsConfig, gqlBundleCall, getBundleData]);

  useEffect(() => {
    if (
      bundleData.networkStatus === 7 &&
      bundleData.data &&
      bundleData.data.bundles.length > 0 &&
      dataConnectors.length === 0
    ) {
      setDataConnectors(
        bundleData.data.bundles.map((d) => {
          const metaData = ipfsConfig.dataConnectorsData.find(
            (a) => a.bundleHash === d.id
          );
          return {
            ...d,
            ...metaData
          };
        })
      );
    }
  }, [bundleData, dataConnectors?.length, ipfsConfig?.dataConnectorsData]);
  if (dataConnectors.length > 0) {
    return (
      <div className="my-4">
        <AppDetailsCard
          title="Data Connector Configurations"
          description={
            "Data Connector Configurations play's a critical role for fetching, securing & tranforming the data, for the app execution."
          }
          content={
            <div className="my-4">
              {dataConnectors.map((d) => (
                <div className="my-4">
                  <div className="text-base text-black-500">{d.source}</div>
                    <AppDataRender
                      data={
                        d.configData
                          ? Object.keys(d.configData).filter(val => val !== 'isChainRead').map((k) => {
                              let isAddress = true;
                              try {
                                utils.getAddress(d.configData[k]);
                              } catch(e) {
                                isAddress = false;
                              }
                              return {
                                type: isAddress ? "link" : "text",
                                val: isAddress ? {
                                  onClick: () =>
                                  copy(d.configData[k]),
                                  text: getMinifedAddress(d.configData[k] || ""),
                                } : d.configData[k],
                                label: k,
                              };
                            })
                          : []
                      }
                      size={"small"}
                      _class="mt-1 lg:mt-1"
                    />
                </div>
              ))}
            </div>
          }
        />
      </div>
    );
  } else {
    return null;
  }
};

export default DataConnectorSection;

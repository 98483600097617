import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={134}
      height={89}
      viewBox="0 0 134 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1494_2182)" stroke="#CFD0D0">
        <path d="M41.2792 -32.5852L-10.7208 2.41479" />
        <path d="M41.2792 -25.5852L-10.7208 9.41479" />
        <path d="M41.2792 -18.5852L-10.7208 16.4148" />
        <path d="M41.2792 -11.5852L-10.7208 23.4148" />
        <path d="M41.2792 -4.58521L-10.7208 30.4148" />
        <path d="M41.2792 2.41479L-10.7208 37.4148" />
        <path d="M41.2792 9.41479L-10.7208 44.4148" />
        <path d="M41.2792 16.4148L-10.7208 51.4148" />
        <path d="M41.2792 23.4148L-10.7208 58.4148" />
        <path d="M41.2792 30.4148L-10.7208 65.4148" />
        <path d="M41.2792 37.4148L-10.7208 72.4148" />
        <path d="M42.2792 44.4148L-9.72081 79.4148" />
        <path d="M44.2792 51.4148L-7.72081 86.4148" />
      </g>
      <path
        d="M.5-14.5h51V30c0 14.083-11.417 25.5-25.5 25.5S.5 44.083.5 30v-44.5z"
        stroke="#CFD0D0"
      />
      <g clipPath="url(#clip1_1494_2182)" stroke="#CFD0D0">
        <path d="M144.279 -32.5852L92.2792 2.41479" />
        <path d="M144.279 -25.5852L92.2792 9.41479" />
        <path d="M144.279 -18.5852L92.2792 16.4148" />
        <path d="M144.279 -11.5852L92.2792 23.4148" />
        <path d="M144.279 -4.58521L92.2792 30.4148" />
        <path d="M144.279 2.41479L92.2792 37.4148" />
        <path d="M144.279 9.41479L92.2792 44.4148" />
        <path d="M144.279 16.4148L92.2792 51.4148" />
        <path d="M144.279 23.4148L92.2792 58.4148" />
        <path d="M144.279 30.4148L92.2792 65.4148" />
        <path d="M144.279 37.4148L92.2792 72.4148" />
        <path d="M145.279 44.4148L93.2792 79.4148" />
        <path d="M147.279 51.4148L95.2792 86.4148" />
        <path d="M148.279 59.4148L96.2792 94.4148" />
        <path d="M149.279 67.4148L97.2792 102.415" />
        <path d="M150.279 75.4148L98.2792 110.415" />
      </g>
      <path
        d="M103.5-14.5h51V63c0 14.083-11.417 25.5-25.5 25.5S103.5 77.083 103.5 63v-77.5z"
        stroke="#CFD0D0"
      />
      <defs>
        <clipPath id="clip0_1494_2182">
          <path
            d="M0-15h52v45c0 14.36-11.64 26-26 26S0 44.36 0 30v-45z"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="clip1_1494_2182">
          <path
            d="M103-15h52v78c0 14.36-11.641 26-26 26s-26-11.64-26-26v-78z"
            fill="#fff"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgComponent

import { FunctionComponent } from "react";
import { AppCard } from "components/lib";
import { getTokenURL } from "utils";
import { useHistory } from "react-router-dom";
import millify from 'millify';

export interface MockAppCardProps {
  vaultInfo: object;
  key?: number;
}

const MockAppCard: FunctionComponent<MockAppCardProps> = (props) => {
  const history = useHistory();
  const { vaultInfo } = props;
  const token0URL = getTokenURL(vaultInfo['pool']['token0']["id"], vaultInfo['pool']['token0']["symbol"], vaultInfo["chainId"], vaultInfo["beaconName"]); 
  const token1URL = getTokenURL(vaultInfo['pool']['token1']["id"], vaultInfo['pool']['token1']["symbol"], vaultInfo["chainId"], vaultInfo["beaconName"]); 
  const appData = {
    data: {
      isDeprecated: false,
      volumeUSD : {
        type: "text",
        val: `${millify(parseInt(vaultInfo['pool']['volumeUSD']))} USD`,
        label: `24hr Volume`,
      },
      fee: {
        type: "text",
        val: `${parseInt(vaultInfo['pool']["feeTier"]) / 10000}%`,
        label: `Fee Tier`
      },
      tvl: {
        type: "text",
        val: `${millify(parseInt(vaultInfo['pool']['totalValueLockedUSD']))} USD`,
        label: "TVL",
      },
      asset: {
        type: "asset",
        val: [token0URL, token1URL],
        label: `${vaultInfo['pool']["token0"]["symbol"]} - ${vaultInfo['pool']["token1"]["symbol"]}`,
      },
    },
    badgeData: vaultInfo['pool']['dex'],
    hideBadge: true,
    cardType: "app",
    onClick: () => vaultInfo["onClick"] ? vaultInfo["onClick"]() :  history.push({
      pathname: "/publish-smart-pool",
      state: {
        data: {
          pool: {...vaultInfo['pool']}
        },
      },
    }),
    incentivized: !!vaultInfo['pool']["incentivized"],
  };

  //@ts-ignore
  return <AppCard {...appData} />;
};

export default MockAppCard;

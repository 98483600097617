"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const addresses = {
    ANGLE: '0x3b9e3b5c616A1A038fDc190758Bbe9BAB6C7A857',
    agEUR: {
        bridges: {
            Anyswap: '0x02a2b736F9150d36C0919F3aCEE8BA2A92FBBb40',
        },
    },
    ExternalStakings: [],
};
exports.default = addresses;

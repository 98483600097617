import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Table,
  Modal,
  Label,
  TableLoader,
} from "components/lib";
import SharedButtons from "../common/sharedButton";
import CreateModal from "pages/dataMarketplace/modals/uploadConnector";
import Config from "../common/configModal";
import { gql, useLazyQuery } from "@apollo/client";
import { useConfig } from "@usedapp/core";
import { getMinifedAddress, supportedChains } from "utils";
import { getClient } from "services/graphql";
import { DeprecatedBundlesContext } from "context/DeprecatedBundles";
import { useChainId } from "hooks/useChainId";

const GET_BUNDLES = gql`
  query getBundles($ids: [String!]) {
    bundles(where:{id_not_in: $ids}) {
      creator
      host
      id
      bundle
      source
      output
      createdAt
      infoHash
    }
  }
`;

const columns = [
  {
    Header: "Name",
    accessor: "source",
    filterable: true,
    sortable: true,
    type: "text",
    searchable: true,
    casing: "capitalize",
  },
  {
    Header: "Host",
    accessor: "host",
    filterable: true,
    sortable: true,
    type: "text",
    searchable: true,
    casing: "capitalize",
  },
  {
    Header: "Output",
    accessor: "output",
    filterable: true,
    sortable: true,
    type: "text",
    searchable: true,
    casing: "capitalize",
  },
  {
    Header: "Creator",
    accessor: "creator",
    filterable: true,
    sortable: true,
    type: "tooltip",
    searchable: true,
    casing: "capitalize",
  },
];

const DataConnector = ({
  steps,
  setSteps,
  setData,
  data,
}: {
  steps: number;
  setSteps: (step: number) => void;
  setData: (data: any) => void;
  data: any;
}) => {
  const [chainId] = useChainId();
  const [dataConnectors, setDataConnectors] = useState([]);
  const [showDnDTable, setShowDnDTable] = useState(false);
  const [dataConnectorsWithPayload, setDataConnectorsWithPayload] = useState(
    data.dataConnectors || []
  );
  const [unSelectedRow, setUnSelectedRow] = useState(null);
  const [sources, setSources] = useState([]);
  const [showDataConnectorForm, setShowDataConnectorForm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { deprecatedBundles } = useContext(DeprecatedBundlesContext);
  let deprecatedBundlesIds = (deprecatedBundles || []).filter((bundle) => bundle["type"] === "DATA_CONNECTOR").map(d => d["id"]);
  deprecatedBundlesIds = deprecatedBundlesIds.length === 0 ? [""] : deprecatedBundlesIds;

  let graphClient = getClient(supportedChains[0].subgraphURl);
  const tconfig = useConfig();
  const supportedChain = supportedChains.find((chain) => {
    if(chainId) {
      return chain.id === chainId;
    } else {
      return chain.id === tconfig.readOnlyChainId
    }
  });
  
  if (supportedChain) {
    graphClient = getClient(supportedChain.subgraphURl);
  }
  const [getBundles, bundles] = useLazyQuery(GET_BUNDLES, {
    client: graphClient,
  });

  const _setNewBundleData = (newData) => {
    setSources(newData.map((item) => {
      const payloadAdded = data?.dataConnectors?.find(
        (d) => d.bundle === item.id
      );
      return {
        ...item,
        creator: {
          optionalText: item.creator,
          label: getMinifedAddress(item.creator),
          value: getMinifedAddress(item.creator),
        },
        payloadHash: payloadAdded ? payloadAdded.payloadHash : null,
      };
    }));
  };

  useEffect(() => {
    const id = setInterval(() => {
      getBundles({variables: {ids: deprecatedBundlesIds}});
    }, 5000);
    return () => {
      clearInterval(id);
    };
  }, [getBundles, deprecatedBundlesIds]);

  const getBundleData = (newData) => {
    let newBundles = newData.bundles || [];
    if (
      data?.strategyData?.dataType &&
      data?.strategyData?.dataType?.length > 0
    ) {
      let filteredDataConnectors = [];
      let otherFilterDataConnector = []
      newData.bundles.map(
        (b) => {
          if(data?.strategyData?.dataType.indexOf(b.output.toLowerCase()) !== -1) {
            filteredDataConnectors.push(b);
          } else {
            otherFilterDataConnector.push(b);
          }
          return null;
        }
      );
      newBundles = filteredDataConnectors.concat(otherFilterDataConnector);
    }
    return newBundles;
  };

  useEffect(() => {
    if (bundles?.data?.bundles?.length > 0) {
      const bundleData = getBundleData(bundles.data);
      if (!bundles.previousData && isLoading) {
        _setNewBundleData(bundleData);
        setIsLoading(false);
      }

      if (
        sources.length > 0 &&
        sources.length !== bundleData.length &&
        !isLoading
      ) {
        _setNewBundleData(bundleData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundles]);

  useEffect(() => {
    // checked
    if (dataConnectors.length > dataConnectorsWithPayload.length) {
      setIsOpen(true);
      setUnSelectedRow(null);
    }
    // //unchecked
    if (dataConnectors.length < dataConnectorsWithPayload.length) {
      setDataConnectorsWithPayload(
        dataConnectors.map((item) => {
          const payload = dataConnectorsWithPayload.find(
            (dataConnector) =>
              dataConnector.source === item.source &&
              dataConnector.host === item.host &&
              dataConnector.bundle === item.bundle
          );
          return payload;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataConnectors]);

  const setSelectedRowsData = (rows) => {
    setDataConnectors(rows.map((row) => row.original));
  };

  const setDataWithPayload = (data) => {
    const tempData = [...dataConnectorsWithPayload];
    tempData.push(data);
    setDataConnectorsWithPayload(tempData);
  };

  const unSelectDataConnector = (data) => {
    setUnSelectedRow(data.bundle);
  };
  return (
    <div>
      <div className="flex flex-col mt-8">
        {!showDnDTable && (
          <div className="flex justify-end mb-4">
            <Button
              content={"Upload Connector"}
              onClickHandler={() => setShowDataConnectorForm(true)}
              type={"primary"}
              casing={"capitalize"}
              size={"small"}
              disabled={isLoading}
            />
          </div>
        )}
        {!isLoading && sources.length > 0 && !showDnDTable && (
          <Table
            data={sources}
            //@ts-ignore
            columns={columns}
            showSearchBar={true}
            rowExpandable={false}
            isRowSelectable={true}
            showPagination={false}
            height={"300px"}
            setSelectedRows={setSelectedRowsData}
            selectedRows={dataConnectors}
            unSelectedRow={unSelectedRow}
            rowIdentifierKey={"bundle"}
          />
        )}
        {isLoading && (
          <TableLoader
            // @ts-ignore
            columns={columns}
            rowCount={4}
          />
        )}
        {!isLoading && sources.length === 0 && (
          <div className="flex flex-col items-center justify-center">
            <div className="text-3xl">🤔</div>
            <div className="my-4">
              <Label
                content={
                  "No Data Connector Found. Add the first Data Connector."
                }
                align={`center`}
                size={`large`}
                color={`white-500`}
              />
            </div>
          </div>
        )}
      </div>
      {showDataConnectorForm && (
        <Modal
          isOpen={showDataConnectorForm}
          size={"large"}
          content={
            <CreateModal
              setSources={setSources}
              sources={sources}
              setShowModal={setShowDataConnectorForm}
            />
          }
        />
      )}
      {/* {showDnDTable && (
        <div className="flex flex-col">
          <Label
            content={
              "Arrange the order of selected Data connectors by drag and dropping first column icon in row"
            }
            align={`center`}
            size={`large`}
            color={`white-500`}
          />
          <div className="flex flex-col mt-8">
            <DnDTable
              data={dataConnectorsWithPayload}
              //@ts-ignore
              columns={columns}
              setData={setDataConnectorsWithPayload}
              headerClass={"text-white-500"}
            />
          </div>
        </div>
      )} */}
      <SharedButtons
        steps={steps}
        setSteps={setSteps}
        previousStep={() => {
          if (showDnDTable) {
            setShowDnDTable(false);
          } else {
            setSteps(steps - 1);
          }
        }}
        nextStep={() => {
          setData({
            ...data,
            dataConnectors: dataConnectorsWithPayload,
          });
          if (!showDnDTable && dataConnectorsWithPayload.length > 1) {
            setShowDnDTable(true);
          } else {
            setSteps(steps + 1);
          }
        }}
      />
      {isOpen && (
        <Config
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setSteps={setSteps}
          steps={steps}
          data={dataConnectors[dataConnectors.length - 1]}
          type={"dataConnector"}
          secondaryData={data?.strategyData?.vault?.payload}
          setData={setDataWithPayload}
          unSelectDataConnector={unSelectDataConnector}
        />
      )}
    </div>
  );
};

export default DataConnector;

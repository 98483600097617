import React, { useEffect, useState, useRef } from "react";

import {
  JsonForm,
  Modal,
  Icon,
} from "components/lib";

import { getIpfsBundleConfig, toTitleCase } from "utils";
import SharedButtons from "./sharedButton";

export const getUniqTypes = (data) => {
  return data.reduce((acc, d) => {
    const alreadyAdded = acc.find(({label}) => d.label === label);
    if(!alreadyAdded) {
      acc.push(d);
    } 
    return acc;
  }, []);
}
const Config = ({
  data,
  type,
  steps,
  setSteps,
  setData,
  isOpen,
  setIsOpen,
  unSelectDataConnector,
  secondaryData,
}: {
  data: any;
  type: "strategy" | "dataConnector";
  steps: number;
  setSteps: (step: number) => void;
  setData: (data: any) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  unSelectDataConnector?: (dataConnector: any) => void;
  secondaryData?: any;
}) => {
  const [formData, setFormData] = React.useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(null);
  let submitFormRef = useRef(null);
  const title =
    type === "strategy"
      ? `Configure App ${toTitleCase(data.name)} Bundle`
      : `Configure Data Connector for ${toTitleCase(
          data?.source
        )} Source & ${toTitleCase(data?.host)} Host`;
  const hash = type === "strategy" ? data.executionBundle : data.bundle;
  const [configJSON, setConfigJSON] = useState({});

  useEffect(() => {
    (async () => {
      const ipfsData = await getIpfsBundleConfig(hash);
      if (ipfsData.hasError) {
        setHasError(ipfsData.msg);
      } else {
        const oldProperties = ipfsData.data?.properties;
        const obj = {};
        const propertiesValueArray = secondaryData
          ? Object.keys(secondaryData)
          : [];
        if(oldProperties) {
          Object.keys(oldProperties).map((d) => {
            obj[d] = oldProperties[d];
            if (propertiesValueArray.indexOf(d) !== -1) {
              obj[d] = {
                ...obj[d],
                default: secondaryData[d],
              };
            }
            return obj;
          });
        }
        //cloning
        if(data?.payload) {
          Object.keys(obj).map(key => {
            obj[key] = {
              ...obj[key],
              default: data?.payload[key]
            };
            return null;
          });
        }
        
        if(data.payloadHash) {
          Object.keys(obj).map(key => {
            obj[key] = {
              ...obj[key],
              default: data.payloadHash[key]
            };
            return null;
          });
        }
        
        const ipfsNewData = {
          ...ipfsData.data,
          properties: obj,
        };
        setConfigJSON(ipfsNewData);
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
	const hasProperties = Object.keys(configJSON["properties"] || {}).length > 0; 

  return (
    <Modal
      isOpen={isOpen}
      size={"large"}
      content={
        <div className="flex flex-col p-4 mb-2" key={hash}>
          <div className="flex mb-4 text-base leading-6 text-white-500">
            {title}
          </div>
          <div
            className={`mb-4 border border-lynch-rgba shadow-xs`}
            style={{ width: "100%" }}
          />
					<a className="flex justify-center mb-2 capitalize text-white-500 hover:underline" 
          rel="noreferrer" 
          href={ type === "strategy" ? "https://docs.steer.finance/steer-apps/writing-an-app#setup-app-params" 
            : "https://docs.steer.finance/data-connectors/interface-api#configuration-form"} 
            target={'_blank'}>{"Learn about configuration paramters?"}</a>

          {hasError && (
            <div className="flex justify-center text-lg leading-6 text-red-500">
              {hasError}
            </div>
          )}
          {isLoading && (
            <div className="flex justify-center text-lg text-white-500">
              <Icon spin={true} name="faSpinner" />
            </div>
          )}
          {!isLoading && (
            <JsonForm
              schema={configJSON}
              ref={submitFormRef}
              //@ts-ignore
              setFormData={setFormData}
              formData={formData}
              onSubmit={() => {
                setIsOpen(false);
                if (type === "strategy") {
                  setSteps(steps + 1);
                }
                setData({
                  ...data,
                  payloadHash: formData,
                  dataType: (configJSON['parameters'] || configJSON['expectedDataTypes'] || ["OHLC"]).map(t => t.toLowerCase())
                });
              }}
              type='app'
            />
          )}
          {!isLoading && !hasProperties && (
						<div className="flex justify-center text-lg leading-6 text-white-500">
						{"No Config Parameters"}
					</div>
					)}
          {/* {!isLoading && bundleOptions.length > 0 && (
            <div className="my-4">
              <div
                className={`border border-lynch-rgba shadow-xs`}
                style={{ width: "100%" }}
              />
              <div className="my-4">
              <Label
                content={"Choose App Data Type"}
                size={"large"}
                casing={"capitalize"}
                color={"white-500"}
              />
              </div>
              {range(dropdownCount).map((key, i) => (
              <div className="mt-4">
                {renderDropdown(
                  bundleOptions,
                  selectedDataConnectorTypes[key],
                  _addNewDataType
                )}
                {selectedDataConnectorTypes.length < bundleOptions.length - 1 &&
                  i === dropdownCount - 1 && (
                    <div className="flex justify-end mt-3">
                      <Button
                        content={"Add More Data Type"}
                        onClickHandler={() =>
                          setDropdownCount(dropdownCount + 1)
                        }
                        iconAlignment={"left"}
                        type={"secondary"}
                        size={"small"}
                        casing={"capitalize"}
                        iconType={"normal"}
                        icon={"faPlus"}
                      />
                    </div>
                  )}
              </div>
            ))}
            </div>
          )}
         */}
          <SharedButtons
            steps={steps}
            setSteps={setSteps}
            previousStep={() => {
              setIsOpen(false);
              if (type === "dataConnector") {
                unSelectDataConnector(data);
              }
            }}
            nextStep={() => {
              submitFormRef.current.click();
            }}
            isDisabled={isLoading || hasError}
          />
        </div>
      }
    />
  );
};

export default Config;

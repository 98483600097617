"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateUsersSLP = exports.harvest = void 0;
const constants_1 = require("../constants");
const utils_1 = require("../utils");
function harvest(chainId, collateral, stablecoin, signer, options = {}) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const stable = (0, utils_1.parseStable)(stablecoin);
        const collat = (0, utils_1.parseCollat)(collateral);
        const address = (_c = (_b = (_a = (0, constants_1.registry)(chainId, stable.symbol, collat.symbol)) === null || _a === void 0 ? void 0 : _a.Strategies) === null || _b === void 0 ? void 0 : _b.GenericOptimisedLender) === null || _c === void 0 ? void 0 : _c.Contract;
        if (!address)
            throw new Error("Can't find contract's address");
        return constants_1.Strategy__factory.connect(address, signer)['harvest()'](options);
    });
}
exports.harvest = harvest;
function updateUsersSLP(chainId, collateral, stablecoin, signer, options = {}) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const stable = (0, utils_1.parseStable)(stablecoin);
        const collat = (0, utils_1.parseCollat)(collateral);
        const address = (_a = (0, constants_1.registry)(chainId, stable.symbol, collat.symbol)) === null || _a === void 0 ? void 0 : _a.FeeManager;
        if (!address)
            throw new Error("Can't find contract's address");
        return constants_1.FeeManager__factory.connect(address, signer).updateUsersSLP(options);
    });
}
exports.updateUsersSLP = updateUsersSLP;

import { useQuery } from '@tanstack/react-query'
import { Token } from '@uniswap/sdk-core'
import { FeeAmount } from '@uniswap/v3-sdk'
import { useEthers } from '@usedapp/core'
import { Protocol } from 'utils/types'
import { getConcentratedLiquidityPool } from './getConcentratedLiquidityPool'

interface UseConcentratedLiquidityPool {
  token0: Token | undefined
  token1: Token | undefined
  chainId: number
  feeAmount: FeeAmount | undefined
  enabled?: boolean,
  protocol: Protocol
}

export const useConcentratedLiquidityPool = ({
  token0,
  token1,
  chainId,
  feeAmount,
  enabled = true,
  protocol
}: UseConcentratedLiquidityPool) => {

  const {library} = useEthers()
  return useQuery({
    queryKey: ['useConcentratedLiquidityPool', { chainId, token0, token1, feeAmount }],
    queryFn: async () => {
      //@ts-ignore
      return await getConcentratedLiquidityPool({
        chainId,
        protocol,
        token0,
        token1,
        feeAmount,
        provider: library
      })
    },
    refetchInterval: 10000,
    enabled: Boolean(enabled && feeAmount && chainId && token0 && token1),
  })
}

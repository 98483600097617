import { FunctionComponent, useState, useEffect } from "react";
import {
	TableLoader,
	Table,
	Label,
} from "components/lib";
import { getClient } from "services/graphql";
import { getMinifedAddress, supportedChains, getExplorerLink } from "utils";
import { useConfig, useEthers } from "@usedapp/core";
import { gql, useLazyQuery } from "@apollo/client";
import CardLoader from "components/CardLoader";
import { TransparentCard } from "components/TransparentCard";
import TitleWithDocLink from "components/TitleWithDocLink";
import { useChainId } from "hooks/useChainId";

const columns = [
	{ Header: "Rank", accessor: "rank", sortable: true },
	{ Header: "Address", accessor: "address", sortable: true, type: "tooltip" },
	{ Header: "Xp Points", accessor: "xp", sortable: true, type: "text" },
	{ Header: "Action", accessor: "", type: "button", sortable: false },
];

const GET_LEADERS = gql`
  query getLeaders {
    leaderBoards{
        address
        numStaticJobs
        numApps
        timestamp
      }
		}
`;

const pageSize = 10;

const Dashboard: FunctionComponent = () => {
	const [leaders, setLeaders] = useState([]);
	const [pageCount, setPageCount] = useState(100);
	const [pageIndex, setPageIndex] = useState(0);
	const [gqlCall, setGqlCall] = useState(false);
	const { account } = useEthers();

const [chainId] = useChainId();

	let graphClient = getClient(supportedChains[0].subgraphURl);
	const tconfig = useConfig();
	const supportedChain = supportedChains.find((chain) => {
	  if(account) {
		return chain.id === chainId;
	  } else {
		return chain.id === tconfig.readOnlyChainId
	  }
	});

	if (supportedChain) {
		graphClient = getClient(supportedChain.subgraphURl);
	}
	const [getLeaders, leadersData] = useLazyQuery(GET_LEADERS, {
		client: graphClient,
	});
	useEffect(() => {
		let notUpdateState = false;
		if (!notUpdateState) {
			if (!gqlCall) {
				getLeaders();
				setGqlCall(true);
			}
		}
		return () => {
			notUpdateState = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account, gqlCall]);

	const calXp = (item) => {
		return (Number(item.numApps) * 100 + Number(item.numStaticJobs));
	}

	useEffect(() => {
		if (leadersData?.data?.leaderBoards && leaders.length === 0) {
			const leadersRec = leadersData?.data?.leaderBoards.map(data => ({
				...data,
				xp: calXp(data),
				address: {
					optionalText: data.address,
					label: getMinifedAddress(data.address),
					value: getMinifedAddress(data.address),
				}
			})).sort((a, b) => b.xp - a.xp);
			const pageBreakupData = leadersRec
				.reduce((resultArray, item, index) => {
					const chunkIndex = Math.floor(index / pageSize);
					if (!resultArray[chunkIndex]) {
						resultArray[chunkIndex] = []; // start a new chunk
					}
						resultArray[chunkIndex].push({
							...item,
							rank: index + 1
						});
					return resultArray;
				}, []);

			const pageCount = Math.ceil(leadersRec.length / pageSize);
			if (leaders.length === 0) {
				setPageCount(pageCount);
				setLeaders(pageBreakupData);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leadersData.data]);

	useEffect(() => {
		setLeaders([]);
	}, [chainId]);

	useEffect(() => {
		if (account) {
			getLeaders();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account]);

	const actionBtns = [
		{
			type: "primary" as "primary" | "secondary" | "tertiary",
			text: "View Details",
			onClickHandler: (data) => {
				let address = data?.address?.optionalText;
				if(!address) {
					address = data?.data?.address?.optionalText;
				}
				window.open(
					`${getExplorerLink(chainId)}/address/${
						address
					}`
				)
			},
			casing: "capitalize" as "capitalize" | "lowercase",
		},
	];

	const fetchRowsData = (pageIndex, pageSize) => {
		setPageIndex(pageIndex);
		return leaders.length > pageIndex ? getJobPageDataObjs : [];
	};

	const getJobPageDataObjs = () => {
		return leaders.length > 0 ? leaders[pageIndex] : [];
	};


	return (
		<>
			 <div
      className={`py-4 m-auto lg:h-full w-10/12 lg:w-9/12`}
    >
    <TransparentCard>
				<TitleWithDocLink
					title={"Testnet LeaderBoard"}
					link="https://docs.steer.finance/guides/testnet/"
					linkText="Learn about testnet incentives?"
					/>
					<div className="my-8">
					{window.innerWidth > 1000 ? (
						!leadersData.loading && leaders.length > 0 ?
							<Table
								data={getJobPageDataObjs()}
								hasCustomRow={false}
								// @ts-ignore
								columns={columns}
								showSearchBar={true}
								actionBtns={actionBtns}
								showPagination={true}
								rowExpandable={false}
								pageSize={pageSize}
								pageIndex={pageIndex}
								pageCount={pageCount}
								backgroundType="white"
								fetchRowsData={(pageIndex, pageSize) =>
									fetchRowsData(pageIndex, pageSize)
								}
							/>
							: (leadersData.loading && <TableLoader
								// @ts-ignore
								columns={columns}
								rowCount={4}
							/>)
					) : !leadersData.loading && leaders.length > 0 ?
						<div className="flex flex-col mb-40">
							{leaders.reduce((acc, jobs) => acc.concat(jobs), []).map((data, i) => {
								return (
									<div className={`${i === 0 ? "" : "mt-4"}`}>
										{/* <ExpandedCard
											actionBtns={actionBtns}
											expandedData={null}
											data={data}
											type="leaderboard"
											cell={{
												row: {
													original: {
														data
													}
												}
											}}
										/> */}
									</div>
								)
							})}
						</div>
						: (leadersData.loading && <CardLoader
							rowCount={4}
						/>)}

					{!leadersData.loading && leaders.length === 0 && (
						<div className="flex flex-col items-center justify-center">
							<div className="text-3xl">📝</div>
							<div className="my-4">
								<Label
									content={
										"No Leaders Found. Use our testnet to become a leader and get a chance to win a mystery box. More you use the testnet bigger the mystery box will be."
									}
									align={`center`}
									size={`large`}
									color={`white-500`}
								/>
							</div>
						</div>
					)}
					</div>
					
				</TransparentCard>
			</div>
		</>
	);
};

export default Dashboard;

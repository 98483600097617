import { useCall } from "@usedapp/core";
import { Contract, utils } from "ethers";

export const useSlot0 = (pool) => {
    const slot0Abi = [
      {
        inputs: [],
        name: 'slot0',
        outputs: [
          { internalType: 'uint160', name: 'sqrtPriceX96', type: 'uint160' },
          { internalType: 'int24', name: 'tick', type: 'int24' },
          { internalType: 'uint16', name: 'observationIndex', type: 'uint16' },
          { internalType: 'uint16', name: 'observationCardinality', type: 'uint16' },
          { internalType: 'uint16', name: 'observationCardinalityNext', type: 'uint16' },
          { internalType: 'uint8', name: 'feeProtocol', type: 'uint8' },
          { internalType: 'bool', name: 'unlocked', type: 'bool' },
        ],
        stateMutability: 'view',
        type: 'function',
      },
    ] as const;

    const slot0Interface = new utils.Interface(slot0Abi);
    const slot0 = useCall(pool ? {
        contract: new Contract(
          pool,
          slot0Interface,
        ),
        method: "slot0",
        args: [],
      }: false);
    if(slot0) {
      return {
        tick: slot0?.value?.tick,
      }
    }
    return null;
  }

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ethers_1 = require("ethers");
const utils_1 = require("ethers/lib/utils");
const bignumber_1 = require("../../utils/bignumber");
const interestRate = ethers_1.BigNumber.from('158153934393112649');
const stablesParameters = {
    EUR: {
        currencySymbol: '€',
        flashloan: {
            maxBorrowable: bignumber_1.parseAmount.ether('1000000'),
            flashLoanFee: bignumber_1.parseAmount.gwei('0'),
        },
        bridges: [
            {
                name: 'Anyswap',
                token: '0x6feFd97F328342a8A840546A55FDcfEe7542F9A8',
                params: {
                    fees: bignumber_1.parseAmount.gwei('0.002'),
                    limit: (0, utils_1.parseEther)('1000000'),
                    hourlyLimit: (0, utils_1.parseEther)('50000'),
                },
            },
        ],
        vaultManagers: [
            {
                collateral: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
                symbol: 'USDC-EUR',
                oracle: 'USDC_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('100000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.825'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.05'),
                    borrowFee: bignumber_1.parseAmount.gwei('0'),
                    repayFee: bignumber_1.parseAmount.gwei('0'),
                    interestRate: interestRate,
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.06'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('1'),
                },
            },
            {
                collateral: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
                symbol: 'wAVAX-EUR',
                oracle: 'AVAX_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('100000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.65'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.1'),
                    borrowFee: bignumber_1.parseAmount.gwei('0'),
                    repayFee: bignumber_1.parseAmount.gwei('0'),
                    interestRate: interestRate,
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.1'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('0.5'),
                },
            },
        ],
    },
};
exports.default = {
    stablesParameters: stablesParameters,
};

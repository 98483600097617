"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ethers_1 = require("ethers");
const utils_1 = require("ethers/lib/utils");
const bignumber_1 = require("../../utils/bignumber");
const interestRate = ethers_1.BigNumber.from('158153934393112649');
const stablesParameters = {
    EUR: {
        currencySymbol: '€',
        flashloan: {
            maxBorrowable: bignumber_1.parseAmount.ether('1000000'),
            flashLoanFee: bignumber_1.parseAmount.gwei('0'),
        },
        bridges: [
            {
                name: 'Synapse',
                token: '0xa0554607e477cdC9d0EE2A6b087F4b2DC2815C22',
                params: {
                    fees: bignumber_1.parseAmount.gwei('0.002'),
                    limit: (0, utils_1.parseEther)('0'),
                    hourlyLimit: (0, utils_1.parseEther)('50000'),
                },
            },
        ],
        vaultManagers: [
            {
                collateral: '0x4200000000000000000000000000000000000006',
                symbol: 'wETH-EUR',
                oracle: 'ETH_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('150000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.75'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.05'),
                    borrowFee: bignumber_1.parseAmount.gwei('0'),
                    repayFee: bignumber_1.parseAmount.gwei('0'),
                    interestRate: interestRate,
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.1'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('1.5'),
                },
            },
            {
                collateral: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
                symbol: 'USDC-EUR',
                oracle: 'USDC_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('4000000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.88'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.05'),
                    borrowFee: bignumber_1.parseAmount.gwei('0'),
                    repayFee: bignumber_1.parseAmount.gwei('0'),
                    interestRate: interestRate,
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.04'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('1.5'),
                },
            },
            {
                collateral: '0x4200000000000000000000000000000000000042',
                symbol: 'OP-EUR',
                oracle: 'OP_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('200000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.65'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.05'),
                    borrowFee: bignumber_1.parseAmount.gwei('0'),
                    repayFee: bignumber_1.parseAmount.gwei('0'),
                    interestRate: interestRate,
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.11'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('0.5'),
                },
            },
        ],
    },
};
exports.default = {
    stablesParameters: stablesParameters,
};

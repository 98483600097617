import { Timeframe } from 'components/lib';
import { ResetContext } from 'pages/apps/publish-pool';
import { useContext, useEffect, useState } from 'react';
import { Strategy } from 'utils/types';
import { configureSettings } from '../../helper';

type DataConnectorConfigProps = {
  selectedStrategy: Strategy,
  onSubmit: (any) => void,
}

const DataConnectorConfigForm = ({ selectedStrategy, onSubmit }: DataConnectorConfigProps) => {
  const [lookback, setLookback] = useState(0);
  const [candleWidth, setCandlewidth] = useState('');

  const { reset, handleChildReset } = useContext(ResetContext);
  const {configuredLookback, configuredCandleWidth} = configureSettings(selectedStrategy)

  useEffect(() => {
    if (selectedStrategy) {
      setLookback(configuredLookback);
      setCandlewidth(configuredCandleWidth);
    }
  }, [configuredCandleWidth, configuredLookback, selectedStrategy]);

  useEffect(() => {
    if (reset) {
      setLookback(configuredLookback);
      setCandlewidth(configuredCandleWidth);
      handleChildReset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    if (candleWidth && candleWidth.length > 0)
    onSubmit({ lookback, candleWidth });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookback, candleWidth])
    
  return (
    <div className="">
      <div className='text-md font-bold mb-2 text-white-500'>
        Data Connector Configuration
      </div>
      <form className="space-y-2">
        <div className="mt-1">
          <Timeframe
            onChange={(val) => setLookback(parseInt(val.toString()))}
            options={["Hours","Days", "Weeks", "Months"]}
            defaultOption="7"
            heading='Lookback'
            defaultSelection={
              {
                seconds: lookback
              }
            }
            tooltipContent='The time period used to create the candles'
          />
        </div>
        <label className="block">
          <Timeframe
            onChange={(val) => {
              setCandlewidth(`${val}s`)
            }}
            options={["Minute", "Hour", "Day"]}
            defaultOption="30"
            heading='Candle Data Width'
            tooltipContent='The time frame of the candlestick (e.g., one minute, one hour, one day)'
            defaultSelection={
              {
                period: candleWidth
              }
            }
          />
        </label>
      </form>
    </div>
  );
};

export default DataConnectorConfigForm
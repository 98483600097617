import { faCheckCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useContractFunction, useEthers } from "@usedapp/core";
import { sendNotification } from "components/Container";
import { Contract, utils } from "ethers";
import { useChainId } from "hooks/useChainId";
import { useEffect, useState } from "react";
import {
  getContractByNetwork,
  getWhitelistVaults,
  logEventInSentry,
  supportedChains,
} from "utils";
import {
  DataConnectorUpdateConfig,
  ExecutionBundleParameters,
  Pool,
  Strategy,
  StrategyDeploymentConfig
} from "utils/types";
import {
  VaultConfig,
  getBeaconName,
  getContractName,
  getHash,
  getPayloadHash,
  vaultConfig,
  checkIfStrategyAlreadyExists,
} from "../helper";
import ModalHeader from "components/ModalHeader";
import StrategyDeploymentConfigForm from './intervalFundGas';
import { useVaultData } from "queries/useVault";
import { useHistory } from "react-router-dom";

type StrategyDeployModalProps = {
  closeModal: () => void;
  selectedStrategy: Strategy;
  selectedPool: Pool;
  executionConfig: ExecutionBundleParameters;
  dataConnectorConfigs: DataConnectorUpdateConfig[];
  _vaultConfig: VaultConfig,
  mode: string
};

const StrategyDeployModal = ({
  closeModal,
  selectedStrategy,
  selectedPool,
  executionConfig,
  dataConnectorConfigs,
  _vaultConfig,
  mode
}: StrategyDeployModalProps) => {
  const [chainId] = useChainId();
  const { account, library } = useEthers();
  const [creationTimestamp, setCreationTimestamp] = useState(null);
  const history = useHistory();
  
  const supportedChain = supportedChains.find((chain) => {
    if(chainId) {
      return chain.id === chainId;
    }
    return false;
  });

 
  const [strategyDeploymentConfig, setStrategyDeploymentConfig] =
  useState<StrategyDeploymentConfig>({
    fundGas: "0",
    epochLength: selectedStrategy?.strategyInterval ? selectedStrategy?.strategyInterval.toString() : '3600',
    epochStart: Date.now()/1000 + 3600, // Forces a first tend one hour from when the strategy is deployed
  });

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [hideForm, setHideForm] = useState(false);

  const {data: vaultData, isLoading: isVaultLoading} = useVaultData(
    account,
    creationTimestamp,
    isSuccess,
    supportedChain.subgraphURl,
  )

  const orchestratorAddress = getContractByNetwork(
    chainId,
    "Orchestrator"
  )?.address;
  const contractName = getContractName(
    selectedPool.dex,
    selectedStrategy.beacon
  );

  const steerPeriphery = getContractByNetwork(chainId, "SteerPeriphery");
  const steerPeripheryInterface = new utils.Interface(steerPeriphery.abi);

  const steerPeripheryContract = new Contract(
    steerPeriphery.address,
    steerPeripheryInterface
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state, send: createVaultAndStrategy } = useContractFunction(
    steerPeripheryContract,
    "createVaultAndStrategy",
    { transactionName: `Publish App ` }
  );

  const { state: cloneState, send: createVaultAndDepositGas } =
  useContractFunction(steerPeripheryContract, "createVaultAndDepositGas", {
    transactionName: `Remix App`,
  });

  useEffect(() => {
    if(state?.status === "Exception") {
      setErrorMessage(state.errorMessage);
      setIsSuccess(false);
      setIsError(true);
    }

    if(cloneState?.status === "Exception") {
      setErrorMessage(cloneState.errorMessage);
      setIsSuccess(false);
      setIsError(true);
    }
  }, [state, cloneState])
  
  const vaultConfigData: VaultConfig = _vaultConfig ? _vaultConfig : vaultConfig(selectedPool);
  // deposit strategy here
  const getParamsAndHash = async () => {
    const hash = await getPayloadHash(
      dataConnectorConfigs,
      executionConfig,
      selectedStrategy.name,
      selectedStrategy,
      strategyDeploymentConfig.epochStart,
      selectedPool,
      strategyDeploymentConfig.epochLength
    );

    let encodedParams = utils.defaultAbiCoder.encode(
      ["address", "address", "uint24", "int24", "uint32"],
      [
        selectedPool.token0.id,
        selectedPool.token1.id,
        vaultConfigData.fee,
        vaultConfigData.maxTickChange || 250,
        vaultConfigData.twapInterval || 45,
      ]
    );

    if (getWhitelistVaults.indexOf(contractName) !== -1) {
      encodedParams = utils.defaultAbiCoder.encode(
        ["address", "bytes"],
        [account, encodedParams]
      );
    }
    return {
      hash,
      encodedParams,
    };
  };

  const deployStrategy = () => {
    (async () => {
      const { encodedParams, hash } = await getParamsAndHash();
      const executionBundleHash = getHash(selectedStrategy, (Number(selectedPool.feeTier)/10000).toFixed(2));
      const strategyId = await checkIfStrategyAlreadyExists(executionBundleHash, chainId, library);

      try {

        if (strategyId) {
          const params = {
            tokenId: strategyId,
            params: encodedParams,
            beaconName:  getBeaconName(contractName),
            vaultManager: orchestratorAddress,
            payloadIpfs: hash
          }
          console.log("Deployment params:", params)
          logEventInSentry('clone-app', {
            ...params,
            tokens: [selectedPool.token0.id, selectedPool.token1.id],
            date: new Date()
          });

          await createVaultAndDepositGas(
            params,
            {
              value: utils.parseEther(strategyDeploymentConfig.fundGas ? String(strategyDeploymentConfig.fundGas) : "0"),
            }
          );
        } else if(mode === 'Expert Mode') {

            console.log('Gas Parameters : ', {
              strategyCreator: account,
              name: selectedStrategy.name,
              execBundle: executionBundleHash,
              maxGasCost: utils.parseUnits("900", "gwei"),
              maxGasPerAction: "3000000",
              params: encodedParams,
              beaconName: getBeaconName(contractName),
              vaultManager: orchestratorAddress,
              payloadIpfs: hash,
            }, 'value', {
              value: utils.parseEther(strategyDeploymentConfig.fundGas ? String(strategyDeploymentConfig.fundGas) : "0"),
            });
            
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const createVaultAndStrategyParams = {
              strategyCreator: account,
              name: selectedStrategy.name,
              execBundle: executionBundleHash,
              maxGasCost: utils.parseUnits("900", "gwei"),
              maxGasPerAction: "3000000",
              params: encodedParams,
              beaconName: getBeaconName(contractName),
              vaultManager: orchestratorAddress,
              payloadIpfs: hash,
            };
            
            logEventInSentry("publish-app", {
              strategyCreator: account,
              name: selectedStrategy.name,
              execBundle: executionBundleHash,
              maxGasCost: utils.parseUnits("900", "gwei"),
              maxGasPerAction: "3000000",
              params: encodedParams,
              beaconName: getBeaconName(contractName),
              vaultManager: orchestratorAddress,
              payloadIpfs: hash,
              tokens: [selectedPool.token0.id, selectedPool.token1.id],
              date: new Date(),
            });
            await createVaultAndStrategy(createVaultAndStrategyParams, {
              value: utils.parseEther(strategyDeploymentConfig.fundGas ? String(strategyDeploymentConfig.fundGas) : "0"),
            });
        } else {
          throw Error('Error in strategy creation contact team');
        }
        setIsSuccess(true);
        setCreationTimestamp((Date.now()/1000)-50)
      } catch (e) {
        console.log(e)
        setIsError(true);
        sendNotification({
          type: "error",
          transactionName: `Publish App failed`,
          transaction: {
            ...e,
            msg: `Publish App failed`,
          },
        });
      }
    })();
  };

  const handleDeposit = async () => {
    setHideForm(true);
    await deployStrategy();
  }

  const handleStrategyDeploymentConfig = (key, value) => {
    setStrategyDeploymentConfig((prevConfig) => {
      return {
        ...prevConfig,
        [key]: value,
      };
    });
  };
  useEffect(() => {
    if (isSuccess) {
      // setTimeout(() => {
      //   closeModal();
      // }, 3000);
      if(!isVaultLoading && vaultData) {
        console.log("🚀 ~ file: strategyDeployModal.tsx:271 ~ useEffect ~ vaultData:", vaultData)
        history.push({
          pathname: `/app/${vaultData["strategyToken"]["id"]}/vault/${vaultData["id"].toLowerCase()}?engine=${vaultData["beaconName"]}&chainId=${chainId}`,
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, vaultData]);
  
  return (
    <>
     <ModalHeader 
      title="Deploy Strategy"
      closeModal={() => closeModal()}
      description=""
     />
     {!hideForm && <div className="flex">
      <StrategyDeploymentConfigForm 
        handleNext={handleDeposit}
        handleStrategyDeploymentConfig={handleStrategyDeploymentConfig}
        selectedPool={selectedPool}
        selectedStrategy={selectedStrategy}
        strategyDeploymentConfig={strategyDeploymentConfig}
      />
     </div>}
      {hideForm && (
        <div className={`flex justify-center items-center my-4 ${isSuccess && !isError ? 'text-green-500': isError ? "text-red-500": "text-white-500"}`}>
          <FontAwesomeIcon icon={
            isSuccess && !isError ? faCheckCircle : isError ?  faTimesCircle : faSpinner
            } spin={isSuccess && !isError ? false : isError ?  false : true} size="2x" />
          <span className="ml-2">
            {isSuccess && !isError ? "Publish App Successful!" : isError ? "Publish App Failed" : "Deploying Strategy..."}
            </span>
        </div>
      )}
      {isError && (
        <div className="flex flex-col items-center justify-center text-red-500">
          <div className="flex mt-2">{errorMessage}</div>
        </div>
      )}
    </>
  );
};

export default StrategyDeployModal;

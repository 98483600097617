import React from "react";
import {Icon} from './Icon';
export const DragHandle = (props) => {
  return (
    <div 
    className={`inline-block  mr-2 ${props.isDragging ? 'cursor-grabbing': 'cursor-grab'}`}
    {...props}>
      <Icon name={'faBars'} size="sm"/>
    </div>
  );
};
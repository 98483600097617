import React, { FunctionComponent, useState } from "react";
import { Title } from "../Title";

export interface AppDetailsCardProp {
  title: string;
  description?: string;
  content?: any;
}

export const AppDetailsCard: FunctionComponent<AppDetailsCardProp> = (props) => {
  const { title, description, content } = props;
  const [moreDesc, setMoreDesc] = useState(false);
  return (
    <div
      data-testid="AppDetailsCard-component"
      className="relative flex flex-col w-full px-6 py-4 lg:py-8 lg:px-20 bg-white-500"
      style={{
        borderRadius: "12px",
      }}
    >
      <Title
        content={title}
        casing={'capitalize'}
        size={'base'}
        color={'black-500'}
        />
        <div className="mt-4 text-sm leading-5 text-gray-500">
          {description.slice(0, 220)}
          {description.length > 220 && !moreDesc && <>... <span className="cursor-pointer hover:underline text-shamrock-500" onClick={() => setMoreDesc
          (true)}>show more</span></>}
          {moreDesc && <>{description.slice(220, description.length)} <span className="cursor-pointer hover:underline text-shamrock-500" onClick={() => setMoreDesc
          (false)}>show less</span></>}
        </div>
        {content}
    </div>
  );
};

AppDetailsCard.defaultProps = {
};

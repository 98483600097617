"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeCollateralRatio = exports.computeSlippageFee = exports.computeSlippage = exports.computeBonusMalusBurn = exports.computeBonusMalusMint = void 0;
const ethers_1 = require("ethers");
const bignumber_1 = require("../utils/bignumber");
function computeBonusMalusMint(chainID, stableSymbol, collateralSymbol, collatRatio, _xBonusMalusMint = [ethers_1.BigNumber.from(0)], _yBonusMalusMint = [ethers_1.BigNumber.from(0)]) {
    const xBonusMalusMint = _xBonusMalusMint.map((e) => ethers_1.BigNumber.from(e));
    const yBonusMalusMint = _yBonusMalusMint.map((e) => ethers_1.BigNumber.from(e));
    collatRatio = ethers_1.BigNumber.from(collatRatio);
    const bonusMalusMint = (0, bignumber_1.piecewiseFunction)(collatRatio, xBonusMalusMint, yBonusMalusMint);
    return bonusMalusMint;
}
exports.computeBonusMalusMint = computeBonusMalusMint;
function computeBonusMalusBurn(chainID, stableSymbol, collateralSymbol, collatRatio, _xBonusMalusBurn = [ethers_1.BigNumber.from(0)], _yBonusMalusBurn = [ethers_1.BigNumber.from(0)]) {
    const xBonusMalusBurn = _xBonusMalusBurn.map((e) => ethers_1.BigNumber.from(e));
    const yBonusMalusBurn = _yBonusMalusBurn.map((e) => ethers_1.BigNumber.from(e));
    collatRatio = ethers_1.BigNumber.from(collatRatio);
    const bonusMalusBurn = (0, bignumber_1.piecewiseFunction)(collatRatio, xBonusMalusBurn, yBonusMalusBurn);
    return bonusMalusBurn;
}
exports.computeBonusMalusBurn = computeBonusMalusBurn;
function computeSlippage(chainID, stableSymbol, collateralSymbol, agTokensMinted, amountInProtocol, inDecimals, rates, _xSlippage = [ethers_1.BigNumber.from(0)], _ySlippage = [ethers_1.BigNumber.from(0)], base = (0, bignumber_1.gwei)(1)) {
    const xSlippage = _xSlippage.map((e) => ethers_1.BigNumber.from(e));
    const ySlippage = _ySlippage.map((e) => ethers_1.BigNumber.from(e));
    const collatRatio = computeCollateralRatio(agTokensMinted, amountInProtocol, inDecimals, rates, base);
    const slippage = (0, bignumber_1.piecewiseFunction)(collatRatio, xSlippage, ySlippage);
    return slippage;
}
exports.computeSlippage = computeSlippage;
function computeSlippageFee(chainID, stableSymbol, collateralSymbol, agTokensMinted, amountInProtocol, inDecimals, rates, _xSlippageFee = [ethers_1.BigNumber.from(0)], _ySlippageFee = [ethers_1.BigNumber.from(0)], base = (0, bignumber_1.gwei)(1)) {
    const xSlippageFee = _xSlippageFee.map((e) => ethers_1.BigNumber.from(e));
    const ySlippageFee = _ySlippageFee.map((e) => ethers_1.BigNumber.from(e));
    const collatRatio = computeCollateralRatio(agTokensMinted, amountInProtocol, inDecimals, rates, base);
    const slippageFee = (0, bignumber_1.piecewiseFunction)(collatRatio, xSlippageFee, ySlippageFee);
    return slippageFee;
}
exports.computeSlippageFee = computeSlippageFee;
function computeCollateralRatio(agTokensMinted, _amountInProtocol, inDecimals, rates, base = (0, bignumber_1.gwei)(1)) {
    agTokensMinted = ethers_1.BigNumber.from(agTokensMinted);
    const amountInProtocol = _amountInProtocol.map((e) => ethers_1.BigNumber.from(e));
    const collatBase = inDecimals.map((e) => (0, bignumber_1.multByPow)(1, e));
    rates = rates.map((e) => ethers_1.BigNumber.from(e));
    base = ethers_1.BigNumber.from(base);
    const ZERO = ethers_1.BigNumber.from(0);
    let collatRatio = ZERO;
    if (agTokensMinted.lte(ZERO)) {
        collatRatio = ethers_1.BigNumber.from('10000').mul(base);
        return collatRatio;
    }
    let val = ZERO;
    for (let i = 0; i < amountInProtocol.length; i++) {
        val = val.add(amountInProtocol[i].mul(rates[i]).div(collatBase[i]));
    }
    collatRatio = val.mul(base).div(agTokensMinted);
    return collatRatio;
}
exports.computeCollateralRatio = computeCollateralRatio;

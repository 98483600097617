import { useState } from "react";
import { useEthers } from "@usedapp/core";
import { useChainId } from "./useChainId";

interface VerifyEIP712SignatureProps {}

interface VerifyEIP712SignatureResponse {
  success?: boolean;
  recoveredAddress?: string;
  error?: string;
}

function getPayload(chainId: string) {
  return JSON.stringify({
    domain: {
      chainId,
      name: "Steer Protocol",
      version: "1",
      verifyingContract: "0x0000000000000000000000000000000000000000",
    },
    message: {
      contents: `I hereby affirm and declare that, to the best of my understanding, I am abiding by all relevant international and local laws, regulations, and sanctions, including those enforced by the Office of Foreign Assets Control (OFAC), that pertain to the usage, acquisition, possession, and sale of digital tokens. This includes, but is not limited to, anti-money laundering (AML) and counter-terrorism financing (CTF) laws, tax reporting requirements, economic sanctions legislation, the U.S. Securities Act of 1933, Financial Action Task Force (FATF) recommendations, the European Union's (EU) Fifth Anti-Money Laundering Directive (5AMLD), Know Your Customer (KYC) rules, United Nations (UN) Security Council resolutions, International Organization of Securities Commissions (IOSCO) guidelines, the EU's proposed Markets in Crypto-Assets (MiCA) regulation, Bank for International Settlements (BIS) guidelines, International Monetary Fund (IMF) suggestions, data protection and privacy laws (e.g., GDPR, CCPA), consumer protection laws, and any other pertinent financial, securities, and commodities regulations. I confirm that I am not a resident, citizen, green card holder, or subject to any restrictions imposed by a regulatory body in any territory where the usage, acquisition, possession, or sale of digital tokens is forbidden or limited by international or local laws. I further assure that I am not engaging in any activities related to digital tokens that would infringe upon any applicable laws and regulations in my jurisdiction or any jurisdiction with which I have a connection, such as citizenship or residency. By signing this statement, I assume complete responsibility for ensuring my adherence to all relevant legal and regulatory requirements, including any reporting, registration, or licensing obligations that may be applicable to my involvement with digital tokens.`,
    },
    primaryType: "Attestation",
    types: {
      EIP712Domain: [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
      ],
      Attestation: [{ name: "contents", type: "string" }],
    },
  });
}

export const useConsent  = () => {
  const { account, library } = useEthers();
  const [chainId] = useChainId();

  const [verifyEIP712SignatureResult, setVerifyEIP712SignatureResult] =
    useState<VerifyEIP712SignatureResponse>();
  const [verifyEIP712SignatureError, setVerifyEIP712SignatureError] =
    useState<Error>();
  const [hasSigned, setHasSigned] = useState(localStorage.getItem('consent-verified') === 'true');
  const [showConsentModal, setShowConsentModal] = useState(false);

  const verifyEIP712Signature = async (
    variables: VerifyEIP712SignatureProps
  ) => {
    try {
      // @ts-ignore
      const signature = await library.send("eth_signTypedData_v4", [
        account,
        getPayload(chainId.toString()),
      ]);

      const response = await fetch('https://ro81h8hq6b.execute-api.us-east-1.amazonaws.com/verify', {
        method: 'POST',
        body: JSON.stringify({
          address: account,
          signature,
          chainId: chainId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to verify signature');
      }

      const result = await response.json();
      setVerifyEIP712SignatureResult(result);
      localStorage.setItem('consent-verified', 'true');
      setShowConsentModal(false);
    } catch (error) {
      setVerifyEIP712SignatureError(error);
    }
  };

  const getSignature = async () => {
    if(localStorage.getItem('consent-verified') !== 'true') {
      if(account) {
        try {
          const response = await fetch(`https://ro81h8hq6b.execute-api.us-east-1.amazonaws.com/verify?address=${account}`);
    
          if (!response.ok) {
            throw new Error('Failed to verify signature');
          }
          const result = await response.json();
          localStorage.setItem('consent-verified', 'true');
          setHasSigned(result.exists);
        } catch (error) {
          console.log({error});
          setHasSigned(false);
        }
      }
    } else {
      setHasSigned(true);
    }
  }

  return {
    verifyEIP712Signature,
    verifyEIP712SignatureResult,
    verifyEIP712SignatureError,
    getSignature,
    hasSigned,
    showConsentModal,
    setShowConsentModal
  };
}


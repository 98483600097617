"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AngleRouter__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
    },
    {
        inputs: [],
        name: "AlreadyAdded",
        type: "error",
    },
    {
        inputs: [],
        name: "IncompatibleLengths",
        type: "error",
    },
    {
        inputs: [],
        name: "InvalidAddress",
        type: "error",
    },
    {
        inputs: [],
        name: "InvalidCall",
        type: "error",
    },
    {
        inputs: [],
        name: "InvalidConditions",
        type: "error",
    },
    {
        inputs: [],
        name: "InvalidReturnMessage",
        type: "error",
    },
    {
        inputs: [],
        name: "InvalidToken",
        type: "error",
    },
    {
        inputs: [],
        name: "NotApprovedOrOwner",
        type: "error",
    },
    {
        inputs: [],
        name: "NotGovernorOrGuardian",
        type: "error",
    },
    {
        inputs: [],
        name: "TooSmallAmountOut",
        type: "error",
    },
    {
        inputs: [],
        name: "ZeroAddress",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "admin",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "setGovernor",
                type: "bool",
            },
        ],
        name: "AdminChanged",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "stableMaster",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "poolManager",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "liquidityGauge",
                type: "address",
            },
        ],
        name: "CollateralToggled",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "tokenAddress",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "Recovered",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "sanToken",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newLiquidityGauge",
                type: "address",
            },
        ],
        name: "SanTokenLiquidityGaugeUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "stableMaster",
                type: "address",
            },
        ],
        name: "StablecoinAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "stableMaster",
                type: "address",
            },
        ],
        name: "StablecoinRemoved",
        type: "event",
    },
    {
        inputs: [],
        name: "ANGLE",
        outputs: [
            {
                internalType: "contract IERC20",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "BASE_PARAMS",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "STETH",
        outputs: [
            {
                internalType: "contract IStETH",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "VEANGLE",
        outputs: [
            {
                internalType: "contract IVeANGLE",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "WETH9",
        outputs: [
            {
                internalType: "contract IWETH9",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "WSTETH",
        outputs: [
            {
                internalType: "contract IWStETH",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IERC20[]",
                name: "stablecoins",
                type: "address[]",
            },
            {
                internalType: "contract IPoolManager[]",
                name: "poolManagers",
                type: "address[]",
            },
            {
                internalType: "contract ILiquidityGauge[]",
                name: "liquidityGauges",
                type: "address[]",
            },
        ],
        name: "addPairs",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IERC20",
                name: "stablecoin",
                type: "address",
            },
            {
                internalType: "contract IStableMasterFront",
                name: "stableMaster",
                type: "address",
            },
        ],
        name: "addStableMaster",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "dest",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "minCollatAmount",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "stablecoin",
                type: "address",
            },
            {
                internalType: "address",
                name: "collateral",
                type: "address",
            },
        ],
        name: "burn",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IERC20[]",
                name: "tokens",
                type: "address[]",
            },
            {
                internalType: "address[]",
                name: "spenders",
                type: "address[]",
            },
            {
                internalType: "uint256[]",
                name: "amounts",
                type: "uint256[]",
            },
        ],
        name: "changeAllowance",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "address[]",
                name: "liquidityGauges",
                type: "address[]",
            },
            {
                internalType: "uint256[]",
                name: "perpetualIDs",
                type: "uint256[]",
            },
            {
                internalType: "address[]",
                name: "perpetualManagers",
                type: "address[]",
            },
        ],
        name: "claimRewards",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "gaugeUser",
                type: "address",
            },
            {
                internalType: "address[]",
                name: "liquidityGauges",
                type: "address[]",
            },
            {
                internalType: "uint256[]",
                name: "perpetualIDs",
                type: "uint256[]",
            },
            {
                internalType: "address[]",
                name: "stablecoins",
                type: "address[]",
            },
            {
                internalType: "address[]",
                name: "collaterals",
                type: "address[]",
            },
        ],
        name: "claimRewards",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "governor",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "guardian",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IStableMasterFront",
                name: "",
                type: "address",
            },
            {
                internalType: "contract IERC20",
                name: "",
                type: "address",
            },
        ],
        name: "mapPoolManagers",
        outputs: [
            {
                internalType: "contract IPoolManager",
                name: "poolManager",
                type: "address",
            },
            {
                internalType: "contract IPerpetualManagerFrontWithClaim",
                name: "perpetualManager",
                type: "address",
            },
            {
                internalType: "contract ISanToken",
                name: "sanToken",
                type: "address",
            },
            {
                internalType: "contract ILiquidityGauge",
                name: "gauge",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IERC20",
                name: "",
                type: "address",
            },
        ],
        name: "mapStableMasters",
        outputs: [
            {
                internalType: "contract IStableMasterFront",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "user",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "minStableAmount",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "stablecoin",
                type: "address",
            },
            {
                internalType: "address",
                name: "collateral",
                type: "address",
            },
        ],
        name: "mint",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "token",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "value",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "deadline",
                        type: "uint256",
                    },
                    {
                        internalType: "uint8",
                        name: "v",
                        type: "uint8",
                    },
                    {
                        internalType: "bytes32",
                        name: "r",
                        type: "bytes32",
                    },
                    {
                        internalType: "bytes32",
                        name: "s",
                        type: "bytes32",
                    },
                ],
                internalType: "struct AngleRouter.PermitType[]",
                name: "paramsPermit",
                type: "tuple[]",
            },
            {
                components: [
                    {
                        internalType: "contract IERC20",
                        name: "inToken",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amountIn",
                        type: "uint256",
                    },
                ],
                internalType: "struct AngleRouter.TransferType[]",
                name: "paramsTransfer",
                type: "tuple[]",
            },
            {
                components: [
                    {
                        internalType: "contract IERC20",
                        name: "inToken",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "collateral",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amountIn",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "minAmountOut",
                        type: "uint256",
                    },
                    {
                        internalType: "bytes",
                        name: "args",
                        type: "bytes",
                    },
                    {
                        internalType: "enum AngleRouter.SwapType",
                        name: "swapType",
                        type: "uint8",
                    },
                ],
                internalType: "struct AngleRouter.ParamsSwapType[]",
                name: "paramsSwap",
                type: "tuple[]",
            },
            {
                internalType: "enum AngleRouter.ActionType[]",
                name: "actions",
                type: "uint8[]",
            },
            {
                internalType: "bytes[]",
                name: "data",
                type: "bytes[]",
            },
        ],
        name: "mixer",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: "address",
                        name: "vaultManager",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        internalType: "bool",
                        name: "approved",
                        type: "bool",
                    },
                    {
                        internalType: "uint256",
                        name: "deadline",
                        type: "uint256",
                    },
                    {
                        internalType: "uint8",
                        name: "v",
                        type: "uint8",
                    },
                    {
                        internalType: "bytes32",
                        name: "r",
                        type: "bytes32",
                    },
                    {
                        internalType: "bytes32",
                        name: "s",
                        type: "bytes32",
                    },
                ],
                internalType: "struct AngleRouter.PermitVaultManagerType[]",
                name: "paramsPermitVaultManager",
                type: "tuple[]",
            },
            {
                components: [
                    {
                        internalType: "address",
                        name: "token",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "value",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "deadline",
                        type: "uint256",
                    },
                    {
                        internalType: "uint8",
                        name: "v",
                        type: "uint8",
                    },
                    {
                        internalType: "bytes32",
                        name: "r",
                        type: "bytes32",
                    },
                    {
                        internalType: "bytes32",
                        name: "s",
                        type: "bytes32",
                    },
                ],
                internalType: "struct AngleRouter.PermitType[]",
                name: "paramsPermit",
                type: "tuple[]",
            },
            {
                components: [
                    {
                        internalType: "contract IERC20",
                        name: "inToken",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amountIn",
                        type: "uint256",
                    },
                ],
                internalType: "struct AngleRouter.TransferType[]",
                name: "paramsTransfer",
                type: "tuple[]",
            },
            {
                components: [
                    {
                        internalType: "contract IERC20",
                        name: "inToken",
                        type: "address",
                    },
                    {
                        internalType: "address",
                        name: "collateral",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "amountIn",
                        type: "uint256",
                    },
                    {
                        internalType: "uint256",
                        name: "minAmountOut",
                        type: "uint256",
                    },
                    {
                        internalType: "bytes",
                        name: "args",
                        type: "bytes",
                    },
                    {
                        internalType: "enum AngleRouter.SwapType",
                        name: "swapType",
                        type: "uint8",
                    },
                ],
                internalType: "struct AngleRouter.ParamsSwapType[]",
                name: "paramsSwap",
                type: "tuple[]",
            },
            {
                internalType: "enum AngleRouter.ActionType[]",
                name: "actions",
                type: "uint8[]",
            },
            {
                internalType: "bytes[]",
                name: "data",
                type: "bytes[]",
            },
        ],
        name: "mixerVaultManagerPermit",
        outputs: [],
        stateMutability: "payable",
        type: "function",
    },
    {
        inputs: [],
        name: "oneInch",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IERC20",
                name: "",
                type: "address",
            },
        ],
        name: "oneInchAllowedToken",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "tokenAddress",
                type: "address",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "tokenAmount",
                type: "uint256",
            },
        ],
        name: "recoverERC20",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IERC20[]",
                name: "stablecoins",
                type: "address[]",
            },
            {
                internalType: "contract IERC20[]",
                name: "collaterals",
                type: "address[]",
            },
            {
                internalType: "contract IStableMasterFront[]",
                name: "stableMasters",
                type: "address[]",
            },
        ],
        name: "removePairs",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IERC20",
                name: "stablecoin",
                type: "address",
            },
        ],
        name: "removeStableMaster",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "admin",
                type: "address",
            },
            {
                internalType: "bool",
                name: "setGovernor",
                type: "bool",
            },
        ],
        name: "setGovernorOrGuardian",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IERC20[]",
                name: "stablecoins",
                type: "address[]",
            },
            {
                internalType: "contract IERC20[]",
                name: "collaterals",
                type: "address[]",
            },
            {
                internalType: "contract ILiquidityGauge[]",
                name: "newLiquidityGauges",
                type: "address[]",
            },
        ],
        name: "setLiquidityGauges",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IERC20",
                name: "",
                type: "address",
            },
        ],
        name: "uniAllowedToken",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "uniswapV3Router",
        outputs: [
            {
                internalType: "contract IUniswapV3Router",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        stateMutability: "payable",
        type: "receive",
    },
];
class AngleRouter__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.AngleRouter__factory = AngleRouter__factory;
AngleRouter__factory.abi = _abi;


import React, { FunctionComponent, CSSProperties } from "react";

type RangeSplitProps = {
	currentTick: number
	minTick: number
	maxTick: number
	token0Symbol: string
	token1Symbol: string
	isDeprecated?: boolean
}

const RangeSplit: FunctionComponent<RangeSplitProps> = ({
	currentTick,
	minTick,
	maxTick,
	token0Symbol: token0,
	token1Symbol: token1,
	isDeprecated
}) => {
	const totalRange = maxTick - minTick
	const currentPos = ((currentTick - minTick) / totalRange) * 100
	let left = Math.max(0, currentPos - 1);
	let right = Math.max(0, 100 - currentPos);
	if(left > 70 && left < 100) {
		left = 69;
		right = 30;
	}
	
	if(right > 70 && right < 100) {
		left = 30;
		right = 69;
	}
	
	const leftStyle: CSSProperties = {
		width: `${left}%`,
	}

	const centerStyle: CSSProperties = {
		width: "1%", // width of the red line, adjusted to 1%
	}

	const rightStyle: CSSProperties = {
		width: `${right}%`,
	}
	return (
		<>
		{(left > 1 || right > 1) && <div className="flex w-full h-4 overflow-hidden bg-white border rounded-full">
			{/* <div style={paddingStyle}></div> */}
			{left > 1 && <div
				style={leftStyle}
				className={`flex flex-row items-center justify-center ${!isDeprecated ? 'bg-gray-200': 'bg-white-200'} transition-width dark:text-background`}
			>
				<div className="text-xs">{token1}</div>
			</div>}
			<div style={centerStyle} className="bg-black transition-width"></div>
			{right > 1 && <div
				style={rightStyle}
				className={`flex flex-row items-center justify-center ${!isDeprecated ? 'bg-gray-200': 'bg-white-200'} transition-width dark:text-background`}
			>
				<div className="text-xs">{token0}</div>
			</div>}
			{/* <div style={paddingStyle}></div> */}
		</div>}
		</>
	)
}

export default RangeSplit;

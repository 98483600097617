"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculatorUsedWrappersList = void 0;
const constants_1 = require("../../types/constants");
const merkl_1 = require("../../types/merkl");
exports.calculatorUsedWrappersList = {
    [constants_1.ChainId.ARBITRUM]: {
        [merkl_1.AMMType.UniswapV3]: [
            merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Arrakis,
            merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Gamma,
            merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].DefiEdge,
            merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Steer,
        ],
        [merkl_1.AMMType.SushiSwapV3]: [],
        [merkl_1.AMMType.Retro]: [],
    },
    [constants_1.ChainId.MAINNET]: {
        [merkl_1.AMMType.UniswapV3]: [
            merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Arrakis,
            merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Gamma,
            merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].DefiEdge,
            merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Ichi,
        ],
        [merkl_1.AMMType.SushiSwapV3]: [],
        [merkl_1.AMMType.Retro]: [],
    },
    [constants_1.ChainId.OPTIMISM]: {
        [merkl_1.AMMType.UniswapV3]: [
            merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Arrakis,
            merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Gamma,
            merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].DefiEdge,
            merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Steer,
        ],
        [merkl_1.AMMType.SushiSwapV3]: [],
        [merkl_1.AMMType.Retro]: [],
    },
    [constants_1.ChainId.POLYGON]: {
        [merkl_1.AMMType.UniswapV3]: [
            merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Arrakis,
            merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Gamma,
            merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].DefiEdge,
            merkl_1.Wrapper[merkl_1.AMMType.UniswapV3].Ichi,
        ],
        [merkl_1.AMMType.SushiSwapV3]: [],
        [merkl_1.AMMType.Retro]: [],
    },
};
__exportStar(require("./addresses"), exports);
__exportStar(require("./endpoints"), exports);

import { createContext } from 'react';

type merklProp = {
    merklRewardPools: Array<object>;
    loadingMerklPools: boolean;
}

export const MerklContext = createContext<merklProp>({
    merklRewardPools: [],
    loadingMerklPools: true
});
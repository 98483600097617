import axios, { AxiosRequestConfig } from "axios";
import { supportedChains, getSupportedDexes } from "utils";
import {getBadgeUrl} from 'components/lib/app/AppCard';
import forgeTokenList from '../../utils/tokenLists/forgeList.json';

export type TOKEN_PRICES = {
  token0: string,
  token1: string
}

/*
 token1_:{
        symbol_contains_nocase: $symbol
      },
*/

const FETCH_TOKENS = `
query pools($symbol: String!) {
  pools(
    where:{
      or: [{
        token0_:{
        symbol_contains_nocase: $symbol
      }
      }]
    },
    orderBy: totalValueLockedUSD
    orderDirection: desc
    first:500
  ) {
    id
    volumeUSD
    totalValueLockedUSD
    token0 {
      id
      symbol
      id
      name
      decimals
    }
    token1 {
      id
      symbol
      id
      name
      decimals
    }
    feeTier
    feesUSD
  }
}`

export const getUSDPrices = async (addressSetByChain: Array<string>): Promise<TOKEN_PRICES | null> => {
  try {
    const res = await axios
      .post("https://coins.llama.fi/prices", {
        coins: addressSetByChain
      });
      
      return parseUSDPricesLlama(res['data'], addressSetByChain);

  } catch(e) {
    console.log('failed to fetch the prices in USD from API');
    return null;
  }
};

const parseUSDPricesLlama = (prices: any, addressSetByChain: Array<string>): TOKEN_PRICES => {
  if (Object.keys(prices.coins).length > 0) {
 
    return {
      token0: prices.coins[addressSetByChain[0]]?.price,
      token1: prices.coins[addressSetByChain[1]]?.price
    }
  } else {
    return null;
  }
};


export const getUsdPriceMetis = async (addressSetByChain: Array<string>): Promise<TOKEN_PRICES | null> => {
  const subgraphURL = 'https://metis-graph.maiadao.io/uniswap-v3';

  return await getPriceFromUniV3Dexes(addressSetByChain, subgraphURL);
}

export const getUSDPriceAvalanche = async (addressSetByChain: Array<string>): Promise<TOKEN_PRICES | null> => {
  const subgraphURL = 'https://api.thegraph.com/subgraphs/name/sushi-v3/v3-avalanche';

  return await getPriceFromUniV3Dexes(addressSetByChain, subgraphURL);
}


// get avmos price per token
export const getUsdPriceEvmos = async (addressSetByChain: Array<string>): Promise<TOKEN_PRICES | null> => {

  const subgraphURL = 'https://subgraph.satsuma-prod.com/09c9cf3574cc/orbital-apes/v3-subgraph/api';

  return await getPriceFromUniV3Dexes(addressSetByChain, subgraphURL);
};

export const getUsdPriceCelo = async (addressSetByChain: Array<string>): Promise<TOKEN_PRICES | null> => {

  const subgraphURL = 'https://api.thegraph.com/subgraphs/name/jesse-sawa/uniswap-celo';

  return await getPriceFromUniV3Dexes(addressSetByChain, subgraphURL);
};

export const getUsdPriceZkEvm = async (addressSetByChain: Array<string>): Promise<TOKEN_PRICES | null> => {

  const subgraphURL = 'https://api.studio.thegraph.com/query/32073/v3-polygon-zkevm/v0.0.2';

  return await getPriceFromUniV3Dexes(addressSetByChain, subgraphURL);
};

export const getUsdPriceKava = async (addressSetByChain: Array<string>): Promise<TOKEN_PRICES | null> => {

  const subgraphURL = 'https://the-graph.kava.io/subgraphs/name/kinetixfi/v3-subgraph';
  return await getPriceFromUniV3Dexes(addressSetByChain, subgraphURL);
};

export const getUsdPriceMatic = async (addressSetByChain: Array<string>): Promise<TOKEN_PRICES | null> => {

  const subgraphUrls = [
    "https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon",
    "https://api.thegraph.com/subgraphs/name/ruvlol/univ3-test",
    "https://api.thegraph.com/subgraphs/name/sushi-v3/v3-polygon"
  ]

  let tokenPrice: TOKEN_PRICES;

  for(const subgraphURL of subgraphUrls) {
    tokenPrice = await getPriceFromUniV3Dexes(addressSetByChain, subgraphURL);

    if (tokenPrice?.token0 && tokenPrice?.token1) {
      return tokenPrice
    }
  }

  return tokenPrice;
};


async function getPriceFromUniV3Dexes(addressSetByChain: Array<string>, subgraphUrl: string): Promise<TOKEN_PRICES | null> {
  try {

    const tokenPrices = [];

    for (const token of addressSetByChain) {
      // just for kava
      if (token === "0xb61c7093b827f114b85bf14b99e623842dc042e9") {
        const prefilledValue = {
          "data": {
              "tokenHourDatas": [{
                "priceUSD": 0.02,
                "token": {
                  "id": "0xb61c7093b827f114b85bf14b99e623842dc042e9"
                }
              }]
          }
        }

        tokenPrices.push(prefilledValue);
      } else {
        const data = JSON.stringify({
          "operationName": "tokenPrice",
          "variables": {},
          "query": `query tokenPrice {\n  tokenHourDatas(\n  first:1,  where: {token_: {id: "${token}"}}\n    orderBy: periodStartUnix\n    orderDirection: desc\n    subgraphError: allow\n  ) {\n  priceUSD\n  \n token { id } \n  }\n}`
        });
    
        const config: AxiosRequestConfig = {
          method: 'post',
          maxBodyLength: Infinity,
          url: subgraphUrl,
          headers: { 
            'content-type': 'application/json', 
          },
          data : data
        };
    
        const response = await axios.request(config)
        tokenPrices.push(response.data);
      }
    }
    
    return parseUSDPriceUniV2Dex(tokenPrices, addressSetByChain);
  } catch(e) {
    console.log('failed to fetch the prices in USD from API');
    return null;
  }
}

export const getUsdPriceBase = async (addressSetByChain: Array<string>): Promise<TOKEN_PRICES | null> => {

  const subgraphURL = 'https://api.studio.thegraph.com/query/32073/v3-base/v0.0.1';

  return await getPriceFromUniV3Dexes(addressSetByChain, subgraphURL);
};


const parseUSDPriceUniV2Dex = (prices: any, addressSetByChain: Array<string>): TOKEN_PRICES => {

    const tokenData = prices.map((tokenPrice) => {
      return tokenPrice.data.tokenHourDatas
    }).filter((tokenPrice) => tokenPrice).reduce((allItem, item) => allItem.concat(item), []);

    if (tokenData && tokenData.length > 0) {

      const tokenInfo = tokenData.find(({token}) => token.id === addressSetByChain[0]);
      const tokenInfo1 = tokenData.find(({token}) => token.id === addressSetByChain[1]);

      if (tokenInfo || tokenInfo1) {
        return {
          token0: tokenInfo ? tokenInfo.priceUSD: null,
          token1: tokenInfo1? tokenInfo1.priceUSD: null
        }
      }
      return null;
    }
    
    return null;
  }


  export const getTokenPair = async (search: string, chainId: number, selectedDex?: string) => {
    // let pool = search.split("-");
    // const token0 = pool[0].trim();
    // const token1 = pool.length > 0 && pool[1].trim();
    // console.log({token0, token1})
    const supportedChain = supportedChains.find((chain) => chain.id === chainId);
    let DEXs = getSupportedDexes(supportedChain);
    let pools = [];
    if(selectedDex) {
      DEXs = DEXs.filter(d => d.value === selectedDex);
    }

    for (let i = 0; i < DEXs.length; i++) {
      try {
        const res = await fetch(DEXs[i]["url"], {
          method: "POST",
          body: JSON.stringify({
            query: FETCH_TOKENS,
            variables: { symbol: search },
          }),
        });
        const data = await res.json();
        let dexPools = data?.data?.pools || [];

        // add a fixed url to all elements
        const dexImageUrl = getBadgeUrl(DEXs[i].value);
        dexPools = dexPools.map((pool) => {
          pool.dexImageUrl = dexImageUrl;
          pool.dex = DEXs[i].value;
          pool.dexUrl = DEXs[i].url;
          return pool;
        })

        pools = [...pools, ...dexPools];
      } catch(err) {
        console.log({err})
      } 
    }
    return pools;
  }


  export const getForgeTokenList = (tokenSymbol: string)  => {
    const token = forgeTokenList.tokens.find(({symbol}) => symbol.toLowerCase().indexOf(tokenSymbol) > -1);
    if (token) {
      return token.logoURI;
    } else {
      return '';
    }
  }
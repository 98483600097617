import React, { useState, FunctionComponent, useEffect } from "react";
import { Input, Button } from "components/lib";
import { useEthers, useEtherBalance, useContractFunction, useCall } from "@usedapp/core";
import { utils } from "ethers";
import { Contract } from "@ethersproject/contracts";
import { sendNotification } from "components/Container";
import ModalHeader from "components/ModalHeader";
import { useChainId } from "hooks/useChainId";
import { getContractByNetwork, getGasTokenSymbol } from "utils";

export interface GasProps {
  vault: {
    id: string;
    asset?: string;
    name?: string;
  };
  closeModal: () => void;
  type?: 'deposit' | 'withdraw'
}

const FundGas: FunctionComponent<GasProps> = (props) => {
  const { account } = useEthers();
  const [chainId] = useChainId();

  const [gasAmount, setGasAmount] = useState(null);
  const etherBalance = useEtherBalance(account, { chainId });
  const ethBalance = utils.formatEther(etherBalance || "0");
  const gasToken = getGasTokenSymbol(chainId);
  const gasVault = getContractByNetwork(chainId, "GasVault");
  const dynamicJob = getContractByNetwork(chainId, "DynamicJobs");
  const gasVaultInterface = new utils.Interface(gasVault.abi);
  const dynamicJobInterface = new utils.Interface(dynamicJob.abi);

  const gasVaultContract = new Contract(
    gasVault.address,
    gasVaultInterface,
  );
  const dynamicJobContract = new Contract(
    props.vault["id"],
    dynamicJobInterface,
  );

  const vaultBalanceCall = useCall({
    contract: gasVaultContract,
    method: "ethBalances",
    args: [props.vault.id],
  });
  
  const vaultBalance = utils.formatEther(vaultBalanceCall?.value?.[0] || '0');
  const BVaultBalance =  utils.parseEther(vaultBalance);
  const { state: depositState, send: deposit } = useContractFunction(gasVaultContract, "deposit", {
    transactionName: `Fund gas for ${
      props.vault?.asset || "automation"
    } vault of amount ${gasAmount} ${gasToken}`,
  });

  const { state: withdrawState, send: withdrawGas } = useContractFunction(dynamicJobContract, "withdrawGas", {
    transactionName: `Withdraw gas for ${
      props.vault?.asset || "automation"
    } vault of amount ${gasAmount} ${gasToken}`,
  });

  const title = props.type !== 'withdraw'? 'Fund': 'Withdraw';

  useEffect(() =>{
    if(depositState.status === 'Mining' || withdrawState.status === 'Mining') {
      props.closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositState, withdrawState])

  const fundGasHandler = async () => {
    try {
      if(props.type !== 'withdraw') {
        await deposit(props.vault.id, {
          value: utils.parseEther(gasAmount),
        });
      } else {
        await withdrawGas(utils.parseEther(gasAmount), account);
      }
    } catch (e) {
      console.log({ e });
      sendNotification({
        type: "error",
        transactionName: `${title} gas failed for ${
          props.vault?.asset || "automation"
        } vault of amount ${gasAmount} ${gasToken}`,
        transaction: {
          ...e,
          msg: `${title} gas failed for ${
            props.vault?.asset || "automation"
          } vault of amount ${gasAmount} ${gasToken}`,
        },
      });
    }
  };

  const isDisable = () => {
    if(props.type !== 'withdraw') {
      return !gasAmount ||
      (gasAmount &&
        utils.parseEther(gasAmount).isZero() &&
        utils.parseEther(gasAmount).isNegative()) ||
      ethBalance === "0.0"
    } else {
      return !gasAmount ||  
      (gasAmount &&
        (utils.parseEther(gasAmount).isZero() ||
        utils.parseEther(gasAmount).isNegative() || 
        utils.parseEther(gasAmount).gt(BVaultBalance)))
    }
  }
  return (
    <div className={``}>
      <ModalHeader
        closeModal={props.closeModal}
        description={`${
          props.vault?.asset
            ? `Fund Gas for Strategy - ${props.vault["name"]} & Vault -
        ${props.vault["asset"]}`
            : ""
        }
        `}
        title={`${title} Gas ${props.vault["name"] || 'For Automation Vault'}`}
      />
      <div className="my-2">
        <Input
          heading={`${title} Gas (${gasToken.toUpperCase()})`}
          val={gasAmount}
          type={"number"}
          onChange={setGasAmount}
          align={"vertical"}
          placeholder={
            props.type !== 'withdraw' && ethBalance === "0.0"
              ? `${gasToken} Balance is low, please fund the account.`
              : ""
          }
          haserror={props.type !== 'withdraw' ? ethBalance === "0.0": vaultBalance === '0.0'}
          optionalText={
            <div className="flex text-white-500">{`Gas Deposited: ${vaultBalance} ${gasToken}`}</div>
          }
        />
      </div>
        {props.vault["asset"] && <div className="my-1 text-sm text-center text-red-300"> NOTE - Please be informed that once gas has been deposited for vaults and strategies, it cannot be withdrawn. We recommend that you take this into consideration when depositing gas for these purposes.</div>}
      <div className={`col-span-1 w-full place-self-end py-4`}>
        <Button
          onClickHandler={fundGasHandler}
          disabled={isDisable()}
          content={`${title} Gas`}
          casing={`uppercase`}
          type={`tertiary`}
          fullWidth={true}
          size={'small'}
        />
      </div>
    </div>
  );
};

export default FundGas;

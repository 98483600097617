import React, { useEffect, useState } from "react";
import { Button } from "components/lib";
import { Contract } from "@ethersproject/contracts";
import { sendNotification } from "components/Container";
import {
  useEthers,
  useContractFunction,
  useCall,
} from "@usedapp/core";
import { utils } from "ethers";

import { getContractByNetwork } from "utils";
import { useChainId } from "hooks/useChainId";

function TestnetFaucet() {
  const { account } = useEthers();
  const [chainId] = useChainId();
  const TestTokenFaucet = getContractByNetwork(chainId, "TestFaucet");
  const faucetInterface = new utils.Interface(TestTokenFaucet.abi);
  const [processing, setProcessing] = useState(false);
  const [isAlreadyClaimed, setIsAlreadyClaimed] = useState(false);

  const faucetContract = new Contract(
    TestTokenFaucet.address,
    faucetInterface,
  );

  const { state, send: getTestTokens } = useContractFunction(
    faucetContract,
    "getTestTokens",
    { transactionName: `Testnet Tokens Request.` }
  );

  const claimedResponse = useCall({
    contract: faucetContract,
    method: 'isGiven',
    args: [account],
  })

  useEffect(() => {
    if(claimedResponse && claimedResponse.value && !isAlreadyClaimed) {
      if (claimedResponse.value[0] === true) {
        setIsAlreadyClaimed(true);
      }
    }
  }, [claimedResponse, isAlreadyClaimed])

  useEffect(() => {
    if(state['status'] === "Success" || state["status"] === "Exception" || state["status"] === "Fail") {
      setProcessing(false);
    }
    if(state["status"] === "Exception") {
      sendNotification({
        type: "error",
        transactionName: `Testnet Token Request Failed.`,
        transaction: {
          msg: `Testnet Tokens Already Claimed.`,
        },
      });
    }
  }, [state])

  return (
    <div className="flex flex-col items-center justify-center">
      <Button
        fullWidth={true}
        type="tertiary"
        content={!processing ? isAlreadyClaimed? "Already Claimed": "Claim Testnet Token" : "Processing"}
        size="small"
        iconSpin={processing}
        icon={processing ? "faSpinner" : ""}
        disabled={processing || isAlreadyClaimed}
        //@ts-ignore
        iconType={processing ? "normal" : ""}
        iconAlignment={"right"}
        onClickHandler={async () => {
          setProcessing(true);
          await getTestTokens();
        }}
      />
    </div>
  );
  // return null;
}

export default TestnetFaucet;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Marketplace__factory = void 0;
const ethers_1 = require("ethers");
const _abi = [
    {
        inputs: [],
        name: "InvalidOracle",
        type: "error",
    },
    {
        inputs: [],
        name: "InvalidParameters",
        type: "error",
    },
    {
        inputs: [],
        name: "MarketPriceNotInRange",
        type: "error",
    },
    {
        inputs: [],
        name: "NoOpenOrder",
        type: "error",
    },
    {
        inputs: [],
        name: "NotAllowed",
        type: "error",
    },
    {
        inputs: [],
        name: "TooSmallOrder",
        type: "error",
    },
    {
        inputs: [],
        name: "UnsafeRecipient",
        type: "error",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "spender",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "id",
                type: "uint256",
            },
        ],
        name: "Approval",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "operator",
                type: "address",
            },
            {
                indexed: false,
                internalType: "bool",
                name: "approved",
                type: "bool",
            },
        ],
        name: "ApprovalForAll",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "quoteToken",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "baseToken",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "sender",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "senderNonce",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
        ],
        name: "MarketCreated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "oracleValue",
                type: "uint256",
            },
        ],
        name: "OracleValueUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "uint48",
                name: "orderIndex",
                type: "uint48",
            },
            {
                indexed: true,
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                indexed: false,
                internalType: "int256",
                name: "amount",
                type: "int256",
            },
        ],
        name: "OrderAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "uint48",
                name: "orderIndex",
                type: "uint48",
            },
            {
                indexed: false,
                internalType: "int256",
                name: "newOrderAmount",
                type: "int256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "tokensReceived",
                type: "uint256",
            },
            {
                indexed: true,
                internalType: "address",
                name: "filler",
                type: "address",
            },
        ],
        name: "OrderFilled",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "uint48",
                name: "orderIndex",
                type: "uint48",
            },
        ],
        name: "OrderRemoved",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
            {
                indexed: false,
                internalType: "uint48",
                name: "orderIndex",
                type: "uint48",
            },
            {
                indexed: false,
                internalType: "int256",
                name: "newOrderAmount",
                type: "int256",
            },
        ],
        name: "OrderUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: false,
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
            {
                indexed: true,
                internalType: "address",
                name: "privilegedAddress",
                type: "address",
            },
        ],
        name: "PrivilegeTransferred",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "id",
                type: "uint256",
            },
        ],
        name: "Transfer",
        type: "event",
    },
    {
        inputs: [],
        name: "BASE_ORACLE",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "BASE_PARAMS",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "spender",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "id",
                type: "uint256",
            },
        ],
        name: "approve",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "balanceOf",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
        ],
        name: "computeMarketPrice",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "quoteToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "baseToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "privilegedAddress",
                type: "address",
            },
            {
                components: [
                    {
                        internalType: "contract IOracle",
                        name: "oracle",
                        type: "address",
                    },
                    {
                        internalType: "uint64",
                        name: "discountIncreaseRate",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "premiumIncreaseRate",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "maxDiscount",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "maxPremium",
                        type: "uint64",
                    },
                    {
                        internalType: "int256",
                        name: "minBuyOrder",
                        type: "int256",
                    },
                    {
                        internalType: "int256",
                        name: "minSellOrder",
                        type: "int256",
                    },
                ],
                internalType: "struct MarketParams",
                name: "params",
                type: "tuple",
            },
        ],
        name: "createMarket",
        outputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "estimateBaseToQuote",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "estimateQuoteToBase",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "owner",
                type: "address",
            },
        ],
        name: "getAddressOrder",
        outputs: [
            {
                internalType: "bool",
                name: "openOrder",
                type: "bool",
            },
            {
                components: [
                    {
                        internalType: "uint48",
                        name: "previous",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "next",
                        type: "uint48",
                    },
                    {
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        internalType: "int256",
                        name: "amount",
                        type: "int256",
                    },
                ],
                internalType: "struct Order",
                name: "order",
                type: "tuple",
            },
            {
                internalType: "bool",
                name: "buyOrSell",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "getApproved",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
        ],
        name: "getBuyOrderAmount",
        outputs: [
            {
                internalType: "uint256",
                name: "amountBroughtToBuy",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "amountToSell",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
        ],
        name: "getMarketMode",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
        ],
        name: "getMarketTokens",
        outputs: [
            {
                internalType: "address",
                name: "quoteToken",
                type: "address",
            },
            {
                internalType: "address",
                name: "baseToken",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
        ],
        name: "getOpenOrders",
        outputs: [
            {
                components: [
                    {
                        internalType: "uint48",
                        name: "previous",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "next",
                        type: "uint48",
                    },
                    {
                        internalType: "address",
                        name: "owner",
                        type: "address",
                    },
                    {
                        internalType: "int256",
                        name: "amount",
                        type: "int256",
                    },
                ],
                internalType: "struct Order[]",
                name: "",
                type: "tuple[]",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
        ],
        name: "getOrdersAmount",
        outputs: [
            {
                internalType: "uint256",
                name: "outAmount",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "inAmount",
                type: "uint256",
            },
            {
                internalType: "bool",
                name: "mode",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "owner",
                type: "address",
            },
        ],
        name: "getPendingPrior",
        outputs: [
            {
                internalType: "uint256",
                name: "amountBrought",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "amountToBring",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
        ],
        name: "getSellOrderAmount",
        outputs: [
            {
                internalType: "uint256",
                name: "amountBroughtToSell",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "amountToBuy",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "isApprovedForAll",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
            {
                internalType: "int256",
                name: "amount",
                type: "int256",
            },
            {
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "limitMarketPrice",
                type: "uint256",
            },
        ],
        name: "make",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint48",
                name: "",
                type: "uint48",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
        ],
        name: "markets",
        outputs: [
            {
                internalType: "contract IERC20",
                name: "quoteToken",
                type: "address",
            },
            {
                internalType: "contract IERC20",
                name: "baseToken",
                type: "address",
            },
            {
                components: [
                    {
                        internalType: "contract IOracle",
                        name: "oracle",
                        type: "address",
                    },
                    {
                        internalType: "uint64",
                        name: "discountIncreaseRate",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "premiumIncreaseRate",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "maxDiscount",
                        type: "uint64",
                    },
                    {
                        internalType: "uint64",
                        name: "maxPremium",
                        type: "uint64",
                    },
                    {
                        internalType: "int256",
                        name: "minBuyOrder",
                        type: "int256",
                    },
                    {
                        internalType: "int256",
                        name: "minSellOrder",
                        type: "int256",
                    },
                ],
                internalType: "struct MarketParams",
                name: "params",
                type: "tuple",
            },
            {
                components: [
                    {
                        internalType: "uint48",
                        name: "firstIndex",
                        type: "uint48",
                    },
                    {
                        internalType: "uint48",
                        name: "lastIndex",
                        type: "uint48",
                    },
                    {
                        internalType: "address",
                        name: "privilegedAddress",
                        type: "address",
                    },
                    {
                        internalType: "uint256",
                        name: "oracleValue",
                        type: "uint256",
                    },
                    {
                        internalType: "uint64",
                        name: "inversionTimestamp",
                        type: "uint64",
                    },
                ],
                internalType: "struct MarketStatus",
                name: "status",
                type: "tuple",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "name",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "nonces",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "orderIndexes",
        outputs: [
            {
                internalType: "uint48",
                name: "",
                type: "uint48",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "",
                type: "bytes32",
            },
            {
                internalType: "uint48",
                name: "",
                type: "uint48",
            },
        ],
        name: "orders",
        outputs: [
            {
                internalType: "uint48",
                name: "previous",
                type: "uint48",
            },
            {
                internalType: "uint48",
                name: "next",
                type: "uint48",
            },
            {
                internalType: "address",
                name: "owner",
                type: "address",
            },
            {
                internalType: "int256",
                name: "amount",
                type: "int256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "id",
                type: "uint256",
            },
        ],
        name: "ownerOf",
        outputs: [
            {
                internalType: "address",
                name: "owner",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
        ],
        name: "reduce",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
        ],
        name: "remove",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
        ],
        name: "resetMarketPrice",
        outputs: [
            {
                internalType: "uint256",
                name: "currentMarketPrice",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "id",
                type: "uint256",
            },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "id",
                type: "uint256",
            },
            {
                internalType: "bytes",
                name: "",
                type: "bytes",
            },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "operator",
                type: "address",
            },
            {
                internalType: "bool",
                name: "approved",
                type: "bool",
            },
        ],
        name: "setApprovalForAll",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes4",
                name: "interfaceId",
                type: "bytes4",
            },
        ],
        name: "supportsInterface",
        outputs: [
            {
                internalType: "bool",
                name: "",
                type: "bool",
            },
        ],
        stateMutability: "pure",
        type: "function",
    },
    {
        inputs: [],
        name: "symbol",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
            {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "onBehalfOf",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "limitMarketPrice",
                type: "uint256",
            },
        ],
        name: "take",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "contract IERC20",
                name: "",
                type: "address",
            },
        ],
        name: "tokenBase",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
        ],
        name: "transfer",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "id",
                type: "uint256",
            },
        ],
        name: "transferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "bytes32",
                name: "marketId",
                type: "bytes32",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
        ],
        name: "transferMarketPrivilege",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
class Marketplace__factory {
    static createInterface() {
        return new ethers_1.utils.Interface(_abi);
    }
    static connect(address, signerOrProvider) {
        return new ethers_1.Contract(address, _abi, signerOrProvider);
    }
}
exports.Marketplace__factory = Marketplace__factory;
Marketplace__factory.abi = _abi;

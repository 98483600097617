import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Title, Label, Icon, Card } from "components/lib";
import { useConfig } from "@usedapp/core";

import { gql, useLazyQuery } from "@apollo/client";
import { getGasTokenSymbol, supportedChains, toTitleCase } from "utils";
import { getClient } from "services/graphql";
import {
  getIpfsJSONConfig,
  getMinifedAddress,
  getExplorerLink,
  JOB_STATUS,
} from "utils";
import ToggleState from "./ToggleState";
import { TransparentCard } from "components/TransparentCard";
import { utils } from "ethers";
import { useChainId } from "hooks/useChainId";

const GET_JOB = gql`
  query job($id: String!) {
    job(id: $id) {
      id
      name
      timestamp
      jobInfo
      targetAddresses
      ipfsHash
      executions {
        timestamp
        executor
        status
      }
      status
      failedCounts
      vaultAddress {
        deployer
        id
      }
      timestamp
      gasUsed
    }
  }
`;

//do a gas vault call to  see how much gas is spent
// go to ipfs and get the details about job
// id of the job

const ViewJobDetails = () => {
  const { id } = useParams();

const [chainId] = useChainId();
  const gasToken = getGasTokenSymbol(chainId);
  const [jobStatus, setJobStatus] = useState(JOB_STATUS["1"]);
  const [data, setData] = useState(null);
  const [ipfsData, setIpfsData] = useState(null);

  const tconfig = useConfig();
  const supportedChain = supportedChains.find((chain) => {
    if(chainId) {
      return chain.id === chainId;
    } else {
      return chain.id === tconfig.readOnlyChainId
    }
  });
  let graphClient = getClient(supportedChains[0].subgraphURl);
  if (supportedChain) {
    graphClient = getClient(supportedChain.subgraphURl);
  }

  const [gqlCall, setGqlCall] = useState(false);
  const [getJob, job] = useLazyQuery(GET_JOB, {
    client: graphClient,
  });

  useEffect(() => {
    let notUpdateState = false;
    if (!gqlCall && !notUpdateState) {
      getJob({ variables: { id: id } });
      setGqlCall(true);
    }
    return () => {
      notUpdateState = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gqlCall]);
  useEffect(() => {
    (async () => {
      if (job?.data?.job) {
        setData(job?.data?.job);
        setJobStatus(JOB_STATUS[job?.data?.job.status]);
        if (!ipfsData) {
          const ipfsData = await getIpfsJSONConfig(job?.data?.job.ipfsHash);
          setIpfsData(ipfsData);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job.data]);
  return data ? (
    <div className="flex flex-col p-6 px-5 py-4 xl:px-60 md:pl-10 md:pr-5">
      <div className="mt-4 mb-8">
        <Title
          content={`${data.name}`}
          size={"xl"}
          color="white-500"
          align={"center"}
          casing={"capitalize"}
        />
      </div>
      <TransparentCard class="px-10">
        <div className="flex justify-center lg:justify-between">
          <Title content={"Automation Info"} size="base" color="white-200" />
          <ToggleState
            vault={data.vaultAddress.id}
            jobStatus={jobStatus}
            jobHash={data.id.split('-')[0]}
            refetch={job.refetch}
            deployer={data.vaultAddress.deployer}
          />
        </div>
        <div className="grid gap-4 mt-4 lg:grid-cols-3">
          <Card
            label={"Created By"}
            value={getMinifedAddress(data.vaultAddress.deployer)}
            size={`small`}
            onClick={() =>
              window.open(
                `${getExplorerLink(chainId)}/address/${
                  data.vaultAddress.deployer
                }`
              )
            }
            secondaryLabel={"View Address"}
          />
          <Card
            label={"Created At"}
            value={new Date(data.timestamp * 1000).toLocaleString()}
            size={"small"}
          />
          <Card
            label={"Task Id"}
            value={getMinifedAddress(data.id)}
            size={`small`}
            onClick={() => navigator.clipboard.writeText(window.location.href)}
            secondaryLabel={"Copy Id & Share"}
          />
        </div>
        <div className="grid gap-4 mt-4 lg:grid-cols-3">
          <Card
            label={"Status"}
            value={toTitleCase(jobStatus)}
            size={`small`}
          />
          <Card
            label={"Execution"}
            value={`${data.executions.length}`}
            size={`small`}
          />
          <Card
            label={"Total Gas Spent"}
            value={`${utils
              .formatEther(data.gasUsed || "0")
              .toString()
              .slice(0, 6)} ${gasToken}`}
            size={`small`}
          />
        </div>
        <div className="flex justify-center mt-6 lg:justify-start">
          <Title content={"Execution Details"} size="base" color="white-200" />
        </div>
        {!ipfsData && (
          <div className="flex justify-center">
            <Icon
              name={`faSpinner`}
              size={`2x`}
              spin={true}
              type={"normal"}
              style={{ color: "#fff" }}
            />
          </div>
        )}
        {ipfsData && (
          <div>
            <div className="grid gap-4 mt-4 lg:grid-cols-3">
              <Card
                label={"Contract Executing"}
                value={getMinifedAddress(data.targetAddresses[0])}
                size={`small`}
                onClick={() =>
                  window.open(
                    `${getExplorerLink(chainId)}/address/${
                      data.targetAddresses[0]
                    }`
                  )
                }
                secondaryLabel={"View Address"}
              />
              <Card
                label={"Execution Interval"}
                value={`Every: ${ipfsData?.metaData?.epochLength}s`}
                size={`small`}
              />
              <Card
                label={"Vault Paying Gas"}
                value={getMinifedAddress(data.vaultAddress.id)}
                size={`small`}
                onClick={() =>
                  window.open(
                    `${getExplorerLink(chainId)}/address/${
                      data.vaultAddress.id
                    }`
                  )
                }
                secondaryLabel={"View Address"}
              />
            </div>
            <div className="flex justify-center mt-6 lg:justify-start">
              <Title content={"Function Details"} size="sm" color="white-200" />
            </div>
            <div
              className={`flex flex-col flex-wrap text-base leading-6 duration-100 
              border-4 rounded-lg hover:shadow-xxs
            border-lynch-500 hover:border-shamrock-500 mt-4 p-5`}
            >
              <div className="flex mt-1 item-center">
                <Title
                  content={`Callable Function`}
                  size="sm"
                  color="white-200"
                />
                <div className="mx-2">
                  <Title
                    content={`${ipfsData?.metaData?.selectedOption?.name}`}
                    size="sm"
                    color="shamrock-500"
                  />
                </div>
              </div>
              <div className="mt-4">
                <Title
                  content="Input Values"
                  size="sm"
                  color="white-200"
                ></Title>
              </div>
              <div className="mt-2 ">
                {ipfsData?.metaData?.selectedOption?.inputs.map((option) => (
                  <div className="flex mt-2">
                    <div className="flex">
                      <Label
                        content={option.type}
                        size="large"
                        color="red-default"
                      />
                      <div className="mx-2">
                        <Label
                          content={option.name}
                          size="large"
                          color="white-500"
                        />
                      </div>
                    </div>
                    <div className="flex mx-2">
                      <Label
                        content={`${
                          ipfsData?.metaData?.callData?.[option.name]
                        }`}
                        size="large"
                        color="shamrock-500"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </TransparentCard>
    </div>
  ) : (
    <></>
  );
};

export default ViewJobDetails;

import React, { FunctionComponent, useState } from "react";
import { Button } from "../Button";
import { Icon } from "../Icon";

export interface AppDetailsCardProp {
  primaryActionables: Array<Object>;
  secondaryActionables: Array<Object>;
}

export const AppDetailsButton: FunctionComponent<AppDetailsCardProp> = (
  props
) => {
  const { primaryActionables, secondaryActionables } = props;
  const [showMore, setShowMore] = useState(true);
  return (
    <div
      data-testid="AppDetailsButton-component"
      className="relative flex flex-col justify-center bg-white-500 "
      style={{
        borderRadius: "12px",
      }}
    >
      <div
        className="flex flex-col p-6 "
        style={{
          background: "rgba(93, 95, 239, 0.1)",
        }}
      >
        <div className="flex items-center justify-evenly">
          {primaryActionables.map((d) => (
            <Button
              type={d["type"]}
              content={d["name"]}
              onClickHandler={d["onClick"]}
              size={"small"}
              {...d}
            />
          ))}
        </div>
        <div
          className="flex items-center justify-center mt-6 text-sm leading-4 text-gray-500"
          onClick={() => setShowMore(!showMore)}
        >
          More Options
          <div className="ml-2">
            <Icon
              name={showMore ? "faChevronUp" : "faChevronDown"}
              color="555555"
            />
          </div>
        </div>
        
      </div>
      {showMore && <div className="flex flex-col items-center justify-center my-6 m-auto" style={{width: '70%'}}>
        {secondaryActionables.map((d) => (
            <div className={`my-2 ${d["fullWidth"] ? 'w-full': ''}`}>
              <Button
              type={d["type"]}
              content={d["name"]}
              onClickHandler={d["onClick"]}
              size={"small"}
              fullWidth={d["fullWidth"]}
              {...d}
            />
              </div>
          ))}
          </div>}
    </div>
  );
};

AppDetailsButton.defaultProps = {};

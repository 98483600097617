import UniswapFactory from "./UniswapV3Factory.json"
import Staking from './Staking.json';

import data from '@steerprotocol/contracts/deployments/polygon.json';

const contract = {
  UniswapFactory,
  ...data.contracts,
  Staking
};

export default contract;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ethers_1 = require("ethers");
const utils_1 = require("ethers/lib/utils");
const bignumber_1 = require("../../utils/bignumber");
const interestRate = ethers_1.BigNumber.from('158153934393112649');
const stablesParameters = {
    EUR: {
        currencySymbol: '€',
        flashloan: {
            maxBorrowable: bignumber_1.parseAmount.ether('1000000'),
            flashLoanFee: bignumber_1.parseAmount.gwei('0'),
        },
        vaultManagers: [
            {
                collateral: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
                symbol: 'wMATIC-EUR',
                oracle: 'MATIC_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('500000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.65'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.05'),
                    borrowFee: bignumber_1.parseAmount.gwei('0'),
                    repayFee: bignumber_1.parseAmount.gwei('0'),
                    interestRate: interestRate,
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.13'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('0.6'),
                },
            },
            {
                collateral: '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
                symbol: 'MAI-EUR',
                oracle: 'MAI_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('500000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.85'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.05'),
                    borrowFee: bignumber_1.parseAmount.gwei('0'),
                    repayFee: bignumber_1.parseAmount.gwei('0'),
                    interestRate: interestRate,
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.08'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('1.5'),
                },
            },
            {
                collateral: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
                symbol: 'USDC-EUR',
                oracle: 'USDC_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('500000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.9'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.05'),
                    borrowFee: bignumber_1.parseAmount.gwei('0'),
                    repayFee: bignumber_1.parseAmount.gwei('0'),
                    interestRate: interestRate,
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.04'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('1.1'),
                },
            },
            {
                collateral: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
                symbol: 'wETH-EUR',
                oracle: 'ETH_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('500000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.8'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.05'),
                    borrowFee: bignumber_1.parseAmount.gwei('0'),
                    repayFee: bignumber_1.parseAmount.gwei('0'),
                    interestRate: interestRate,
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.1'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('1.5'),
                },
            },
            {
                collateral: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
                symbol: 'wBTC-EUR',
                oracle: 'BTC_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('300000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.725'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.1'),
                    borrowFee: bignumber_1.parseAmount.gwei('0'),
                    repayFee: bignumber_1.parseAmount.gwei('0'),
                    interestRate: interestRate,
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.075'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('0.4'),
                },
            },
            {
                collateral: '0x583EE5b0b2999679d1DdE7aa178B225ad57c481b',
                symbol: 'am3CRV-EUR',
                oracle: 'am3CRV_EUR',
                params: {
                    debtCeiling: (0, utils_1.parseEther)('1000'),
                    collateralFactor: bignumber_1.parseAmount.gwei('0.7'),
                    targetHealthFactor: bignumber_1.parseAmount.gwei('1.1'),
                    borrowFee: bignumber_1.parseAmount.gwei('0.003'),
                    repayFee: bignumber_1.parseAmount.gwei('0.004'),
                    interestRate: interestRate,
                    liquidationSurcharge: bignumber_1.parseAmount.gwei('0.98'),
                    maxLiquidationDiscount: bignumber_1.parseAmount.gwei('0.08'),
                    whitelistingActivated: false,
                    baseBoost: bignumber_1.parseAmount.gwei('0.4'),
                },
            },
        ],
    },
};
exports.default = {
    stablesParameters: stablesParameters,
};

import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 318.6 318.6"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".st1,.st2,.st3,.st4,.st5,.st6,.st9{fill:#e4761b;stroke:#e4761b;stroke-linecap:round;stroke-linejoin:round}.st2,.st3,.st4,.st5,.st6,.st9{fill:#d7c1b3;stroke:#d7c1b3}.st3,.st4,.st5,.st6,.st9{fill:#233447;stroke:#233447}.st4,.st5,.st6,.st9{fill:#cd6116;stroke:#cd6116}.st5,.st6,.st9{fill:#e4751f;stroke:#e4751f}.st6,.st9{fill:#f6851b;stroke:#f6851b}.st9{fill:#763d16;stroke:#763d16}"
        }
      </style>
      <path
        fill="#e2761b"
        stroke="#e2761b"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M274.1 35.5L174.6 109.4 193 65.8z"
      />
      <path className="st1" d="M44.4 35.5L143.1 110.1 125.6 65.8z" />
      <path
        className="st1"
        d="M238.3 206.8L211.8 247.4 268.5 263 284.8 207.7z"
      />
      <path className="st1" d="M33.9 207.7L50.1 263 106.8 247.4 80.3 206.8z" />
      <path
        className="st1"
        d="M103.6 138.2L87.8 162.1 144.1 164.6 142.1 104.1z"
      />
      <path
        className="st1"
        d="M214.9 138.2L175.9 103.4 174.6 164.6 230.8 162.1z"
      />
      <path className="st1" d="M106.8 247.4L140.6 230.9 111.4 208.1z" />
      <path className="st1" d="M177.9 230.9L211.8 247.4 207.1 208.1z" />
      <path
        className="st2"
        d="M211.8 247.4L177.9 230.9 180.6 253 180.3 262.3z"
      />
      <path
        className="st2"
        d="M106.8 247.4L138.3 262.3 138.1 253 140.6 230.9z"
      />
      <path className="st3" d="M138.8 193.5L110.6 185.2 130.5 176.1z" />
      <path className="st3" d="M179.7 193.5L188 176.1 208 185.2z" />
      <path className="st4" d="M106.8 247.4L111.6 206.8 80.3 207.7z" />
      <path className="st4" d="M207 206.8L211.8 247.4 238.3 207.7z" />
      <path
        className="st4"
        d="M230.8 162.1L174.6 164.6 179.8 193.5 188.1 176.1 208.1 185.2z"
      />
      <path
        className="st4"
        d="M110.6 185.2L130.6 176.1 138.8 193.5 144.1 164.6 87.8 162.1z"
      />
      <g>
        <path className="st5" d="M87.8 162.1L111.4 208.1 110.6 185.2z" />
        <path className="st5" d="M208.1 185.2L207.1 208.1 230.8 162.1z" />
        <path
          className="st5"
          d="M144.1 164.6L138.8 193.5 145.4 227.6 146.9 182.7z"
        />
        <path
          className="st5"
          d="M174.6 164.6L171.9 182.6 173.1 227.6 179.8 193.5z"
        />
      </g>
      <path
        className="st6"
        d="M179.8 193.5L173.1 227.6 177.9 230.9 207.1 208.1 208.1 185.2z"
      />
      <path
        className="st6"
        d="M110.6 185.2L111.4 208.1 140.6 230.9 145.4 227.6 138.8 193.5z"
      />
      <path
        fill="#c0ad9e"
        stroke="#c0ad9e"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M180.3 262.3L180.6 253 178.1 250.8 140.4 250.8 138.1 253 138.3 262.3 106.8 247.4 117.8 256.4 140.1 271.9 178.4 271.9 200.8 256.4 211.8 247.4z"
      />
      <path
        fill="#161616"
        stroke="#161616"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M177.9 230.9L173.1 227.6 145.4 227.6 140.6 230.9 138.1 253 140.4 250.8 178.1 250.8 180.6 253z"
      />
      <g>
        <path
          className="st9"
          d="M278.3 114.2L286.8 73.4 274.1 35.5 177.9 106.9 214.9 138.2 267.2 153.5 278.8 140 273.8 136.4 281.8 129.1 275.6 124.3 283.6 118.2z"
        />
        <path
          className="st9"
          d="M31.8 73.4L40.3 114.2 34.9 118.2 42.9 124.3 36.8 129.1 44.8 136.4 39.8 140 51.3 153.5 103.6 138.2 140.6 106.9 44.4 35.5z"
        />
      </g>
      <path
        className="st6"
        d="M267.2 153.5L214.9 138.2 230.8 162.1 207.1 208.1 238.3 207.7 284.8 207.7z"
      />
      <path
        className="st6"
        d="M103.6 138.2L51.3 153.5 33.9 207.7 80.3 207.7 111.4 208.1 87.8 162.1z"
      />
      <path
        className="st6"
        d="M174.6 164.6L177.9 106.9 193.1 65.8 125.6 65.8 140.6 106.9 144.1 164.6 145.3 182.8 145.4 227.6 173.1 227.6 173.3 182.8z"
      />
    </svg>
  )
}

export default SvgComponent

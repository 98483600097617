import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={367}
      height={272}
      viewBox="0 0 367 272"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M457.454 267.178c-.101-.032-.219-.077-.32-.11l2.291 1.771c2.269.741 4.527 1.496 6.751 2.318l.694-.776c-3.131-1.154-6.289-2.19-9.416-3.203z"
        fill="url(#paint0_linear_1242_1858)"
      />
      <path
        d="M470.854 253.278c-10.306-4.581-20.967-9.322-29.292-18.955-9.59-11.116-10.864-24.323-12.098-37.106-1.247-13.002-2.567-26.451-12.6-37.657-9.443-10.52-27.001-10.601-43.999-10.663-12.941-.058-25.157-.108-33.935-4.406-11.234-5.505-12.393-14.364-13.515-22.942-1.205-9.217-2.437-18.754-16.286-23.68-14.228-5.057-29.619-6.272-44.501-7.439-15.954-1.258-32.376-2.578-47.293-8.651l2.679 2.07c14.238 5.134 29.632 6.374 44.585 7.55 14.812 1.158 30.119 2.377 44.2 7.366 13.219 4.692 14.359 13.513 15.577 22.833 1.155 8.833 2.335 17.961 14.107 23.744 9.031 4.425 21.373 4.48 34.453 4.53 16.733.065 34.038.135 43.155 10.288 9.834 10.96 11.106 24.257 12.347 37.092 1.253 12.937 2.547 26.297 12.336 37.659 8.489 9.829 19.268 14.614 29.692 19.24 2.602 1.16 5.19 2.308 7.716 3.524l.67-.748c-2.675-1.285-5.336-2.467-7.998-3.649z"
        fill="url(#paint1_linear_1242_1858)"
      />
      <path
        d="M477.195 246.383c-10.053-5.099-20.464-10.383-28.405-20.387-8.836-11.11-10.22-24.517-11.575-37.487-1.467-14.046-2.849-27.336-12.926-37.793-9.418-9.788-25.141-9.694-40.364-9.604-11.781.079-23.968.144-33.4-4.245-10.852-5.026-12.666-13.563-14.431-21.832-1.906-8.931-3.865-18.155-17.315-22.473-13.522-4.327-28.026-5.156-42.039-5.95-17.314-.996-35.216-2.01-51.242-9.654-9.834-4.7-17.671-13.68-25.952-23.186-7.656-8.77-15.561-17.85-25.226-23.293-15.214-8.581-32.512-14.63-49.6-20.24l2.323 1.795c16.163 5.356 32.401 11.184 46.743 19.275 9.502 5.364 17.353 14.355 24.944 23.052 8.377 9.605 16.295 18.67 26.336 23.46 16.248 7.746 34.264 8.78 51.689 9.77 13.957.797 28.379 1.63 41.74 5.902 12.84 4.123 14.666 12.646 16.573 21.693 1.811 8.511 3.67 17.291 15.041 22.556 9.681 4.49 22.007 4.417 33.902 4.357 15.653-.103 30.433-.166 39.509 9.242 9.822 10.214 11.197 23.338 12.64 37.205 1.372 13.098 2.776 26.658 11.78 37.99 8.09 10.188 18.607 15.53 28.765 20.687 2.49 1.257 4.952 2.516 7.35 3.816l.67-.748c-2.488-1.347-5.024-2.64-7.53-3.908z"
        fill="url(#paint2_linear_1242_1858)"
      />
      <path
        d="M483.291 239.502c-9.845-5.652-20.049-11.49-27.685-21.833-8.178-11.084-9.736-24.326-11.222-37.12-1.684-14.329-3.265-27.865-13.338-38.618-8.97-9.556-24.12-9.342-38.766-9.106-10.99.161-22.367.346-31.203-3.537-10.476-4.597-12.917-12.813-15.279-20.738-2.565-8.634-5.213-17.585-18.326-21.343-12.866-3.683-26.559-4.16-39.802-4.613-16.827-.575-34.214-1.168-50.046-8.386-10.44-4.754-19.48-14.202-28.2-23.335-8.105-8.473-16.484-17.227-26.167-22.592-17.128-9.485-36.671-15.98-55.588-22.268A1568.095 1568.095 0 01112.733.985l2.4 1.854c4.096 1.394 8.174 2.75 12.236 4.094 18.859 6.265 38.359 12.75 55.382 22.176 9.547 5.284 17.848 13.977 25.904 22.39 8.813 9.204 17.918 18.726 28.553 23.584 16.038 7.308 33.551 7.907 50.492 8.501 13.173.444 26.795.913 39.532 4.564 12.505 3.588 14.981 11.854 17.562 20.616 2.408 8.167 4.934 16.61 15.882 21.411 9.071 3.972 20.562 3.807 31.69 3.637 15.052-.221 29.279-.458 37.911 8.744 9.846 10.509 11.435 23.89 13.084 38.053 1.518 12.935 3.076 26.292 11.422 37.598 7.77 10.516 18.067 16.425 28.018 22.135a290.84 290.84 0 017.147 4.189l.658-.735c-2.452-1.503-4.891-2.905-7.315-4.294z"
        fill="url(#paint3_linear_1242_1858)"
      />
      <path
        d="M1.904-84.772c-.513-.626-1.013-1.267-1.526-1.893L0-86.244l1.904 1.472z"
        fill="url(#paint4_linear_1242_1858)"
      />
      <path
        d="M14.795-81.634c-2.945-3.703-5.91-7.444-8.913-11.17l-.682.763c2.966 3.65 5.865 7.317 8.76 10.959 3.928 4.945 7.89 9.94 11.957 14.878l3.638 2.812c-5.041-6.014-9.918-12.153-14.76-18.242z"
        fill="url(#paint5_linear_1242_1858)"
      />
      <path
        d="M492.321 232.682c-3.629-2.529-6.962-4.483-10.217-6.377-7.478-4.375-13.946-8.153-20.03-16.952-7.624-11.001-9.34-24.065-11.027-36.693-1.933-14.636-3.76-28.453-13.939-39.496-8.608-9.345-23.191-8.946-37.291-8.587-10.294.261-20.931.557-29.238-2.894-10.146-4.204-13.156-12.077-16.062-19.686-3.2-8.364-6.491-17.007-19.3-20.254-12.292-3.124-25.271-3.257-37.804-3.391-16.313-.155-33.185-.328-48.779-7.108-11.031-4.796-20.843-14.29-30.347-23.453-8.441-8.157-17.17-16.606-26.871-21.893-17.108-9.331-36.443-15.941-55.164-22.33-12.977-4.436-26.216-8.96-38.91-14.443l3.018 2.333c11.681 4.86 23.726 8.991 35.565 13.03 18.663 6.367 37.954 12.967 54.973 22.253 9.552 5.218 18.232 13.606 26.623 21.701 9.569 9.237 19.446 18.803 30.669 23.678 15.8 6.871 32.799 7.05 49.222 7.198 12.462.125 25.37.25 37.533 3.342 12.221 3.115 15.291 11.127 18.531 19.592 2.988 7.81 6.068 15.898 16.671 20.294 8.541 3.54 19.321 3.262 29.725 2.994 14.472-.394 28.126-.751 36.42 8.213 9.953 10.799 11.76 24.463 13.673 38.945 1.688 12.744 3.417 25.911 11.193 37.121 6.222 8.997 12.794 12.834 20.378 17.267 3.212 1.884 6.556 3.824 10.14 6.317a118.004 118.004 0 014.882 3.589l.657-.735a145.491 145.491 0 00-4.894-3.575z"
        fill="url(#paint6_linear_1242_1858)"
      />
      <path
        d="M497.655 225.602c-3.505-2.64-6.755-4.715-9.889-6.745-7.386-4.742-13.755-8.835-19.623-17.789-7.133-10.876-9.054-23.799-10.903-36.276-2.232-15.006-4.33-29.194-14.689-40.33-8.561-9.194-21.499-8.582-35.213-7.925-9.754.449-19.837.943-28.229-2.412-9.919-3.96-13.471-11.492-16.906-18.774-3.781-8.008-7.708-16.29-20.106-19.151-12.053-2.778-24.569-2.668-36.666-2.557-15.453.142-31.434.29-46.823-5.55-11.988-4.545-22.661-14.336-32.987-23.811-8.462-7.783-17.222-15.818-26.675-20.914-16.854-9.09-35.753-15.661-54.025-22.025-22.782-7.94-46.324-16.143-66.477-29.301C55.502-42.948 37.672-65.52 20.428-87.34c-2.998-3.79-6.031-7.63-9.1-11.429l-.682.762c3.02 3.738 5.987 7.504 8.935 11.233 15.096 19.1 30.627 38.767 49.687 53.497a135.43 135.43 0 008.583 6.127c20.262 13.241 43.89 21.466 66.731 29.427 18.229 6.354 37.084 12.916 53.849 21.959 9.333 5.025 18.03 13.013 26.458 20.746 10.391 9.55 21.145 19.425 33.352 24.048 15.608 5.917 31.7 5.763 47.251 5.627 12.027-.12 24.46-.224 36.383 2.522 11.852 2.738 15.479 10.42 19.331 18.553 3.53 7.47 7.191 15.201 17.521 19.318 8.627 3.444 18.851 2.967 28.728 2.498 13.437-.641 26.099-1.236 34.303 7.567 10.132 10.891 12.21 24.926 14.437 39.79 1.878 12.591 3.796 25.605 11.052 36.667 6.004 9.152 12.463 13.292 19.942 18.105 3.13 2.005 6.353 4.081 9.827 6.697a118.44 118.44 0 015.343 4.244l.67-.748a119.06 119.06 0 00-5.374-4.268z"
        fill="url(#paint7_linear_1242_1858)"
      />
      <path
        d="M502.849 218.539c-3.392-2.736-6.548-4.945-9.612-7.083-7.322-5.106-13.635-9.524-19.357-18.641-6.726-10.746-8.854-23.438-10.901-35.723-2.456-14.695-4.991-29.889-15.577-41.268-8.368-8.999-20.767-8.201-33.9-7.371-9.221.584-18.746 1.187-26.779-1.867-9.643-3.655-13.681-10.849-17.59-17.806-4.344-7.73-8.847-15.721-21.01-18.17-11.606-2.34-23.538-1.917-35.082-1.517-14.941.537-30.369 1.09-45.449-4.304-12.538-4.487-23.873-14.329-34.848-23.869-8.619-7.49-17.564-15.23-26.936-20.241-16.542-8.824-34.948-15.36-52.755-21.688-22.92-8.137-46.629-16.563-66.917-30.009C62.312-46.806 43.82-70.394 25.962-93.195c-3.088-3.837-6.128-7.728-9.235-11.602l-.682.762c3.042 3.801 6.031 7.63 9.002 11.422C40.884-72.41 57.188-51.617 77.286-36.085c2.663 2.058 5.4 4.035 8.202 5.878C105.9-16.665 129.68-8.23 152.658-.07c17.749 6.305 36.112 12.831 52.591 21.608 9.268 4.952 18.15 12.645 26.723 20.099 11.053 9.6 22.48 19.513 35.222 24.066 15.287 5.484 30.837 4.911 45.877 4.381 11.474-.409 23.335-.84 34.799 1.482 11.664 2.363 15.827 9.769 20.258 17.635 4 7.12 8.144 14.488 18.17 18.3 8.294 3.142 17.93 2.532 27.262 1.941 12.857-.813 24.994-1.583 32.975 7.001 10.374 11.147 12.775 25.524 15.328 40.755 2.073 12.373 4.214 25.168 11.031 36.076 5.843 9.303 12.234 13.781 19.649 18.959 3.048 2.125 6.189 4.323 9.55 7.035a122.755 122.755 0 015.845 5l.657-.734c-1.703-1.639-3.63-3.289-5.746-4.994z"
        fill="url(#paint8_linear_1242_1858)"
      />
      <path
        d="M507.876 211.485c-3.291-2.819-6.393-5.148-9.403-7.404-7.288-5.495-13.601-10.235-19.232-19.512-6.395-10.559-8.718-23.034-10.988-35.099-2.81-15.061-5.741-30.63-16.637-42.249-8.255-8.774-20.133-7.826-32.711-6.798-8.743.724-17.778 1.453-25.541-1.347-9.4-3.398-13.881-10.244-18.193-16.868-4.852-7.455-9.891-15.17-21.882-17.255-11.221-1.951-22.627-1.237-33.658-.532-14.418.918-29.307 1.864-44.082-3.11-13.042-4.393-25.041-14.31-36.607-23.893-8.706-7.189-17.697-14.643-26.973-19.556-16.188-8.575-34.056-15.04-51.336-21.283-23.029-8.337-46.848-16.962-67.23-30.711-24.61-16.579-43.676-41.164-62.106-64.936-3.027-3.904-6.07-7.821-9.168-11.734l-.682.762c3.033 3.84 6.026 7.695 8.988 11.526C46.989-77.182 64.031-55.195 85.183-38.85a147.025 147.025 0 007.611 5.514c20.504 13.82 44.381 22.467 67.484 30.838 17.238 6.232 35.077 12.7 51.176 21.228 9.171 4.854 18.128 12.26 26.775 19.426 11.659 9.654 23.736 19.631 36.981 24.09 14.994 5.051 29.993 4.098 44.522 3.174 10.976-.702 22.311-1.425 33.391.509 11.507 2.01 16.181 9.167 21.136 16.77 4.43 6.785 9.001 13.793 18.754 17.325 8.025 2.887 17.183 2.139 26.036 1.409 12.29-.999 23.9-1.97 31.774 6.44C461.507 119.26 464.289 134 467.2 149.622c2.267 12.156 4.627 24.706 11.114 35.427 5.724 9.464 12.127 14.251 19.508 19.817 2.994 2.245 6.081 4.561 9.341 7.357a120.435 120.435 0 016.403 5.869l.67-.748c-1.952-1.946-4.054-3.87-6.36-5.859z"
        fill="url(#paint9_linear_1242_1858)"
      />
      <path
        d="M512.758 204.456c-3.218-2.901-6.266-5.348-9.205-7.712-7.323-5.866-13.651-10.941-19.206-20.39-6.105-10.357-8.663-22.598-11.127-34.446-3.223-15.449-6.554-31.419-17.836-43.221-8.251-8.633-19.61-7.445-31.633-6.217-8.335.854-16.932 1.737-24.53-.866-9.202-3.153-14.045-9.68-18.736-15.999-5.333-7.182-10.864-14.61-22.744-16.38-10.886-1.622-21.806-.602-32.398.373-13.895 1.3-28.261 2.627-42.734-1.952-13.545-4.3-26.108-14.261-38.24-23.913-8.697-6.905-17.704-14.05-26.829-18.869-15.761-8.29-33.015-14.65-49.726-20.798-23.107-8.512-47.02-17.325-67.489-31.417-25.15-17.32-44.695-42.803-63.557-67.439a1388.861 1388.861 0 00-9.29-12.036l-.683.762a1320.8 1320.8 0 019.095 11.816c17.235 22.503 35.021 45.708 57.178 62.83a154.365 154.365 0 006.634 4.85c20.59 14.163 44.561 22.998 67.742 31.545 16.668 6.137 33.894 12.499 49.593 20.741 9.032 4.747 17.992 11.856 26.659 18.738 12.221 9.698 24.865 19.745 38.614 24.11 14.692 4.656 29.18 3.308 43.186 2.002 10.509-.97 21.374-1.987 32.13-.395 11.382 1.683 16.527 8.606 21.978 15.948 4.806 6.453 9.764 13.116 19.275 16.392 7.832 2.692 16.579 1.788 25.026.927 11.746-1.211 22.835-2.332 30.667 5.862 11.089 11.607 14.388 27.438 17.579 42.747 2.487 11.91 5.054 24.229 11.234 34.736 5.673 9.609 12.064 14.732 19.467 20.683 2.924 2.352 5.956 4.787 9.171 7.663 2.586 2.32 4.885 4.58 7.011 6.799l.67-.748a136.815 136.815 0 00-6.946-6.726z"
        fill="url(#paint10_linear_1242_1858)"
      />
      <path
        d="M517.496 197.431c-3.172-2.981-6.178-5.535-9.076-8.004-7.372-6.249-13.743-11.656-19.318-21.259-5.882-10.138-8.643-22.122-11.326-33.725-3.651-15.849-7.442-32.243-19.192-44.223-8.264-8.413-19.158-7.072-30.677-5.617-7.996 1.002-16.25 2.058-23.665-.427-12.409-4.135-16.354-10.291-20.19-16.248-4.154-6.478-8.081-12.597-22.595-14.469-10.914-1.415-21.667-.172-32.075 1.037-13.1 1.523-26.654 3.085-40.616-.661-13.73-3.683-27.364-14.173-40.549-24.317-8.931-6.879-17.382-13.386-25.693-17.738-15.322-8.02-31.979-14.287-48.12-20.339-23.13-8.691-47.05-17.67-67.497-32.022-25.806-18.079-45.74-44.532-65.014-70.083-3.01-3.983-6.053-8.015-9.136-12.032l-.682.762c3.018 3.943 5.993 7.877 8.953 11.798 17.895 23.726 36.359 48.192 59.569 66.128a152.152 152.152 0 005.699 4.197c20.583 14.434 44.577 23.448 67.766 32.161 16.097 6.042 32.723 12.285 47.987 20.283 8.221 4.305 16.626 10.777 25.525 17.632 13.278 10.215 26.978 20.779 40.952 24.512 14.177 3.798 27.827 2.217 41.052.7 10.341-1.193 21.038-2.432 31.784-1.033 13.99 1.813 17.589 7.448 21.778 13.976 3.736 5.834 7.99 12.435 20.778 16.702 7.662 2.561 16.054 1.497 24.173.475 11.231-1.425 21.844-2.775 29.7 5.275 11.542 11.774 15.301 28.028 18.934 43.749 2.694 11.679 5.493 23.739 11.434 34.016 5.666 9.764 12.099 15.219 19.536 21.542 2.91 2.455 5.9 4.997 9.03 7.967a143.199 143.199 0 017.672 7.817l.669-.749c-2.271-2.583-4.775-5.14-7.57-7.783z"
        fill="url(#paint11_linear_1242_1858)"
      />
      <path
        d="M521.179 193.847c-9.727-10.232-19.804-20.805-27.581-33.858-6.088-10.229-9.12-22.262-12.044-33.889-3.996-15.931-8.128-32.382-20.235-44.316-8.358-8.278-18.803-6.706-29.807-5.036-7.684 1.15-15.626 2.355-23.022.023-12.148-3.84-16.42-9.72-20.57-15.413-4.561-6.287-8.895-12.214-23.406-13.738-10.61-1.11-20.936.416-30.936 1.871-12.638 1.856-25.728 3.778-39.468.34-14.172-3.54-28.216-14.07-41.823-24.24-8.811-6.603-17.142-12.834-25.287-17.08-15.349-8.018-31.644-14.397-47.411-20.574-22.504-8.806-45.773-17.927-66.419-31.88-27.048-18.302-47.245-46.04-66.794-72.863-2.812-3.852-5.642-7.743-8.5-11.631l-.682.761c2.805 3.802 5.582 7.605 8.328 11.385 17.744 24.392 36.073 49.535 59.623 67.735a141.145 141.145 0 007.457 5.394c20.754 14.037 44.082 23.18 66.645 32.008C194.971-4.987 211.25 1.38 226.525 9.364c8.071 4.211 16.371 10.418 25.136 16.985 13.687 10.255 27.836 20.844 42.224 24.436 13.956 3.49 27.156 1.561 39.917-.315 9.917-1.45 20.175-2.96 30.644-1.867 14 1.452 17.97 6.936 22.593 13.271 4.051 5.57 8.643 11.881 21.155 15.842 7.63 2.421 15.723 1.195 23.53.024 10.718-1.614 20.846-3.154 28.833 4.72 11.883 11.716 15.987 28.053 19.95 43.844 2.934 11.704 5.976 23.813 12.148 34.153 7.83 13.14 17.942 23.762 27.714 34.031 2.45 2.56 4.902 5.146 7.334 7.784l.67-.748c-2.373-2.616-4.807-5.164-7.194-7.677z"
        fill="url(#paint12_linear_1242_1858)"
      />
      <path
        d="M525.764 186.933c-9.841-10.689-20.005-21.72-27.948-35.085-5.929-9.968-9.168-21.77-12.302-33.191-4.516-16.402-9.176-33.376-21.86-45.25-11.419-10.712-18.434-8.26-26.543-5.436-6.468 2.251-13.777 4.797-25.057 1.42-11.874-3.561-16.449-9.19-20.901-14.634-4.984-6.107-9.689-11.883-24.193-13.034-10.365-.829-20.282.944-29.886 2.659-12.221 2.18-24.849 4.434-38.359 1.267-14.543-3.39-28.965-13.937-42.895-24.127-8.649-6.315-16.821-12.285-24.801-16.45-14.825-7.728-30.448-13.978-45.54-20.002-22.44-8.965-45.658-18.23-66.258-32.47-27.546-19.032-47.985-47.67-67.73-75.357-2.74-3.844-5.512-7.712-8.31-11.579l-.695.776c2.73 3.767 5.437 7.562 8.109 11.307 18.262 25.598 37.104 52.012 61.645 70.977a162.232 162.232 0 006.358 4.66c20.72 14.31 43.984 23.612 66.484 32.598 15.092 6.024 30.684 12.25 45.449 19.956 7.907 4.13 16.032 10.065 24.65 16.356 14.035 10.248 28.55 20.867 43.324 24.32 13.754 3.218 26.505.943 38.832-1.269 9.537-1.698 19.387-3.454 29.595-2.654 14.012 1.115 18.352 6.45 23.386 12.618 4.338 5.308 9.245 11.356 21.452 15.013 11.628 3.485 19.136.864 25.752-1.435 8.069-2.81 14.459-5.03 25.381 5.183 12.476 11.667 17.104 28.501 21.576 44.777 3.157 11.485 6.418 23.349 12.403 33.43 7.996 13.452 18.195 24.533 28.082 35.258a717.765 717.765 0 018.022 8.8l.67-.748c-2.622-2.924-5.264-5.795-7.892-8.654z"
        fill="url(#paint13_linear_1242_1858)"
      />
      <path
        d="M530.192 179.966c-9.949-11.095-20.252-22.555-28.388-36.231-5.77-9.707-9.256-21.262-12.594-32.427-5.065-16.872-10.305-34.34-23.64-46.187-11.873-10.556-18.646-7.965-26.467-4.964-6.148 2.36-13.136 5.016-24.155 1.933-11.583-3.243-16.447-8.637-21.149-13.859-5.386-5.98-10.449-11.62-24.966-12.434-10.138-.585-19.703 1.437-28.942 3.389-11.79 2.488-23.988 5.053-37.324 2.158-14.858-3.242-29.556-13.773-43.778-23.958-8.462-6.056-16.451-11.792-24.237-15.853-14.271-7.438-29.203-13.523-43.636-19.406-22.346-9.098-45.458-18.512-65.98-33.013-27.934-19.77-48.537-49.248-68.462-77.788-2.666-3.809-5.351-7.657-8.064-11.503l-.694.776c2.644 3.747 5.276 7.507 7.874 11.218 18.728 26.833 38.07 54.53 63.587 74.25a149.614 149.614 0 005.132 3.806c20.615 14.573 43.801 24.02 66.206 33.141 14.417 5.871 29.334 11.944 43.547 19.36 7.727 4.038 15.681 9.725 24.1 15.77 14.315 10.258 29.106 20.86 44.22 24.139 13.567 2.958 25.9.36 37.797-2.161 9.172-1.935 18.669-3.94 28.638-3.371 14.04.792 18.742 6.014 24.166 12.07 4.587 5.087 9.781 10.873 21.681 14.2 11.395 3.19 18.555.46 24.862-1.962 7.809-2.987 13.969-5.361 25.296 4.75 13.116 11.655 18.308 28.97 23.329 45.717 3.375 11.239 6.853 22.834 12.694 32.665 8.19 13.763 18.512 25.261 28.507 36.392 2.933 3.279 5.896 6.582 8.841 9.963l.67-.748c-2.871-3.347-5.788-6.614-8.671-9.832z"
        fill="url(#paint14_linear_1242_1858)"
      />
      <path
        d="M534.43 172.921c-10.082-11.474-20.505-23.327-28.871-37.273-5.666-9.442-9.356-20.74-12.913-31.66-5.645-17.366-11.504-35.312-25.531-47.119-12.338-10.386-18.941-7.664-26.558-4.482-5.886 2.449-12.553 5.214-23.341 2.4-11.295-2.95-16.397-8.136-21.337-13.151-5.745-5.844-11.182-11.357-25.686-11.864-9.952-.325-19.148 1.959-28.034 4.16-11.964 2.975-23.238 5.794-36.407 2.89-14.775-3.248-28.85-13.158-42.464-22.735-8.801-6.203-17.126-12.061-25.642-16.272-12.582-6.224-25.57-11.613-38.133-16.833-24.608-10.201-47.848-19.852-69.099-35.47-28.009-20.563-48.524-50.549-68.397-79.556a1991.543 1991.543 0 00-8.304-12.055l-.694.775c2.739 3.934 5.422 7.872 8.101 11.784 19.203 28.029 39.027 56.974 65.643 77.542.991.766 1.994 1.518 3.024 2.269 21.36 15.7 45.76 25.811 69.34 35.61 12.547 5.208 25.521 10.585 38.059 16.799 8.442 4.176 16.736 10.01 25.491 16.178 13.706 9.648 27.874 19.63 42.893 22.928 13.44 2.953 25.377-.022 36.935-2.896 8.819-2.184 17.932-4.463 27.718-4.128 14.028.483 19.064 5.596 24.902 11.51 4.813 4.895 10.266 10.42 21.878 13.456 11.164 2.92 18.015.066 24.06-2.443 7.593-3.155 13.542-5.646 25.391 4.293C480.261 69.192 486.06 87 491.657 104.214c3.564 10.971 7.261 22.32 12.995 31.862 8.419 14.032 18.874 25.909 28.974 37.42a795.302 795.302 0 019.76 11.248l.67-.748c-3.204-3.765-6.423-7.426-9.626-11.075z"
        fill="url(#paint15_linear_1242_1858)"
      />
      <path
        d="M538.554 165.971c-10.23-11.864-20.82-24.145-29.46-38.37-5.555-9.127-9.4-19.901-13.116-30.322-6.289-17.611-12.784-35.841-27.868-47.843l-.79-.61c-12.634-9.764-18.914-7.022-26.143-3.863-5.542 2.415-11.794 5.156-22.27 2.607-8.463-2.075-14.7-6.987-20.721-11.732-7.65-6.027-15.563-12.257-27.786-12.152-9.677.07-18.321 2.528-26.656 4.902-11.481 3.257-22.331 6.334-35.478 3.609-15.325-3.19-29.959-13.44-44.132-23.357-8.458-5.915-16.442-11.51-24.506-15.67-12.988-6.677-26.23-12.192-39.043-17.514-22.978-9.563-44.675-18.597-64.962-33.769-28.189-21.094-48.755-51.901-68.632-81.693a1632.86 1632.86 0 00-8.094-12.055l-.695.775c2.64 3.927 5.28 7.855 7.889 11.758 19.478 29.185 39.598 59.349 66.957 80.492.635.49 1.27.98 1.932 1.47 20.396 15.255 42.167 24.324 65.216 33.896 12.785 5.323 26.012 10.826 38.969 17.48 8.002 4.111 15.955 9.682 24.369 15.586 14.267 9.99 29.01 20.324 44.562 23.551 13.434 2.786 24.418-.325 36.035-3.615 8.282-2.346 16.831-4.784 26.355-4.859 11.82-.095 19.222 5.74 27.042 11.9 6.129 4.828 12.46 9.812 21.166 11.937 10.852 2.656 17.304-.161 22.989-2.65 7.19-3.143 12.903-5.633 24.98 3.7l.805.622c14.824 11.824 21.271 29.903 27.516 47.388 3.735 10.458 7.599 21.27 13.195 30.497 8.689 14.287 19.298 26.606 29.574 38.506 3.559 4.13 7.148 8.285 10.682 12.559l.67-.748c-3.5-4.225-7.043-8.343-10.551-12.413z"
        fill="url(#paint16_linear_1242_1858)"
      />
      <path
        d="M542.558 159.019c-10.446-12.354-21.241-25.138-30.112-39.427-5.525-8.897-9.651-19.036-13.653-28.873-7.129-17.523-14.502-35.65-30.146-47.992l-.603-.466c-13.316-10.29-19.488-7.58-26.638-4.452-5.263 2.309-11.239 4.917-21.338 2.59-8.533-1.968-15.116-6.64-21.482-11.146-8.145-5.765-16.567-11.72-28.893-11.764-9.899-.032-18.229 2.806-26.281 5.537-10.467 3.557-20.333 6.911-33.208 4.35-15.936-3.178-30.983-13.817-45.529-24.137-8.285-5.874-16.094-11.402-24.022-15.595-13.03-6.894-26.204-12.31-38.948-17.533-21.474-8.815-41.768-17.133-61.109-31.988l-.418-.323c-28.149-21.753-48.535-53.112-68.245-83.442-2.606-3.994-5.22-8.039-7.857-12.057l-.694.776c2.584 3.93 5.126 7.851 7.664 11.746 19.76 30.391 40.183 61.825 68.486 83.698l.418.323c19.45 14.939 39.818 23.291 61.378 32.127 12.701 5.212 25.844 10.605 38.831 17.488 7.854 4.16 15.647 9.675 23.886 15.513 14.651 10.378 29.791 21.089 45.971 24.317 13.188 2.62 23.668-.95 33.816-4.386 7.956-2.712 16.195-5.506 25.913-5.476 11.963.038 19.876 5.624 28.223 11.546 6.459 4.577 13.132 9.296 21.896 11.328 10.459 2.42 16.619-.276 22.041-2.645 7.202-3.158 12.853-5.696 26.078 4.755 15.411 12.163 22.741 30.163 29.825 47.56 4.006 9.862 8.165 20.051 13.729 29.022 8.908 14.365 19.734 27.173 30.211 39.551 3.882 4.588 7.798 9.226 11.648 13.996l.682-.762c-3.833-4.642-7.7-9.219-11.52-13.759z"
        fill="url(#paint17_linear_1242_1858)"
      />
      <path
        d="M547.103 153.153c-10.856-13.038-22.09-26.531-31.487-41.549-5.107-8.136-9.443-17.425-13.629-26.414-8.455-18.088-17.199-36.79-33.092-49.118l-.124-.096c-14.306-11.056-20.494-8.358-27.644-5.23-5.012 2.204-10.697 4.692-20.385 2.522-8.369-1.888-15.325-6.32-22.056-10.623-8.824-5.622-17.923-11.434-30.141-11.394-9.751.037-17.765 3.165-25.515 6.175-9.835 3.816-19.124 7.432-31.465 5.007-16.26-3.199-31.735-14.237-46.718-24.918-8.121-5.793-15.812-11.276-23.519-15.483-12.884-7.057-25.959-12.35-38.595-17.489-20.225-8.22-39.306-15.99-57.377-30.186-28.521-22.479-48.875-54.664-68.564-85.807-2.505-3.962-5.029-7.96-7.596-11.969l-.706.789c2.495 3.884 4.935 7.772 7.383 11.62 19.727 31.219 40.134 63.491 68.794 86.077l1.053.814c17.852 13.796 36.695 21.453 56.612 29.549 12.605 5.115 25.652 10.41 38.462 17.432 7.648 4.185 15.293 9.632 23.399 15.413 15.076 10.753 30.671 21.862 47.174 25.11 12.683 2.482 22.12-1.182 32.101-5.045 7.643-2.977 15.581-6.05 25.123-6.087 11.867-.058 20.435 5.412 29.503 11.2 6.82 4.35 13.869 8.853 22.438 10.78 10.049 2.265 15.918-.31 21.089-2.575 7.098-3.1 12.716-5.571 26.48 5.066l.124.096c15.691 12.172 24.364 30.75 32.763 48.726 4.217 9.013 8.556 18.327 13.697 26.512 9.447 15.08 20.697 28.584 31.571 41.66 3.96 4.764 7.955 9.577 11.908 14.496l.682-.762c-3.888-4.845-7.833-9.597-11.743-14.299z"
        fill="url(#paint18_linear_1242_1858)"
      />
      <path
        d="M550.331 146.476c-11.028-13.079-22.42-26.601-32.06-41.347-4.967-7.59-9.248-16.239-13.774-25.375-9.313-18.798-18.907-38.249-35.581-50.26-15.541-11.227-21.536-8.657-28.454-5.67-4.766 2.047-10.164 4.389-19.826 2.423-8.629-1.743-15.883-6.106-22.923-10.326-9.065-5.441-18.418-11.058-30.626-10.94-9.674.096-17.425 3.428-24.912 6.64-9.322 4.005-18.13 7.786-30.153 5.561-16.563-3.064-32.316-14.524-47.56-25.615-7.863-5.73-15.307-11.138-22.719-15.324-12.571-7.114-25.415-12.46-37.848-17.65-19.565-8.168-38.067-15.883-55.262-29.77-28.241-22.791-48.145-55.274-67.397-86.701-2.294-3.729-4.59-7.483-6.93-11.248l-.707.789c2.256 3.654 4.496 7.296 6.706 10.914 19.302 31.488 39.259 64.058 67.627 86.97.731.588 1.462 1.176 2.206 1.75 16.706 12.911 34.517 20.346 53.328 28.185 12.39 5.18 25.207 10.527 37.715 17.593 7.338 4.151 14.767 9.547 22.599 15.254 15.337 11.162 31.214 22.718 48.032 25.819 12.365 2.282 21.317-1.572 30.798-5.637 7.396-3.17 15.039-6.469 24.492-6.552 11.872-.124 20.681 5.165 30.019 10.77 7.102 4.268 14.46 8.689 23.29 10.472 10.019 2.035 15.576-.369 20.514-2.49 6.878-2.971 12.319-5.303 27.337 5.544.539.393 1.077.786 1.604 1.193 15.437 11.93 24.691 30.589 33.648 48.674 4.544 9.173 8.84 17.834 13.829 25.487 9.675 14.795 21.098 28.341 32.129 41.446 4.429 5.264 8.905 10.564 13.314 15.997l.682-.761c-4.332-5.373-8.758-10.612-13.137-15.815z"
        fill="url(#paint19_linear_1242_1858)"
      />
      <path
        d="M553.942 140.855c-11.404-13.187-23.215-26.825-33.275-41.688-5.136-7.606-9.648-16.202-14.431-25.284-9.998-19.028-20.289-38.696-37.515-50.88-16.229-11.483-22.164-8.98-29.066-6.099-4.706 1.98-10.034 4.215-19.704 2.404-8.798-1.643-16.318-5.913-23.585-10.056-9.282-5.286-18.895-10.758-31.097-10.59-9.612.144-17.13 3.656-24.397 7.062-8.885 4.159-17.282 8.096-29.033 6.035-16.756-2.937-32.684-14.786-48.073-26.242-7.619-5.68-14.805-11.026-21.918-15.165-12.27-7.158-24.816-12.573-36.952-17.831-19.011-8.202-36.976-15.962-53.635-29.803-27.807-23.101-47.144-55.72-65.837-87.245-2.014-3.397-4.06-6.819-6.092-10.254l-.706.789c1.973 3.297 3.934 6.608 5.876 9.881 18.734 31.625 38.108 64.32 66.082 87.526a128.84 128.84 0 003.559 2.842c15.778 12.193 32.618 19.453 50.348 27.125 12.105 5.235 24.621 10.626 36.832 17.76 7.054 4.118 14.224 9.451 21.813 15.108 15.513 11.552 31.55 23.485 48.561 26.458 12.12 2.116 21.057-2.072 29.705-6.114 7.163-3.348 14.574-6.827 23.938-6.957 11.893-.176 20.922 4.983 30.489 10.42 7.356 4.19 14.954 8.519 23.949 10.176 10.023 1.854 15.495-.454 20.348-2.48 6.848-2.88 12.256-5.145 27.962 5.957 16.969 12.032 27.243 31.572 37.173 50.5 4.787 9.109 9.301 17.73 14.472 25.386 10.082 14.926 21.908 28.576 33.343 41.787 4.717 5.44 9.487 10.967 14.159 16.604l.683-.762c-4.601-5.512-9.321-10.978-13.976-16.37z"
        fill="url(#paint20_linear_1242_1858)"
      />
      <path
        d="M557.43 135.207c-11.82-13.278-24.019-27.01-34.495-41.964-5.333-7.62-10.075-16.164-15.091-25.22-10.653-19.233-21.683-39.128-39.439-51.422-16.93-11.726-22.599-9.432-29.775-6.508-4.648 1.886-9.946 4.03-19.651 2.375-8.979-1.53-16.714-5.735-24.223-9.812-9.487-5.145-19.293-10.49-31.503-10.282-9.581.168-16.867 3.858-23.918 7.432-8.489 4.303-16.495 8.359-28.032 6.44-16.952-2.835-33.047-15.112-48.631-26.995-7.283-5.559-14.163-10.807-20.879-14.892-11.994-7.29-24.395-12.868-36.378-18.239-18.378-8.264-35.734-16.06-51.634-29.522-27.324-23.142-46.045-55.953-64.15-87.667-1.749-3.078-3.526-6.155-5.291-9.245l-.719.803c1.693 2.965 3.399 5.917 5.077 8.871 18.157 31.801 36.915 64.687 64.383 87.962a117.97 117.97 0 004.389 3.554c14.787 11.427 30.722 18.586 47.516 26.133 11.956 5.372 24.31 10.914 36.245 18.182 6.67 4.05 13.535 9.285 20.786 14.82 15.693 11.966 31.912 24.34 49.147 27.21C323.058 9.207 331.6 4.875 339.881.688c6.947-3.517 14.129-7.15 23.446-7.315 11.89-.202 21.139 4.829 30.926 10.136 7.571 4.125 15.424 8.375 24.575 9.946 10.019 1.713 15.461-.503 20.256-2.436 6.861-2.777 12.291-4.98 28.701 6.39 17.496 12.117 28.501 31.923 39.112 51.056 5.033 9.068 9.793 17.649 15.145 25.306 10.525 15.016 22.756 28.772 34.579 42.075 5.039 5.666 10.112 11.382 15.122 17.256l.67-.748c-4.939-5.866-9.978-11.532-14.983-17.148z"
        fill="url(#paint21_linear_1242_1858)"
      />
      <path
        d="M562.157 131.391c-12.729-13.842-25.893-28.135-37.063-44.04-5.006-7.137-9.687-15.105-14.656-23.57-11.832-20.17-24.089-41.035-42.511-53.522-17.642-11.954-23.376-9.734-30.609-6.922-4.646 1.795-9.914 3.846-19.646 2.31-9.134-1.443-17.099-5.596-24.811-9.623-9.656-5.045-19.639-10.25-31.846-10.017-9.565.18-16.678 4.028-23.527 7.757-8.126 4.423-15.81 8.589-27.125 6.773-17.05-2.727-33.163-15.364-48.747-27.568-7.04-5.51-13.664-10.72-20.109-14.758-11.682-7.347-23.75-12.944-35.404-18.36-17.794-8.274-34.621-16.076-49.955-29.468-26.816-23.417-44.876-56.293-62.336-88.083-1.494-2.72-2.988-5.44-4.498-8.172l-.719.803c1.423 2.595 2.873 5.189 4.28 7.772 17.513 31.878 35.61 64.829 62.569 88.38a126.783 126.783 0 005.707 4.685c13.873 10.722 28.82 17.669 44.52 24.945 11.639 5.404 23.664 10.992 35.283 18.291 6.386 4.015 13.008 9.2 20.016 14.686 15.696 12.314 31.946 25.033 49.294 27.806 11.696 1.858 19.889-2.582 27.819-6.904 6.745-3.671 13.726-7.46 23.027-7.637 11.93-.217 21.317 4.689 31.284 9.883C400.18.898 408.251 5.11 417.57 6.58c10.03 1.583 15.446-.516 20.223-2.37 6.942-2.692 12.409-4.82 29.547 6.79 18.221 12.333 30.409 33.099 42.173 53.169 4.969 8.466 9.668 16.471 14.705 23.632 11.205 15.955 24.387 30.285 37.148 44.151 4.897 5.327 9.844 10.715 14.709 16.224l.682-.762c-4.824-5.408-9.737-10.747-14.6-16.024z"
        fill="url(#paint22_linear_1242_1858)"
      />
      <path
        d="M565.53 125.7c-13.139-13.883-26.7-28.23-38.338-44.198-5.203-7.15-10.136-15.129-15.326-23.582-12.507-20.322-25.467-41.34-44.455-53.896-18.395-12.167-24.194-10.02-31.539-7.317-4.656 1.718-9.937 3.667-19.711 2.236-9.278-1.37-17.477-5.496-25.395-9.498-9.811-4.958-19.966-10.09-32.123-9.795-12.172.283-17.468 4.384-22.599 8.36-5.828 4.519-11.341 8.774-26.908 6.733-17.498-2.313-33.369-15.338-48.714-27.956-6.75-5.539-13.114-10.779-19.322-14.817-11.337-7.38-23.089-13.01-34.442-18.47-17.222-8.268-33.493-16.077-48.287-29.398-26.266-23.681-43.634-56.598-60.418-88.442-1.235-2.336-2.474-4.698-3.725-7.045l-.731.816c1.164 2.211 2.327 4.422 3.503 6.62 16.837 31.931 34.246 64.949 60.651 88.738 2.304 2.08 4.63 4.016 6.999 5.846 12.976 10.028 26.927 16.712 41.563 23.742 11.323 5.436 23.032 11.055 34.307 18.387 6.162 4.003 12.494 9.219 19.214 14.734 14.75 12.112 31.467 25.86 49.291 28.218 16.023 2.117 21.713-2.278 27.717-6.936 4.979-3.863 10.149-7.854 21.959-8.141 11.853-.277 21.424 4.541 31.55 9.674 8.008 4.048 16.284 8.234 25.759 9.618 10.045 1.48 15.473-.517 20.277-2.283 7.038-2.594 12.613-4.637 30.519 7.198 18.806 12.439 31.666 33.333 44.133 53.554 5.205 8.465 10.126 16.457 15.375 23.644 11.673 16.017 25.28 30.4 38.434 44.295 5.247 5.55 10.559 11.175 15.805 16.932l.67-.748c-5.215-5.734-10.477-11.296-15.693-16.823z"
        fill="url(#paint23_linear_1242_1858)"
      />
      <path
        d="M567.021 117.564c-12.816-13.219-26.067-26.888-37.829-41.87-6.236-7.927-12.027-16.499-18.176-25.578-13.005-19.21-26.412-39.055-44.166-52.2-11.619-8.587-22.114-7.399-33.226-6.135-6.098.696-12.418 1.405-19.222.52-9.4-1.234-17.824-5.373-25.951-9.375-9.943-4.9-20.213-9.96-32.315-9.668-12.256.288-17.425 4.51-22.432 8.581-5.6 4.558-10.881 8.877-26.072 6.942-17.488-2.236-33.909-16.169-48.43-28.45-6.51-5.514-12.646-10.716-18.629-14.742-11.021-7.412-22.413-13.062-33.447-18.528-16.647-8.238-32.373-16.042-46.601-29.293-25.684-23.922-42.317-56.87-58.421-88.741a570.97 570.97 0 00-2.974-5.866l-.731.816c.917 1.814 1.834 3.628 2.736 5.429 16.13 31.96 32.816 64.995 58.627 89.039 2.695 2.52 5.447 4.83 8.234 6.984 12.077 9.334 25.048 15.767 38.627 22.486 11.003 5.442 22.38 11.08 33.326 18.458 5.922 3.978 12.042 9.168 18.522 14.659 14.632 12.39 31.192 26.43 49.034 28.71 15.674 2.01 21.121-2.436 26.894-7.158 4.867-3.973 9.899-8.07 21.779-8.35 11.798-.273 21.5 4.486 31.771 9.546 8.216 4.048 16.729 8.233 26.314 9.495 6.934.916 13.321.19 19.487-.523 10.878-1.237 21.167-2.4 32.402 5.914 17.612 13.012 30.985 32.807 43.909 51.932 6.149 9.079 11.955 17.663 18.226 25.64 11.808 15.017 25.078 28.724 37.909 41.955 6.304 6.506 12.674 13.085 18.931 19.877l.67-.748c-6.168-6.746-12.518-13.288-18.776-19.758z"
        fill="url(#paint24_linear_1242_1858)"
      />
      <path
        d="M570.148 111.845c-13.195-13.235-26.852-26.92-39.047-41.914-6.477-7.951-12.504-16.522-18.917-25.598-13.618-19.317-27.728-39.313-46-52.466-12.015-8.641-22.948-7.607-34.542-6.509-6.127.582-12.447 1.176-19.214.365-9.544-1.16-18.121-5.303-26.43-9.307-10.032-4.83-20.422-9.844-32.472-9.582-12.33.254-17.402 4.574-22.313 8.742-5.4 4.597-10.524 8.946-25.358 7.08-17.426-2.188-33.658-16.39-47.96-28.916-6.286-5.502-12.225-10.69-17.968-14.691-10.677-7.445-21.749-13.102-32.463-18.574-16.072-8.208-31.25-15.979-44.924-29.147-25.043-24.141-40.938-57.094-56.332-88.969-.743-1.54-1.489-3.106-2.247-4.659l-.743.83c.683 1.402 1.35 2.793 2.018 4.183 15.419 31.964 31.352 64.992 56.538 89.266a115.82 115.82 0 009.412 8.126c11.21 8.663 23.222 14.792 35.775 21.224 10.671 5.462 21.712 11.095 32.327 18.492 5.697 3.966 11.593 9.143 17.86 14.608 14.441 12.633 30.8 26.956 48.58 29.188 15.314 1.914 20.588-2.572 26.177-7.322 4.77-4.07 9.693-8.252 21.645-8.522 11.785-.26 22.027 4.686 31.942 9.471 8.398 4.05 17.093 8.238 26.806 9.415 6.881.829 13.269.219 19.451-.366 11.36-1.072 22.084-2.107 33.747 6.285 18.132 13.045 32.176 32.968 45.729 52.21 6.127 8.694 12.459 17.685 18.952 25.649 12.242 15.03 25.902 28.74 39.127 41.999 6.725 6.739 13.546 13.575 20.214 20.639l.67-.748c-6.56-6.98-13.334-13.781-20.04-20.482z"
        fill="url(#paint25_linear_1242_1858)"
      />
      <path
        d="M573.253 106.129c-13.586-13.237-27.633-26.924-40.259-41.907-6.733-7.988-13.028-16.581-19.693-25.668-14.212-19.385-28.873-39.438-47.736-52.634-12.428-8.707-23.839-7.812-35.926-6.864-6.14.48-12.502.972-19.234.211-9.676-1.101-18.422-5.26-26.9-9.28-10.112-4.799-20.551-9.759-32.58-9.549-12.404.22-17.423 4.626-22.269 8.868-5.258 4.614-10.213 8.979-24.717 7.184-17.325-2.156-33.267-16.594-47.331-29.327-6.061-5.49-11.806-10.689-17.325-14.678-10.36-7.477-21.085-13.141-31.467-18.633-15.438-8.155-29.995-15.86-43.296-28.947-24.687-24.304-39.881-57.543-54.573-89.705-.412-.916-.835-1.818-1.246-2.734l-.755.843c.333.74.681 1.493 1.013 2.233 14.73 32.237 29.965 65.577 54.795 90.014a115.247 115.247 0 0010.384 9.06c10.482 8.1 21.581 13.962 33.175 20.09 10.336 5.456 21.018 11.11 31.331 18.551 5.473 3.953 11.187 9.129 17.245 14.593 14.191 12.854 30.272 27.4 47.966 29.611 14.988 1.87 20.12-2.634 25.552-7.413 4.705-4.144 9.575-8.413 21.576-8.621 11.765-.208 22.071 4.696 32.05 9.438 8.552 4.055 17.403 8.271 27.276 9.387 6.847.78 13.264.284 19.459-.2 11.866-.934 23.086-1.791 35.162 6.666 18.676 13.052 33.303 33.056 47.465 52.379 6.379 8.704 12.98 17.718 19.743 25.73 12.661 15.032 26.739 28.743 40.34 41.992 7.16 6.984 14.429 14.051 21.537 21.386l.67-.748c-7.065-7.325-14.291-14.382-21.432-21.328z"
        fill="url(#paint26_linear_1242_1858)"
      />
      <path
        d="M576.314 100.405c-13.961-13.228-28.395-26.892-41.44-41.876-6.988-8.024-13.856-17.06-20.506-25.813-14.748-19.43-30.012-39.513-49.36-52.693-12.865-8.745-24.785-8.014-37.397-7.241-6.181.379-12.595.785-19.34.038-9.783-1.07-18.687-5.258-27.294-9.309-10.136-4.772-20.627-9.703-32.623-9.56-12.497.148-17.467 4.617-22.275 8.934-5.144 4.633-9.989 8.99-24.185 7.203-17.186-2.164-32.777-16.79-46.528-29.696-5.868-5.501-11.392-10.714-16.741-14.687-10.028-7.497-20.418-13.155-30.478-18.627-14.858-8.1-28.92-15.744-41.67-28.774-24-24.486-38.41-57.695-52.342-89.798-.201-.478-.418-.967-.619-1.445l-.755.843c.138.314.261.616.399.93 13.985 32.191 28.42 65.488 52.551 90.121a111.683 111.683 0 0011.492 10.193c9.631 7.443 19.826 12.997 30.441 18.784 10.017 5.462 20.36 11.084 30.342 18.545 5.291 3.95 10.796 9.126 16.648 14.615 13.879 13.027 29.612 27.787 47.195 30.005 14.68 1.862 19.711-2.675 25.016-7.459 4.671-4.193 9.492-8.524 21.582-8.686 11.732-.14 22.09 4.734 32.121 9.448 8.697 4.097 17.687 8.305 27.67 9.415 6.843.754 13.286.346 19.522-.036 12.402-.773 24.129-1.493 36.66 7.04 19.193 13.06 34.392 33.07 49.09 52.438 6.65 8.753 13.522 17.815 20.541 25.864 13.091 15.019 27.541 28.695 41.517 41.935 7.624 7.227 15.344 14.551 22.903 22.142l.67-.748c-7.525-7.542-15.214-14.842-22.807-22.045z"
        fill="url(#paint27_linear_1242_1858)"
      />
      <path
        d="M579.337 94.719c-14.333-13.194-29.143-26.849-42.614-41.794-7.275-8.085-14.43-17.181-21.339-25.996C500.12 7.49 484.339-12.6 464.512-25.713c-13.303-8.784-25.732-8.215-38.895-7.616-6.262.293-12.733.586-19.49-.147-9.922-1.062-18.943-5.294-27.679-9.376-10.148-4.758-20.679-9.674-32.618-9.624-12.522.059-17.509 4.721-22.321 9.219-5.088 4.746-9.873 9.242-23.736 6.907-17.596-2.965-31.383-16.358-44.707-29.303-5.832-5.658-11.348-11.025-17.093-15.465-9.708-7.502-19.775-13.141-29.511-18.594-14.332-8.015-27.855-15.588-40.109-28.557-23.286-24.67-36.904-57.797-50.061-89.831a.354.354 0 01-.044-.126l-.768.857c13.15 31.983 26.771 65.02 50.088 89.714a107.714 107.714 0 0012.498 11.292c8.841 6.833 18.157 12.053 27.862 17.481 9.705 5.43 19.725 11.032 29.387 18.5 5.714 4.415 11.196 9.733 17.012 15.379 13.452 13.065 27.366 26.58 45.331 29.6 14.406 2.432 19.392-2.23 24.65-7.167 4.676-4.374 9.513-8.899 21.617-8.959 11.701-.048 22.072 4.813 32.115 9.513 8.826 4.128 17.921 8.395 28.04 9.47 6.855.74 13.366.432 19.671.15 12.955-.6 25.202-1.171 38.186 7.413 19.676 13.019 35.403 33.022 50.603 52.387 6.924 8.827 14.082 17.95 21.4 26.044 13.506 14.995 28.344 28.648 42.679 41.867 8.1 7.457 16.295 15.01 24.322 22.87l.657-.735c-8.028-7.769-16.193-15.299-24.261-22.731z"
        fill="url(#paint28_linear_1242_1858)"
      />
      <path
        d="M583.76 90.622c-15.222-13.605-30.977-27.667-45.174-43.265-7.097-7.786-13.837-16.585-21.005-25.9-15.972-20.814-32.495-42.33-53.52-52.846-15.094-7.545-26.057-7.5-38.776-7.456-6.477.036-13.178.059-21.305-.813-10.047-1.066-19.173-5.357-28-9.485-10.148-4.758-20.651-9.676-32.575-9.73-12.622-.064-17.645 4.64-22.507 9.191-5.047 4.731-9.811 9.174-23.342 6.82-17.751-3.085-31.493-16.996-44.758-30.446-5.307-5.367-10.313-10.456-15.459-14.686-8.991-7.455-19.131-13.335-28.912-19.029-13.352-7.764-27.128-15.786-38.245-27.83-22.267-24.228-35.054-56.851-47.288-88.54l-.78.871c12.221 31.587 24.988 64.056 47.292 88.243 3.86 4.203 8.06 7.91 12.427 11.284 8.299 6.414 17.295 11.64 26.069 16.763 9.75 5.671 19.831 11.528 28.773 18.921 5.087 4.208 10.09 9.271 15.366 14.614 13.391 13.572 27.261 27.605 45.385 30.77 14.073 2.451 19.023-2.17 24.272-7.068 4.712-4.414 9.601-8.968 21.777-8.903 11.66.057 22.03 4.918 32.073 9.618 8.916 4.174 18.144 8.497 28.376 9.592 8.198.88 15.219.85 21.446.83 13.083-.04 23.419-.086 38.231 7.333 20.803 10.415 37.242 31.819 53.129 52.521 7.157 9.329 13.928 18.152 21.056 25.962 14.231 15.648 30.001 29.722 45.254 43.351 8.066 7.2 16.229 14.498 24.234 22.089l.658-.734c-7.99-7.579-16.121-14.853-24.172-22.042z"
        fill="url(#paint29_linear_1242_1858)"
      />
      <path
        d="M586.847 85.009c-15.609-13.582-31.736-27.61-46.348-43.183-7.352-7.823-14.379-16.683-21.822-26.072-16.451-20.746-33.456-42.22-54.837-52.69-15.46-7.574-27.043-7.687-40.441-7.821-6.588-.074-13.398-.134-21.484-1.021-10.165-1.111-19.381-5.448-28.273-9.65-10.108-4.773-20.572-9.706-32.456-9.891-12.737-.2-18.051 4.74-22.757 9.09-5.054 4.68-9.822 9.097-23.027 6.672-17.457-3.18-30.705-17.146-43.517-30.662-5.148-5.428-10.019-10.55-15.018-14.827-8.665-7.41-18.481-13.27-27.964-18.918-12.881-7.676-26.201-15.622-36.835-27.592-21.246-23.992-33.203-55.904-44.515-87.042l-.78.871c11.284 31.024 23.248 62.78 44.488 86.722 10.734 12.092 24.143 20.085 37.086 27.808 9.439 5.638 19.194 11.45 27.809 18.798 4.953 4.242 9.808 9.352 14.925 14.756 6.616 6.978 13.344 14.064 20.729 19.772 7.061 5.457 14.71 9.641 23.431 11.225 13.76 2.509 18.716-2.062 23.956-6.92 4.78-4.431 9.737-9.002 22.012-8.814 11.621.188 21.979 5.062 31.982 9.778 8.994 4.234 18.287 8.63 28.649 9.756 8.158.895 14.983.968 21.598 1.04 13.26.142 24.716.25 39.939 7.71 21.143 10.355 38.08 31.73 54.45 52.39 7.459 9.401 14.488 18.286 21.872 26.133 14.658 15.61 30.816 29.66 46.44 43.255 8.511 7.406 17.119 14.909 25.572 22.731l.658-.735c-8.423-7.797-17.03-15.3-25.522-22.67z"
        fill="url(#paint30_linear_1242_1858)"
      />
      <path
        d="M589.721 79.136c-15.9-13.461-32.346-27.367-47.285-42.78-7.639-7.882-14.928-16.83-22.661-26.304-16.859-20.671-34.325-42.04-55.961-52.431-15.78-7.568-27.942-7.853-42.033-8.178-6.725-.156-13.686-.31-21.807-1.246-10.313-1.18-19.566-5.592-28.523-9.867-10.056-4.803-20.452-9.752-32.274-10.096-12.828-.361-18.283 4.561-23.084 8.93-5.302 4.81-9.894 8.972-22.817 6.465-17.126-3.314-29.86-17.298-42.16-30.831-5.003-5.501-9.73-10.697-14.625-15.032-8.342-7.39-17.857-13.178-27.041-18.779-12.452-7.598-25.313-15.442-35.463-27.338-20.26-23.69-31.383-54.865-41.81-85.411l-.804.898c10.399 30.432 21.541 61.438 41.743 85.106 10.265 12.03 23.231 19.933 35.742 27.553 9.153 5.577 18.609 11.343 26.886 18.66 4.86 4.285 9.556 9.457 14.544 14.946 6.694 7.359 13.517 14.865 21.12 20.74 6.503 5.026 13.592 8.87 21.67 10.44 13.466 2.604 18.457-1.917 23.747-6.714 4.89-4.437 9.945-9.001 22.339-8.653 11.597.331 21.876 5.236 31.799 9.982 9.047 4.321 18.405 8.791 28.9 9.973 8.176.933 15.153 1.1 21.905 1.254 13.937.321 25.989.612 41.531 8.066 21.426 10.274 38.795 31.546 55.586 52.118 7.749 9.486 15.068 18.458 22.738 26.364 14.974 15.462 31.45 29.392 47.366 42.866 9.045 7.657 18.23 15.423 27.224 23.548l.658-.735c-8.951-8.114-18.12-15.868-27.15-23.514z"
        fill="url(#paint31_linear_1242_1858)"
      />
      <path
        d="M592.831 73.585c-16.256-13.414-33.058-27.274-48.412-42.661-7.764-7.773-15.301-16.267-23.264-25.252-17.667-19.914-35.941-40.527-57.153-53.352-14.409-8.719-29.077-8.868-44.581-9.019-6.936-.066-14.096-.144-21.276-1.02-10.468-1.3-19.747-5.8-28.713-10.152-9.962-4.821-20.266-9.815-32.067-10.327-12.95-.548-18.546 4.357-23.477 8.695-5.395 4.738-10.052 8.827-22.681 6.226-16.752-3.44-28.926-17.406-40.693-30.919-4.875-5.586-9.477-10.892-14.28-15.271-8.044-7.344-17.272-13.071-26.169-18.612-12.033-7.481-24.49-15.22-34.216-27.064-19.19-23.392-29.534-53.828-39.086-83.744l-.804.898c9.523 29.802 19.896 60.029 39.055 83.398 9.841 11.978 22.36 19.766 34.483 27.293 8.878 5.503 18.032 11.197 26.014 18.492 4.756 4.344 9.339 9.612 14.199 15.186 6.712 7.719 13.582 15.583 21.37 21.602 5.977 4.619 12.51 8.148 19.957 9.691 13.171 2.698 18.243-1.737 23.598-6.46 5.032-4.421 10.213-8.98 22.729-8.445 11.562.488 21.288 5.22 31.592 10.213 9.057 4.398 18.453 8.944 29.09 10.259 7.266.896 14.454.973 21.417 1.038 15.351.147 29.837.294 43.987 8.835 21.06 12.73 39.268 33.27 56.886 53.123 7.994 9.009 15.53 17.503 23.313 25.313 15.374 15.425 32.206 29.308 48.478 42.735 9.487 7.838 19.113 15.783 28.552 24.114l.658-.735c-9.408-8.306-19.006-16.254-28.506-24.078z"
        fill="url(#paint32_linear_1242_1858)"
      />
      <path
        d="M595.987 68.07c-16.597-13.356-33.755-27.168-49.481-42.52-8.039-7.847-15.872-16.479-24.143-25.587-17.928-19.77-36.502-40.223-57.927-52.868-14.753-8.686-29.863-9.015-45.852-9.356-7.182-.142-14.605-.308-22.034-1.285-10.592-1.395-19.899-6.01-28.895-10.476-9.857-4.879-20.03-9.932-31.756-10.616-13.074-.759-18.871 4.107-23.959 8.415-5.491 4.64-10.256 8.646-22.594 5.924-15.926-3.514-27.108-16.92-37.941-29.896-5.111-6.113-9.926-11.883-15.181-16.635-8.049-7.279-16.746-12.78-25.153-18.08-12.158-7.693-23.645-14.935-33.228-27.083-18.201-23.065-27.815-52.707-36.53-81.976l-.816.911c8.699 29.142 18.302 58.591 36.452 81.594 9.702 12.309 21.267 19.611 33.514 27.35 8.364 5.289 17.014 10.755 24.998 17.96 5.205 4.691 9.99 10.437 15.069 16.526 6.448 7.721 13.013 15.603 20.662 21.515 5.342 4.128 11.202 7.298 17.901 8.77 12.883 2.844 18.063-1.508 23.542-6.136 5.205-4.379 10.571-8.91 23.211-8.164 11.502.672 21.109 5.45 31.309 10.5 9.086 4.513 18.483 9.174 29.271 10.584 7.501.985 14.951 1.15 22.176 1.302 15.836.337 30.803.649 45.272 9.184 21.302 12.549 39.783 32.93 57.676 52.651 8.29 9.146 16.124 17.777 24.193 25.648 15.757 15.376 32.946 29.213 49.575 42.593 9.925 7.992 20.004 16.104 29.857 24.616l.658-.735c-9.856-8.537-19.921-16.637-29.846-24.63z"
        fill="url(#paint33_linear_1242_1858)"
      />
      <path
        d="M599.206 62.603c-16.926-13.31-34.441-27.076-50.536-42.369-8.34-7.918-16.439-16.663-24.994-25.922-18.145-19.615-36.932-39.889-58.553-52.316-15.07-8.654-30.605-9.15-47.091-9.669-7.472-.227-15.2-.491-22.879-1.57-10.676-1.506-19.972-6.25-28.969-10.832-9.729-4.963-19.797-10.074-31.483-10.98-13.205-1.021-19.214 3.818-24.518 8.074-5.659 4.535-10.532 8.457-22.613 5.565-15.475-3.696-26.056-16.983-36.289-29.84-4.977-6.262-9.686-12.18-14.926-17.036-7.825-7.267-16.272-12.667-24.459-17.889-11.788-7.521-22.907-14.618-32.1-26.764-17.142-22.638-26.026-51.485-33.946-80.093l-.828.925c7.9 28.454 16.801 57.107 33.939 79.72 9.324 12.292 20.521 19.449 32.386 27.03 8.14 5.187 16.529 10.565 24.288 17.758 5.163 4.796 9.853 10.676 14.799 16.915 6.358 7.997 12.846 16.142 20.588 22.125 4.862 3.757 10.211 6.648 16.323 8.103 12.611 3.002 17.93-1.243 23.549-5.762 5.404-4.34 10.986-8.819 23.767-7.85 11.445.882 20.948 5.717 31.02 10.853 9.086 4.628 18.472 9.419 29.345 10.94 7.723 1.088 15.49 1.337 23.006 1.574 16.32.528 31.741 1.006 46.542 9.522 21.469 12.333 40.179 32.546 58.274 52.1 8.586 9.283 16.685 18.028 25.056 25.97 16.142 15.328 33.672 29.106 50.613 42.428 10.348 8.135 20.835 16.378 31.113 25.058l.658-.735c-10.248-8.656-20.735-16.898-31.082-25.033z"
        fill="url(#paint34_linear_1242_1858)"
      />
      <path
        d="M602.486 57.184c-17.255-13.265-35.095-26.96-51.531-42.194-8.614-7.992-16.979-16.85-25.831-26.246-18.315-19.425-37.261-39.522-58.977-51.724-15.328-8.6-31.317-9.263-48.248-9.987-7.805-.324-15.878-.67-23.823-1.862-10.704-1.62-19.99-6.494-28.947-11.207-9.616-5.06-19.559-10.282-31.161-11.398-13.337-1.285-19.608 3.467-25.159 7.648-5.841 4.417-10.869 8.219-22.706 5.17-14.996-3.878-24.93-17.01-34.544-29.71-4.873-6.437-9.479-12.528-14.721-17.5-7.627-7.252-15.864-12.536-23.829-17.656-11.429-7.336-22.221-14.272-31.079-26.412-16.213-22.242-24.407-50.28-31.579-78.172l-.84.939c7.152 27.738 15.375 55.568 31.553 77.761 8.98 12.326 19.861 19.308 31.38 26.691 7.919 5.083 16.097 10.345 23.659 17.524 5.164 4.912 9.752 10.966 14.594 17.378 6.218 8.212 12.573 16.622 20.362 22.64 4.397 3.4 9.255 6.049 14.792 7.473 12.357 3.197 17.828-.953 23.645-5.343 5.651-4.263 11.492-8.68 24.404-7.448 11.389 1.09 20.779 6.022 30.726 11.27 9.047 4.758 18.422 9.679 29.327 11.339 8.004 1.214 16.089 1.546 23.937 1.88 16.789.707 32.624 1.366 47.71 9.826 21.566 12.108 40.462 32.143 58.7 51.509 8.867 9.407 17.235 18.29 25.88 26.307 16.483 15.27 34.341 29.002 51.611 42.28 10.74 8.253 21.649 16.638 32.311 25.475l.657-.734c-10.625-8.879-21.534-17.264-32.273-25.517z"
        fill="url(#paint35_linear_1242_1858)"
      />
      <path
        d="M605.857 51.837c-17.552-13.196-35.709-26.859-52.501-42.046-8.79-7.943-17.212-17.145-26.15-26.884-18.141-19.796-36.896-40.252-59.449-50.408-17.107-7.696-32.405-8.838-48.561-10.046-8.193-.622-16.665-1.254-25.861-2.745-10.749-1.747-19.974-6.805-28.897-11.674-9.389-5.139-19.105-10.438-30.518-11.823-13.435-1.614-20.11 3.033-25.979 7.13-6.149 4.293-11.455 7.995-23.054 4.74-14.306-4.035-23.537-17.016-32.475-29.563-4.822-6.787-9.4-13.202-14.717-18.324-7.591-7.293-15.632-12.357-23.429-17.254-11.06-6.959-21.525-13.528-30.142-25.85-15.264-21.808-22.812-49.047-29.248-76.21l-.853.952c6.429 26.996 13.99 54.015 29.232 75.761 8.75 12.493 19.294 19.122 30.474 26.151 7.738 4.876 15.733 9.903 23.243 17.111 5.252 5.048 9.784 11.428 14.586 18.177 6.049 8.519 12.246 17.221 20.097 23.288 3.871 2.992 8.133 5.342 12.992 6.703 12.092 3.406 17.871-.62 23.969-4.885 6.009-4.195 12.21-8.518 25.221-6.956 11.185 1.347 20.784 6.602 30.095 11.68 9 4.93 18.327 10.02 29.276 11.807 9.24 1.5 17.755 2.143 25.959 2.752 16.074 1.213 31.258 2.336 48.173 9.953 22.344 10.04 41.006 30.424 59.078 50.12 8.941 9.765 17.391 18.965 26.215 26.959 16.827 15.236 35.01 28.897 52.594 42.117 11.1 8.348 22.37 16.828 33.423 25.784l.658-.735c-11.066-8.942-22.336-17.422-33.451-25.782z"
        fill="url(#paint36_linear_1242_1858)"
      />
      <path
        d="M609.434 46.672c-17.888-13.202-36.407-26.87-53.516-42.024-8.94-7.922-17.435-16.927-26.402-26.457-17.884-19-36.383-38.66-58.709-48.893-18.18-8.341-34.472-9.953-51.71-11.65-8.301-.823-16.871-1.668-26.112-3.285-10.753-1.889-19.749-7.115-28.418-12.18-8.937-5.203-18.162-10.583-29.264-12.258-10.39-1.561-18.478 2.293-26.302 6.029-8.036 3.825-15.625 7.444-24.949 4.75-14.492-4.18-23.033-17.755-31.322-30.882-4.14-6.583-8.436-13.401-13.364-18.752-7.214-7.831-15.739-12.647-23.968-17.325-10.256-5.809-20.858-11.816-28.905-23.72-14.523-21.466-21.277-47.953-26.971-74.336l-.865.966c5.671 26.204 12.466 52.469 26.911 73.876 8.172 12.116 18.908 18.179 29.284 24.058 8.151 4.619 16.602 9.4 23.701 17.096 4.864 5.278 8.947 11.748 13.246 18.592 5.89 9.339 11.905 18.89 20.127 25.244 3.499 2.704 7.384 4.832 11.827 6.102 9.759 2.823 17.531-.884 25.747-4.823 7.681-3.663 15.609-7.456 25.661-5.926 10.889 1.649 20.021 6.957 28.854 12.102 8.758 5.112 17.832 10.397 28.813 12.324 9.284 1.627 17.896 2.483 26.21 3.291 17.139 1.69 33.333 3.296 51.333 11.544 22.12 10.143 40.538 29.717 58.357 48.643 8.998 9.555 17.496 18.585 26.483 26.543 17.17 15.203 35.69 28.87 53.608 42.096 11.387 8.409 22.971 16.947 34.283 25.965l.658-.735c-11.34-9.016-22.912-17.568-34.326-25.975z"
        fill="url(#paint37_linear_1242_1858)"
      />
      <path
        d="M613.178 41.613c-18.242-13.245-37.112-26.93-54.564-42.053-8.914-7.717-17.395-16.503-26.375-25.814-17.765-18.402-36.154-37.424-57.994-47.557-19.496-9.036-37.439-11.233-56.444-13.56-7.959-.972-16.173-1.98-24.776-3.519-10.8-1.924-19.439-7.403-27.802-12.692-8.425-5.338-17.126-10.865-28.076-12.767-10.651-1.856-19.306 1.813-27.698 5.364-8.524 3.609-16.585 7.024-25.941 4.19-14.114-4.279-21.853-18.108-29.338-31.488-3.916-7.009-7.972-14.241-12.858-19.904-7.224-8.345-15.939-12.686-24.373-16.902-9.968-4.988-20.293-10.135-28.013-21.994-13.823-21.225-19.557-47.154-24.43-72.786l-.877.979c4.862 25.44 10.634 51.122 24.407 72.285 7.876 12.095 18.337 17.324 28.454 22.381 8.328 4.158 16.941 8.466 24.017 16.627 4.832 5.576 8.842 12.772 12.736 19.717 5.563 9.938 11.254 20.114 19.631 26.588 3.05 2.357 6.456 4.23 10.357 5.403 9.748 2.952 17.996-.525 26.715-4.236 8.233-3.49 16.756-7.099 27.066-5.301 10.71 1.878 19.317 7.334 27.65 12.599 8.456 5.36 17.184 10.886 28.227 12.86 8.647 1.55 16.888 2.556 24.847 3.528 18.919 2.306 36.792 4.494 56.096 13.451 21.661 10.042 39.957 28.992 57.656 47.32 8.996 9.323 17.492 18.121 26.453 25.874 17.471 15.16 36.357 28.857 54.642 42.113 11.626 8.433 23.439 17.009 35.003 26.038l.658-.734c-11.601-8.989-23.414-17.565-35.056-26.01z"
        fill="url(#paint38_linear_1242_1858)"
      />
      <path
        d="M617.508 37.19c-18.462-13.118-37.553-26.674-55.238-41.653-9.204-7.804-17.963-16.484-27.219-25.662-17.538-17.398-35.653-35.38-56.897-46.618-19.616-10.394-38.757-12.804-59.004-15.354-8.261-1.044-16.833-2.12-25.37-3.792-10.724-2.096-19.014-7.789-27.056-13.291-8.058-5.513-16.375-11.205-27.153-13.388-10.946-2.199-20.117 1.256-28.978 4.628-8.984 3.392-17.474 6.613-26.971 3.555-13.885-4.447-20.875-18.526-27.652-32.142-3.658-7.385-7.46-15.018-12.311-20.953-6.921-8.48-15.959-12.84-24.716-17.076-9.872-4.775-20.083-9.697-27.153-20.615-12.885-19.902-18.437-44.747-22.915-69.591l-.889.993c4.455 24.665 10.057 49.25 22.905 69.076 7.24 11.166 17.603 16.183 27.608 21.014 8.636 4.165 17.556 8.481 24.341 16.762 4.767 5.825 8.534 13.409 12.174 20.756 5.198 10.462 10.532 21.213 19.063 27.807 2.726 2.106 5.773 3.793 9.234 4.902 9.888 3.177 18.565-.107 27.72-3.574 8.718-3.299 17.726-6.718 28.358-4.578 10.562 2.132 18.774 7.765 26.739 13.207 8.135 5.573 16.53 11.324 27.498 13.47 8.58 1.682 17.152 2.76 25.456 3.814 20.148 2.542 39.163 4.946 58.612 15.235 21.08 11.158 39.161 29.09 56.622 46.427 8.871 8.79 18.034 17.897 27.269 25.724 17.704 15.017 36.81 28.585 55.315 41.712 11.662 8.276 23.479 16.672 35.064 25.532l.657-.734c-11.603-8.898-23.435-17.306-35.113-25.594z"
        fill="url(#paint39_linear_1242_1858)"
      />
      <path
        d="M622.986 33.91c-18.731-12.933-38.09-26.306-55.956-41.172-9.605-7.999-19.094-17.357-28.278-26.411-17.332-17.1-35.238-34.76-56.177-45.9-20.281-10.794-41.28-13.879-61.599-16.876-8.354-1.231-17.02-2.496-25.586-4.282-10.815-2.258-18.833-8.225-26.577-14.002-7.635-5.693-15.533-11.59-26.177-14.038-11.206-2.583-20.824.64-30.121 3.767-9.404 3.16-18.275 6.133-28.035 2.872-13.955-4.662-20.225-18.991-26.293-32.842-3.397-7.735-6.898-15.734-11.695-21.904-6.87-8.831-15.63-12.448-24.113-15.965-10.467-4.314-20.36-8.392-27.189-21.541-10.638-20.421-15.87-43.868-20.306-67.092l-.877.98c4.409 23.019 9.663 46.207 20.215 66.491 7.025 13.486 17.566 17.834 27.756 22.049 8.347 3.435 16.962 7.009 23.648 15.606 4.729 6.071 8.208 14.007 11.57 21.693 4.785 10.925 9.689 22.126 18.391 28.851a29.115 29.115 0 008.566 4.595c10.124 3.381 19.182.346 28.761-2.863 9.149-3.08 18.609-6.242 29.497-3.744 10.401 2.399 18.19 8.212 25.748 13.845 7.836 5.849 15.963 11.9 27.037 14.22 8.609 1.796 17.263 3.075 25.645 4.305 21.141 3.102 41.102 6.03 61.219 16.742 20.775 11.06 38.634 28.684 55.901 45.71 9.2 9.067 18.719 18.45 28.356 26.472 17.912 14.901 37.303 28.298 56.049 41.244 11.264 7.784 22.696 15.675 33.915 23.977l.658-.735c-11.226-8.353-22.661-16.27-33.953-24.052z"
        fill="url(#paint40_linear_1242_1858)"
      />
      <path
        d="M628.637 30.671C609.665 17.92 590.039 4.755 571.99-10.022c-9.88-8.073-19.541-17.794-28.894-27.187-17.09-17.166-34.757-34.916-55.641-45.094-21.45-10.454-42.357-14.228-64.502-18.198-8.277-1.494-16.837-3.023-25.419-4.936-10.937-2.445-18.593-8.729-25.994-14.771-7.219-5.924-14.682-12.014-25.209-14.741-11.492-2.965-21.577-.01-31.341 2.848-9.843 2.89-19.15 5.618-29.176 2.128-14.099-4.911-19.717-19.588-25.177-33.797-3.082-7.998-6.24-16.263-10.895-22.622-6.349-8.681-14.827-13.461-25.124-14.191-11.474-2.284-20.185-10.052-25.911-23.086-8.536-19.463-12.926-41.569-17.413-64.97l-.877.979c4.439 23.134 8.838 44.994 17.33 64.331 5.868 13.374 14.858 21.357 26.803 23.729 10.001.709 18.196 5.338 24.354 13.734 4.571 6.249 7.735 14.448 10.767 22.385 4.377 11.439 8.889 23.166 17.839 30.083a28.539 28.539 0 008.004 4.344c10.363 3.612 19.844.835 29.863-2.103 9.616-2.811 19.551-5.745 30.741-2.851 10.28 2.65 17.639 8.682 24.761 14.509 7.513 6.151 15.262 12.507 26.485 15.012 8.598 1.926 17.173 3.466 25.478 4.959 22.047 3.963 42.879 7.703 64.181 18.088 20.692 10.099 38.294 27.776 55.319 44.869 9.369 9.404 19.06 19.15 28.987 27.258 18.108 14.8 37.737 27.99 56.752 40.752 10.82 7.256 21.767 14.634 32.579 22.368l.657-.734c-10.867-7.731-21.841-15.108-32.68-22.402z"
        fill="url(#paint41_linear_1242_1858)"
      />
      <path
        d="M634.465 27.5c-19.211-12.544-39.093-25.538-57.28-40.192-10.204-8.208-20.12-18.172-29.715-27.82-16.755-16.839-34.072-34.25-54.528-44.35-21.914-10.813-43.585-15.108-66.525-19.624-8.525-1.685-17.349-3.417-26.096-5.528-11.046-2.644-18.292-9.21-25.303-15.572-6.775-6.156-13.77-12.506-24.235-15.506-11.784-3.399-22.392-.71-32.651 1.882-10.325 2.608-20.068 5.092-30.352 1.334-14.073-5.145-19.012-19.965-23.791-34.291-2.803-8.428-5.71-17.119-10.432-23.784-6.288-8.865-14.845-13.705-25.365-14.332-11.451-2.104-20.09-9.748-25.696-22.712-8.004-18.477-12.035-39.316-16.07-61.378l-.889.993c3.987 21.795 8.024 42.362 15.957 60.715 5.746 13.304 14.68 21.175 26.602 23.367 10.213.619 18.482 5.283 24.552 13.865 4.638 6.553 7.508 15.17 10.301 23.52 3.903 11.764 7.933 23.855 17.022 30.879a27.262 27.262 0 007.427 4.082c10.62 3.88 20.541 1.373 31.041-1.284 10.108-2.57 20.569-5.212 32.064-1.899 10.206 2.938 17.093 9.204 23.771 15.263 7.107 6.459 14.477 13.122 25.813 15.851 8.775 2.109 17.614 3.853 26.151 5.525 22.869 4.507 44.454 8.782 66.22 19.526 20.277 10.008 37.544 27.357 54.234 44.122 9.611 9.66 19.557 19.649 29.807 27.892 18.277 14.7 38.144 27.683 57.385 40.251 10.32 6.733 20.752 13.574 31.042 20.72l.657-.734c-10.32-7.17-20.795-14.022-31.118-20.78z"
        fill="url(#paint42_linear_1242_1858)"
      />
      <path
        d="M640.449 24.359C621.027 12.019 600.935-.77 582.61-15.298c-10.497-8.32-20.627-18.542-30.421-28.437-16.349-16.501-33.242-33.538-53.211-43.538-22.234-11.13-45.526-16.101-68.06-20.925-8.918-1.92-18.134-3.886-27.215-6.209-11.174-2.881-17.655-9.454-24.52-16.417-6.291-6.404-12.818-13.014-23.255-16.338-12.137-3.855-23.27-1.456-34.066.858-10.808 2.328-21.033 4.531-31.621.468-14.063-5.39-18.318-20.302-22.411-34.721-2.524-8.856-5.131-18.029-9.994-25.033-6.288-9.071-14.973-13.942-25.773-14.462-11.495-1.909-20.118-9.425-25.631-22.317-7.476-17.517-11.203-37.085-14.814-57.807l-.889.993c3.59 20.453 7.312 39.764 14.74 57.129 5.686 13.255 14.587 20.986 26.566 22.97 10.464.514 18.862 5.208 24.944 13.983 4.764 6.881 7.358 15.952 9.86 24.745 3.438 12.048 6.947 24.428 16.191 31.572 2.013 1.556 4.285 2.851 6.896 3.856 10.909 4.172 21.285 1.947 32.271-.404 10.633-2.279 21.643-4.657 33.475-.9 10.159 3.225 16.577 9.75 22.785 16.043 6.672 6.769 13.556 13.769 25.061 16.721 9.096 2.334 18.328 4.313 27.258 6.219 22.448 4.803 45.685 9.778 67.77 20.839 19.79 9.907 36.62 26.896 52.904 43.325 9.81 9.906 19.971 20.152 30.518 28.533 18.374 14.591 38.479 27.366 57.931 39.73 9.75 6.199 19.62 12.468 29.36 19.029l.658-.735c-9.787-6.596-19.688-12.89-29.468-19.113z"
        fill="url(#paint43_linear_1242_1858)"
      />
      <path
        d="M646.593 21.274c-19.589-12.123-39.858-24.657-58.295-39.09-10.949-8.576-21.462-19.072-31.625-29.228-15.734-15.727-32.01-31.988-50.997-42.472-21.613-11.939-44.814-16.955-67.229-21.825-10.11-2.197-20.578-4.463-30.62-7.321-11.375-3.243-17.597-10.192-24.208-17.557-5.843-6.495-11.854-13.212-21.677-16.959-11.831-4.517-23.826-2.416-35.424-.376-11.57 2.038-22.514 3.962-33.101-.307-14.013-5.651-17.435-19.942-21.067-35.11-2.24-9.35-4.547-19.005-9.619-26.332-6.652-9.629-15.979-12.878-24.996-16.003-10.668-3.71-20.766-7.209-27.109-20.49-8.059-16.954-11.631-35.321-14.438-53.488l-.913 1.021c2.789 17.923 6.387 36.056 14.394 52.833 6.535 13.682 17.321 17.437 27.754 21.058 8.83 3.071 17.965 6.241 24.427 15.585 4.954 7.167 7.251 16.745 9.465 26.006 2.942 12.31 5.983 24.949 15.367 32.201 1.858 1.436 3.966 2.651 6.365 3.63 10.896 4.392 22.494 2.352 33.723.373 11.435-2.004 23.279-4.084 34.802.31 9.573 3.646 15.5 10.252 21.235 16.663 6.43 7.156 13.059 14.559 24.751 17.886 10.058 2.87 20.542 5.148 30.68 7.343 22.356 4.848 45.458 9.857 66.92 21.701 18.851 10.402 35.065 26.616 50.749 42.281 10.194 10.18 20.71 20.701 31.705 29.314C606.096-2.61 626.369 9.949 646 22.082c9.12 5.644 18.361 11.358 27.509 17.323l.657-.735c-9.138-6.004-18.41-11.742-27.573-17.396z"
        fill="url(#paint44_linear_1242_1858)"
      />
      <path
        d="M652.918 18.191C633.285 6.38 613.016-5.832 594.636-20.036l-.341-.264c-11.183-8.665-21.8-19.426-32.067-29.846-15.159-15.375-30.841-31.246-49.174-41.524-21.68-12.128-45.173-17.578-67.918-22.841-10.762-2.493-21.869-5.069-32.419-8.297-11.522-3.517-17.2-10.782-23.222-18.452-5.327-6.81-10.833-13.827-20.714-17.919-12.163-5.026-24.784-3.248-36.994-1.52-12.142 1.711-23.6 3.33-34.482-1.282-13.994-5.935-16.848-20.363-19.879-35.618-1.932-9.781-3.936-19.892-9.171-27.505-6.712-9.768-16.27-12.874-25.524-15.882-10.913-3.553-21.215-6.912-27.596-20.268-7.616-15.898-11.001-33.039-13.574-50.035l-.913 1.02c2.555 16.752 5.978 33.646 13.487 49.371 6.588 13.769 17.618 17.367 28.269 20.834 9.066 2.954 18.436 6.007 24.939 15.452 5.129 7.439 7.092 17.45 9.011 27.128 2.493 12.607 5.057 25.545 14.657 32.964a26.054 26.054 0 005.877 3.414c11.179 4.75 23.349 3.037 35.092 1.362 12.059-1.706 24.515-3.474 36.384 1.44 9.616 3.979 15.026 10.899 20.265 17.572 5.858 7.473 11.903 15.206 23.773 18.832 10.593 3.238 21.716 5.826 32.506 8.318 22.658 5.241 46.08 10.683 67.597 22.73 18.209 10.182 33.817 26.02 48.926 41.333 10.298 10.444 20.946 21.228 32.191 29.942l.341.263c18.426 14.24 38.754 26.475 58.402 38.298 8.448 5.078 17.004 10.24 25.472 15.588l.658-.735c-8.512-5.358-17.083-10.532-25.577-15.646z"
        fill="url(#paint45_linear_1242_1858)"
      />
      <path
        d="M656.769 12.69C637.665 1.84 617.915-9.371 600.57-22.683a96.112 96.112 0 00-.31-.24c-11.257-8.7-21.811-19.618-32.027-30.184-14.509-14.987-29.503-30.488-47.166-40.548-21.617-12.286-45.314-18.123-68.231-23.749-11.488-2.824-23.349-5.729-34.5-9.398-11.703-3.842-16.762-11.389-22.129-19.381-4.782-7.125-9.719-14.485-19.733-18.956-12.515-5.597-25.819-4.14-38.656-2.735-12.715 1.384-24.74 2.702-35.925-2.305-13.979-6.245-16.27-20.745-18.714-36.1-1.614-10.224-3.302-20.805-8.77-28.714-6.86-9.836-16.725-12.834-26.255-15.709-11.213-3.395-21.815-6.593-28.308-20.059-7.172-14.843-10.406-30.807-12.802-46.654l-.913 1.02c2.394 15.615 5.649 31.321 12.743 45.988 6.715 13.89 18.03 17.317 28.997 20.636 9.341 2.822 19.002 5.755 25.617 15.309 5.347 7.723 7.006 18.19 8.619 28.298 2.037 12.853 4.151 26.089 13.953 33.664a26.174 26.174 0 005.404 3.21c11.493 5.13 24.22 3.732 36.522 2.398 12.699-1.396 25.825-2.831 38.059 2.642 9.718 4.333 14.568 11.557 19.25 18.559 5.23 7.794 10.647 15.848 22.714 19.81 11.194 3.679 23.086 6.608 34.586 9.419 22.846 5.617 46.453 11.408 67.922 23.626 17.512 9.966 32.456 25.404 46.903 40.345 10.343 10.686 20.994 21.702 32.461 30.519 17.395 13.374 37.188 24.596 56.323 35.47 8.859 5.028 17.812 10.128 26.628 15.469l.657-.735c-8.807-5.38-17.818-10.502-26.72-15.54z"
        fill="url(#paint46_linear_1242_1858)"
      />
      <path
        d="M663.364 9.657c-19.138-10.578-38.935-21.503-56.193-34.68-11.458-8.739-22.101-20.026-32.373-30.91-13.78-14.632-28.025-29.739-44.973-39.545-21.468-12.424-45.232-18.567-68.217-24.498-12.848-3.323-25.003-6.456-36.805-10.627-11.903-4.204-16.315-12.034-20.969-20.326-4.197-7.456-8.526-15.175-18.734-20.072-13.02-6.263-26.755-5.231-40.02-4.226-12.506.946-25.443 1.906-37.877-3.168-14.742-6.007-16.892-22.861-18.969-39.152-1.339-10.473-2.602-20.357-7.041-27.31-6.622-10.366-16.987-13.127-27.022-15.818-11.132-2.987-22.638-6.055-29.322-19.6-6.795-13.86-9.935-28.671-12.189-43.418l-.913 1.02c2.251 14.516 5.429 29.08 12.158 42.751 6.882 13.996 18.662 17.138 30.038 20.175 10.266 2.755 19.953 5.338 26.347 15.343 4.317 6.766 5.567 16.549 6.893 26.919 1.821 14.206 3.684 28.835 13.811 36.661a24.483 24.483 0 005.824 3.327c12.683 5.176 25.77 4.195 38.403 3.253 13.735-1.032 26.684-2.005 39.434 4.12 9.869 4.749 14.135 12.304 18.245 19.624 4.559 8.104 9.266 16.484 21.576 20.818 11.845 4.182 24.015 7.326 36.907 10.66 22.914 5.922 46.592 12.044 67.908 24.375 16.784 9.725 30.98 24.77 44.71 39.34 10.098 10.727 20.546 21.794 31.881 30.554l.619.479c17.32 13.224 37.148 24.173 56.329 34.762 8.076 4.469 16.243 8.984 24.309 13.699l.67-.748c-8.098-4.739-16.31-9.29-24.445-13.782z"
        fill="url(#paint47_linear_1242_1858)"
      />
      <path
        d="M673.157 9.347c-20.005-11.132-40.687-22.65-59.065-36.623-11.619-8.795-22.021-20.264-32.122-31.339-12.993-14.253-26.439-28.995-42.616-38.55-21.216-12.53-44.947-18.946-67.881-25.137-13.778-3.72-26.777-7.228-39.294-11.975-12.122-4.603-15.845-12.706-19.741-21.288-3.573-7.802-7.25-15.869-17.675-21.256-13.441-6.934-27.906-6.258-41.912-5.596-13.078.62-26.614 1.254-39.4-4.276-14.644-6.322-16.236-23.068-17.799-39.261-1.052-11.056-2.04-21.511-6.914-28.914-7.012-10.691-17.827-13.272-28.27-15.771-11.463-2.736-23.313-5.564-30.251-19.236-6.54-12.857-9.624-26.59-11.783-40.274l-.901 1.007c2.156 13.452 5.266 26.951 11.715 39.647 7.163 14.122 19.289 17.024 30.995 19.809 10.701 2.562 20.811 4.965 27.58 15.284 4.721 7.193 5.708 17.532 6.759 28.473 1.374 14.297 2.772 29.006 13.054 36.952 1.58 1.22 3.361 2.275 5.398 3.159 13.036 5.631 26.711 4.989 39.927 4.361 14.492-.678 28.167-1.32 41.323 5.464 10.095 5.2 13.696 13.116 17.178 20.756 3.831 8.393 7.811 17.086 20.372 21.844 12.56 4.758 25.601 8.276 39.395 12.007 22.876 6.169 46.52 12.564 67.6 25.012 16.041 9.473 29.41 24.155 42.353 38.347 9.924 10.892 20.186 22.138 31.536 30.91l.728.562c18.441 14.021 39.165 25.55 59.201 36.706 5.978 3.33 11.999 6.672 18.006 10.117l.658-.735c-6.05-3.455-12.133-6.844-18.154-10.186z"
        fill="url(#paint48_linear_1242_1858)"
      />
      <path
        d="M677.002 3.592c-19.029-9.94-38.712-20.203-55.62-33.04-11.544-8.76-21.764-20.433-31.611-31.704-12.138-13.892-24.698-28.248-40.088-37.541-20.895-12.626-44.498-19.243-67.337-25.659-14.738-4.14-28.65-8.031-41.865-13.433-12.27-4.994-15.128-13.303-18.159-22.091-2.851-8.257-5.794-16.793-16.904-22.732-14.37-7.652-29.502-7.284-44.133-6.92-13.576.327-27.622.682-40.718-5.526-14.208-6.721-15.364-23.222-16.506-39.182-.793-11.202-1.619-22.774-7.02-30.7-7.486-11.011-18.804-13.382-29.736-15.685-12.394-2.603-24.1-5.066-31.402-18.858-6.338-11.942-9.413-24.631-11.513-37.327l-.902 1.007c2.114 12.476 5.203 24.945 11.419 36.701 7.53 14.268 20.065 16.888 32.172 19.431 11.204 2.352 21.792 4.572 29.023 15.224 5.248 7.716 6.058 19.161 6.845 30.221 1.021 14.37 2.07 29.176 12.506 37.242a24.263 24.263 0 004.614 2.806c13.362 6.321 27.55 5.983 41.249 5.636 14.492-.356 29.47-.727 43.556 6.775 10.748 5.728 13.609 14.062 16.394 22.13 2.968 8.624 6.043 17.539 18.77 22.725 13.286 5.41 27.229 9.326 41.995 13.463 22.765 6.383 46.297 12.99 67.04 25.522 15.243 9.225 27.737 23.508 39.81 37.326 9.893 11.307 20.16 23.016 31.785 31.862 16.985 12.896 36.715 23.195 55.787 33.146 6.396 3.332 12.839 6.7 19.227 10.187l.682-.762c-6.416-3.486-12.917-6.876-19.36-10.244z"
        fill="url(#paint49_linear_1242_1858)"
      />
      <path
        d="M684.247.742c-18.989-9.634-38.618-19.578-55.229-32.277l-.356-.275c-10.747-8.306-20.466-19.983-29.871-31.28-11.649-13.998-23.709-28.475-37.979-36.971-20.099-11.965-43.277-18.851-65.703-25.524-16.048-4.783-31.196-9.285-45.397-15.518-12.545-5.506-14.639-14.053-16.839-23.096-2.101-8.599-4.269-17.502-15.754-24.076-14.854-8.487-30.782-8.459-46.21-8.434-14.188.015-28.861.047-42.39-6.702-14.156-7.073-14.827-23.384-15.461-39.181-.477-11.787-.977-23.959-7.007-32.44-8.07-11.325-19.934-13.495-31.409-15.573-12.89-2.342-25.052-4.559-32.792-18.506-6.134-11.116-9.197-22.853-11.274-34.61l-.889.994c2.078 11.55 5.17 23.079 11.238 34.006 7.996 14.421 20.999 16.782 33.575 19.065 11.762 2.138 22.895 4.159 30.694 15.112 5.875 8.246 6.358 20.291 6.831 31.936.574 14.371 1.177 29.178 11.831 37.411a26.197 26.197 0 004.216 2.637c13.781 6.876 28.597 6.862 42.923 6.838 15.261-.015 31.036-.047 45.618 8.276 11.077 6.327 13.187 15.002 15.234 23.398 2.164 8.878 4.427 18.085 17.461 23.807 14.259 6.255 30.147 10.983 45.53 15.574 22.339 6.652 45.446 13.53 65.437 25.41 14.118 8.403 26.097 22.794 37.682 36.719 9.439 11.346 19.192 23.073 30.047 31.462l.356.276c16.689 12.759 36.379 22.75 55.411 32.395 5.386 2.735 10.786 5.482 16.185 8.319l.682-.762c-5.457-2.86-10.947-5.653-16.391-8.41z"
        fill="url(#paint50_linear_1242_1858)"
      />
      <path
        d="M691.53-2.238c-18.789-9.272-38.218-18.855-54.479-31.33l-.186-.144c-10.67-8.245-19.972-20.154-28.973-31.668-10.653-13.62-21.645-27.709-35.055-35.908-19.523-11.935-42.438-18.94-64.585-25.72-16.386-5.021-33.352-10.213-48.246-17.281-12.816-6.083-14.087-14.847-15.453-24.12-1.31-8.954-2.675-18.228-14.557-25.5-14.937-9.126-31.631-9.298-47.764-9.45-16.023-.159-31.165-.306-44.67-8.487-15.11-9.168-14.594-23.41-14.062-38.491.454-12.242.894-24.908-7.576-34.907-8.57-10.122-20.581-12.246-32.192-14.289-13.253-2.347-26.946-4.757-35.496-19.261-6.068-10.283-9.174-21.154-11.267-32.073l-.877.98c2.11 10.724 5.244 21.387 11.206 31.496 8.804 14.953 22.768 17.412 36.276 19.795 11.925 2.102 23.194 4.089 31.515 13.904 8.225 9.718 7.774 22.191 7.325 34.252-.484 13.622-.987 27.644 11.355 37.182a35.753 35.753 0 003.213 2.206c13.78 8.371 29.787 8.518 45.265 8.671 15.98.149 32.519.316 47.168 9.267 11.459 7.013 12.791 16.031 14.059 24.769 1.334 9.134 2.719 18.56 16.057 24.909 14.968 7.102 31.964 12.318 48.406 17.336 22.088 6.758 44.917 13.742 64.304 25.595 13.212 8.069 24.182 22.095 34.77 35.64 9.1 11.638 18.539 23.629 29.351 32.007 16.354 12.548 35.83 22.166 54.662 31.449 4.362 2.15 8.755 4.326 13.155 6.552l.682-.762a1888.544 1888.544 0 00-13.331-6.62z"
        fill="url(#paint51_linear_1242_1858)"
      />
      <path
        d="M333.294-259.257c-.932 13.712-1.904 27.876 10.948 37.808a34.824 34.824 0 002.727 1.9c14.197 9.015 30.873 9.496 47.017 9.957 16.83.485 34.22.989 49.412 10.911 11.904 7.772 12.359 17.124 12.803 26.168.471 9.364.954 19.036 14.641 26.046 15.658 8.026 33.641 13.661 51.029 19.111 11.707 3.661 23.606 7.402 35.047 11.916l-2.71-2.094c-10.538-4.001-21.376-7.405-32.053-10.753-17.345-5.441-35.285-11.065-50.85-19.019-13.106-6.722-13.567-15.687-14.043-25.193-.477-9.299-.953-18.92-13.284-26.977-15.467-10.112-33.039-10.617-50.035-11.092-16.719-.492-32.512-.935-46.422-9.774-15.152-9.615-14.196-23.792-13.18-38.796.758-10.854 1.488-22.029-4.222-31.598l-2.478-1.914c7.248 9.813 6.438 21.778 5.653 33.393z"
        fill="url(#paint52_linear_1242_1858)"
      />
      <path
        d="M699.939-4.374c-18.581-8.95-37.785-18.198-53.921-30.668l-.542-.42c-10.544-8.24-19.267-20.299-27.716-31.962-.51-.717-1.033-1.42-1.544-2.137l-2.85-2.202c1.171 1.618 2.342 3.237 3.525 4.842 8.487 11.738 17.259 23.858 27.927 32.195l.542.418c16.229 12.543 35.492 21.813 54.119 30.799 1.961.94 3.938 1.892 5.888 2.846l-3.732-2.884a34.371 34.371 0 00-1.696-.827z"
        fill="url(#paint53_linear_1242_1858)"
      />
      <path
        d="M292.148-308.715c7.268 1.128 14.68 2.299 21.41 5.199l-2.771-2.142c-5.942-2.083-12.286-3.073-18.528-4.03-14.507-2.258-28.217-4.384-37.347-18.851-5.787-9.19-8.962-18.985-11.105-28.881l-.604-.466-.462.517c2.171 10.009 5.363 19.933 11.267 29.283 9.396 14.902 24.018 17.18 38.14 19.371z"
        fill="url(#paint54_linear_1242_1858)"
      />
      <path
        d="M291.827-320.298l-1.703-1.317c-11.556-2.278-21.801-6.052-28.062-16.645-1.586-2.676-2.917-5.431-4.114-8.221l-1.688-1.304c1.375 3.41 2.976 6.742 4.884 9.966 6.817 11.552 18.109 15.238 30.683 17.521z"
        fill="url(#paint55_linear_1242_1858)"
      />
      <path
        d="M345.261-267.926c-1.539 13.654-3.132 27.749 10.105 37.978a38.75 38.75 0 002.621 1.841c14.925 9.601 32.243 10.327 49.001 11.035 6.739.284 13.562.562 20.295 1.439l-1.502-1.161c-6.239-.725-12.561-1.008-18.808-1.256-16.589-.692-33.753-1.415-48.406-10.851-15.504-9.979-13.932-24.021-12.257-38.881.377-3.23.739-6.471.919-9.715l-1.037-.801c-.154 3.448-.541 6.923-.931 10.372z"
        fill="url(#paint56_linear_1242_1858)"
      />
      <path
        d="M655.417-36.497l-1.084-.838c-4.253-3.356-8.079-7.395-11.671-11.782l-3.298-2.55c4.329 5.671 9.001 10.871 14.311 15.067l1.099.85c8.378 6.474 17.649 11.982 27.22 17.007l-4.708-3.638c-7.672-4.25-15.071-8.862-21.869-14.116z"
        fill="url(#paint57_linear_1242_1858)"
      />
      <path
        d="M469.847-176.572c.688 10.935 1.336 21.241 13.436 27.301 14.587 7.291 31.079 12.716 48.557 18.445 2.971.983 5.953 1.953 8.939 2.948l-2.307-1.782c-2.11-.687-4.223-1.399-6.332-2.086-17.436-5.718-33.897-11.12-48.397-18.39-11.547-5.77-12.19-15.819-12.866-26.445-.131-1.873-.234-3.748-.407-5.631l-1.162-.898c.266 2.162.396 4.357.539 6.538z"
        fill="url(#paint58_linear_1242_1858)"
      />
      <path
        d="M497.372-152.805c-7.936-4.246-10.426-10.359-11.164-17.26l-1.146-.885c.615 7.565 3.062 14.312 11.823 19.011 8.94 4.791 18.681 8.775 28.946 12.496l-2.554-1.974c-9.168-3.425-17.864-7.085-25.905-11.388z"
        fill="url(#paint59_linear_1242_1858)"
      />
      <path
        d="M368.078-238.176c.65.503 1.34.99 2.073 1.488 13.798 9.282 29.798 11.13 45.61 12.165l-1.378-1.065c-15.2-1.045-30.494-3.038-43.62-11.871-12.777-8.608-13.74-19.617-12.533-31.526l-.991-.766c-1.207 11.472-.571 22.757 10.839 31.575z"
        fill="url(#paint60_linear_1242_1858)"
      />
      <path
        d="M384.047-246.072c-4.463-3.15-7.412-6.557-9.357-10.178l-1.812-1.4c1.758 4.006 4.603 7.793 9.094 11.263.464.359.956.717 1.449 1.074 4.936 3.47 10.185 6.007 15.627 7.888l-2.586-1.999c-4.306-1.716-8.459-3.867-12.415-6.648z"
        fill="url(#paint61_linear_1242_1858)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint10_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint11_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint12_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint13_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint14_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint15_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint16_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint17_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint18_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint19_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint20_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint21_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint22_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint23_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint24_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint25_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint26_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint27_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint28_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint29_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint30_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint31_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint32_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint33_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint34_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint35_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint36_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint37_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint38_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint39_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint40_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint41_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint42_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint43_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint44_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint45_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint46_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint47_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint48_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint49_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint50_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint51_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint52_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint53_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint54_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint55_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint56_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint57_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint58_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint59_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint60_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint61_linear_1242_1858"
          x1={435}
          y1={-29.4282}
          x2={334.791}
          y2={145.848}
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgComponent

import { useEffect } from "react";


// function getApiUrl(chainId) {
//     return `https://pools.sushi.com/api/v0?&chainIds=${chainId}&isWhitelisted=true&orderBy=liquidityUSD&isIncentivized=true&orderDir=desc&protocols=SUSHISWAP_V3`;
// }


export const useSushiRewardPools = (chainId: number) => {
    // const [loadingSushiRewardPools, setLoadingSushiRewardPools] = useState(false);
    // const url = getApiUrl(chainId);

    useEffect(() => {
        // const fetchData = async () => {
        //     try {
        //         const response = await axios.get(url);
        //         const data = await response.data;

        //         if (data && data.length > 0) {
        //             // filter data array to include incentives which are present inside each element
        //             const pools = data.filter((pool) => {
        //                 // filter incentives which are merkle rewards
        //                 if (pool.incentives) {
        //                     pool.incentives = pool.incentives.filter((incentive) => incentive.chefType === "Merkl");
        //                     return pool.incentives.length > 0 && pool.isIncentivized === true
        //                 } else {
        //                     return false;
        //                 }
        //             }).map((pool) => {
        //                 // filter out the merkle incentives and merge it into main object from array
        //                 pool.incentives = pool.incentives.filter((incentive) => incentive.chefType === 'Merkl');
        //                 pool.incentive = pool.incentives[0];
        //                 return pool
        //             });
        //         } else {
        //         }
        //         setLoadingSushiRewardPools(false);
        //     } catch (err) {
        //         console.log("Error fetching sushi-reward-pools data");
        //         setLoadingSushiRewardPools(false);
        //     }
        // };
        // fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId]);

    return { sushiRewardPools: [], loadingSushiRewardPools: false };
}
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const stablesParameters = {
    EUR: {
        currencySymbol: '€',
    },
};
exports.default = {
    stablesParameters: stablesParameters,
};

import React, { FunctionComponent } from "react";
import {Icon} from './Icon';

export interface IconDetailBoxProps {
  msg: string;
  icon: string;
  spin?: boolean;
}

export const IconDetailBox: FunctionComponent<IconDetailBoxProps> = (props) => {
  return (
    <div data-testid="IconDetailBox-component">
      <div className={`
        border border-radical-red-rgba-secondary 
        bg-radical-red-rgba-primary rounded-xl p-4 flex items-center
      `} 
      >
         <Icon name={props.icon} size={"lg"} style={{ color: "#ff6682" }} spin={props.spin || false}/>
        <div className={`text-base leading-5 text-red-default ml-4`}>{props.msg}</div>
      </div>

    </div>
  )
}
 
IconDetailBox.defaultProps = {
};

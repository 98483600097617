import { useQuery } from "@tanstack/react-query";
import { Strategy } from "utils/types";  // Define Strategy type as per your application
import millify from 'millify';

export interface QueryResponse {
  data: Strategy[]
}

export const useStrategies = (chainId) => {
  return useQuery(
    ["strategies_data"],
    async () => {
  
      const res = await fetch(`https://9i52h964s3.execute-api.us-east-1.amazonaws.com/dev/strategies?chainId=${chainId}`)

      const data = await res.json() as QueryResponse

      return (data?.data || []).map(d => {
        return {
          ...d,
          totalTvl: millify(
            parseFloat(d.totalTvl),
            {
              precision: 2
            }
          ),
          avgApr: `${d.avgApr}%`
        }
      })
    }
  )
}